export function usePermission() {
  const route = useRoute();
  const permission = computed(() => {
    if (route.query.permission) {
      return parseInt(route.query.permission);
    }
    return 0;
  });
  return {
    permission,
  };
}
