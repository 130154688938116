<template>
  <el-card
    class="card-item"
    shadow="never"
    :body-style="{ padding: 0 }"
    @click="$emit('toEdit', props.info.bkId)"
    @contextmenu="handleContextMenu"
  >
    <div class="mark default" v-if="info.defaultUse">Default</div>
    <div class="mark lock" v-if="info.lock">
      Locked
      <img :src="lockIcon" alt="" />
    </div>
    <div class="image-container">
      <div class="logo">
        <img
          :src="info.coverPic ? info.coverPic : getDefaultLogo(index)"
          alt=""
        />
      </div>
    </div>
    <div class="card-footer">
      <p class="name">{{ info.name }}</p>
      <div class="info">
        <span class="time">{{ updateTime }}</span>
        <span @click.stop>
          <el-dropdown
            v-if="!isEmptyObject(operations)"
            popper-class="barnd-info-dropdown"
            placement="bottom-end"
            trigger="click"
            ref="dropdownRef"
            @command="handleCommand"
            @visibleChange="handleMenuVisibleChange"
            :teleported="false"
            :popper-options="{
              modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
            }"
          >
            <img
              :class="['more', isFocusMenu ? 'active' : '']"
              width="16"
              height="16"
              :src="moreIcon"
            />
            <template #dropdown v-if="isFocusMenu">
              <el-dropdown-menu class="card-menu">
                <el-dropdown-item
                  v-for="operation of operations"
                  :key="operation.text"
                  :command="operation"
                  :disabled="operationDisabled(operation)"
                >
                  <svgIcon
                    :color="operationDisabled(operation) ? '#BBBFC4' : ''"
                    :name="operation.iconName"
                  />
                  <span>{{ operation.text }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </span>
      </div>
    </div>
  </el-card>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  watch,
  defineEmits,
  defineProps,
  getCurrentInstance,
} from "vue";
import type { PropType } from "vue";
import type { DropdownInstance } from "element-plus/lib/components";
import type { BrandInfo, LogoOperation } from "../../type";
import type { MutexSpace } from "@/utils";
import lockIcon from "../../assets/images/lock.svg";
import moreIcon from "@/assets/icons/common/icon_more.svg";
import logo1 from "@/assets/brand-kit-images/default_logo_blue.png";
import logo3 from "@/assets/brand-kit-images/default_logo_green.png";
import logo4 from "@/assets/brand-kit-images/default_logo_orange.png";
import logo2 from "@/assets/brand-kit-images/default_logo_purple.png";
import { formatDate } from "@/utils";

const emits = defineEmits(["delete", "default", "toEdit"]);
const props = defineProps({
  info: {
    type: Object as PropType<BrandInfo>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  handlePosterLoadFail: {
    type: Function as PropType<(e: Event) => void>,
    default: () => {},
  },
  handleClickPoster: {
    type: Function as PropType<(e: MouseEvent) => void>,
    default: () => {},
  },
  posterStyle: {
    type: String,
    default: "",
  },
});

let popperRef: undefined | DropdownInstance["popperRef"];
const mutexSpace = inject("mutexSpace") as MutexSpace;
const dropdownRef = ref(null as unknown as DropdownInstance);
const isFocusMenu = mutexSpace.ref();
const instance = getCurrentInstance();
const operations: LogoOperation[] = [
  {
    text: "Edit",
    iconName: "icon_edit",
    handler: () => {
      emits("toEdit", props.info.bkId);
    },
  },
  {
    text: "Mark as default",
    iconName: "icon_default_use",
    handler: () => {
      emits("default", props.info.bkId);
    },
  },
  {
    text: "Delete",
    iconName: "icon_delete",
    handler: () => {
      emits("delete", props.info.bkId);
    },
  },
];

const operationDisabled = (operation: LogoOperation) => {
  if (props.info.lock) {
    if (operation.text === "Delete") {
      return false;
    } else {
      return true;
    }
  } else {
    if (props.info.defaultUse) {
      const banArray = ["Mark as default", "Delete"];
      return banArray.includes(operation.text);
    } else {
      return false;
    }
  }
};

const defaultLogoList: string[] = [logo1, logo2, logo3, logo4];

const updateTime = computed(() => formatDate(props.info.createTime || 0));

watch(
  () => isFocusMenu.value,
  (value) => {
    value ? dropdownRef.value.handleOpen() : dropdownRef.value.handleClose();
  },
);

const isEmptyObject = (target: object) => {
  return Object.keys(target).length === 0;
};

const handleMenuVisibleChange = (visible: boolean) => {
  isFocusMenu.value = visible;
};

const handleCommand = (operation: LogoOperation) => {
  operation.handler(props.index);
};

const getDefaultLogo = (id: number) => {
  return defaultLogoList[id % 4];
};

const handleContextMenu = (e: PointerEvent) => {
  if (
    popperRef === undefined ||
    (e.target as HTMLElement).closest(".barnd-info-dropdown") !== null
  )
    return;

  isFocusMenu.value = true;

  const el = instance!.vnode.el as HTMLElement;
  const targetRect = el.getBoundingClientRect();
  const menu = el.querySelector(".barnd-info-dropdown") as HTMLElement;
  const contentRef = popperRef!.contentRef.contentRef;

  setTimeout(() => {
    const style = contentRef.contentStyle[1];
    const offsetX =
      e.clientX + menu.offsetWidth > innerWidth ? menu.offsetWidth + 4 : 0;
    const offsetY =
      e.clientY + menu.offsetHeight > innerHeight ? menu.offsetHeight : 0;

    style.top = `${e.clientY - targetRect.y - offsetY}px`;
    style.left = `${e.clientX - targetRect.x - offsetX}px`;
    style.right = "auto";
    style.bottom = "auto";
  }, 1);

  e.preventDefault();
  e.stopPropagation();
};

onMounted(() => {
  popperRef = dropdownRef.value?.popperRef;
});
</script>

<style scoped lang="scss">
.card-item {
  position: relative;
  border-color: var(--card-border-color);
  border-radius: var(--card-border-radius);
  user-select: none;
  overflow: visible;
  cursor: pointer;
}

.mark {
  z-index: 1;
  position: absolute;
  top: 15px;
  left: 15px;
  height: 28px;
  background: #000000;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.default {
  color: #ffffff;
}

.lock {
  display: flex;
  align-items: center;
  color: #ffe4ae;
  & > img {
    width: 16px;
    margin-left: 6px;
  }
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/transparentmask.webp");
  background-size: contain;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  aspect-ratio: 1 / 1;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 3.5px;
  border-top-right-radius: 3.5px;

  & > img {
    max-width: 100%;
    max-height: 100%;
    transform: scale(1.01);
  }
}

.card-footer {
  padding: 14px;
  border-top: 1px solid var(--card-border-color);
}

.name {
  margin-bottom: 8px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-dropdown) {
    position: static;
  }
}

.time {
  font-size: 12px;
  line-height: 24px;
  color: #646a73;
}

.card-item:hover .more,
.more.active {
  opacity: 1;
}

.more {
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 4px;
  outline: none;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: #eaeaea;
    border-radius: 2px;
  }
}

.card-menu {
  min-width: 150px;
  padding-block: 8px;

  & > :deep(li) {
    color: #1f2329;
    padding-inline: 12px;

    & > svg {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    & > span {
      font-size: 14px;
    }
  }

  :deep(.el-dropdown-menu__item.is-disabled) {
    cursor: default;
    color: #bbbfc4;
  }
}

:deep(.barnd-info-dropdown) {
  border: 1px solid var(--card-border-color);
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);

  & > .el-popper__arrow {
    display: none;
  }
}
</style>
