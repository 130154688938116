<template>
  <div class="flex w-full justify-between mb-6 isPick">
    <div class="flex flex-col isPick" :style="{ marginRight: '4px' }">
      <p class="label isPick">
        {{ label }}
      </p>
      <input
        class="value isPick"
        :value.prop="value"
        @focus="onFocus"
        @input="onInput"
        @blur="onBlur"
        @keyup.enter="onEnter"
        :style="valueStyle"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "Input",
  props: {
    label: {
      type: String,
      default: "RGBA",
    },
    value: {
      type: [Number, String],
      default: "",
    },
    width: {
      type: Number,
      default: 10,
    },
  },
  emits: ["change", "focus", "blur", "enter"],
  setup(props, { emit }) {
    const valueStyle = computed(() => ({
      minWidth: `${props.width}px`,
      maxWidth: `${props.width}px`,
      width: `${props.width}px`,
    }));
    const onInput = (e) => {
      emit("change", e.target?.value);
    };
    const onFocus = () => {
      emit("focus");
    };
    const onBlur = () => {
      emit("blur");
    };
    const onEnter = () => {
      emit("enter");
    };
    return {
      onInput,
      valueStyle,
      onFocus,
      onEnter,
      onBlur,
    };
  },
});
</script>

<style scoped lang="scss">
.input {
  display: inline-block;
  font-size: 12px;
}

.label {
  width: 100%;
  height: 30px;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8f959e;
  font-size: 10px;
  // background: #e7e8e9;
}

[pick-colors-theme="dark"] .label {
  color: #999;
  background: #252930;
}

.value {
  flex: 1;
  height: 30px;
  text-align: center;
  // background: #eceef0;
  box-sizing: border-box;
  border: 1px solid #bbbfc4;
  color: #666666;
  font-size: 12px;
}

[pick-colors-theme="dark"] .value {
  background: #2e333a;
  border: 1px solid #2e333a;
  color: #ffffff;
}

.value:focus {
  outline: none;
  border: 1px solid #875eff;
}
</style>
