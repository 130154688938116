<template>
  <el-scrollbar>
    <div class="voice-collection">
      <div class="collection-item-box" v-if="list && list.length">
        <div v-for="item in list" :key="item.mediaId">
          <voiceItem
            :list="list"
            :selectedMediaId="selectedMediaId"
            :item="item"
            @onSelected="handleSelected"
            @onPlay="handlePlayVideo"
            @onPause="handlePauseVideo"
            @onCancelSelected="handleCancelSelected"
          />
        </div>
      </div>
      <div v-else class="h-full flex justify-center items-center">
        <svg-icon
          name="icon_empty"
          style="color: #bbbfc4; height: 200px"
        ></svg-icon>
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup>
import voiceItem from './voiceItem.vue';
import { useSimilarVideo } from '@/pages/similarVideo/store/index.js';

const similarVideoStore = useSimilarVideo();
const {} = storeToRefs(similarVideoStore);

const emit = defineEmits(['back', 'onPlay']);

const props = defineProps({
  list: Array,
  selectedStyleId: Number,
  selectedMediaId: Number,
});

const handleSelected = (mediaId, voiceId) => {
  similarVideoStore.setSelectedMediaId(mediaId);
  similarVideoStore.setSelectedVoiceId(voiceId);
  props.list.forEach((item) => {
    if (item.mediaId != mediaId) {
      item.isSelected = false;
    }
  });
};

const handleCancelSelected = (id) => {
  similarVideoStore.setSelectedMediaId(null);
  similarVideoStore.setSelectedVoiceId(null);
  props.list.forEach((item) => {
    if (item.mediaId == id) {
      item.isSelected = false;
    }
  });
};

const handlePlayVideo = (id) => {
  props.list.forEach((item) => {
    if (item.mediaId != id) {
      item.isPlay = false;
    }
  });

  emit('onPlay');
};

const handlePauseVideo = (id) => {
  props.list.forEach((item) => {
    if (item.mediaId == id) {
      item.isPlay = false;
    }
  });
};

const handleBack = () => {
  emit('back');
};
</script>

<style lang="scss" scoped>
.voice-collection {
  padding: 21px 12px 120px 15px;

  .collection-item-box {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 4px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-auto-rows: minmax(124px, 124px);
  }

  .header {
    display: flex;
    padding-left: 9px;
    .title {
      color: #000;
      text-align: center;
      font-family: Inter-variant;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      margin-left: -80px;
      pointer-events: none;
    }
  }
}
</style>
