<template>
  <el-scrollbar class="scenelist-scrollbar">
    <div class="scenelist-container">
      <template
        v-if="hookScenes.length > 0"  
      >
        <div
          v-for="(scene, index) in hookScenes"
          class="scene-wrapper"
          :key="scene.sceneId"
        >
          <Hook 
            :scene="scene" 
          />
        </div>
      </template>
      <template
        v-if="overallScenes.length > 0"  
      >
        <div
          
          v-for="(scene, index) in overallScenes"
          class="scene-wrapper"
          :key="scene.sceneId"
        >
          <Overall 
            :scene="scene" 
          />
        </div>
      </template>
      
      <div 
        v-for="(scene, index) in defaultScenes"
        class="scene-wrapper default"
        :key="scene.sceneId"
      >
        <Scene 
          :scene="scene" 
          :index="index"
        />
      </div>
    </div>
  </el-scrollbar>
  <Loading/>
</template>

<script setup>
import Scene from "./scene.vue";
import Hook from "./hook.vue";
import Overall from "./overall.vue";
import Loading from "./loading.vue";
import { useScriptStore } from "../../stores/script";

const {
  currentFrame,
  scenePlaying,
  loadingScene,
  scenes,
  pause,
  seekTo,
  setActiveScene,
  setScenePlaying,
} = useScriptStore();

const currentPlay = ref(null);
const defaultScenes = computed(() => {
  return scenes.value.filter((item) => 
    item.type === "default" || !item.type
  );
});
const hookScenes = computed(() => {
  return scenes.value.filter((item) =>  {
    const hookTypeList = ["hook_start", "viral_hook"];
    return hookTypeList.includes(item.type);
  });
});
const overallScenes = computed(() => {
  return scenes.value.filter((item) => 
    item.type === "voice_sticker"
  );
});

provide("currentPlay", currentPlay);

watch(
  currentFrame,
  async (value) => {
    const scene = currentPlay.value;
    if (scenePlaying.value) {
      if (scene?.end <= value) {
        await replayScene(scene);
        setScenePlaying(false);
      }
    }
  }
);

watch(
  loadingScene,
  (value) => {
    if (value) {
      pause();
    }
  }
);

const replayScene = async (scene) => {
  await pause();
  await seekTo(scene.start);
};

const initActiveScene = () => {
  setActiveScene(defaultScenes.value[0]);
};

onMounted(initActiveScene);
</script>

<style lang="scss" scoped>
.scenelist-container {
  min-height: 100vh;
  padding: 0px 14px 300px 0;
}

.scene-wrapper + .scene-wrapper {
  margin-top: 10px;
}
</style>