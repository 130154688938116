<script setup>
import { getStickerList } from "@/api/resources";
import { useNetwork } from "@/composables";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import Material from "./material.vue";

const { getXY, addStickerNode } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { assertNetworkError } = useNetwork();
const { dragData } = useDrag();

const hoverFile = ref(null);
const files = ref([]);
const loading = ref(false);

onMounted(getStickers);

async function getStickers() {
  loading.value = true;
  const { success, data } = await getStickerList();

  if (success) {
    files.value = data.reduce((group, item) => {
      const tag = item.tag || "Animated";
      if (!group[tag]) group[tag] = [];
      group[tag].push(item);
      return group;
    }, {});
  }
  loading.value = false;
}

async function addSticker(file) {
  assertNetworkError();
  const { 
    type, 
    coverPic, 
    width480,
    width1080,
    url: src,
    id: sourceId, 
    preview1080Url: hdUrl,
  } = file;
  const materialMeta = { width480, width1080, url1080: hdUrl };
  await addStickerNode({ coverPic, materialMeta, sourceId, src, mode: type === 1 ? "animated" : "static" });
  commit();
  updateDraft();
}

function handleDrag(e, dragTarget, file) {
  const { 
    type, 
    coverPic, 
    width480,
    width1080,
    url: src,
    id: sourceId, 
    preview1080Url: hdUrl,
  } = file;
  const materialMeta = { width480, width1080, url1080: hdUrl };
  const dragElement = dragTarget.cloneNode();

  Object.assign(dragElement.style, {
    maxWidth: "100px",
    maxHeight: "100px",
  });

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      ...getXY(),
      coverPic, 
      materialMeta, 
      sourceId, 
      src, 
      type: "sticker",
      scale: 0.5,
      mode: type === 1 ? "animated" : "static",
    },
  });
}

function handleMouseDown(e) {
  const target = e.target.closest("[candrag]");

  if (target === null || e.button !== 0) return;
  const dragTarget = target.querySelector("[drag-target]");

  if (dragTarget === null) return;
  const { key, index } = dragTarget.dataset;
  const file = files.value[key][index];

  const mouseUpListener = () => {
    addSticker(file);
    target.removeEventListener("mousemove", moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener("mouseup", mouseUpListener);
    handleDrag(e, dragTarget, file);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  target.addEventListener("mousemove", moveListener, { once: true });
  target.addEventListener("mouseup", mouseUpListener, { once: true });
}
</script>
<template>
  <Material title="Sticker">
    <el-scrollbar>
      <div class="material-content" @mousedown="handleMouseDown">
        <el-skeleton animated :loading="loading">
          <template #template>
            <el-skeleton-item variant="text" />
            <div class="skeleton-list">
              <el-skeleton-item
                v-for="(_, i) in Array(20)"
                variant="rect"
                :key="i"
              />
            </div>
            <el-skeleton-item variant="text" />
            <div class="skeleton-list">
              <el-skeleton-item
                v-for="(_, i) in Array(20)"
                variant="rect"
                :key="i"
              />
            </div>
          </template>
          <div class="col" v-for="(key, i) in Object.keys(files).sort()" :key="i">
            <div class="file-header">
              <span>{{ key }}</span>
            </div>
            <div class="file-list">
              <div
                v-for="(file, j) in files[key]"
                class="file-item"
                :key="file.id"
                @mouseenter="hoverFile = file" 
                @mouseleave="hoverFile = null"
              >
                <div class="image-wrapper" candrag>
                  <img
                    drag-target
                    loading="lazy"
                    draggable="false"
                    :src="hoverFile === file && file.dynamicPic ? file.dynamicPic : file.coverPic"
                    :data-key="key"
                    :data-index="j"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-skeleton>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style scoped>
.material-content .col {
  padding-bottom: 8px;
}
.file-header {
  height: 54px;
  display: flex;
  align-items: center;
}
.file-header span {
  font-size: 14px;
  line-height: 22px;
  color: #060606;
}
.file-list,
.skeleton-list {
  display: grid;
  flex-wrap: wrap;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));
}
.file-item {
  width: 68px;
}
.image-wrapper,
:deep(.el-skeleton__rect) {
  width: 68px;
  height: 68px;
  padding: 7px;
  border: 1px solid #e8e9ec;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  transition: border-color 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
:deep(.el-skeleton__text) {
  margin: 18px 0;
  height: 20px;
  width: 50%;
}
.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
</style>
