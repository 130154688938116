<template>
  <div class="form-wrapper" id="form-wrapper">
    <el-scrollbar>
      <div class="script-render-form-container">
        <div class="prompt-container">
          <div class="title flex">
            Script
            <assistance v-if="globalDomain === 1" :loading="false"></assistance>
          </div>

          <el-input
            @input="handleInput"
            :class="{
              exceeded: prompt?.length >= 7000,
              focused: promptFocused,
            }"
            v-model="prompt"
            resize="none"
            type="textarea"
            show-word-limit
            placeholder="Enter your script to generate video"
            :rows="6"
            :maxlength="7000"
            @focus="handleFocus"
            @blur="handleBlur"
          />

          <div
            class="prompt-popover"
            :class="{ hide: !showPrompt, appear: showPrompt }"
          >
            <p>Try one of these:</p>
            <div class="prompt-preset-grid">
              <div
                v-for="item in promptPreset"
                class="prompt-preset-grid-item"
                @mousedown.stop="clickPrompt(item.value)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
        <div class="ratio-container">
          <p class="title">Ratio</p>
          <div class="select-container">
            <bv-select
              :showBoxShadow="true"
              :showBorder="false"
              :teleported="false"
              v-model="ratio"
              :options="ratioPreset"
              :popperStyle="{
                width: '50vw',
                maxWidth: '712px',
                minWidth: '500px',
              }"
              @change="(v) => (ratio = v)"
            />
          </div>
        </div>
      </div>
    </el-scrollbar>

    <PrimaryButton class="button-container" padding="16px 48px" @click="submit">
      <div class="button-text">
        <svg-icon
          v-if="loading"
          class="loading-icon"
          name="icon_loading"
          :size="14"
        />
        <svg-icon class="star" name="icon_generate_star" :size="18" />
        Generate
      </div>
    </PrimaryButton>
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { checkResource } from '@/api/script';
import { getLanguageList } from '@/api/language';
import { useMessage, removeEscapeHtml } from '@/utils';
import { useTrackStore } from '@/store/modules/track';
import { useModalManager } from '@/components/common/custom-modal/instance';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import Assistance from './aiAssistance.vue';
import { sessionSetItem } from '@/utils/storage';

const modalManager = useModalManager();

const { collectData, track } = useTrackStore();
const router = useRouter();
const message = useMessage();
const promptFocused = ref(false);
const prompt = ref('');
const showPrompt = ref('');
const ratio = ref('9:16');
const language = ref('');
const loading = ref(false);
const languageOptions = ref([]);
const leftPage = ref(false);
const globalDomain = inject("globalDomain");

const ratioPreset = [
  {
    label: '9:16 ( Portrait )',
    value: '9:16',
  },
  {
    label: '1:1 ( Square )',
    value: '1:1',
  },
  {
    label: '16:9 ( Landscape )',
    value: '16:9',
  },
];

const promptPreset = [
  {
    label: 'Some habits that can improve your sleep health',
    value: `Here are some habits that can improve your sleep health:
1. consistent: Try to go to bed and wake up at the same time each day
Create a restful environment: Keep your bedroom dark, quiet, and at a comfortable temperature
2. Remove electronic devices: Avoid TVs, computers, and smartphones in the bedroom
3. Avoid certain foods and drinks: Avoid large meals, caffeine, and alcohol before bed
4. Get some exercise: Daily exercise can promote solid sleep  `,
  },
  {
    label: '6 Ways to Improve Your Mental Health',
    value: `With so much uncertainty in the world, it’s easy to feel overwhelmed by fear and anxiety. Find out how you can improve your mental health by focusing on things you can control.
1. Take care of yourself
Take care of yourself by managing your stress levels, getting enough sleep, staying active and eating healthy. These are important actions to take to improve your overall health and maintain mental wellness.
2. Connect with others
Reaching out to loved ones is a great way to reduce anxiety and improve your mental health. Calls, texts and social media can keep you connected while maintaining a responsible social distance.
3. Learn a new skill
Studies show that learning a new skill can result in increased wages and an improved ability to enjoy life to the fullest. Leverage your spare time indoors by learning a new technical, survival or even self-awareness skill.
4. Talk to your kids
Much like adults, children struggle with stress. Reduce their anxiety by offering information that is truthful and appropriate for their age and development level. This will boost their mood while maintaining your mental well-being.
5. Practice gratitude
Focusing on what you are grateful for can reduce depression and increase positive emotions such as joy, optimism and enthusiasm. Regularly write brief reflections on moments for which you are thankful to increase mental resilience.
6. Revisit an old hobby
Now is the perfect time to get back to the things you love. Research shows that revisiting old hobbies can increase productivity and improve mental sharpness.`,
  },
  {
    label: "Boolvideo's solution for social media marketer",
    value: `In the scroll-stopping world of social media, engaging video content is the key to capturing attention and driving results. However, constantly creating fresh, thumb-stopping videos for all your social channels is incredibly time and resource-intensive. Boolvideo is here to solve this struggle. It empowers social media marketers to overcome these challenges by providing a comprehensive suite of powerful yet user-friendly video creation and editing features.`,
  },
  {
    label: 'Boolvideo could convert written scripts to video effortlessly',
    value: `Are you in search of the ultimate ease in content creation, aspiring to turn your written scripts into compelling videos without a hassle? Look no further, as we present you the cutting-edge path to bring your visions to the digital stage using advanced artificial intelligence.Boolvideo's 'Script to Video' is the simplest and most efficient way to metamorphose your scripts into engaging video content. This innovation is not just about generating plain visuals but about crafting immersive experiences for social media enthusiasts across Instagram, TikTok, and YouTube Shorts.
Begin with merely a few lines of text that embody your core message or idea. Boolvideo takes this input and breathes life into it, weaving together high-quality stock footage, lifelike AI narrations, captivating soundtracks, tailored effects, and much more. You are at the helm, steering every aspect of the video creation process.
The scope doesn't end there. Enjoy the freedom to mix and match elements with the drag-and-drop ease that brings sophistication sans the complexity. Become an orchestrator of your content's vibe, rhythm, and narrative flow through control that was previously unthinkable without technical expertise in video editing.
Imagine liberating yourself from the constraints of traditional content creation. Our platform enables personalized storytelling that speaks directly to your audience's wants and interests. Seize the moment, empower your idea, and engage with your followers on a level that transcends the usual. Discover the transformative effect of taking a dream, a script, and casting it into the visual wonder of the social media world – effortlessly, seamlessly, like never before.`,
  },
];

const subscribeModalVisible = ref(false);

const handleInput = (inputValue) => {
  if (inputValue.trim() === '') {
    prompt.value = '';
  } else {
    prompt.value = inputValue;
  }

  showPrompt.value = prompt.value === '';
};

const submit = async () => {
  prompt.value = removeEscapeHtml(prompt.value);
  if (prompt.value.trim() === '') {
    message.error('Script can not be empty', { duration: 2000 });
    return;
  }

  const params = {
    language: language.value,
    size: ratio.value,
    prompt: prompt.value,
  };
  sessionSetItem('generateParams', params);
  const { noCredit, msg, code } = await checkResource({
    resource: [90001],
  });

  if (code == 101011) {
    modalManager.applyTemplate('paymentFail', {});
    return;
  }

  if (noCredit) {
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  router.push({
    path: '/script-to-video/generating',
  });
};

const setup = async () => {
  const languageHelper = (list) => {
    let temp = [];
    list.forEach((item) => {
      for (const i of item.localInfoList) {
        const newItem = {
          label: i.localName,
          value: i.locale,
        };
        temp.push(newItem);
      }
    });
    return temp;
  };

  const { success, data } = await getLanguageList({ filterSpecial: true });
  if (!success) return;

  languageOptions.value = languageHelper(data);
  const native = 'en-US';
  language.value = native;
};

const handleFocus = () => {
  promptFocused.value = true;
  showPrompt.value = prompt.value === '';
};

const handleBlur = () => {
  promptFocused.value = false;
  showPrompt.value = false;
  prompt.value = removeEscapeHtml(prompt.value);
};

const clickPrompt = (value) => {
  prompt.value = value;
};

onBeforeMount(setup);
onBeforeUnmount(() => {
  leftPage.value = true;
});
</script>

<style lang="scss" scoped>
:deep(.el-scrollbar) {
  width: 100%;
}

:deep(.el-scrollbar__view) {
  display: flex;
  justify-content: center;
}

.form-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem 0;
}

.script-render-form-container {
  width: 50%;
  min-width: 500px;
  max-width: 712px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  color: #060606;
  padding-bottom: 200px;

  & > div {
    width: 100%;
  }
}

.input-container {
  width: 100%;
  padding-left: 9px;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.select-container {
  height: 46px;
}

.prompt-container {
  position: relative;

  & > :deep(.el-textarea) {
    padding-bottom: 40px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    transition: border-color 0.2s;

    &:hover {
      border-color: #be9fff;
    }

    &.focused {
      border-color: #875eff;
    }

    &.exceeded > .el-input__count {
      color: #ff5449;
    }

    & > .el-textarea__inner {
      padding: 20px 20px 0;
    }

    & > .el-input__count {
      right: 12px;
      bottom: 12px;
      transition: color 0.2s;
    }
  }
}

.hide {
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.appear {
  opacity: 1;
  pointer-events: all;
}

.prompt-popover {
  position: absolute;
  width: 100%;
  bottom: -4px;
  transform: translateY(100%);
  padding: 24px;
  border-radius: 4px;
  border: 0.5px solid #e5e7eb;
  background: #fff;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
  z-index: 2;
  transition: opacity 0.08s linear 0s;

  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 18px;
  }
}

.prompt-preset-grid {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.prompt-preset-grid-item {
  width: 100%;
  height: 100%;
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fd;
  }
}

.button-container {
  position: fixed;
  bottom: 58px;
  width: fit-content !important;
}

.button-text {
  position: relative;
  width: 94px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  & > .star {
    margin-right: 6px;
  }

  & > .loading-icon {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    animation: rotate 1s linear infinite;
  }
}

.loading-container {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

:deep(.el-slider) {
  --el-slider-button-size: 18px;
  --el-slider-button-wrapper-size: 18px;
}

:deep(.el-input__inner) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #060606;
}

:deep(.el-textarea__inner) {
  font-family: Inter-variant !important;
  color: #060606;
  font-size: 14px;
  line-height: 20px;
  border: none;
  box-shadow: none;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::placeholder {
    color: #8f959e;
  }
}

:deep(.bv-select-button .select-wapper) {
  padding: 12px 21px;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

:deep(.bv-select-button .select-wapper) {
  padding: 12px 21px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e5e7eb inset !important;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

:deep(.bv-select-button .select-wapper.show-box-shadow.is-focus) {
  padding: 12px 21px;
  box-shadow: 0 0 0 1px #875eff inset !important;
}

:deep(.bv-select-button .select-value) {
  color: #060606;
  font-size: 14px;
  line-height: 20px;
}

:deep(.el-input__wrapper) {
  border: 1px solid #e5e7eb;
  box-shadow: none;
  transition: all 200ms;
  padding: 0 12px;
}

:deep(.el-input .el-input__wrapper:hover),
:deep(.el-textarea__inner:hover) {
  outline-color: #be9fff;
  border-color: #be9fff;
  box-shadow: none !important;
}

:deep(.el-input .el-input__wrapper.is-focus),
:deep(.el-textarea__inner:focus) {
  border-color: #875eff !important;
  outline-color: #875eff !important;
  outline-offset: 0;
  box-shadow: none !important;
}

:deep(.el-input__prefix) {
  margin-right: 6px;
  color: #646a73;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.el-input__prefix-inner > :last-child) {
  margin: 0;
}

:deep(.idea-language-select .bv-select-dropdown) {
  height: 400px;
}
</style>

<style lang="scss">
.bv-select-dropdown.idea-language-select ul {
  max-height: 250px;
}
</style>
