<template>
  <div class="h-full w-full flex items-center justify-center">
    <GenerateLoading
      :current="currentStep"
      :showSteps="copyGenerateSteps"
      :stepsLength="generateSteps.length"
      :stepNum="8"
      :perStepPercent="[60, 80, 100]"
    />

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, watch, onUnmounted } from 'vue';
import { useLoading } from '@/components/common/generateLoading/useLoading.js';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useSingleMessage } from '@/utils';
import { sessionGetItem, sessionSetItem } from '@/utils/storage';
import { routeMap } from '@/components/common/generateLoading/data.js';
import { validImport } from '@/pages/createVideos/utils/import';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

import { useRoute, useRouter } from 'vue-router';
import { templateVideoRender } from '@/api/index';

const Message = useSingleMessage();
const modalManager = useModalManager();
const router = useRouter();
const route = useRoute();

const { currentStep, clearTimer, generateSteps, copyGenerateSteps, renderVO } =
  useLoading();
const leftPage = ref(false);
const reqId = ref('');
const formState = ref(null);
const subscribeModalVisible = ref(false);

const handleGenerated = async (formState) => {
  // 生成接口)
  const { data, noCredit, msg, code, success } = await templateVideoRender(
    formState
  );

  if (!data) {
    Message.error('Please try again');
    router.replace({
      path: '/template-to-video',
    });
    return;
  }

  router.replace({
    path: route.path,
    query: {
      reqId: data,
    },
  });

  reqId.value = data;
};

watch(
  () => [renderVO.value, currentStep.value],
  () => {
    if (renderVO.value && currentStep.value.id == generateSteps.value.length) {
      clearTimer();
      if (leftPage.value) {
        return;
      }

      router.replace({
        path: '/template-to-video/previewer',
        query: {
          reqId: renderVO.value.reqId,
          ratio: renderVO.value.ratio,
          videoType: 'templates',
        },
      });
    }
  }
);

onMounted(() => {
  reqId.value = route.query.reqId;
  formState.value = sessionGetItem('generateParams');
  if (reqId.value) {
    return;
  }

  handleGenerated(formState.value);
});

onBeforeUnmount(() => {
  leftPage.value = true;
});

onUnmounted(() => {
  clearTimer();
});
</script>
