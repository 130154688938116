<template>
  <el-scrollbar>
    <div class="similar-video-workspace" ref="container">
      <p class="title">Two ways to create viral videos</p>
      <div
        class="card-container"
        :class="{
          'small-screen': containerWidth < 1300,
          'bigger-screen': containerWidth >= 1300,
        }"
      >
        <CardItem
          v-for="item in cardInfo"
          :key="item.id"
          :item="item"
          class="card-item"
          @onPlay="handlePlayVideo"
        />
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup>
import { ref } from 'vue';
import { getItem } from '@/utils/storage';
import CardItem from './components/cardItem.vue';
import hooks from '@/assets/similar-video/hooks.mp4';
import roll from '@/assets/similar-video/b-roll.mp4';
import smPrecisoTrackEvent from '@/utils/smPrecisoTrackEvent.ts';

const globalDomain = inject('globalDomain');
const cardInfo = ref([
  {
    id: 1,
    url: roll,
    title: 'Create a Similar Video',
    describe:
      'Drop a URL link to replicate a similar video on TikTok and YouTube to achieve millions of organic views, just like viral videos!',
    buttonText: 'Create now',
    link: '/similar-video',
  },
  {
    id: 2,
    url: hooks,
    title: 'Text to Video',
    describe:
      'Input a text-based script, then generate videos with celebrity stickers and voiceovers to create shorts that attract millions of organic views on TikTok and YouTube 10x faster. ',
    buttonText: 'Generate with one click',
    link: '/similar-script-to-video',
  },
]);

const container = ref(null);
const containerWidth = ref(1440);

const resizeWindow = () => {
  const width = window.innerWidth;
  containerWidth.value = width;
};

const handlePlayVideo = (id) => {
  cardInfo.value.forEach((item) => {
    if (item.id != id) {
      item.isPlaying = false;
    } else {
      item.isPlaying = true;
    }
  });
};

onMounted(async () => {
  if (globalDomain == 2 && import.meta.env.VITE_ENV == 'prod') {
    smPrecisoTrackEvent({
      OrderId: getItem("user_email") || "guest",
      Amount: '0',
      PageType: 'checkout',
    });
  }

  await nextTick();
  resizeWindow();
  window.onresize = () => {
    resizeWindow();
  };
});
</script>

<style lang="scss" scoped>
.similar-video-workspace {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 116px 36px;
  overflow-y: scroll;
  overflow-x: hidden;

  .title {
    width: 100%;
    color: #000;
    font-family: Inter-variant;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    margin-bottom: 64px;
    padding-left: 1px;
  }
}

.card-container {
  width: 100%;
  // height: 100%;
}

.bigger-screen {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, minmax(500px, 1fr));
}

.small-screen {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, minmax(500px, 1fr));
}
</style>
