<template>
  <div class="seven-part-container" id="part-seven">
    <div class="content-box" @click="handleToPricing">
      <img :src="iconArrow" class="icon-arrow" />
      <CutDownTime />

      <div class="button-box">
        <RouterLink class="button" :to="{ hash: '#pricing' }">
          Get 70% Off Gold Package
          <img :src="iconClick" class="icon-click" />
        </RouterLink>
      </div>

      <p class="describe marginT">Your ultimate short video engine</p>
      <p class="describe">It evolves with you too</p>
    </div>

    <div class="socials-container">
      <p class="title text-42px">Socials</p>
      <p class="sub-title">Related links</p>
      <ul class="social-name">
        <li v-for="item in linkList" :key="item.id" @click="handleLink(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>

    <ul class="social-media-list">
      <li v-for="item of socialMediaList">
        <a :href="item.link" target="_blank">
          <img :src="item.icon" class="social-media-icon" />
        </a>
      </li>
    </ul>
    <p class="explain">©2023 Boolv.tech. All rights reserved</p>
  </div>
</template>

<script setup>
import iconArrow from "./images/iconArrow.svg";
import iconClick from "./images/icon_click.png";
import CutDownTime from "./cutDownTime.vue";
import twitterIcon from "./images/twitter.svg";
import youtubeIcon from "./images/youtube.svg";
import facebookIcon from "./images/facebook.svg";
import instagramIcon from "./images/instagram.svg";
import emailIcon from "./images/email.svg";
import LinkedIn from "./images/LinkedIn.svg";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();

const linkList = [
  {
    id: 1,
    name: "YouTube Tutorials",
    link: "https://www.youtube.com/playlist?list=PLtWMq-vob2I4Tu5AWP06CmbvmgFq7Z0Y9",
  },
  {
    id: 2,
    name: "Discord Community",
    link: "https://discord.com/invite/WSC2FyGYCn",
  },
  {
    id: 3,
    name: "Twitter",
    link: "https://twitter.com/boolvtech",
  },
  {
    id: 4,
    name: "Product Hunt Reviews",
    link: "https://www.producthunt.com/products/boolvideo#boolvideo",
  },
  {
    id: 5,
    name: "Founder's LinkedIn",
    link: "https://www.linkedin.com/in/han-zhu-7633a05a",
  },
];

const socialMediaList = [
  {
    icon: twitterIcon,
    link: "https://twitter.com/boolvtech",
    style: { backgroundColor: "#000000" },
  },
  {
    icon: youtubeIcon,
    link: "https://www.youtube.com/channel/UCkzHe1HYa5Ijcbci_WeBjYA",
    style: { backgroundImage: "linear-gradient(180deg, #F03232, #FF1919)" },
  },
  {
    icon: facebookIcon,
    link: "https://www.facebook.com/profile.php?id=100083482763930",
    style: { backgroundImage: "linear-gradient(180deg, #3B5998, #4267B2)" },
  },
  {
    icon: instagramIcon,
    link: "https://www.instagram.com/boolvtech",
    style: { background: "linear-gradient(180deg, #FCAA49, #F05194)" },
  },
  {
    icon: LinkedIn,
    link: "https://www.linkedin.com/company/boolv-tech",
    style: { background: "linear-gradient(180deg, #FCAA49, #F05194)" },
  },
  {
    icon: emailIcon,
    link: "https://discord.gg/WSC2FyGYCn",
    style: { background: "linear-gradient(180deg, #64667A, #4E4F5C)" },
  },
];

const handleToPricing = () => {
  if (route.hash) {
    const targetElement = document.querySelector(route.hash);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "auto" });
    }
  }
};

const handleLink = (item) => {
  window.open(item.link);
};
</script>
<style lang="scss" scoped>
.seven-part-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(var(--base-size) * 1.2);
  padding-left: 50px;
  padding-right: 50px;

  .content-box {
    cursor: pointer;
    position: relative;
    z-index: 1;
    max-width: 1024px;
    max-height: 459.337px;
    width: calc(var(--base-size) * 12);
    height: calc(var(--base-size) * 4.59);
    border-radius: 18px;
    background: #6741ff;
    background: linear-gradient(308deg, #531ee9 43.41%, #7c50ff 92.97%);
    padding-top: calc(var(--base-size) * 0.32);
    display: flex;
    flex-direction: column;
    align-items: center;

    .time-box {
      color: #fff;
      text-align: center;
      font-family: Inter-variant;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 174%;
      margin-left: 24px;
    }

    .time {
      display: inline-block;
      width: 40px;
    }

    .button {
      width: calc(var(--base-size) * 8.9);
      height: calc(var(--base-size) * 1.17);
      max-width: 890px;
      max-height: 117px;
      background: rgba(0, 0, 0, 0.8);
      color: #fff500;
      text-align: center;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.54);
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      margin-top: calc(var(--base-size) * 0.29);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .icon-click {
        max-width: 113px;
        max-height: 127px;
        width: calc(var(--base-size) * 1.13);
        width: calc(var(--base-size) * 1.27);
        position: absolute;
        left: 49%;
        bottom: -59%;
      }
    }

    .marginT {
      margin-top: calc(var(--base-size) * 0.59);
    }

    .describe {
      color: #fff;
      text-align: center;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.32);
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
    }
  }

  .socials-container {
    width: 100%;
    max-width: 1024px;
    margin-top: calc(var(--base-size) * 1.192);

    .title {
      color: #000;
      font-family: Inter-variant;
      font-style: normal;
      font-weight: 700;
      line-height: 15.368px;
      margin-bottom: calc(var(--base-size) * 0.55);
    }

    .sub-title {
      color: #060606;
      font-family: Inter-variant;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 211%;
    }

    .social-name {
      padding-left: calc(var(--base-size) * 0.45);
      margin-bottom: calc(var(--base-size) * 0.46);

      li {
        cursor: pointer;
        color: #060606;
        font-family: Inter-variant;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 211%;
        text-decoration-line: underline;
        list-style: disc;
      }
    }
  }

  .icon-arrow {
    max-width: 209.958px;
    max-height: 144.115px;
    width: calc(var(--base-size) * 2.09958);
    height: calc(var(--base-size) * 1.44115);
    transform: rotate(0);
    position: absolute;
    top: 0;
    left: 16px;
  }

  .social-media-list {
    width: 100%;
    max-width: 1024px;

    li {
      display: inline-block;
    }

    .social-media-icon {
      max-width: 90px;
      max-height: 90px;
      height: calc(var(--base-size) * 1.5);
      width: calc(var(--base-size) * 1.5);
      margin-right: calc(var(--base-size) * 0.32);
      margin-bottom: calc(var(--base-size) * 0.32);
    }
  }

  .explain {
    width: 100%;
    max-width: 1024px;
    margin-top: 14px;
    color: #060606;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 211%;
    margin-bottom: 242px;
  }
}
</style>
