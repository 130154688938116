<template>
  <el-dialog
    id="crop-modal"
    modal-class="asset-modal"
    v-model="dialogVisible"
    destroy-on-close
    :close-on-click-modal="false"
    :show-close="false"
    width="840px"
  >
    <div class="relative" v-loading="loading">
      <div class="flex items-center border-b border-lineColor">
        <div :class="{ 'font-medium text-base py-6 pl-6 text-black': true }">
          Image Crop
        </div>
      </div>
      <div
        class="absolute top-6 right-6 h-6 w-6 hover:bg-uploadBg flex items-center justify-center rounded-sm cursor-pointer"
        @click="handleCloseClick"
      >
        <svg-icon
          name="icon_close"
          :style="{ width: '20px', height: '20px' }"
          color="#646A73"
        ></svg-icon>
      </div>
      <div class="flex h-full w-full">
        <el-scrollbar height="410px">
          <div class="px-6 py-6 flex flex-col">
            <div
              v-for="(asset, index) in innerAssets"
              :key="asset.key"
              class="flex mb-4 cursor-pointer"
              @click="handleItemClick(asset)"
            >
              <p class="pr-3 text-defaultColor text-sm">{{ index + 1 }}</p>
              <div
                class="bg-corpItemBg rounded border-2 flex justify-center items-center"
                :class="{
                  'border-primaryColor': currentCropItem?.key == asset.key,
                  'border-corpItemBg': currentCropItem?.key != asset.key,
                }"
                style="width: 100px; height: 100px"
              >
                <div
                  class="show-preview"
                  :style="{ width: '100%', height: '100%', overflow: 'hidden' }"
                >
                  <div class="flex items-center justify-center h-full w-full">
                    <img
                      :key="asset.key"
                      :src="asset?.croppedData?.url || asset?.url"
                      class="'object-contain h-full w-full"
                      :style="{ 'object-fit': 'contain' }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div class="flex-1">
          <bv-cropper
            ref="cropper"
            fixed
            autoCrop
            :outputSize="cropperOptions.outputSize"
            :outputType="cropperOptions.outputType"
            :centerBox="cropperOptions.centerBox"
            :fixedBox="cropperOptions.fixedBox"
            :canScale="cropperOptions.canScale"
            :mode="cropperOptions.mode"
            :img="cropImgUrl"
            :currentScale="currentScale"
            :fixedNumber="fixedNumber"
            :autoCropWidth="autoCropWidth"
            :autoCropHeight="autoCropHeight"
            :cropAxis="cropAxis"
            :isFirstRender="isFirstRender"
            @realTime="handleRealTimePreview"
            @img-scale="handleImageScale"
            @onIsFirstRender="handleIsFirstRender"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex items-center justify-end pr-7">
        <ordinary-plain-button
          padding="7px 36px"
          :showDefaultIcon="false"
          class="mr-4"
        >
          <p
            class="text-sm font-normal"
            @click="handleCloseClick"
            :style="{ width: '51.82px' }"
          >
            cancel
          </p>
        </ordinary-plain-button>
        <primary-button
          padding="7px 36px"
          :showDefaultIcon="false"
          @click="handleConfirmClick"
        >
          <p class="text-sm font-normal" :style="{ width: '51.82px' }">
            Confirm
          </p>
        </primary-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import {
  ref,
  defineEmits,
  type Ref,
  watch,
  reactive,
  type PropType,
  computed,
  unref,
} from "vue";
import BvCropper from "@/components/common/bv-crop/crop.vue";
import { PrimaryButton, OrdinaryPlainButton } from "@/components/common/index";
import "./style.css";

export type CropAssetData = {
  key: string;
  url: string;
  width: number;
  height: number;
  scale: number;
  croppedData?: CroppedData;
  size: [1, 1];
};

export type CroppedData = {
  url: string;
  width: number;
  scale: number;
  height: number;
  axis: { x1: number; y1: number; x2: number; y2: number };
};

type CropRealTimeData = {
  w: number;
  h: number;
  scale: number;
  div: { width: number; height: number };
  url: string;
  img: { wdith: string; height: string; transform: string };
  html: string;
};

const emit = defineEmits(["close", "change"]);

const props = defineProps({
  visible: Boolean,
  cropAssets: {
    type: Array as PropType<CropAssetData[]>,
    default: () => [],
  },
  selectedKey: {
    type: Object as PropType<string | null>,
    default: () => null,
  },
});

const loading = ref(false);
const isFirstRender = ref(true);

const cropper: Ref<typeof BvCropper | null> = ref(null);
const cropperOptions = reactive({
  outputType: "png",
  outputSize: "1",
  centerBox: false,
  fixedBox: false,
  canScale: true,
  mode: "contain",
});

const dialogVisible = ref(false);
watch(
  () => props.visible,
  (value) => {
    dialogVisible.value = value;
  },
);

const innerAssets: Ref<CropAssetData[]> = ref([]);
watch(
  () => props.cropAssets,
  (value) => {
    innerAssets.value = value.map((item) => ({ ...item }));

    if (props.selectedKey) {
      const cropItem = innerAssets.value.find(
        (item) => item.key === props.selectedKey,
      );
      if (cropItem) {
        currentCropItem.value = cropItem;
      } else {
        currentCropItem.value = innerAssets.value[0];
      }
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

const currentCropItem: Ref<CropAssetData | null> = ref(null);
watch(
  () => props.selectedKey,
  (cropKey) => {
    const cropItem = innerAssets.value.find((item) => item.key === cropKey);
    if (cropItem) {
      currentCropItem.value = cropItem;
    } else {
      currentCropItem.value = innerAssets.value[0];
    }
  },
  {
    immediate: true,
  },
);

const cropImgUrl = computed(() => {
  return currentCropItem.value?.url;
});

const currentScale = computed(() => {
  return currentCropItem.value?.croppedData?.scale;
});

const fixedNumber = computed(() => {
  if (currentCropItem.value?.size && currentCropItem.value?.size.length) {
    return [currentCropItem.value?.size[0], currentCropItem.value?.size[1]];
  }
  return [1, 1];
});

const autoCropWidth = computed(() => {
  const croppedAxis = currentCropItem.value?.croppedData?.axis;
  if (croppedAxis) {
    return croppedAxis.x2 - croppedAxis.x1;
  } else {
    return currentCropItem.value?.width;
  }
});

const autoCropHeight = computed(() => {
  const croppedAxis = currentCropItem.value?.croppedData?.axis;

  if (croppedAxis) {
    return croppedAxis.y2 - croppedAxis.y1;
  } else {
    return currentCropItem.value?.height;
  }
});

const cropAxis = computed(() => {
  return currentCropItem.value?.croppedData?.axis;
});

const handleItemClick = async (item: CropAssetData) => {
  isFirstRender.value = true;
  currentCropItem.value = item;
};

const handleIsFirstRender = (isFirst: boolean) => {
  isFirstRender.value = isFirst;
};

const handleImageScale = (scale: number) => {
  isFirstRender.value = false;
};

const handleRealTimePreview = (data: CropRealTimeData) => {
  if (!currentCropItem.value || !cropper.value) {
    return;
  }
  const targetCropItem = currentCropItem.value;
  const cropperComp = cropper.value;
  cropperComp.getCropData((base64url: string) => {
    innerAssets.value.forEach((item) => {
      if (targetCropItem.key == item.key) {
        item.croppedData = {
          url: base64url,
          scale: data.scale,
          width: data.w,
          height: data.h,
          axis: cropperComp.getCropAxis(),
        };
      }
    });
  });
};

const handleConfirmClick = () => {
  const list = unref(innerAssets.value);
  emit("change", list);
  handleCloseClick();
};

const handleCloseClick = () => {
  dialogVisible.value = false;
  emit("close");
};
</script>
<style lang="scss">
.asset-modal {
  .el-dialog__header {
    padding: 0 !important;
    background-color: red;
  }

  .el-dialog__body {
    padding: 0px;
  }

  .el-dialog__footer {
    padding: 24px 0;
    border-top: 1px solid #1f232926;
  }
}

:deep(.el-overlay.asset-modal) {
  background-color: rgba(0, 0, 0, 0.54);
}
</style>
