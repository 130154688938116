<template>
  <div class="cloud-container">
    <div class="header">
      <group-select
        v-model="filters"
        canReset
        :teleported="false"
        :width="230"
        :options="filterOptions"
        @change="filter"
      >
        <icon-button
          border
          name="editor_library_filter"
          :size="18"
          :active="active"
          :color="active ? '#FFFFFF' : '#646A73'"
        />
      </group-select>
    </div>
    <el-scrollbar ref="scrollbar" class="file-scrollbar">
      <el-skeleton animated :loading="loading && page === 1">
        <template #template>
          <el-skeleton-item v-for="(_, i) in Array(40)" variant="rect" :key="i" />
        </template>
        <div 
          class="list-wrapper" 
          v-if="files.length > 0"  
        >
          <div
            class="file-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-immediate="false"
          >
            <div class="file-item" 
              v-for="file in files" 
              :key="file.mid" 
              @click="clickFile(file)"
            >
              <div class="file-content" :data-id="file.mid" >
                <div class="image-wrapper">
                  <div
                    class="image-mask"
                    v-if="0 <= file.fakeProgress && file.fakeProgress < 1"
                  >
                    <span v-if="file.tag !== 'ai'">Uploading</span>
                    <el-progress
                      :percentage="file.fakeProgress * 100"
                      :show-text="false"
                      :color="file.tag === 'ai' ? '#FFE39B' : '#FFFFFF'"
                    />
                  </div>
                  <img
                    v-if="file.coverPic"
                    :src="file.coverPic"
                    loading="lazy"
                  />
                  <svg-icon
                    v-else-if="!file.coverPic && file.type === 'image'"
                    name="editor_thumb_image"
                    color="#95979A"
                    :size="24"
                  />
                  <svg-icon
                    v-else-if="!file.coverPic && file.type === 'video'"
                    name="editor_thumb_video"
                    color="#95979A"
                    :size="24"
                  />
                  <svg-icon
                    v-else-if="!file.coverPic && file.type === 'audio'"
                    name="editor_thumb_music"
                    color="#95979A"
                    :size="24"
                  />
                  <svg-icon
                    v-if="file.tag === 'ai'"
                    class="ai-tag"
                    name="editor_ai_tools"
                    :size="16"
                  />
                </div>
                <span
                  v-show="selectFile === file"
                  class="select-mask"
                >
                  <svg-icon
                    name="script_select_file"
                    :size="24"
                  />
                </span>
                <span
                  v-if="['video', 'audio'].includes(file.type) && file.duration"
                  class="duration"
                  >{{ secondsToHms(file.duration) }}</span
                >
              </div>
              <div class="title">{{ file.name }}</div>
            </div>
          </div>
        </div>
        <empty v-else />
        <loading v-show="loading && page > 1" />
      </el-skeleton>
    </el-scrollbar>
  </div>

</template>

<script setup>
import { getCloudMaterialList } from "@/api/material";
import { fileUploadState } from "@/api/upload";
import { secondsToHms } from "../../utils";
import Loading from "./loading.vue";
import Empty from "./empty.vue";
const filterOptions = [
  {
    label: "Type",
    value: "type",
    items: [
      {
        label: "Image",
        value: "image",
      },
      {
        label: "Video",
        value: "video",
      },
      {
        label: "Audio",
        value: "audio",
      },
    ],
  },
  {
    label: "Source",
    value: "source",
    items: [
      {
        label: "Original",
        value: "original",
      },
      {
        label: "AI generated",
        value: "ai",
      },
    ],
  },
];

const emits = defineEmits([
  "select",
]);

const scrollbar = ref(null);
const files = ref([]);
const page = ref(1);
const total = ref(0);
const timer = ref(null);
const loading = ref(false);
const selectFile = ref(null);
const filters = reactive({ type: new Set(), source: new Set() });
const active = computed(() => filters.type.size > 0 || filters.source.size > 0);

onUnmounted(() => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = null;
});

watch(
  page,
  (newPage) => {
    if (newPage === 1) {
      scrollbar.value?.setScrollTop(0);
    }
    getCloudMaterial(newPage);
  },
  { immediate: true },
);

async function getCloudMaterial(page, size = 40, order) {
  loading.value = true;
  const params = { page, size };

  if (filters.type.size > 0) {
    params.filterTypes = Array.from(filters.type);
  }
  if (filters.source.size > 0) {
    params.source = Array.from(filters.source);
  }
  const response = await getCloudMaterialList(params);
  const { records } = response.data;

  for (const file of records) {
    if (file.state === 2) {
      file.fakeProgress = 0.5;
    } else {
      file.fakeProgress = 1;
    }
  }

  total.value = response.data.total;
  files.value = order
    ? records.concat(files.value)
    : files.value.concat(records);

  for (const file of files.value) {
    if (file.state === 2) {
      getUploadState(file.mid)
        .then((info) => Object.assign(file, info))
        .catch((e) => Object.assign(file, e));
    }
  }
  loading.value = false;
}

async function filter() {
  files.value = [];

  if (page.value === 1) {
    await getCloudMaterial(1);
  } else {
    page.value = 1;
  }
}

function loadMore() {
  if (0 < files.value.length && files.value.length < total.value) {
    page.value++;
  }
}

async function getUploadState(mid) {
  return new Promise((resolve, reject) => {
    const getState = async () => {
      const { success, data } = await fileUploadState(mid);

      if (success) {
        if (data.state === 0) {
          resolve({ ...data, fakeProgress: 1 });
        } else if (data.state === 1) {
          reject({ error: "failure", success: false });
        } else {
          timer.value = setTimeout(getState, 1000);
        }
      } else {
        reject({ error: "failure", success: false });
      }
    };
    getState();
  });
}

function clickFile(file) {
  if (selectFile.value === file) {
    selectFile.value = null;
    return;
  }
  
  selectFile.value = file;

  emits("select", file);
}
</script>

<style lang="scss" scoped>
.cloud-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.file-scrollbar {
  height: calc(100% - 86px);
}

:deep(.file-scrollbar) .el-scrollbar__view{
  min-height: calc(100% + 1px);
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 24px;
  padding: 25px 60px;

}

.file-list,
:deep(.el-skeleton) {
  display: grid;
  flex-wrap: wrap;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(107px, 1fr));
}

.list-wrapper {
  padding: 0 60px;
}

:deep(.el-skeleton) {
  padding: 0 60px;
}

.file-item {
  width: 107px;
}

.file-content {
  margin-bottom: 4px;
  overflow: hidden;
  position: relative;
}

.image-wrapper,
:deep(.el-skeleton__item) {
  width: 107px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f3f5f7;
  border-radius: 4px;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}

.ai-tag {
  position: absolute;
  left: 8px;
  bottom: 10px;
}

.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.image-wrapper .image-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
}

.image-wrapper .image-mask span {
  color: #f8f5ff;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-content .duration {
  padding: 0 8px;
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  right: 8px;
  bottom: 8px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.title {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}
:deep(.icon-button.border) {
  border-radius: 4px;
  border: 1px solid #d5d6d7;
  margin-left: 12px;
}
:deep(.icon-button.border .icon-wapper) {
  padding: 8px;
}
:deep(.icon-button.active) {
  border: 1px solid #fff;
  background-color: #875eff;
}

.select-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.60);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>