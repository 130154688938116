import productToVideoPoster from '../AiFeature/assets/product_to_video.webp';
import ideaToVideoPoster from '../AiFeature/assets/idea_to_video.webp';
import blogToVideoPoster from '../AiFeature/assets/blog_to_video.webp';
import scriptToVideoPoster from '../AiFeature/assets/script_to_video.webp';
import visualToVideoPoster from '../AiFeature/assets/visual_to_video.webp';
import templateToVideoPoster from '../AiFeature/assets/template_to_video.webp';
import scratchPoster from '../AiFeature/assets/scratch.webp';
import aiToolPoster from './assets/ai-tool-poster.png';
import previewData from './assets/videos/index';
import { useRoute } from 'vue-router';

const infoMap: Record<string, {
  preview: string | {
    src: string;
    poster: string
  };
  title: string;
  description: string;
  link: {
    text: string;
    path: string;
  }
}> = {
  ['product-to-video']: {
    preview: productToVideoPoster,
    title: 'Product URL to Video',
    description: `
      Turn your product URL into viral videos in seconds!
      Supports Shopify, Amazon, Etsy, and etc.
    `,
    link: {
      text: 'Try product to video',
      path: '/product-to-video',
    }
  },
  ['idea-to-video']: {
    preview: ideaToVideoPoster,
    title: 'Idea to Video',
    description: `Simply input your ideas to create a dynamic video, with an AI voiceover.`,
    link: {
      text: 'Try idea to video',
      path: '/idea-to-video',
    }
  },
  ['blog-to-video']: {
    preview: blogToVideoPoster,
    title: 'Blog to Video',
    description: `Turn your blog into dynamic videos`,
    link: {
      text: 'Try blog to video',
      path: '/blog-to-video',
    }
  },
  ['script-to-video']: {
    preview: scriptToVideoPoster,
    title: 'Script to Video',
    description: `Convert your scripts to video in one click`,
    link: {
      text: 'Try script to video',
      path: '/script-to-video',
    }
  },
  ['visuals-to-video']: {
    preview: visualToVideoPoster,
    title: 'Visuals to Video',
    description: `Transform images and video clips into dynamic videos`,
    link: {
      text: 'Try visuals to video',
      path: '/visuals-to-video',
    }
  },
  ['template-to-video']: {
    preview: templateToVideoPoster,
    title: 'Templates to Video',
    description: `Choose a professional template to generate great videos with your assets`,
    link: {
      text: 'Try templates to video',
      path: '/template-video-list',
    }
  },
  ['web-editor']: {
    preview: scratchPoster,
    title: 'Start from scratch',
    description: `Start your video creation from scratch with the best online short video editor!`,
    link: {
      text: 'Start creating',
      path: '/editor',
    }
  },
  ['ai-tools']: {
    preview: aiToolPoster,
    title: 'AI image and video editing tools',
    description: 'Take your video creation to the next level by editing your images or videos directly in the editor with AI tools!',
    link: {
      text: 'Try AI Tools',
      path: '/aiTools',
    }
  },
  ['ecommerce-seller']: {
    preview: previewData.ecommerceSeller,
    title: 'Increase Online Reach and Boost Sales with Boolvideo\'s Video Solutions',
    description: 'Boolvideo AI Video Generator makes professional video creation accessible to any ecommerce seller. With powerful automation and intuitive tools, you can efficiently generate amazing product videos, ads, marketing clips and more-no special skills required.',
    link: {
      text: 'Try for Free',
      path: '/workspace',
    },
  },
  ['content-creator']: {
    preview: previewData.contentCreator,
    title: 'Boost Your Content Strategy with Boolvideo to Engage Your Audience',
    description: 'As a content creator, you know the power of video for driving engagement, building an audience, and monetizing your creativity. However, the process of scripting, filming, and editing video content is incredibly time-consuming and costly. This is where Boolvideo can be a total game-changer.',
    link: {
      text: 'Try for Free',
      path: '/workspace',
    },
  },
  ['social-media-marketer']: {
    preview: previewData.marketer,
    title: 'Ignite Your Social Media with Boolvideo\'s Video Solutions',
    description: 'In the scroll-stopping world of social media, engaging video content is the key to capturing attention and driving results. However, constantly creating fresh, thumb-stopping videos for all your social channels is incredibly time and resource-intensive. Boolvideo is here to solve this struggle.',
    link: {
      text: 'Try for Free',
      path: '/workspace',
    },
  },
  ['photographer']: {
    preview: previewData.photographer,
    title: 'Showcase Your Photography and Tell Compelling Visual Stories with Boolvideo',
    description: 'As a photographer, your artistry lies in capturing the world through a unique lens, freezing moments in time and evoking emotions with every frame. However, in today\'s digital age, presenting your work in a captivating and engaging manner often requires more than just a collection of still images.',
    link: {
      text: 'Try for Free',
      path: '/workspace',
    },
  },
  ['artist']: {
    preview: previewData.artist,
    title: 'Elevate Your Artistic Expression with Boolvideo\'s Video Solutions',
    description: 'As an artist, you strive to capture life\'s beauty through a unique lens, expressing emotions and igniting inspiration through your creations. However, in today\'s digital age, static artwork alone may struggle to captivate increasingly distracted audiences. You need to embrace the power of video to bring your art to life, leaving a lasting impression on viewers\' minds.',
    link: {
      text: 'Try for Free',
      path: '/workspace',
    },
  },
  ['affiliate-program']: {
    preview: previewData.marketer,
    title: 'Partner with Boolvideo',
    description: 'Join our affiliate program now to earn a 40% commission on every sale. Share your passion and monetize your influence!',
    link: {
      text: 'Join the Affiliate Program',
      path: 'https://boolvideo.tolt.io/login',
    },
  },
};

export default infoMap;

export const useData = () => {
  const route = useRoute();
  console.log('route.name', route)
  const pageName = (route.name as string).match(/(?:^case-|^feature-)?(.+)/)![1];

  return infoMap[pageName];
};
