<template>
  <p class="error-text" :style="{ 'opacity': innerErrorMsg ? 1 : 0 }" v-if="showError">{{ innerErrorMsg }}</p>
  <div class="input-wrapper">
    <el-input ref="inputRef" class="ignore" v-model="inputUrl" :placeholder="placeholder"
      :class="{ 'error-input': innerErrorMsg }" @input="handleInput" @focus="handleFocus">
    </el-input>
    <div class="case-modal ignore" v-if="showModal" @click="handleSelect">
      <p class="title ignore">{{ title || 'Try this URL' }}</p>
      <div class="content ignore">
        <div class="image ignore" v-if="icon">
          <img :src="icon" />
        </div>
        <p class="ignore">{{ url }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, computed, onMounted } from 'vue'
const props = defineProps({
  title: String,
  url: String,
  icon: String,
  errorMsg: String,
  showCase: Boolean,
  showError: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: 'Enter product URL to generate video'
  }
})

const emit = defineEmits(['update:url'])

const inputRef = ref(null)
const inputUrl = ref('')
const innerErrorMsg = ref('')
const isFocus = ref(false)

const showModal = computed(() => {
  return isFocus.value && !inputUrl.value && props.showCase
})

watch(() => props.errorMsg, (value) => {
  innerErrorMsg.value = value
})

const setErrorMsg = (msg) => {
  innerErrorMsg.value = msg
}

const handleFocus = () => {
  isFocus.value = true
  inputRef.value?.select()
}

const handleBlur = (event) => {
  isFocus.value = false
}

const handleInput = (inputValue) => {
  setErrorMsg('');
  if (inputValue.trim() === '') {
    inputUrl.value = ''
  } else {
    inputUrl.value = inputValue
  }

  emit('update:url', inputUrl.value)
  emit('update:error', '')
}

const handleSelect = async () => {
  inputUrl.value = props.url
  emit('update:url', inputUrl.value)
}

const handleDocumentClick = (event) => {
  const classList = event.target.classList
  if (classList.contains('ignore') || classList.contains('el-input__inner')) {
    isFocus.value = true
  } else {
    handleBlur()
  }
};

onMounted(() => {
  document.addEventListener('click', handleDocumentClick);
});
</script>

<style lang="scss" scoped>
.error-text {
  height: 32px;
  line-height: 22px;
  padding-bottom: 8px;
  width: 100%;
  text-align: left;
  color: #FF5449;
  font-size: 14px;
  transition: opacity 0.2s;
}

.input-wrapper {
  width: 100%;
  position: relative;
}

.case-modal {
  position: absolute;
  width: 100%;
  max-height: 158px;
  background: green;
  top: 66px;
  border-radius: 4px;
  border: 0.5px solid #E5E7EB;
  background: #FFF;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 26px 23px;
  z-index: 2;

  .title {
    color: #000;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .image {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    margin-right: 15px;

    img {
      width: 70px;
      height: 70px;
      border-radius: 4px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #646A73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
  }
}
</style>