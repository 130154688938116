<script setup>
import * as easingFunctions from "@/utils/easing";

const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  active: {
    type: Boolean,
    default: false,
  },
});
const easingCanvas = ref(null);

onMounted(() => {
  const { name } = props;
  const easing = easingFunctions[name];
  const ctx = easingCanvas.value.getContext("2d");
  const startX = 20;
  const endX = 80;
  let startY = 80;
  let endY = 20;

  switch (name) {
    case "easeInElastic":
      startY -= 10; 
      endY -= 10;
      break;
    case "easeOutElastic":
      startY += 10; 
      endY += 10;
      break;
  }
  const amount = startY - endY;
  const duration = endX - startX;

  ctx.beginPath();
  ctx.moveTo(startX, startY);

  for (let i = startX + 1; i <= endX; i++) {
    ctx.lineTo(i, startY - easing(i - startX, 0, amount, duration));
  }
  ctx.stroke();
});
</script>
<template>
  <div class="canvas-container" :class="{ active }">
    <canvas ref="easingCanvas" width="100" height="100"></canvas>
  </div>
</template>
<style>
.easing-item .canvas-container {
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #f3f5f7;
  transition: all 200ms;
  overflow: hidden;
  cursor: pointer;
}
.easing-item .canvas-container.active {
  border-color: #875eff;
}
</style>