<template>
  <div class="space-tabs">
    <div class="space-tab-header">
      <ul class="tab-lables" ref="listRef" @click="handleClickLabel">
        <li
          v-for="item of tabs"
          :key="item.name"
          :class="modelValue === item.name ? 'active' : ''"
          :data-label="item.name"
        >
          {{ item.label }}
        </li>
      </ul>
      <slot name="extra"></slot>
      <div class="active-bar" :style="activeBarStyle"></div>
    </div>
    <div class="space-tab-pane perfect-scrollbar">
      <v-render :vnode="tabs[modelValue].vnode" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import type { VNode } from "vue";

interface TabData {
  name: string;
  label: string;
  vnode: VNode;
}

const props = defineProps({
  tabs: {
    type: Object as PropType<Record<string, TabData>>,
    default: () => [],
  },
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["tab-click"]);
const listRef = ref(null as unknown as HTMLElement);
const activeBarStyle = reactive({
  width: "0",
  transform: "",
});

function handleClickLabel(e: MouseEvent) {
  const target = (e.target as HTMLElement).closest<HTMLElement>(
    ".tab-lables > li[data-label]",
  );

  if (target !== null) {
    emit("tab-click", target.dataset.label);
  }
}

function updateActiveBar() {
  const activeBar = listRef.value.querySelector("li.active") as HTMLElement;

  if (activeBar === listRef.value.firstElementChild) {
    activeBarStyle.width = `${activeBar.clientWidth - 20}px`;
    activeBarStyle.transform = `translateX(${activeBar.offsetLeft}px)`;
  } else if (activeBar === listRef.value.lastElementChild) {
    activeBarStyle.width = `${activeBar.clientWidth - 20}px`;
    activeBarStyle.transform = `translateX(${activeBar.offsetLeft + 20}px)`;
  } else {
    activeBarStyle.width = `${activeBar.clientWidth - 40}px`;
    activeBarStyle.transform = `translateX(${activeBar.offsetLeft + 20}px)`;
  }
}

watch(
  () => props.modelValue,
  () => {
    nextTick(updateActiveBar);
  },
);

onMounted(() => {
  updateActiveBar();
  document.fonts.load("12px Inter-variant").then(() => updateActiveBar());
});
</script>

<style scoped lang="scss">
.space-tabs {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}

.space-tab-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e8e9ec;
}

.tab-lables {
  display: flex;
  align-items: center;
  height: 34px;
  color: #646a73;
  font-size: 16px;
  font-weight: 500;

  & > li {
    padding-inline: 20px;
    cursor: pointer;
  }

  & > li:first-of-type {
    padding-left: 0;
  }

  & > li:last-of-type {
    padding-right: 0;
  }

  & > li.active {
    color: var(--primary-color);
  }
}

.space-tab-pane {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.active-bar {
  position: absolute;
  left: 0;
  bottom: -0.99px;
  height: 1px;
  width: 100px;
  transition:
    width 0.3s,
    transform 0.3s;
  background-color: var(--primary-color);
}
</style>
