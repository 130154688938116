<template>
  <form-item>
    <template #label>
      <p class="flex items-center">Purpose
        <el-tooltip placement="bottom" effect="dark" popper-class="effect-tooltip">
          <template #content>
            <p class="purpose-tip mb-2">Ad: Ideal for commercials, suitable for creating <br /> engaging promotional or
              brand-focused ads.</p>
            <p class="purpose-tip mb-2">Post: Trendy and shareable, perfect for boosting <br /> engagement on social
              platforms.
            </p>
            <p class="purpose-tip">Listing: Ideal for online stores, these videos showcase <br /> products effectively
              on product pages.</p>
          </template>
          <svg-icon name="icon_describe" :size="18" style="margin-left: 6px" class="cursor-pointer"></svg-icon>
        </el-tooltip>
      </p>
    </template>

    <el-select v-model="selectedPurposeText" :popper-append-to-body="false" popper-class="feature-edit-select"
      placeholder="Select" size="large" :suffix-icon="SelectSuffix" :popper-options="{
            modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
          }" @change="handleChangePurpose">

      <el-option v-for="item in purposeData" :key="item.value" :label="item.label" :value="item.value">
        <div class="flex justify-between" :class="{ 'active': purposeId == item.value }">
          <p class="label">{{ item.label }}</p>
          <p v-if="purposeId == item.value"><svg-icon name="icon_option_selected" :size="18"></svg-icon></p>
        </div>
      </el-option>
    </el-select>

    <el-checkbox-group v-model="selectedSubPurposeList" class="checkbox-group">
      <el-checkbox :label="item.label" v-for="item in subPurposeData" :key="item.value" />
    </el-checkbox-group>

  </form-item>
</template>

<script setup>
import { ref, watch } from 'vue'
import FormItem from './form-item.vue'
import SelectSuffix from "@/components/common/selectSuffix.vue";

const props = defineProps({
  value: String,
  size: String,
  defaultPurpose: String,
  purposeList: Array,
  subPurposeList: Array,
})

const emit = defineEmits(['update:value'])

const purposeId = ref('1')
const selectedSubPurposeList = ref(['General showcase'])
const selectedPurposeText = ref('Listing / Product showcase ( 1 )')
const purposeData = ref(props.purposeList)
const subPurposeData = ref(props.subPurposeList)

watch(() => props.subPurposeList, (subPurposeList) => {
  subPurposeData.value = subPurposeList
}, {
  immediate: true,
  deep: true
})


watch(() => purposeId.value, (purposeId) => {
  emit('update:purposeId', purposeId)
}, {
  deep: true
})

watch(() => selectedSubPurposeList.value, (list) => {
  emit('update:value', list)
}, {
  deep: true
})


watch(() => [selectedSubPurposeList.value], (value) => {
  const length = selectedSubPurposeList.value.length > 0 ? ` ( ${selectedSubPurposeList.value.length} ) ` : ''
  const item = purposeData.value.find(item => Number(item.value) == purposeId.value)
  selectedPurposeText.value = item?.label + length
}, {
  deep: true
})

const setSubPurpose = (purposeId) => {
  purposeData.value.forEach(purpose => {
    if (purpose.id == Number(purposeId)) {

      subPurposeData.value = purpose.children?.map((item) => {
        return {
          label: item.name,
          value: item.id + '',
          id: item.id
        };
      });
    }
  })
}


watch(() => props.purposeList, (purposeList) => {
  purposeData.value = purposeList

  if (props.defaultPurpose == 2) {
    selectedSubPurposeList.value = props.value
    purposeId.value = props.defaultPurpose
    setSubPurpose(props.defaultPurpose)
    selectedPurposeText.value = 'Post ( 1 )'
  }
}, {
  immediate: true,
  deep: true
})


watch(() => [purposeId.value, props.size], () => {
  // "1008" "General video to post" 
  //  1007 "YouTube Shorts"
  if (props.size == '16:9' && purposeId.value == 2) {
    subPurposeData.value = subPurposeData.value.filter(item => item.id == 1008 || item.id == 1007)
    const list = ['General video to post', 'YouTube Shorts']
    selectedSubPurposeList.value = selectedSubPurposeList.value.filter(value => list.includes(value));
    return
  }

  setSubPurpose(purposeId.value)
})

const handleChangePurpose = (id) => {
  purposeId.value = id
  setSubPurpose(id)
  selectedSubPurposeList.value = []
}

</script>

<style lang="scss">
@import './common.scss';
</style>

<style lang="scss" scoped>
.purpose-tip {
  line-height: 22px;
  color: #FFF;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
</style>