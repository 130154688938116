<template>
  <div
    class="card-upload"
    @click="$emit('upload', defaultLogoList[listLength % 4])"
  >
    <div class="upload-container">
      <svgIcon name="icon_upload" :size="32" style="color: #646a73" />
      <span>Add</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";

defineEmits(["upload"]);
defineProps({
  listLength: {
    type: Number,
    required: true,
  },
});

const defaultLogoList: string[] = [
  "https://boolv.tech/static/default/default_logo_blue.png",
  "https://boolv.tech/static/default/default_logo_purple.png",
  "https://boolv.tech/static/default/default_logo_green.png",
  "https://boolv.tech/static/default/default_logo_orange.png",
];
</script>

<style lang="scss" scoped>
.card-upload {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.card-upload:hover {
  background-color: #f4f5f7;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid var(--card-border-color);
  border-radius: var(--card-border-radius);

  & > span {
    font-size: 14px;
    margin-top: 16px;
    color: #060606;
  }
}
</style>
