import icon1 from "./assets/process_step1.png";
import icon2 from "./assets/process_step2.png";
import icon3 from "./assets/process_step3.png";

export const steps = [
  {
    icon: icon1,
    title: {
      content: "AI assistant at work",
      offset: [50, -14],
    },
  },
  {
    icon: icon2,
    title: {
      content: "Video processing",
      offset: [0, 36],
    },
  },
  {
    icon: icon3,
    title: {
      content: "Take a while...",
      offset: [30, 24],
    },
  },
];

for (const { icon: iconUrl } of steps) {
  fetch(iconUrl);
}
