<template>
  <import-file/>
</template>

<script setup>
import { onMounted } from 'vue'
import ImportFile from '../commonComponents/ImportFile.vue'
import { sessionRemoveItem } from "@/utils/storage";

onMounted(() => {
  sessionRemoveItem('cacheForm')
})
</script>