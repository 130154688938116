<template>
  <div class="filter-container">
    <div
      class="filter-box"
      v-if="showSource || (typeTagsList && typeTagsList.length > 1)"
    >
      <p class="filter-type">Type</p>
      <bv-tag
        class="tag-item"
        :class="{ 'tag-mr': index !== typeTagsList.length - 1 }"
        bgColor="#875eff"
        v-for="(item, index) in typeTagsList"
        :text="item.name"
        :key="item.value"
        :active="selectedTypeTagIds.includes(item.value)"
        @click="handleTypeTagClick(item)"
      />
    </div>

    <div class="filter-box" v-if="showSource">
      <p class="filter-type">Source</p>
      <bv-tag
        bgColor="#875eff"
        class="tag-item"
        :class="{ 'tag-mr': index !== tagsSourceList.length - 1 }"
        :text="item.name"
        v-for="(item, index) in tagsSourceList"
        :key="item.value"
        @click="handleSourceTagClick(item)"
        :active="selectedSourceTagIds.includes(item.value)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { BvTag } from '@/components/common/index';

const props = defineProps({
  accept: {
    type: String,
    default: 'image',
  },
  filterType: Array,
  filterSource: Array,
  showSource: Boolean,
});

const globalDomain = inject('globalDomain');
const emit = defineEmits(['updateType', 'updateSource']);

const typeTagsList = ref([]);
const selectedTypeTagIds = ref(['image']);
const selectedSourceTagIds = ref([]);

const tagsSourceList = computed(() => {
  if (globalDomain == 1) {
    return [
      {
        name: 'Original',
        value: 'original',
      },
      {
        name: 'AI generated',
        value: 'ai',
      },
    ];
  }

  if (globalDomain == 2) {
    return [
      {
        name: 'Original',
        value: 'original',
      }
    ];
  }
});

watch(
  () => props.filterType,
  (filterType) => {
    selectedTypeTagIds.value = filterType;
  },
  {
    immediate: true,
  }
);

watch(
  () => props.filterSource,
  (filterSource) => {
    selectedSourceTagIds.value = filterSource;
  },
  {
    immediate: true,
  }
);

watch(
  () => props.accept,
  (accept) => {
    const acceptArr = accept && accept.split(',');
    typeTagsList.value = acceptArr.map((item) => {
      return {
        name: item,
        value: item,
      };
    });
  },
  {
    immediate: true,
  }
);

const handleTypeTagClick = (item) => {
  const index = selectedTypeTagIds.value.findIndex((id) => item.value == id);

  if (index < 0) {
    selectedTypeTagIds.value.push(item.value);
    emit('updateType', selectedTypeTagIds.value);
    return;
  }

  if (selectedTypeTagIds.value.length == 1) {
    return;
  }

  selectedTypeTagIds.value.splice(index, 1);
  emit('updateType', selectedTypeTagIds.value);
};

const handleSourceTagClick = (item) => {
  const index = selectedSourceTagIds.value.findIndex((id) => item.value == id);

  if (index < 0) {
    selectedSourceTagIds.value.push(item.value);
    emit('updateSource', selectedSourceTagIds.value);
    return;
  }

  if (selectedSourceTagIds.value.length == 1) {
    return;
  }
  selectedSourceTagIds.value.splice(index, 1);
  emit('updateSource', selectedSourceTagIds.value);
};
</script>

<style lang="scss" scoped>
.filter-container {
  margin-bottom: 14px;
  padding: 0px 20px;
  display: flex;
  align-items: center;

  .filter-box {
    margin-right: 36px;
    display: flex;
    align-items: center;
  }

  .filter-type {
    color: #646a73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-right: 10px;
  }

  .tag-item {
    cursor: pointer;
    font-size: 14px;
    margin-right: 0px;
  }

  .tag-mr {
    margin-right: 10px;
  }
}
</style>
