<template>
  <div class="slide-container relative" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave">
    <!-- tags -->
    <div class="absolute -top-10 w-full">
      <div class="flex mb-3 min-h-30"
        :class="{ 'opacity-0': !innerItem.tags || innerItem.type == 'button', 'justify-start': ratio == '9:16 || 1:1', 'justify-center': ratio == '16:9' }">
        <bv-tag bgColor="#875eff" :text="i" v-for="i in innerItem.tags" :key="i" v-if="isLoaded || innerItem.type == 'button'" />
      </div>
    </div>

    <div v-if="innerItem.type == 'button'"
      class="carousel__item border border-borderColor flex justify-center items-center w-full h-full">
      <ordinary-plain-button class="regenerate-button" padding="16px 10px" round @click="handleRegenerate">
        <span class="text-base text-center font-normal ml-2" :class="{
          'text-tipColor': loading,
          'text-titleColor': !loading,
          'cursor-not-allowed': loading,
        }">Regenerate</span>
        <template #preIcon>
          <svg-icon name="icon_refresh" :class="{ 'animate-spin': loading }" :style="{
            width: '24px',
            height: '24px',
            color: loading ? '#646A73' : '#060606',
          }"></svg-icon>
        </template>
      </ordinary-plain-button>
    </div>

    <div v-else-if="!innerItem.templateUrl && !innerItem.renderConfig"
      class="bg-loadingColor carousel__item flex justify-center items-center animate-pulse relative h-full w-full">
      <svg-icon name="icon_loading" :style="{ width: '24px', height: '24px', color: 'rgb(187, 191, 196)' }"
        class="cursor-pointer animate-spin"></svg-icon>
    </div>

    <div :id="innerItem.rid" class="carousel__item relative h-full" v-else>
      <ai-player ref="videoRenderRef" :config="currentConfig" @loaded="handleLoaded" @onIsPlaying="handleVideoIsPlaying"
        v-if="innerItem.renderConfig" />
    </div>

    <!-- buttons -->
   <div class="absolute h-16 w-full -mt-1 flex items-end render-item-btn" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave" :class="{'showButton': showButton}">
    <render-item-btn :item="innerItem" ref="renderItemBtn" @onEdit="handleEdit" @onExport="handleExport" />
   </div>

    <!-- 导出弹窗 -->
    <edit-export-info-dialog exportType="previewer" :rid="innerItem.rid" :reqId="innerItem.reqId"
      :visible="showExportDialog" @confirm="handleExportSuccess" @close="() => (showExportDialog = false)" />
  </div>
</template>
<script setup lang="ts">
import { ref, type PropType, onBeforeUnmount, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import AiPlayer from "./aiPlayer.vue";
import EditExportInfoDialog from "@/components/common/exportDialog.vue";
import RenderItemBtn from './renderItemBtn.vue'

import type { templateInfo } from "../type";
import {
  BvTag,
  PlainButton,
  OrdinaryPlainButton,
} from "@/components/common/index";

import { useTrackStore } from "@/store/modules/track";
import { getItem } from "@/utils/storage";

const emits = defineEmits(["download", "onIsPlaying", "goBackToFirstPlay"]);
const router = useRouter();
const route = useRoute();

type VideoRenderType = typeof AiPlayer;

const props = defineProps({
  item: {
    type: Object as PropType<templateInfo>,
    default: () => {
      return {};
    },
  },
  loading: Boolean,
  ratio: String,
  index: Number,
  length: {
    type: Number,
    default: 1,
  },
  request: Function,
});


const { collectData, track, getEventData } = useTrackStore();
const currentConfig = ref<string | null>(null)
const showExportDialog = ref(false);
const showButton = ref(false);
const videoRenderRef = ref<VideoRenderType | null>(null);
const isLoaded = ref(false);
const draftId = ref<string | undefined>("");
const intersectionRatio = ref(0)
const innerItem = ref(props.item)
const renderItemBtn = ref(null)

watch(() => props.item, (value) => {
  innerItem.value = value
}, {
  immediate: true
})


const handleLoaded = () => {
  isLoaded.value = true;
};


const handleVideoIsPlaying = (isPlaying: boolean) => {
  emits("onIsPlaying", props.item.rid, isPlaying);
};

const handleMouseMove = () => {
  renderItemBtn.value?.cancelHoverStatus()
  if (!isLoaded.value) {
    return;
  }
  showButton.value = true
};

const handleMouseLeave = () => {
  showButton.value = false
}
const handleEdit = async () => {
  console.log(props.item.draftId);
  const trackHelper = () => {
    const { videoType } = route.query;
    collectData("boolvideo_edit_click", {
      video_type: videoType,
      video_purpose: getEventData("boolvideo_create_input").video_purpose,
      video_subtype: getEventData("boolvideo_create_input").video_subtype,
      rid: props.item.rid,
      reqId: props.item.reqId,
      draftId: props.item.draftId,
    });
    track("boolvideo_edit_click");
  };

  if (!isLoaded.value) {
    return;
  }
  trackHelper();
  try {
    const url = router.resolve({
      path: "/editor",
      query: {
        rid: props.item.rid,
      },
    });

    videoRenderRef.value?.onStop();
    window.open(url.href);

  } catch {
    console.log("error===");
  }
};

// 导出
const handleExport = () => {
  const trackHelper = () => {
    const { videoType } = route.query;
    collectData("boolvideo_export_click", {
      video_type: videoType,
      video_purpose: getEventData("boolvideo_create_input").video_purpose,
      video_subtype: getEventData("boolvideo_create_input").video_subtype,
      editor_type: "direct_export",
      rid: props.item.rid,
      reqId: props.item.reqId,
      draftId: props.item.draftId,
    });
  }
  if (!isLoaded.value) {
    return;
  }
  trackHelper();
  showExportDialog.value = true;
};

// 导出成功
const handleExportSuccess = async (exportDraftId: string) => {
  showExportDialog.value = false;
  draftId.value = exportDraftId;
};

// 生成
const handleRegenerate = () => {
  if (props.loading) {
    return;
  }

  if (props.request) {
    props.request();
  }
};

// 监听videoItem 是否在可视范围内
const observeElement = async () => {
  var opts = {
    // 根容器，root范围不可见停止播放
    root: document.getElementById("carousel__container"),
  };

  var observer = new IntersectionObserver(function (changeArr) {
    changeArr.forEach((change) => {

      if (change.intersectionRatio === 0) {
        // 视频不可见，视频暂停播放播放
        videoRenderRef.value?.onStop();
      } else {
        intersectionRatio.value = change.intersectionRatio
        currentConfig.value = innerItem.value.renderConfig
      }
    });
  }, opts);

  const element = document.getElementById(innerItem.value.rid + "");
  if (!element) {
    return;
  }

  observer.observe(element);
};

watch(
  () => props.item.isPlaying,
  (value) => {
    if (!value) {
      videoRenderRef.value?.onStop();
    }
  },
  {
    immediate: true,
  },
);

onMounted(() => {
  observeElement();
});

onBeforeUnmount(() => {
  if (videoRenderRef.value) {
    videoRenderRef.value.destroy();
  }
});
</script>
<style lang="scss" scoped>
.slide-container {
  width: 100%;
  height: 100%;
}

.carousel__item {
  svg {
    border-radius: 0 !important;
  }
}
.render-item-btn {
  opacity: 0;
  pointer-events: none;
}

.render-item-btn.showButton {
  opacity: 1;
  pointer-events:all;
}
</style>
<style lang="scss">
.btn-container.el-popper.is-dark {
  background: #1F2329 !important;
  border: 1px solid #1F2329;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.btn-container.el-popper.is-dark .el-popper__arrow::before {
  background: #1F2329 !important;
  border: 1px solid #1F2329;
}

.regenerate-button {
  max-width: 194px;
  max-height: 58px;
  width: 95%;

  .el-button {
    width: 100% !important;
  }
}


</style>
