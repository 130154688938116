<template>
  <section class="workflow-part" :class="isAttachBackground ? 'feature-page' : ''">
    <h2>{{ info.title }}</h2>
    <ul class="step-list">
      <li v-for="(step, i) of info.steps">
        <div v-if="step.preview" class="preview-box">
          <img
            v-if="'alt' in step.preview"
            class="step-preview"
            loading="lazy"
            :src="step.preview.src"
            :alt="step.preview.alt"
          />
          <advancedVideo
            v-else
            class="step-preview"
            loop autoplay muted
            :poster="step.preview.poster"
            :src="step.preview.src"
          />
        </div>
        <section :class="['step-info', route.name === 'feature-ai-tools' ? 'no-line' : '']">
          <h3>{{ step.title }}</h3>
          <p>
            <v-render :vnode="trimContent(step.description)" />
          </p>
          <StarButton
            v-if="step.link"
            :link="step.link.path"
          >
            {{ step.link.text }}
          </StarButton>  
        </section>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { type VNode } from 'vue';
import { useData } from './data';
import { useRoute } from 'vue-router';
import StarButton from '../star-button.vue';

const info = useData();
const route = useRoute();
const isAttachBackground = (route.name as string).startsWith('feature-') && route.name !== 'feature-ai-tools';

const trimContent = (content: string | VNode) => {
  if (typeof content === 'string') {
    return content.trim();
  } else {
    return content;
  }
};
</script>

<style scoped lang="scss">
.workflow-part {
  padding: 120px calc((100% - var(--view-width)) / 2) 240px;
  
  & > h2 {
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    white-space: pre-line;
  }
}

.feature-page {
  & > h2 {
    font-size: 48px;
  }

  & > .step-list {
    margin-top: 98px;
  }

  .preview-box {
    background-image: linear-gradient(113deg, #6941FF 0.03%, #9C71FF 105.81%);
    padding: 32px 45px;

    & > .step-preview {
      border-radius: 6px;
    }
  }
}

.step-list {
  margin-top: 108px;

  & > li {
    position: relative;
    display: flex;
    gap: 108px;

    &:not(:last-child) {
      margin-bottom: 70px;
    }

    &:last-child > .step-info::before {
      display: none;
    }
  }
}

.preview-box {
  border-radius: 12px;
}

.step-preview {
  width: 625px;
  border-radius: 12px;
  object-position: center;
}

.step-info {
  & > h3 {
    font-size: 40px;
    font-weight: 600;
  }

  & > p {
    margin-top: 16px;
    font-size: 20px;
    white-space: pre-line;

    & > :deep(a) {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  & > button {
    position: absolute;
    bottom: 0;
  }

  &:not(.no-line) {
    &::before {
      content: '';
      position: absolute;
      top: 23px;
      width: 1px;
      height: calc(100% + 70px);
      background-color: #E5E7EB;
      transform: translateX(-30px);
    }
  
    &::after {
      content: '';
      position: absolute;
      top: 23px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #E5E7EB;
      transform: translateX(-36px);
    }
  }

}
</style>
