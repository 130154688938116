<template>
  <div class="matting-box w-full h-full" ref="mattingBoxRef">
    <div class="matting-box-inner" @mouseenter="handleMouseEnter">
      <canvas
        class="matting-board"
        ref="mattingCvsRef"
        id="matting-board-canvas"
        :data-zoomRatio="zoomRatio"
        :width="width"
        :height="height"
        :style="{
          width: (width / 2) * zoomRatio + 'px',
          height: (height / 2) * zoomRatio + 'px',
        }"
      >
      </canvas>
      <div class="matting-cursor-container" v-if="!isDrag">
        <div
          class="matting-cursor"
          :style="{
            ...mattingCursorStyle,
            width: `${radius * 2}px`,
            height: `${radius * 2}px`,
          }"
        ></div>
      </div>
      <!-- <img
        v-if="!isDrag"
        class="matting-cursor"
        :style="mattingCursorStyle"
        :src="cursorImage"
      /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  type Ref,
  defineProps,
  onMounted,
  watchEffect,
  defineExpose,
} from "vue";
import {
  useMattingBoard,
  computeValidImageSize,
} from "./composables/use-matting-board";
import useMattingCursor from "./composables/use-matting-cursor";
import { generateResultImage } from "./helpers/dom-helper";

export interface MattingBoardProps {
  rawImage: ImageData | null;
  mattingImage: ImageData | null;
  isErasing: boolean;
  radius: number;
  hardness: number;
  zoomRatio: number;
  isDrag: boolean;
}

const props = withDefaults(defineProps<MattingBoardProps>(), {
  rawImage: null,
  mattingImage: null,
  isErasing: true,
  radius: 1,
  hardness: 1,
  zoomRatio: 1,
});

const emits = defineEmits(["update", "onMouseEnterCanvas", "onInit"]);
const mattingBoxRef: Ref<null | HTMLDivElement> = ref(null);
const mattingCvsRef: Ref<null | HTMLCanvasElement> = ref(null);

const sessionId = ref(Math.random());
const rawImage: Ref<null | ImageData> = ref(props.rawImage);
const mattingImage: Ref<null | ImageData> = ref(props.mattingImage);
const isErasing = ref(props.isErasing);
const radius = ref(props.radius);
const hardness = ref(props.hardness);
const zoomRatio = ref(props.zoomRatio);
const isDrag = ref(props.isDrag);

watch(
  () => props.rawImage,
  (value) => (rawImage.value = value),
);
watch(
  () => props.mattingImage,
  (value) => (mattingImage.value = value),
);
watch(
  () => props.isErasing,
  (value) => (isErasing.value = value),
);
watch(
  () => props.radius,
  (value) => {
    radius.value = value;
  },
);
watch(
  () => props.hardness,
  (value) => (hardness.value = value),
);
watch(
  () => props.zoomRatio,
  (value) => (zoomRatio.value = value),
);
watch(
  () => props.isDrag,
  (value) => (isDrag.value = value),
);

let backPivot = -1;
const historySnapshots: ImageData[] = [];

const drawingEndCallback = async () => {
  // 当前若是撤销过，一旦操作则移除后续的记录
  if (backPivot >= 0) {
    historySnapshots.splice(backPivot);
    backPivot = -1;
  }
  const ctx = inputHiddenCtx.value;
  const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  historySnapshots.push(imageData);
  notifyStatusUpdate();
};

const {
  width,
  height,
  inputCtx,
  imageSources,
  inputHiddenCtx,
  draggingInputBoard,
} = useMattingBoard({
  sessionId,
  rawImage,
  mattingImage,
  isErasing,
  radius,
  hardness,
  zoomRatio,
  isDrag,
  drawingEndCallback,
});

const { cursorImage, mattingCursorStyle, renderOutputCursor } =
  useMattingCursor({
    inputCtx,
    isDragging: draggingInputBoard,
    isErasing,
    radius,
    hardness,
    zoomRatio,
  });

onMounted(() => {
  initContextsAndSize();
  renderOutputCursor();

  window.onresize = () => {
    initContextsAndSize();
  };
});

watch(
  () => mattingImage.value,
  () => {
    initContextsAndSize();
  },
);

let boxWidth = 0;
let boxHeight = 0;

function initContextsAndSize() {
  if (!boxWidth || !boxHeight) {
    const mattingCanvas = mattingCvsRef.value as HTMLCanvasElement;
    inputCtx.value = mattingCanvas.getContext("2d");
    const mattingBox = mattingBoxRef.value as HTMLDivElement;
    boxWidth = mattingBox.clientWidth;
    boxHeight = mattingBox.clientHeight;
  }

  if (mattingImage.value) {
    const validImageSize = computeValidImageSize(
      mattingImage.value,
      { width: boxWidth, height: boxHeight },
      { horizontal: 0, vertical: 0 },
    );

    width.value = validImageSize.width * 2;
    height.value = validImageSize.height * 2;
    emits("onInit");
  }
}

function notifyStatusUpdate() {
  const canUndo = canUndoMatting();
  const canRedo = canRedoMatting();
  emits("update", { canUndo, canRedo });
}

function resetMattingBoard() {
  sessionId.value = Math.random();
}

function getMattingResult() {
  if (imageSources.value) {
    return generateResultImage(imageSources.value.raw, inputHiddenCtx.value);
  }
}

/** 能否撤销 */
function canUndoMatting() {
  return backPivot != 0;
}

/** 能否恢复 */
function canRedoMatting() {
  return backPivot >= 0 && backPivot < historySnapshots.length;
}

/** 撤销 */
function undoMatting() {
  if (!canUndoMatting()) {
    return;
  }

  let newBackPivot;
  if (backPivot < 0) {
    newBackPivot = historySnapshots.length - 1;
  } else {
    newBackPivot = backPivot - 1;
  }

  // 通过快照记录恢复时，需先置为null再设置，避免刷新失效
  mattingImage.value = null;

  const snapshot = historySnapshots[newBackPivot - 1];
  if (snapshot) {
    mattingImage.value = snapshot;
  } else {
    mattingImage.value = props.mattingImage;
  }
  backPivot = newBackPivot;
  notifyStatusUpdate();
}

/** 恢复 */
function redoMatting() {
  if (!canRedoMatting()) {
    return;
  }

  let newBackPivot = backPivot + 1;
  const snapshot = historySnapshots[newBackPivot - 1];
  if (snapshot) {
    mattingImage.value = snapshot;
  }
  backPivot = newBackPivot;
  notifyStatusUpdate();
}

function handleMouseEnter() {
  emits("onMouseEnterCanvas", true);
}

defineExpose({
  width,
  height,
  resetMattingBoard,
  getMattingResult,
  undoMatting,
  redoMatting,
});
</script>

<style lang="scss">
.matting-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .matting-box-inner {
    position: relative;
    max-width: 100%;
    max-height: 100%;

    .matting-board {
      background-color: #fff;
      background-image: linear-gradient(45deg, #ccc 25%, transparent 0),
        linear-gradient(45deg, transparent 75%, #ccc 0),
        linear-gradient(45deg, #ccc 25%, transparent 0),
        linear-gradient(45deg, transparent 75%, #ccc 0);
      background-position:
        0 0,
        6px 6px,
        6px 6px,
        12px 12px;
      background-size: 12px 12px;
    }

    .matting-cursor {
      pointer-events: none;
      display: none;
      position: absolute;
      left: -9999px;
      top: -9999px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: rgba(204, 255, 0, 0.65);
      border-radius: 9999px;
    }
  }
}

.matting-cursor-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
}
</style>
