<template>
  <el-popover 
    popper-class="script-effect-popper"
    width="356"
    placement="bottom-end"
    :visible="effectsActive"
    :show-arrow="false"
    :popper-options="{ modifiers: [{ name: 'offset', options: { offset: [0, 4] }}]}"
  >
    <template #reference>
      <icon-button
        name="script_effect"
        tip="Effect"
        :size="18"
        :tipDelay="0"
        @click="effectsActive = !effectsActive"
      />
    </template>
    <el-scrollbar 
      v-click-outside="() => effectsActive = false"
    >
      <el-skeleton animated :loading="effectsLoading">
        <template #template>
          <div v-for="(_, i) in Array(20)" class="skeleton-item" :key="i">
            <el-skeleton-item variant="rect" />
            <el-skeleton-item variant="text" />
          </div>
        </template>
        <div class="effect-list">
          <div 
            v-for="(effect, i) in effects" :key="i" 
            class="effect-item" 
            @mouseenter="hoverEffect = effect" 
            @mouseleave="hoverEffect = null"
          >
            <div
              class="image-wrapper"
              :class="{ 'is-placeholder': !effect.coverPic }"
              @click="replaceEffect(effect)"
              :style="{ pointerEvents: processEffect? 'none': 'auto' }"
            >
              <div 
                class="effect-loading"
                v-if="processEffect === effect"
              >
                <svg-icon
                  name="icon_loading"
                  color="#F8F5FF"
                  :size="18"
                />
              </div>
              <div 
                class="effect-no"
                v-if="effect.name === 'No effect'"
              >
                <svg-icon
                  name="script_null"
                  color="#BBBFC4"
                  :size="24"
                />
              </div>
              <img
                v-else-if="effect.coverPic"
                :src="hoverEffect === effect ? effect.dynamicPic : effect.coverPic"
                draggable="false"
              />
              <svg-icon
                v-else
                name="editor_thumb_image"
                color="#95979A"
                :size="24"
              />
            </div>
            <div class="title">
              <bv-tip
                :content="effect.name"
              >
                {{ effect.name }}
              </bv-tip>
            </div>
          </div>
        </div>
      </el-skeleton>
    </el-scrollbar>
  </el-popover>
</template>

<script setup>
import { parseNode } from "../../utils/scene";
import { observeNode } from "../../utils/observe";
import { useDraftStore } from "../../stores/draft";
import { useScriptStore } from "../../stores/script";
import { useResourceStore } from "../../stores/resources";

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
  node: {
    type: Object,
  },
});

const {
  creator,
  pause,
  refresh,
  removeNode,
  addEffectNode,
} = useScriptStore();
const { 
  getEffects, 
  effectsLoading
} = useResourceStore();
const { updateDraft } = useDraftStore();

const effects = ref([]);
const effectsActive = ref(false);
const hoverEffect = ref(null);
const processEffect = ref(null);

watch(
  effectsActive,
  (value) => {
    if (value) {
      getEffects().then(res => {
        const nullEffect = {
          id: 0,
          name: "No effect",
        };
        effects.value = [ nullEffect, ...res ];
      });
    }
  },
  {
    once: true,
  }
);

const replaceEffect = async (e) => {
  pause();

  const { nodes, start, end, sceneId } = props.scene;
  const { effect } = parseNode(nodes);
  if (effect && effect.conf.sourceId == e.id) return ;
  processEffect.value = e;

  if (effect && e.name === "No effect") {
    removeNode(effect);
  }
  else {
    const { 
      name,
      blend, 
      width480,
      width1080,
      url: src, 
      preview1080Url: url1080,  
      id: sourceId, 
    } = e;
    const materialMeta = { width480, width1080, url1080 };
    if (effect) {
      removeNode(effect);
    }
    await addEffectNode({ name, src, blend, sourceId, materialMeta, start, end, sceneId });
    triggerRef(creator);
  }
  
  nextTick(refresh);
  updateDraft();
  processEffect.value = null;
};

const setup = () => {
  if (props.node) {
    const { node } = props;
    observeNode(node);
  }
};

onMounted(setup);
</script>

<style lang="scss">
.script-effect-popper.el-popper.el-popper {
  height: 427px;
  border-radius: 4px;
  border: 1px solid #E5E7EB;
  background: #FFF;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.12);
  padding: 0;
}

.script-effect-popper {
  & .effect-list, .el-skeleton {
    padding: 18px;
    display: grid;
    flex-wrap: wrap;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  }

  & .effect-item {
    width: 70px;
  }

  & .skeleton-item {
    display: flex;
    flex-wrap: wrap;
  }

  & .effect-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    background-color: #000000;

    & > svg {
      animation: rotate 1s linear infinite;
    }
  }

  & .image-wrapper, .el-skeleton__rect {
    position: relative;
    width: 70px;
    height: 70px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 4px;
    background: #ffffff;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color 200ms;
    cursor: pointer;
  }

  & .image-wrapper.is-placeholder {
    background-color: #f3f5f7;
  }

  & .el-skeleton__text {
    margin: 4px 0;
    height: 12px;
  }

  & .image-wrapper:hover {
    border-width: 2px;
    border-color: #875eff;
  }

  & .image-wrapper img, .image-wrapper .effect-no {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .effect-no {
    display: flex;
    align-items: center;
    justify-content: center;  
    background: #F3F5F7;
  }

  .title {
    margin-top: 6px;
    padding: 0 2px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: #646a73;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
  }
}
</style>