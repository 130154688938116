<template>
  <div class="hue flex justify-between items-center text-xs isPick">
    <color-item
      class="color-item mr-2 isPick"
      :size="24"
      :value="value"
      :border="true"
      :border-radius="1"
    />
    <div class="relative rounded-sm isPick" @mousedown.prevent.stop="onSelect">
      <canvas ref="canvas" :style="{ borderRadius: '1px' }" />
      <div
        :style="{
          position: 'absolute',
          height: '12px',
          background: '#fff',
          top: '0px',
          pointerEvents: 'none',
          boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.3)',
          ...sliderStyle,
        }"
      ></div>
      <!-- <svg-icon name="icon_slider" :style="{
        height: '20px',
        position: 'absolute',
        top: '20%',
        ...sliderStyle
      }"></svg-icon> -->
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import ColorItem from "../color-item";
export default defineComponent({
  name: "Hue",
  props: {
    width: {
      type: Number,
      default: 156,
    },
    height: {
      type: Number,
      default: 12,
    },
    value: {
      type: String,
      default: "",
    },
    hue: {
      type: Number,
      default: 0,
    },
  },
  emits: ["input", "change"],
  setup(props, { emit }) {
    const sliderWidth = 4;
    const sliderWidthHalf = sliderWidth / 2;
    const sliderStyle = computed(() => {
      return {
        left: `${(1 - props.hue / 360) * props.width - sliderWidthHalf}px`,
        width: `${sliderWidthHalf}px`,
      };
    });
    const canvas = ref<HTMLCanvasElement>();
    const renderHue = () => {
      canvas.value.width = props.width;
      canvas.value.height = props.height;
      const ctx = canvas.value.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, props.width, 0);
      gradient.addColorStop(0, "#FF0000"); // red
      gradient.addColorStop(0.17, "#FF00FF"); // purple
      gradient.addColorStop(0.17 * 2, "#0000FF"); // blue
      gradient.addColorStop(0.17 * 3, "#00FFFF"); // green
      gradient.addColorStop(0.17 * 4, "#00FF00"); // green
      gradient.addColorStop(0.17 * 5, "#FFFF00"); // yellow
      gradient.addColorStop(1, "#FF0000"); // red
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, props.width, props.height);
    };

    onMounted(() => {
      renderHue();
    });
    const onSelect = (e: MouseEvent) => {
      const target = e.target as HTMLCanvasElement;
      const { left } = target.getBoundingClientRect();

      const getHue = (e: MouseEvent) => {
        const { clientX } = e;
        let x = clientX - left;

        if (x < 0) x = 0;
        if (x > props.width) x = props.width;
        // 因为是反向的，所以负数
        const percent = -((x * 100) / props.width) + 100;
        return (360 * percent) / 100;
      }

      const onSelectMoving = (e: MouseEvent) => {
        emit("input", getHue(e));
      };
      const onSelectEnd = (e: MouseEvent) => {
        document.removeEventListener("mousemove", onSelectMoving);
        document.removeEventListener("mouseup", onSelectEnd);
        emit("change", getHue(e));
      };
      // 单点击选择
      onSelectMoving(e);
      // 选择移动
      document.addEventListener("mousemove", onSelectMoving);
      // 选择结束
      document.addEventListener("mouseup", onSelectEnd);
    };
    return {
      canvas,
      sliderStyle,
      onSelect,
    };
  },
});
</script>

<style scoped lang="scss">
.hue {
  position: relative;
  margin-top: 8px;
}

.slider {
  height: 12px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  z-index: 1;
  pointer-events: none;
}
</style>
