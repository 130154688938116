<script setup>
import { getProjectMaterialList, deleteProjectMaterial } from '@/api/material';
import { useNetwork } from '@/composables';
import { usePermission } from '../../composables';
import { useMessage, DataUnit, generateGID } from '@/utils';
import { IMAGETYPE, VIDEOTYPE, AUDIOTYPE } from '@/utils/type.ts';
import { useModalManager } from '@/components/common/custom-modal/instance';
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from '../../stores';
import { useTrackStore } from '@/store/modules/track';
import { secondsToHms } from '../../utils';

const { permission } = usePermission();
const globalDomain = inject("globalDomain");

const accept = [
  {
    types: IMAGETYPE.split(","),
    maxSize: 10 * DataUnit.MB,
  },
  {
    types: VIDEOTYPE.split(","),
    maxSize: 200 * DataUnit.MB,
  },
  {
    types: AUDIOTYPE.split(","),
    maxSize: 50 * DataUnit.MB,
  },
];

if (permission.value) {
  accept.push({
    types: ['.zip'],
    maxSize: 200 * DataUnit.MB,
  });
}
const uploader = ref(null);
const files = ref([]);
const { collectData, track } = useTrackStore();
const route = useRoute();
const {
  getXY,
  mediaTab,
  showMaterial,
  updateProject,
  updateCloud,
  resources,
  addImageNode,
  addVideoNode,
  addAudioNode,
  addEffectNode,
  removeNode,
  secondToFrame,
} = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const modalManager = useModalManager();
const message = useMessage();
const { assertNetworkError } = useNetwork();
const { dragData } = useDrag();

const currentFile = ref(null);
const notShowAITip = ref(false);
const droppable = ref(false);
const page = ref(1);
const total = ref(0);

watch(
  [page, () => route.query.draftId],
  ([newPage]) => {
    getProjectMaterial(newPage);
  },
  { immediate: true }
);
watch(updateProject, () => {
  showMaterial.value = true;
  getProjectMaterial(1, 1, true);
});

function submit() {
  commit();
  updateDraft();
}

async function getProjectMaterial(page, size = 20, order) {
  const { draftId } = route.query;

  if (draftId) {
    const params = { page, size, draftId };
    const response = await getProjectMaterialList(params);
    const { records } = response.data;

    total.value = response.data.total;
    files.value = order
      ? records.concat(files.value)
      : files.value.concat(records);

    for (const file of files.value) {
      if (file.state > 1) {
        uploader.value
          ?.getUploadState(file)
          .then((info) => Object.assign(file, info))
          .catch((e) => Object.assign(file, e));
      }
    }
    if (order) {
      for (let i = 0; i < records.length; i++) {
        const file = files.value[i];

        if (file.type === 'video' && file.tag === 'ai') {
          currentFile.value = file;
          break;
        }
      }
    }
  }
}

function loadMore() {
  if (0 < files.value.length && files.value.length < total.value) {
    page.value++;
  }
}

function handleUploadSuccess(file) {
  updateCloud.value++;
  collectData('boolvideo_upload_media', {
    media_type: file.type,
    access: 'timeline_editor',
  });
  track('boolvideo_upload_media');
}

function handleBeforeAddFiles(files) {
  collectData('boolvideo_upload_media', {
    is_batch_upload: files.length > 1,
  });
}

async function addAsset(file) {
  assertNetworkError();

  if (file.previewUrl) {
    const {
      name,
      type,
      duration,
      width480,
      width1080,
      coverPic,
      preview1080Url: hdUrl,
      previewUrl: src,
    } = file;
    const materialMeta = { width480, width1080, url1080: hdUrl };
    const newDuration = secondToFrame(duration);

    switch (type) {
      case 'image':
        await addImageNode({ name, hdUrl, src, materialMeta });
        break;
      case 'video':
        await addVideoNode({
          name,
          hdUrl,
          src,
          coverPic,
          materialMeta,
          duration: newDuration,
          transparent: file.aiType === 'videoBgRemove',
        });
        break;
      case 'audio':
        await addAudioNode({ name, src, duration: newDuration });
        break;
      case 'effect':
        await addEffectNode({ name, src });
        break;
    }
    submit();
  }
}

function handleDrag(e, target, file) {
  const dragElement = target.querySelector('[drag-target]').cloneNode(true);
  dragElement.style.backgroundColor = 'rgba(100,100,100,.12)';
  const {
    type,
    name,
    duration,
    aiType,
    coverPic,
    width480,
    width1080,
    previewUrl: src,
    preview1080Url: hdUrl,
  } = file;
  const materialMeta = { width480, width1080, url1080: hdUrl };

  const getRestConfig = () => {
    switch (type) {
      case 'image':
        return { ...getXY(), hdUrl, materialMeta, fit: 'contain' };
      case 'video':
        return {
          ...getXY(),
          hdUrl,
          materialMeta,
          duration,
          coverPic,
          fit: 'contain',
          transparent: aiType === 'videoBgRemove',
        };
      case 'audio':
        return { duration };
      case 'effect':
        return { ...getXY(), loop: true };
      default:
        return {};
    }
  };

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      type,
      name,
      src,
      ...getRestConfig(),
    },
  });
}

function handleMouseDown(e) {
  const target = e.target.closest('.file-content[candrag]');

  if (target === null || e.button !== 0) return;
  const file = files.value.find(({ mid }) => mid === target.dataset.id);

  const mouseUpListener = () => {
    addAsset(file);
    target.removeEventListener('mousemove', moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener('mouseup', mouseUpListener);
    handleDrag(e, target, file);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  if (!file.error && file.previewUrl) {
    target.addEventListener('mousemove', moveListener, { once: true });
  }

  target.addEventListener('mouseup', mouseUpListener, { once: true });
}

function showDeleteModal(option) {
  modalManager.applyTemplate('warning', {
    icon: null,
    title: 'Delete selected items?',
    key: 'delete-material-modal',
    content:
      'If selected items have been added to the track, they will also be deleted from the track.',
    confirmText: 'Delete',
    onConfirm: () => deleteMaterial(option),
  });
}

async function deleteMaterial(option) {
  const { draftId } = route.query;
  const nodes = resources.get(option.url);

  await deleteProjectMaterial(option.id, { draftId });
  files.value.splice(option.index, 1);
  total.value -= 1;
  if (nodes) {
    for (const node of nodes) {
      removeNode(node);
    }
  }
  message.success('Deleted');
  submit();
}

function dragLeave(e) {
  if (!e.target.contains(e.relatedTarget)) {
    droppable.value = false;
  }
}

function handleCheckFail(file) {
  let uploadEventName =
    globalDomain == 1
      ? 'boolvideo_upload_project_media'
      : 'similarvideo_upload_project_media';

  collectData(uploadEventName, {
    media_type: file.type,
    name: file.name,
    size: formatBytes(file.size),
    access: 'timeline_editor',
  });

  track(uploadEventName);
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
</script>
<template>
  <el-scrollbar>
    <div class="upload">
      <bv-uploader
        v-model="files"
        ref="uploader"
        :accept="accept"
        @beforeAddFiles="handleBeforeAddFiles"
        @success="handleUploadSuccess"
        @checkFail="handleCheckFail"
      />
      <el-button size="large" @click="uploader?.handleClickUpload">
        <svg-icon name="editor_plus" color="#1C1B1E" :size="18" />
        <span>Upload media</span>
      </el-button>
    </div>
    <div v-if="files.length === 0" class="empty">
      <div
        class="empty-wapper"
        :class="{ droppable }"
        @mouseenter="droppable = true"
        @mouseleave="droppable = false"
        @drop.stop.prevent="uploader?.handleDropUpload"
        @dragover.stop.prevent="droppable = true"
        @dragleave.stop.prevent="dragLeave"
        @click="uploader?.handleClickUpload"
      >
        <p>Drop or click here to upload</p>
        <p>video, image, audio</p>
      </div>
    </div>
    <div
      v-else
      class="file-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-immediate="false"
      @mousedown="handleMouseDown"
    >
      <div
        class="file-item"
        v-for="(file, index) in files"
        :key="generateGID(file)"
        :class="{ active: file.previewUrl }"
      >
        <bv-contextmenu
          :options="[
            {
              index,
              id: file.mid,
              url: file.previewUrl,
              label: 'Delete',
              onClick: showDeleteModal,
            },
          ]"
          :width="126"
        >
          <div>
            <bv-guide
              name="mat_guide"
              title="Video processing"
              description="It will take approximately 1 min to process your full video."
              :visible="
                currentFile !== null &&
                file.mid === currentFile.mid &&
                mediaTab === 'project'
              "
              :disappear="notShowAITip"
              @click="currentFile = null"
            >
              <template #reference>
                <div class="file-content" candrag :data-id="file.mid">
                  <div class="image-wrapper" drag-target>
                    <div
                      class="image-mask error"
                      v-if="file.state === 1"
                      @click="uploader?.reupload(file.id)"
                    >
                      <svg-icon name="editor_upload_warnning" :size="24" />
                      <span>Click retry</span>
                    </div>
                    <div class="image-mask" v-else-if="file.state > 1">
                      <span v-if="file.tag !== 'ai'">Uploading</span>
                      <el-progress
                        :percentage="file.progress"
                        :show-text="false"
                        :color="file.tag === 'ai' ? '#FFE39B' : '#FFFFFF'"
                      />
                    </div>
                    <img
                      v-if="file.coverPic"
                      :src="file.coverPic"
                      loading="lazy"
                      draggable="false"
                    />
                    <svg-icon
                      v-else-if="!file.coverPic && file.type === 'image'"
                      name="editor_thumb_image"
                      color="#95979A"
                      :size="24"
                    />
                    <svg-icon
                      v-else-if="!file.coverPic && file.type === 'video'"
                      name="editor_thumb_video"
                      color="#95979A"
                      :size="24"
                    />
                    <svg-icon
                      v-else-if="!file.coverPic && file.type === 'audio'"
                      name="editor_thumb_music"
                      color="#95979A"
                      :size="24"
                    />
                    <svg-icon
                      v-if="file.tag === 'ai'"
                      class="ai-tag"
                      name="editor_ai_tools"
                      :size="16"
                    />
                  </div>
                  <span
                    v-if="
                      ['video', 'audio'].includes(file.type) && file.duration
                    "
                    class="duration"
                    >{{ secondsToHms(file.duration) }}</span
                  >
                </div>
                <div class="title">{{ file.name }}</div>
              </template>
              <template #extra>
                <el-checkbox
                  v-model="notShowAITip"
                  label="Do not remind again"
                  size="large"
                />
              </template>
            </bv-guide>
          </div>
        </bv-contextmenu>
      </div>
    </div>
  </el-scrollbar>
</template>
<style scoped>
.upload {
  width: 100%;
  margin: 22px 0;
  padding: 0 16px;
}
:deep(.el-button.el-button--large) {
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1c1b1e;
  background-color: #ffffff;
}
:deep(.el-button.el-button--large svg) {
  margin-right: 4px;
}
.empty {
  height: calc(100% - 80px);
  padding: 0 16px 27px;
}
.empty .empty-wapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  border-radius: 8px;
  transition: border-color 200ms;
  border-width: 1px;
  border-style: dashed;
  border-color: transparent;
  position: relative;
  cursor: pointer;
}

.empty-wapper.droppable {
  border-color: #dac9ff;
}
.empty-wapper p {
  color: #8f959e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.file-list {
  display: grid;
  flex-wrap: wrap;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(107px, 1fr));
  padding: 0 16px;
}

.file-item {
  width: 107px;
}

.file-content {
  margin-bottom: 4px;
  overflow: hidden;
  position: relative;
}

.image-wrapper {
  width: 107px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #f3f5f7;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}

.ai-tag {
  position: absolute;
  left: 8px;
  bottom: 10px;
}

.image-wrapper:hover {
  border-color: #875eff;
}

.file-content .duration {
  padding: 0 8px;
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  right: 8px;
  bottom: 8px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.image-wrapper .image-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 4px;
  border: 1px solid #ffffff;
}

.image-wrapper .image-mask.error svg {
  margin-bottom: 6px;
}

.image-wrapper .image-mask.error span {
  margin-bottom: 0;
}

.image-wrapper .image-mask span {
  color: #f8f5ff;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 8px;
}

.image-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.title {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}
</style>
<style>
.el-popover.guide-popper .el-checkbox__label {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.el-popover.guide-popper .el-checkbox__label {
  padding-left: 12px;
}
.el-popover.guide-popper .el-checkbox.el-checkbox--large {
  height: auto;
}
.el-popover.guide-popper .el-checkbox.el-checkbox--large .el-checkbox__inner {
  width: 16px;
  height: 16px;
  background-color: transparent;
}
.el-popover.guide-popper .el-checkbox__inner::after {
  width: 5px;
  height: 10px;
  border-radius: 1px;
  top: -1px;
  left: 3.5px;
  border-width: 2px;
}
.el-popover.guide-popper .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #875eff;
}
</style>
