import { _get, _post, _put, _singleGet } from "@/utils/https";
const { VITE_API_BASE } = import.meta.env;

interface RenderVideoFromIdeaParams {
  language: string;
  duration: number;
  prompt: string;
  size: string;
}

interface GetUpdateSceneInfoParams {
  language: string;
  size: string;
  text: string;
  voiceName: string;
  voiceStyle: string;
  autoTranslation?: boolean;
}

interface GetAiSceneInfoParams {
  language: string;
  aiSceneIdx: number;
  draftId: string;
  size: string;
  voiceName: string;
  voiceStyle: string;
  text?: string;
}

interface GetNewSceneInfoParams {
  language: string;
  size: string;
  text: string;
  voiceName: string;
  voiceStyle: string;
}

interface CreateNewVersionParams {
  draftId: string;
  duration: number;
  language: string;
  renderConfig: string;
}

interface RenderVO {
  draftId: string;
  draftType: number;
  ratio: string;
  reqId: string;
  version: number;
}

interface RenderVideoFromBlogParams {
  blogUrl: string;
  duration: number;
  language: string;
  size: string;
}

interface RenderVideoFromScriptParams {
  blogUrl: string;
  duration: number;
  language: string;
  size: string;
}

export const renderVideoFromBlog = async (params: RenderVideoFromBlogParams) => {
  return await _post<RenderVO>( VITE_API_BASE + "/video/render/blog", params);
}

export const renderVideoFromScript = async (params: RenderVideoFromScriptParams) => {
  return await _post<RenderVO>( VITE_API_BASE + "/video/render/script", params);
}

export const renderVideoFromIdea = async (params: RenderVideoFromIdeaParams) => {
  return await _post( VITE_API_BASE + "/video/render/idea", params);
};

export const getUpdateSceneInfo = async (params: GetUpdateSceneInfoParams) => {
  return await _post( VITE_API_BASE + "/voice/update_scene", params);
};

export const getAiSceneInfo = async (params: GetAiSceneInfoParams) => {
  return await _post( VITE_API_BASE + "/voice/ai_scene", params);
};

export const getNewSceneInfo = async (params: GetNewSceneInfoParams) => {
  return await _post( VITE_API_BASE + "/voice/add_scene", params);
};

export const getEditorDraftId = async (params: { draftId: string }) => {
  return await _post( VITE_API_BASE + "/voice/to_timeline", params);
}

export const createNewVersion = async (params: CreateNewVersionParams) => {
  return await _post( VITE_API_BASE + "/voice/to_other", params);
}

export const checkResource = async (params: any) => {
  return await _post( VITE_API_BASE + "/video/render/check_resource", params);
}
