<script setup>
const tagOptions = [
  "Architecture",
  "Interiors",
  "Textures",
  "Patterns",
  "Nature",
  "Cozy",
];
const ratioOptions = ["portrait", "landscape", "square"];
const colorOptions = [
  ["#FFFFFF", "#CCCCCC", "#999999", "#666666", "#323333", "#000001"],
  ["#F9B8BA", "#F57A7B", "#F03D3D", "#D91012", "#9C0A0D", "#5F0708"],
  ["#F9DDB8", "#F5BD7C", "#F09F3D", "#DA8013", "#9D5B0F", "#5E3708"],
  ["#FAF6B8", "#F4ED7B", "#F0E73D", "#D9CE15", "#9B950D", "#5F590A"],
  ["#CFF9B9", "#A6F47A", "#7BF03D", "#57DA10", "#3F9C0C", "#265E06"],
  ["#B9F9E7", "#7BF5D6", "#3EEFC3", "#18D8A7", "#089C78", "#085E49"],
  ["#B9E5F9", "#7AD0F4", "#3FBAEE", "#0D9DD9", "#0B719C", "#07145F"],
  ["#B8C1F9", "#AA7BF4", "#853EF0", "#6112DA", "#450C9C", "#2A075E"],
  ["#F6B9FA", "#EE7BF3", "#E73EEF", "#CE12D8", "#950C9B", "#5A085E"],
];

const emit = defineEmits([
  "update:keyword",
  "update:ratio",
  "update:color",
  "search",
]);
const props = defineProps({
  keyword: {
    type: String,
    default: "",
  },
  ratio: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "",
  },
  showColor: {
    type: Boolean,
    default: true,
  },
});

const popover = ref(null);
const keyword = ref(props.keyword);
const ratio = ref(props.ratio);
const color = ref(props.color);

function updateKeyword(value) {
  keyword.value = value;
  emit("update:keyword", keyword.value);

  if (value === "") {
    ratio.value = "";
    color.value = "";

    emit("update:ratio", ratio.value);
    emit("update:color", color.value);
  }
}

function clear() {
  keyword.value = "";
  ratio.value = "";
  color.value = "";

  emit("update:keyword", keyword.value);
  emit("update:ratio", ratio.value);
  emit("update:color", color.value);
  emit("search");
}

function search() {
  emit("search");
}

function clickTag(tag) {
  keyword.value = tag;

  emit("update:keyword", keyword.value);
  emit("search");
}

function clickRatio(value) {
  ratio.value = value === ratio.value ? "" : value;

  emit("update:ratio", ratio.value);
  emit("search");
}

function clickColor(value) {
  color.value = value === color.value ? "" : value;

  emit("update:color", color.value);
  emit("search");
}

function reset() {
  ratio.value = "";
  color.value = "";

  emit("update:ratio", ratio.value);
  emit("update:color", color.value);
  emit("search");
}
</script>
<template>
  <div class="header">
    <div class="searchbar">
      <el-input
        :model-value="keyword"
        placeholder="Search"
        @keyup.enter="search"
        @update:model-value="updateKeyword"
      >
        <template #prefix>
          <svg-icon name="editor_library_search" :size="18" />
        </template>
        <template #suffix>
          <icon-button
            v-if="keyword.length > 0"
            name="editor_library_close"
            :size="20"
            @click="clear"
          />
        </template>
      </el-input>
      <el-popover
        ref="popover"
        placement="bottom-end"
        trigger="click"
        :offset="6"
        :show-arrow="false"
        :width="230"
        :hide-after="0"
        :teleported="false"
        :disabled="keyword.length === 0"
      >
        <template #reference>
          <icon-button
            border
            name="editor_library_filter"
            :size="18"
            :disabled="keyword.length === 0"
            :active="!!ratio || !!color"
            :color="!!ratio || !!color ? '#FFFFFF' : '#646A73'"
          />
        </template>
        <div class="filter-title">Ratio</div>
        <div class="ratio-list">
          <div
            class="ratio-option"
            v-for="(option, i) in ratioOptions"
            :key="i"
            :class="{ active: option === ratio }"
            @click="clickRatio(option)"
          >
            <div :class="option"></div>
          </div>
        </div>
        <div class="filter-title" v-if="showColor">Color</div>
        <div class="color-list" v-if="showColor">
          <div v-for="(col, i) in colorOptions" class="color-col" :key="i">
            <div
              v-for="(option, j) in col"
              class="color-option"
              :key="j"
              :style="{ backgroundColor: option }"
              @click="clickColor(option)"
            >
              <svg-icon
                v-show="option === color"
                name="editor_library_checked"
                :size="22"
              />
            </div>
          </div>
        </div>
        <div class="buttons">
          <ordinary-plain-button
            :disabled="!ratio && !color"
            size="extra-small"
            @click="reset"
            >Reset</ordinary-plain-button
          >
          <primary-button size="extra-small" @click="popover?.hide()"
            >Done</primary-button
          >
        </div>
      </el-popover>
    </div>
    <div class="tag-list">
      <span
        v-for="(option, i) in tagOptions"
        :key="i"
        class="tag"
        :class="{ active: option === keyword }"
        @click="clickTag(option)"
        >{{ option }}</span
      >
    </div>
  </div>
</template>
<style scoped>
.header {
  padding: 24px 16px 12px;
}
.searchbar {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
:deep(.el-popover.el-popper) {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.08);
  padding: 12px 12px 20px;
}
.filter-title {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.ratio-list {
  margin: 10px 0 20px;
  display: flex;
  justify-content: space-between;
}
.ratio-option {
  width: 64px;
  height: 38px;
  border-radius: 2px;
  border: 0.5px solid #e5e7eb;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
}
.ratio-option.active {
  border-color: #6741ff;
  background-color: #f8f5ff;
}
.ratio-option div {
  background-color: #fff;
  border-radius: 2px;
  border: 0.5px solid #646a73;
  transition: border-color 200ms;
}
.ratio-option.active div {
  border-color: #6741ff;
  background-color: #f8f5ff;
}
.ratio-option .landscape {
  width: 30px;
  height: 18px;
}
.ratio-option .portrait {
  width: 18px;
  height: 30px;
}
.ratio-option .square {
  width: 24px;
  height: 24px;
}
.color-list {
  display: flex;
  margin-top: 5px;
}
.color-col + .color-col {
  margin-left: 1px;
}
.color-option {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.color-option + .color-option {
  margin-top: 1px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
:deep(.buttons .el-button) {
  padding: 0;
  width: 94px;
  height: 36px;
}
.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  gap: 6px;
}
.tag {
  padding: 4px 7px;
  border-radius: 2px;
  background-color: #ebedef;
  color: #646a73;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  transition: all 200ms;
}
.tag.active {
  background-color: #646a73;
  color: #f8f5ff;
}
:deep(.icon-button.border) {
  border-radius: 4px;
  border: 1px solid #d5d6d7;
  margin-left: 12px;
}
:deep(.icon-button.border .icon-wapper) {
  padding: 8px;
}
:deep(.icon-button.active) {
  border: 1px solid #fff;
  background-color: #875eff;
}
:deep(.el-input) {
  height: 100%;
}
:deep(.el-input__wrapper) {
  padding: 0 8px;
  box-shadow: none;
  border: 1px solid #d5d6d7;
  transition: all 200ms;
}
:deep(.el-input__wrapper.is-focus) {
  border-color: #6741ff;
}
:deep(.el-input__prefix-inner > :last-child) {
  margin-right: 4px;
}
:deep(.el-input__suffix-inner > :first-child) {
  margin-left: 4px;
}
:deep(.el-input__inner) {
  padding: 0;
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
:deep(input::placeholder) {
  color: #8f959e;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 300ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
