<template>
  <tools-modal v-model="visible" @close="handleClose" title="Video Enhancer">
    <video-enhancer
      mode="editor"
      :originUrl="src"
      :mid="mid"
      @onAsyncProcess="handleAsyncProcess"
    />
  </tools-modal>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import ToolsModal from "../../modal/toolsModal.vue";
import VideoEnhancer from "./videoEnhancer.vue";

const emit = defineEmits(["complete", "update:modelValue"]);
const props = defineProps({
  mid: {
    type: String,
    required: true,
  },
  src: String,
  modelValue: Boolean,
});

const visible = ref(false);

watch(
  () => props.modelValue,
  (value) => {
    visible.value = value;
  },
);

const handleClose = () => {
  visible.value = false;
  emit("update:modelValue", false);
};

const handleAsyncProcess = () => {
  emit("complete");
  handleClose();
};
</script>

<style lang="scss" scoped></style>
