<template>
  <div class="loading-container">
    <svg-icon name="editor_loading" color="#646A73" :size="20" />
  </div>
</template>
<style scoped>
.loading-container {
  padding: 8px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-container svg {
  animation: rotate 1s linear infinite;
}
</style>
