<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import YouTubePlayer from "youtube-player";

const props = defineProps({
  id: { type: String, default: "" },
  src: { type: String, required: true },
  width: { type: Number, default: 0 },
  height: { type: Number, default: 0 },
  isPlay: { type: Boolean, default: false },
  playerVars: {
    type: Object,
    default () {
      return {};
    }
  }
});

const emit = defineEmits(["ended", "play", "pause"]);
const isFirstPlay = ref(false)

onMounted(() => {
  initPlayer();
  loadPlayer();
  addStateChange();
  stop();
  document.addEventListener('click', function (event) {
    stop();
  });
});

onBeforeUnmount(() => {
  removeStateChange();
  player && player.destroy();
  player = null;
});

const getVideoId = () => {
  try {
    return new URL(props.src).searchParams.get("v") || "";
  } catch (error) {
    return "";
  }
};

let player = null;
const initPlayer = () => {
  try {
    player = YouTubePlayer(`youtube-${props.id}`, {
      videoId: getVideoId(),
      width: props.width,
      height: props.height,

      // playerVars 播放器其它参数配置
      playerVars: {
        playsinline: 1,
        ...props.playerVars
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const loadPlayer = () => {
  try {
    player.loadVideoById(getVideoId());
  } catch (error) {
    console.log(error);
  }
};

const play = () => player && player.playVideo();
const pause = () => player && player.pauseVideo();
const stop = () => player && player.stopVideo();

// -1（未开始）0（已结束）1（正在播放）2（已暂停）3（正在缓冲）5（视频已插入）
let stateChangeListener;
const addStateChange = () => {
  stateChangeListener = player && player.on("stateChange", (event) => {
    if (event.data === 0) {
      emit("ended");
    }
    if (event.data === 1) {
      isFirstPlay.value = true
      emit("play", props.id);
    }
    if (event.data === 2) {
      emit("pause", props.id);
      pause()
    }
  });
};
const removeStateChange = () => {
  if (stateChangeListener && player) player.off(stateChangeListener);
};

watch(
  () => isFirstPlay.value,
  () => {

  }
);

watch(
  () => props.src,
  () => loadPlayer()
);

watch(
  () => props.isPlay,
  (isPlay) => {
    if (isPlay) {
      play()
    } else {
      pause()
    }
  }, {
  immediate: true
}
);

watch(
  () => [props.width, props.height],
  () => {
    player?.setSize && player.setSize(props.width, props.height);
  }
);

defineExpose({ play, pause });
</script>

<template>
  <div class="youtube-video" @click.stop="() => {}">
    <div :id="'youtube-' + id"></div>
  </div>
</template>

<style lang="scss" scoped>
.youtube-video {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);
  }
}
</style>
