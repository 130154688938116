<template>
  <div>
    <header class="header">
      <div class="left">
        <svg-icon
          v-if="globalDomain == 1"
          class="logo"
          name="editor_logo"
          color="#FFFFFF"
          :style="{ width: '150px', height: '36px' }"
          @click="router.push('/workspace')"
        />

        <RouterLink
          v-if="globalDomain == 2"
          to="/workspace"
          v-html="similarVideo_logo"
          class="boolv-logo cursor-pointer"
        />

        <div class="draft-state">
          <svg-icon v-show="state === 'saved'" name="editor_cloud" :size="20" />
          <svg-icon
            v-show="state === 'failed'"
            name="editor_cloud_failure"
            :size="20"
          />
          <span class="time" v-show="state === 'saved' && lastUpdate">
            {{ dateToHms(lastUpdate) }}
          </span>
          <span>{{ displayText[state] }}</span>
        </div>
      </div>
      <div class="right">
        <bv-guide
          name="scene-editor-guide"
          title="Switch to the timeline editor"
          description="Customize everything as you wish (e.g. music, subtitle styles, layout, etc.)"
          footer-text="3/3"
          confirm-text="OK"
          placement="bottom-start"
          :visible="guideStep === 3"
          v-if="canEditInTimeline"
        >
          <template #reference>
            <div class="button" @click="gotoTimeline">
              <svg-icon name="script_timeline" class="prefix" :size="18" />
              <span>Edit in timeline</span>
            </div>
          </template>
        </bv-guide>
        <bv-dropdown
          trigger="click"
          popper-class="script-translate-popper"
          placement="bottom-end"
          searchPlaceholder="Search"
          :options="languageList"
          :showSearch="true"
          :max-height="304"
          @command="translateClick"
          @visibleChange="handleVisibleChange"
          v-if="canTranslate"
        >
          <div class="" :class="['button', translateActive ? 'active' : '']">
            <svg-icon name="script_translate" class="prefix" :size="18" />
            <span>Translate</span>
            <svg-icon name="icon_left" class="icon_left suffix" :size="12" />
          </div>
          <template #header>
            <div class="dropdown-header">Version translate to</div>
          </template>
        </bv-dropdown>
        <primary-button
          :disabled="!canPlay && translating"
          @click="exportVideo"
        >
          {{`Export${globalDomain == 1 ? ' HD' : ''}`}}
        </primary-button>
        <Avatar :options="permission ? extraOptions : []" />
      </div>
      <div v-if="translating" class="loading-container">
        <GenerateLoading />
      </div>
    </header>
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup lang="jsx">
import GenerateLoading from '@/components/common/generateLoading/index.vue';
import Avatar from '@/layout/components/avatar.vue';
import defaultConfig from '@/assets/data/conf.json';
import { useMessage } from '@/utils';
import { dateToHms } from '../../utils';
import { useNetwork } from '@/composables';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { getTranslateLanguageList } from '@/api/language';
import { getEditorDraftId } from '@/api/script';
import { postExportVideo } from '@/api/draft';
import { useDraftStore } from '../../stores/draft';
import { useScriptStore } from '../../stores/script';
import { useTrackStore } from '@/store/modules/track';
import { usePermission } from '../../composables/usepermission';
import similarVideo_logo from '@/assets/similar-video/similarVideo_logo.svg?raw';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

const { materialChecker, creator, canPlay, start, pause } = useScriptStore();
const {
  rid,
  reqId,
  state,
  title,
  ratio,
  videoType,
  lastUpdate,
  updateDraft,
} = useDraftStore();
const route = useRoute();
const router = useRouter();
const message = useMessage();
const modalManager = useModalManager();
const { permission } = usePermission();
const { assertNetworkError } = useNetwork();
const { collectData, track } = useTrackStore();

const translateActive = ref(false);
const exportLoading = ref(false);
const translating = ref(false);
const languageList = ref([]);
const guideStep = inject('guideStep');
const globalDomain = inject('globalDomain');

const subscribeModalVisible = ref(false);

const canTranslate = computed(() => {
  return !translating.value;
});
const canEditInTimeline = computed(() => !translating.value);

const form = reactive({
  name: '',
  resolution: globalDomain === 1 ? 1080 : 480,
});
const options = [
  {
    label: '1080P',
    value: 1080,
  },
  {
    label: '720P',
    value: 720,
  },
  {
    label: '480P',
    value: 480,
  },
];
const displayText = {
  saving: 'Saving',
  saved: 'Saved',
  failed: 'Save failed',
};
const extraOptions = [
  {
    label: 'Reset Json',
    handler: resetCofig,
  },
  {
    label: 'Copy Json',
    handler: copyConfig,
  },
  {
    label: 'Import Json',
    handler: importConfig,
  },
];

watch(
  title,
  (newTitle) => {
    form.name = newTitle;
  },
  { immediate: true }
);

const exportVideo = () => {
  assertNetworkError();
  exportLoading.value = true;

  modalManager.applyTemplate('confirm', {
    modalClass: 'export-modal',
    showCancel: false,
    confirmText: 'Export',
    title: 'Export',
    key: 'editor-export',
    content: (
      <el-form
        model={form}
        labelWidth="88px"
        labelPosition="left"
        style={{ marginTop: '24px' }}>
        <el-form-item label="Name">
          <el-input
            modelValue={form.name}
            onUpdate:modelValue={(v) => (form.name = v)}
            onKeyup={(e) => e.stopPropagation()}
          />
        </el-form-item>
        <el-form-item label="Resolution" style={{ marginBottom: 0 }}>
          <bv-select
            popperStyle={{ width: '279px' }}
            options={options}
            modelValue={form.resolution}
            onChange={(v) => (form.resolution = v)}
          />
        </el-form-item>
      </el-form>
    ),
    onConfirm: async () => {
      const { draftId } = route.query;
      const { name, resolution } = form;
      const params = { draftId, name, resolution };
      const response = await postExportVideo(params);
      const { success, code, msg, noCredit } = response;

      if (!success) {
        if (noCredit) {
          modalManager.applyTemplate('upgradeTips', {
            msg,
            code,
            key: 'editor-uogradeTip',
            onConfirm: () => {
              subscribeModalVisible.value = true;
            },
          });
        } else {
          message.error('Can’t export, please try again');
        }
      } else {
        collectData('boolvideo_export_click', {
          video_type: videoType.value,
          ratio: ratio.value.replace(':', '_'),
          video_subtype: 'with_ai_voiceover',
          editor_type: 'scene_editor',
          rid: rid.value,
          reqId: reqId.value,
          draftId,
        });
        track('boolvideo_export_click');
        const { href } = router.resolve({
          path: '/space',
          query: { tab: 'exported' },
        });
        window.open(href, '_blank');
      }
      exportLoading.value = false;
    },
  });
};

const handleVisibleChange = (value) => {
  translateActive.value = value;
};

const translateClick = async (value) => {
  collectData('boolvideo_scene_translate_click', {
    video_type: videoType.value,
    language: value.value,
    rid: rid.value,
    reqId: reqId.value,
    draftId: route.query.draftId,
  });
  track('boolvideo_scene_translate_click');

  pause();

  const language = value.value;
  const { draftId } = route.query;
  const translateParams = JSON.stringify({
    draftId,
    language,
  });
  const pathData = router.resolve({
    path: '/scene-editor',
    query: {
      translateParams,
      videoType: videoType.value,
    },
  });
  window.open(pathData.href, '_blank');
};

const gotoTimeline = async () => {
  const draftId = route.query.draftId;
  const { success, data } = await getEditorDraftId({ draftId });

  if (!success) return;

  collectData('boolvideo_edit_click', {
    video_type: videoType.value,
    video_subtype: 'with_ai_voiceover',
    draftId,
    rid: rid.value,
    reqId: reqId.value,
  });
  track('boolvideo_edit_click');
  const pathData = router.resolve({
    path: '/editor',
    query: {
      draftId: data.draftId,
    },
  });
  window.open(pathData.href, '_blank');
};

async function resetCofig() {
  modalManager.applyTemplate('confirm', {
    title: 'Do you want to reset?',
    onConfirm: () => start(defaultConfig),
  });
}

async function copyConfig() {
  try {
    await navigator.clipboard.writeText(JSON.stringify(creator.value.conf));
    message.success('Copied to clipboard');
  } catch (e) {
    message.error(JSON.stringify(e));
  }
}

async function importConfig() {
  const materialHelper = () => {
    if (!materialChecker()) {
      modalManager.applyTemplate('warn', {
        title: 'Failed to retrieve current media',
        content: 'Please replace the media manually.',
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z"
              fill="#FF8800"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5597 12.6904 16 12 16C11.3097 16 10.75 16.5597 10.75 17.25C10.75 17.9404 11.3097 18.5 12 18.5Z"
              fill="white"
            />
            <path
              d="M12 6V14"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
        footer: (
          <Space size={12} reversed>
            <CommonButton
              size="extra-small"
              onclick={modalManager.modal.onClose}>
              Got it
            </CommonButton>
          </Space>
        ),
        modalClass: 'material-warn-tip',
        key: 'material-warn-tip',
      });
    }
  };

  const config = await navigator.clipboard.readText();

  try {
    await start(config);
    updateDraft();
    message.success('Import successfully');
    materialHelper();
  } catch (e) {
    message.error(e);
  }
}

async function setup() {
  const languageParams = {
    filter: "NONE",
  };
  switch(globalDomain) {
    case 1:
      languageParams.filter = "AZURE_TEXT_TO_SPEECH";
      break;
    case 2:
      languageParams.filter = "_11LABS";
      break;
  }
  const res = await getTranslateLanguageList(languageParams);
  if (!res.success) return;
  languageList.value = res.data.map((item) => ({
    label: item.name,
    value: item.language,
  }));
}

onMounted(setup);
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e9ec;
  min-width: 1256px;
  position: relative;
  height: 60px;
  background-color: #ffffff;
  flex: 0 0 auto;
  margin: 0;
  color: #060606;
}

.boolv-logo {
  height: 36px;
  margin-right: 24px;
}

.left {
  margin-left: 26px;
  display: flex;
  align-items: center;
}

.draft-state {
  margin-left: 46px;
  display: flex;
  align-items: center;
  color: #8f959e;
  font-size: 12px;
  line-height: 20px;
  user-select: none;
}

.draft-state svg {
  margin-right: 10px;
}

.draft-state .time {
  margin-right: 5px;
}
.logo {
  cursor: pointer;
  z-index: 5;
}

.right {
  display: flex;
  align-items: center;
  margin-right: 34px;
}

:deep(.right) > div + div {
  margin-left: 20px;
}

:deep(.right .el-button) {
  height: 36px;
  width: 150px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.button {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.08s linear 0s;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  & > .prefix {
    margin-right: 6px;
  }

  & > .suffix {
    margin-left: 6px;
  }

  & > svg {
    color: #1c1b1e;
  }

  & > span {
    color: #060606;
  }

  & > .icon_left {
    transform: rotate(-90deg);
    color: #8f959e;
  }
}

.button:hover {
  background-color: #f8f5ff;

  & > svg {
    color: #6741ff;
  }

  & > span {
    color: #6741ff;
  }
}

.active {
  background-color: #f8f5ff;

  & > svg {
    color: #6741ff;
  }

  & > span {
    color: #6741ff;
  }
}

.loading-container {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.dropdown-header {
  padding: 5px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
</style>
<style>
.modal-container.export-modal {
  width: 415px;
}

.export-modal .el-form-item__label {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
}

.export-modal .el-input__wrapper {
  border: 1px solid #d5d6d7;
  box-shadow: none;
  transition: border-color 200ms;
  padding: 0 8px;
}

.export-modal .select-wapper {
  border: 1px solid #d5d6d7;
  transition: border-color 200ms;
  padding: 0 8px;
}

.export-modal .el-input__inner {
  padding: 0;
}

.export-modal .el-input__inner,
.export-modal .bv-select-button .select-value {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.export-modal .el-button--primary {
  font-weight: 400;
}

.export-modal .el-input .el-input__wrapper:hover,
.export-modal .bv-select-button .select-wapper:hover {
  border: 1px solid #be9fff;
}

.export-modal .el-input .el-input__wrapper.is-focus {
  border: 1px solid #875eff !important;
  box-shadow: none !important;
}

.script-translate-popper {
  width: 229px;
  color: #060606;
}

.script-translate-popper .el-dropdown-menu__item {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #060606;
}
</style>
