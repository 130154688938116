<template>
  <bv-guide
    name="scene-editor-guide"
    title="Smart scene editor"
    description="Easily adjust voiceovers, scene order and content; Auto-generate new scenes based on context or your new idea."
    footer-text="2/3"
    confirm-text="Next"
    placement="bottom-start"
    :disappear="false"
    :visible="guideStep === 2 && index === 0"
    :popperOptions="{modifiers: [{name: 'offset',options: { offset: [-48, 12]}}]}"
    @click="guideStep = 3"
  >
    <template #reference>
      <el-dropdown
        trigger="hover"
        popper-class="script-tools-popper"
        placement="bottom-end"
        :popper-options="{ modifiers: [{ name: 'offset', options: { offset: [0, 4] }}]}"
        @command="aiaddCommand"
        @visibleChange="(v) => aiaddActive = v"
      >
        <icon-button 
          class="aiadd"
          :class="{active: aiaddActive}"
          name="script_aiadd"
          :size="18"
        />
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="item in aiaddOption"
              :command="item.value"
              :disabled="item.value === 'aiClip' && parseNode(scene.nodes).text === ''"
            > 
              <svg-icon
                :name="item.prefix"
                :size="18"
              />
              <span style="margin-left: 12px;">
                {{ item.label }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </template>
  </bv-guide>
  <TextDialog 
    v-model="textVisible"
    @confirm="addNewScene"
  />
</template>

<script setup>
import TextDialog from "../dialog/textdialog.vue";
import { extractLocale } from "../../utils";
import { parseNode } from "../../utils/scene";
import { useDraftStore } from "../../stores/draft";
import { useScriptStore } from "../../stores/script";
import { useTrackStore } from "@/store/modules/track";
import { validImport } from "@/pages/createVideos/utils/import";
import { getAiSceneInfo } from "@/api/script";
import { useMessage } from "@/utils";
import { useModalManager } from "@/components/common/custom-modal/instance";

const props = defineProps({
  index: {
    type: Number,
  },
  scene: {
    type: Object,
    default: {},
  },
});

const {
  defaultVoiceStyle,
  defaultVoiceName,
  loadingScene,
  scenes,
  pause,
  addScene,
  addNodesAt,
  removeScene,
  setLoadingScene,
} = useScriptStore();
const { 
  ratio,
  videoType, 
  updateDraft
} = useDraftStore();
const { 
  track,
  collectData, 
} = useTrackStore();
const modalManager = useModalManager();
const message = useMessage();

const textVisible = ref(false);
const aiaddActive = ref(false);
const aiaddOption = [
  {
    label: "Auto-generate a new scene",
    value: "aiClip",
    prefix: "script_starbar",
  },
  {
    label: "Idea to a new scene",
    value: "textClip",
    prefix: "script_textrecognize",
  }
];
const sceneLanguage = computed(() => {
  if (videoType.value.startsWith("similar_video")) {
    return props.scene.defaultLanguage;
  }
  else {
    const voiceName = defaultVoiceName.value;
    return extractLocale(voiceName);
  }
});

const guideStep = inject("guideStep");

const aiaddCommand = async (command) => {
  pause();
  switch(command) {
    case "aiClip":
      await addAiScene();
      break;
    case "textClip":
      textVisible.value = true;
      break;
  }
};

const addAiScene = async () => {
  if (loadingScene.value) return;
  collectData("boolvideo_scene_edit_click", {
    click: "auto_gen_scene",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
  const newScene = await addScene(props.scene);
  setLoadingScene(newScene);

  const voiceName = defaultVoiceName.value;
  const voiceStyle = defaultVoiceStyle.value;
  const language = sceneLanguage.value;
  const aiSceneIdx = props.index + 1;
  const size = ratio.value;
  const sceneTexts = [];
  scenes.value.map(item => {
    const { text } = parseNode(item.nodes);
    if (text !== "") sceneTexts.push(text);
  });
  const params = {
    size,
    language,
    voiceName,
    voiceStyle,
    aiSceneIdx,
    sceneTexts,
    lab11: videoType.value.startsWith("similar_video"),
  };
  const { success, data, code, msg } = await getAiSceneInfo(params);
  if (!success) {
    if (validImport(code)) {
      modalManager.applyTemplate("importFailed", {msg});
    }
    
    setLoadingScene(null);
    removeScene(newScene);
    message.error("Processing failed");
  }
  else {
    addNodesAt(newScene, data)
      .then(() => {
        updateDraft();
        setLoadingScene(null);
        message.success("Processing completed");
      });
  }
};

const addNewScene = async (value) => {
  if (loadingScene.value) return;

  collectData("boolvideo_scene_edit_click", {
    click: "idea_to_scene",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
  const newScene = await addScene(props.scene);
  setLoadingScene(newScene);

  let voiceName = defaultVoiceName.value;
  let voiceStyle = defaultVoiceStyle.value;
  const { speech } = parseNode(props.scene.nodes);
  if (speech) {
    voiceName = speech.conf.voiceName;
    voiceStyle = speech.conf.voiceStyle;
  }
  const size = ratio.value;
  const aiSceneIdx = props.index + 1;
  const language = sceneLanguage.value;
  const sceneTexts = [];
  scenes.value.map(item => {
    const { text } = parseNode(item.nodes);
    if (text !== "") sceneTexts.push(text);
  });
  const params = {
    size,
    language,
    voiceName,
    voiceStyle,
    aiSceneIdx,
    sceneTexts,
    prompt: value,
    lab11: videoType.value.startsWith("similar_video"),
  };
  const { success, data } = await getAiSceneInfo(params);
  if (!success) {
    setLoadingScene(null);
    removeScene(newScene);
    message.error("Processing failed");
  }
  else {
    addNodesAt(newScene, data)
      .then(() => {
        updateDraft();
        setLoadingScene(null);
        message.success("Processing completed");
      });
  }
};
</script>

<style lang="scss" scoped>
.aiadd {
  &:hover, 
  &.active{
    background-color: #F8F5FF
  }
  
}
</style>