import { watch, ref, onMounted, onBeforeUnmount } from 'vue'
import { routeMap } from '@/components/common/generateLoading/data.js'
import { videoRenderState } from '@/api/index'
import { useRoute, useRouter } from 'vue-router'
import { useSingleMessage } from '@/utils';

export function useLoading (id) {
  const Message = useSingleMessage();
  const route = useRoute()
  const router = useRouter()
  const currentStep = ref(null)
  const generateSteps = ref(null)
  const copyGenerateSteps = ref(null)
  const renderVO = ref(null)
  const reqId = ref(id)
  let timer = null
  let updateTimer = null

  const init = (routeMap, path) => {
    for (let key in routeMap) {
      if (path == key) {
        generateSteps.value = routeMap[key].map((item, index) => {
          return {
            id: index + 1,
            state: index == 0 ? 2 : 1, // 0 表示完成， 2 表示进行中, 1 表示未开始
            name: item
          }
        })
      }
    }
    currentStep.value = generateSteps.value[0]
    copyGenerateSteps.value = [...generateSteps.value]
  }

  const getVideoRenderState = async (reqId) => {
    const { data, code } = await videoRenderState(reqId)
    if (code != 0) {
      Message.error('error, Please try again');
      clearTimer()
      let path = ''
      if (route.path == '/similar-video/generating') {
        path = '/similar-video/setting'
      }else {
        path = route.path?.split('/generating')[0]
      }
     
      setTimeout(() => {
        if (path) {
          router.replace(path)
        }
      }, 2000)
      return
    }
    const { steps, renderVO: info } = data

    if (info) {
      // 最后一步
      currentStep.value = generateSteps.value[generateSteps.value.length - 1]
    }

    setTimeout(() => {
      renderVO.value = info
    }, 1000)

    if (!steps || !steps.length) {
      return
    }

    steps.forEach((step, idx) => {
      generateSteps.value.forEach((item, index) => {
        if (item.name == step.name) {
          if (step.state == 0) {
            item.state = 0
            if (generateSteps.value[index + 1]) {
              generateSteps.value[index + 1].state = 2
            }
          }
        }
      })
    })
  }


  const checkSate = () => {
    const currentIdx = generateSteps.value.findIndex(item => item.id == currentStep.value?.id)
    const current = generateSteps.value[currentIdx]
    const next = generateSteps.value[currentIdx + 1]
    if (current?.state == 0) {
      // 当前完成，进入下一步
      currentStep.value = next ? next : currentStep.value
    }
  }

  watch(() => generateSteps.value, () => {
    if (updateTimer) {
      return
    }

    updateTimer = setInterval(() => {
      checkSate()
    }, 500)
  }, {
    deep: true
  })

  // 初始化生成文案
  watch(() => route.path, (path) => {

    if (path) {
      init(routeMap, path)
    }
  }, {
    immediate: true
  })

  watch(() => reqId.value, (reqId) => {
    if (reqId) {
      if (timer) {
        return
      }

      setTimeout(() => {
        timer = setInterval(() => {
          getVideoRenderState(reqId)
        }, 3000)
      }, 1500)
    }
  })

  watch(() => route.query.reqId, () => {
    reqId.value = route.query.reqId
  })

  const clearTimer = () => {
    // 进度更新完成，跳转preview
    clearInterval(timer)
    timer = null

    clearInterval(updateTimer)
    updateTimer = null
  }

  onMounted(() => {
    if (route.query.reqId) {
      if (timer) {
        return
      }

      timer = setInterval(() => {
        getVideoRenderState(route.query.reqId)
      }, 3000)
    }
  })

  onBeforeUnmount(() => {
    clearTimer()
  })

  return {
    currentStep,
    generateSteps,
    copyGenerateSteps,
    renderVO,
    clearTimer
  }
}