import artistPreview from './preview_artist.mp4';
import contentCreatorPreview from './preview_content_creator.mp4';
import designerPreview from './preview_designer.mp4';
import ecommerceSellerPreview from './preview_e-commerce_seller.mp4';
import marketerPreview from './preview_marketer.mp4';
import photographerPreview from './preview_photographer.mp4';
import artistPoster from './poster/artist.jpg';
import contentCreatorPoster from './poster/content_creator.jpg';
import designerPoster from './poster/designer.jpg';
import ecommerceSellerPoster from './poster/e-commerce_seller.jpg';
import marketerPoster from './poster/marketer.jpg';
import photographerPoster from './poster/photographer.jpg';


export default {
  artist: {
    src: artistPreview,
    poster: artistPoster,
  },
  contentCreator: {
    src: contentCreatorPreview,
    poster: contentCreatorPoster,
  },
  designer: {
    src: designerPreview,
    poster: designerPoster,
  },
  ecommerceSeller: {
    src: ecommerceSellerPreview,
    poster: ecommerceSellerPoster,
  },
  marketer: {
    src: marketerPreview,
    poster: marketerPoster,
  },
  photographer: {
    src: photographerPreview,
    poster: photographerPoster,
  }
};
