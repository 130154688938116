<template>
  <div
    class="upload-container"
    @mouseenter="() => (isHover = true)"
    @mouseleave="() => (isHover = false)"
  >
    <svgIcon
      name="icon_upload"
      :size="32"
      style="color: #646a73"
      v-show="!isHover"
    />
    <p v-show="isHover">Add logos from</p>
    <div class="upload-button" v-show="isHover">
      <upload
        class="logo-upload"
        :onUpload="handleUpload"
        :onSuccess="handleSuccess"
        :onProgress="handleProgress"
        prompt="Computer"
        :accept="accept"
      />
    </div>
    <plain-button
      v-show="isHover"
      style="max-width: 100%"
      size="extra-small"
      @click="handleUploadFromCloud"
    >
      <p class="text-primaryColo font-normal" :style="{ width: '65.47px' }">
        Cloud
      </p>
    </plain-button>
  </div>
</template>

<script setup lang="tsx">
import { defineProps, defineEmits } from "vue";
import { IMAGETYPE } from '@/utils/type.ts'
import Upload from "../upload/upload.vue";
import Space from "@/components/common/space/index.vue";
import { CommonButton } from "@/components/common/bv-button/index";
import { useModalManager } from "@/components/common/custom-modal/instance";
import type {
  UploadEvent,
  UploadProgressEvent,
  UploadSuccessEvent,
} from "@/components/common/bv-upload/type";

const modalManager = useModalManager();

const emits = defineEmits(["success", "start", "progress", "openCloud"]);
const props = defineProps({
  listLength: {
    type: Number,
    required: true,
  },
});

const isHover = ref(false);
const accept = ref(IMAGETYPE)

const handleUpload = (e: UploadEvent) => {
  if (props.listLength < 20) {
    const url = URL.createObjectURL(new Blob([e]));
    emits("start", e.uid, url);
    return true;
  } else {
    modalManager.applyTemplate("warn", {
      title: "Create failed",
      content:
        "Only 20 logo can be upload, please delete the created and try again.",
      footer: (
        <Space size={12} reversed>
          <CommonButton size="extra-small" onclick={modalManager.modal.onClose}>
            Got it
          </CommonButton>
        </Space>
      ),
      modalClass: "upload-limit-tip-modal",
      key: "upload-limit-tip",
    });
    return false;
  }
};

const handleUploadFromCloud = () => {
  emits("openCloud");
};

const handleProgress = (e: UploadProgressEvent) => {
  emits("progress", e.uid, e.percent);
};

const handleSuccess = (e: UploadSuccessEvent) => {
  emits("success", e.uid, e["1080url"]);
};
</script>

<style lang="scss" scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;
  aspect-ratio: 1 / 1;
  border: 1px solid var(--card-border-color);
  border-radius: var(--card-border-radius);
}

.logo-upload {
  width: 100%;
}

:deep(.modal-container.upload-limit-tip-modal) {
  width: 415px;
}

.upload-button {
  max-width: 100%;
  height: auto;
  border-radius: 9999px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #875eff;
  cursor: pointer;

  &:hover {
    background-color: #f8f5ff;
  }
}

.logo-upload :deep(.el-upload) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > p {
    padding: 7px 36px;
    font-size: 14px;
    color: #875eff;
  }
}
</style>
