import preview_1 from './preview_1.mp4';
import preview_2 from './preview_2.mp4';
import preview_3 from './preview_3.mp4';
import preview_4 from './preview_4.mp4';

export default [
  {
    poster: 'https://cdn.boolv.video/pgc/home_video/Phontographer/1_thumbnail1.jpg',
    src: preview_1,
  },
  {
    poster: 'https://cdn.boolv.video/pgc/home_video/Phontographer/3_thumbnail.jpg',
    src: preview_2,
  },
  {
    poster: 'https://cdn.boolv.video/pgc/home_video/Phontographer/2_thumbnail.jpg',
    src: preview_3,
  },
  {
    poster: 'https://cdn.boolv.video/pgc/home_video/Phontographer/4_thumbnail.jpg',
    src: preview_4,
  },
];
