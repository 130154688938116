<template>
  <div ref="containerRef" class="card-list-container perfect-scrollbar">
    <Transition>
      <div v-if="isFirstLoad" class="loadingIcon">
        <img :src="loadingIcon" />
      </div>
    </Transition>
    <div v-if="items.length === 0 && isLoaded" class="empty-data">
      <svgIcon style="color: #bbbfc4; height: 200px" name="icon_empty" />
    </div>
    <ul v-else class="card-list" ref="cardListRef">
      <li v-for="item in items" :class="item.containerClass" :key="item.key">
        <v-render :vnode="item.vnode" />
      </li>
      <li
        v-if="!isLoaded"
        class="card-skeleton loading-box"
        :style="{ position: isLoading ? 'static' : 'relative' }"
        ref="cardSkeletonRef"
        key="#skeleton"
      >
        <card-skeleton v-show="isLoading" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, provide, onBeforeUnmount } from "vue";
import { MutexSpace } from "@/utils";
import CardSkeleton from "./card-skeleton.vue";
import { useSelection, isIntersection } from "../utils/helper";
import loadingIcon from "@/assets/icons/space/icon_loading.svg";
import type { PropType } from "vue";
import type { CardData } from "../utils/type";

provide("mutexSpace", new MutexSpace());

const props = defineProps({
  isFirstLoad: {
    type: Boolean,
    default: false,
  },
  isLoaded: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array as PropType<
      CardData<
        | SpaceResData.DraftRecordDTO
        | SpaceResData.ExportVideoVO
        | SpaceResData.MaterialLibDetailVO
      >[]
    >,
    default: () => [],
  },
});

const emit = defineEmits<{
  (e: "loadMore"): void;
}>();

const route = useRoute();
const batchDelete = inject<Set<string>>("batchDelete")!;
const cardSkeletonRef = ref<null | HTMLLIElement>(null);
const cardListRef = ref<null | HTMLUListElement>(null);
const containerRef = ref(null as unknown as HTMLDivElement);
let intersectionObserver: IntersectionObserver | null = null;

const selection = useSelection({
  handler(e) {
    const containerBoundingRect = cardListRef.value!.getBoundingClientRect();

    for (const { record, vnode, key } of props.items) {
      if (record === null) continue;
      if (
        !("rid" in record) &&
        (!("exportId" in record) || record.renderStatus !== 0) &&
        (!("mid" in record) || record.state !== 0)
      )
        continue;

      const el = vnode.el!;
      const elBoundingRect = el.getBoundingClientRect();

      if (
        isIntersection(e.rect, {
          width: elBoundingRect.width,
          height: elBoundingRect.height,
          left: elBoundingRect.left - containerBoundingRect.left,
          right: elBoundingRect.right - containerBoundingRect.left,
          top: elBoundingRect.top - containerBoundingRect.top,
          bottom: elBoundingRect.bottom - containerBoundingRect.top,
        })
      ) {
        batchDelete.add(key);
      }
    }
  },
});

function canLoadMore() {
  if (props.isLoaded || props.isLoading) {
    return false;
  }

  const targetBoundingClientRect =
    cardSkeletonRef.value!.getBoundingClientRect();
  const parentBoundingClientRect = containerRef.value.getBoundingClientRect();

  return (
    targetBoundingClientRect.bottom >= parentBoundingClientRect.top &&
    targetBoundingClientRect.top <= parentBoundingClientRect.bottom
  );
}

watch(cardSkeletonRef, (el) => {
  if (el === null) {
    intersectionObserver?.disconnect();
  } else {
    intersectionObserver = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            !props.isLoaded && !props.isLoading && emit("loadMore");
          }
        }
      },
      { root: containerRef.value },
    );

    intersectionObserver.observe(cardSkeletonRef.value!);
  }
});

watch(
  () => props.isLoaded,
  (isLoaded) => {
    if (isLoaded) {
      intersectionObserver?.disconnect();
    }
  },
);

onMounted(() => {
  if (route.query.tab === "media") {
    watchEffect(() => (selection.ref.value = cardListRef.value));
  }
});

onBeforeUnmount(() => {
  intersectionObserver?.disconnect();
  batchDelete.clear();
});

defineExpose({
  canLoadMore,
  containerRef,
});
</script>

<style lang="scss" scoped>
.card-list {
  --card-list-row-gap: 38px;
  --card-list-col-gap: 36px;
  --card-border-radius: 4px;
  --card-poster-height: 250px;
  --card-border-color: #dee0e3;

  display: grid;
  position: relative;
  min-height: 328px;
  padding: 36px 42px 36px 50px;
  grid-gap: var(--card-list-row-gap) var(--card-list-col-gap);
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--card-poster-height), 1fr)
  );

  & > li.hidden {
    display: none;
  }
}

.card-list-container {
  position: relative;
  height: 100%;
  padding-right: 3px;
  overflow-y: auto;
}

.loading-box {
  position: static;
  top: calc(-1 * var(--card-list-row-gap) - 1px);
}

.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 200px;
  font-size: 14px;
  color: #8f959e;
}

.loadingIcon {
  position: absolute;
  inset: 1px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 9;

  & > img {
    animation: spin 0.4s linear infinite;
  }
}

.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-leave-to {
  opacity: 0;
}
</style>
