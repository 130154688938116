<template>
  <div class="project-container">
    <el-scrollbar>
      <div class="upload">
        <bv-uploader
          v-model="files"
          ref="uploader"
          :accept="accept"
          @success="handleUploadSuccess"
          @beforeAddFiles="handleBeforeAddFiles"
          @checkFail="handleCheckFail"
        />
        <el-button size="large" @click="uploader?.handleClickUpload">
          <svg-icon name="editor_plus" color="#1C1B1E" :size="18" />
          <span>Upload media</span>
        </el-button>
      </div>
      <div v-if="files.length === 0" class="empty">
        <div
          class="empty-wapper"
          :class="{ droppable }"
          @mouseenter="droppable = true"
          @mouseleave="droppable = false"
          @drop.stop.prevent="uploader?.handleDropUpload"
          @dragover.stop.prevent="droppable = true"
          @dragleave.stop.prevent="dragLeave"
          @click="uploader?.handleClickUpload"
        >
          <p>Drop or click here to upload</p>
          <p>video, image, audio</p>
        </div>
      </div>
      <div
        v-else
        class="file-list"
        v-infinite-scroll="loadMore"
        :infinite-scroll-immediate="false"
      >
        <div
          class="file-item"
          v-for="(file, index) in files"
          :key="file.mid"
          :class="{ active: file.previewUrl }"
          @click="clickFile(file)"
        >
          <div class="file-content" :data-id="file.mid">
            <div class="image-wrapper">
              <div
                class="image-mask error"
                v-if="file.state === 1"
                @click="uploader?.reupload(file.id)"
              >
                <svg-icon name="editor_upload_warnning" :size="24" />
                <span>Click retry</span>
              </div>
              <div class="image-mask" v-else-if="file.state > 1">
                <span v-if="file.tag !== 'ai'">Uploading</span>
                <el-progress
                  :percentage="file.progress"
                  :show-text="false"
                  :color="file.tag === 'ai' ? '#FFE39B' : '#FFFFFF'"
                />
              </div>
              <img
                v-if="file.coverPic"
                :src="file.coverPic"
                loading="lazy"
                draggable="false"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'image'"
                name="editor_thumb_image"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'video'"
                name="editor_thumb_video"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'audio'"
                name="editor_thumb_music"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-if="file.tag === 'ai'"
                class="ai-tag"
                name="editor_ai_tools"
                :size="16"
              />
            </div>
            <span v-show="selectFile === file" class="select-mask">
              <svg-icon name="script_select_file" :size="24" />
            </span>
            <span
              v-if="['video', 'audio'].includes(file.type) && file.duration"
              class="duration"
              >{{ secondsToHms(file.duration) }}</span
            >
          </div>
          <div class="title">{{ file.name }}</div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { getProjectMaterialList } from "@/api/material";
import { DataUnit } from "@/utils";
import { secondsToHms } from "../../utils";
import { IMAGETYPE, VIDEOTYPE, IMAGEANDVIDEOTYPE } from "@/utils/type.ts";
import { useTrackStore } from "@/store/modules/track";

const globalDomain = inject("globalDomain");

const emits = defineEmits(["select"]);

const accept = [
  {
    types: IMAGETYPE.split(","),
    maxSize: 10 * DataUnit.MB,
  },
  {
    types: VIDEOTYPE.split(","),
    maxSize: 200 * DataUnit.MB,
  },
  {
    types: IMAGEANDVIDEOTYPE.split(","),
    maxSize: 50 * DataUnit.MB,
  },
];
const { collectData, track } = useTrackStore();
const route = useRoute();

const uploader = ref(null);
const selectFile = ref(null);
const droppable = ref(false);
const files = ref([]);
const page = ref(1);
const total = ref(0);

watch(
  [page],
  ([newPage]) => {
    getProjectMaterial(newPage);
  },
  { immediate: true }
);

async function getProjectMaterial(page, size = 40, order) {
  const { draftId } = route.query;

  if (draftId) {
    const params = { page, size, draftId };
    const response = await getProjectMaterialList(params);
    const { records } = response.data;

    total.value = response.data.total;
    files.value = order
      ? records.concat(files.value)
      : files.value.concat(records);

    for (const file of files.value) {
      if (file.state > 1) {
        uploader.value
          ?.getUploadState(file)
          .then((info) => Object.assign(file, info))
          .catch((e) => Object.assign(file, e));
      }
    }
  }
}

function loadMore() {
  if (0 < files.value.length && files.value.length < total.value) {
    page.value++;
  }
}

function handleUploadSuccess(file) {
  collectData("boolvideo_upload_media", {
    media_type: file.type,
    access: "scene_editor",
  });
  track("boolvideo_upload_media");
}

function handleBeforeAddFiles(files) {
  collectData("boolvideo_upload_media", {
    is_batch_upload: files.length > 1,
  });
}

function handleCheckFail(file) {
  let uploadEventName =
    globalDomain == 1
      ? "boolvideo_upload_project_media"
      : "similarvideo_upload_project_media";

  collectData(uploadEventName, {
    media_type: file.type,
    name: file.name,
    size: formatBytes(file.size),
    access: 'scene_editor'
  });

  track(uploadEventName);
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function clickFile(file) {
  if (file.state !== 0) return;

  if (selectFile.value === file) {
    selectFile.value = null;
    return;
  }

  selectFile.value = file;
  emits("select", file);
}
</script>

<style lang="scss" scoped>
.project-container {
  height: 100%;
}
.upload {
  width: 100%;
  padding: 25px 60px;
}
:deep(.el-button.el-button--large) {
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1c1b1e;
  background-color: #ffffff;
}
:deep(.el-button.el-button--large svg) {
  margin-right: 4px;
}
:deep(.el-button.el-button--large:hover) {
  background-color: #f5f6f7;
}

:deep(.el-scrollbar__view) {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.empty {
  padding: 0 60px 27px;
  flex: 1 1 auto;
}

.empty-wapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: border-color 200ms;
  border-width: 1px;
  border-style: dashed;
  border-color: transparent;
  position: relative;
  cursor: pointer;
}

.empty-wapper.droppable {
  border-color: #dac9ff;
}

.empty-wapper p {
  color: #8f959e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.file-list {
  display: grid;
  flex-wrap: wrap;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(107px, 1fr));
  padding: 0 60px;
}

.file-item {
  width: 107px;
}

.file-content {
  margin-bottom: 4px;
  overflow: hidden;
  position: relative;
}

.image-wrapper {
  width: 107px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #f3f5f7;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}

.ai-tag {
  position: absolute;
  left: 8px;
  bottom: 10px;
}

.image-wrapper:hover {
  border-color: #875eff;
}

.file-content .duration {
  padding: 0 8px;
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  right: 8px;
  bottom: 8px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.image-wrapper .image-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 4px;
  border: 1px solid #ffffff;
}

.image-wrapper .image-mask.error svg {
  margin-bottom: 6px;
}

.image-wrapper .image-mask.error span {
  margin-bottom: 0;
}

.image-wrapper .image-mask span {
  color: #f8f5ff;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 8px;
}

.image-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.title {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}

.select-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
