<script setup>
import TextAttr from "./textattr.vue";
import SubtitleAttr from "./subtitleattr.vue";
import GraphicAttr from "./graphicattr.vue";
import ImageAttr from "./imageattr.vue";
import StickerAttr from "./stickerattr.vue";
import EffectAttr from "./effectattr.vue";
import FilterAttr from "./filterattr.vue";
import VideoAttr from "./videoattr.vue";
import AudioAttr from "./audioattr.vue";
import SpeechAttr from "./speechattr.vue";
import TransitionAttr from "./transitionattr.vue";
import CanvasAttr from "./canvasattr.vue";
import { useCreatorStore } from "../../stores";

const { creator, activeNodeMap } = useCreatorStore();
const node = computed(() => {
  if (activeNodeMap.size === 1) {
    for (const node of activeNodeMap.values()) {
      return node;
    }
  }
  return null;
});
</script>

<template>
  <div class="attr-container">
    <canvas-attr v-if="creator && (node === null || !node.parent)" />
    <effect-attr 
      v-else-if="node.type === 'effect'" 
      v-model:x="node.conf.x"
      v-model:y="node.conf.y"
      v-model:rotate="node.conf.rotate"
      v-model:scale="node.conf.scale"
      v-model:opacity="node.conf.opacity"
      v-model:keyframes="node.conf.keyframes"
      v-model:blend="node.conf.blend"
      v-model:loop="node.conf.loop"
      :node="node"
      :key="`effect-${node.id}`"
      :initialState="node.initialState"
    />
    <filter-attr
      v-else-if="node.type === 'filter'"
      v-model:mix="node.conf.mix"
      :node="node"
      :key="`filter-${node.id}`"
      :name="node.conf.name"
      :initialState="node.initialState"
    />
    <transition-attr
      v-else-if="node.type === 'transition'"
      :node="node"
      :key="`transition-${node.id}`"
      :name="node.conf.name"
    />
    <audio-attr
      v-else-if="node.type === 'audio'"
      v-model:volume="node.conf.volume"
      v-model:fadeIn="node.conf.fadeIn"
      v-model:fadeOut="node.conf.fadeOut"
      v-model:speed="node.conf.speed"
      v-model:end="node.conf.end"
      :node="node"
      :key="`audio-${node.id}`"
      :initialState="node.initialState"
    />
    <speech-attr
      v-else-if="node.type === 'speech'"
      v-model:volume="node.conf.volume"
      v-model:fadeIn="node.conf.fadeIn"
      v-model:fadeOut="node.conf.fadeOut"
      v-model:speed="node.conf.speed"
      v-model:end="node.conf.end"
      :node="node"
      :key="`speech-${node.id}`"
      :initialState="node.initialState"
    />
    <image-attr
      v-else-if="node.type === 'image'"
      v-model:src="node.conf.src"
      v-model:hdUrl="node.conf.hdUrl"
      v-model:materialMeta="node.conf.materialMeta"
      v-model:x="node.conf.x"
      v-model:y="node.conf.y"
      v-model:rotate="node.conf.rotate"
      v-model:scale="node.conf.scale"
      v-model:opacity="node.conf.opacity"
      v-model:keyframes="node.conf.keyframes"
      v-model:blend="node.conf.blend"
      v-model:mask="node.conf.mask"
      v-model:tag="node.conf.tag"
      :key="`image-${node.id}`"
      :node="node"
      :initialState="node.initialState"
    />
    <video-attr
      v-else-if="node.type === 'video'"
      v-model:x="node.conf.x"
      v-model:y="node.conf.y"
      v-model:rotate="node.conf.rotate"
      v-model:scale="node.conf.scale"
      v-model:opacity="node.conf.opacity"
      v-model:keyframes="node.conf.keyframes"
      v-model:blend="node.conf.blend"
      v-model:mask="node.conf.mask"
      v-model:volume="node.conf.volume"
      v-model:fadeIn="node.conf.fadeIn"
      v-model:fadeOut="node.conf.fadeOut"
      v-model:speed="node.conf.speed"
      v-model:end="node.conf.end"
      :key="`video-${node.id}`"
      :node="node"
      :initialState="node.initialState"
      :hdUrl="node.conf.hdUrl"
      :coverPic="node.conf.coverPic"
      :transparent="node.conf.transparent"
    />
    <sticker-attr
      v-else-if="node.type === 'sticker'"
      v-model:x="node.conf.x"
      v-model:y="node.conf.y"
      v-model:rotate="node.conf.rotate"
      v-model:scale="node.conf.scale"
      v-model:opacity="node.conf.opacity"
      v-model:keyframes="node.conf.keyframes"
      v-model:blend="node.conf.blend"
      :key="`sticker-${node.id}`"
      :node="node"
      :initialState="node.initialState"
    />
    <graphic-attr
      v-else-if="node.type === 'graphic'"
      v-model:x="node.conf.x"
      v-model:y="node.conf.y"
      v-model:width="node.conf.width"
      v-model:height="node.conf.height"
      v-model:rotate="node.conf.rotate"
      v-model:opacity="node.conf.opacity"
      v-model:radius="node.conf.radius"
      v-model:fill="node.conf.fill"
      v-model:stroke="node.conf.stroke"
      v-model:strokeThickness="node.conf.strokeThickness"
      v-model:keyframes="node.conf.keyframes"
      v-model:blend="node.conf.blend"
      :key="`graphic-${node.id}`"
      :node="node"
      :initialState="node.initialState"
    />
    <text-attr
      v-else-if="node.type === 'text'"
      v-model:text="node.conf.text"
      v-model:fontFamily="node.conf.fontFamily"
      v-model:fontSize="node.conf.fontSize"
      v-model:fill="node.conf.fill"
      v-model:fontWeight="node.conf.fontWeight"
      v-model:fontStyle="node.conf.fontStyle"
      v-model:align="node.conf.align"
      v-model:letterSpacing="node.conf.letterSpacing"
      v-model:leading="node.conf.leading"
      v-model:x="node.conf.x"
      v-model:y="node.conf.y"
      v-model:rotate="node.conf.rotate"
      v-model:scale="node.conf.scale"
      v-model:opacity="node.conf.opacity"
      v-model:anchor="node.conf.anchor"
      v-model:paddingX="node.conf.paddingX"
      v-model:paddingY="node.conf.paddingY"
      v-model:stroke="node.conf.stroke"
      v-model:strokeThickness="node.conf.strokeThickness"
      v-model:background="node.conf.background"
      v-model:backgroundRadius="node.conf.backgroundRadius"
      v-model:backgroundOpacity="node.conf.backgroundOpacity"
      v-model:dropShadow="node.conf.dropShadow"
      v-model:dropShadowAlpha="node.conf.dropShadowAlpha"
      v-model:dropShadowAngle="node.conf.dropShadowAngle"
      v-model:dropShadowBlur="node.conf.dropShadowBlur"
      v-model:dropShadowColor="node.conf.dropShadowColor"
      v-model:dropShadowDistance="node.conf.dropShadowDistance"
      v-model:keyframes="node.conf.keyframes"
      v-model:blend="node.conf.blend"
      v-model:tag="node.conf.tag"
      v-model:preset="node.conf.preset"
      :key="`text-${node.id}`"
      :node="node"
      :initialState="node.initialState"
    />
    <subtitle-attr
      v-else-if="node.type === 'subtitle'"
      v-model:text="node.conf.text"
      v-model:fontFamily="node.conf.fontFamily"
      v-model:fontSize="node.conf.fontSize"
      v-model:fill="node.conf.fill"
      v-model:fontWeight="node.conf.fontWeight"
      v-model:fontStyle="node.conf.fontStyle"
      v-model:align="node.conf.align"
      v-model:letterSpacing="node.conf.letterSpacing"
      v-model:leading="node.conf.leading"
      v-model:x="node.conf.x"
      v-model:y="node.conf.y"
      v-model:rotate="node.conf.rotate"
      v-model:scale="node.conf.scale"
      v-model:opacity="node.conf.opacity"
      v-model:stroke="node.conf.stroke"
      v-model:strokeThickness="node.conf.strokeThickness"
      v-model:background="node.conf.background"
      v-model:backgroundRadius="node.conf.backgroundRadius"
      v-model:backgroundOpacity="node.conf.backgroundOpacity"
      v-model:dropShadow="node.conf.dropShadow"
      v-model:dropShadowAlpha="node.conf.dropShadowAlpha"
      v-model:dropShadowAngle="node.conf.dropShadowAngle"
      v-model:dropShadowBlur="node.conf.dropShadowBlur"
      v-model:dropShadowColor="node.conf.dropShadowColor"
      v-model:dropShadowDistance="node.conf.dropShadowDistance"
      v-model:keyframes="node.conf.keyframes"
      v-model:blend="node.conf.blend"
      v-model:preset="node.conf.preset"
      v-model:animation="node.conf.animation"
      v-model:highlight="node.conf.highlight"
      v-model:wordBoundars="node.conf.wordBoundars"
      :key="`subtitle-${node.id}`"
      :node="node"
      :initialState="node.initialState"
    />
  </div>
</template>
<style scoped>
.attr-container {
  height: 100%;
  background-color: #ffffff;
}
:deep(.attr-content) {
  height: 100%;
  width: 262px;
}
:deep(.color-picker .color-item) {
  margin: 0;
  border-radius: 0 !important;
  border-color: #d5d6d7 !important;
}
:deep(.el-input) {
  height: 30px;
}
:deep(.el-input__inner) {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #060606;
}
:deep(.el-textarea__inner) {
  height: 104px;
  color: #060606;
  font-size: 12px;
  line-height: 20px;
  padding: 12px;
  border: 0.5px solid #bbbfc4;
  outline: 0.5px solid transparent;
  box-shadow: none;
  transition: all 200ms;
}
:deep(.bv-select-button .select-wapper) {
  border: 0.5px solid #bbbfc4;
  outline: 0.5px solid transparent;
}
:deep(.bv-select-button .select-value) {
  color: #060606;
  font-size: 12px;
  line-height: 20px;
}
:deep(.el-input__wrapper) {
  border: 0.5px solid #bbbfc4;
  outline: 0.5px solid transparent;
  box-shadow: none;
  transition: all 200ms;
  padding: 0 12px;
}
:deep(.el-input .el-input__wrapper:hover),
:deep(.bv-select-button .select-wapper:hover),
:deep(.el-textarea__inner:hover) {
  outline-color: #be9fff;
  border-color: #be9fff;
}
:deep(.el-input .el-input__wrapper.is-focus),
:deep(.bv-select-button .select-wapper.border.is-focus),
:deep(.el-textarea__inner:focus) {
  border-color: #875eff !important;
  outline-color: #875eff !important;
  outline-offset: 0;
  box-shadow: none !important;
}
:deep(.el-input__prefix) {
  margin-right: 6px;
  color: #646a73;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
:deep(.el-input__prefix-inner > :last-child) {
  margin: 0;
}
:deep(.col.col-small .select-wapper),
:deep(.col.col-small .el-input__wrapper) {
  padding: 0 8px;
}
:deep(.col.col-medium .el-input__wrapper) {
  padding: 0 10px;
}
:deep(.col.col-single .el-input__wrapper) {
  padding: 0 12px 0 9px;
}
:deep(.col-slider .el-slider) {
  flex: 1 1;
  margin: 0 10px;
}
:deep(.col-slider .el-input) {
  flex: 0 0 60px;
}
:deep(.form) {
  padding: 24px 18px;
}
:deep(.form.keyframe) {
  padding: 24px 14px 24px 18px;
}
:deep(.form + .form) {
  border-top: 1px solid #e8e9ec;
}
:deep(.form-header) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  margin-bottom: 16px;
}
:deep(.form-header.align-right) {
  justify-content: flex-end;
}
:deep(.form-header span),
:deep(.form-title span) {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}
:deep(.form-header .right) {
  display: flex;
  height: 100%;
}
:deep(.form-header .right .icon-button) {
  margin-right: 10px;
}
:deep(.form-title) {
  margin: 24px 0 16px;
}
:deep(.form.has-keyframe .icon-button) {
  margin-right: 4.24px;
}
:deep(.form .col) {
  width: 100%;
  display: flex;
  align-items: center;
}
:deep(.form .col + .col) {
  margin-top: 18px;
}
:deep(.form .col.col-input > :first-child) {
  margin-right: 14px;
}
:deep(.form .col.col-input > div),
:deep(.form .col.col-input > span) {
  flex: 1;
  min-width: 0;
  height: 30px;
  position: relative;
}
:deep(.form .col.col-input.col-single > :first-child) {
  margin-right: 0;
}
:deep(.form .col.col-input > .keyframe) {
  flex: 0 0 38px;
  margin-left: 14px;
}
:deep(.el-collapse-item__header) {
  height: 54px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #060606;
  border-color: #e8e9ec;
  padding: 0 18px;
}
:deep(.el-collapse-item__header.is-active) {
  border-bottom-color: transparent;
}
:deep(.el-collapse-item__arrow) {
  margin: 0 0 0 auto;
}
:deep(.el-collapse-item__content) {
  padding-bottom: 0;
}
:deep(button.plain) {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 0.5px solid #6741FF;
  background-color: #fff;
  color: #6741FF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  transition: background-color 200ms;
}
:deep(button.plain:hover) {
  background-color: #F8F5FF;
}
:deep(.grid-list) {
  display: grid;
  flex-wrap: wrap;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));
}
:deep(.grid-item) {
  width: 68px;
}
:deep(.grid-item .image-wrapper) {
  position: relative;
  width: 68px;
  height: 68px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  overflow: hidden;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}
:deep(.grid-item .image-wrapper:hover) {
  border-color: #a378ff !important;
}
:deep(.grid-item .image-wrapper.active) {
  border-color: #875eff !important;
}
:deep(.grid-item .image-wrapper .background-image) {
  width: 100%;
  height: 100%;
}
:deep(.grid-item .image-wrapper .image-mask) {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.55);
}
:deep(.grid-item .image-wrapper svg) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
:deep(.grid-item .image-wrapper img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
:deep(.grid-item .title) {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  margin-top: 8px;
}
:deep(.form-header .text-button) {
  font-weight: 400;
  line-height: 20px;
  font-size: 12px;
  color: #6741FF;
  cursor: pointer;
}
</style>
