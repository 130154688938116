import { executeMattingDrawing } from './drawing-helper';
import {
  computeRealRadius,
  computeStep,
  computeStepBase,
  getRawDistance,
} from './util';
import {
  EventType,
  GLOBAL_COMPOSITE_OPERATION_DESTINATION_OUT,
  GLOBAL_COMPOSITE_OPERATION_SOURCE_OVER,
} from './constants';
import {
  BoardDrawingConfig,
  BrushDrawingBaseConfig,
  CanDrawAndBindMouseListenerConfig,
  ComputePositionAndMovementConfig,
  ComputeRealPositionConfig,
  DrawingListenerConfig,
  InitDrawingConfig,
  InitDrawingListenerConfig,
  PositionAndMovements,
} from '../types/drawing-listener';
import { MouseMovements, PixelPosition } from '../types/common';
import { InImageRangeConfig } from '../types/drawing';

export function initDrawingListeners(config: InitDrawingListenerConfig) {
  const { listenerManager } = config;
  console.log('listenerManager====', listenerManager)
  const listenerConfig = generateDrawingListenerConfig(config);
  const {
    inputBoardDrawingConfig: { ctx: inputCtx },
    brushDrawingBaseConfig: { positionRange },
  } = listenerConfig;
  const { boardRect, draggingInputBoard } = listenerConfig;
  resetPivotalOptions(listenerConfig);
  const drawingListener = generateDrawingListener(listenerConfig);
  let canDrawAndBindListener = false;
  listenerManager.initMouseListeners({
    mouseTarget: (inputCtx.value as CanvasRenderingContext2D).canvas,
    down(ev: MouseEvent) {
      canDrawAndBindListener = true;
      // canDrawAndBindMoveListener({
      // 	ev,
      // 	boardRect,
      // 	positionRange,
      // 	draggingInputBoard,
      // });

      if (canDrawAndBindListener && !draggingInputBoard) {
        console.log('return canDrawAndBindListener====', draggingInputBoard);
        drawingListener(ev);
        return canDrawAndBindListener;
      }
    },
    move(ev: MouseEvent) {
      console.log('ev======', ev);
      if (!draggingInputBoard && canDrawAndBindListener) {
        drawingListener(ev);
      }
    },
    up(ev: MouseEvent) {
      if (!draggingInputBoard && canDrawAndBindListener) {
        canDrawAndBindListener = false;
        drawingListener(ev);
      }
    },
  });
}

/** 生成绘制监听器的配置对象 */
function generateDrawingListenerConfig(
  config: InitDrawingListenerConfig
): DrawingListenerConfig {
  const {
    imageSources,
    imageSources: { raw, matting },
    initDrawingConfig,
    boardContexts,
    ...restConfig
  } = config;

  const { inputCtx, inputHiddenCtx, inputDrawingCtx } = boardContexts;
  const brushDrawingBaseConfig = generateBrushBaseConfig(initDrawingConfig);
  const inputBoardDrawingConfig: BoardDrawingConfig = {
    ctx: inputCtx,
    hiddenCtx: inputHiddenCtx,
    drawingCtx: inputDrawingCtx,
    mattingSource: matting,
  };

  return {
    brushDrawingBaseConfig,
    mattingSources: imageSources,
    inputBoardDrawingConfig,
    ...restConfig,
  };
}

/** 重置画板配置对象中关键的选项 */
function resetPivotalOptions(config: DrawingListenerConfig) {
  const { inputBoardDrawingConfig } = config;
  const {
    mattingSources: { raw, matting },
    isErasing,
  } = config;
  if (isErasing) {
    inputBoardDrawingConfig.mattingSource = matting;
    inputBoardDrawingConfig.hiddenCtx.value.globalCompositeOperation =
      GLOBAL_COMPOSITE_OPERATION_DESTINATION_OUT;
  } else {
    inputBoardDrawingConfig.mattingSource = raw;
    inputBoardDrawingConfig.hiddenCtx.value.globalCompositeOperation =
      GLOBAL_COMPOSITE_OPERATION_SOURCE_OVER;
  }
}

/** 生成画笔的基础配置对象 */
function generateBrushBaseConfig(
  config: InitDrawingConfig
): BrushDrawingBaseConfig {
  const {
    radius: rawRadius,
    hardness,
    zoomRatio,
    transformConfig: { scaleRatio, positionRange },
  } = config;

  const radius =
    (computeRealRadius(rawRadius.value) * 2) / scaleRatio / zoomRatio.value;
  const stepBase = computeStepBase(radius);
  const step = computeStep(radius);
  return {
    radius,
    step,
    stepBase,
    scaleRatio,
    positionRange,
    hardness: hardness.value,
    zoomRatio: zoomRatio.value,
  };
}

/** 生成擦补画笔的绘制监听器 */
function generateDrawingListener(config: DrawingListenerConfig) {
  const {
    drawingEndCallback,
    brushDrawingBaseConfig,
    brushDrawingBaseConfig: { step, scaleRatio, zoomRatio, positionRange },
  } = config;

  const { inputBoardDrawingConfig, isErasing } = config;
  let totalMovement = 0;

  return (ev: MouseEvent) => {
    const positionAndMovements = computePositionAndMovements({
      ev,
      scaleRatio,
      zoomRatio,
      positionRange,
    });
    const { movementX, movementY } = positionAndMovements;
    const commonPointConfig = {
      ...brushDrawingBaseConfig,
      ...positionAndMovements,
    };
    totalMovement += getRawDistance(movementX, movementY);
    if (canDrawing(totalMovement, step, ev.type)) {
      totalMovement = 0;
      executeMattingDrawing({
        ...commonPointConfig,
        ...inputBoardDrawingConfig,
        isErasing,
      });
    }
    if (ev.type == EventType.Mouseup) {
      drawingEndCallback();
    }
  };
}

/** 判断是否可以绘制 */
function canDrawing(
  totalMovement: number,
  step: number,
  eventType: string
): boolean {
  return totalMovement >= step || eventType === EventType.MouseDown;
}

/** 计算绘制点坐标位置及鼠标指针水平、垂直移动距离 */
function computePositionAndMovements(
  config: ComputePositionAndMovementConfig
): PositionAndMovements {
  const { ev, scaleRatio, zoomRatio, positionRange } = config;
  const { minX, minY } = positionRange;
  const { movementX, movementY, offsetX, offsetY } = ev;
  const realPosition = computeRealPosition({
    offsetX,
    offsetY,
    minX,
    minY,
    scaleRatio,
    zoomRatio,
  });
  const realMovements = computeRealMovements(
    { movementX, movementY },
    scaleRatio,
    zoomRatio
  );
  return { ...realPosition, ...realMovements };
}

/** 计算相对于真实图像尺寸的鼠标位置 */
function computeRealPosition(config: ComputeRealPositionConfig): PixelPosition {
  const { offsetX, offsetY, minX, minY, scaleRatio, zoomRatio } = config;
  const x = ((offsetX / zoomRatio - minX) / scaleRatio) * 2;
  const y = ((offsetY / zoomRatio - minY) / scaleRatio) * 2;
  return { x, y };
}

/** 计算相对于真实图像尺寸的移动距离 */
function computeRealMovements(
  rawMovements: MouseMovements,
  scaleRatio: number,
  zoomRatio: number
) {
  const { movementX: rawMovementX, movementY: rawMovementY } = rawMovements;
  const movementX = (rawMovementX / scaleRatio) * 2;
  const movementY = (rawMovementY / scaleRatio) * 2;
  return { movementX, movementY };
}

/** 判断是否可以绘制且绑定鼠标移动绘制的监听器 */
function canDrawAndBindMoveListener(
  canDrawAndBindConfig: CanDrawAndBindMouseListenerConfig
) {
  const { ev, boardRect, positionRange, draggingInputBoard } =
    canDrawAndBindConfig;
  const { pageX, pageY } = ev;
  const { left, top } = boardRect;
  const { minX, maxX, minY, maxY } = positionRange;
  const x = computePivot(pageX, left);
  const y = computePivot(pageY, top);
  const inImageRange = isInImageRange({ x, y, minX, maxX, minY, maxY });
  return inImageRange && !draggingInputBoard;
}

/** 判断坐标位置是否在绘制的图像的范围内 */
export function isInImageRange(inRangeConfig: InImageRangeConfig): boolean {
  const { x, y, minX, maxX, minY, maxY } = inRangeConfig;
  return x >= minX && x <= maxX && y >= minY && y <= maxY;
}

/** 计算鼠标的位置对应的像素在图像中的位置 */
function computePivot(pagePivot: number, leftOrTop: number) {
  return pagePivot - leftOrTop;
}
