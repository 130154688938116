<script setup>
import Segment from "./segment.vue";
import { useTrackStore } from "@/store/modules/track"
import { useAssetModal } from "../../utils/instance";
import { replaceSource } from "../../utils/node";
import { useHistoryStore, useDraftStore } from "../../stores";

defineEmits(["update:start", "update:end","update:keyframes", "update:mask"]);
const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  start: {
    type: Number,
    default: 0,
  },
  end: {
    type: Number,
    default: 0,
  },
  keyframes: {
    type: Object,
    default: {},
  },
  mask: {
    type: Object,
    default: {},
  },
  src: {
    type: String,
    default: null,
  },
});

const route = useRoute();
const assetModal = useAssetModal();
const { commit } = useHistoryStore();
const { updateDraft } = useDraftStore();
const { collectData, track } = useTrackStore();

const url = ref(props.src);
const uniqueOptions = [
  {
    label: 'Replace',
    prefix: {
      name: 'icon_replace',
    },
    onClick: () => assetModal.open(route.query.draftId, handleReplace),
  },
];
const style = computed(() => ({
  backgroundImage: `url(${url.value})`,
}));

watch(() => props.src, (newSrc) => {
  url.value = newSrc;
});

function submit() {
  commit();
  updateDraft();
}

function handleReplace(file) {
  replaceSource(props.node, file).then(() => {
    submit();
    collectData("boolvideo_timeline_edit_click", {
      click: "replace_media",
    });
    track("boolvideo_timeline_edit_click");
  });
};
</script>
<template>
  <Segment
    :style="{ background: node.active && node.isPrepared ? '#F3F5F7' : '#99A3A4' }"
    :node="node"
    :start="start"
    :end="end"
    :keyframes="keyframes"
    :mask="mask"
    :unique-options="uniqueOptions"
    @update:start="$emit('update:start', $event)"
    @update:end="$emit('update:end', $event)"
    @update:keyframes="$emit('update:keyframes', $event)"
    @update:mask="$emit('update:mask', $event)"
  >
    <div class="segment-screenshot" :style="style"></div>
  </Segment>
</template>
<style scoped>
.segment-screenshot {
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-repeat: repeat-x;
}
</style>
