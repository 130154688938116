import { createImageFromSprite } from "@/utils";
import productToVideo from './assets/product_to_video.webp';
import ideaToVideo from './assets/idea_to_video.webp';
import blogToVideo from './assets/blog_to_video.webp';
import scriptToVideo from './assets/script_to_video.webp';
import visualToVideo from './assets/visual_to_video.webp';
import templateToVideo from './assets/template_to_video.webp';
import scratch from './assets/scratch.webp';
import iconSpriteUrl from './assets/icon_sprite.webp';

const iconSprite = new Image();
iconSprite.src = iconSpriteUrl;

export default [
  {
    key: '0',
    title: 'Product to video',
    preview: productToVideo,
    icon: createImageFromSprite(iconSprite, new DOMRect(182 * 6, 0, 180, 180)),
    link: {
      path: '/product-to-video',
      text: 'Try product to video',
    },
    itemColor: '#F4F0FF',
    description: 'Turn your product URL into viral videos in seconds! Supports Shopify, Amazon, Etsy, etc.',
  },
  {
    key: '1',
    title: 'Idea to video',
    preview: ideaToVideo,
    icon: createImageFromSprite(iconSprite, new DOMRect(182 * 5, 0, 180, 180)),
    link: {
      path: '/idea-to-video',
      text: 'Try idea to video',
    },
    itemColor: '#FFF4DB',
    description: 'Turn your ideas into animated videos',
  },
  {
    key: '2',
    title: 'Blog to video',
    preview: blogToVideo,
    icon: createImageFromSprite(iconSprite, new DOMRect(182 * 4, 0, 180, 180)),
    link: {
      path: '/blog-to-video',
      text: 'Try blog to video',
    },
    itemColor: '#F4F0FF',
    description: 'Turn your blog into dynamic videos',
  },
  {
    key: '3',
    title: 'Script to video',
    preview: scriptToVideo,
    icon: createImageFromSprite(iconSprite, new DOMRect(182 * 3, 0, 180, 180)),
    link: {
      path: '/script-to-video',
      text: 'Try script to video',
    },
    itemColor: '#FFEFEF',
    description: 'Convert your scripts to video in one click',
  },
  {
    key: '4',
    title: 'Visuals to video',
    preview: visualToVideo,
    icon: createImageFromSprite(iconSprite, new DOMRect(182 * 2, 0, 180, 180)),
    link: {
      path: '/visuals-to-video',
      text: 'Try visuals to video',
    },
    itemColor: '#EAF0FF',
    description: 'Transform images and video clips into dynamic videos',
  },
  {
    key: '5',
    title: 'Templates to video',
    preview: templateToVideo,
    icon: createImageFromSprite(iconSprite, new DOMRect(182 * 1, 0, 180, 180)),
    link: {
      path: '/template-video-list',
      text: 'Try templates to video',
    },
    itemColor: '#EAF6E0',
    description: 'Choose a professional template to generate great videos with your assets',
  },
  {
    key: '6',
    title: 'Start from scratch',
    preview: scratch,
    icon: createImageFromSprite(iconSprite, new DOMRect(0, 0, 180, 180)),
    link: {
      path: '/editor',
      text: 'Start creating',
    },
    itemColor: '#F0F0F0',
    description: 'Start creating with our best online editor',
  },
];
