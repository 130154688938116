export const tipListMap = {
  Starter: [
    '☑️ 30 Video Exports / month',
    '☑️ Unlimited AI Image Tools',
    '☑️ 30min Video Tools Credits / month',
    '☑️ 15,000 Characters / month for Voiceover',
    '☑️ 10 Starter templates',
    '☑️ 10 Brand Kits',
    '☑️ 10 GB Cloud Storage',

  ],
  Standard: [
    '☑️ 200 Video Exports / month',
    '☑️ Unlimited AI Image Tools',
    '☑️ 100min Video Tools Credits / month',
    '☑️ 50,000 Characters / month for Voiceover',
    '☑️ 100+ Standard templates',
    '☑️ Unlimited Brand Kits',
    '☑️ 50 GB Cloud Storage',
  ],
  Pro: [
    '☑️ Unlimited Video Exports / month',
    '☑️ Unlimited AI Image Tools',
    '☑️ 300min Video Tools Credits / month',
    '☑️ 100,000 Characters / month for Voiceover',
    '☑️ 500+ Standard templates',
    '☑️ Unlimited Brand Kits',
    '☑️ 200 GB Cloud Storage',
  ]
};
