import imageBgRemove from './image_bg_remove.webp';
import imageEnhancer from './image_enhancer.webp';
import objectEraser from './object_eraser.mp4';
import objectEraserPoster from './object_eraser_poster.jpg';
import videoBgRemove from './video_bg_remove.mp4';
import videoBgRemovePoster from './video_bg_remove_poster.jpg';
import videoEnhancer from './video_enhancer.mp4';
import videoEnhancerPoster from './video_enhancer_poster.jpg';

export default {
  imageBgRemove,
  imageEnhancer,
  objectEraser: {
    src: objectEraser,
    poster: objectEraserPoster,
  },
  videoBgRemove: {
    src: videoBgRemove,
    poster: videoBgRemovePoster,
  },
  videoEnhancer: {
    src: videoEnhancer,
    poster: videoEnhancerPoster,
  }
};
