<template>
  <div
    class="slogan-container"
    @mouseover="() => (showButton = true)"
    @mouseout="() => (showButton = false)"
  >
    <img
      :style="{ visibility: showButton ? 'visible' : 'hidden' }"
      class="icon add"
      :src="addIcon"
      @click="$emit('add', index)"
      alt=""
    />
    <el-input
      v-model="slogan"
      :placeholder="placeholder"
      show-word-limit
      maxlength="100"
    />
    <img
      :style="{
        visibility: showButton && listLength > 1 ? 'visible' : 'hidden',
      }"
      class="icon remove"
      :src="removeImg"
      @click="$emit('remove', index)"
      @mouseover="() => (removeHover = true)"
      @mouseout="() => (removeHover = false)"
      alt=""
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, defineProps, defineEmits } from "vue";
import addIcon from "../../assets/images/add.svg";
import removeIcon from "../../assets/images/remove.svg";
import removeHoverIcon from "../../assets/images/remove_hover.svg";

const emits = defineEmits(["update:modelValue", "remove", "add"]);
const props = defineProps({
  placeholder: String,
  index: Number,
  listLength: {
    type: Number,
    default: 1,
  },
  modelValue: {
    type: String,
    required: true,
  },
});

const slogan = ref("");
const removeHover = ref(false);
const showButton = ref(false);
const removeImg = computed(() =>
  removeHover.value ? removeHoverIcon : removeIcon,
);

watch(
  () => props.modelValue,
  (value) => (slogan.value = value),
  {
    immediate: true,
  },
);

watch(slogan, (value) => emits("update:modelValue", value));
</script>

<style scoped lang="scss">
.slogan-container {
  display: flex;
  align-items: center;
  margin-left: -46px;
  margin-right: -46px;
}

.icon {
  padding: 6px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  cursor: pointer;
}

.add {
  margin-right: 14px;
  &:hover {
    background: rgba(31, 35, 41, 0.08);
  }
}

.remove {
  margin-left: 14px;
  &:hover {
    border: 1px solid #ff5449;
    background: #ffe4e2;
  }
}
</style>
