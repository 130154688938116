<template>
  <el-dialog
    modal-class="cloud-asset-container"
    destroy-on-close
    :close-on-click-modal="false"
    v-model="dialogVisible"
    :show-close="false"
    :align-center="true"
    width="65%"
  >
    <template #header>
      <slot name="header" v-if="slots['header']"></slot>
      <div class="header-container" v-else>
        <p>{{ title }}</p>
        <CloseIcon @close="handleCloseDialog" />
      </div>
    </template>

    <div class="content-container">
      <slot name="filter"></slot>
      <el-scrollbar>
        <div
          class="scroll-container"
          v-loading="loading && !reLoading"
          v-infinite-scroll="handleRequest"
          :infinite-scroll-immediate="false"
          :infinite-scroll-disabled="disabled"
          :infinite-scroll-distance="1"
        >
          <slot></slot>
        </div>
      </el-scrollbar>
    </div>

    <template #footer>
      <div class="flex justify-center items-center">
        <primary-button
          size="small"
          :showDefaultIcon="false"
          @click="handleNext"
          :disabled="!innerActiveButton"
        >
          <p style="width: 108px" class="text-sm font-normal leading-22">
            {{ footerText }}
          </p>
        </primary-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, watch, useSlots } from 'vue';
const slots = useSlots();
import CloseIcon from '@/components/common/dialogCloseIcon.vue';

const emit = defineEmits(['close', 'next']);
const props = defineProps({
  activeButton: Boolean,
  confirmNoClose: Boolean,
  title: {
    type: String,
    default: 'My cloud',
  },
  footerText: {
    type: String,
    default: 'Next',
  },
  httpRequest: {
    type: Function,
  },
  pagination: Object,
  visible: Boolean,
});

const dialogVisible = ref(props.visible);
const disabled = ref(false);
const loading = ref(false);
const reLoading = ref(false);
const innerActiveButton = ref(false);
const innerPagination = reactive({
  size: 40,
  page: 1,
});

watch(
  () => props.activeButton,
  (activeButton) => {
    innerActiveButton.value = activeButton;
  },
  {
    immediate: true,
  }
);

watch(
  () => props.pagination,
  (pagination) => {
    innerPagination.page = pagination.page;
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => props.visible,
  (visible) => {
    dialogVisible.value = visible;
  },
  {
    immediate: true,
  }
);

// 触底更新
const handleRequest = () => {
  reLoading.value = true;
  innerPagination.page = innerPagination.page + 1;
  props.httpRequest(innerPagination.page);
};

const handleCloseDialog = () => {
  emit('close', true);
};

// 替换
const handleNext = () => {
  emit('next');
  if (!props.confirmNoClose) {
    emit('close', true);
  }
};
</script>

<style lang="scss" scoped>
.cloud-asset-container {
  .header-container {
    position: relative;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #060606;
  }

  .content-container {
    min-height: 350px;
    height: 100%;
  }

  .scroll-container {
    padding: 0px 20px;
    overflow: auto;
    height: 100%;
  }

  .el-scrollbar {
    height: calc(100% - 44px);
  }
}
</style>

<style lang="scss">
.cloud-asset-container {
  .el-dialog {
    margin: auto;
    height: 80%;
    min-width: 507px;

    .el-dialog__header {
      padding: 24px;
      margin: 0;
    }

    .el-dialog__body {
      padding: 0px 0px;
      height: calc(100% - 164px);
    }

    .el-dialog__footer {
      padding: 20px;
    }
  }
}
</style>
