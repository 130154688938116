<template>
  <div class="waiting-container">
    <div v-show="loading">
      <div class="svg-box">
        <svg-icon
          name="icon_loading"
          :style="{ width: '48px', height: '48px' }"
          class="animate-spin duration-300"
        ></svg-icon>
      </div>
      <p class="tip">Waiting for checking payment...</p>
    </div>
    <div v-show="!loading">
      <div class="svg-box">
        <svg-icon
          name="icon_payment_success"
          :style="{ width: '48px', height: '48px' }"
          :class="{ 'enter-from-bottom': true, 'slide-enter': !loading }"
        ></svg-icon>
      </div>
      <p class="tip">Thank you for subscribing.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getLtdOrderState } from "@/api/premium";
import { useTrackStore } from "@/store/modules/track";
import { useHead } from "@vueuse/head";

const { collectData, track } = useTrackStore();
const router = useRouter();
const route = useRoute();
const loading = ref(true);
const orderId = ref(0);
const packageCode = ref(0);
const timer: Ref<any> = ref(null);
const packageMap = {
  300204: "standard_ltd",
  300304: "pro_ltd",
  300202: "standard_annual",
  300302: "pro_annual",
};

useHead({
  title: route.meta.title,
  meta: [
    {
      property: "og:title",
      content: route.meta.title,
    },
    {
      name: "twitter:title",
      content: route.meta.title,
    },
  ],
});
const polling = async (id: number) => {
  await getLtdOrderState({ order_id: id }).then((res) => {
    if (res.data.state === "FINISH") {
      clearTimeout(timer.value);
      timer.value = null;
      loading.value = false;
      collectData("boolvideo_deal_subscribe_click", {
        click: "subscribe_confirm",
        plan_name: packageMap[packageCode.value as keyof typeof packageMap],
      });
      track("boolvideo_deal_subscribe_click");
      gtag("event", "golden_deal_success");

      setTimeout(() => {
        router.replace({ path: "/login" });
      }, 2000);
      return;
    } else {
      timer.value = setTimeout(async () => {
        await polling(id);
      }, 2000);
    }
  });
};

onMounted(() => {
  const email = localStorage.getItem("user_email");
  // window.fpr("referral", { email: email });

  loading.value = true;
  orderId.value = parseInt(route.query?.orderId as string, 10);
  packageCode.value = parseInt(route.query?.packageCode as string, 10);
  polling(orderId.value);
});

onBeforeUnmount(() => {
  clearTimeout(timer.value);
  timer.value = null;
});
</script>

<style scoped lang="scss">
.waiting-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.svg-box {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.tip {
  font-size: 20px;
  font-weight: 600;
}

.enter-from-bottom {
  transform: translateY(50%);
}

.slide-enter {
  transition: transform 0.5s;
  transform: translateY(0);
}
</style>
