<script setup>
import { render } from "vue";
import Cor from "@boolv/creator";
import {
  useCreatorStore,
  useNodeStore,
  useCopyStore,
  useDraftStore,
  useHistoryStore,
  useAttrTrackStore,
  useDrag,
  useKeyboard,
} from "../../stores";
import { getComputedSize, getBounds, clamp, courtship } from "../../utils";
import { unrefElement } from "../../composables";
import { useTrackStore } from "@/store/modules/track";
import { throttled } from "@/utils";
import Keyframe from "./keyframe.vue";

const emit = defineEmits([
  "update:start",
  "update:end",
  "update:ss",
  "update:keyframes",
  "update:mask",
]);
const props = defineProps({
  y: {
    type: Number,
    default: 0,
  },
  start: {
    type: Number,
    default: 0,
  },
  end: {
    type: Number,
    default: 0,
  },
  ss: {
    type: Number,
    default: 0,
  },
  draggable: {
    type: Boolean,
    default: true,
  },
  alignable: {
    type: Boolean,
    default: true,
  },
  symmetry: {
    type: Boolean,
    default: false,
  },
  handlers: {
    type: Array,
    default: () => ["ml", "mr"],
  },
  style: {
    type: Object,
    default: {},
  },
  node: {
    type: Object,
    default: null,
  },
  keyframes: {
    type: Object,
    default: null,
  },
  mask: {
    type: Object,
    default: null,
  },
  uniqueOptions: {
    type: Array,
    default: () => [],
  },
});

const {
  empty,
  magnet,
  magnetMap,
  autoSnap,
  primaryNode,
  attrTabMap,
  zIndexDelta,
  timeline,
  nodeMap,
  displayFrame,
  currentFrame,
  creator,
  activeNodeMap,
  frameToWidth,
  widthToFrame,
  secondToFrame,
  removeActiveNodes,
  getTrack,
  createTrack,
  refresh,
  seekTo,
  tracks,
  nodes,
  setActiveNode,
  removeNode,
  getNode,
  addTransitionNode,
  adsorb,
} = useCreatorStore();
const { updateDraft } = useDraftStore();
const { copy, cut, setCopyedKeyframe } = useCopyStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();
const { pressed } = useKeyboard();
const { collectData, track, clearEventData } = useTrackStore();
useNodeStore(props.node);
useAttrTrackStore(props.node);

const timelineRef = inject("timeline");
const scrollRef = inject("scrollRef");
const refLineY = inject("refLineY");
const refLineLeft = inject("refLineLeft");
const refLineRight = inject("refLineRight");
const showRefLineY = inject("showRefLineY");
const showRefLineLeft = inject("showRefLineLeft");
const showRefLineRight = inject("showRefLineRight");
const scrollElement = scrollRef.value.wrapRef;

const ticker = ref(null);
const scrollX = ref(0);
const scrollY = ref(0);

const frame = ref(-1);
const left = ref(frameToWidth(props.start));
const top = ref(props.y);
const right = ref(null);

const width = ref(frameToWidth(props.end - props.start));
const parentWidth = ref(null);

const trusted = ref(false);
const moveStart = ref(false);
const moving = ref(false);
const dragStart = ref(false);
const dragging = ref(false);
const resizeStart = ref(false);
const resizing = ref(false);
const scrollStart = ref(false);
const scrolling = ref(false);
const aligning = ref(false);

const segment = ref(null);
const currentHandler = ref(null);

const beforePosition = reactive({
  pageX: 0,
  pageY: 0,
  width: 0,
  height: 0,
  frame: -1,
  ss: 0,
});
const dragBounds = ref(null);
const scrollBounds = ref(null);
const resizeBounds = ref(null);

const shadow = ref(null);
const currentTrack = ref(null);
const trackIndex = ref(null);
const overlay = ref(false);
const overlayElement = ref(null);

const contextMenuOptions = ref([
  {
    label: "Copy",
    prefix: {
      name: "icon_copy",
    },
    onClick: copy,
  },
  {
    label: "Cut",
    prefix: {
      name: "icon_cut",
    },
    onClick: () => {
      cut();
      submit();
    },
  },
  {
    label: "Delete",
    prefix: {
      name: "icon_delete",
    },
    onClick: () => {
      removeActiveNodes();
      submit();
    },
  },
]);

const isMask = computed(() => attrTabMap[props.node.id] === "mask");
const isDragTarget = computed(() => props.node.id === "dragTarget");
const shouldMagnet = computed(
  () => magnet.value && props.node.parent && props.node.parent.type === "scene"
);
const draggable = computed(() => props.draggable && props.node.isPrepared);
const active = computed(
  () => activeNodeMap.has(props.node.id) && props.node.isPrepared
);
const style = computed(() => ({
  left: `${left.value}px`,
  top: `${top.value}px`,
  width: `${width.value}px`,
  ...props.style,
}));

onMounted(() => {
  const parentElement = segment.value.parentElement;
  const { uniqueOptions } = props;
  const [pw] = getComputedSize(parentElement);

  if (uniqueOptions) {
    contextMenuOptions.value = [...uniqueOptions, ...contextMenuOptions.value];
  }
  parentWidth.value = pw;
  right.value = pw - width.value - left.value;

  resetPosition();

  if (nodes.value.length > 0) {
    segment.value.scrollIntoView({ block: "center" });
  }
  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp, true);
  segment.value.addEventListener("align", handleAlign);
});

onBeforeUnmount(() => {
  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp, true);
  segment.value.removeEventListener("align", handleAlign);

  stopScroll();
  segment.value = null;
  currentHandler.value = null;
});

watch(
  [() => props.start, () => props.end],
  ([newStart, newEnd], [oldStart]) => {
    const { node, keyframes, mask } = props;
    const delta = newStart - oldStart;

    node.setDuration(newStart, newEnd);

    if (resizing.value) {
      return;
    }
    if (keyframes) {
      const newKeyframes = {};

      for (const key of Object.keys(keyframes)) {
        const frameString = (parseInt(key) + delta).toString();
        newKeyframes[frameString] = keyframes[key];
      }
      emit("update:keyframes", newKeyframes);
    }
    if (mask && mask.keyframes) {
      const newKeyframes = {};

      for (const key of Object.keys(mask.keyframes)) {
        const frameString = (parseInt(key) + delta).toString();
        newKeyframes[frameString] = mask.keyframes[key];
      }
      emit("update:mask", { ...mask, keyframes: newKeyframes });
    }
  }
);
watch(
  [() => props.start, () => props.end, () => timeline.frameWidth],
  ([newStart, newEnd]) => {
    if (dragging.value || resizing.value) {
      return;
    }
    left.value = frameToWidth(newStart);
    width.value = frameToWidth(newEnd - newStart);
    right.value = parentWidth.value - width.value - left.value;
  }
);
watch(
  () => dragData.entered,
  (entered) => {
    if (isDragTarget.value && !entered) {
      removeShadow();
      removeOverlay();
      render(null, dragData.target);
      showRefLineLeft.value = false;
      showRefLineRight.value = false;
      showRefLineY.value = false;
      dragData.target = dragData.dragNode;
    }
  }
);

watch(
  () => timeline.frameWidth,
  () => nextTick(resetPosition)
);
watch(magnetMap, () => {
  const node = props.node;
  const parent = node.parent;
  const start = magnetMap.get(node.id);

  if (
    dragging.value ||
    start === undefined ||
    !parent ||
    (parent.type !== "scene" && node.type !== "transition")
  ) {
    return;
  }
  const newLeft = frameToWidth(start);
  beforePosition.left = newLeft;
  beforePosition.right = parentWidth.value - width.value - newLeft;
  magnetMap.delete(node.id);
});

function handleAlign(e) {
  const { isAdsorptionLeft, isAdsorptionRight, justify, delta } = e.detail;

  if (isAdsorptionLeft) {
    left.value = left.value + delta;

    if (justify) {
      right.value = parentWidth.value - width.value - left.value;
    }
    width.value = computeWidth(parentWidth.value, left.value, right.value);
  }
  if (isAdsorptionRight) {
    right.value = right.value + delta;

    if (justify) {
      left.value = parentWidth.value - width.value - right.value;
    }
    width.value = computeWidth(parentWidth.value, left.value, right.value);
  }
  aligning.value = isAdsorptionLeft || isAdsorptionRight;
}

function mouseMove(e) {
  if (moveStart.value) {
    move(e);
  } else if (dragStart.value) {
    drag(e);
  } else if (resizeStart.value) {
    resize(e);
  }
}

function mouseUp() {
  if (moveStart.value && !moving.value && frame.value >= 0) {
    seekTo(frame.value);
  }
  trusted.value = false;
  moveStart.value = false;
  dragStart.value = false;
  resizeStart.value = false;
  currentHandler.value = null;
  showRefLineLeft.value = false;
  showRefLineRight.value = false;
  showRefLineY.value = false;

  if (moving.value) {
    moving.value = false;
    updateKeyframes();
    frame.value = -1;
  }
  if (dragging.value) {
    if (shadow.value) {
      left.value = getBounds(shadow.value)[0];
    }
    if (empty.value) {
      left.value = 0;
    }
    top.value = 0;
    dragging.value = false;
    handleDragEnd();
  }
  if (resizing.value) {
    resizing.value = false;
    handleResizeEnd();
  }
  stopScroll();
  resetPosition();
}

function keyDown(e, frame) {
  switch (e.code) {
    case "Delete":
    case "Backspace":
      removeKeyframe(frame);
      break;
    case "KeyC":
      pressed.value && copyKeyframe(frame);
      break;
  }
}

function segmentDown(e) {
  window.removeEventListener("mouseup", mouseUp, true);
  window.addEventListener("mouseup", mouseUp, true);

  if (e.isTrusted && active.value) {
    primaryNode.value = props.node;
    const trackElements = document.querySelectorAll(".track");

    for (let i = trackElements.length - 1; i >= 0; i--) {
      const track = trackElements[i];
      const elements = track.querySelectorAll(".segment.active");

      for (const element of elements) {
        if (element === segment.value) continue;
        element.dispatchEvent(new MouseEvent("mousedown", e));
      }
    }
  }
  if (draggable.value) {
    dragStart.value = true;
  }
  savePosition(e);
  calcDragBounds();
  calcScrollBounds();
}

function handlerDown(e, handler) {
  window.removeEventListener("mouseup", mouseUp, true);
  window.addEventListener("mouseup", mouseUp, true);

  resizeStart.value = true;
  currentHandler.value = handler;

  if (e.isTrusted) {
    primaryNode.value = props.node;
    const elements = document.querySelectorAll(
      `.segment.active .handler-${handler}`
    );

    for (const element of elements) {
      if (element.parentElement === segment.value) continue;
      element.dispatchEvent(new MouseEvent("mousedown", e));
    }
  }
  savePosition(e);
  calcResizeBounds();
}

function keyframeDown(e, frameString) {
  if (e.button !== 0) return;

  const currentFrame = parseInt(frameString);

  moveStart.value = true;
  frame.value = currentFrame;
  savePosition(e);
}

function move(e) {
  moving.value = true;

  const delta = widthToFrame(e.pageX - beforePosition.pageX);
  const newFrame = clamp(beforePosition.frame + delta, props.start, props.end);
  frame.value = newFrame;
}

function drag(e) {
  const node = props.node;
  const bounds = dragBounds.value;
  const scrollLeft = beforePosition.scrollLeft - scrollElement.scrollLeft;
  const scrollTop = beforePosition.scrollTop - scrollElement.scrollTop;
  const deltaX = beforePosition.pageX - e.pageX + scrollLeft;
  const deltaY = beforePosition.pageY - e.pageY + scrollTop;

  if (deltaX === 0 && deltaY === 0) {
    return;
  }
  if (!active.value) {
    setActiveNode(node);
  }
  if (!segment.value.classList.contains("active")) {
    return;
  }
  if (!dragging.value && trusted.value && shouldMagnet.value) {
    adsorb(true);
  }
  dragging.value = true;

  if (magnetMap.size > 0) {
    return;
  }
  let newLeft = Math.max(bounds.minLeft, beforePosition.left - deltaX);
  let newRight = parentWidth.value - width.value - newLeft;
  const newTop = beforePosition.top - deltaY;

  if (trusted.value) {
    if (autoSnap.value && props.alignable) {
      [newLeft, newRight] = snap(newLeft, newRight, 10);
    }
    scroll(e);
  }
  if (aligning.value) {
    newLeft = left.value;
    newRight = right.value;
  }
  left.value = newLeft;
  right.value = newRight;
  top.value = newTop;

  if (trusted.value && props.alignable) {
    const timelineElement = unrefElement(timelineRef);
    const trackElements = document.querySelectorAll(".track");
    const timelineBox = timelineElement.getBoundingClientRect();
    const n = trackElements.length;
    let clientX = snapToGrid(newLeft);
    let isOverlay = false,
      overlayElement = null,
      valid = false,
      trackIndexRef = null,
      showRefLineYRef = false;

    for (let i = 0; i < n; i++) {
      const index = n - i - 1;
      const trackElement = trackElements[i];
      const prevTrackElement = trackElements[i - 1];
      const track = toRaw(tracks.value[index]);
      const shouldMagnet = magnet.value && track.kind === "primary";
      const trackBox = trackElement.getBoundingClientRect();
      const elements = trackElement.querySelectorAll(
        ".segment.alignable:not(.active)"
      );

      if (
        track.valid(node) &&
        trackBox.top <= e.pageY &&
        e.pageY <= trackBox.bottom
      ) {
        valid = true;
        currentTrack.value = track;
        zIndexDelta.value = track.getZIndex() - node.getZIndex();

        if (elements.length === 0 && shouldMagnet) {
          clientX = 0;
        }
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          const bounding = segment.value.getBoundingClientRect();
          const x = parseFloat(element.dataset.left);
          const [w] = getComputedSize(element);
          const r = x + w;
          const right = clientX + width.value;
          const cursorX = clientX + e.pageX - bounding.x;
          const center = x + w / 2;

          if (cursorX < center) {
            if (x < right) {
              if (shouldMagnet) {
                clientX = x;
              }
              overlayElement = element;
              isOverlay = true;
              break;
            }
          } else if (clientX < r) {
            const next = elements[i + 1];
            clientX = r;
            isOverlay = true;

            if (next && parseFloat(next.dataset.left) < clientX + width.value) {
              overlayElement = next;
            }
            break;
          } else if (i === elements.length - 1 && shouldMagnet) {
            clientX = r;
          }
        }
        setOverlay(overlayElement, clientX + width.value);
        setShadow(trackElement, clientX);
        break;
      } else if (e.pageY <= trackBox.top) {
        if (prevTrackElement) {
          const prevTrackBox = prevTrackElement.getBoundingClientRect();

          if (prevTrackBox.bottom <= e.pageY) {
            showRefLineYRef = true;
            trackIndexRef = index + 1;
            refLineY.value =
              scrollElement.scrollTop +
              prevTrackBox.bottom -
              timelineBox.top +
              4;
          }
        } else {
          showRefLineYRef = true;
          trackIndexRef = index + 1;
          refLineY.value =
            scrollElement.scrollTop + trackBox.top - timelineBox.top - 4;
        }
        break;
      } else if (e.pageY > trackBox.bottom && track.kind === "primary") {
        showRefLineYRef = true;
        trackIndexRef = index + 1;
        refLineY.value =
          scrollElement.scrollTop + trackBox.top - timelineBox.top - 4;
        break;
      }
    }
    if (!overlayElement) {
      removeOverlay();
    }
    if (!valid) {
      removeShadow();
    }
    overlay.value = isOverlay;
    trackIndex.value = trackIndexRef;
    showRefLineY.value = showRefLineYRef;
  }
}

function resize(e) {
  const node = props.node;
  const deltaX = snapToGrid(beforePosition.pageX - e.pageX);

  if (deltaX === 0) {
    return;
  }
  resizing.value = true;

  const handler = currentHandler.value;
  const bounds = resizeBounds.value;

  let newLeft = left.value;
  let newRight = right.value;

  if (handler[1] === "l") {
    newLeft = clamp(
      beforePosition.left - deltaX,
      bounds.minLeft,
      bounds.maxLeft
    );
    if (props.symmetry) {
      newRight = right.value - (left.value - newLeft);
    }
  } else if (handler[1] === "r") {
    newRight = clamp(
      beforePosition.right + deltaX,
      bounds.minRight,
      bounds.maxRight
    );
    if (props.symmetry) {
      newLeft = left.value - (right.value - newRight);
    }
  }
  if (autoSnap.value && trusted.value && props.alignable) {
    [newLeft, newRight] = snap(newLeft, newRight, 5, handler[1]);
  }
  if (aligning.value) {
    newLeft = left.value;
    newRight = right.value;
  }

  newLeft = clamp(newLeft, bounds.minLeft, bounds.maxLeft);
  newRight = clamp(newRight, bounds.minRight, bounds.maxRight);
  const newWidth = computeWidth(parentWidth.value, newLeft, newRight);

  left.value = newLeft;
  right.value = newRight;
  width.value = newWidth;

  const start = widthToFrame(newLeft);
  const duration = widthToFrame(newWidth);
  const end = start + duration;
  const deltaLeft = beforePosition.left - newLeft;
  const deltaRight = beforePosition.right - newRight;

  if (handler[1] === "l" && ["video", "audio", "speech"].includes(node.type)) {
    const info = node.getInfo();
    const speed = node.getSpeed();
    const newSs = clamp(
      beforePosition.ss - Math.floor(widthToFrame(deltaLeft) * speed),
      0,
      secondToFrame(info.duration / speed) - 1
    );

    emit("update:ss", newSs);
  }
  emit("update:start", start);
  emit("update:end", end);

  if (trusted.value) {
    if (shouldMagnet.value) {
      if (handler[1] === "l") {
        let prev = segment.value;

        while ((prev = prev.previousElementSibling)) {
          const node = nodeMap.get(prev.dataset.id);
          const duration = node.getDuration();
          const start = widthToFrame(parseFloat(prev.dataset.left) - deltaLeft);

          node.conf.start = start;
          node.conf.end = start + duration;
        }
      } else if (handler[1] === "r") {
        let next = segment.value;

        while ((next = next.nextElementSibling)) {
          const node = nodeMap.get(next.dataset.id);
          const duration = node.getDuration();
          const start = widthToFrame(
            parseFloat(next.dataset.left) + deltaRight
          );

          node.conf.start = start;
          node.conf.end = start + duration;
        }
      }
    }
    displayFrame.value = handler[1] === "l" ? start : end;
  }
}

async function handleDragEnd() {
  const elements = document.querySelectorAll(".segment.dragging");
  const start = widthToFrame(left.value);
  const duration = props.end - props.start;
  const end = start + duration;
  let node;

  if (isDragTarget.value) {
    const { type, conf } = props.node;
    const Node = Cor.NODE_MAP[type];
    node = new Node({ ...conf, start, end });
    render(null, dragData.target);
  } else {
    node = toRaw(props.node);
    emit("update:start", start);
    emit("update:end", end);
    await nextTick();
  }
  const parent = node.parent;
  const { track, used } = getOrCreateTrack(node);

  if (shadow.value) removeShadow();
  if (overlayElement.value) overlayElement.value = null;
  if (showRefLineY.value) showRefLineY.value = false;
  removeTransitionIfNeeded(node, start, end, track);

  if (node.type === "transition") {
    if (!isDragTarget.value) {
      removeNode(node, { skipTrigger: true, skipRefresh: true });
    }
    await addTransitionNode(node.conf, start, { skipRender: true });
  } else if (track.kind === "primary") {
    if (parent && parent.type === "scene") {
      track.addChild(parent);
    } else {
      const scene = new Cor.Scene();
      track.addChild(scene);
      scene.addChild(node);
      await scene.start(false, false);
    }
  } else {
    track.addChild(node);
  }
  if (parent && parent.children.length === 0) {
    parent.destroy();
  }
  creator.value.annotate();
  removeTransitionIfNeeded(node, start, end);
  triggerRef(creator);

  if (!used) await track.start(false, false);
  if (isDragTarget.value && node.type !== "transition") await node.start();

  if (elements.length <= 1) {
    zIndexDelta.value = 0;
    creator.value.refresh();
    submit();
  }
  trackTimeChangeHelper();
}

function removeTransitionIfNeeded(node, start, end, track) {
  const shallowNode = getNode(node, false);
  const prevSibling = shallowNode.prevSibling;
  const nextSibling = shallowNode.nextSibling;

  if (prevSibling && prevSibling.type === "transition") {
    if (
      (track && track !== shallowNode.parent) ||
      start !== prevSibling.prevSibling.endFrame
    ) {
      removeNode(prevSibling, { skipTrigger: true, skipRefresh: true });
    }
  }
  if (nextSibling && nextSibling.type === "transition") {
    if (
      (track && track !== shallowNode.parent) ||
      end !== nextSibling.nextSibling.startFrame
    ) {
      removeNode(nextSibling, { skipTrigger: true, skipRefresh: true });
    }
  }
}

function handleResizeEnd() {
  const { node, keyframes, mask, start, end } = props;
  const elements = document.querySelectorAll(".segment.resizing");
  const shallowNode = getNode(node, false);
  const prevSibling = shallowNode.prevSibling;
  const nextSibling = shallowNode.nextSibling;
  const duration = end - start;
  const maxTransDuration = courtship(duration / 2);

  if (keyframes) {
    const newKeyframes = { ...keyframes };

    for (const key of Object.keys(keyframes)) {
      const frame = parseInt(key);

      if (frame < props.start || frame > props.end) {
        delete newKeyframes[frame];
      }
    }
    emit("update:keyframes", newKeyframes);
  }
  if (mask && mask.keyframes) {
    const newKeyframes = { ...mask.keyframes };

    for (const key of Object.keys(mask.keyframes)) {
      const frame = parseInt(key);

      if (frame < props.start || frame > props.end) {
        delete newKeyframes[frame];
      }
    }
    emit("update:mask", { ...mask, keyframes: newKeyframes });
  }
  if (prevSibling && prevSibling.type === "transition") {
    if (start > prevSibling.prevSibling.endFrame || duration < 4) {
      removeNode(prevSibling, { skipRefresh: true });
    } else if (prevSibling.getDuration() > maxTransDuration) {
      prevSibling.conf.start = start - maxTransDuration / 2;
      prevSibling.conf.end = start + maxTransDuration / 2;
    }
  }
  if (nextSibling && nextSibling.type === "transition") {
    if (end < nextSibling.nextSibling.startFrame || duration < 4) {
      removeNode(nextSibling, { skipRefresh: true });
    } else if (nextSibling.getDuration() > maxTransDuration) {
      nextSibling.conf.start = start - maxTransDuration / 2;
      nextSibling.conf.end = start + maxTransDuration / 2;
    }
  }
  if (elements.length <= 1) {
    if (shouldMagnet.value) {
      adsorb();
    }
    nextTick(refresh);
    submit();
  }
  displayFrame.value = currentFrame.value;
}

function snap(l, r, asp, alignment = "lr") {
  const parentElement = segment.value.parentElement;
  const unactiveElements = document.querySelectorAll(".segment:not(.active)");
  const activeElements = document.querySelectorAll(".segment.active");
  const alignLeft = alignment.includes("l");
  const alignRight = alignment.includes("r");
  const justify = alignLeft && alignRight;
  const width = computeWidth(parentWidth.value, l, r);

  let newLeft = l;
  let newRight = r;
  let isAdsorptionLeft = false;
  let isAdsorptionRight = false;
  let lineLeft = 0;
  let lineRight = 0;

  if (!overlay.value) {
    for (const element of unactiveElements) {
      if (element.parentElement === parentElement) continue;

      const [x] = getBounds(element);
      const [w] = getComputedSize(element);
      const r1 = l + width;
      const r2 = x + w;
      const rl = x - r1;
      const lr = l - r2;
      const ll = l - x;
      const rr = r1 - r2;

      if (alignLeft) {
        if (-asp <= lr && lr <= asp) {
          isAdsorptionLeft = true;
          newLeft = lineLeft = r2;
          if (justify) {
            newRight = parentWidth.value - width - newLeft;
          }
          break;
        } else if (-asp <= ll && ll <= asp) {
          isAdsorptionLeft = true;
          newLeft = lineLeft = x;
          if (justify) {
            newRight = parentWidth.value - width - newLeft;
          }
          break;
          // console.log('[ll]', { ll, rr, newLeft, newRight })
        }
      }
      if (alignRight) {
        if (-asp <= rl && rl <= asp) {
          isAdsorptionRight = true;
          newRight = parentWidth.value - x;
          lineRight = x;
          if (justify) {
            newLeft = parentWidth.value - width - newRight;
          }
          break;
        } else if (-asp <= rr && rr <= asp) {
          isAdsorptionRight = true;
          newRight = parentWidth.value - r2;
          lineRight = r2;
          if (justify) {
            newLeft = parentWidth.value - width - newRight;
          }
          break;
          // console.log('[rr]', { ll, rr, newLeft, newRight, isAdsorptionLeft, isAdsorptionRight })
        }
      }
    }
  }
  for (const element of activeElements) {
    if (element === segment.value) {
      continue;
    }
    const detail = { isAdsorptionLeft, isAdsorptionRight, justify };
    const event = { detail };

    if (isAdsorptionLeft) {
      detail.delta = newLeft - left.value;
    } else if (isAdsorptionRight) {
      detail.delta = newRight - right.value;
    }
    element.dispatchEvent(new CustomEvent("align", event));
  }

  showRefLineLeft.value = isAdsorptionLeft;
  showRefLineRight.value = isAdsorptionRight;
  refLineLeft.value = lineLeft;
  refLineRight.value = lineRight;

  return [newLeft, newRight];
}

function scroll(e) {
  const bounds = scrollBounds.value;

  if (e.pageX <= bounds.minLeft && scrollElement.scrollLeft > 0) {
    scrollX.value = -10;
  } else if (
    e.pageX >= bounds.minRight &&
    scrollElement.scrollLeft < bounds.maxLeft
  ) {
    scrollX.value = 10;
  } else {
    scrollX.value = 0;
  }
  if (e.pageY <= bounds.minTop && scrollElement.scrollTop > 0) {
    scrollY.value = -5;
  } else if (
    e.pageY >= bounds.minBottom &&
    scrollElement.scrollTop < bounds.maxTop
  ) {
    scrollY.value = 5;
  } else {
    scrollY.value = 0;
  }
  if (scrollX.value || scrollY.value) {
    startScroll();
  } else {
    stopScroll();
  }
}

function tick() {
  const bounds = scrollBounds.value;
  scrolling.value = true;

  if (scrollX.value) {
    if (
      (scrollX.value < 0 && scrollElement.scrollLeft <= 0) ||
      (scrollX.value > 0 && scrollElement.scrollLeft >= bounds.maxLeft)
    ) {
      scrollX.value = 0;
    }
    let newLeft = left.value;
    let newRight = right.value;

    newLeft = left.value + scrollX.value;
    newRight = right.value - scrollX.value;

    left.value = newLeft;
    right.value = newRight;
    width.value = computeWidth(parentWidth.value, newLeft, newRight);
    scrollElement.scrollLeft = clamp(
      scrollElement.scrollLeft + scrollX.value,
      0,
      bounds.maxLeft
    );
  }
  if (scrollY.value) {
    if (
      (scrollY.value < 0 && scrollElement.scrollTop <= 0) ||
      (scrollY.value > 0 && scrollElement.scrollTop >= bounds.maxTop)
    ) {
      scrollY.value = 0;
    }
    let newTop = top.value;

    newTop = top.value + scrollY.value;

    top.value = newTop;
    scrollElement.scrollTop = clamp(
      scrollElement.scrollTop + scrollY.value,
      0,
      bounds.maxTop
    );
  }

  if (scrollX.value || scrollY.value) {
    ticker.value = requestAnimationFrame(tick);
  } else {
    stopScroll();
  }
}

function startScroll() {
  showRefLineLeft.value = false;
  showRefLineRight.value = false;

  if (!scrollStart.value) {
    scrollStart.value = true;
  }
  if (!ticker.value) {
    ticker.value = requestAnimationFrame(tick);
  }
}

function stopScroll() {
  scrollStart.value = false;

  if (scrolling.value) {
    scrolling.value = false;
  }
  if (ticker.value) {
    cancelAnimationFrame(ticker.value);
  }
  ticker.value = null;
}

function calcDragBounds() {
  const bounds = dragBounds.value;
  const elements = document.querySelectorAll(".segment.active");
  let min = left.value;

  if (active.value) {
    for (const element of elements) {
      const [left] = getBounds(element);
      min = Math.min(min, left);
    }
  }
  bounds.minLeft = left.value - min;
}

function calcScrollBounds() {
  const bounds = scrollBounds.value;
  const scrollBox = scrollElement.getBoundingClientRect();

  bounds.minLeft = scrollBox.left + 150;
  bounds.maxLeft = scrollElement.scrollWidth - scrollBox.width - 1;
  bounds.minRight = scrollBox.right - 150;
  bounds.minTop = scrollBox.top + 50;
  bounds.maxTop = scrollElement.scrollHeight - scrollBox.height - 1;
  bounds.minBottom = scrollBox.bottom - 50;
}

function calcResizeBounds() {
  const node = props.node;
  const symmetry = props.symmetry >> 0;
  const bounds = resizeBounds.value;

  bounds.minLeft = 0;
  bounds.maxLeft =
    left.value + (width.value / (symmetry + 1) - timeline.frameWidth);
  bounds.minRight = 0;
  bounds.maxRight =
    right.value + (width.value / (symmetry + 1) - timeline.frameWidth);

  let prev = segment.value.previousElementSibling;
  let next = segment.value.nextElementSibling;

  if (!shouldMagnet.value) {
    while (prev && !prev.classList.contains("alignable")) {
      prev = prev.previousElementSibling;
    }
    while (next && !next.classList.contains("alignable")) {
      next = next.nextElementSibling;
    }
    if (prev) {
      const [x] = getBounds(prev);
      const [w] = getComputedSize(prev);
      bounds.minLeft = x + w * (1 - symmetry);
    }
    if (next) {
      const [x] = getBounds(next);
      const [w] = getComputedSize(next);
      bounds.minRight = parentWidth.value - x - w * symmetry;
    }
  }
  switch (node.type) {
    case "transition": {
      const { prevSibling, nextSibling } = props.node;
      const minDuration = Math.min(
        prevSibling.getDuration(),
        nextSibling.getDuration()
      );
      const maxDuration = courtship(minDuration / 2);
      const maxWidth = frameToWidth(maxDuration);
      const minLeft = left.value - (maxWidth - width.value) / 2;
      const minRight = right.value - (maxWidth - width.value) / 2;

      bounds.minLeft = minLeft;
      bounds.minRight = minRight;
      break;
    }
    case "video":
    case "audio":
    case "speech": {
      const info = node.getInfo();
      const ss = node.getSs();
      const speed = node.getSpeed();
      const ssWidth = frameToWidth(ss / speed);
      const durationWidth = frameToWidth(secondToFrame(info.duration / speed));
      const minLeft = left.value - ssWidth;
      const minRight = parentWidth.value - minLeft - durationWidth;

      bounds.minLeft = Math.max(bounds.minLeft, minLeft);
      bounds.minRight = Math.max(bounds.minRight, minRight);
      break;
    }
  }
}

function savePosition(e) {
  beforePosition.pageX = e.pageX;
  beforePosition.pageY = e.pageY;
  beforePosition.scrollLeft = scrollElement.scrollLeft;
  beforePosition.scrollTop = scrollElement.scrollTop;
  beforePosition.frame = frame.value;
  beforePosition.ss = props.ss;
  beforePosition.left = left.value;
  beforePosition.right = right.value;
  beforePosition.top = top.value;
  trusted.value = isDragTarget.value || e.isTrusted;
}

function resetPosition() {
  beforePosition.pageX = 0;
  beforePosition.pageY = 0;
  beforePosition.scrollLeft = 0;
  beforePosition.scrollTop = 0;
  beforePosition.frame = -1;
  beforePosition.ss = 0;
  beforePosition.left = left.value;
  beforePosition.right = right.value;
  beforePosition.top = top.value;

  dragBounds.value = {
    minLeft: null,
    maxLeft: null,
    minRight: null,
    maxRight: null,
  };
  scrollBounds.value = {
    maxLeft: null,
    maxRight: null,
    maxTop: null,
    maxBottom: null,
  };
  resizeBounds.value = {
    minLeft: null,
    maxLeft: null,
    minRight: null,
    maxRight: null,
  };
}

function computeWidth(parentWidth, left, right) {
  return parentWidth - left - right;
}

function snapToGrid(delta) {
  return Math.round(delta / timeline.frameWidth) * timeline.frameWidth;
}

function submit() {
  commit();
  updateDraft();
}

const trackTimeChangeHelper = throttled(() => {
  const node = props.node;
  const id = node.conf.sourceId || node.id;
  collectData("boolvideo_timeline_edit_click", {
    click: "time_change",
    element_type: node.type,
    element_id: id,
    with_mask: Boolean(node.conf.mask),
  });
  track("boolvideo_timeline_edit_click");
  clearEventData("boolvideo_timeline_edit_click");
}, 10000);

function getOrCreateTrack(node) {
  const trackRef = currentTrack.value;
  const trackIndexRef = trackIndex.value;
  const result = { used: false };
  let zIndex = node.parent && node.getZIndex();

  if (!trusted.value) {
    zIndex = Math.max(0, zIndex + zIndexDelta.value);
  }
  if (node.type === "transition") {
    result.track = toRaw(tracks.value[0]);
    result.used = true;
  }
  if (trackRef) {
    result.track = toRaw(trackRef);
    result.used = true;
  } else if (trackIndexRef) {
    result.track = createTrack(node.type, trackIndexRef);
  } else {
    Object.assign(result, getTrack(node, zIndex));
  }
  return result;
}

function setStart(element, deltaX = 0, deep) {
  const helper = (e) => {
    if (!e || !e.classList.contains("segment")) return;
    const node = nodeMap.get(e.dataset.id);
    const left = parseFloat(e.dataset.left) + deltaX;
    const start = widthToFrame(left);
    const duration = node.getDuration();
    const end = start + duration;

    node.conf.start = start;
    node.conf.end = end;
  };
  helper(element);

  if (element && deep) {
    let next = element;

    while ((next = next.nextElementSibling)) {
      helper(next);
    }
  }
}

function setOverlay(overlay, left) {
  const oldOverlay = toRaw(overlayElement.value);

  if (oldOverlay && oldOverlay !== overlay) {
    setStart(oldOverlay, 0, true);
  }
  if (overlay) {
    const deltaX = left - parseFloat(overlay.dataset.left);
    setStart(overlay, deltaX, true);
  }
  overlayElement.value = overlay;
}

function removeOverlay() {
  if (overlayElement.value) {
    setStart(overlayElement.value, 0, true);
  }
  overlayElement.value = null;
}

function setShadow(parent, left) {
  let shadowRef = shadow.value;

  if (!shadowRef) {
    shadowRef = document.createElement("div");
    shadowRef.classList.add("segment-shadow");
    shadowRef.style.width = `${width.value}px`;
    shadow.value = shadowRef;
  }
  parent.appendChild(shadowRef);
  shadowRef.style.left = `${left}px`;
}

function removeShadow() {
  const shadowRef = shadow.value;

  if (shadowRef) {
    shadowRef.parentElement.removeChild(shadowRef);
  }
  shadow.value = null;
  currentTrack.value = null;
}

function updateKeyframes() {
  const { keyframes, mask } = props;
  const oldFrame = beforePosition.frame;
  const newFrame = frame.value;

  if (isMask.value) {
    const newKeyframes = { ...mask.keyframes };

    newKeyframes[newFrame] = { ...mask.keyframes[oldFrame] };
    delete newKeyframes[oldFrame];
    emit("update:mask", { ...mask, keyframes: newKeyframes });
  } else {
    const newKeyframes = { ...keyframes };

    newKeyframes[newFrame] = { ...keyframes[oldFrame] };
    delete newKeyframes[oldFrame];
    emit("update:keyframes", newKeyframes);
  }
  submit();
}

function copyKeyframe(frame) {
  const { keyframes, mask } = props;

  if (isMask.value) {
    const keyframe = { ...mask.keyframes[frame] };
    setCopyedKeyframe(keyframe);
  } else {
    const keyframe = { ...keyframes[frame] };
    setCopyedKeyframe(keyframe);
  }
}

function removeKeyframe(frame) {
  const { keyframes, mask } = props;

  if (isMask.value) {
    const newKeyframes = { ...mask.keyframes };
    delete newKeyframes[frame];
    emit("update:mask", { ...mask, keyframes: newKeyframes });
  } else {
    const newKeyframes = { ...keyframes };
    delete newKeyframes[frame];
    emit("update:keyframes", newKeyframes);
  }
  submit();
}

function updateEasing(easing, frame) {
  const { keyframes, mask } = props;

  if (isMask.value) {
    const newKeyframes = { ...mask.keyframes };
    newKeyframes[frame].easing = easing;
    emit("update:mask", { ...mask, keyframes: newKeyframes });
  } else {
    const newKeyframes = { ...keyframes };
    newKeyframes[frame].easing = easing;
    emit("update:keyframes", newKeyframes);
  }
  submit();
}

function contextmenu() {
  const node = props.node;

  if (activeNodeMap.has(node.id)) {
    return;
  }
  setTimeout(() => setActiveNode(node));
}

function handleClick() {
  setTimeout(() => setActiveNode(props.node, !pressed.value));
}

function getLeft(frameString) {
  const currentFrame = parseInt(frameString);
  const newFrame =
    currentFrame === beforePosition.frame ? frame.value : currentFrame;
  return frameToWidth(newFrame - props.start);
}
</script>
<template>
  <div
    ref="segment"
    class="segment"
    :data-id="node.id"
    :data-left="beforePosition.left"
    :class="{ draggable, alignable, active, dragging, resizing }"
    :style="style"
    @mousedown.stop="segmentDown"
    @click.stop="handleClick"
  >
    <span
      v-for="(handler, i) in handlers"
      v-show="!dragging && active"
      class="handler"
      :key="i"
      :class="'handler-' + handler"
      @mousedown.stop="handlerDown($event, handler)"
      @click.stop
    >
      <div class="stick"></div>
    </span>
    <bv-contextmenu
      :options="contextMenuOptions"
      :width="187"
      @contextmenu="contextmenu"
    >
      <div class="segment-content">
        <div v-if="!node.conf.active" class="segment-loading">
          <div class="icon-wapper">
            <svg-icon name="editor_segment_warning" :size="16" />
          </div>
          <span class="loading-text">Media lost</span>
        </div>
        <div v-else-if="!node.isPrepared" class="segment-loading">
          <div class="icon-wapper">
            <svg-icon
              class="loading-icon"
              name="editor_segment_loading"
              :size="16"
            />
          </div>
          <span class="loading-text">Loading</span>
        </div>
        <slot v-else></slot>
        <template v-if="keyframes">
          <keyframe
            v-for="frame in Object.keys(keyframes)"
            :key="frame"
            :visible="!dragging && active && !isMask"
            :left="getLeft(frame)"
            :easing="keyframes[frame].easing"
            @mousedown="keyframeDown($event, frame)"
            @keydown="keyDown($event, frame)"
            @apply="updateEasing($event, frame)"
          />
        </template>
        <template v-if="mask && mask.keyframes">
          <keyframe
            v-for="frame in Object.keys(mask.keyframes)"
            :key="frame"
            :visible="!dragging && active && isMask"
            :left="getLeft(frame)"
            :easing="mask.keyframes[frame].easing"
            @mousedown="keyframeDown($event, frame)"
            @keydown="keyDown($event, frame)"
            @apply="updateEasing($event, frame)"
          />
        </template>
      </div>
    </bv-contextmenu>
  </div>
</template>
<style scoped>
.segment {
  position: absolute;
  height: 100%;
}
.segment:not(.alignable) {
  z-index: 1;
}
.segment.active:not(.dragging):before {
  content: "";
  position: absolute;
  inset: 0;
  outline: 1px solid #6741ff;
  outline-offset: 1px;
  z-index: 2;
  pointer-events: none;
}
.segment.dragging {
  z-index: 7;
}
.segment.dragging:not(.alignable) {
  z-index: 8;
}
.handler {
  width: 10px;
  height: 100%;
  position: absolute;
  cursor: ew-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.handler-ml {
  left: -1.5px;
  transform: translateX(-50%);
}
.handler-mr {
  right: -1.5px;
  transform: translateX(50%);
}
.stick {
  width: 5px;
  height: 16px;
  background-color: #6741ff;
}
.segment-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.segment-loading {
  display: flex;
  align-items: center;
}
.icon-wapper {
  margin-left: 18px;
  margin-right: 8px;
}
.segment-loading svg {
  margin: 0;
}
.segment-loading .loading-icon {
  animation: rotate 1s linear infinite;
}
.loading-text {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
:deep(.segment-content > svg) {
  margin: 0 8px;
  flex: 0 0 18px;
}
:deep(.segment-content .segment-title) {
  width: calc(100% - 18px);
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  margin-right: 5px;
}
</style>
