import { usePointerMove, useScopeId } from "@/utils";
import type {
  CardOperation,
  CardItemData,
  CardData,
  CardItemVNode,
} from "./type";

type CardItem<T> = CardItemData<T> & { vnode: CardItemVNode };
type UnwrappedCardOperation<T> = Omit<CardOperation, "handler"> & {
  handler: (item: CardItem<T>, index: number) => void;
};

export function wrapperCardOperations<T>(
  operations: UnwrappedCardOperation<T>[],
  items: CardData<T>[],
): CardOperation[] {
  return operations.map((operation) => ({
    text: operation.text,
    iconName: operation.iconName,
    handler(id: string) {
      const index = items.findIndex((item) => item.key === id);

      operation.handler(items[index] as CardItem<T>, index);
    },
  }));
}

interface SelectionEvent {
  target: EventTarget;
  rect: {
    top: number;
    bottom: number;
    left: number;
    right: number;
    width: number;
    height: number;
  };
}

interface SelectionConfig {
  handler(e: SelectionEvent): void;
}

interface BoundingRect {
  top: number;
  bottom: number;
  left: number;
  right: number;
  width: number;
  height: number;
}

export function isIntersection(rectA: BoundingRect, rectB: BoundingRect) {
  return !(
    rectA.left > rectB.right ||
    rectA.right < rectB.left ||
    rectA.top > rectB.bottom ||
    rectA.bottom < rectB.top
  );
}

export function useSelection(config: SelectionConfig) {
  const scopeId = useScopeId();
  const startPoint = { x: 0, y: 0 };
  const highlightArea = document.createElement("div");

  const pointerMove = usePointerMove({
    overflowX: false,
    overflowY: false,
    handler(e) {
      if (e.state === "start") {
        startPoint.x = e.x;
        startPoint.y = e.y;
      } else if (e.state === "end") {
        if (highlightArea.style.display !== "block") {
          const focus = document.elementFromPoint(
            e.originalEvent.clientX,
            e.originalEvent.clientY,
          );
          focus?.dispatchEvent(new MouseEvent("click", e.originalEvent));
          return;
        }

        highlightArea.removeAttribute("style");

        config.handler({
          target: e.target,
          rect: {
            top: Math.min(startPoint.y, e.y),
            bottom: Math.max(startPoint.y, e.y),
            left: Math.min(startPoint.x, e.x),
            right: Math.max(startPoint.x, e.x),
            width: Math.abs(startPoint.x - e.x),
            height: Math.abs(startPoint.y - e.y),
          },
        });
      } else {
        if (
          highlightArea.style.display !== "block" &&
          Math.sqrt((e.x - startPoint.x) ** 2 + (e.y - startPoint.y) ** 2) < 5
        )
          return;

        Object.assign(highlightArea.style, {
          display: "block",
          top: `${Math.min(startPoint.y, e.y)}px`,
          left: `${Math.min(startPoint.x, e.x)}px`,
          width: `${Math.abs(startPoint.x - e.x)}px`,
          height: `${Math.abs(startPoint.y - e.y)}px`,
        });
      }
    },
  });

  const filterEvent = (e: Event) =>
    e instanceof PointerEvent && e.stopPropagation();

  highlightArea.classList.add("selection-box");
  highlightArea.setAttribute(scopeId, "");

  watch(pointerMove.ref, (ref) => {
    if (ref === null) {
      highlightArea.style.opacity = "";
      highlightArea.parentElement?.removeChild(highlightArea);
    } else {
      ref.appendChild(highlightArea);
      ref.addEventListener("click", filterEvent);
    }
  });

  return { ref: pointerMove.ref };
}
