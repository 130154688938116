<template>
  <div class="example-guide-container" v-if="caseList && caseList.length">
    No media? Try these
  </div>
  <div class="item-box" v-if="caseList && caseList.length">
    <div
      v-for="item in caseList"
      :key="item.caseId"
      class="item cursor-pointer"
      @click="handleClickItem(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script setup>
import { setItem } from "@/utils/storage";
const props = defineProps({
  list: Array,
});

const emit = defineEmits(["select"]);
const caseList = computed(() => {
  const list = props.list;
  setItem("caseList", list);
  return list;
});

const handleClickItem = (item) => {
  emit("select", item);
};
</script>
<style lang="scss">
.example-guide-container {
  margin-bottom: 24px;
  color: #646a73;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.item-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(213px, 1fr));
  grid-row-gap: 24px;
  grid-column-gap: 18px;

  .item {
    border-radius: 4px;
    border: 1px solid #e5e7eb;
    display: inline-flex;
    padding: 17px 30px;
    justify-content: center;
    align-items: center;
    color: #060606;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &:hover {
      background-color: rgba(31, 35, 41, 0.08);
    }
  }
}
</style>
