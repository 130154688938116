<template>
  <common-upload
    ref="uploadRef"
    class="upload-box"
    :isLogo="true"
    :beforeUpload="onUpload"
    :handleSuccess="onSuccess"
    :handleProgress="onProgress"
    :accept="accept"
  >
    <template #trigger>
      <p v-if="prompt">{{ prompt }}</p>
    </template>
  </common-upload>
</template>

<script setup lang="ts">
import commonUpload from "@/components/common/bv-upload/commonUpload.vue";
import type { PropType } from "vue";
import type { AcceptType } from "@/utils/type";
import type {
  UploadSuccessEvent,
  UploadProgressEvent,
  UploadEvent,
  UploadFailEvent,
} from "@/components/common/bv-upload/type";
import type { UploadInstance } from "element-plus";

defineProps({
  onUpload: {
    type: Function as PropType<(e: UploadEvent) => boolean>,
    default: () => {},
  },
  onFail: {
    type: Function as PropType<(e: UploadFailEvent) => void>,
    default: () => {},
  },
  onSuccess: {
    type: Function as PropType<(e: UploadSuccessEvent) => void>,
    default: () => {},
  },
  onProgress: {
    type: Function as PropType<(e: UploadProgressEvent) => void>,
    default: () => {},
  },
  accept: {
    type: String,
    default: "",
  },
  prompt: String,
});

const uploadRef = ref(null as unknown as InstanceType<typeof commonUpload>);
const exposeData = {
  elUploadRef: null as unknown as UploadInstance,
};

onMounted(() => {
  exposeData.elUploadRef = uploadRef.value.elUploadRef;
});

defineExpose(exposeData);
</script>

<style lang="scss" scoped>
.upload-box {
  background-color: transparent;
}
</style>
