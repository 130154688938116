<template>
  <el-row class="w-full feature-edit-container">
    <el-col :span="layout.left" class="part-left part-item">
      <PreviewImages :imageList="imageList" :fileList="fileList" />
    </el-col>
    <el-col :span="layout.right" class="part-right part-item">
      <ParamsEdit :productInfo="productInfo" :editMode="editMode" />
    </el-col>

    <primary-button
      size="small"
      @click="handleGenerated"
      class="generate-button"
      padding="16px 48px"
    >
      <p class="btn-text">
        <svg-icon
          name="icon_generate_star"
          :style="{ 'margin-right': '6px' }"
          :size="18"
        ></svg-icon>
        Generate
      </p>
    </primary-button>
  </el-row>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import PreviewImages from './previewImages.vue';
import ParamsEdit from './paramsEdit.vue';

import { storeToRefs } from 'pinia';
import { useFeatureEdit } from './store/index.js';
import { removeEscapeHtml, useSingleMessage } from '@/utils';

const featureEdit = useFeatureEdit();
const { formState, imageMidsList, cacheImageList } = storeToRefs(featureEdit);

const emit = defineEmits(['onGenerated']);
const props = defineProps({
  imageList: Array,
  fileList: Array,
  productInfo: Object,
});

const layout = reactive({
  left: 14,
  right: 10,
});

const screenWidth = ref(1440);
const Message = useSingleMessage();

const handleGenerated = () => {
  imageMidsList.value = imageMidsList.value.filter((item) => item);

  if (!imageMidsList.value.length) {
    Message.error('Please add your media', { duration: 2000 });
    return;
  }

  if (formState.value.type == 0 && !formState.value.purposeIds.length) {
    Message.error('Please fill the purpose', { duration: 2000 });
    return;
  }

  if (formState.value.type == null) {
    Message.error('Please fill the type', { duration: 2000 });
    return;
  }

  if (formState.value.effectCustomization == null) {
    Message.error('Please fill the AI Customization', { duration: 2000 });
    return;
  }

  if (formState.value.size == null) {
    Message.error('Please fill the ratio', { duration: 2000 });
    return;
  }

  formState.value.mids = imageMidsList.value;
  formState.value.productDesc = removeEscapeHtml(formState.value.productDesc);

  const cacheForm = {
    ...formState.value,
    cacheImageList: cacheImageList.value,
  };
  emit('onGenerated', formState.value, cacheForm);
};

watch(
  () => screenWidth.value,
  (width) => {
    if (width >= 860 && width <= 1250) {
      layout.left = 12;
      layout.right = 12;
    } else if (width < 860 && width >= 560) {
      layout.left = 9;
      layout.right = 12;
    } else if (width < 560) {
      layout.left = 11;
      layout.right = 13;
    } else {
      layout.left = 14;
      layout.right = 10;
    }
  }
);

onMounted(() => {
  window.onresize = () => {
    screenWidth.value = window.innerWidth;
  };
});
</script>
<style lang="scss" scoped>
.feature-edit-container {
  max-width: 1920px;
  padding: 0px 20px 0px 78px;
  height: calc(100vh - 60px);
  overflow-y: hidden;
  overflow-x: scroll;
  position: relative;

  .generate-button {
    width: 190px;
    height: 54px;
    position: absolute;
    bottom: 80px;
    left: 50%;
    margin-left: -95px;
    z-index: 99;
  }

  .btn-text {
    color: #fff;
    text-align: center;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.part-left {
  padding-right: 10px;
  height: 100%;
}

.part-right {
  border-left: 1px solid #e5e7eb;
  height: 100%;
  padding: 0px 0px 0px 54px;
  overflow-y: auto;
}

@media only screen and (max-width: 1000px) {
  .part-right {
    padding: 40px 0px 0px 30px;
  }

  .feature-edit-container {
    padding: 0px 10px 0px 30px;
  }
}
</style>
