<script setup>
import Header from "./header.vue";
import Content from "./content.vue";
import Loading from "./loading.vue";
import Assistance from "@/components/ai-assistant/assistance.vue";
import defaultConfig from "@/assets/data/conf.json";
import debugConfig from "@/assets/data/debug.json?raw";
import { getVideoRenderInfo, getDraftDetail, putDraft } from "@/api/draft";
import { useTrackStore } from "@/store/modules/track";
import { useMessage, useRetryAsync } from "@/utils";
import { useNetwork } from "@/composables";
import { useCreatorStore, useDraftStore, useKeyboard } from "../../stores";

const { 
  title, 
  videoType,
  setRatio, 
  setVideoType, 
  setVideoSubtype, 
  setDraftId, 
  setReqId, 
  setRid 
} = useDraftStore();
const { creator, start, destroy } = useCreatorStore();
const { collectData } = useTrackStore();
const route = useRoute();
const router = useRouter();
const message = useMessage();
const { onLine } = useNetwork();
const globalDomain = inject("globalDomain");

useKeyboard(true);
onMounted(setup);
onBeforeUnmount(destroy);

watch(onLine, (newOnLine) => {
  if (!newOnLine) {
    message.error("Editing can not be synchronized", { showClose: true });
  }
});

async function setup() {
  const trackHelper = (value) => {
    const { renderType, draftId, reqId, rid } = value;
    setVideoType(renderType);
    setVideoSubtype(renderType);
    setDraftId(draftId);
    setReqId(reqId);
    setRid(rid);
  };

  const { debug } = route.query;
  let rid = route.query.rid || "";
  let draftId = route.query.draftId;
  let reqId = "";
  let renderConfig = JSON.stringify(defaultConfig);
  let duration = 0;
  let ratio = "9:16";
  let formattingId = [];
  let scriptId = 0;
  let sourceRatio = ratio;

  if (rid) {
    const { success, data } = await getVideoRenderInfo(rid);

    if (!success) {
      return;
    }
    trackHelper(data);
    draftId = data.draftId;
    reqId = data.reqId;
    renderConfig = data.renderConfig;
    duration = data.duration;
    sourceRatio = ratio = data.ratio;
    formattingId = data.formattingId;
    scriptId = data.scriptId;
  }
  if (!draftId) {
    const { success, data } = await putDraft({
      rid,
      renderConfig,
      duration,
      ratio,
    });

    if (!success) {
      return;
    }
    draftId = data.draftId;
  } else {
    if (debug) {
      await start(debugConfig, sourceRatio);
      return;
    }

    const { success, data } = await getDraftDetail(draftId);

    if (!success) {
      return;
    }

    trackHelper(data);
    rid = data.rid;
    reqId = data.reqId;
    renderConfig = data.renderConfig;
    title.value = data.name;
    ratio = data.ratio;
    formattingId = data.formattingId;
    scriptId = data.scriptId;
    sourceRatio = data.sourceRatio;
  }

  collectData("boolvideo_export_click", {
    ratio: ratio.replace(":", "_"),
    video_type: videoType.value,
    formatting_id: formattingId || [],
    script_id: scriptId || 0,
    editor_type: "timeline_editor",
    draftId,
    reqId,
    rid,
  });
  collectData("boolvideo_timeline_edit_click", {
    draftId,
    reqId,
    rid,
    video_type: videoType.value,
  });

  router.replace({ query: { ...route.query, rid: undefined, draftId } });
  setRatio(ratio);
  const retryStart = useRetryAsync(start);
  await retryStart(renderConfig, sourceRatio);
}
</script>
<template>
  <div class="editor-layout">
    <Header />
    <Content />
    <Assistance v-if="globalDomain === 1" :loading="!creator"/>
    <Loading v-if="!creator" />
  </div>
</template>

<style scoped>
.editor-layout {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

:deep(.el-slider__button-wrapper.dragging),
:deep(.el-slider__button.dragging),
:deep(.el-slider__button-wrapper:hover),
:deep(.el-slider__button:hover) {
  cursor: pointer;
}
</style>
