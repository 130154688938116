<template>
  <div ref="container" class="h-full flex justify-center items-center pt-10 pb-24 single-previewer-container">
    <div class="h-full render-item-box relative" :style="{ aspectRatio: ratio == '1:1' ? '1/1' : '9/16' }">
        <div class="h-full bg-uploadBg border-borderColor video-render-box" :style="{ aspectRatio: ratio == '1:1' ? '1/1' : '9/16' }" >
            <video-render class="h-full"  :style="{ aspectRatio: ratio == '1:1' ? '1/1' : '9/16' }" :src="renderItem?.templateUrl" :editAssets="renderItem?.editAssets" :loop="true"
              :level="item?.lever" :showBorder="true" ref="videoRenderRef" @onLoaded="handleLoaded" rangeWidth="364px"
              @onIsPlaying="handleItemIsPlaying" />
          </div>
       <div class="absolute h-16 w-full -mt-1 flex items-end pt-4"  v-if="showButton" >
        <render-item-btn :item="renderItem" ref="renderItemBtn" @onEdit="handleEdit" @onExport="handleExport" />
      </div>
      </div>
  </div>

  <share-link-dialog :visible="showShareLinkDialog" @close="() => (showShareLinkDialog = false)" />

  <!-- 导出弹窗 -->
  <edit-export-info-dialog exportType="previewer" :rid="renderItem?.rid" :reqId="renderItem?.reqId"
    :visible="showExportDialog" @confirm="handleExportSuccess" @close="() => (showExportDialog = false)" />
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, onBeforeUnmount, render } from "vue";

import VideoRender from "./components/videoRender.vue";
import ShareLinkDialog from "./components/shareLinkDialog.vue";
import { getRenderInfos } from "@/api/index";
import EditExportInfoDialog from "@/components/common/exportDialog.vue";
import RenderItemBtn from './components/renderItemBtn.vue'

import { useRoute, useRouter } from "vue-router";
import type { templateInfo } from "./type";
import { setItem } from "@/utils/storage";
import { useTrackStore } from "@/store/modules/track";

const router = useRouter();
const route = useRoute();
const reqId = ref("");
const ratio = ref("9:16");
const showShareLinkDialog = ref(false);
const showButton = ref(false);
const container: any = ref(null);
const windowWidth = ref(0);
const windowHeight = ref(0);

const renderItemStyle = reactive({
  width: "30%",
});

const videoRenderRef: any = ref(null);

const imageList = ref<templateInfo[]>([]);
const showExportDialog = ref(false);

const renderItem = computed(() => {
  return imageList.value.length && imageList.value[0];
});

const { collectData, track } = useTrackStore();

//编辑
const handleEdit = async () => {
  try {
    if (!renderItem.value) {
      return;
    }
    // S类 跳转
    collectData("boolvideo_edit_click", {
      video_type: "templates",
      template_id: renderItem.value.templateId,
      draftId: renderItem.value.draftId,
      rid: renderItem.value.rid,
      reqId: renderItem.value.reqId,
    });
    track("boolvideo_edit_click");
    router.push({
      path: "/template-to-video/editVideo",
      query: {
        rid: renderItem.value.rid,
      },
    });

  } catch {
    console.log("error===");
  }
};

const handleExport = () => {
  showExportDialog.value = true;
};

//异步导出
const handleExportSuccess = () => {
  showExportDialog.value = false;
};

const initAssets = (assets: EditableAssetItem[]) => {
  const newAsset = assets.map((asset: EditableAssetItem) => {
    return {
      ...asset,
      cropUrl: asset.material,
      layerId: "",
      highLight: false,
    };
  });
  return newAsset || [];
};

const handleImageList = (data: templateInfo[]) => {
  data.forEach((item) => {

    let editAssets: EditableAssetItem[] = [];
    if (item.assets && item.assets.length) {
      editAssets = initAssets(item.assets!);
    }

    const newItem: templateInfo = {
      rid: item.rid,
      type: item.type,
      reqId: item.reqId,
      templateUrl: item.templateUrl,
      templateId: item.templateId,
      editAssets: editAssets,
      renderConfig: item.renderConfig,
      ratio: item.ratio,
      tags: item.tags,
      lever: item.lever || "",
      isFirstPlay: true,
      draftId: item.draftId,
      previewImageIndex: item.previewImageIndex
    };

    imageList.value.push(newItem);
  });
};

// 根据版本获取渲染包和素材资源
const getAssetInfo = async (reqId: string) => {
  try {
    const { data } = await getRenderInfos(reqId, 1);
    handleImageList(data);
  } catch (err) {
    console.log("err====");
  }
};

// 加载完成
const handleLoaded = () => {
  showButton.value = true;
  videoRenderRef.value?.handleChangeFrame(
    renderItem.value.previewImageIndex || 30
  );
};


// 播放之后将首次播放的变量置为false, 并且让其回到第 0 帧
const handleItemIsPlaying = () => {
  imageList.value.forEach((item) => {
    item.isFirstPlay = false;
  });
};

watch(
  () => renderItem.value.isFirstPlay,
  (value) => {
    if (!value) {
      videoRenderRef.value?.goToAndPlay(1);
    }
  },
);

const updateWindowWidth = () => {
  const rect = container.value?.getBoundingClientRect();
  windowWidth.value = rect?.width;
  windowHeight.value = rect?.height;

  if (windowHeight.value / windowWidth.value < 0.6 && windowHeight.value  < 800) {
    renderItemStyle.width = "25%";
  }

  if (windowHeight.value / windowWidth.value < 0.6 && windowHeight.value  > 800) {
    renderItemStyle.width = "30%";
  }

  if ( windowHeight.value  < 400) {
    renderItemStyle.width = "15%";
  }
};

onMounted(() => {
  ratio.value = route.query.ratio as string;
  const id = route.query.reqId as string;
  reqId.value = id;
  getAssetInfo(id);
  setItem("reqId", reqId.value);
  setItem("ratio", ratio.value);
  updateWindowWidth();

  window.onresize = () => {
    updateWindowWidth();
  };
});

onBeforeUnmount(() => {
  videoRenderRef.value?.destroy()
})
</script>
<style lang="scss" scoped>
:deep(.el-loading-spinner .path) {
  stroke: #646a73 !important;
}

.previewer {
  border-width: 0.5px;
}

.video-render-box {
  
  border-radius: 8px;
}

.single-previewer-container {
  min-width: 900px;
  min-height: 650px;

  .render-item-box {
    max-height: 900px;
  }
}


</style>
