<template>
  <div :class="['object-eraser', disable ? 'disable' : '']">
    <div class="main-view">
      <drag-view
        :class="['view-container', isProcessing ? 'processing' : '']"
        ref="dragRef"
        :style="containerStyle"
        :isDrag="isDrag"
      >
        <context-menu
          :operations="operations"
          @visiable="brushState.opacity = 0"
        >
          <v-render
            class="drawboard"
            :vnode="drawboard.vnode"
            @mousemove="handleMouseMove"
            @mouseleave="handleMouseLeave"
            :[customInstanceId]="''"
          />
        </context-menu>
        <div class="brush" :style="brushStyle"></div>
        <div
          v-if="editFrames.length > 0"
          :class="['original-resource', isCompare ? 'compare' : '']"
          :style="{ backgroundImage: `url('${resourceUrl}')` }"
        ></div>
      </drag-view>
    </div>
    <tool-bar
      class="tool-bar"
      ref="toolbarRef"
      :disable="disable"
      :active="activeCode"
      :redo-disable="!canRedo"
      :undo-disable="!canUndo"
      :loading="isSaving"
      @onScale="handleScale"
      @onCompare="handleCompare"
      @onReset="handleReset"
      @onDrag="handleDrag"
      @onBrushSize="handleBrushSize"
      @onBrushSizeChange="handleChangeBrushSize"
      @onSubmit="handleSubmit"
      @onBack="currentFrameIndex--"
      @onPre="currentFrameIndex++"
    >
      <template #brushLabel>
        <span class="brush-label">Brush</span>
      </template>
    </tool-bar>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { apiCall, useTimedTask, generateGID } from "@/utils";
import { randomClassName, useStyle, useSingleMessage } from "@/utils/dom";
import Drawboard from "../../utils/drawboard";
import DragView from "../../components/drag/index.vue";
import ToolBar from "../../components/toolBar/index.vue";
import ContextMenu from "../../components/contextMenu/index.vue";
import {
  fileUploadPre,
  getMaterialUploadState,
  uploadFile,
  applyImageErase,
} from "@/api";
import { createMenuItem, useProcessRequestHandler } from "../helper";
import type { PanelOperation } from "../../components/contextMenu/type";
import { authenticator } from "@/utils/authenticate";

const styleRule = useStyle();
const customInstanceId = randomClassName();
const emits = defineEmits(["submit"]);
const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  mode: {
    type: String,
    default: "",
  },
});

const drawboard = new Drawboard({
  board: {
    width: 0,
    height: 0,
  },
  brush: {
    color: "#CCFF00",
    opacity: 0.6,
  },
});

const route = useRoute();
const router = useRouter();
const Message = useSingleMessage();
const handleProcessRequest = useProcessRequestHandler();
const isDrag = ref(false);
const disable = ref(true);
const isCompare = ref(false);
const isProcessing = ref(false);
const isSaving = ref(false);
const activeCode = ref(0);
const viewScale = ref(1);
const currentFrameIndex = ref(0);
const dragRef = ref(null as unknown as InstanceType<typeof DragView>);
const toolbarRef = ref(null as unknown as InstanceType<typeof ToolBar>);
const brushState = reactive({ x: 0, y: 0, opacity: 0 });
const originalResource = new Image();
const editFrames = ref<string[]>([]);
const canUndo = computed(() => currentFrameIndex.value > 0);
const canRedo = computed(
  () => currentFrameIndex.value < editFrames.value.length - 1,
);
const resourceUrl = computed(() =>
  props.mode === "editor" ? props.src : (route.query.url as string),
);
const boardScale = computed(() =>
  toolbarRef.value !== null ? toolbarRef.value.data.currentScale.value : 1,
);
const containerStyle = computed(() => {
  return {
    width:
      drawboard.config.board.width * boardScale.value * viewScale.value + "px",
    height:
      drawboard.config.board.height * boardScale.value * viewScale.value + "px",
  };
});

const brushStyle = computed(() => {
  const brushSize = drawboard.config.brush.size;

  return {
    width: `${brushSize * 2}px`,
    opacity: brushState.opacity,
    transform: `translate3d(${brushState.x - brushSize}px, ${
      brushState.y - brushSize
    }px, 0)`,
  };
});

const backgroundStyle = styleRule.insertStyle(
  `canvas.drawboard[${customInstanceId}]`,
  {},
);
const requestTimedTask = useTimedTask(() => void 0, 1000);
const operations: PanelOperation[] = [
  {
    label: createMenuItem("icon_download", "Download"),
    handler: handleDownload,
  },
];

watch(
  () => editFrames.value[currentFrameIndex.value],
  (frameBase64) => {
    backgroundStyle.backgroundImage =
      frameBase64 === undefined
        ? ""
        : `url(data:image/jpg;base64,${frameBase64})`;
  },
);

watch(
  () => isSaving.value || isProcessing.value,
  (value) => (disable.value = value),
);

watchEffect(() => {
  drawboard.config.board.scale *= viewScale.value;
});

watchEffect(() => {
  disable.value = true;
  editFrames.value = [];
  originalResource.src = resourceUrl.value;
  currentFrameIndex.value = 0;
  Message.loading("Loading, it will take a while", { duration: 0 });
});

originalResource.crossOrigin = "anonymous";
originalResource.onload = () => {
  const containerHeight = dragRef.value.el!.parentElement!.offsetHeight;

  disable.value = false;
  drawboard.resize([originalResource.width, originalResource.height]);
  editFrames.value = [toBase64(originalResource)];
  Message.close();

  if (containerHeight > originalResource.height) return;
  viewScale.value =
    Math.max(100, containerHeight - 100) / originalResource.height;
};

function handleScale(scale: number) {
  drawboard.config.board.scale = scale;
}

function handleCompare(value: boolean) {
  isCompare.value = value;
  activeCode.value ^= toolbarRef.value.codeMap.compare;
}

function handleReset() {
  Object.assign(dragRef.value.position, {
    top: 0,
    left: 0,
  });

  toolbarRef.value.data.currentScale.value = 1;
  handleScale(1);
}

function handleDrag() {
  isDrag.value = !isDrag.value;
  drawboard.config.abilities.editable = !isDrag.value;
  activeCode.value ^= toolbarRef.value.codeMap.drag;
}

async function handleSubmit() {
  isSaving.value = true;

  const file = base64ToFile(editFrames.value[currentFrameIndex.value]);
  const { preSignUrl, mid } = await apiCall(fileUploadPre, {
    suffix: ".jpg",
    size: file.size,
    type: "jpg",
    draftId: (route.query.draftId as string) || 0,
    toolName: "objectEraser",
  });

  const result = await uploadFile(preSignUrl, file);
  if (result.code !== 0) {
    isSaving.value = false;
    Message.error("error, save failed", { duration: 2000 });
    return;
  }

  requestTimedTask.task = async () => {
    const uploadState = await apiCall(getMaterialUploadState, mid);

    if (uploadState.state === 1) {
      isSaving.value = false;
      Message.error("error, save failed", { duration: 2000 });
      return;
    }

    if (props.mode === "editor") {
      if (uploadState.state === 2) return;
      emits("submit", {
        url: uploadState.preview480Url,
        HDUrl: uploadState.preview1080Url,
        width480: uploadState.width480, 
        width1080: uploadState.width1080,
      });
    } else {
      if (uploadState.init) return;
      router.replace("/space?tab=media");
    }
  };

  requestTimedTask.start();
}

function handleBrushSize(value: number) {
  const board = drawboard.config.board;

  Object.assign(brushState, {
    opacity: 1,
    x: (board.width * board.scale) / 2,
    y: (board.height * board.scale) / 2,
  });

  drawboard.config.brush.size = value;
  drawboard.config.abilities.editable = false;
}

function handleChangeBrushSize() {
  brushState.opacity = 0;
  drawboard.config.abilities.editable = !isDrag.value;
}

function handleMouseMove(e: MouseEvent) {
  if (!drawboard.config.abilities.editable) return;

  Object.assign(brushState, {
    opacity: 1,
    x: e.offsetX,
    y: e.offsetY,
  });
}

function handleMouseLeave() {
  brushState.opacity = 0;
}

async function handleProcess() {
  isProcessing.value = true;

  const result = await handleProcessRequest(applyImageErase, {
    imgBase64: editFrames.value[currentFrameIndex.value],
    maskBase64: toBase64(getMask(drawboard.canvas!)),
  }).catch(() => {});

  isProcessing.value = false;
  drawboard.clear();

  if (result === undefined) return;
  if (currentFrameIndex.value < editFrames.value.length - 1) {
    editFrames.value = editFrames.value.slice(0, currentFrameIndex.value + 1);
  }

  editFrames.value.push(result.imgBase64);
  currentFrameIndex.value = editFrames.value.length - 1;
}

function handleDownload() {
  const file = base64ToFile(editFrames.value[currentFrameIndex.value]);
  const link = document.createElement("a");

  link.href = URL.createObjectURL(file);
  link.download = `object-erase-${generateGID()}.jpg`;

  link.click();
}

drawboard.on("draw", (e) => {
  if (e.state === "end") {
    handleProcess();
    return;
  }

  drawboard.context!.clearRect(
    0,
    0,
    drawboard.config.board.width,
    drawboard.config.board.height,
  );
  drawboard.draw(drawboard.currentRecord!);
});

function getMask(canvas: HTMLCanvasElement) {
  const offscreenCanvas = document.createElement("canvas");
  const ctx = offscreenCanvas.getContext("2d")!;
  const imageData = canvas
    .getContext("2d")!
    .getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i++) {
    const index = i * 4;
    const isWhite = imageData.data[index + 3] > 34;

    data[index] = data[index + 1] = data[index + 2] = isWhite ? 255 : 0;
    data[index + 3] = 255;
  }

  offscreenCanvas.width = canvas.width;
  offscreenCanvas.height = canvas.height;
  ctx.putImageData(imageData, 0, 0);

  return offscreenCanvas;
}

const toBase64 = (() => {
  const offscreenCanvas = document.createElement("canvas");
  const ctx = offscreenCanvas.getContext("2d")!;

  return (resource: HTMLCanvasElement | HTMLImageElement) => {
    offscreenCanvas.width = resource.width;
    offscreenCanvas.height = resource.height;
    ctx.drawImage(resource, 0, 0);

    const base64Url = offscreenCanvas.toDataURL("image/jpeg");
    const sliceIndex = base64Url.indexOf("base64,");
    return sliceIndex === -1 ? base64Url : base64Url.slice(sliceIndex + 7);
  };
})();

function base64ToFile(base64: string) {
  const binary = Uint8Array.from(atob(base64), (char) => char.charCodeAt(0));
  return new File([binary], "", { type: "image/jpeg" });
}

onUnmounted(() => {
  // originalResource.onload = null;
});
</script>

<style lang="scss" scoped>
.object-eraser {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.disable {
    pointer-events: none;
  }
}

.tool-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: clamp(30px, 3.5%, 50px);
  margin-inline: auto;
}

.main-view {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(96.5% - 114px);
}

.brush-label {
  font-size: 14px;
  margin-right: 30px;
}

.brush {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ccff00;
  border-radius: 50%;
  transition: opacity 0.2s;
  pointer-events: none;
  aspect-ratio: 1 / 1;
}

.drawboard,
.original-resource {
  background: none no-repeat center/cover;
}

.view-container.processing {
  animation: fade-out 0.4s alternate infinite;
}

.original-resource {
  position: absolute;
  inset: 0;
  clip-path: inset(0 0 0 100%);
  transition: clip-path 0.2s;

  &.compare {
    clip-path: inset(0 0 0 0);

    &::after {
      right: calc(100% - 1px);
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: -1px;
    width: 1px;
    height: 100%;
    background-color: #ccff00;
    transition: right 0.2s;
  }
}

@keyframes fade-out {
  to {
    opacity: 0.75;
  }
}
</style>
