<template>
  <div class="picker isPick" :style="pickerStyle">
    <div class="picker-inner isPick">
      <div class="picker-header isPick">
        <saturation
          class="saturation isPick"
          :hue="h"
          :saturation="s"
          :value="v"
          @input="onSelectSaturation($event,'input')"
          @change="onSelectSaturation($event,'change')"
          :color="colorValue"
        />
        <hue
          class="hue isPick"
          :hue="h"
          @input="onSelectHue($event, 'input')"
          @change="onSelectHue($event, 'change')"
          :value="colorValue"
        />
      </div>
      <div class="flex isPick">
        <!-- hex 输入 -->
        <input-value
          :value="colorValue"
          @change="onInputChange"
          @focus="onInputFocus"
          label="HEX"
          @blur="onInputBlur"
          @enter="onInputEnter"
          :width="72"
        />
        <!-- rgb输入 -->
        <input-value
          :value="rgbColor?.r"
          @change="onInputRChange"
          @focus="onInputFocus"
          @blur="onInputBlur"
          @enter="onInputEnter"
          label="R"
          :width="35"
        />
        <input-value
          :value="rgbColor?.g"
          @change="onInputGChange"
          @focus="onInputFocus"
          @blur="onInputBlur"
          @enter="onInputEnter"
          label="G"
          :width="35"
        />
        <input-value
          :value="rgbColor?.b"
          @change="onInputBChange"
          @focus="onInputFocus"
          @blur="onInputBlur"
          @enter="onInputEnter"
          label="B"
          :width="35"
        />
      </div>

      <!-- 预设色值列表 -->
      <Colors
        v-if="colors.length > 0"
        :colors="colors"
        :selected-index="selectColorIndex"
        @change="onSelectColor"
      />
    </div>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  computed,
  watch,
  ref,
  reactive,
  unref,
  nextTick,
} from "vue";
import Saturation from "./Saturation.vue";
import Hue from "./Hue.vue";
import InputValue from "./InputValue.vue";
import Colors from "./Colors.vue";
import {
  hsvFormat,
  rgbFormat,
  hsv2rgb,
  checkColorValue,
  transformHsva,
  checkColorFormat,
  filterHsva,
  rgb2hsv,
} from "../utils";

export default defineComponent({
  name: "Picker",
  components: {
    Colors,
    Saturation,
    Hue,
    InputValue,
  },
  props: {
    format: {
      type: String,
      default: "hex",
    },
    width: {
      type: String,
      default: "222",
    },
    inputWidth: {
      type: Number,
      default: 140,
    },
    showAlpha: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    // 定义颜色列表
    colors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["input", "change"],
  setup(props, { emit, expose }) {
    const pickerStyle = computed(() => ({
      width: props.width ? props.width + "px" : "222px",
    }));
    const inputWidth = computed(() =>
      props.inputWidth ? props.inputWidth : 140,
    );

    // hex 转换成 hsva 模型 h:色相 s:饱和度 v:明度
    const handleTransformValue = () => {
      const value = props.value?.trim();
      if (value) {
        const format = checkColorFormat(value);
        return filterHsva(transformHsva(value, format, props.showAlpha));
      }

      return null;
    };

    // hsv 转换成rgb对象
    const handleRgbColor = ({ h, s, v }) => {
      return hsv2rgb(h, s, v);
    };

    const hsva = ref(handleTransformValue());
    const selectColorIndex = ref(-1);
    const isInputFocus = ref(false);
    const colorValue = ref(
      hsva.value
        ? hsvFormat({ ...hsva.value }, props.format, props.showAlpha)
        : "",
    );
    const rgbColor = reactive(hsva.value ? handleRgbColor(hsva.value) : {});
    const cacheHsva = ref(hsva.value ? { ...hsva.value } : null);

    // 监听props.value 改变hsv
    watch(
      () => props.value,
      () => {
        hsva.value = handleTransformValue();
      },
    );

    const h = computed(() => hsva.value?.h || 0);
    const s = computed(() => hsva.value?.s || 0);
    const v = computed(() => hsva.value?.v || 0);
    const a = computed(() => (hsva.value?.a != null ? hsva.value?.a : 1));
    const rgb = computed(() => hsv2rgb(h.value, s.value, v.value));
    const rgbStr = computed(
      () => `rgb(${rgb.value.r}, ${rgb.value.g}, ${rgb.value.b})`,
    );
    const label = computed(() => props.format.toLocaleUpperCase());

    const emitColor = (event) => {
      let color = "";
      let rgb = {};
      if (hsva.value != null) {
        color = hsvFormat({ ...hsva.value }, props.format, props.showAlpha);
        rgb = rgbFormat({ ...hsva.value });
        cacheHsva.value = { ...hsva.value };
      } else {
        cacheHsva.value = null;
      }
      colorValue.value = color;
      rgbColor.r = rgb.r;
      rgbColor.g = rgb.g;
      rgbColor.b = rgb.b;

      emit(event, color);
    }

    const onSelectHue = (hue, event) => {
      selectColorIndex.value = -1;
      if (hsva.value == null) {
        hsva.value = {
          h: hue,
          s: s.value,
          v: v.value,
          a: a.value,
        };
      }
      hsva.value = {
        ...hsva.value,
        h: hue,
      };
      emitColor(event);
    };

    const onSelectSaturation = ([saturation, value], event) => {
      selectColorIndex.value = -1;
      if (!hsva.value) {
        hsva.value = {
          h: h.value,
          s: saturation,
          v: value,
          a: a.value,
        };
        return;
      }

      hsva.value = {
        ...hsva.value,
        s: saturation,
        v: value,
      };
      emitColor(event);
    };

    const handleColorChange = (color, format, showAlpha) => {
      if (color.length === 0) return;
      const hsv = transformHsva(color, format, showAlpha);
      const { h, s, v } = hsv;
      if (isNaN(h) || isNaN(s) || isNaN(v)) return;
      let a = 1;
      const alpha = hsv.a;
      if (!isNaN(alpha)) {
        a = alpha;
      }
      hsva.value = {
        h,
        s,
        v,
        a,
      };
      emitColor("change");
    };
    const onSelectColor = (color, index) => {
      selectColorIndex.value = index;
      color = color.trim();
      if (color === "") {
        hsva.value = null;
      } else {
        const format = checkColorFormat(color);
        if (format) {
          handleColorChange(color, format, true);
        } else {
          // 无效值
          hsva.value = null;
        }
      }
      emitColor("change");
    };
    const onInputFocus = () => {
      isInputFocus.value = true;
    };
    const onInputBlur = () => {
      isInputFocus.value = false;
      const color = colorValue.value.trim();
      if (color === "") {
        hsva.value = null;
      } else if (checkColorValue(color, props.format)) {
        handleColorChange(color, props.format, props.showAlpha);
      } else {
        // 无效值
        if (unref(cacheHsva) != null) {
          hsva.value = unref(cacheHsva);
        } else {
          colorValue.value = "";
        }
      }
      emitColor("change");
    };
    const onInputEnter = () => {
      const color = colorValue.value.trim();
      if (color === "") {
        hsva.value = null;
      } else if (checkColorValue(color, props.format)) {
        handleColorChange(color, props.format, props.showAlpha);
      } else {
        // 无效值
        if (unref(cacheHsva) != null) {
          hsva.value = unref(cacheHsva);
        } else {
          colorValue.value = "";
        }
      }
      emitColor("change");
    }

    const onInputChange = (color) => {
      selectColorIndex.value = -1;
      colorValue.value = color;
    };

    const handleRgb2Hsv = (value) => {
      let hsv = {};
      if (value != null) {
        const { r, g, b } = value;
        const data = rgb2hsv({ r, g, b });
        hsv = data;
        return filterHsva(hsv);
      }
      return null;
    };
    const onInputRChange = (color) => {
      selectColorIndex.value = -1;
      rgbColor.r = Number(color);
      hsva.value = handleRgb2Hsv(rgbColor);
      emitColor("change");
    };

    const onInputGChange = (color) => {
      rgbColor.g = Number(color);
      hsva.value = handleRgb2Hsv(rgbColor);

      selectColorIndex.value = -1;
      emitColor("change");
    };

    const onInputBChange = (color) => {
      rgbColor.b = Number(color);
      hsva.value = handleRgb2Hsv(rgbColor);
      selectColorIndex.value = -1;
      emitColor("change");
    };

    const resetValue = () => {
      nextTick(() => {
        selectColorIndex.value = -1;
        hsva.value = handleTransformValue();
        emitColor("change");
      });
    };

    expose({
      resetValue,
    });

    return {
      h,
      s,
      v,
      a,
      rgbStr,
      onSelectSaturation,
      onSelectHue,
      label,
      colorValue,
      rgbColor,
      pickerStyle,
      inputWidth,
      onInputChange,
      onInputEnter,
      onInputFocus,
      onInputBlur,
      selectColorIndex,
      onSelectColor,
      onInputRChange,
      onInputGChange,
      onInputBChange,
    };
  },
});
</script>

<style scoped lang="scss">
.picker {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #dee0e3;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%);
}

.picker-inner {
  padding: 16px;
}

[pick-colors-theme="dark"] .picker {
  background: #1d2024;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%);
}

.alpha {
  margin-left: 10px;
}
</style>
