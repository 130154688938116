<script setup>
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  modalClass: {
    type: String,
    default: null,
  },
});
const visible = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    visible.value = newValue;
  },
);
watch(visible, (newValue) => {
  emit("update:modelValue", newValue);
});
</script>
<template>
  <teleport to="body">
    <transition>
      <div class="overlay" v-show="visible">
        <div class="modal-container" :class="modalClass">
          <div class="modal-header">
            <span class="title">{{ title }}</span>
            <icon-button
              name="modal_close"
              :size="20"
              @click="visible = false"
            />
          </div>
          <div class="modal-content">
            <slot name="content"></slot>
          </div>
          <div class="modal-footer" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>
<style scoped>
.overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2000;
}
.modal-container {
  width: 1060px;
  border-radius: 4px;
  background-color: #ffffff;
  overflow: hidden;
}
.modal-header {
  width: 100%;
  padding: 24px 28px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-footer {
  width: 100%;
  padding: 24px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-header span {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.modal-content {
  width: 100%;
  height: 75vh;
  padding: 18px 46px 28px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 300ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
