<script setup>
import EasingModal from "./easingmodal.vue";

const options = [
  { 
    label: "Edit easing",
    onClick: () => {
      showEasingModal.value = true;
    },
  }, 
  { 
    label: "Copy",
    onClick: () => {
      emit("copy");
    },
  },
  { 
    label: "Delete",
    onClick: () => {
      emit("delete");
    },
  },
];
const emit = defineEmits(["mousedown", "keydown", "apply"]);
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  left: {
    type: Number,
    default: 0,
  },
  easing: {
    type: String,
    default: 'linear',
  },
});

const keyframe = ref(null);
const showEasingModal = ref(false);

onMounted(() => {
  keyframe.value.focus();
});
</script>
<template>
  <bv-contextmenu :width="200" :options="options">
    <div
      v-show="visible"
      ref="keyframe"
      class="segment-keyframe"
      tabindex="-1"
      :style="{ left: `${left}px` }"
      @mousedown.stop="$emit('mousedown', $event)"
      @keydown.stop="$emit('keydown', $event)"
    >
      <easing-modal v-model="showEasingModal" :easing="easing" @apply="$emit('apply', $event)" />
    </div>
  </bv-contextmenu>
</template>
<style scoped>
.segment-keyframe {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  transform: translate(-4px, -50%) rotate(45deg);
  border: 0.5px solid #bbbfc4;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  z-index: 3;
}
.segment-keyframe:focus {
  background-color: #875eff;
  border: none;
}
</style>