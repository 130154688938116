// Card Init copywriting
export const starterPriorityList = [
  "10 video exports / month",
  "Limited voiceover transcription mins",
  "Limited Al-generated credits",
];

export const starterPlanDescription =
  "Best for video starters who want to explore the capabilities of our AI Director.";

export const starterPriorityDescription = "";

export const standardPriorityList = [
  "60 video exports / month",
  "600 voiceover transcription mins",
  "Unlimited AI image tools",
  "30min video tools credits / month",
  "500 AI-generated credits",
  "100+ standard templates",
  "3 brand kits",
  "10G cloud storage",
  "Al image recognition",
  "GPT-4 powered script-writing",
];

export const standardPlanDescription =
  "Best for small businesses wanting to increase online visibility.";

export const standardPriorityDescription = "";

export const proPriorityList = [
  "Unlimited video exports / month",
  "1500 voiceover transcription mins",
  "Unlimited AI image tools",
  "90min video tools credits / month",
  "5000 AI-generated credits",
  "500+ premium templates",
  "Unlimited brand kits",
  "100G cloud storage",
  "Al image recognition",
  "GPT-4 powered script-writing",
];

export const proPlanDescription =
  "Best for growing businesses wanting to reach wider audiences and drive more sales.";

export const proPriorityDescription = "Everything Free Plan offers, and:";

export const ultraPriorityList = [
  "Everything Pro plan offers",
  "Advanced Al image / video tools",
  "More cloud storage",
  "More music tracks",
  "More premium clips",
];

export const ultraPlanDescription =
  "Best for large organizations seeking to manage multiple brands and create visual content at scale.";

export const ultraPriorityDescription = "Everything Free Plan offers, and:";

export const faqList = [
  {
    title: "What happens when I cancel my subscription?",
    content: `Once you cancel your subscription, the renewal of your billing cycle will be stopped. You will still have access to the subscription features until the end of the current billing cycle, and no further charges will be incurred.`,
  },
  {
    title: "What is a three day trial period?",
    content: `A three day trial period serves as a hesitation period, allowing new subscribers to fully access membership benefits without immediate financial commitment. During this period, users can evaluate our services and may cancel their subscription at any time within these three days without incurring charges. If the subscription is not canceled at the end of the trial period, it will be charged according to our terms and no refunds will be issued.`,
  },
  {
    title: "Can I upgrade my subscription plan?",
    content: `Yes, you can upgrade your subscription plan at any time. Any unused time on your current plan will be credited back to you and reduce the cost of the upgrade. After you confirm, your account will immediately get upgraded. Go to the Premiumn section and take your account to the next level now!`,
  },
  {
    title: "Can I downgrade my subscription plan?",
    content: `Yes, you can downgrade your subscription plan at any time. Downgrading your plan will reduce your cloud storage capacity. Please make sure that you remove your file contents to comply with the new plan limits before downgrading. If your storage capacity exceeds the limit, you will be unable to perform any upload or export operations.`,
  },
  {
    title: "Can I cancel my subscription plan?",
    content: `You can cancel your monthly subscription at any time. We hope you'll fall in love with Boolvideo and never want to leave :)`,
  },
  {
    title: "Is this a secure site for purchases?",
    content:
      "We process all transactions via Stripe, which guarantees your safety and security. All of the communication between you and our site are encrypted and secured!",
  },
];

/**
 * @see subscriptionInfoList ~@/utils/eventTracking.js
 */
export const cycleTypes = {
  YEAR: {
    name: "year",
    cycle: "yearly",
  },
  MONTH: {
    name: "month",
    cycle: "monthly",
  },
};

export const cancelTips = {
  300401: [
    "Can't export videos anymore",
    "Lose 100 voiceover transcription mins",
    "Lose 100 AI-generated credits",
    "Lose 10 starter templates",
    "Lose 3 brand kits",
    "Lose 5G cloud storage",
  ],
  300201: [
    "Can't export videos anymore",
    "Can't use AI tools anymore",
    "Lose 600 voiceover transcription mins",
    "Lose 500 AI-generated credits",
    "Lose 100+ standard templates",
    "Lose 3 brand kits",
    "Lose 10G cloud storage",
  ],
  300301: [
    "Can't export videos anymore",
    "Can't use AI tools anymore",
    "Lose 1500 voiceover transcription mins",
    "Lose 5000 AI-generated credits",
    "Lose 500+ standard templates",
    "Lose  unlimited brand kits",
    "Lose 100G cloud storage",
  ],
  300402: [
    "Can't export videos anymore",
    "Lose 100 voiceover transcription mins",
    "Lose 100 AI-generated credits",
    "Lose 10 starter templates",
    "Lose 3 brand kits",
    "Lose 5G cloud storage",
  ],
  300202: [
    "Can't export videos anymore",
    "Can't use AI tools anymore",
    "Lose 600 voiceover transcription mins",
    "Lose 500 AI-generated credits",
    "Lose 100+ standard templates",
    "Lose 3 brand kits",
    "Lose 10G cloud storage",
  ],
  300302: [
    "Can't export videos anymore",
    "Can't use AI tools anymore",
    "Lose 1500 voiceover transcription mins",
    "Lose 5000 AI-generated credits",
    "Lose 500+ standard templates",
    "Lose  unlimited brand kits",
    "Lose 100G cloud storage",
  ],
};
