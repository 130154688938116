<template>
  <el-card
    class="card-item"
    shadow="never"
    :body-style="{ padding: 0 }"
    @click="logoClick"
    @contextmenu="handleContextMenu"
  >
    <div class="mark" v-if="logo.defaultUse">Default</div>
    <div class="image-container">
      <div class="logo" @click="">
        <img :src="logo.url" alt="" />
      </div>
    </div>
    <span @click.stop>
      <el-dropdown
        v-if="!isEmptyObject(operations)"
        popper-class="logo-info-dropdown"
        placement="bottom-end"
        trigger="click"
        ref="dropdownRef"
        @command="handleCommand"
        @visibleChange="handleMenuVisibleChange"
        :teleported="false"
        :popper-options="{
          modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
        }"
      >
        <img
          :class="['more', isFocusMenu ? 'active' : '']"
          width="24"
          height="24"
          :src="moreIcon"
        />
        <template #dropdown v-if="isFocusMenu">
          <el-dropdown-menu class="card-menu">
            <el-dropdown-item
              v-for="operation of operations"
              :key="operation.text"
              :command="operation"
              :disabled="operationDisabled(operation)"
            >
              <svgIcon
                :color="operationDisabled(operation) ? '#BBBFC4' : ''"
                :name="operation.iconName"
              />
              <span>{{ operation.text }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </span>
  </el-card>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, getCurrentInstance } from "vue";
import type { MutexSpace } from "@/utils";
import type { PropType } from "vue";
import type { DropdownInstance } from "element-plus/lib/components";
import type { LogoOperation, LogoInfo } from "../../type";
import moreIcon from "../../assets/images/logo_more.svg";

const mutexSpace = inject("mutexSpace") as MutexSpace;

const emits = defineEmits(["default", "remove", "preview"]);
const props = defineProps({
  info: {
    type: Object as PropType<LogoInfo>,
    required: true,
  },
  listLength: {
    type: Number,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const logo: Ref<LogoInfo> = ref(props.info);
const operations: LogoOperation[] = [
  {
    text: "Mark as default",
    iconName: "icon_default_use",
    handler: () => {
      emits("default", props.index);
    },
  },
  {
    text: "Delete",
    iconName: "icon_delete",
    handler: () => {
      emits("remove", props.index);
    },
  },
];
let popperRef: undefined | DropdownInstance["popperRef"];
const instance = getCurrentInstance();
const dropdownRef = ref(null as unknown as DropdownInstance);
const isFocusMenu = mutexSpace.ref();

watch(
  () => props.info,
  (value) => {
    logo.value = value;
  },
  {
    immediate: true,
  },
);

watch(isFocusMenu, (value) => {
  value ? dropdownRef.value.handleOpen() : dropdownRef.value.handleClose();
});

const logoClick = () => {
  emits("preview", logo.value.url);
};

const operationDisabled = (operation: LogoOperation) => {
  if (props.info.defaultUse) {
    const banArray = ["Mark as default", "Delete"];
    return banArray.includes(operation.text);
  } else {
    return false;
  }
};

const isEmptyObject = (target: object) => {
  return Object.keys(target).length === 0;
};

const handleMenuVisibleChange = (visible: boolean) => {
  isFocusMenu.value = visible;
};

const handleCommand = (operation: LogoOperation) => {
  operation.handler(props.index);
};

const handleContextMenu = (e: PointerEvent) => {
  if (
    popperRef === undefined ||
    (e.target as HTMLElement).closest(".logo-info-dropdown") !== null
  )
    return;

  isFocusMenu.value = true;

  if (!instance!.vnode.el?.querySelector(".more")) {
    return;
  }

  const el = instance!.vnode.el.querySelector(".more") as HTMLElement;
  const targetRect = el.getBoundingClientRect();
  const contentRef = popperRef!.contentRef.contentRef;

  setTimeout(() => {
    const style = contentRef.contentStyle[1];

    style.top = `${e.clientY - targetRect.y}px`;
    style.left = `${e.clientX - targetRect.x}px`;
    style.right = "auto";
    style.bottom = "auto";
    console.log(style);
  }, 1);

  e.preventDefault();
  e.stopPropagation();
};

onMounted(() => {
  popperRef = dropdownRef.value?.popperRef;
});
</script>

<style scoped lang="scss">
.card-item {
  position: relative;
  border-color: var(--card-border-color);
  border-radius: var(--card-border-radius);
  user-select: none;
  overflow: visible;
  cursor: pointer;
  & .el-dropdown {
    position: absolute;
    right: 19px;
    bottom: 16px;
  }
}

.mark {
  z-index: 1;
  position: absolute;
  top: 15px;
  left: 15px;
  height: 28px;
  background: #000000;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f6;
  background-image: url("../../assets/images/transparentmask.webp");
  background-size: contain;
  aspect-ratio: 1 / 1;
  border-radius: var(--card-border-radius);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.card-item:hover .more,
.more.active {
  opacity: 1;
}

.more {
  width: 24px;
  height: 24px;
  outline: none;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
  border-radius: 2px;
}

.card-menu {
  min-width: 150px;
  padding-block: 8px;

  & > :deep(li) {
    color: #1f2329;
    padding-inline: 12px;

    & > svg {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    & > span {
      font-size: 14px;
    }
  }

  :deep(.el-dropdown-menu__item.is-disabled) {
    cursor: default;
    color: #bbbfc4;
  }
}

:deep(.logo-info-dropdown) {
  border: 1px solid var(--card-border-color);
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);

  & > .el-popper__arrow {
    display: none;
  }
}
</style>
