<template>
  <div class="subscribe-container">
    <!-- 左 -->
    <div class="text-sm font-normal payment-info-container">
      <!-- 套餐信息 -->
      <el-skeleton :loading="isLoading">
        <template #template>
          <div class="flex flex-col">
            <el-skeleton-item
              variant="circle"
              style="width: 24px; height: 24px; margin-bottom: 20px"
            />
            <el-skeleton-item
              variant="text"
              style="width: 30%; margin-bottom: 20px"
            />
            <el-skeleton-item
              variant="text"
              style="width: 50%; height: 36px; margin-bottom: 50px"
            />
            <el-skeleton-item
              variant="text"
              style="width: 240px; height: 24px; margin-bottom: 90px"
            />
            <el-skeleton-item
              variant="rect"
              style="width: 30%; height: 0; padding: 0; padding-bottom: 30%"
            />
          </div>
        </template>
        <template #default>
          <p
            class="text-base font-medium"
            v-if="productSummary.productSummaryName"
          >
            {{ productSummary.productSummaryName }}
          </p>
          <p class="mt-5" v-if="productSummary.productSummaryTotalAmount">
            <span class="text-5xl font-bold mr-6">{{
              productSummary.productSummaryTotalAmount
            }}</span>
          </p>
          <p class="text-base text-tipColor mt-5 inline-block">
            {{
              `Per user / ${subscriptionCycle === "MONTH" ? "30 days" : "year"}`
            }}
          </p>
          <p class="mt-9 flex justify-between">
            <span>{{ orderDetailsItems.lineItemProductName }}</span>
            <span>{{ orderDetailsItems.lineItemTotalAmount }}</span>
          </p>
          <p class="mt-2 text-tipColor">
            {{
              `Billing cycle - ${
                subscriptionCycle === "MONTH" ? "monthly" : "annually"
              }`
            }}
          </p>
          <!-- 账单信息 -->
          <p class="mt-9 mb-6 flex justify-between">
            <span>Subtotal</span>
            <span>{{
              orderDetailsFooter.orderDetailsFooterSubtotalAmount
            }}</span>
          </p>
          <hr />
          <!-- 优惠券信息 -->
          <coupon
            :data="coupon"
            :discount="orderDetailsFooter.discountLine?.amount"
            :update="updatePriceData"
          />
          <hr />
          <p
            class="mt-6 font-medium flex justify-between"
            v-if="orderDetailsFooter.orderDetailsTrialAmount"
          >
            <span>Total after trial</span
            ><span>{{ orderDetailsFooter.orderDetailsTrialAmount }}</span>
          </p>
          <p class="mt-6 font-medium flex justify-between">
            <span>Total due today</span
            ><span>{{ orderDetailsFooter.orderDetailsTotalAmount }}</span>
          </p>
        </template>
      </el-skeleton>
    </div>
    <!-- 右 -->
    <div class="purchase-container">
      <p class="text-xl font-medium text-center">Payment Details</p>
      <div class="email-container" v-if="isPaymentReady">
        <div class="w-3/10">Email:</div>
        <div class="email-details">
          {{ getEmail() }}
        </div>
      </div>
      <CheckoutForm
        v-if="clientSecret"
        :clientSecret="clientSecret"
        :couponCode="couponApplied ? couponValue : null"
        :customerId="customerId"
        :packageCode="packageCode"
        :setupIntentId="setupIntentId"
        :handleCreateSubscription="handleCreateSubscription"
        :email="email"
        @ready="handlePaymentReady"
        class="my-6"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import CheckoutForm from "./checkout-form.vue";
import Coupon from "../coupon/coupon.vue";
import { useModalManager } from "@/components/common/custom-modal/instance";
import {
  initSetupIntent,
  updateNewPriceData,
  createSubscription,
} from "@/api/premium";
import { usePricingStore } from "../../stores";
import { useTrackStore } from "@/store/modules/track";
import { useMessage } from "@/utils";
import { getToken, getEmail } from "@/utils/auth";
import { paymentPolling } from "@/utils/premium";

const modalManager = useModalManager();
const message = useMessage();
const router = useRouter();
const email = localStorage.getItem("user_email") || "";
const isLoading = ref(true);
const isPaymentReady = ref(false);
const clientSecret = ref("");
const setupIntentId = ref("");
const customerId = ref("");
const packageCode = ref(0);
const subscriptionCycle = ref("YEAR");
interface Props {
  packageCode: number;
}
const props = defineProps<Props>();
const { getCardInfo } = usePricingStore();
const { collectData, track, getTrackPlanName } = useTrackStore();
// 左侧订单信息
const productSummary = ref({
  productSummaryDescription: "Server error",
  productSummaryName: "",
  productSummaryTotalAmount: "",
});
const orderDetailsItems = ref({
  lineItemAmountDetail: "",
  lineItemImage: "",
  lineItemProductName: "",
  lineItemTotalAmount: "",
});
const coupon = ref({
  couponCheckFailMsg: "",
  couponCheckSuccessBoolean: true,
  couponValue: "",
});
const orderDetailsFooter = ref({
  discountLine: {
    amount: "",
    couponCode: "",
    discountDescription: "",
  },
  orderDetailsFooterSubtotalAmount: "",
  orderDetailsTotalAmount: "",
  orderDetailsTrialAmount: "",
});

const setup = async() => {
  isLoading.value = true;
  packageCode.value = props.packageCode;
  await initSetupIntent({ packageCode: packageCode.value }).then((res: any) => {
    if (res.code === 0) {
      const { boolvideoNewSubPaymentVO, setupIntentData } = res.data;
      updatePaymentOrderInfo(boolvideoNewSubPaymentVO);
      updateSetupIntentData(setupIntentData);
    } else {
      message.error(res.msg);
    }
  });
  collectData("boolvideo_subscribe_confirm", {
    plan_name: getTrackPlanName(props.packageCode),
  });
  isLoading.value = false;
};

onBeforeMount(setup);

const updatePaymentOrderInfo = (_value: any) => {
  const {
    orderDetails: _orderDetails,
    productSummary: _productSummary,
    subscriptionCycle: _subscriptionCycle,
  } = _value;
  productSummary.value = _productSummary;
  subscriptionCycle.value = _subscriptionCycle;
  const {
    orderDetailsFooter: _orderDetailsFooter,
    orderDetailsItems: _orderDetailsItems,
  } = _orderDetails;
  orderDetailsItems.value = _orderDetailsItems;
  orderDetailsFooter.value = _orderDetailsFooter;
};

const updateSetupIntentData = (_value: any) => {
  clientSecret.value = _value.clientSecret;
  setupIntentId.value = _value.setupIntentId;
  customerId.value = _value.customerId;
};

const updateCouponData = (_value: any) => {
  const { couponCheckFailMsg, couponCheckSuccessBoolean, orderDetails } =
    _value;
  const discountLine = orderDetails?.orderDetailsFooter?.discountLine;
  const couponValue = discountLine ? discountLine.couponCode : "";
  coupon.value = {
    couponCheckFailMsg,
    couponCheckSuccessBoolean,
    couponValue,
  };
};

const updatePriceData = async (couponCode: string) => {
  const payload = {
    packageCode: packageCode.value,
    couponCode: couponCode || null,
  };
  const res = await updateNewPriceData(payload);
  if (res.code === 0) {
    if (res.data.couponCheckSuccessBoolean) {
      updatePaymentOrderInfo(res.data);
    }
    updateCouponData(res.data);
    return res;
  } else {
    message.error(res.msg);
    return;
  }
};

const handlePaymentReady = (value: boolean) => {
  isPaymentReady.value = value;
};

const handleCreateSubscription = async () => {
  const { couponValue } = coupon.value;
  const couponApplied = couponValue;
  return await createSubscription({
    couponCode: couponApplied ? couponValue : null,
    customerId: customerId.value,
    packageCode: packageCode.value,
    setupIntentId: setupIntentId.value,
    toltReferral: window.tolt_referral

  })
    .then(async (res) => {
      const { code, data, msg } = res;
      if (code === 0) {
        await paymentPolling(data.orderId)
          .then(async () => {
            track("boolvideo_subscribe_confirm");
            const pricingArr = getCardInfo(packageCode.value)?.monthlyPrice?.split('$') || []

            // 订阅成功
            gtag("event", `subscribe_success`, {
              type:subscriptionCycle.value ? subscriptionCycle.value.toLowerCase() : '', // 套餐周期类型: 年包/月包
              package_code: packageCode.value, // 套餐类型 code
              value: Number(pricingArr[1]), // 套餐价格 number
              currency: 'USD'
            });
            router.push({
              path: "/premium",
              query: {
                subscriptionType: data.subscriptionOperType,
              },
            });
          })
          .catch(error => {
            const { state, hostedInvoiceUrl } = error;
            const doneCallback = () => {
              router.push({
                path: "/premium",
              });
            };
            switch(state) {
              case "CANCEL":
                setup();
                break;
              case "PAYMENT_ANOMALY":
                if (hostedInvoiceUrl) {
                  modalManager.applyTemplate("paymentAuthentication", { 
                    doneCallback ,
                    redirectUrl: hostedInvoiceUrl,
                  });
                }
                break;
              case "PAYMENT_ERROR":
                modalManager.applyTemplate("paymentFail", { 
                  doneCallback ,
                  repaySuccessCallback: doneCallback,
                });
                break;
            }
            throw { msg: "Please check your credit card limit and availability." };
          });
      } else {
        throw { msg };
      }
    })
    .catch((error) => {
      message.error(error.msg);
      return error.msg;
    });
};

</script>

<style lang="scss" scoped>
.subscribe-container {
  width: 100%;
}

.payment-info-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 24px 16px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.purchase-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 24px 16px 16px;
  border-top: 1px solid #ebedef;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.email-container {
  width: 100%;
  display: flex;
  padding: 12px;
  margin-top: 24px;
  margin-bottom: 20px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02);
  background: #f7f7f7;
}

.email-details {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  .subscribe-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .payment-info-container {
    width: 50%;
    margin: 0;
    padding: 30px 91px;
  }

  .purchase-container {
    width: 50%;
    margin: 0;
    padding: 30px 91px;
    border-top: none;
    border-left: 1px solid #ebedef;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
