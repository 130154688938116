<template>
  <el-scrollbar>
    <div class="voice-collection">
      <div class="flex justify-between title">
       <div class="flex"> <p class="mr-3">Rank </p><p>Hooks</p></div>
        <p class="like-tip">Likes</p>
      </div>
      <div class="collection-item-box" v-if="list && list.length">
        <div v-for="(item, index) in list" :key="item.mediaId" class="collection-item">
          <viralHooksItem
            :list="list"
            :selectedMediaId="selectedMediaId"
            :item="item"
            :index="index"
            @onSelected="handleSelected"
            @onPlay="handlePlayVideo"
            @onPause="handlePauseVideo"
            @onCancelSelected="handleCancelSelected"
          />
        </div>
      </div>
      <div v-else class="h-full flex justify-center items-center">
        <svg-icon
          name="icon_empty"
          style="color: #bbbfc4; height: 200px"
        ></svg-icon>
      </div>
    </div>
  </el-scrollbar>
</template>

<script setup>
import viralHooksItem from './viralHooksItem.vue';
import { useSimilarVideo } from '@/pages/similarVideo/store/index.js';

const similarVideoStore = useSimilarVideo();
const {} = storeToRefs(similarVideoStore);

const emit = defineEmits(['back', 'onPlay']);

const props = defineProps({
  list: Array,
  selectedStyleId: Number,
  selectedMediaId: Number,
});


const handleSelected = (mediaId, voiceId) => {
  similarVideoStore.setSelectedMediaId(mediaId);
  similarVideoStore.setSelectedVoiceId(voiceId);
  props.list.forEach((item) => {
    if (item.mediaId != mediaId) {
      item.isSelected = false;
    }
  });
};

const handleCancelSelected = (id) => {
  similarVideoStore.setSelectedMediaId(null);
  similarVideoStore.setSelectedVoiceId(null);
  props.list.forEach((item) => {
    if (item.mediaId == id) {
      item.isSelected = false;
    }
  });
};

const handlePlayVideo = (id) => {
  props.list.forEach((item) => {
    if (item.mediaId != id) {
      item.isPlay = false;
    }
  });

  emit('onPlay');
};

const handlePauseVideo = (id) => {
  props.list.forEach((item) => {
    if (item.mediaId == id) {
      item.isPlay = false;
    }
  });
};

const handleBack = () => {
  emit('back');
};
</script>

<style lang="scss" scoped>
.voice-collection {
  padding: 21px 12px 120px 15px;

  .title {
    padding: 0px 3px;
    margin-bottom: 12px;
  }

  .like-tip {
    width: 59px;
    text-align: left;
  }

  .collection-item {
    margin-bottom: 12px;
  }

  .header {
    display: flex;
    padding-left: 9px;
    .title {
      color: #000;
      text-align: center;
      font-family: Inter-variant;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      margin-left: -80px;
      pointer-events: none;
    }
  }
}
</style>
