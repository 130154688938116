<script setup lang="ts">
import { reactive, ref, h, watch } from "vue";
import { Loading } from "@element-plus/icons-vue";
import GoogleLogin from "./google-login.vue";
import prefix_email from "@/assets/images/login/prefix_email.svg";
import prefix_password from "@/assets/images/login/prefix_password.svg";
import prefix_email_fail from "@/assets/images/login/fail_email.svg";
import prefix_password_fail from "@/assets/images/login/fail_password.svg";
import { checkForm } from "../utils";
import { useDebounceFn } from "@vueuse/core";
import { useUserStore, useSubscriptionInfo } from "@/store/modules/user";
import { useTrackStore } from "@/store/modules/track";
import { useRouter, useRoute } from "vue-router";
import { PrimaryButton } from "@/components/common";
import { useMessage } from "@/utils";

const message = useMessage();
const user = useUserStore();
const subscriptionInfo = useSubscriptionInfo();
const { track } = useTrackStore();
const router = useRouter();
const route = useRoute();
interface FormState {
  email: string;
  password: string;
}
const formState: FormState = reactive({
  email: "",
  password: "",
});
const showPassword = ref<boolean>(false);
const disabled = ref<boolean>(true);

watch(formState, () => {
  /* 深层级变更状态所触发的回调 */
  if (formState.email && formState.password) {
    disabled.value = false;
  } else {
    disabled.value = true;
  }
});

const isLoading = ref(false);
//报错信息
const errorFiles = reactive({
  email: "success",
  password: "success",
  errorMsg: "",
});

const initState = () => {
  errorFiles.email = "success";
  errorFiles.password = "success";
  errorFiles.errorMsg = "";
};

const setErrorState = (msg: string) => {
  errorFiles.email = "error";
  errorFiles.password = "error";
  errorFiles.errorMsg = msg;
};

const indicator = h(Loading, {
  style: {
    fontSize: "20px",
    color: "#fff",
    marginRight: "8px",
  },
  spin: true,
});

const debouncedFn = useDebounceFn(async (value) => {
  //login api
  initState();
  const res = await user.userLogin({
    username: value.email,
    password: value.password,
  });
  const { code, msg } = res;
  if (code !== 0) {
    message.error("login failed");
    isLoading.value = false;
    setErrorState(msg);
    return;
  } else {
    await subscriptionInfo.refresh();
    track("boolvideo_signup_success");
    const redirect: any = route.query?.redirect;
    const query = route.query;
    if (redirect) {
      delete query.redirect;
      router.push({ path: redirect, query });
    } else {
      router.push("/workspace");
    }
  }
}, 500);

const handleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const handleInput = (name: string) => {
  errorFiles[name] = "success";
  errorFiles.errorMsg = "";
};

const onFinish = async (values: FormState) => {
  isLoading.value = true;
  //先检查values是否都存在并且合法,登录状态下不验证参数是否合法
  await checkForm(values, true)
    .then(async () => {
      initState();
      await debouncedFn(values);
    })
    .catch((err) => {
      const errorList = err.errorFiles;
      errorFiles.errorMsg = err.message;
      errorFiles.email = errorList.includes("email") ? "error" : "success";
      errorFiles.password = errorList.includes("password")
        ? "error"
        : "success";
    });
  isLoading.value = false;
};

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};
</script>

<template>
  <div>
    <el-form
      :model="formState"
      class="login-form"
      name="basic"
      autocomplete="off"
    >
      <el-form-item class="mb-3">
        <h1 class="text-black text-2xl font-medium">Log in</h1>
      </el-form-item>
      <el-form-item class="mb-4">
        <span class="text-gray-400 font-normal"
          >Don't have an account?
          <router-link
            :to="{path: '/signup', query: route.query}"
            class="cursor-pointer text-[#875eff] hover:text-[#a987ff] duration-300 transition-all"
          >
            Sign up for free</router-link>
          </span
        >
      </el-form-item>
      <el-form-item class="mb-6">
        <GoogleLogin />
      </el-form-item>
      <el-form-item
        name="email"
        class="mb-7"
        :validate-status="errorFiles.email"
      >
        <el-input
          @keyup.enter="onFinish(formState)"
          v-model="formState.email"
          @input="handleInput('email')"
          class="form-input"
          placeholder="Email"
        >
          <template #prefix>
            <img :src="prefix_email" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        name="password"
        class="mb-3"
        :validate-status="errorFiles.password"
      >
        <el-input
          @keyup.enter="onFinish(formState)"
          v-model="formState.password"
          :type="!showPassword ? 'password' : 'text'"
          @input="handleInput('password')"
          class="form-input"
          :show-password="true"
          placeholder="Password"
        >
          <template #prefix>
            <img :src="prefix_password" />
          </template>
          <!-- <template #suffix>
            <div class="cursor-pointer" @click.stop="handleShowPassword">
              <SvgIcon v-if="showPassword" name="icon_show_password" :size="24"></SvgIcon>
              <SvgIcon v-else name="icon_hide_password" :size="24"></SvgIcon>
            </div>
          </template> -->
        </el-input>
      </el-form-item>

      <!-- 错误信息展示，常驻 -->
      <el-form-item class="mb-3 h-[32px]">
        <div class="error-msg-box">{{ errorFiles.errorMsg }}</div>
      </el-form-item>

      <el-form-item>
        <div class="continue-button">
          <PrimaryButton
            @click="onFinish(formState)"
            :disabled="disabled"
            :loading="isLoading"
          >
            <span class="text-base font-normal"> Continue with email</span>
          </PrimaryButton>
        </div>
      </el-form-item>
      <el-form-item class="forgot-password">
        <router-link
          :to="{ path: '/resetPwd', query: { step: '1', type: 'forgot' } }"
          class="underline text-[#8F959E] hover:no-underline"
          >Forgot password?</router-link
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.login-form {
  min-width: 375px;

  :deep(.el-input) {
    height: 54px;
    border-color: transparent;
    border-radius: 41px;
    padding: 0;
  }

  :deep(.el-input__wrapper) {
    height: 54px;
    border-radius: 41px;
    padding: 1px 11px;
  }

  :deep(.el-form-item__content) {
    display: flex;
    justify-content: center;
    min-height: 32px;
    flex-wrap: nowrap;
  }

  :deep(.el-input__inner) {
    border-color: transparent;
    box-shadow: none;
    text-align: left;
    padding: 0;
    font-size: 14px;
    color: #000;
  }

  :deep(.el-input__prefix-inner > :last-child) {
    margin-right: 12px;
    margin-left: 10px;
  }

  :deep(.el-input__suffix-inner) {
    margin: 0 13px 0 10px;
  }

  :deep(.el-input__wrapper.is-focus) {
    box-shadow: 0 0 0 1px #875eff inset !important;
  }
}

.error-msg-box {
  font-weight: 400;
  max-width: 375px;
  color: #f54a45;
  text-align: center;
}

.continue-button {
  width: 100%;

  :deep(.el-button) {
    width: 100%;
    height: 54px;
    border-radius: 36px;
    // background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  }
}

.forgot-password {
  :deep(.el-form-item__content) {
    display: flex;
    justify-content: center;
  }

  a:hover {
    color: #875eff;
  }
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
