<template>
  <section class="slogan-container" :style="`--base-size: ${baseSize}px`">
    <h1>Turn everything into top-notch videos</h1>
    <p>
      Transform product URL, blog URL, images, videos, or text into 
      captivating videos with dynamic AI voices and great
      audio-visual treat.
    </p>
    <button class="explore-button" @click="$router.push('/workspace')">
      Explore Now
    </button>
  </section>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';

const { width: windowWidth } = useWindowSize();
const baseSize = computed(
  () => Math.max(100, (windowWidth.value / 1440) * 100) | 0,
);

</script>

<style scoped lang="scss">
.slogan-container {
  position: relative;
  padding: calc(var(--base-size) * 1.2) calc(var(--base-size) * 1.4) calc(var(--base-size) * 1.7);
  margin: 0 auto;
  background: url(./assets/slogan_background.webp) no-repeat center / cover;

  & > h1 {
    font-size: calc(var(--base-size) * .58);
    font-weight: 600;
    margin-bottom: calc(var(--base-size) * .48);
  }
  
  & > p {
    font-size: calc(var(--base-size) * .40);
    font-weight: 300;
  }
}

.explore-button {
  padding: calc(var(--base-size) * .21) calc(var(--base-size) * .74);
  margin-top: calc(var(--base-size) * .86);
  font-size: calc(var(--base-size) * .2);
  font-weight: 300;
  background-color: #181818;
  color: #FFFFFF;
  border-radius: 9999px;
  cursor: pointer;
}
</style>