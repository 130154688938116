<template>
  <Overlay :open="open" :zIndex="1000" @click.self="handleClose">
    <div class="modal-container">
      <header>
        <SvgIcon
          class="modal-close-btn"
          name="common_modal_close_icon"
          @click="handleClose"
        />
      </header>
      <div class="modal-content-container">
        <button class="btn-prev-tool" @click="$emit('update:index', index - 1)">
          <img :src="leftArrow" />
        </button>
        <div class="modal-content">
          <div
            class="preview-box"
            :style="{ aspectRatio: toolInfo.preview.ratio }"
          >
            <img
              v-if="toolInfo.preview.type === 'image'"
              :src="toolInfo.preview.url"
            />
            <advanced-video
              v-else-if="toolInfo.preview.type === 'video'"
              autoplay
              loop
              :src="toolInfo.preview.url"
              :poster="toolInfo.preview.poster"
            />
            <div v-else class="preview-text">{{ toolInfo.preview.text }}</div>
          </div>
          <div class="info-detail">
            <h3>{{ toolInfo.title }}</h3>
            <p style="height: 44px">{{ toolInfo.description }}</p>
          </div>
          <ul class="dot-list">
            <li
              v-for="i of props.list.length"
              :class="{ active: i - 1 === index }"
            ></li>
          </ul>
          <div class="relative">
            <div class="animation-container" ref="animationContainerRef"></div>
            <PrimaryButton
              v-if="toolInfo.online"
              class="use-btn"
              @click="handleUseIt"
            >
              <SvgIcon name="icon_useit" :size="17" />
              Use it
            </PrimaryButton>
            <button
              v-else
              :class="['vote-btn', toolInfo.vote ? 'voted' : '']"
              @click="handleVote"
            >
              <SvgIcon name="icon_triangle" :size="17" />
              {{
                toolInfo.vote ? numberFormat.format(toolInfo.count) : "UPVOTE"
              }}
            </button>
          </div>
        </div>
        <button
          class="btn-next-tool"
          style="transform: scale(-1, 1)"
          @click="$emit('update:index', index + 1)"
        >
          <img :src="leftArrow" />
        </button>
      </div>
    </div>
  </Overlay>
</template>

<script setup lang="ts">
import lottie from "lottie-web";
import { apiCall } from "@/utils/request";
import { toolVote, getToolVoteNum } from "@/api/aiTools";
import leftArrow from "../../assets/icons/icon_arrow.svg";
import upvoteAnimationData from "../../assets/animations/upvote.json?url";
import { numberFormat } from "../../utils/instance";
import type { Tool } from "../../type";
import { useRouter } from "vue-router";

let upvoteAnimation: any = null;
const animationContainerRef = ref(null as unknown as HTMLElement);
const emits = defineEmits(["update:index", "update:open"]);
const props = defineProps({
  list: {
    type: Array as PropType<Tool[]>,
    required: true,
  },
  index: {
    type: Number,
    default: 0,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const toolInfo = computed(() => {
  return props.list[props.index];
});

function handleClose() {
  emits("update:open", false);
}

function handleUseIt() {
  router.push({
    path: "/aiTools/upload",
    query: {
      aiToolType: toolInfo.value.name,
    },
  });
}

async function handleVote() {
  const toolName = `ai_tool_${toolInfo.value.name}`;

  if (toolInfo.value.vote) {
    upvoteAnimation.stop();
    await toolVote({ toolName, cancel: true });
  } else {
    await toolVote({ toolName, cancel: false });
    toolInfo.value.count = await apiCall(getToolVoteNum, { toolName });
    upvoteAnimation.goToAndPlay(93, true);
  }

  toolInfo.value.vote = !toolInfo.value.vote;
}

function handleAnimationComplete() {
  upvoteAnimation.stop();
}

watch(toolInfo, (info) => {
  upvoteAnimation.stop();
});

onMounted(() => {
  upvoteAnimation = lottie.loadAnimation({
    container: animationContainerRef.value,
    renderer: "svg",
    loop: false,
    autoplay: false,
    path: upvoteAnimationData,
  });

  upvoteAnimation.addEventListener("complete", handleAnimationComplete);
  upvoteAnimation.setSpeed(1.2);
});

onBeforeUnmount(() => {
  upvoteAnimation.destroy();
});
</script>

<style scoped lang="scss">
.modal-container {
  width: 840px;
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
}

.modal-content-container {
  display: flex;
  align-items: center;
  margin-block: 12px 8px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-inline: 24px;
}

.modal-close-btn {
  width: 24px;
  height: 24px;
  margin-inline: auto 0;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
}

.preview-box {
  width: 390px;
  background-color: #ebedef;
  border-radius: 10px;
  overflow: hidden;

  & > img,
  & > video {
    width: 100%;
  }

  & > .preview-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 64px;
  }
}

.btn-prev-tool,
.btn-next-tool {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 66px;
  border: 1px solid #e5e7eb;
  border-radius: 50%;

  &:hover {
    background-color: #ebedef;
  }
}

.info-detail {
  margin-block: 24px 16px;

  & > h3 {
    margin-bottom: 18px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #1f2329;
    line-height: 1;
  }

  & > p {
    font-size: 14px;
    color: #646a73;
    line-height: 1.57;
  }
}

.dot-list {
  display: flex;
  gap: 6px;

  & > li {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #bbbfc4;

    &.active {
      background-color: #646a73;
    }
  }
}

.vote-btn,
.use-btn:deep(> .el-button) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 54px;
  margin-top: 26px;
  font-size: 14px;
  border-radius: 9999px;

  & svg {
    margin-right: 8px;
  }
}

.vote-btn {
  color: #060606;
  border: 1px solid #bbbfc4;

  &:hover,
  &.voted {
    color: #6741ff;
    border-color: #875eff;

    & > svg {
      color: inherit;
    }
  }

  & > svg {
    color: #535558;
    transform: translateY(0.1em);
  }

  &:hover {
    background-color: #f8f5ff;
  }
}

.animation-container {
  position: absolute;
  width: 350px;
  height: 350px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  pointer-events: none;
}
</style>
