<template>
  <div class="progress-container">
    <div class="image-container">
      <div class="logo" @click="">
        <img :src="info.url" alt="" />
      </div>
    </div>
    <div class="progress-box">
      <span class="progress-tip">uploading...</span>
      <div class="progress-bar">
        <div
          :class="progressClass"
          :style="{ width: `${progress * 100}%` }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import type { PropType } from "vue";

interface UploadInfo {
  id: string;
  url?: string;
  state?: 0 | 1 | 2;
  progress?: number;
}

const props = defineProps({
  info: {
    type: Object as PropType<UploadInfo>,
    required: true,
  },
  type: {
    type: String as PropType<"default" | "loop">,
    default: "default",
  },
});

const progress = ref(0);
const progressClass = computed(
  () => `progress ${props.type === "loop" ? "progress-loop" : ""}`,
);

watch(
  () => props.info,
  (value) => {
    if (value.progress) progress.value = value.progress;
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>

<style lang="scss" scoped>
.progress-container {
  position: relative;
  padding: 0;
}

.progress-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  inset: 0;
  border-radius: var(--card-border-radius);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.progress-tip {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  margin-bottom: 16px;
}

.progress-bar {
  position: relative;
  width: 160px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow-x: hidden;
}

.progress {
  height: 100%;
  background-color: #ffffff;
  transition: width 0.2s;
}

.progress-loop {
  position: absolute;
  animation: progressLoop 1.5s linear infinite;
}

:deep(.video-name),
:deep(.update-time) {
  color: #bbbfc4;
}

.image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f6;
  aspect-ratio: 1 / 1;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: var(--card-border-radius);

  & > img {
    max-width: 100%;
    max-height: 100%;
    border-radius: var(--card-border-radius);
  }
}
</style>
