<template>
  <div class="payment-container">
    <header class="w-full p-4">
      <Back />
    </header>
    <div class="overflow-auto w-full h-full lg:h-max">
      <component
        :is="paymentMap[currentType]"
        :key="currentType"
        :packageCode="packageCode"
      >
      </component>
    </div>
  </div>
</template>

<script setup lang="tsx">
import { useModalManager } from "@/components/common/custom-modal/instance";
import { useMessage } from "@/utils";
import Back from "./back.vue";
import Subscribe from "./subscribe.vue";
import Update from "./update.vue";
type PaymentType =
  | "NEW_SUBSCRIPTION"
  | "UPGRADE_SUBSCRIPTION"
  | "DEGRADE_SUBSCRIPTION";
const route = useRoute();
const paymentMap = reactive({
  NEW_SUBSCRIPTION: Subscribe,
  UPGRADE_SUBSCRIPTION: Update,
  DEGRADE_SUBSCRIPTION: Update,
});
const currentType: Ref<PaymentType> = ref("NEW_SUBSCRIPTION");
const packageCode = ref(0);

onBeforeMount(() => {
  const {
    packageCode: _packageCode,
    subscriptionOperType: _subscriptionOperType,
  } = route.query;
  packageCode.value = parseInt(_packageCode as string, 10);
  currentType.value = _subscriptionOperType as PaymentType;
});
</script>

<style lang="scss" scoped>
.payment-container {
  position: relative;
  height: calc(100vh - 65px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  :deep(.el-scrollbar) {
    width: 100%;
  }

  :deep(.el-scrollbar__wrap) {
    width: 100%;
  }

  :deep(.el-scrollbar__view) {
    width: 100%;
  }
}

</style>

<style lang="scss">
.payment-fail-modal.modal-container {
  width: 500px
}
</style>
