<template>
  <div class="flex items-center flex-col w-full h-full relative">
    <div
      v-if="currentMode == 'upload'"
      class="upload-container"
      tips="Click button or drop images here to upload"
      @mouseenter="handleShowImageList"
      @mouseleave="handleHiddenImageList"
    >
      <custom-upload
        ref="uploadRef"
        uploadType="drag"
        :accept="accept"
        :handleError="handleError"
        :beforeUpload="handleBeforeUpload"
        :getFileListLength="getFileListLength"
        @uploadProcess="handleProgress"
        @onSuccess="handleSuccess"
        @click.capture="handleClick"
      >
        <template #icon>
          <svg-icon
            name="icon_upload_image"
            :style="{ width: '46px', height: '58px', color: '#FFE68E' }"
          ></svg-icon>
        </template>
        <template #tip>
          <p
            class="text-primaryColor text-sm font-normal mt-3 leading-22"
            @click.stop="handleUploadFromCloud"
          >
            Or import from cloud
          </p>
          <p
            v-if="route.query.aiToolType === 'videoBackgroundRemover'"
            class="upload-tip"
          >
            Only supports videos with human presence
          </p>
          <p
            v-if="route.query.aiToolType === 'videoEnhancer'"
            class="upload-tip"
          >
            Max 500MB, 1min, 1080p × 1080p resolution
          </p>
        </template>
      </custom-upload>
      <div
        v-if="usedCase?.onlyPreview"
        class="use-tip"
        @click="handleClickCase(0)"
      >
        <span class="play-icon" v-html="playIcon"></span>
        View instructions
      </div>
      <div v-else class="case-box">
        <span class="case-tip">
          {{ `No ${usedCase?.type}? Try one of these` }}
        </span>
        <ul class="case-list" :style="caseListStyle">
          <li v-for="(item, i) of usedCase?.cases" @click="handleClickCase(i)">
            <video
              v-if="usedCase!.type === 'video'"
              autoplay
              muted
              loop
              :src="item.thumbnail"
            ></video>
            <img v-else :src="item.thumbnail" :draggable="false" />
          </li>
        </ul>
      </div>
    </div>
    <CloudAssetModal
      :multiple="false"
      :isAiType="true"
      :accept="isVideo ? 'video' : 'image'"
      :visible="isOpenAssetDialog"
      @close="handleCloseAssetDialog"
      @confirm="handleConfirm"
    />
    <div
      class="loading-mask fixed top-0 left-0 h-full w-full bg-white opacity-60"
      v-if="loading"
    ></div>
    <div
      class="loading-mask fixed top-0 left-0 h-full w-full bg-white opacity-60"
      v-if="route.query.aiToolType === 'videoEnhancer'"
    ></div>

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSingleMessage, queryVNode } from '@/utils';
import { CustomUpload } from '@/components/common/index';
import CloudAssetModal from '@/components/asset-modal/cloudAssetDialog/index.vue';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

import { setItem } from '@/utils/storage';
import { useToolStore } from '../stores/tool';
import { useSubscriptionInfo } from '@/store/modules/user';
import { useTrackStore } from '@/store/modules/track';
import { useModalManager } from '@/components/common/custom-modal/instance';
import playIcon from '../assets/icons/icon_play.svg?raw';
import toolCase from '../assets/cases';

type ResourceType = {
  uid?: number;
  id: string | number;
  url?: string;
  '1080url'?: string;
  state?: number;
  mid?: string;
  type?: string;
  upload?: boolean;
  percent: number;
  selected?: boolean;
  fileUrl?: string;
  duration?: number;
};

type Tool = {
  name: string;
  title: string;
  type: 'image' | 'copywriting' | 'video';
  description: string;
  abbreviation: string;
  count: number;
  vote: boolean;
  online: boolean;
  path: string;
  preview: {
    url: string;
    ratio: string;
  } & ({ type: 'image' } | { type: 'video'; poster: string });
};

let preUpload = null as File | null;
const { collectData, track } = useTrackStore();
const { toolList } = useToolStore();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  currentMode: String,
});

const uploadRef = ref(null as unknown as InstanceType<typeof CustomUpload>);
const isVideo = ref(false);
const aiToolType = ref('');
const isOpenAssetDialog = ref(false);
const currentMode = ref('upload');
const resourceListLength = ref(0);
const loading = ref(false);
const resourceList = ref<ResourceType[]>([]);
const showExamples = ref(false);
const Message = useSingleMessage();
const subscriptionInfo = useSubscriptionInfo();
const modalManager = useModalManager();
const instanceVnode = getCurrentInstance()!.vnode;
const cancelUploadTask = ref(false);
const subscribeModalVisible = ref(false);

const usedCase = (() => {
  const aiToolType = (route.query.aiToolType || '').toString();

  if (aiToolType in toolCase) {
    return toolCase[route.query.aiToolType as keyof typeof toolCase];
  } else {
    return null;
  }
})();

const caseListStyle = (() => {
  if (usedCase === null) return {};
  return usedCase.cases.length === 1 ? { width: '200px' } : { width: '395px' };
})();

const accept = computed(() => {
  const aiToolType = route.query?.aiToolType as string;
  isVideo.value = ['videoBackgroundRemover', 'videoEnhancer'].includes(
    aiToolType
  );
  if (isVideo.value) {
    return '.mp4';
  }
  return '.png,.PNG,.jpeg,.JPEG,.webp,.WEBP,.JPG,.jpg';
});

const getFileListLength = (num: number) => {
  resourceListLength.value = num;
};

const handleUploadFromCloud = () => {
  isOpenAssetDialog.value = true;
};

const handleCloseAssetDialog = () => {
  isOpenAssetDialog.value = false;
};

const handleUploadClose = () => {
  resourceListLength.value = 0;
  uploadRef.value?.clearFileArr();
  cancelUploadTask.value = true;
  loading.value = false;
};

const continueUpload = () => {
  cancelUploadTask.value = false;
};

const uploadComplete = (data: ResourceType) => {
  if (cancelUploadTask.value) {
    return;
  }

  collectData('boolvideo_aitools_use', {
    tool_name: toolList.find((item) => item.name == aiToolType.value)?.title,
    access: 'ai_pages',
  });
  track('boolvideo_aitools_use');

  // 不同工具跳转不同的页面
  router.push({
    path: toolList.find((item) => item.name == aiToolType.value)?.path,
    query: {
      url: data['1080url'],
    },
  });
};

const handleClickCase = (i: number) => {
  const target = usedCase!.cases[i];

  if ('preview' in target) {
    modalManager.applyTemplate('info', {
      modalClass: 'case-result-preview-modal',
      title: '',
      content: h('video', {
        muted: true,
        loop: true,
        autoplay: true,
        src: target.preview,
        style: {
          width: '100%',
          border: '1px solid #E5E7EB',
          marginBlock: '12px 6px',
          borderRadius: '4px',
        },
      }),
    });
  } else {
    router.push({
      path: toolList.find((item) => item.name == aiToolType.value)?.path,
      query: {
        url: target.input,
      },
    });
  }
};

const handleShowImageList = () => {
  showExamples.value = true;
};

const handleHiddenImageList = () => {
  showExamples.value = false;
};

const handleSelectImage = (data: ResourceType) => {
  uploadComplete(data);
};

const handleConfirm = (list: ResourceType[]) => {
  cancelUploadTask.value = false;
  const userResource = subscriptionInfo.getUserResource(
    'boolvideo_tools_' + (isVideo.value ? 'video' : 'image')
  );

  if (
    userResource === undefined ||
    (isVideo.value
      ? !userResource.unlimitedDuration && userResource.num < list[0].duration!
      : !userResource.unlimitedNum && userResource.num === 0)
  ) {
    modalManager.applyTemplate('noCredits', {
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  resourceList.value = list;
  uploadComplete(resourceList.value && resourceList.value[0]);
};

const handleProgress = (data: ResourceType) => {
  const index = resourceList.value.findIndex((image) => image.uid == data.uid);

  if (index >= 0) {
    resourceList.value[index] = { ...resourceList.value[index], ...data };
  } else {
    resourceList.value.push(data);
  }
};

const handleSuccess = (data: ResourceType) => {
  // 成功之后根据mid 复写数据
  const index = resourceList.value.findIndex((image) => image.uid == data.uid);
  if (index >= 0) {
    resourceList.value[index] = { ...resourceList.value[index], ...data };
  }

  preUpload = null;
};

const handleError = (data: ResourceType) => {
  // 因为失败可能不存在 mid 的现象， 所以这里使用uid查找
  const index = resourceList.value.findIndex((image) => image.uid == data.uid);
  if (index >= 0) {
    resourceList.value[index] = { ...resourceList.value[index], ...data };
  } else {
    resourceList.value.push(data);
  }

  Message.error('Upload failed, please try again.');
  preUpload = null;
};

const getVideoInfoFromFile = async (file: File) => {
  return new Promise((resolve, reject) => {
    var video = document.createElement('video');
    video.preload = 'metadata';
    let info = {};
    video.src = URL.createObjectURL(file);
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      info.duration = Math.ceil(video.duration);
      resolve(info);
    };
  });
};

const checkVideo = async (file: File) => {
  const videoInfo = await getVideoInfoFromFile(file);
  //limit duration
  if (Math.ceil(videoInfo.duration) > 60) {
    Message.error('Video length cannot exceed 1min', {
      duration: 0,
      onClose: handleUploadClose,
    });
    return false;
  }
};
const handleBeforeUpload = (file: File) => {
  checkVideo(file);
  continueUpload();
  if (preUpload === file) return;
  else if (preUpload !== null) return false;

  const elUploadExpose = queryVNode(instanceVnode, 'ElUpload')!.component!
    .exposeProxy!;

  const startUpload = () => {
    resourceList.value = [];
    loading.value = true;
    Message.loading('Uploading... it will take a while', {
      duration: 0,
      onClose: handleUploadClose,
    });
  };

  if (isVideo.value) {
    const userResource = subscriptionInfo.getUserResource(
      'boolvideo_tools_video'
    );
    getVideoDuration(file).then((duration) => {
      if (
        userResource === undefined ||
        (!userResource.unlimitedDuration && userResource.num < duration)
      ) {
        modalManager.applyTemplate('noCredits', {
          onConfirm: () => {
            subscribeModalVisible.value = true;
          },
        });
        return;
      } else {
        elUploadExpose.handleStart(file);
        elUploadExpose.submit();
        startUpload();
      }
    });
    preUpload = file;
    return false;
  }

  startUpload();
};

function getVideoDuration(file: File): Promise<number> {
  const video = document.createElement('video');
  video.src = URL.createObjectURL(file);

  return new Promise((res) => {
    video.onloadedmetadata = () => {
      res(video.duration);
      video.remove();
    };
  });
}

const handleClick = (e: Event) => {
  const userResource = subscriptionInfo.getUserResource(
    'boolvideo_tools_' + (isVideo.value ? 'video' : 'image')
  );

  if (
    userResource === undefined ||
    (!userResource.unlimitedDuration && userResource.num === 0)
  ) {
    modalManager.applyTemplate('noCredits', {
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    e.stopPropagation();
  }
};

watch(
  () => resourceList.value,
  () => {
    const index = resourceList.value.findIndex((image) => image.state == 2);
    if (index >= 0) {
      // 存在正在上传中的项目
      return;
    }

    if (
      resourceList.value.length &&
      resourceList.value.length === resourceListLength.value
    ) {
      // 上传完成
      loading.value = false;
      Message.close();

      uploadComplete(resourceList.value && resourceList.value[0]);
    }
  },
  {
    deep: true,
  }
);

onMounted(() => {
  aiToolType.value = route.query?.aiToolType as string;
  setItem('aiToolType', aiToolType.value);
});
</script>
<style lang="scss" scoped>
.craw-container {
  :deep(.el-input__inner) {
    height: 54px;
    width: 100%;
  }
}

.image-box-background {
  width: 394px;
  height: 212px;
  // margin-top: 14px;
  padding-top: 28px;
  position: absolute;
  left: 50%;
  margin-left: -197px;
}

.inner-image-box:before {
  width: 0px;
  height: 0px;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: -32px;
  padding: 0;
  border: 16px solid transparent;
  border-color: transparent transparent #ffffff transparent;
  content: '';
  z-index: 12;
}

.inner-image-box:after {
  width: 0px;
  height: 0px;
  position: absolute;
  left: 50%;
  margin-left: -9.5px;
  top: -35px;
  padding: 0;
  border: 17.5px solid transparent;
  border-color: transparent transparent #f1f1f1 transparent;
  content: '';
  z-index: 10;
}

.inner-image-box {
  width: 394px;
  height: 212px;
  position: absolute;
  // background-color: green;
  // margin-top: 14px;

  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.upload-position-container {
  position: relative;
}

.example-images-box {
  display: flex;
  justify-content: space-between;
  padding: 22px 20px;
}

.example-video-box {
  display: flex;
  justify-content: center;
  padding: 22px 98px;
}

.example-image-icon-box {
  cursor: pointer;
  background-color: #f6f6f6;
  width: 110px;
  height: 110px;
  border-radius: 4px;
}

.example-video-demo-box {
  border-radius: 4px;
}

.example-image-icon {
  width: 110px;
  height: 110px;
  border-radius: 4px;
}

.example-video-demo {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.tooltip-title {
  text-align: center;
  color: #8f959e;
  padding-top: 24px;
}

.upload-tip {
  margin-top: 12px;
  font-size: 14px;
  color: #8f959e;
}

.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-block: 48px;

  & > div {
    width: auto;
    height: auto;
  }
}

.case-box {
  margin-top: 42px;
}

.case-list {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin: 22px auto 0;

  & > li {
    position: relative;
    flex: 1;
    width: 100%;
    height: 110px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      opacity: 0;
      background-color: #000000;
      transition: opacity 0.2s;
      z-index: 1;
    }

    &:hover::after {
      opacity: 0.3;
    }

    & > img,
    & > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.05);
      clip-path: inset(1px);
    }
  }
}

.case-tip {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #8f959e;
}

.case-result-preview-modal {
  width: 840px;
}

.play-icon {
  display: inline-block;
  margin-right: 12px;
}

.use-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
  color: #646a73;
  cursor: pointer;
}
</style>
