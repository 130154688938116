import type { VNode } from "vue";
import { RouterLink } from "vue-router";

const infoMap: Record<string, {
  title: string;
  content: VNode;
}> = {
  ['product-to-video']: {
    title: 'Convert Your Product URL\ninto Engaging Videos In Seconds',
    content: (
      <>
        Boolvideo transforms product URLs into captivating videos within seconds, revolutionizing product ad testing, traffic catching, and sales growth through high-engagement video content.
        <br /><br />
        <b>1. Bulk Video Production for Testing: </b><br />
        Boolvideo enables you to rapidly create multiple videos to discover the most effective promotional content, subsequently boosting sales.
        <br /><br />
        <b>2. Attract Buyers with Listing Videos: </b><br />
        By showcasing products through quick-to-process videos, you can seize buyer interest and present comprehensive details, ultimately improving conversion rates.
        <br /><br />
        <b>3. Social Media Integration: </b><br />
        Schedule multiple videos on social media planning tools to drive traffic to your products across various social platforms.
        <br /><br />
        <b>Tailored Video Styles: </b><br />
        Depending on your marketing strategy, choose from two unique video aesthetics offered by Boolvideo:
        <br /> - AI Voiceover Videos: Focus on high-quality scripts and a mix of narrative, text, and voice elements to present a clear, story-driven portrayal of your product, ideal for unboxing videos or how-to guides.
        <br /> - Non-Voiceover Videos: Emphasize a blend of sound and vision through customized music, effects, and images to create a visually stunning short video, great for quick, attention-grabbing showcases.
        <br /><br />
        <b>Sophisticated Video Editing with a Single Click: </b><br />
        Utilize Boolvideo’s advanced AI voiceovers and rich media collection for swift, effortless production of polished videos. Intelligent features include auto-cropping, background matching, and integration of various media elements.
        <br /><br />
        Boolvideo delivers an innovative video creation service that not only informs viewers but also captures their attention, making it simpler to resonate with your audience and create a memorable brand experience.
      </>
    ),
  },
  ['idea-to-video']: {
    title: 'Convert Your Ideas\ninto Captivating Videos Easily',
    content: (
      <>
        Are you eager to produce videos for social media with just a spark of an idea? Boolvideo unveils a revolutionary tool that transforms your brief video concept, mood, or style prompt into reality. At the touch of a button, Boolvideo's AI expertly crafts an engaging script and harmonizes it with fitting media.
        <br /><br />
        Enhance your online presence with content designed for various social platforms like Instagram, TikTok, YouTube Shorts, Twitter, and Facebook. With Boolvideo's Idea to Video feature, you start with a selection of diverse AI Voices, which you can fine-tune for each individual scene by adjusting tone, volume, speed, and pitch to your preference.
        <br /><br />
        Furthermore, if you feel the existing language doesn't suit your taste, BooIvideo offers seamless one-click language conversion. Enjoy personalized narration control and captivate your audience with content that resonates.
      </>
    ),
  },
  ['script-to-video']: {
    title: 'Convert Written Scripts\nto Video Effortlessly',
    content: (
      <>
        Are you in search of the ultimate ease in content creation, aspiring to turn your written scripts into compelling videos without a hassle? Look no further, as we present you the cutting-edge path to bring your visions to the digital stage using advanced artificial intelligence.
        <br /><br />
        Boolvideo's 'Script to Video' is the simplest and most efficient way to metamorphose your scripts into engaging video content. This innovation is not just about generating plain visuals but about crafting immersive experiences for social media enthusiasts across Instagram, TikTok, and YouTube Shorts.
        <br /><br />
        Begin with merely a few lines of text that embody your core message or idea. Boolvideo takes this input and breathes life into it, <b>weaving together high-quality stock footage, lifelike AI narrations, captivating soundtracks, tailored effects, and much more.</b> You are at the helm, steering every aspect of the video creation process.
        <br /><br />
        The scope doesn't end there. <b>Enjoy the freedom to mix and match elements with the drag-and-drop ease</b> that brings sophistication sans the complexity. Become an orchestrator of your content's vibe, rhythm, and narrative flow through control that was previously unthinkable without technical expertise in video editing.
        <br /><br />
        Imagine liberating yourself from the constraints of traditional content creation. Our platform enables personalized storytelling that speaks directly to your audience's wants and interests. Seize the moment, empower your idea, and engage with your followers on a level that transcends the usual. Discover the transformative effect of taking a dream, a script, and casting it into the visual wonder of the social media world – effortlessly, seamlessly, like never before.
      </>
    ),
  },
  ['blog-to-video']: {
    title: 'Convert Your Blog\ninto Stunning Videos Smoothly',
    content: (
      <>
        Effortlessly transform your blog content into engaging videos with Boolvideo. With the convenience of script-driven editing, the excellence of AI-generated voices, and the extensive selection of a media library, producing polished videos is now simpler and more rapid than ever before.
        <br /><br />
        Enhance your content marketing approach and broaden your reach by effortlessly converting your written blog content into enticing videos that grab viewers' attention and stimulate interaction.
      </>
    ),
  },
  ['visuals-to-video']: {
    title: 'Convert Your Visuals\ninto Striking Videos In Clicks',
    content: (
      <>
        Transform your images into dynamic videos with Boolvideo's 'Visuals to Video' feature, offering two tailored video options to bring your visuals to life. Whether you're looking to create a narrative with depth or a quick, sensory-rich showcase, Boolvideo has the tools to meet your needs.
        <br /><br />
        For those seeking a story-driven video, our AI Voiceover option is the perfect fit. It takes your provided materials, such as photos or videos, and focuses on crafting a high-quality script. The AI then weaves together visuals, text, and voiceover to deliver a clear, impactful story, customizable to your desired length. This option is <b>ideal for Unboxing videos, Step by step introductions, or Listicle videos,</b> where engaging storytelling is key to animating your static resources.
        <br /><br />
        On the other hand, if you prefer a video that emphasizes the beauty of sight and sound, our no AI Voiceover option prioritizes the aesthetic combination of auditory and visual elements. This approach is designed to create a 15-second feast for the senses, <b>perfect for Slideshow videos or Short videos</b> that captivate with their visual and auditory appeal.
        <br /><br />
        Boolvideo's 'Visuals to Video' feature ensures that your images are not just displayed but transformed into a compelling narrative or a stunning audiovisual experience, depending on your chosen video style.
      </>
    ),
  },
  ['template-to-video']: {
    title: 'Convert Your visuals\ninto Professional high-quality Videos Easily',
    content: (
      <>
        In the dynamic world of e-commerce, Boolvideo stands at the forefront of innovation, providing <b>the finest video templates specifically crafted by leading designers and AI engineers.</b> Seamlessly integrated with major platforms such as Shopify, Amazon, and Etsy, Boolvideo empowers merchants to create short videos for a wide array of products and services with unmatched ease and efficiency.
        <br /><br />
        With <b>Boolvideo's one-click video generation</b>, product advertising testing becomes a matter of simplicity: instantly transform any product URL into a custom-tailored video advertisement. Leveraging AI to analyze product details, Boolvideo streamlines the ad creation process, ensuring each video resonates with its intended audience. Moreover, <b>Boolvideo’s batch production feature</b> enables merchants to efficiently produce numerous AI-generated videos at once, thereby maintaining a competitive edge by flooding the market with a plethora of creative options.
        <br /><br />
        Beyond simplifying video production, Boolvideo's sophisticated templates are expertly designed to catch the eye and showcase the unique features of products. By leveraging these exceptional templates, merchants can <b>significantly enhance website dwell time, increasing the likelihood of conversion</b> and <b>amplifying sales potential</b>.
        <br /><br />
        Finally, Boolvideo offers a springboard to social media dominance by <b>providing high-quality, vibrant animation template videos</b> that command attention across social platforms. Such top-tier visual content is crucial in <b>expanding market reach, outshining competitors, and driving up sales figures</b> as merchants harness the power of social media to lead the race in their respective markets.
      </>
    ),
  },
  ['web-editor']: {
    title: 'Simple but powerful',
    content: (
      <>
        Designed for efficiency and power, our video editor is an elegant tool that's lightweight in size but loaded with features. With a minimalist interface and comprehensive capabilities, the editor optimizes for a seamless user experience without sacrificing the power under the hood.
        <br /><br />
        <b>AI Video Script Generator</b><br />
        For those looking to jumpstart their campaigns, our <b>AI video script generator</b> is a game-changer. Leveraging the knowledge obtained from thousands of high-performing social media video ads, it <b>crafts engaging scripts tailor-made for your marketing needs</b>. Boolvideo's AI analyzes your product or service, pulls all the necessary data, and generates countless script variations to captivate your audience. If the script isn't quite to your taste, step in at any time to tweak and personalize it—ensuring your voice shines through.
        <br /><br />
        <b>AI voiceover</b><br />
        When your video needs a voiceover, our rich text-to-speech options come to the rescue. With the array of languages available, you can break down language barriers, catering your content to varied audiences and extending your ad campaigns globally without missing a beat.
        <br /><br />
        <b>AI tools</b><br />
        AI tools within the editor, such as background removal, object erasing, and image enhancement, add another layer of sophistication to your editing capabilities. These cutting-edge assets are indispensable for creating content that stands out in a crowded marketplace.
        <br /><br />
        <b>AI Assistant</b><br />
        Furthermore, our AI Assistant magnifies your productivity tenfold by generating stunning copy and visuals, such as stickers, at the command of your keyboard. Compose your requests, and let the AI Assistant handle the crafting, so you can focus more on the bigger picture of your video project.
        <br /><br />
        <b>Video keyframes</b><br />
        For the animation enthusiasts, our keyframe settings let you orchestrate animations with the kind of precision typically only found in large-scale editing software. Step into the realm of professional animation without needing a hefty application.
        <br /><br />
        <b>Canvas Mask</b><br />
        Our canvas mask feature is a creative powerhouse allowing for advanced editing techniques that can elevate your videos to the next level. With this, masking becomes a fluid part of the creative process, unlocking new potential for every project.
        <br /><br />
        <b>Basic functions and rich material gallery resources</b><br />
        All fundamental features come standard with our editor; you can expect hassle-free uploading to cloud storage, supporting picture, video, and audio file formats. When combined with our fully stocked library containing an extensive range of media from Pexels and more, you have instant access to high-quality visual content at your fingertips.
        <br /><br />
        <b>Extensive music library</b><br />
        Last but not least, our extensive music library is filled with royalty-free tracks giving you the freedom to soundtrack your videos to perfection. With this wealth of resources at your disposal, you can forget concerns about copyright and focus instead on cultivating the perfect ambience for your creations.
      </>
    ),
  },
  ['ecommerce-seller']: {
    title: 'Boolvideo\'s AI Video Solutions for Ecommerce Sellers',
    content: (
      <>
        <p>Boolvideo empowers ecommerce sellers to overcome these challenges by providing an AI-powered, user-friendly platform for effortless video creation and editing.</p>
        <br />
        <h3>
          <RouterLink to='/product-to-video'>
            Product URL to Video
          </RouterLink>
        </h3>
        <p>Automatically generate professional-quality product videos by simply entering your product URL, showcasing your offerings in a captivating and informative manner by choosing either AI Voiceover videos for better story-telling or non-voiceover videos for product showcase.</p>
        <br />
        <h3>
          <RouterLink to='/idea-to-video'>
            Idea to Video
          </RouterLink>
        </h3>
        <p>Transform your product descriptions, marketing copy, or scripts into engaging video content with just a few clicks.</p>
        <br />
        <h3>
          <RouterLink to='/visuals-to-video'>
            Visuals to Video
          </RouterLink>
        </h3>
        <p>Synthesize your images and videos into vibrant video productions.</p>
        <br />
        <h3>
          <RouterLink to='/template-video-list'>
            Instant Video Templates
          </RouterLink>
        </h3>
        <p>Kickstart your video creation process with a vast library of pre-built, ecommerce-specific video templates tailored for various needs, such as product demos, social media ads, and brand storytelling.</p>
        <br />
        <h3>
          <RouterLink to='/editor'>
            Intuitive Video Editing
          </RouterLink>
        </h3>
        <p>Fine-tune your videos with Boolvideo's user-friendly web-based video editor, incorporating your brand elements, visual effects, and motion graphics.</p>
      </>
    ),
  },
  ['content-creator']: {
    title: 'Boolvideo\'s AI Video Solutions for Content Creators',
    content: (
      <>
        <p>Boolvideo empowers content creators to overcome these challenges by providing a comprehensive suite of powerful yet user-friendly video creation and editing tools.</p>
        <br />
        <h3>
          <RouterLink to='/idea-to-video'>
            Idea to Video
          </RouterLink>
        </h3>
        <p>Transform your written content, such as blog posts, scripts, or stories, into captivating video formats with just a few clicks.</p>
        <br />
        <h3>
          <RouterLink to='/blog-to-video'>
            Blog to Video
          </RouterLink>
        </h3>
        <p>Convert your blog posts or other web-based written content into lively and engaging video formats. </p>
        <br />
        <h3>
          <RouterLink to='/script-to-video'>
            Script to Video
          </RouterLink>
        </h3>
        <p>Bring your video scripts and screenplays to life by effortlessly transforming them into captivating video content</p>
        <br />
        <h3>
          <RouterLink to='/visuals-to-video'>
            Visuals to Video
          </RouterLink>
        </h3>
        <p>Synthesize your images, illustrations, and visual assets into vibrant video productions.</p>
        <br />
        <h3>
          <RouterLink to='/template-video-list'>
            Customizable Templates
          </RouterLink>
        </h3>
        <p>Access a vast library of professionally designed video templates tailored for various content types.</p>
        <br />
        <h3>
          <RouterLink to='/editor'>
            Intuitive Video Editing
          </RouterLink>
        </h3>
        <p>Boolvideo's intuitive web-based video editor allows you to fine-tune your videos with ease, incorporating your branded elements, visual effects, and motion graphics.</p>
      </>
    ),
  },
  ['social-media-marketer']: {
    title: 'Boolvideo\'s AI Video Solutions',
    content: (
      <>
        <p>Boolvideo empowers social media marketers to overcome these challenges by providing a comprehensive suite of powerful yet user-friendly video creation and editing tools.</p>
        <br />
        <h3>
          <RouterLink to='/product-to-video'>
            Product URL to Video
          </RouterLink>
        </h3>
        <p>Automatically generate professional-quality product videos by simply entering your product URL, showcasing the products either for story-telling or for product showcase.</p>
        <br />
        <h3>
          <RouterLink to='/idea-to-video'>
            Idea to Video
          </RouterLink>
        </h3>
        <p>Convert your ideas into engaging videos</p>
        <br />
        <h3>
          <RouterLink to='/script-to-video'>
            Script to Video
          </RouterLink>
        </h3>
        <p>Transform your written content, such as social media captions, blog posts, or scripts, into engaging videos.</p>
        <br />
        <h3>
          <RouterLink to='/blog-to-video'>
            Blog to Video
          </RouterLink>
        </h3>
        <p>Convert your blog posts or other web-based written content into lively and engaging video formats. </p>
        <br />
        <h3>
          <RouterLink to='/template-video-list'>
            Customizable Templates
          </RouterLink>
        </h3>
        <p>Access a vast library of professionally designed, social media-optimized video templates tailored for various marketing objectives, from product promotions to brand storytelling.</p>
        <br />
        <h3>
          <RouterLink to='/editor'>
            Intuitive Video Editing
          </RouterLink>
        </h3>
        <p>Boolvideo's intuitive web-based video editor allows you to fine-tune your videos with ease, incorporating your brand elements, visual effects, and motion graphics.</p>
      </>
    ),
  },
  ['photographer']: {
    title: 'Boolvideo\'s AI Video Solutions for Photographers',
    content: (
      <>
        <p>Boolvideo empowers photographers to overcome these challenges by providing a comprehensive suite of powerful yet user-friendly video creation and editing tools, allowing you to seamlessly transform your photographic artistry into captivating video stories.</p>
        <br />
        <h3>
          <RouterLink to='/visuals-to-video'>
            Visuals to Video
          </RouterLink>
        </h3>
        <p>Effortlessly turn your visual artwork into captivating videos.</p>
        <br />
        <h3>
          <RouterLink to='/idea-to-video'>
            Idea to Video
          </RouterLink>
        </h3>
        <p>Convert your ideas into engaging videos.</p>
        <br />
        <h3>
          <RouterLink to='/script-to-video'>
            Script to Video
          </RouterLink>
        </h3>
        <p>Transform your written content, such as social media captions or scripts into engaging videos and replace the materials with your own artwork.</p>
        <br />
        <h3>
          <RouterLink to='/blog-to-video'>
            Blog to Video
          </RouterLink>
        </h3>
        <p>Convert your blog posts or other web-based written content into lively and engaging video formats.</p>
        <br />
        <h3>
          <RouterLink to='/template-video-list'>
            Customizable Template Library
          </RouterLink>
        </h3>
        <p>Access a vast collection of professionally designed video templates tailored for various needs.</p>
        <br />
        <h3>
          <RouterLink to='/editor'>
            Intuitive Video Editing
          </RouterLink>
        </h3>
        <p>Use Boolvideo's intuitive web-based editor to fine-tune your videos, incorporating your artistic style elements, visual effects and more.</p>
      </>
    ),
  },
  ['artist']: {
    title: 'Boolvideo\'s AI Video Solutions for Artists',
    content: (
      <>
        <p>Boolvideo empowers artists to overcome these challenges by providing powerful yet user-friendly video creation and editing tools, allowing you to transform your art into engaging visual experiences that resonate with your audience.</p>
        <br />
        <h3>
          <RouterLink to='/visuals-to-video'>
            Visuals to Video
          </RouterLink>
        </h3>
        <p>Effortlessly turn your paintings, sculptures, and other visual artworks into captivating videos.</p>
        <br />
        <h3>
          <RouterLink to='/idea-to-video'>
            Idea to Video
          </RouterLink>
        </h3>
        <p>Convert your ideas into engaging videos</p>
        <br />
        <h3>
          <RouterLink to='/script-to-video'>
            Script to Video
          </RouterLink>
        </h3>
        <p>Transform your written content, such as social media captions or scripts into engaging videos and replace the materials with your own artwork.</p>
        <br />
        <h3>
          <RouterLink to='/template-video-list'>
            Customizable Template Library
          </RouterLink>
        </h3>
        <p>Access a vast collection of professionally designed video templates tailored for various needs</p>
        <br />
        <h3>
          <RouterLink to='/editor'>
            Intuitive Video Editing
          </RouterLink>
        </h3>
        <p>Use Boolvideo's intuitive web-based editor to fine-tune your videos, incorporating your artistic style elements, visual effects and more.</p>
      </>
    ),
  },
};

export default infoMap;

export const useData = () => {
  const route = useRoute();
  const pageName = (route.name as string).match(/(?:^case-|^feature-)?(.+)/)![1];

  return infoMap[pageName];
};
