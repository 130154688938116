<template>
  <el-dropdown
    :trigger="trigger"
    :popper-class="'bv-dropdown-popper ' + popperClass"
    :placement="placement"
    :teleported="teleported"
    :popper-options="popperOptions"
    @command="handleCommand"
    @visibleChange="handleVisibleChange"
  >
    <slot></slot>
    <template #dropdown>
      <div class="dropdown-wrapper">
        <slot name="header" />
        <div 
          v-if="showSearch"
          class="search-container"
        >
          <el-input
            class="dropdown-search-input"
            v-model="searchContent"
            :placeholder="searchPlaceholder"
          >
            <template #prefix>
              <svg-icon
                name="icon_search"
                :size="18"
              />
            </template>
          </el-input>
        </div>
        <el-scrollbar 
          class="menu-scrollbar"
          :max-height="maxHeight"
        >
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="item in displayOptions"
              :key="item.label"
              :command="item"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-scrollbar>
      </div>
    </template>
  </el-dropdown>
</template>

<script setup lang="ts">
type ItemType = {
  value: number | string | Array<any>;
  label: string;
};

const emits = defineEmits(["command", "visibleChange"]);
const props = defineProps({
  options: {
    type: Array as PropType<ItemType[]>,
    default: [],
  },
  type: String,
  size: {
    type: String as PropType<"large" | "default" | "small">,
    default: "default",
  },
  maxHeight: [String , Number],
  trigger: {
    type: String as PropType<"click" | "hover" | "contextmenu">,
    default: "click",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placement: {
    type: String as PropType<
      | "top"
      | "top-start"
      | "top-end"
      | "bottom"
      | "bottom-start"
      | "bottom-end"
    >,
    default: "bottom-end",
  },
  hideOnClick: Boolean,
  showTimeout: Number,
  hideTimeout: Number,
  role: String,
  tabIndex: Number,
  popperClass: String,
  popperOptions: {
    type: Object,
    default: () => ({
      modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
    }),
  },
  teleported: {
    type: Boolean,
    default: true,
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    default: "",
  },
});

const visible = ref(false);
const searchContent = ref("");
const displayOptions = computed(() => {
  if (props.showSearch && searchContent.value !== "") {
    return props.options.filter(i => {
      const lowerLabel = i.label.toLowerCase();
      const lowerSearch = searchContent.value.toLowerCase();
      return lowerLabel.includes(lowerSearch);
    });
  }
  else {
    return props.options;
  }
});

watch(
  visible,
  (value) => {
    if (!value) {
      searchContent.value = "";
    }
  }
);

const handleCommand = (value: ItemType) => {
  emits("command", value);
};

const handleVisibleChange = (value: boolean) => {
  visible.value = value;
  emits("visibleChange", value);
};
</script>

<style lang="scss" scoped>
.bv-dropdown-popper .search-container {
  padding: 5px 12px;
}
</style>

<styel lang="scss">
.bv-dropdown-popper .el-input__wrapper {
  padding: 0px;
  border: none;
  box-shadow: none;
}

.bv-dropdown-popper .search-container .el-input__inner {
  padding: 0;
  height: 22px;
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #060606;

  &::placeholder {
    color: #8F959E;
  }
}
.bv-dropdown-popper .dropdown-wrapper {
  padding: 8px 0;
}

.bv-dropdown-popper .el-dropdown-menu {
  padding: 0;
}

.bv-dropdown-popper .el-dropdown-menu__item {
  padding: 5px 12px;
  color: #060606;
}

.search-container .el-input__prefix-inner > :first-child{
  margin-right: 4px;
}
.bv-dropdown-popper .el-popper__arrow {
  display: none;
}
</styel>