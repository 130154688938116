<template>
  <dialog-container :visible="isVisible" @close="handleClose" :httpRequest="getAssets" @next="handleNext"
    :activeButton="activeButton" footerText="Replace" :pagination="pagination">
    <template #header>
      <div class="relative">
        <div class="flex items-center justify-center">
          <div @click="handleChangeMode(TYPE.PROJECT)"
            class="cursor-pointer default-text mr-4 pb-2  font-medium text-base"
            :class="{ 'active-text': activeName == TYPE.PROJECT }">
            Project
          </div>
          <div @click="handleChangeMode(TYPE.CLOUD)"
            class="cursor-pointer default-text mr-4 pb-2  font-medium text-base"
            :class="{ 'active-text': activeName == TYPE.CLOUD }">
            Cloud
          </div>
          <div @click="handleChangeMode(TYPE.BRANDKIT)"
            class="cursor-pointer default-text mr-4 pb-2  font-medium text-base"
            :class="{ 'active-text': activeName == TYPE.BRANDKIT }" v-if="isLogo">
            Brand kit
          </div>
        </div>
        <div
          class="absolute top-0 right-0 h-6 w-6 hover:bg-uploadBg flex items-center justify-center rounded-sm cursor-pointer"
          @click="handleClose">
          <svg-icon name="icon_close" :size="20" color="#646A73"></svg-icon>
        </div>
      </div>
    </template>

    <template #filter v-if="activeName == TYPE.CLOUD || activeName == TYPE.PROJECT">
      <Filter @updateType="handleUpdateType" :filterType="filterType" :filterSource="filterSource" :showSource="activeName == TYPE.CLOUD ? true : false" :accept="accept"
        @updateSource="handleUpdateSource"> </Filter>
    </template>

    <div class="grid-image-wrapper" v-if="activeName == TYPE.BRANDKIT">
      <Empty v-if="showEmpty" :showSpaceLink="showSpaceLink" />
      <div v-for="item in brandKitLists" :key="item.bkId">
        <p class="ellipsis-title">{{ item.name }}</p>
        <logo-grid-image :showMask="false" :imageList="item.logos" :selectedLogoUrl="selectedLogoUrl"
          @select="getSelectLogo"  class="mb-10">
        </logo-grid-image>
      </div>
    </div>

    <div class="grid-image-wrapper" v-else>
      <Empty v-if="showEmpty && activeName == TYPE.CLOUD" :showSpaceLink="showSpaceLink" />
      <grid-image v-else :showUpload="activeName == TYPE.PROJECT" :draftId="draftId" :showMask="false" :multiple="multiple" :accept="accept"
        :imageList="materialList" :selectedIdList="selectedMidList" @select="getSelectedMid"
        :handleProgress="handleUploadProgress" :handleSuccess="handleUploadSuccess" :handleError="handleUploadError">
        <template #select-icon>
          <div class=" h-full w-full relative">
            <div class="absolute bottom-3 right-3">
              <svg-icon name="icon_select_image" :style="{ width: '16px', height: '16px', color: '#fff' }"></svg-icon>
            </div>
          </div>
        </template>
      </grid-image>
    </div>

    <div class="loading" v-if="loading">
      <svg-icon name="icon_loading" :style="{ width: '24px', height: '24px' }"
        class="animate-spin duration-300 mr-2"></svg-icon>
    </div>
  </dialog-container>
</template>

<script setup>
import { ref, computed } from 'vue'
import { GridImage } from "./edit-cloud-grid-image/index.ts"
import { LogoGridImage } from "./logo-grid-image/index";
import dialogContainer from './cloudAssetDialog/dialogContainer.vue'
import Filter from './components/filter.vue'
import { materialCloud, getProjectMaterialList, getBrandKitList } from "@/api/index";
import Empty from './components/empty.vue'

const emit = defineEmits(['close'])

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false,
  },
  isLogo: Boolean,
  httpRequest: Function,
  isAiType: Boolean,
  visible: Boolean,
  draftId: String,
  accept: {
    type: String,
    default: 'image,video'
  }
})

const TYPE = {
  PROJECT: 1,
  CLOUD: 2,
  BRANDKIT: 3
}

const isVisible = ref(false)
const filterType = ref(['image'])
const filterSource = ref(['original'])
const selectedMidList = ref([])
const materialList = ref([])
const showEmpty = ref(false)
const loading = ref(false)
const activeName = ref(1)

const brandKitLists = ref([])
const selectedLogoUrl = ref(null)
const currentSelectedLogoItm = ref(null)

const activeButton = computed(() => {
  return selectedMidList.value.length || currentSelectedLogoItm.value
})
const showSpaceLink = computed(() => {
  return (
    filterSource.value.includes("original") &&
    filterSource.value.length == 1
  );
});

// 获取列表
const getBrandKitLists = async () => {
  loading.value = true;
  try {
    const { data } = await getBrandKitList();
    brandKitLists.value = data;
    loading.value = false;
  } catch {
    loading.value = false;
  }
};

const getSelectLogo = (item) => {
  selectedLogoUrl.value = item.url;
  currentSelectedLogoItm.value = item
  selectedMidList.value.splice(0, 1, item.url);
};

const pagination = reactive({
  size: 40,
  page: 1,
})

const getAssets = async (currentPage) => {
  loading.value = true
  pagination.page = currentPage ? currentPage : pagination.page

  const params = {
    filterTypes: filterType.value,
    page: pagination.page,
    size: pagination.size,
    source: filterSource.value,
    draftId: props.draftId,
  };

  if (activeName.value == TYPE.PROJECT) {
    params.draftId = props.draftId;
  }

  try {
    let res = null
    if (activeName.value == TYPE.PROJECT) {
      delete params.source
      res = await getProjectMaterialList(params);
    } else {
      delete params.draftId
      res = await materialCloud(params);
    }

    const { records, current, total } = res.data;
    const list = formateList(records)

    pagination.page = current;
    pagination.total = total;
    
    materialList.value = [...materialList.value, ...list];
    loading.value = false
    if (!materialList.value.length) {
      showEmpty.value = true
      return
    }
    showEmpty.value = false
  } catch {

  }
};

watch(() => props.visible, (visible) => {
  if (visible) {
    getAssets()
  }
  isVisible.value = visible
}, {
  immediate: true
})

const handleClose = () => {
  emit('close')
  selectedMidList.value = []
  isVisible.value = false
}

const handleUpdateType = (type) => {
  filterType.value = type
}

const handleChangeMode = (value) => {
  activeName.value = value
}

const handleUpdateSource = (source) => {
  filterSource.value = source
}

const formateList = (originList) => {
  const list = originList.map((image) => {
    return {
      ...image,
      state: 0,
      url: image.preview480Url,
      hdUrl: image.preview1080Url,
      "1080url": image.preview1080Url,
    };
  });
  return list
}

watch(() => [filterSource.value, filterType.value], () => {
  pagination.page = 1
  materialList.value = []
  getAssets()
}, {
  deep: true
})

watch(() => activeName.value, (value) => {
  pagination.page = 1
  selectedMidList.value = []
  materialList.value = []
  brandKitLists.value = []

  if (value == TYPE.BRANDKIT) {
    getBrandKitLists()
  } else {
    getAssets()
  }
}, {
  deep: true
})

const getSelectedMid = (item) => {
  if (!props.multiple) {
    selectedMidList.value = [item.mid];
    return
  }

  let ind = selectedMidList.value.findIndex(selectItem => selectItem === item.mid);
  if (ind >= 0) {
    selectedMidList.value.splice(ind, 1);
    return;
  }
  selectedMidList.value.push(item.mid);
};

const handleNext = () => {
  if(activeName.value == TYPE.BRANDKIT) {
    emit("replace", currentSelectedLogoItm.value);
    selectedMidList.value = []
    currentSelectedLogoItm.value = null
    return
  }

  let selectedList = [];
  selectedMidList.value.forEach(mid => {
    const image = materialList.value.find(item => item.mid == mid)
    if (image) {
      selectedList.push(image);
    }
  })

  emit("confirm", selectedList);
  emit("replace", selectedList[0]);
  selectedMidList.value = []
}


// 上传过程, 更新状态
const handleUploadProgress = (data) => {
  const index = materialList.value.findIndex(
    (image) => image.mid === data.mid || image.uid === data.uid,
  );
  if (index >= 0) {
    materialList.value[index] = { ...materialList.value[index], ...data };
    return;
  }
  materialList.value.unshift(data);
};

// 上传成功
const handleUploadSuccess = (data) => {
  const index = materialList.value.findIndex(
    (image) => image.mid === data.mid || image.uid === data.uid,
  );
  if (index >= 0) {
    materialList.value[index] = { ...materialList.value[index], ...data };
    return;
  }
};

//上传失败
const handleUploadError = (data) => {
  const index = materialList.value.findIndex(
    (image) => image.mid === data.mid || image.uid === data.uid,
  );
  if (index >= 0) {
    materialList.value[index] = { ...materialList.value[index], ...data };
    return;
  }
  materialList.value.unshift(data);
};
</script>

<style lang="scss" scoped>
.grid-image-wrapper {
  position: relative;
}

.loading {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-text {
  color: #646A73;
  font-family: Inter-variant;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-bottom: 2px solid #FFF;
}

.active-text {
  color: #060606;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-bottom: 2px solid #060606;
}

.ellipsis-title {
  width: 100%;
  padding-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
</style>

<style lang="scss"></style>