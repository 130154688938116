<template>
  <card-list
    ref="cardListRef"
    :items="items"
    :isFirstLoad="page === 1"
    :isLoading="isLoading"
    :isLoaded="isLoaded"
    @loadMore="handleLoadMore"
  />
  <teleport :to="previewerRoot">
    <previewer
      ref="previewerRef"
      :record="previewData"
      @change="changePreview"
    />
  </teleport>
</template>

<script setup lang="tsx">
import { watch } from 'vue';
import { wrapperCardOperations } from '../utils/helper';
import CardItem from '../components/card-item.vue';
import CardList from '../components/card-list.vue';
import CardUpload from '../components/card-upload.vue';
import CardUploadFail from '../components/card-upload-fail.vue';
import CardProgressItem from '../components/card-progress-item.vue';
import Previewer from '../components/material-previewer/index.vue';
import musicIcon from '@/assets/icons/space/icon_music.svg';
import videoIcon from '@/assets/icons/space/icon_video.svg';
import imageIcon from '@/assets/icons/space/icon_image.svg';
import modalInstance from '@/components/common/custom-modal/instance';
import { IMAGETYPE, VIDEOTYPE, AUDIOTYPE } from '@/utils/type';
import { useFilterStore } from '../utils/store';
import { useTrackStore } from '@/store/modules/track';
import { emitter } from '../utils/instance';
import { useAssistStore } from '@/store/modules/assist';
import { usePermission } from '../composables/usepermission';
const { refreshSpaceTime } = storeToRefs(useAssistStore());

import {
  vref,
  apiCall,
  downloadFile,
  getFileExtension,
  DataUnit,
  createRef,
  useTimedTask,
  useSingleMessage,
  type TimedTask,
} from '@/utils';
import {
  getCloudMaterialList,
  deleteCloudMaterial,
  renameMaterial,
  getMaterialUploadState,
} from '@/api/space';
import type {
  CardItemData,
  CardData,
  CardOperation,
  CardItemVNode,
} from '../utils/type';
import type {
  UploadEvent,
  UploadFailEvent,
  UploadSuccessEvent,
  UploadProgressEvent,
  FileReceivedEvent,
} from '@/components/common/bv-upload/type';
import type { UploadInstance } from 'element-plus';

interface UploadingItem {
  progress: Ref<number>;
  file: UploadEvent;
}

type UsedCardItemData = CardItemData<SpaceResData.MaterialLibDetailVO>;

const { permission } = usePermission();
const { collectData, track } = useTrackStore();
// ANCHOR - 变量声明
let resetCount = 0;
let requestTimedTask: TimedTask;
const size = 30;
const page = ref(1);
const isLoaded = ref(false);
const isLoading = ref(false);
const Message = useSingleMessage();
const filterStore = useFilterStore();
const loadingItems = new Set<string>();
const modalManager = modalInstance.modalManager!;
const uploadingMap = new Map<string, UploadingItem>();
const renameInputRef = ref<null | HTMLInputElement>(null);
const previewData = ref<null | SpaceResData.MaterialLibDetailVO>(null);
const cardListRef = ref(null as unknown as InstanceType<typeof CardList>);
const previewerRef = ref(null as unknown as InstanceType<typeof Previewer>);
const batchDelete = inject<Set<string>>('batchDelete')!;
const refreshSpaceSize = inject('refreshSpaceSize') as () => void;
const uploadVNode = (
  <CardUpload
    onUpload={handleUpload}
    onSuccess={handleUploadSuccess}
    onProgress={handleUploadProgress}
    onFail={handleUploadFail}
    onPreprocessingError={handlePreprocessingError}
    onFileReceived={handleFileReceived}
    accept={[
      { types: IMAGETYPE.split(','), maxSize: 10 * DataUnit.MB },
      {
        types: VIDEOTYPE.split(','),
        maxSize: 200 * DataUnit.MB,
      },
      { types: AUDIOTYPE.split(','), maxSize: 50 * DataUnit.MB },
    ]}
  />
);

const items = reactive<CardData<SpaceResData.MaterialLibDetailVO>[]>([
  { key: '#upload', record: null, vnode: uploadVNode },
]);

const extraOptions = permission.value ? [{
  text: "Copy ID",
  iconName: "icon_duplicate",
  handler: (item) => handleCopy(item.record.mid),
}] : [];

const cardOperations: CardOperation[] =
  wrapperCardOperations<SpaceResData.MaterialLibDetailVO>(
    [
      {
        text: 'Rename',
        iconName: 'icon_rename',
        handler: (item) =>
          modalManager.applyTemplate('rename_card_item', {
            inputRef: renameInputRef,
            value: item.record.name,
            async onRename(name: string) {
              if (name === item.record.name || name === '') return;

              await apiCall(
                renameMaterial,
                item.key,
                name.replace(/&/g, '%26')
              );
              item.record.name = name;
              item.vnode.props!.info.title = `${name}.${getFileExtension(
                item.record.originUrl
              )}`;
              item.vnode.component!.update();
            },
          }),
      },
      {
        text: 'Download',
        iconName: 'icon_download',
        handler: (item) =>
          downloadFile(
            item.record.originUrl,
            `${item.record.name}.${getFileExtension(item.record.originUrl)}`
          ),
      },
      {
        text: 'Delete',
        iconName: 'icon_delete',
        handler: (item, index) =>
          modalManager.applyTemplate('delete_card_item', {
            async onDelete() {
              await apiCall(deleteCloudMaterial, item.key);
              index !== -1 && items.splice(index, 1);
              refreshSpaceSize();
            },
          }),
      },
      ...extraOptions,
    ],
    items
  );

const previewerRoot = document.createElement('div');
document.body.appendChild(previewerRoot);

async function handleCopy(str) {
  try {
    await navigator.clipboard.writeText(str);
    Message.success("Copied to clipboard");
  } catch (e) {
    Message.error(e);
  }
}

function urlToFile(url, imageName) {
  return new Promise((resolve, reject) => {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.setRequestHeader('Accept', 'image/png');
    xhr.responseType = 'blob';
    xhr.onload = () => {
      blob = xhr.response;
      let imgFile = new File([blob], imageName, { type: 'image/png' });
      resolve(imgFile);
    };
    xhr.onerror = (e) => {
      reject(e);
    };
    xhr.send();
  });
}

const exposeUploadFunc = async (url: string) => {
  console.log('url', url);
  const name = 'image-' + new Date().getTime() + '.png';
  const file = await urlToFile(url, name);
  if (file) {
    const uploader = uploadVNode.component!.exposed!
      .uploadRef.value.elUploadRef;
    uploader.clearFiles();
    uploader.handleStart(file);
    uploader.submit();
  }
};

emitter.on('uploadFile', exposeUploadFunc);

// ANCHOR - 事件处理
async function handleLoadMore() {
  const currentResetCount = resetCount;
  isLoading.value = true;

  const { records, total } = await apiCall(getCloudMaterialList, {
    page: page.value,
    size,
    filterTypes: [...filterStore.queryTypes.type],
    source: [...filterStore.queryTypes.source],
  }).finally(() => {
    if (currentResetCount !== resetCount) return;
    isLoading.value = false;
  });

  if (currentResetCount !== resetCount) return;
  if (total <= page.value++ * size) {
    isLoaded.value = true;
  }

  items.push(
    ...records.map((record) => ({
      record,
      key: record.mid,
      vnode: renderCardItem(record),
    }))
  );

  nextTick(() => {
    cardListRef.value.canLoadMore() && nextTick(handleLoadMore);
  });

  requestTimedTask.run();
}

// 上传事件触发顺序：handleUpload > handleUploadProgress > handleFileReceived > handleUploadSuccess/handleUploadFail
function handleUpload(file: UploadEvent) {
  const id = file.uid.toString();
  const progressValue = vref(0);
  const poster = file.type.startsWith('image/')
    ? URL.createObjectURL(new Blob([file]))
    : file.type.startsWith('audio/')
    ? musicIcon
    : videoIcon;

  const item = {
    key: id,
    record: {} as SpaceResData.MaterialLibDetailVO,
    vnode: (
      <CardProgressItem
        value={progressValue}
        tip={'Uploading...'}
        info={{
          id: id,
          title: `untitled.${getFileExtension(file.name)}`,
          duration: 0,
          updateTime: -1,
          poster,
        }}
      />
    ) as CardItemVNode,
  };

  if (uploadingMap.has(id)) {
    const index = items.findIndex((item) => item.key === id);
    items[index] = item;
  } else {
    items.splice(1, 0, item);
  }

  uploadingMap.set(id, {
    progress: progressValue,
    file,
  });

  if (filterStore.queryTypeCount.value !== 0) {
    for (const item of Object.values(filterStore.queryTypes)) {
      item.clear();
    }
  }

  return true;
}

async function handleUploadSuccess(e: UploadSuccessEvent) {
  const id = e.mid;
  uploadingMap.delete(id);
  refreshSpaceSize();

  const record = await apiCall(getMaterialUploadState, id);
  const itemIndex = items.findIndex((item) => item.key === id);
  const vnode = renderCardItem(record) as CardItemVNode;
  const replacedItem = {
    vnode,
    record,
    key: record.mid,
  };

  // vnode.props!.info.poster = (items[itemIndex].vnode as CardItemVNode).props!.info.poster;

  if (itemIndex === -1) {
    items.splice(1, 0, replacedItem);
  } else {
    items.splice(1, 0, Object.assign(items[itemIndex], replacedItem));
    items.splice(itemIndex + 1, 1);
  }

  emitter.emit('uploadSuccessFile', e);
  const trackHelper = () => {
    collectData('boolvideo_upload_media', {
      media_type: e.type,
      access: 'my_space',
      is_batch_upload: e.batch,
    });
    track('boolvideo_upload_media');
  };
  trackHelper();
}

function handleUploadProgress(e: UploadProgressEvent) {
  uploadingMap.get(e.uid.toString())!.progress.value = e.percent / 100;
}

function handleUploadFail(e: UploadFailEvent) {
  const id = e.uid.toString();
  const item = items.find((item) => item.key === id)!;

  const handleClickMask = () => {
    const uploadingFile = uploadingMap.get(id);
    const uploader = uploadVNode.component!.exposed!
      .uploadRef;
    uploader.value.uploadRetry(uploadingFile?.file.uid);
  };

  item.vnode = (
    <CardUploadFail
      tip={'Upload failed, click retry'}
      info={item.vnode.props!.info}
      onClickMask={handleClickMask}
    />
  ) as CardItemVNode;
  emitter.emit('uploadFailFile', e);
}

function handlePreprocessingError(e: any) {
  const index = items.findIndex((item) => item.key === e.mid)!;

  items.splice(index, 1);
  Message.error(e.msg);
}

function handleFileReceived(e: FileReceivedEvent) {
  const uid = e.uid.toString();
  const uploadingItem = uploadingMap.get(uid)!;
  const cardItem = items.find((item) => item.key === uid)!;

  cardItem.key = e.mid;
  cardItem.vnode.props!.info.id = e.mid;

  uploadingMap.delete(uid);
  uploadingMap.set(e.mid, uploadingItem);
}

function renderCardItem(record: SpaceResData.MaterialLibDetailVO) {
  let isPreviewable = record.type === 'image' || record.type === 'video';
  const isAICard = record.tag === 'ai';
  const cardClass = isAICard ? 'ai-card-item' : '';
  const size = record.coverPicRatio.split(':').map(Number);
  const isLoading = record.state > 1;
  const posterStyle = ref(
    isPreviewable && record.state === 0 ? 'width: 100%; cursor: pointer' : ''
  );
  const fallbackIcon =
    record.type === 'video'
      ? videoIcon
      : record.type === 'audio'
      ? musicIcon
      : imageIcon;
  const info = reactive({
    id: record.mid,
    title: `${record.name}.${getFileExtension(record.originUrl)}`,
    poster: record.coverPic,
    duration: record.duration,
    updateTime: record.uploadTime,
    selected: false,
  });

  const handlePosterLoadFail = () => {
    isPreviewable = false;
    posterStyle.value =
      'width: 100%; background-color: #FFF; border-radius: var(--card-border-radius)';
    info.poster = fallbackIcon;

    if (size.length === 2) {
      posterStyle.value = `background-color: #FFF; aspect-ratio: ${size[0]}/${size[1]};`;
      if (size[0] > size[1]) {
        // posterStyle.value += `width: min(100%, ${toFixed(size[0] / size[1] * 250, 3)}px); height: auto`;
        posterStyle.value += `width: 100%; height: auto`;
      }
    }
  };

  const handleClickPoster = () => {
    if (isPreviewable) {
      changePreview(record.mid, 0);
    }
  };

  const usedProps = {
    info,
    handlePosterLoadFail,
    class: cardClass,
    posterStyle: createRef(posterStyle, 'value'),
    ...(isLoading
      ? {}
      : {
          handleClickPoster,
          operations: cardOperations,
        }),
  };

  if (isLoading) {
    const index = items.findIndex((item) => item.key === record.mid)!;
    const item = items[index];

    if (index !== -1 && item.record !== null && !('mid' in item.record)) {
      items.splice(index, 1);
      return item.vnode;
    } else loadingItems.add(record.mid);
  }

  info.poster === '' && handlePosterLoadFail();

  return (
    record.state === 1 ? (
      <CardUploadFail
        info={info}
        class={cardClass}
        tip={isAICard ? 'Process failed' : 'Upload failed'}
        handlePosterLoadFail={handlePosterLoadFail}
      />
    ) : isLoading ? (
      <CardProgressItem
        {...usedProps}
        {...(isAICard
          ? {
              value: 0.25,
              tip: 'Processing...',
              type: 'loop',
            }
          : {
              value: 0.98,
              tip: 'Uploading...',
            })}
      />
    ) : (
      <CardItem {...usedProps} />
    )
  ) as CardItemVNode;
}

requestTimedTask = useTimedTask(async () => {
  const currentResetCount = resetCount;

  for (const id of loadingItems.values()) {
    const record = await apiCall(getMaterialUploadState, id);
    const index = items.findIndex((item) => item.key === id)!;

    if (resetCount !== currentResetCount) return;
    if (record.state > 1) {
      const targetNode = items[index] as UsedCardItemData;
      if (targetNode.record.coverPic !== record.coverPic) {
        targetNode.record.coverPic = record.coverPic;
        targetNode.vnode.props!.info.poster = record.coverPic;
      }

      continue;
    }

    const vnode = renderCardItem(record);
    const item = {
      vnode,
      record,
      key: record.mid,
    };

    items.splice(index, 1);
    items.splice(1, 0, item);
    loadingItems.delete(id);
  }

  if (loadingItems.size === 0) {
    requestTimedTask.stop();
  }
}, 3000);

watch(renameInputRef, (input) => {
  if (input !== null) {
    setTimeout(() => {
      input.focus();
      input.select();
    });
  }
});

async function* previewList() {
  let i = 0;

  while (!isLoaded.value || i < items.length) {
    if (!isLoaded.value && i === items.length) {
      Message.loading('Processing, it will take a while');
      await handleLoadMore();
      Message.close();
    }

    const record = items[i++].record;

    if (record === null) continue;
    if (
      record.state === 0 &&
      (record.type === 'image' || record.type === 'video')
    ) {
      yield record;
    }
  }
}

async function changePreview(id: string, direction: number) {
  const list = previewList();
  const temp: (null | SpaceResData.MaterialLibDetailVO)[] = [null, null, null];

  // 类似滑动窗口
  for await (const item of list) {
    if (item.mid === id) {
      if (direction === 0) {
        const next = await list.next();

        temp[0] = temp[1];
        temp[1] = item;
        temp[2] = next.done ? null : next.value;
      } else if (direction === -1) {
        temp[2] = item;
      } else {
        let next = await list.next();

        temp[0] = item;
        temp[1] = next.done ? null : next.value;

        next = await list.next();
        temp[2] = next.done ? null : next.value;
      }

      break;
    }

    temp[0] = temp[1];
    temp[1] = item;
  }

  previewData.value = toRaw(temp[1]);
  previewerRef.value.open = true;
  previewerRef.value.disablePrev = temp[0] === null;
  previewerRef.value.disableNext = temp[2] === null;
}

batchDelete.delete = ((originalDelete) => {
  let lock = false;

  onBeforeUnmount(() => {
    batchDelete.delete = originalDelete;
  });

  return function (id: string) {
    if (lock) return Set.prototype.delete.call(toRaw(batchDelete), id);

    const index = items.findIndex((item) => item.key === id)!;
    index !== -1 && items.splice(index, 1);
    lock = true;

    const reslut = originalDelete.call(batchDelete, id);
    lock = false;

    return reslut;
  };
})(batchDelete.delete);

watch(filterStore.queryTypes, () => {
  const allItems = [...items];
  const { queryTypes } = filterStore;

  resetCount++;
  page.value = 1;
  isLoaded.value = true;
  isLoading.value = false;
  items.splice(1);
  batchDelete.clear();
  loadingItems.clear();
  nextTick(() => (isLoaded.value = false));

  for (const item of allItems) {
    if (item.record === null) continue;
    if (item.record.state !== 0) {
      if ('mid' in item.record) continue;
      const poster = item.vnode.props!.info.poster;

      if (
        !(
          filterStore.queryTypeCount.value === 0 ||
          (queryTypes.type.has('image') && poster.startsWith('blob:')) ||
          (queryTypes.type.has('video') && poster === videoIcon) ||
          (queryTypes.type.has('audio') && poster === musicIcon)
        )
      ) {
        item.containerClass = 'hidden';
      } else {
        item.containerClass = undefined;
      }

      items.push(item);
    }
  }
});

async function requestList() {
  isLoading.value = true;
  const { records, total } = await apiCall(getCloudMaterialList, {
    page: page.value,
    size,
    filterTypes: [...filterStore.queryTypes.type],
    source: [...filterStore.queryTypes.source],
  }).finally(() => {
    isLoading.value = false;
  });

  items.push(
    ...records.map((record) => ({
      record,
      key: record.mid,
      vnode: renderCardItem(record),
    }))
  );
}

watch(
  () => refreshSpaceTime.value,
  () => {
    // setTimeout(() => {
    //   page.value = 1;
    //   requestList();
    // }, 2000);
  }
);

onMounted(() => {
  filterStore.setAllQueryTypes([
    {
      label: 'Type',
      value: 'type',
      items: [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
        { label: 'Audio', value: 'audio' },
      ],
    },
    {
      label: 'Source',
      value: 'source',
      items: [
        { label: 'Original', value: 'original' },
        { label: 'AI generated', value: 'ai' },
      ],
    },
  ]);
});

onUnmounted(() => {
  resetCount = -1;
  filterStore.reset();
  document.body.removeChild(previewerRoot);
});
</script>

<style scoped>
:deep(.ai-card-item:after) {
  content: 'AI';
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 96px;
  left: 14px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffe39b;
  font-size: 12px;
  z-index: 1;
}
</style>
