<script setup>
import { unrefElement } from "../../composables";
import { clamp } from "../../utils";
import { useCreatorStore } from "../../stores";

const emit = defineEmits(["update:x", "dragging", "dragend"]);
const props = defineProps({
  x: {
    type: Number,
    default: 0,
  },
  maxLeft: {
    type: Number,
    default: null,
  },
});
const { timeline } = useCreatorStore();
const left = ref(null);
const parentWidth = ref(null);
const cursor = ref(null);
const dragStart = ref(false);
const dragging = ref(false);
const beforePosition = reactive({ pageX: 0 });
const style = computed(() => ({
  left: `${left.value - 5}px`,
}));

watch(
  () => props.x,
  (newX) => {
    if (dragging.value) {
      return;
    }
    left.value = newX;
  },
);

onMounted(() => {
  const { parentElement } = unrefElement(cursor);
  parentWidth.value = parentElement.clientWidth;

  left.value = props.x;

  document.addEventListener("mousemove", handleMouseMove);
  window.addEventListener("mouseup", handleMouseUp);
});
onUnmounted(() => {
  document.removeEventListener("mousemove", handleMouseMove);
  window.removeEventListener("mouseup", handleMouseUp);
});

function handleMouseMove(e) {
  if (dragStart.value) {
    drag(e);
  }
}

function handleMouseUp() {
  beforePosition.pageX = 0;

  dragStart.value = false;

  if (dragging.value) {
    dragging.value = false;
    emit("dragend", left.value);
  }
}

function cursorDown(e) {
  dragStart.value = true;

  beforePosition.pageX = e.pageX;
  beforePosition.left = left.value;
}

function drag(e) {
  dragging.value = true;

  const deltaX = snapToGrid(timeline.frameWidth, beforePosition.pageX - e.pageX);
  const newLeft = clamp(beforePosition.left - deltaX, 0, props.maxLeft);

  left.value = newLeft;

  emit("update:x", newLeft);
  emit("dragging", newLeft);
}

function snapToGrid(grid, delta) {
  return Math.round(delta / grid) * grid;
}
</script>

<template>
  <div
    ref="cursor"
    class="timeline-cursor"
    :style="style"
    @mousedown.stop="cursorDown"
  >
    <div class="timeline-cursor-hd"></div>
    <div class="timeline-cursor-bd"></div>
  </div>
</template>
<style scoped>
.timeline-cursor {
  bottom: 0;
  position: absolute;
  top: 0;
  width: 11px;
  cursor: col-resize;
}
.timeline-cursor-hd {
  width: 11px;
  height: 16.5px;
  background-color: #646a73;
  border: 2px solid #646a73;
  border-bottom: none;
  left: 0;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 6;
}
.timeline-cursor-hd:after {
  background-color: #646a73;
  border-bottom: 2px solid #646a73;
  border-right: 2px solid #646a73;
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  transform: rotate(45deg) translate(2.8px, 2px);
  width: 7.78px;
  height: 7.79px;
}
.timeline-cursor-bd {
  background-color: #646a73;
  bottom: 0;
  left: 5px;
  position: absolute;
  top: 20px;
  width: 1px;
}
</style>
