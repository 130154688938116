import type { AnimationItem } from "@boolv/renderer";
import {
  animationItemAPIFactory,
  type AnimationItemAPI,
} from "./animation/AnimationItem";

export * from "./animation/AnimationItem";

export interface AnimationAPI extends AnimationItemAPI {}

function createAnimationApi(anim: AnimationItem): AnimationAPI {
  return Object.assign({}, animationItemAPIFactory(anim));
}

export default {
  createAnimationApi: createAnimationApi,
};
