import type { VNode } from "vue";

const infoMap: Record<string, {
  title: string;
  content: VNode;
}> = {
  ['ecommerce-seller']: {
    title: 'The Challenges Ecommerce Sellers Face',
    content: (
      <>
        <p>- Limited time and resources to spend on video production</p>
        <p>- Lacking professional video editing skills and equipment</p>
        <p>- Difficulty keeping up with constant video content demands across all platforms</p>
        <p>- High costs of outsourcing video creation</p>
      </>
    ),
  },
  ['content-creator']: {
    title: 'The Challenges Content Creators Face',
    content: (
      <>
        <p>- Limited time and resources for professional video production</p>
        <p>- Lacking expertise with complex video editing software</p>
        <p>- Struggling to keep up with insatiable audience demand for fresh video content</p>
        <p>- High costs of outsourcing video editing and animation</p>
      </>
    ),
  },
  ['social-media-marketer']: {
    title: 'The Challenges Social Media Marketers Face',
    content: (
      <>
        <p>- Struggling to produce enough video creative to feed the social content beast</p>
        <p>- Lacking time/skills for professional video editing and production</p>
        <p>- Need for constant iteration to find winning video ads and content</p>
        <p>- High costs involved with outsourcing video creation</p>
      </>
    ),
  },
  ['photographer']: {
    title: 'The Challenges Photographers Face',
    content: (
      <>
        <p>- Limited resources and expertise to create professional-quality video content</p>
        <p>- Difficulty translating the essence and storytelling power of your photographic work into video formats</p>
        <p>- Maintaining a consistent visual style and branding across different media types</p>
        <p>- Time-consuming and complex video editing processes</p>
      </>
    ),
  },
  ['artist']: {
    title: 'The Challenges Social Media Marketers Face',
    content: (
      <>
        <p>- Lack of professional video production resources and skills</p>
        <p>- Difficulty translating the tension and energy of your artwork into dynamic, captivating video content</p>
        <p>- Maintaining a consistent visual style and brand identity across different media types</p>
        <p>- Time-consuming and resource-intensive processes for creating high-quality videos</p>
      </>
    ),
  },
};

export default infoMap;

export const useData = () => {
  const route = useRoute();
  const pageName = (route.name as string).match(/(?:^case-|^feature-)?(.+)/)![1];

  return infoMap[pageName];
};
