<template>
  <div class="empty">
    <svg
      width="107"
      height="85"
      viewBox="0 0 107 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.7746 56.9492H65.4496C65.6684 56.9492 65.8817 56.9256 66.0871 56.8808C66.2925 56.9256 66.5058 56.9492 66.7246 56.9492H88.8246C90.4677 56.9492 91.7996 55.6173 91.7996 53.9742C91.7996 52.3312 90.4677 50.9992 88.8246 50.9992H86.2746C84.6316 50.9992 83.2996 49.6673 83.2996 48.0242C83.2996 46.3812 84.6316 45.0492 86.2746 45.0492H94.3496C95.9927 45.0492 97.3246 43.7173 97.3246 42.0742C97.3246 40.4312 95.9927 39.0992 94.3496 39.0992H84.9996C86.6427 39.0992 87.9746 37.7673 87.9746 36.1242C87.9746 34.4812 86.6427 33.1492 84.9996 33.1492H57.7996C59.4427 33.1492 60.7746 31.8173 60.7746 30.1742C60.7746 28.5312 59.4427 27.1992 57.7996 27.1992H33.5746C31.9316 27.1992 30.5996 28.5312 30.5996 30.1742C30.5996 31.8173 31.9316 33.1492 33.5746 33.1492H16.5746C14.9316 33.1492 13.5996 34.4812 13.5996 36.1242C13.5996 37.7673 14.9316 39.0992 16.5746 39.0992H27.1996C28.8427 39.0992 30.1746 40.4312 30.1746 42.0742C30.1746 43.7173 28.8427 45.0492 27.1996 45.0492H10.1996C8.55656 45.0492 7.22461 46.3812 7.22461 48.0242C7.22461 49.6673 8.55656 50.9992 10.1996 50.9992H26.7746C25.1316 50.9992 23.7996 52.3312 23.7996 53.9742C23.7996 55.6173 25.1316 56.9492 26.7746 56.9492ZM96.0497 56.9495C97.6927 56.9495 99.0247 55.6175 99.0247 53.9745C99.0247 52.3315 97.6927 50.9995 96.0497 50.9995C94.4066 50.9995 93.0747 52.3315 93.0747 53.9745C93.0747 55.6175 94.4066 56.9495 96.0497 56.9495Z"
        fill="#EBEDEF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.0756 47.7305C48.0424 47.9656 48.0252 48.2059 48.0252 48.4502C48.0252 51.2668 50.3085 53.5502 53.1252 53.5502C55.9418 53.5502 58.2252 51.2668 58.2252 48.4502C58.2252 48.2059 58.208 47.9656 58.1748 47.7305H70.5502V59.0752C70.5502 59.7793 69.9794 60.3502 69.2752 60.3502H36.9752C36.271 60.3502 35.7002 59.7793 35.7002 59.0752V47.7305H48.0756Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.6502 47.6008C58.6502 50.6522 56.1766 53.1258 53.1252 53.1258C50.0738 53.1258 47.6002 50.6522 47.6002 47.6008C47.6002 47.5019 47.6028 47.4037 47.6079 47.3061H35.7002L39.7634 35.2923C39.9385 34.7744 40.4244 34.4258 40.9712 34.4258H65.2792C65.826 34.4258 66.3118 34.7744 66.487 35.2923L70.5502 47.3061H58.6425C58.6476 47.4037 58.6502 47.5019 58.6502 47.6008Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.8414 48.0057C57.8414 50.3636 55.7297 52.7 53.1249 52.7C50.5201 52.7 48.4084 50.3636 48.4084 48.0057C48.4084 47.9293 48.4107 47.4284 48.415 47.353H39.5249L42.9935 38.9196C43.143 38.5194 43.5578 38.25 44.0245 38.25H62.2253C62.692 38.25 63.1068 38.5194 63.2563 38.9196L66.7249 47.353H57.8348C57.8391 47.4284 57.8414 47.9293 57.8414 48.0057Z"
        fill="#EBEDEF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.2314 47.3935V58.6508C36.2314 59.0615 36.5644 59.3945 36.9752 59.3945H69.2752C69.686 59.3945 70.0189 59.0615 70.0189 58.6508V47.3935L65.9838 35.4625C65.8816 35.1604 65.5982 34.957 65.2792 34.957H40.9712C40.6522 34.957 40.3688 35.1604 40.2666 35.4625L36.2314 47.3935Z"
        stroke="#BBBFC4"
        stroke-width="0.79475"
      />
      <path
        d="M41.6498 47.1758C43.3229 47.1758 45.1284 47.1758 47.0664 47.1758C47.8638 47.1758 47.8638 47.7362 47.8638 48.0258C47.8638 50.8424 50.1997 53.1258 53.0812 53.1258C55.9626 53.1258 58.2985 50.8424 58.2985 48.0258C58.2985 47.7362 58.2985 47.1758 59.0959 47.1758H69.6998M38.4937 47.1758H39.5248H38.4937Z"
        stroke="#BBBFC4"
        stroke-width="0.79475"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M63.7922 24.7779L59.0749 30.0705M52.7422 22.9492V30.0705V22.9492ZM41.6499 24.7779L46.3672 30.0705L41.6499 24.7779Z"
        stroke="#BBBFC4"
        stroke-width="0.79475"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <p>No result</p>
  </div>
</template>
<style scoped>
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%);
}
.empty p {
  color: #646a73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: -15px;
}
</style>
