<template>
  <input
    :multiple="multiple"
    hidden
    type="file"
    ref="fileInput"
    :accept="fileType.formats"
    @change="handleInputChange"
  />
  <subscribe-dialog
    :visible="subscribeModalVisible"
    @close="subscribeModalVisible = false"
    :showIntroduction="false"
  />
</template>
<script setup>
import { Uppy } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { FileType, useMessage } from '@/utils';
import {
  fileUploadPre,
  deleteMultipartUpload,
  getListParts,
  fileUploadState,
  completeMultipartUpload,
} from '@/api/upload';
import { useModalManager } from '@/components/common/custom-modal/instance';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

const emit = defineEmits([
  'beforeAddFiles',
  'checkFail',
  'success',
  'update:modelValue',
]);
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  accept: {
    type: Array,
  },
  multiple: {
    type: Boolean,
    default: true,
  },
});

const fileType = new FileType(props.accept);
const route = useRoute();
const message = useMessage();
const modalManager = useModalManager();

const fileInput = ref(null);
const uppy = ref(null);
const subscribeModalVisible = ref(false);
const timers = reactive(new Map());

const fileMap = computed(() =>
  props.modelValue.reduce((a, c) => {
    a[c.id || c.mid] = c;
    return a;
  }, {})
);

watch(
  () => props.accept,
  () => {
    fileType.value = new FileType(props.accept);
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  uppy.value = new Uppy({
    autoProceed: true,
  }).use(AwsS3, {
    id: 'AWSPlugin',
    shouldUseMultipart(file) {
      return file.size > 10 * 1024 * 1024;
    },
    async getUploadParameters(file, { signal }) {
      const data = await getSignedURL(file, signal);
      return { url: data.preSignUrl, method: 'PUT' };
    },
    async createMultipartUpload(file, signal) {
      signal?.throwIfAborted();

      const minPartSize = 5 * 1024 * 1024;
      const maxPartCount = 10000;
      const chunkSize = Math.max(Math.ceil(file.size / 10000), minPartSize);
      const partCount = Math.min(
        Math.ceil(file.size / chunkSize),
        maxPartCount
      );
      const data = await getSignedURL(file, signal, {
        partCount,
        multipartUpload: true,
      });
      return data;
    },
    async abortMultipartUpload(file, { key, uploadId, signal }) {
      const response = await deleteMultipartUpload(
        encodeURIComponent(uploadId),
        { key: encodeURIComponent(key) },
        signal
      );
      return response.data;
    },
    signPart(file, { partNumber, signal }) {
      signal?.throwIfAborted();

      for (const presigned of file.presignedUrls) {
        if (partNumber === presigned.partNumber) {
          return presigned;
        }
      }
    },
    async listParts(file, { key, uploadId }, signal) {
      signal?.throwIfAborted();

      const response = await getListParts(
        encodeURIComponent(uploadId),
        {
          key: encodeURIComponent(key),
        },
        signal
      );
      return response.data;
    },
    async completeMultipartUpload(file, { key, uploadId, parts }, signal) {
      signal?.throwIfAborted();

      const newParts = [];

      for (const part of parts) {
        newParts.push({
          PartNumber: part.PartNumber,
          ETag: part.ETag.replace(/^"|"$/g, ''),
        });
      }
      const response = await completeMultipartUpload(
        encodeURIComponent(uploadId),
        encodeURIComponent(key),
        {
          parts: newParts,
        },
        signal
      );
      return response.data;
    },
  });
  uppy.value.on('files-added', async (files) => {
    const newFiles = files.map((file) => ({
      id: file.id,
      state: 2,
      progress: 0,
    }));
    emit('update:modelValue', newFiles.concat(props.modelValue));
  });
  uppy.value.on('upload-progress', (file, { bytesUploaded, bytesTotal }) => {
    const target = fileMap.value[file.id];
    target.progress = 20 + (bytesUploaded / bytesTotal) * 30;
  });
  uppy.value.on('upload-success', async (file) => {
    const target = fileMap.value[file.id];

    try {
      const data = await getUploadState(target);
      Object.assign(target, data);
      emit('success', data);
    } catch (e) {
      const newFiles = [];
      for (const file of props.modelValue) {
        if (file === target) continue;
        newFiles.push(file);
      }
      emit('update:modelValue', newFiles);
      message.error('This file is corrupted. Please check and try again.');
    }
  });
  uppy.value.on('upload-error', (file) => {
    const target = fileMap.value[file.id];
    target.state = 1;
  });
});
onUnmounted(() => {
  for (const timer of timers.values()) {
    clearTimeout(timer);
  }
  timers.clear();

  if (uppy.value) {
    uppy.value.destroy();
  }
  uppy.value = null;
});

async function getSignedURL(file, signal, options = {}) {
  const draftId = route.query.draftId || 0;
  const name = file.name;
  const fileType = file.type;
  const suffix = name.substring(name.lastIndexOf('.'));
  const { success, code, data, msg } = await fileUploadPre(
    {
      suffix,
      draftId,
      fileType,
      partCount: options.partCount,
      multipartUpload: options.multipartUpload,
    },
    signal
  );
  if (!success) {
    throw new Error(msg);
  }
  if (code === 40001) {
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    throw new Error(msg);
  }
  const target = fileMap.value[file.id];

  target.progress = 20;
  target.mid = data.mid;
  file.presignedUrls = data.presignedUrls;

  return data;
}

async function getUploadState(file) {
  return new Promise((resolve, reject) => {
    file.progress = 50;
    let increment = 10;
    const getState = async () => {
      const { success, data } = await fileUploadState(file.mid);

      if (success) {
        if (data.state === 0) {
          timers.delete(file.mid);
          file.progress = 100;
          resolve(data);
        } else if (data.state === 1) {
          timers.delete(file.mid);
          reject();
        } else {
          const timer = setTimeout(getState, 1000);
          file.progress = Math.min(90, file.progress + increment);
          increment = Math.max(1, Math.round(increment / 2));
          timers.set(file.mid, timer);
        }
      } else {
        timers.delete(file.mid);
        reject();
      }
    };
    getState();
  });
}

function handleClickUpload() {
  if (fileInput.value) {
    fileInput.value.click();
  }
}

function handleDropUpload(e) {
  addFiles(e.dataTransfer.files);
}

function handleInputChange(e) {
  addFiles(e.target.files);
  e.target.value = null;
}

function addFiles(files) {
  const newFiles = Array.from(files);
  const validFiles = [];

  for (const file of newFiles) {
    const result = fileType.check(file);

    if (!result.passed) {
      emit('checkFail', file);
      message.error(result.msg);
      continue;
    }
    validFiles.push(file);
  }

  if (validFiles.length <= 0) {
    return;
  }

  emit('beforeAddFiles', newFiles);

  uppy.value.addFiles(
    validFiles.map((file) => ({
      name: file.name,
      type: file.type,
      data: file,
      meta: {
        relativePath: (Math.random() * 2 ** 60).toString(),
      },
    }))
  );
}

function reupload(id) {
  const target = fileMap.value[id];

  target.state = 2;
  target.progress = 0;
  uppy.value.retryUpload(id);
}

defineExpose({ getUploadState, handleClickUpload, handleDropUpload, reupload });
</script>
