<template>
  <Layout />
</template>

<script setup>
import Layout from "./layout/index.vue";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useHead } from "@vueuse/head";

onMounted(() => {
  gtag("event", "golden_deal_page_view");
  const email = localStorage.getItem("user_email");
  // window.fpr("referral", { email: email });
});

const route = useRoute();
useHead({
  title: route.meta.title,
  meta: [
    {
      property: "og:title",
      content: route.meta.title,
    },
    {
      name: "twitter:title",
      content: route.meta.title,
    },
  ],
});
</script>
<style lang="scss"></style>
