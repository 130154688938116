<template>
  <div class="six-part-container" id="questions">
    <div class="six-part">
      <P class="primary-title text-42px">Questions</P>

      <p class="sub-title">
        1.What's the difference between Boolvideo and Boolvideo Lite:
      </p>
      <ul class="question-list">
        <li>Boolvideo and Boolvideo Lite are two different products.</li>
        <li>
          Boolvideo Lite is designed to be an easy, accessible video creation
          tool for anyone, regardless of their editing expertise. It is perfect
          for beginners who want a simplified, user-friendly way to produce
          professional-quality videos quickly. No complex editing is needed and
          supported, as the videos are generated by template matching.
        </li>
        <li>
          Boolvideo is more of a powerful online video tool. It is designed for
          seasoned video editors who want more customization and control over
          their content. It has a new AI Director as a main path, along with a
          much more powerful editor. While it does have a separate path for
          templated videos like Lite, this is not its primary focus.
        </li>
      </ul>

      <p class="title">
        In summary, if you're a beginner looking for a straightforward way to
        start making professional videos, Boolvideo Lite would be your choice.
        However, if you 're seeking deeper creative capabilities and want to
        have full control of your videos, Boolvideo would be the best option.
      </p>

      <p class="sub-title">2.What is '7-day money-back guarantee'?</p>
      <ul class="question-list">
        <li>
          A 7-day money-back guarantee means that you can purchase the product
          and use it for up to 7 days. During this period, if you decide that
          the product is not right for you or does not meet your expectations,
          you can request a full refund. This allows you to try out the product
          with reassurance.
        </li>
      </ul>

      <p class="sub-title">
        3.Can l upgrade or downgrade my subscription plan?
      </p>
      <ul class="question-list">
        <li>
          Yes, you can upgrade or downgrade your subscription plan with our
          help. Contact us at support@boolvector.com.
        </li>
      </ul>

      <p class="sub-title">4.Can I cancel my subscription plan?</p>
      <ul class="question-list">
        <li>
          You can cancel your subscription in 7 days without any reason. After
          7-day trial, you can cancel the annual plan at any time, but you can't
          cancel the Lifetime Deal. If you need to unsubscribe, please contact
          us at support@boolvector.com. We hope you'll fall in love with
          Boolvideo and never want to leave :)
        </li>
      </ul>

      <p class="sub-title">5.How do I request a refund?</p>
      <ul class="question-list">
        <li>
          You can get a refund in 7 days without any reason. Usually, we don't
          process refunds after your 7-day trial. However, if you are not 100%
          happy, contact us at support@boolvector.com. As a startup, we will try
          our best to deliver delightful experiences for our customers.
        </li>
      </ul>

      <p class="sub-title">6.Is this a secure site for purchases?</p>
      <ul class="question-list">
        <li>
          We process all transactions via Stripe, which guarantees your safety
          and security. All of the communication between you and our site are
          encrypted and secured!
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
const embedScript = document.createElement("script");

embedScript.src = "https://embed.famewall.io/frame.js";
document.body.appendChild(embedScript);
</script>

<style lang="scss">
.six-part-container {
  padding: 0 50px;
  display: flex;
  justify-content: center;
  padding-top: 120px;

  .six-part {
    width: 100%;
    max-width: 1024px;
  }

  .primary-title {
    color: #000;
    font-family: Inter-variant;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 15.368px;
  }

  .sub-title {
    color: #060606;
    font-family: Inter-variant;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 211%;
    margin-top: 54px;
  }

  .title {
    color: #3d3d3d;
    font-family: Inter-variant;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 211%;
    margin-top: 54px;
  }

  .question-list {
    padding-left: 16px;

    li {
      color: #3d3d3d;
      font-family: Inter-variant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 211%;
      list-style: disc;
    }
  }
}
</style>
