import { _get, _post } from "@/utils/https";

const urlList = {
  materialCloud: "/material/cloud/page",
};

const { VITE_API_BASE } = import.meta.env;

//素材库
export function materialCloud(params: SpaceReqParams.GetMaterialList) {
  return _post<SpaceResData.GetCloudMaterialList>(
    VITE_API_BASE + urlList.materialCloud,
    params,
  );
}
