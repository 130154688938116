<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import Layout from "./components/layout/layout.vue";
import { useHead } from "@vueuse/head";
import { useStyle } from "@/utils";
import { useModalManager } from "@/components/common/custom-modal/instance";
const route = useRoute();
const modalManager = useModalManager();

useHead({
  title: route.meta.title,
  meta: [
    {
      property: "og:title",
      content: route.meta.title,
    },
    {
      name: "twitter:title",
      content: route.meta.title,
    },
  ],
});

useStyle([[".el-main", { "padding-bottom": "0", "padding-top": "0" }]]);

function handleClick(e: MouseEvent) {
  if (!modalManager.modal.open) return;
  const target = e.target as HTMLElement;

  if (target.closest(".modal-container") === null) {
    modalManager.modal.onClose();
  }
}

onMounted(() => {
  window.addEventListener("click", handleClick, true);
});

onBeforeUnmount(() => {
  window.removeEventListener("click", handleClick, true);
});
</script>

<template>
  <Layout />
</template>

<style lang="scss" scoped>
.hide-scrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
}

:deep(.el-scrollbar__bar) {
  display: none !important;
}

:deep(.el-scrollbar__thumb) {
  display: none !important;
}
</style>
