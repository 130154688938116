import {
  textPreview,
  getFontFamilyByLabel,
} from "@/constants/text";

export const defaultStyle = {
  opacity: 1,
  fill: "#000000",
  stroke: "#000000",
  strokeThickness: 0,
  background: "",
  backgroundOpacity: 1,
  backgroundRadius: 10,
  fontWeight: "normal",
  fontStyle: "normal",
  fontFamily: getFontFamilyByLabel("Inter"),
  leading: 0,
  dropShadow: false,
  dropShadowAlpha: 1,
  dropShadowAngle: Math.PI / 6,
  dropShadowBlur: 0,
  dropShadowColor: "#000000",
  dropShadowDistance: 5,
  strokeThickness: 0.18,
};

export const presetTypes = {  
  default: {
    fill: "#FFFFFF",
    stroke: "#000000",
    leading: -0.45,
    url: textPreview.default,
  },
  experience1: {
    dropShadow: true,
    dropShadowBlur: 10,
    dropShadowColor: "#FFFFFF",
    dropShadowDistance: 0,
    fill: "#FF0000",
    fontFamily: getFontFamilyByLabel("Bangers"),
    stroke: "#FFFFFF",
    strokeThickness: 0.15,
    url: textPreview.experience1,
  },
  newContent: {
    fill: "#00FF40",  
    fontWeight: "bold",
    strokeThickness: 0.21,
    leading: -0.2,
    url: textPreview.newContent,
  },
  shopNow: {
    fill: "#FFFA00",
    fontWeight: "bold",
    fontStyle: "italic",
    fontFamily: getFontFamilyByLabel("Poppins"),
    strokeThickness: 0.34,
    url: textPreview.shopNow,
  },
  experience2: {
    dropShadow: true,
    dropShadowBlur: 10,
    dropShadowColor: "#000000",
    dropShadowDistance: 11,
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Bangers"),
    stroke: "#000000",
    strokeThickness: 0.17,
    url: textPreview.experience2,
  },
  vintage1: {
    fill: "#BDFF89",
    fontFamily: getFontFamilyByLabel("Lobster"),
    stroke: "#000000",
    strokeThickness: 0.2,
    leading: -0.3,
    url: textPreview.vintage1,
  },
  bestLife2: {
    fill: "#FFFFFF",
    stroke: "#000000",
    strokeThickness: 0.2,
    background: "#313CF6",
    backgroundOpacity: 0.7,
    backgroundRadius: 15,
    leading: -0.2,
    fontFamily: getFontFamilyByLabel("Roboto"),
    fontWeight: "bold",
    url: textPreview.bestLife2,
  },
  travelTips1: {
    fill: "#00FFBC",
    fontFamily: getFontFamilyByLabel("BebasNeue"),
    dropShadow: true,
    dropShadowBlur: 10,
    dropShadowColor: "#000000",
    dropShadowDistance: 0,
    stroke: "#000000",
    strokeThickness: 0.09,
    url: textPreview.travelTips1,
  },
  loveIt: {
    fill: "#FFFFFF",
    stroke: "#FF00F1",
    fontWeight: "bold",
    strokeThickness: 0.17,
    leading: -0.3,
    url: textPreview.loveIt,
  },
};

export const animationOptions = [
  {
    name: "No animation",
    animation: null,
  },
  {
    name: "Color up",
    animation: "ColorUp",
  },
  {
    name: "Bounce out",
    animation: "BounceOut",
  },
  {
    name: "Typewriter",
    animation: "Typewriter",
  },
];