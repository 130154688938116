<template>
  <div class="card-wrapper" @click="handleToolCardClick">
    <div class="card-container">
      <div class="profile">
        <div class="thumbnail-container">
          <img :src="thumbnailIcon" alt="" />
          <p class="abbreviation">{{ tool.abbreviation }}</p>
        </div>
        <div class="description-container">
          <p class="title">
            {{ tool.title }} <span class="tip" v-if="tool.disabled">(Feature Upgrading)</span>
          </p>
          <p class="description">
            {{ tool.description }}
          </p>
        </div>
      </div>
      <div class="vote" ref="voteRef">
        <div class="animation-container" ref="animationContainerRef"></div>
        <button v-if="tool.online" class="btn-use-it" :class="{ 'disable-btn': tool.disabled }" @click.stop="handleUseit">
          <SvgIcon name="icon_useit" :size="20" />
          Use it
        </button>
        <button v-else :class="['btn-vote', tool.vote ? 'voted' : '']" @click.stop="handleVote">
          <SvgIcon name="icon_triangle" :size="20" />
          {{ tool.vote ? numberFormat.format(tool.count) : "UPVOTE" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="tsx">
import lottie from "lottie-web";
import { useRouter } from "vue-router";

import thumbnailIcon from "../../assets/icons/thumbnail.png";
import upvoteAnimationData from "../../assets/animations/upvote.json?url";
import { toolVote, getToolVoteNum } from "@/api/aiTools";
import { apiCall } from "@/utils";
import { numberFormat } from "../../utils/instance";
import { authenticator } from "@/utils/authenticate";
import { useModalManager } from "@/components/common/custom-modal/instance";
import type { Tool, InfoModal } from "../../type";

const props = defineProps({
  tool: {
    type: Object as PropType<Tool>,
    required: true,
  },
});

const router = useRouter();
let upvoteAnimation: any = null;
const toolName = `ai_tool_${props.tool.name}`;
const infoModal = inject<InfoModal>("infoModal")!;
const animationContainerRef = ref(null as unknown as HTMLElement);

const handleVote = async () => {
  if (props.tool.vote) {
    upvoteAnimation.stop();
    await toolVote({ toolName, cancel: true });
  } else {
    await toolVote({ toolName, cancel: false });
    props.tool.count = await apiCall(getToolVoteNum, { toolName });
    upvoteAnimation.goToAndPlay(93, true);
  }

  props.tool.vote = !props.tool.vote;
};

const handleUseit = async () => {
  if (props.tool.disabled) {
    return
  }
  router.push({
    path: "/aiTools/upload",
    query: {
      aiToolType: props.tool.name,
    },
  });
};

const handleToolCardClick = () => {
  if (props.tool.disabled) {
    return
  }
  infoModal.index = infoModal.getIndex(props.tool.name);
  infoModal.open = true;
};

function handleAnimationComplete() {
  upvoteAnimation.stop();
}

onMounted(() => {
  if (!props.tool.online) {
    upvoteAnimation = lottie.loadAnimation({
      container: animationContainerRef.value,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: upvoteAnimationData,
    });

    upvoteAnimation.addEventListener("complete", handleAnimationComplete);
    upvoteAnimation.setSpeed(1.2);
  }
});

onBeforeUnmount(() => {
  upvoteAnimation?.destroy();
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  box-sizing: content-box;
  width: 100%;
  margin: -26px -30px;
  padding: 26px 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(134deg,
        rgba(103, 65, 255, 0.08) 15.74%,
        rgba(103, 65, 255, 0) 48.69%);
  }
}

.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile {
  display: flex;
}

.thumbnail-container {
  position: relative;
  width: 64px;
  height: 64px;
  margin-right: 18px;
  flex-basis: fit-content;

  img {
    width: 64px;
    border-radius: 4px;
    aspect-ratio: 1 / 1;
  }
}

.abbreviation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: #ffdb94;
}

.description-container {
  width: 100%;
  max-width: 416px;
  padding-right: 24px;
}

.title {
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  // .tip {
  //   color: red
  // }
}

.description {
  color: #646a73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.vote>button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  padding: 15px 24px;
  border-radius: 27px;
  font-size: 14px;
  transition: all 0.2s;

  &>svg {
    margin-right: 8px;
  }
}

.btn-vote {
  color: #060606;
  border: 1px solid #bbbfc4;

  &:hover,
  &.voted {
    color: #6741ff;
    border-color: #875eff;

    &>svg {
      color: inherit;
    }
  }

  &>svg {
    color: #535558;
    transform: translateY(0.1em);
  }

  &:hover {
    background-color: #f8f5ff;
  }
}

.btn-use-it {
  color: #6741ff;
  border: 1px solid #875eff;

  &:hover {
    background-color: #f8f5ff;
  }
}

.btn-use-it.disable-btn {

  color: #646a73;
  border: 1px solid #646a73;

  &:hover {
    background-color: #fff;
  }
}

.vote {
  position: relative;
}

.animation-container {
  position: absolute;
  width: 350px;
  height: 350px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  pointer-events: none;
}
</style>
