<script setup>
import { useCreatorStore, useDraftStore, useHistoryStore } from "../../stores";
import Attr from "./attr.vue";
import Extra from "./extra.vue";

const emit = defineEmits(["update:volume", "update:fadeIn", "update:fadeOut", "update:speed", "update:end"]);
const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  initialState: {
    type: Object,
    default: {},
  },
  volume: {
    type: Number,
    default: 1,
  },
  fadeIn: {
    type: Number,
    default: 0,
  },
  fadeOut: {
    type: Number,
    default: 0,
  },
  speed: {
    type: Number,
    default: 1,
  },
  end: {
    type: Number,
    default: 0,
  },
});
const { creator, getTrack } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const speed = ref(props.speed);

function submit() {
  commit();
  updateDraft();
}

function resetBasicProperty() {
  const { initialState } = props;
  emit("update:volume", initialState.volume);
  emit("update:fadeIn", initialState.fadeIn);
  emit("update:fadeOut", initialState.fadeOut);
  submit();
}

function resetSpeedProperty() {
  const { node, initialState } = props;
  const duration = node.getDuration();
  const currentSpeed = node.getSpeed();
  const end = node.startFrame + Math.floor(duration * currentSpeed / initialState.speed);

  speed.value = initialState.speed;
  emit("update:speed", initialState.speed);
  emit("update:end", end);
  submit();
  nextTick(() => creator.value.annotate());
}

function updateVolume(value, shouldSubmit) {
  emit("update:volume", value);
  shouldSubmit && submit();
}

function updateFadeIn(value, shouldSubmit) {
  emit("update:fadeIn", value);
  shouldSubmit && submit();
}

function updateFadeOut(value, shouldSubmit) {
  emit("update:fadeOut", value);
  shouldSubmit && submit();
}

function updateSpeed(value) {
  const node = props.node;
  const duration = node.getDuration();
  const speed = node.getSpeed();
  const end = node.startFrame + Math.round(duration * speed / value);
  const rawNode = toRaw(node);
  const oldTrack = rawNode.getTrack();
  const zIndex = rawNode.getZIndex();

  rawNode.setEnd(end);
  const { track, used } = getTrack(rawNode, zIndex);

  emit("update:speed", value);
  emit("update:end", end);

  if (track === oldTrack) {
    nextTick(() => creator.value.annotate());
  } else {
    track.addChild(rawNode);

    if (!used) {
      track.start(false, false).then(() => creator.value.annotate());
    }
    triggerRef(creator);
  }
  submit();
}
</script>
<template>
  <Attr title="Speech">
    <el-scrollbar>
      <div class="attr-content">
        <div class="form">
          <div class="form-header">
            <span>Basic</span>
            <icon-button
              name="editor_reset"
              :size="14"
              @click="resetBasicProperty"
            />
          </div>
          <div class="form-content">
            <div class="col col-slider">
              <svg-icon
                clickable
                v-show="volume === 0"
                name="editor_mute"
                :size="18"
                @click="updateVolume(1)"
              />
              <svg-icon
                clickable
                v-show="volume > 0"
                name="editor_volume"
                :size="18"
                @click="updateVolume(0)"
              />
              <bv-slider
                :model-value="volume"
                :min="0"
                :max="1"
                :step="0.01"
                :show-tooltip="false"
                @input="updateVolume"
                @change="updateVolume($event, true)"
              />
              <input-number
                align-center
                percent
                :model-value="volume"
                :min="0"
                :max="1"
                :step="0.01"
                @input="updateVolume"
                @change="updateVolume($event, true)"
              />
            </div>
            <div class="col col-slider">
              <svg-icon name="editor_fade_in" :size="18" />
              <bv-slider
                :model-value="fadeIn"
                :min="0"
                :max="300"
                :step="3"
                :show-tooltip="false"
                @input="updateFadeIn"
                @change="updateFadeIn($event, true)"
              />
              <input-number
                duration
                align-center
                :model-value="fadeIn"
                :min="0"
                :max="300"
                :step="3"
                @input="updateFadeIn"
                @change="updateFadeIn($event, true)"
              />
            </div>
            <div class="col col-slider">
              <svg-icon name="editor_fade_out" :size="18" />
              <bv-slider
                :model-value="fadeOut"
                :min="0"
                :max="300"
                :step="3"
                :show-tooltip="false"
                @input="updateFadeOut"
                @change="updateFadeOut($event, true)"
              />
              <input-number
                duration
                align-center
                :model-value="fadeOut"
                :min="0"
                :max="300"
                :step="3"
                @input="updateFadeOut"
                @change="updateFadeOut($event, true)"
              />
            </div>
          </div>
        </div>
        <div class="form">
          <div class="form-header">
            <span>Speed</span>
            <icon-button
              name="editor_reset"
              :size="14"
              @click="resetSpeedProperty"
            />
          </div>
          <div class="form-content">
            <div class="col col-slider">
              <svg-icon name="editor_speed" :size="18" />
              <bv-slider
                v-model="speed"
                :min="0.1"
                :max="4"
                :step="0.1"
                :marks="{ 0.1: '', 1: '', 2: '', 3: '', 4: '' }"
                :show-tooltip="false"
                @change="updateSpeed"
              />
              <input-number
                speed
                align-center
                v-model="speed"
                :min="0.1"
                :max="4"
                :step="0.1"
                @change="updateSpeed"
              />
            </div>
          </div>
        </div>
        <extra :config="node.conf" />
      </div>
    </el-scrollbar>
  </Attr>
</template>
<style scoped></style>
