<template>
  <form-item>
    <template #label>
      <p class="flex items-center">AI Customization
        <el-tooltip placement="bottom" effect="dark" popper-class="effect-tooltip">
          <template #content>
            Auto-add your selected elements <br /> based on video content.
          </template>
          <svg-icon name="icon_describe" :size="18" style="margin-left: 6px" class="cursor-pointer"></svg-icon>
        </el-tooltip>
      </p>
    </template>

    <el-select v-model="effectSelectedText" :popper-append-to-body="false" popper-class="feature-edit-select"
      placeholder="Select" size="large" :suffix-icon="SelectSuffix" :popper-options="{
            modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
          }" @change="handleChangeEffect">
      <el-option v-for="item in effectData" :key="item.value" :label="item.label" :value="item.value">
        <div class="flex justify-between" :class="{ 'active': effectId == item.value }">
          <p class="label">{{ item.label }}</p>
          <p v-if="effectId == item.value"><svg-icon name="icon_option_selected"
              :size="18"></svg-icon>
          </p>
        </div>
      </el-option>
    </el-select>
    <el-checkbox-group v-model="customizeIds" class="checkbox-group" @input="handleChangeCustom">
      <el-checkbox :label="item.label" v-for="item in subEffectData" :key="item.value" />
    </el-checkbox-group>

  </form-item>
</template>

<script setup>
import { ref, watch } from 'vue'
import FormItem from './form-item.vue'
import SelectSuffix from "@/components/common/selectSuffix.vue";

const props = defineProps({
  value: String,
  effectList: Array,
  subEffectList: Array,
})

const emit = defineEmits(['update:value'])

const effectId = ref('0')
const effectSelectedText = ref('Conservative')
const customizeIds = ref([])
const effectData = ref(props.effectList)
const subEffectData = ref(props.subEffectList)

watch(() => props.effectList, (effectList) => {
  effectData.value = effectList
}, {
  immediate: true,
  deep: true
})


watch(() => props.subEffectList, (subEffectList) => {
  subEffectData.value = subEffectList
}, {
  immediate: true,
  deep: true
})

watch(() => effectId.value, (effectId) => {
  emit('update:value', effectId)
}, {
  deep: true
})

watch(() => customizeIds.value, (list) => {
  emit('update:customizeIds', list)
}, {
  deep: true
})


watch(() => [customizeIds.value], async (value) => {
  const length = customizeIds.value.length > 0 ? ` ( ${customizeIds.value.length} ) ` : ''
  const item = effectData.value.find(item => Number(item.value) == effectId.value)
  effectSelectedText.value = item?.label + length
}, {
  immediate: true,
  deep: true
})

const handleChangeEffect = (id) => {
 
  customizeIds.value = []
  effectId.value = id

  effectData.value.forEach(effect => {
    if (effect.value == id) {
      customizeIds.value = effect.children || []
    }
  })
}

const handleChangeCustom = () => {
  effectId.value = '3'
}
</script>

<style lang="scss">
@import './common.scss';
</style>

<style lang="scss" scoped>
.purpose-tip {
  line-height: 22px;
  color: #FFF;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
</style>