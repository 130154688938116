<template>
  <component :is="pageMap[currentPage]" />
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import Payment from "./components/payment/payment.vue";
import Pricing from "./components/pricing/pricing.vue";
import { usePricingStore } from "./stores";
const router = useRouter();
const { getPricingCardData } = usePricingStore();
const pageMap = {
  payment: Payment,
  premium: Pricing,
};
const currentPage = ref("premium");
watchEffect(() => {
  if (router.currentRoute.value.path) {
    currentPage.value = router.currentRoute.value.path.split("/")[1];
  }
});

onBeforeMount(async () => {
  //  获取列表资源
  await getPricingCardData();
});
</script>

<style lang="scss" scoped></style>
