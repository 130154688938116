<template>
  <Topic :key="route.name" />
  <BrandEndorsement />
  <Challenge :key="route.name" />
  <Introduction :key="route.name" />
  <Workflow :key="route.name" />
  <UseCase :key="route.name" />
  <CommentQuote />
  <BottomPart />
</template>

<script setup>
import Topic from './components/Topic/index.vue';
import Challenge from './components/Challenge/index.vue';
import Introduction from './components/Introduction/index.vue';
import BrandEndorsement from './components/BrandEndorsement/index.vue';
import Workflow from './components/Workflow/index.vue';
import UseCase from './components/UseCase/index.vue';
import CommentQuote from './components/CommentQuote/index.vue';
import BottomPart from './components/bottom-part.vue';
import { useViewStore } from "@/store/view";
import { useRoute } from 'vue-router';

const route = useRoute();
const viewStore = useViewStore();

onMounted(() => {
  const prevTheme = viewStore.header.theme;

  viewStore.header.theme = "light";
  onBeforeUnmount(() =>  {
    viewStore.header.theme = prevTheme;
  });
});

</script>
