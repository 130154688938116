<script setup>
import { useCreatorStore } from "../../stores";

const tabs = [
  {
    type: "media",
    title: "Media",
    icon: "editor_media",
  },
  {
    type: "library",
    title: "Library",
    icon: "editor_library",
  },
  {
    type: "audio",
    title: "Audio",
    icon: "editor_audio",
  },
  {
    type: "text",
    title: "Text",
    icon: "editor_text",
  },
  {
    type: "graphic",
    title: "Shape",
    icon: "editor_graphic",
  },
  {
    type: "sticker",
    title: "Sticker",
    icon: "editor_sticker",
  },
  {
    type: 'effect',
    title: 'Effects',
    icon: 'editor_effect',
  },
  {
    type: 'transition',
    title: 'Transitions',
    icon: 'editor_transition',
  },
  {
    type: "filter",
    title: "Filters",
    icon: "editor_filter",
  },
  {
    type: "voiceover",
    title: "Voiceover",
    icon: "editor_voiceover",
  },
  {
    type: "brandKit",
    title: "Brand kit",
    icon: "editor_brand_kit",
  },
];

const { materialTab, showMaterial } = useCreatorStore();

const globalDomain = inject("globalDomain");

const dynamicTabs = computed(() => {
  if (globalDomain === 1) {
    return tabs;
  }
  else if (globalDomain === 2) {
    const banTabs = ["voiceover", "brandKit"];
    return tabs.filter((item) => 
      !banTabs.includes(item.type)
    );
  }
});

function handleClick(type) {
  showMaterial.value = true;
  materialTab.value = type;
}
</script>
<template>
  <div class="material-header">
    <div class="header-content">
      <div
        class="title-container"
        :class="{ active: materialTab === tab.type && showMaterial }"
        v-for="tab in dynamicTabs"
        @click="handleClick(tab.type)"
      >
        <div class="title">
          <svg-icon :name="tab.icon" :size="24" />
          <span class="tab-title">{{ tab.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.material-header {
  display: flex;
  background-color: #ffffff;
  border-right: 1px solid #e8e9ec;
  height: 100%;
  float: left;
  padding: 24px 4px;
  width: 80px;
}
.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden scroll;
  scrollbar-width: none;
  gap: 23px;
}
.header-content .title-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 73px;
  height: 56px;
  border-radius: 4px;
  transition: background-color 300ms;
}
.header-content .title-container.active {
  background-color: #ebedef;
}
.header-content .title-container:hover {
  background-color: #ebedef;
}
.header-content .title {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tab-title {
  margin-top: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
}
</style>
