<template>
  <el-scrollbar>
    <div class="params-edit-container perfect-scrollbar h-full w-full">
      <el-form :model="form" labelPosition="top">
        <Prompt :value="formState.productDesc" @update:value="formState.productDesc = $event" />
        <BrandKit :value="formState.bkId" @update:value="formState.bkId = $event" :brandKitList="brandKitList"
          style="margin-bottom: 350px;" />
      </el-form>
    </div>
  </el-scrollbar>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from 'vue'
import SelectSuffix from "@/components/common/selectSuffix.vue";

import Prompt from '../../../commonComponents/featureEditForm/prompt.vue'
import BrandKit from '../../../commonComponents/featureEditForm/brandkit.vue'

import { useFeatureEdit } from "./store/index.js";
import { storeToRefs } from "pinia";

const featureEditStore = useFeatureEdit();
const { brandKitList } = storeToRefs(featureEditStore)

const props = defineProps({
  productInfo: Object,
})

const formState = reactive({
  productDesc: '',
  bkId: '',
  url: ''
})

watch(() => props.productInfo, (productInfo) => {
  formState.productDesc = productInfo.productDesc
  formState.url = productInfo.url
  formState.bkId = productInfo.bkId
  formState.templateId = productInfo.templateId
}, {
  immediate: true
})

watch(() => formState, () => {
  featureEditStore.updateFormState(formState)
}, {
  immediate: true,
  deep: true
})

onMounted(() => {
  featureEditStore.getBrandKitListFn();
})

</script>

<style lang="scss">
.params-edit-container {
  font-family: Inter-variant;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 40px;

  .el-form-item {
    margin-bottom: 36px;
    margin-right: 34px;
  }

  .el-form--default.el-form--label-top .el-form-item .el-form-item__label {
    color: #060606;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 18px;
  }

  .el-textarea__inner {
    box-shadow: 0 0 0 1px #E5E7EB inset;
    border-radius: 6px;
    color: #060606;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 18px 16px;

    &:hover {
      box-shadow: 0 0 0 1px #be9fff inset !important;
    }

    &:focus {
      box-shadow: 0 0 0 1px #875eff inset !important;
    }


    --sb-track-color: transparent;
    --sb-thumb-color: rgba(0, 0, 0, 0.25);
    --sb-size: 12px;
    &::-webkit-scrollbar {
      width: var(--sb-size);
    }

    &::-webkit-scrollbar-track {
      background: var(--sb-track-color);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: var(--sb-thumb-color)
    }
  }

  .el-select {
    margin: 0px;
    height: 46px;
    width: 100% !important;
  }

  .el-select .el-input__wrapper {
    box-shadow: 0 0 0 1px #E5E7EB inset;
    border-radius: 6px;
  }

  .el-select .el-input--large {
    height: 46px;
  }


  .el-select .el-input--large .el-input__inner {
    color: #060606;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 12px 0px 12px 6px;
  }


  .disabled-select.el-select .el-input--large .el-input__inner {
    color: #8F959E;
  }

  .el-checkbox-group {
    margin-top: 13px;
  }

  .el-checkbox {
    width: 100%;
    color: #646A73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .el-checkbox__inner {
    width: 16px;
    height: 16px;
    text-align: center;
    border-color: #8F959E;
  }

  .el-checkbox__inner::after {
    left: 5px;
    top: 2px;
    height: 6px;
    border-width: 2px;
  }
}

.disabled-select {
  color: #8F959E !important;
}

.feature-edit-select .el-select-dropdown__item {
  padding: 5px 21px;
  line-height: 22px;
  height: 32px;
  font-size: 14px;
  color: #060606;
}

.feature-edit-select .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: rgba(0, 0, 0, 0.08);
}

.feature-edit-select .el-select-dropdown__item.selected {
  font-weight: 400;
}

.feature-edit-select .el-popper__arrow {
  display: none;
}

.feature-edit-select .el-select-dropdown__list {
  padding: 8px 0;
}

.search-box {
  .el-input__wrapper {
    box-shadow: none;
    padding: 0px;
  }

  .el-input__inner {
    font-size: 14px;
    color: rgb(96, 98, 102);
    padding: 8px 12px 8px 0px;
  }
}

.effect-tooltip.el-popper.is-dark {
  background: #000;
  border-radius: 4px;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.effect-tooltip.el-popper.is-dark .el-popper__arrow::before {
  background: #000;
}

.effect-tooltip.el-popper {
  padding: 12px 24px;
}
</style>

<style lang="scss" scoped>
.params-edit-container {

  .voice-time {
    width: 100%;
    height: 76px;
    border-radius: 6px;
    background: #F7F7F8;
    margin-top: 18px;
    padding: 16px 21px;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    .title {
      width: 123px;
      margin-right: 30px;
    }

    .time-num {
      color: #060606
    }

    .time-tip {
      color: #646A73
    }

    .voice-slider {
      flex: 1
    }
  }

  .checkbox-group {
    padding-left: 21px
  }

}

.search-box {
  display: flex;
  align-items: center;

  padding: 0px 15px 5px 18px;

  .icon_search {
    margin-right: 8px;
  }

  .el-input {
    box-shadow: none;
  }
}

.empty-data {
  padding: 48px 0px;
  color: #8F959E;
  text-align: center;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  .link {
    color: #6741FF;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>