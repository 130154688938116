<template>
  <div class="email-container">
    <div class="email-label">Email</div>
    <input
      class="email-input"
      :class="{
        'email-input-focus': isFocus,
        'email-input-error': isError,
      }"
      @focusin="() => (isFocus = true)"
      @focusout="() => (isFocus = false)"
      @blur="handleBlur"
      @input="handleInput"
      v-model="email"
      placeholder="Email"
    />
    <p class="error" v-if="isError">{{ errorMsg }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, defineEmits, defineProps } from "vue";

const emits = defineEmits(["update:email"]);
const props = defineProps({
  email: String,
});

const email = ref("");
const isFocus = ref(false);
const isError = ref(false);
const errorMsg = ref("");

watch(email, (value) => {
  emits("update:email", value);
});
const validate = (email: string) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
};

const handleInput = (e: Event) => {
  isError.value = false;
  errorMsg.value = "";
};

const handleBlur = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (target) {
    const value = target.value;
    if (!value) {
      isError.value = true;
      errorMsg.value = "Your email can not be empty";
    } else {
      if (!validate(value)) {
        isError.value = true;
        errorMsg.value = "Your email is invalid";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.email-container {
  margin-bottom: 12px;
}

.email-label {
  margin-bottom: 4px;
  font-size: 14px;
  transition:
    transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  font-weight: 600;
  line-height: 1.15;
}
.email-input {
  overflow: hidden;
  width: 100%;
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 14px;
  color: rgb(48, 49, 61);
  line-height: 1.15;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition:
    border 0.15s ease,
    box-shadow 0.15s ease,
    color 0.15s ease;

  &:focus {
    border-color: hsla(210, 96%, 45%, 50%);
    box-shadow:
      0px 1px 1px rgba(0, 0, 0, 0.03),
      0px 3px 6px rgba(0, 0, 0, 0.02),
      0 0 0 3px hsla(210, 96%, 45%, 25%),
      0 1px 1px 0 rgba(0, 0, 0, 0.08);
  }

  &::placeholder {
    color: #757680;
  }
}

.email-input-focus {
  border-color: hsla(210, 96%, 45%, 50%);
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 3px hsla(210, 96%, 45%, 25%),
    0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.email-input-error {
  color: #df1b41;
  border-color: #df1b41;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 1px #df1b41;
}

.error {
  margin-top: 4px;
  color: #df1b41;
  font-size: 0.93rem;
}
</style>
