import { Space } from "@/components/common";
import { useModalManager } from "@/components/common/custom-modal/instance";
import SvgIcon from "@/components/common/bv-svgIcon/svgIcon.vue";
import { authenticator } from "@/utils/authenticate";
import { useSingleMessage } from "@/utils";
import type { ResponseType } from "@/utils/https";

type ApiFn<T = any> = (...args: any[]) => Promise<ResponseType<T>>;

const upgradeCode = new Set([20001, 30001, 40001, 50001, 60001]);

export function createMenuItem(icon: string, text: string) {
  return (
    <Space size={12} alignment="center">
      <SvgIcon name={icon} size={18} />
      {text}
    </Space>
  );
}

export function useProcessRequestHandler() {
  const Message = useSingleMessage();
  const modalManager = useModalManager();

  return function <T extends ApiFn, R = T extends ApiFn<infer R> ? R : never>(
    apiFn: T,
    ...args: Parameters<T>
  ) {
    return new Promise<R>(async (res, rej) => {
      const { data, code, msg } = await apiFn(...args);

      if (upgradeCode.has(code)) {
        if (code === 50001) {
          modalManager.applyTemplate("noCredits", {});
        } else {
          modalManager.applyTemplate("upgradeTips", { code, msg });
        }
      } else if (code === 0) {
        await authenticator.refresh();
        res(data);
      } else {
        Message.error("Error: Please refresh the page", { duration: 2000 });
      }

      rej({ code, msg });
    });
  };
}
