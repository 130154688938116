import { inject, ref, h, type VNode, reactive, defineExpose } from "vue";

import { defaultConfigs } from "./partials/defaults";
import { mapNumberToRange } from "./utils";
import { CarouselConfig, CarouselNav } from "./types";

const Pagination = () => {
  const config: CarouselConfig = inject(
    "config",
    reactive({ ...defaultConfigs }),
  );
  const maxSlide = inject("maxSlide", ref(0));
  const minSlide = inject("minSlide", ref(0));
  const slidesCount = inject("slidesCount", ref(0));

  const currentSlide = inject("currentSlide", ref(0));
  const nav: CarouselNav = inject("nav", {});
  const children: Array<VNode> = [];

  const isActive = (page: number): boolean => {
    const number = mapNumberToRange({
      val: currentSlide.value,
      max: maxSlide.value,
      min: 0,
      itemsToShow: config.itemsToShow,
    });

    return number === page;
  };

  defineExpose({
    isActive,
  });

  for (
    let page = minSlide.value;
    page < slidesCount.value / config.itemsToShow;
    page++
  ) {
    const item = h("li", {
      class: {
        "carousel__pagination-item": true,
        "carousel__pagination-item-active": isActive(page),
      },
      key: page,
    });
    children.push(item);
  }

  return h("ol", { class: "carousel__pagination" }, children);
};

export default Pagination;
