<script setup>
import { useToolStore } from "../../stores";

const { scale, zoom, fit } = useToolStore();
const isFocus = ref(false);

const options = [
  {
    label: "Zoom to fit",
    description: "Shift 1",
    handler: fit,
  },
  {
    label: "Zoom in",
    description: "Shift +",
    handler: function() {
      zoom(10);
    },
  },
  {
    label: "Zoom out",
    description: "Shift -",
    handler: function() {
      zoom(-10);
    },
  },
  {
    label: "Zoom to 50%",
    handler: function() {
      zoom(0, 0.5);
    },
  },
  {
    label: "Zoom to 100%",
    description: "Shift 0",
    handler: function() {
      zoom(0, 1);
    },
  },
  {
    label: "Zoom to 200%",
    handler: function() {
      zoom(0, 2);
    },
  },
];

function close() {
  isFocus.value = false;
}
</script>
<template>
  <el-popover trigger="click" placement="bottom-end" popper-class="scale-popper" :width="187" :offset="4" :visible="isFocus" :show-arrow="false">
    <template #reference>
      <div class="select-wrapper" :class="{ 'is-focus': isFocus }" @click="isFocus = !isFocus" v-click-outside="close">
        <div class="select-value">{{ Math.round(scale * 100) }}%</div>
        <div class="suffix">
          <div class="suffix-icon">
            <svg-icon name="icon_select_arrow" :size="18" />
          </div>
        </div>
      </div>
    </template>
    <div class="select-content">
      <div v-for="(option, i) in options" :key="i">
        <div class="item" @click="option.handler">
          <span class="label">{{ option.label }}</span>
          <span class="description" v-if="option.description">{{ option.description }}</span>
        </div>
      </div>
    </div>
  </el-popover>
</template>
<style scoped>
.select-wrapper {
  width: 65px;
  height: 24px;
  display: flex;
  align-items: center;
  border: 0.5px solid #bbbfc4;
  outline: 0.5px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  transition: all 200ms;
  padding: 1px 3px 1px 6px;
  cursor: pointer;
}
.select-wrapper:hover {
  outline-color: #be9fff;
  border-color: #be9fff;
}
.select-wrapper.is-focus {
  border-color: #875eff !important;
  outline-color: #875eff !important;
  outline-offset: 0;
}

.suffix-icon {
  transition: all 200ms;
}

.select-wrapper.is-focus .suffix-icon {
  transform: rotate(180deg);
}

.select-value {
  flex: 1;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  font-variant: tabular-nums;
}
</style>
<style>
.el-popover.scale-popper {
  padding: 8px 0;
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);
}

.el-popover.scale-popper .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}
.el-popover.scale-popper .item:hover {
  background-color: rgba(31, 35, 41, 0.08);
}
.el-popover.scale-popper .label {
  color: #060606;
}
.el-popover.scale-popper .description {
  color: #8F959E;
}
</style>