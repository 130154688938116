import preview_1 from './preview_1.mp4';
import preview_2 from './preview_2.mp4';

export default [
  {
    poster: 'https://cdn.boolv.video/pgc/home_video/Marketer/1_thumbnail.jpg',
    src: preview_1,
  },
  {
    poster: 'https://cdn.boolv.video/pgc/home_video/Marketer/2_thumbnail.jpg',
    src: preview_2,
  },
];
