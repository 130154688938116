<script lang="ts" setup>
import { useSlots, ref, watch } from "vue";
import { icon_media, icon_arrow_right } from "@/assets/icons/workspace/index";

const emit = defineEmits(["click", "onClickTip"]);
const slots = useSlots();
const props = defineProps({
  border: String,
  icon: String,
  description: String,
  title: String,
  cardStyle: Object,
  titleStyle: Object,
  showBtn: {
    type: Boolean,
    default: false,
  },
});

const iconUrl = ref();

watch(
  () => props.icon,
  () => {
    if (props.icon) {
      iconUrl.value = props.icon;
    } else {
      iconUrl.value = icon_media;
    }
  },
  {
    immediate: true,
  },
);

const handleClick = () => {
  emit("click");
};

// 弹窗
const handleClickLearn = () => {
  emit("onClickTip");
};
</script>

<template>
  <div class="custom-card border-borderColor rounded cursor-pointer hover:bg-cardBgHover" :class="{ 'border': border }"
    :style="{ ...cardStyle }">
    <div class="h-full flex items-center" @click="handleClick">
      <!-- left -->
      <div class="card-icon-box">
        <slot name="icon" v-if="slots['icon']" class="card-icon"> </slot>
        <img :src="iconUrl" v-else class="card-icon" />
      </div>
      <!-- right -->
      <div class="flex-1">
        <slot name="title" v-if="slots['title']" class="card-title"></slot>
        <p v-else class="card-title" :style="{ ...titleStyle }">
          {{ title || "Url or image to video" }} <slot name="suffix" v-if="slots['suffix']"></slot>
        </p>
        <slot name="description" v-if="slots['description']" class="card-description">
        </slot>
        <p v-else class="card-description">
          {{
      description ||
      "Transform images and videos into dynamic visual stories"
    }}
        </p>
        <slot name="tip" v-if="slots['tip']" class="text-tipColor text-sm flex">
        </slot>
        <p v-else class="text-tipColor text-sm flex"></p>
        <p class="mr-2 leading-22 mt-2.5" @click.stop="handleClickLearn" v-if="showBtn">
          Learn
        </p>
        <img :src="icon_arrow_right" v-if="showBtn" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-card {
  padding: 25px 20px;
}

.card-icon-box {
  width: 60px;
  height: 60px;
  margin-right: 22px;
  background: #EBEDEF;
  border-radius: 4px;
}

.card-icon {
  width: 100%;
}

.card-title {
  color: #060606;
  font-family: Inter-variant;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 7px;
}

.card-description {
  color: #646A73;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
</style>
