<template>
  <div class="h-full w-full flex items-center justify-center">
    <GenerateLoading
      :current="currentStep"
      :showSteps="copyGenerateSteps"
      :stepsLength="generateSteps.length"
      :stepNum="4"
      :perStepPercent="[50, 80, 100]"
    />

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="handleSubscribeClose"
      :showIntroduction="false"
    />

    <!-- <BvModal
      :modelValue="showNoCredit"
      @update:modelValue="(value) => (showNoCredit = value)"
      @close="handleClose"
      @confirm="handleConfirm"
    /> -->
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, watch, onUnmounted, reactive } from 'vue';
import { useLoading } from '@/components/common/generateLoading/useLoading.js';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useSingleMessage } from '@/utils';
import { sessionGetItem, sessionSetItem } from '@/utils/storage';
import { routeMap } from '@/components/common/generateLoading/data.js';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import BvModal from '@/components/common/noCredit.vue';
import { useRoute, useRouter } from 'vue-router';
import { validImport } from '@/pages/createVideos/utils/import';
import { renderVideoFromScript, videoRenderState } from '@/api/index';
import { useTrackStore } from '@/store/modules/track';

const { collectData, track } = useTrackStore();
const Message = useSingleMessage();
const modalManager = useModalManager();
const router = useRouter();
const route = useRoute();

const {
  currentStep,
  clearTimer,
  generateSteps,
  copyGenerateSteps,
  renderVO,
  errorInfo,
} = useLoading();
const leftPage = ref(false);
const reqId = ref('');
const formState = ref(null);
const subscribeModalVisible = ref(false);
const showNoCredit = ref(false);

const handleClose = () => {
  // 回退
  router.replace({
    path: '/script-to-video',
  });
};

const handleConfirm = () => {
  subscribeModalVisible.value = true;
};

const handleSubscribeClose = () => {
  router.replace({
    path: '/script-to-video',
  });
};

const handleGenerated = async (formState) => {
  // 生成接口
  const { data, noCredit, msg, code, success } = await renderVideoFromScript(
    formState
  );

  if (!data) {
    Message.error('Please try again');
    router.replace({
      path: '/script-to-video',
    });
    return;
  }

  const trackHelper = (reqId) => {
    collectData('boolvideo_text_input', {
      video_type: 'script_to_video',
      ratio: formState.size.replace(':', '_'),
      reqId,
    });
    track('boolvideo_text_input');
  };

  trackHelper(data);
  router.replace({
    path: route.path,
    query: {
      reqId: data,
    },
  });
  reqId.value = data;
};


watch(
  () => renderVO.value,
  (renderVO) => {
    if (renderVO) {
      clearTimer();
      if (leftPage.value) {
        return;
      }
      router.replace({
        path: '/scene-editor',
        query: {
          draftId: renderVO.draftId,
          videoType: 'script_to_video',
        },
      });
    }
  }
);

onMounted(() => {
  reqId.value = route.query.reqId;
  formState.value = sessionGetItem('generateParams');

  if (reqId.value) {
    return;
  }
  
  handleGenerated(formState.value);
});

onBeforeUnmount(() => {
  leftPage.value = true;
});

onUnmounted(() => {
  clearTimer();
});
</script>
