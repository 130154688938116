<template>
  <div class="editor-loading">
    <div class="content">
      <svg-icon name="editor_loading" color="#646A73" :size="24" />
      <span class="loading-text">Editor loading...</span>
    </div>
  </div>
</template>
<style scoped>
.editor-loading {
  z-index: 2023;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.88);
}
.content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content svg {
  animation: rotate 1s linear infinite;
}
.loading-text {
  color: #060606;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}
</style>
