import { _get } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

export async function getBrandKitList() {
  return await _get(`${VITE_API_BASE}/brand_kit/list`);
}

export async function getBrandKitDetail(id: string) {
  return await _get(`${VITE_API_BASE}/brand_kit/${id}`);
}
