import backgroundRemoverCase from './background-remover';
import imageEnhancerCase from './image-enhancer';
import objectEraserCase from './object-eraser';
import videoBackgroundRemoverCase from './video-background-remover';
import videoEnhancerCase from './video-enhancer';

interface CaseType {
  input: string;
  output: string;
  thumbnail: string;
}

export default {
  backgroundRemover: {
    type: 'image',
    onlyPreview: false,
    cases: backgroundRemoverCase,
  },
  objectErase: {
    type: 'image',
    onlyPreview: true,
    cases: objectEraserCase,
  },
  imageEnhancer: {
    type: 'image',
    onlyPreview: false,
    cases: imageEnhancerCase,
  },
  videoEnhancer: {
    type: 'video',
    onlyPreview: false,
    cases: videoEnhancerCase,
  },
  videoBackgroundRemover: {
    type: 'video',
    onlyPreview: true,
    cases: videoBackgroundRemoverCase,
  },
} as Record<string, {
  type: 'image' | 'video',
  onlyPreview: false,
  cases: CaseType[],
} | {
  type: 'image' | 'video',
  onlyPreview: true,
  cases: {
    preview: string;
  }[],
}>;
