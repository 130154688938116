<template>
  <div
    class="h-full w-full relative"
    @mousemove="handleShowActionBar"
    @mouseleave="handleHiddenActionBar"
  >
    <div class="h-full w-full relative video-box z-0" @click="handlePlay">
      <video
        :id="item?.templateId"
        ref="videoRef"
        :src="item?.previewUrl"
        preload="metadata"
        :poster="item?.cover"
        class="w-full h-full cursor-pointer video-player absolute left-0 right-0"
        :controls="false"
        @canplay="handleCanPlay"
        v-if="showRange || item?.isPlaying"
      ></video>
      <img
        :src="item?.cover"
        class="w-full h-full cursor-pointer video-player absolute left-0 right-0"
        v-show="!item?.isPlaying"
      />

      <div
        v-if="isPlay && showRange"
        className="rang-mask absolute bottom-0 left-0  z-100"
        :style="rangeMaskStyle"
      ></div>
      <div
        v-if="isPlay && showRange"
        className="rang-box absolute  items-center bottom-0 pl-4 pr-4 text-white z-100"
        :style="rangeMaskStyle"
        @click="handleStop"
      >
        <div class="flex flex-col h-full" :style="{ paddingTop: '14px' }">
          <bv-slider
            v-model="currentDuration"
            :show-tooltip="false"
            :max="item?.duration"
            :min="0"
            :colors="{
              main: '#fff',
              runway: 'rgba(255, 255, 255, 0.30)',
              track: '#fff',
              stop: '#535558',
              tooltip: '#1F2329',
            }"
            @input="handleChangeFrame"
          />

          <div class="flex justify-between" :style="{ marginTop: '10px' }">
            <div class="flex">
              <div class="cursor-pointer" @click.stop="handlePlay">
                <svg-icon
                  :name="isPlay ? 'icon_edit_pause' : 'icon_edit_play'"
                  :style="{
                    width: '24px',
                    height: '24px',
                    marginLeft: '-6px',
                    marginRight: '14px',
                  }"
                />
              </div>
              <div class="font-normal">
                {{ currentTime }} / 0:{{
                  (item?.duration + "").padStart(2, "0")
                }}
              </div>
            </div>

            <div class="ml-3 cursor-pointer" @click.stop="handleVolume">
              <svg-icon
                :name="isMuted ? 'icon_edit_mute' : 'icon_edit_unmute'"
                :style="{ width: '20px', height: '20px', marginRight: '-4px' }"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="absolute bottom-5 left-5 px-3 py-1 text-white text-xs font-medium rounded-xl z-10"
        style="background-color: rgba(0, 0, 0, 0.6)"
        v-if="!isPlay && isLoaded"
      >
        0:{{ item?.duration }}
      </div>

      <svg-icon
        name="icon_loading"
        :style="{ width: '30px', height: '30px', color: '#BBBFC4' }"
        class="absolute left-1/2 top-1/2 -ml-5 -mt-5 cursor-pointer animate-spin"
        v-if="item?.isPlaying && !isLoaded"
      ></svg-icon>
      <svg-icon
        v-if="showRange && !isPlay"
        name="icon_big_play"
        :style="{ width: '80px', height: '80px' }"
        class="absolute left-1/2 top-1/2 -ml-10 -mt-10 cursor-pointer z-10"
      ></svg-icon>
      <div
        class="absolute cursor-pointer right-4 top-4 z-10 py-1 px-4 rounded-full font-semibold"
        style="
          background: linear-gradient(270deg, #6741ff 10.3%, #a378ff 119.09%);
        "
      >
        <p style="color: #ffffff; font-size: 14px">{{ item?.lever }}</p>
      </div>
    </div>

    <div
      v-if="showRange"
      class="w-full absolute pt-5 -mt-1"
      @mousemove="handleShowActionBar"
      @mouseleave="handleHiddenActionBar"
    >
      <div class="w-full flex justify-center">
        <div v-track:click="'boolvideo_template_click'">
          <primary-button
            :showDefaultIcon="false"
            padding="16px 36px"
            @click="handleToGenerate"
          >
            <p
              class="text-sm font-normal flex items-center leading-22"
              :style="{ width: '117px' }"
            >
              Use this template
            </p>
          </primary-button>
        </div>
      </div>
      <!-- 展示模版id -->
      <div class="w-full flex justify-center mt-1" v-if="showTemplateId">
        <el-button @click="handleCopyId"
          ><span class="text-xs"
            >模版id: {{ item?.templateId }}</span
          ></el-button
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import useClipboard from "vue-clipboard3";
import {
  ref,
  reactive,
  watch,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import { PrimaryButton } from "@/components/common/index";
import { useTrackStore } from "@/store/modules/track";
const emits = defineEmits([
  "onLoaded",
  "onIsPlaying",
  "onplay",
  "onPause",
  "onIsMute",
]);
const { collectData, clearEventData } = useTrackStore();

type Template = {
  aspectRatio: string;
  previewImageIndex: number;
  previewUrl: string;
  templateId: string;
  isPlaying: boolean;
  duration: number;
  isReplay?: boolean;
};

const props = defineProps({
  aspectRatio: String,
  item: Object as PropType<Template>,
  isAllMute: {
    type: Boolean,
    default: false,
  },
  editAssets: {
    type: Array,
    default: [],
  },
  src: {
    type: String,
    default: "",
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  currentVolum: {
    type: Number,
    default: 5,
  },
  isShowWaterPrinter: {
    type: Boolean,
    default: true,
  },
});

const { toClipboard } = useClipboard();
const route = useRoute();
const router = useRouter();
const videoRef: any = ref(null);
const timer: any = ref(null);
const isLoaded = ref(false);
const showRange = ref(false);
const isPlay = ref(false);
const isMuted = ref(false);
const currentDuration = ref(0);
const currentTime = ref("0:00");
const showTemplateId = ref(false);
const rangeMaskStyle = reactive({
  width: "300px",
  height: "66px",
});

const handleShowActionBar = () => {
  showRange.value = true;
};

const handleHiddenActionBar = () => {
  showRange.value = false;
};

watch(
  () => props.isAllMute,
  (value) => {
    isMuted.value = value;

    if (!videoRef.value) {
      return;
    }
    // 如果用户点击了静音便全部静音
    if (!value) {
      videoRef.value.muted = false;
    } else {
      videoRef.value.muted = true;
    }
  },
  {
    immediate: true,
  },
);

// 静音控制
const handleVolume = () => {
  isMuted.value = !isMuted.value;
  emits("onIsMute", isMuted.value);
  if (!isMuted.value) {
    videoRef.value.muted = false;
  } else {
    videoRef.value.muted = true;
  }
};

// 播放控制
const handlePlay = () => {
  if (!isPlay.value) {
    emits("onplay", props.item);
    videoRef.value?.play();

    if (!isMuted.value) {
      videoRef.value.muted = false;
    } else {
      videoRef.value.muted = true;
    }
  } else {
    videoRef.value?.pause();
    emits("onPause", props.item);
  }

  isPlay.value = !isPlay.value;
};

// 快进
const handleChangeFrame = (val: number) => {
  videoRef.value.currentDuration = val;
  videoRef.value.currentTime = val;
};

const destroy = () => {
  videoRef.value?.pause();
  emits("onPause", props.item);
};

const handleStop = (event: Event) => {
  event.stopPropagation();
};

watch(
  () => props.item,
  () => {
    if (!props.item?.isPlaying) {
      isPlay.value = false;

      if (!videoRef.value) {
        return;
      }

      videoRef.value?.pause();
    } else {
      isPlay.value = true;
      videoRef.value?.play();
    }

    if (props.item?.isReplay) {
      if (!videoRef.value) {
        return;
      }
      videoRef.value.currentTime = 0;
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

const handleCanPlay = () => {
  setTimeout(() => {
    isLoaded.value = true;
    getVideoCurrentTime();
  }, 1000);
};

// 指定生成
const handleToGenerate = () => {
  console.log(props.item);
  // 判断是否登陆
  const token = localStorage.getItem("user_token");
  if (!token) {
    router.push({
      path: "/login",
    });
    return;
  }

  collectData("boolvideo_export_click", {
    video_type: "template",
    ratio:
      props.aspectRatio == "Vertical 9:16"
        ? "9_16"
        : props.aspectRatio == "1:1 ( Square )"
        ? "1_1"
        : "",
    template_id: props.item?.templateId,
    editor_type: "direct_export",

  });
  collectData("boolvideo_template_click", {
    template_id: props.item?.templateId,
  });

  router.push({
    path: "/template-to-video",
    query: {
      templateId: props.item?.templateId,
      ratio: props.item?.aspectRatio
    },
  });
};

defineExpose({
  isLoaded,
  showRange,
  destroy,
  handleChangeFrame,
});

const getVideoBoxWidth = () => {
  const videoBox = document.getElementsByClassName("video-box")[0];
  const clientWidth = videoBox?.clientWidth;

  if (!clientWidth) {
    return;
  }
  rangeMaskStyle.width = clientWidth + "px";
};

const getVideoCurrentTime = () => {
  videoRef.value?.addEventListener(
    "timeupdate",
    function () {
      if (!videoRef.value) {
        return;
      }
      let value = Math.floor(videoRef.value.currentTime);
      currentDuration.value = value;
      currentTime.value = value < 10 ? `0:0${value}` : `0:${value}`;
    },
    false,
  );
};

const handleCopyId = async () => {
  try {
    await toClipboard(props.item?.templateId); //实现复制
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {

  showTemplateId.value = route.query && (route.query?.showTemplateId as string);
  getVideoBoxWidth();
  getVideoCurrentTime();
  window.addEventListener("resize", getVideoBoxWidth, { passive: true });
  collectData("boolvideo_template_click", {
    template_id: props.item?.templateId,
  });
});

onBeforeUnmount(() => {
  destroy();
});

onUnmounted(() => {
  clearInterval(timer.value);
  timer.value = null;
});
</script>
<style lang="scss" scoped>
.rang-mask {
  background-color: rgba(0, 0, 0, 0.6);
  height: 66px;
}

.rang-box {
  height: 66px;
  font-size: 14px;
}

.video-player {
  border-width: 0.5px;
}
</style>

<style lang="scss" scoped>
.video-box {
  :deep(.el-slider__bar) {
    border-radius: 10px;
    height: 4px;
  }

  :deep(.el-slider__runway) {
    border-radius: 10px;
    height: 4px;
  }

  :deep(.el-slider__button) {
    width: 0px;
    height: 0px;
  }

  :deep(.bv-slider) {
    --el-slider-button-wrapper-size: 6px;
  }
}
</style>
