<template>
  <div>

  </div>
</template>

<script setup>
import { parseNode } from "../../utils/scene";
import { observeNode } from "../../utils/observe";

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
})
const setup = () => {
  const { scene } = props;
  const { videos } = parseNode(scene.nodes);
  if (videos.length > 0) {
    for (const video of videos) {
      observeNode(video);
    }
  }
};

onMounted(setup);
</script>

<style lang="scss" scoped>

</style>