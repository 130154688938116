<template>
      <div class="w-full flex justify-center" v-if="innerItem.type !== 'button'">
      <div class="flex justify-between flex-box">
        <el-tooltip class="box-item" popper-class="btn-container" effect="dark" content="Saved in drafts"
          placement="bottom" v-if="collectDiabled || innerItem.draftId" :visible="isHoverDisableCollection">
          <svg-icon name="icon_disabled_collection" color="#E5E7EB" :style="{ 'width': '39px', height: '39px', 'outline': 'none' }"
            class="cursor-pointer" @mousemove.stop="() => isHoverDisableCollection = true"   @mouseleave.stop="() => isHoverDisableCollection = false" @mouseout.stop="() => isHoverDisableCollection = false"></svg-icon>
        </el-tooltip>

        <div class="" v-else @mousemove.stop="() => isHoverCollection = true"
          @mouseleave.stop="() => isHoverCollection = false" @mouseout.stop="() => isHoverCollection = false">
          <el-tooltip class="box-item" popper-class="btn-container" effect="dark" content="Save to drafts"
            placement="bottom" :visible="isHoverActiveCollection">
            <svg-icon name="icon_hover_collection" color="#6741FF" :style="{ 'width': '39px', height: '38px' ,'outline': 'none'}"
              class="icon-bth cursor-pointer" v-if="isHoverCollection" @click="handleCollection"
               @mouseenter="() => isHoverActiveCollection = true" 
               @mousemove.stop="() => isHoverActiveCollection = true"  
                @mouseleave.stop="() => isHoverActiveCollection = false"
                 @mouseout.stop="() => isHoverActiveCollection = false"></svg-icon>
            <svg-icon name="icon_collection" color="#BBBFC4" :style="{ 'width': '39px', height: '38px' ,'outline': 'none' }"
              class="icon-bth cursor-pointer" v-else></svg-icon>
          </el-tooltip>
        </div>

        <div class="" @mousemove.stop="() => isHoverEdit = true" @mouseleave.stop="() => isHoverEdit = false"
          @mouseout.stop="() => isHoverEdit = false">

          <el-tooltip class="box-item" popper-class="btn-container" effect="dark" content="Edit"
            placement="bottom" :visible="isHoverEdit">
            <svg-icon name="icon_hover_video_edit" color="#6741FF" :style="{ 'width': '39px', height: '38px'  ,'outline': 'none'}"
            class="icon-bth cursor-pointer " @click="handleEdit" v-if="isHoverEdit"></svg-icon>
          <svg-icon name="icon_video_edit" color="#BBBFC4" :style="{ 'width': '39px', height: '38px' ,'outline': 'none' }"
            class="icon-bth cursor-pointer " v-else></svg-icon>
          </el-tooltip>
        </div>

        <div @mousemove.stop="() => isHoverExport = true" @mouseleave.stop="() => isHoverExport = false"
          @mouseout.stop="() => isHoverExport = false">
          <el-tooltip class="box-item" popper-class="btn-container" effect="dark" content="Export"
            placement="bottom" :visible="isHoverExport">
            <svg-icon name="icon_hover_export" color="#6741FF" :style="{ 'width': '39px', height: '38px'  ,'outline': 'none'}"
            class="icon-bth cursor-pointer" @click="handleExport" v-if="isHoverExport"></svg-icon>
          <svg-icon name="icon_export" color="#BBBFC4" :style="{ 'width': '39px', height: '38px'  ,'outline': 'none'}"
            class="icon-bth cursor-pointer" v-else></svg-icon>
          </el-tooltip>
        </div>
      </div>
    </div>
</template>

<script setup lang="ts">
import { type PropType, defineExpose } from 'vue'
import type { templateInfo } from "../type";
import { useSingleMessage } from "@/utils";
import { useCollection } from '../util'
import { useRoute } from "vue-router";
import { useTrackStore } from "@/store/modules/track";

const emit = defineEmits(['onExport', 'onEdit'])
const props = defineProps({
  item: {
    type: Object as PropType<templateInfo>,
    default: () => {
      return {};
    },
  },
  isLoaded: Boolean
})

const { collectData, track } = useTrackStore();
const route = useRoute();
const Message = useSingleMessage();
const isHoverDisableCollection = ref(false)
const isHoverActiveCollection = ref(false)
const isHoverCollection = ref(false)
const isHoverEdit = ref(false)
const isHoverExport = ref(false)
const innerItem = ref(props.item)
const collectDiabled = ref(false);

watch(() => props.item, (value) => {
  innerItem.value = value
}, {
  immediate: true
})


const handleCollection = () => {
  const trackHelper = () => {
    const { videoType } = route.query;
    collectData("boolvideo_save_to_draft", {
      video_type: videoType,
      rid: props.item.rid,
      reqId: props.item.reqId,
      draftId: props.item.draftId,
    });
    track("boolvideo_save_to_draft");
  };
  trackHelper();
  collectDiabled.value = true;
  Message.success("Saved to drafts successfully", { duration: 2000 });
  useCollection(innerItem.value.rid)
}

const cancelHoverStatus = () => {
  isHoverCollection.value = false;
  isHoverEdit.value = false
  isHoverExport.value = false
  isHoverDisableCollection.value = false
};

const handleEdit = async () => {
  emit('onEdit')
};

// 导出
const handleExport = () => {
  emit('onExport')
};


defineExpose({
  cancelHoverStatus
})

</script>

<style lang="scss" scoped>
.flex-box {
  width: 162px;
}
</style>