<template>
  <div
    class="enhancer-container"
    :style="{ paddingBottom: mode == 'editor' ? '0px' : '39px' }"
  >
    <div class="enhancer-box">
      <h1 class="title" :style="titleStyle" v-if="!mode">Result Preview</h1>
      <div
        class="video-container"
        :style="{ height: mode == 'editor' ? '80%' : '76%' }"
      >
        <video-item :src="originUrl" />
      </div>
      <div
        class="button-container"
        :style="{ marginTop: mode == 'editor' ? '24px' : '48px' }"
      >
        <primary-button
          v-if="mode == 'editor'"
          :loading="loading"
          :showDefaultIcon="false"
          padding="20px 65px"
          @click="handleEditorProcess"
        >
          <template #preIcon>
            <svg-icon
              name="icon_magic"
              :style="{ width: '20.7px', height: '20.7px', color: '#fff' }"
            />
          </template>
          <p
            class="text-base font-normal flex items-center leading-22"
            :style="{ marginLeft: '10px' }"
          >
            Enhance
          </p>
        </primary-button>
        <primary-button
          v-else
          :loading="loading"
          :showDefaultIcon="false"
          padding="20px 36px"
          @click="handleProcess"
        >
          <template #preIcon>
            <svg-icon
              name="icon_magic"
              :style="{ width: '20.7px', height: '20.7px', color: '#fff' }"
            />
          </template>
          <p
            class="text-base font-normal flex items-center leading-22"
            :style="{ marginLeft: '10px' }"
          >
            Enhance video
          </p>
        </primary-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import VideoItem from "../bgRemove/videoItem.vue";
import { PrimaryButton } from "@/components/common/index";
import videosExample from "@/assets/videos/ai-generated.mp4";
import cases from '../../assets/cases/video-enhancer';
import { useSingleMessage } from "@/utils";
import { useProcessRequestHandler } from "../helper";
import {
  getVideoBgRemoveState,
  getVideoEnhancePreview,
  getVideoEnhanceResult,
  fileUploadPre,
  uploadFile,
  fileUploadState
} from "@/api";

const emit = defineEmits(["onAsyncProcess"]);
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  mode: String,
  originUrl: String,
});

const Message = useSingleMessage();
const router = useRouter();
const route = useRoute();
const handleProcessRequest = useProcessRequestHandler();
const titleStyle = computed(() => {
  return {
    opacity: processComplete.value ? 0 : 0,
  };
});
const loading = ref(false);
const originUrl = ref(videosExample);
const resultUrl = ref("");
const currentUrl = computed(() =>
  originUrl.value && !processComplete.value ? originUrl.value : resultUrl.value,
);
const state = ref(0);
const timer: Ref<any> = ref(null);
const taskId = ref("");
const draftId: Ref<string | 0> = ref(0);
const processComplete = ref(true);
const usedCase = cases.find(item => item.input === route.query.url) ?? null;

const queryProcessState = async () => {
  const { data } = await getVideoBgRemoveState({
    taskId: taskId.value,
  });
  state.value = data.taskState;
  if (state.value === 2) {
    timer.value = setTimeout(async () => {
      await queryProcessState();
    }, 2000);
    return;
  } else if (state.value === 1) {
    Message.close();
    Message.error("failed, try again");
  } else if (state.value === 0) {
    if (data?.videoUrl) {
      processComplete.value = true;
      Message.close();
    }
  }
  loading.value = false;
};

const getEnhanceResult = async () => {
  const result = await handleProcessRequest(getVideoEnhanceResult, {
    url: originUrl.value,
    draftId: draftId.value || 0,
  }).catch(() => {});

  if (result === undefined) return { code: -1 };
  taskId.value = result.taskId;

  return { code: 0, data: result };
};

const getEnhancePreview = async () => {
  loading.value = true;
  Message.loading("Processing, it will take a while", { duration: 0 });
  const { data, success } = await getVideoEnhancePreview({
    url: originUrl.value,
    draftId: draftId.value || 0,
  });
  if (success) {
    state.value = data.taskState;
    taskId.value = data.taskId;
    queryProcessState();
  } else {
    state.value = 2;
    Message.error("failed, try again");
  }
};

const handleToSpace = () => {
  const href = router.resolve({
    path: "/space",
    query: {
      tab: "media",
    },
  });
  // 点击事件
  window.open(href.href, "_blank");
};

const handleProcess = async () => {
  let code = -1;

  if (processComplete.value) {
    loading.value = true;

    if (usedCase === null) {
      code = (await getEnhanceResult()).code;
    } else {
      await uploadMaterials(usedCase.output);
      code = 0;
    }

    if (code === 0) {
      handleToSpace();
    }
    loading.value = true;
    return;
  }
  // 第一次处理, 获取5s预览视频
  // getEnhancePreview();
};

const handleEditorProcess = async () => {
  loading.value = true;
  const data = await getEnhanceResult();
  if (data.code === 0) {
    emit("onAsyncProcess");
  }
  loading.value = false;
};

const uploadMaterials = async (url: string) => {
  const blob = await fetch(url).then(res => res.blob());
  const file = new File([blob], 'untitled');
  const uploadParams = {
    suffix: '.mp4',
    size: file.size,
    type: 'video',
    draftId: 0,
    toolName: 'VIDEO_BG_REMOVE',
  };

  const preResult = await fileUploadPre(uploadParams);
  if (preResult.code !== 0) {
    throw new Error("error, save failed");
  }
  const { preSignUrl, mid } = preResult.data;
  const uploadResult = await uploadFile(preSignUrl, file);
  if (uploadResult.code !== 0) {
    throw new Error("error, save failed");
  }

  await waitPreProcessing(mid);
};

const waitPreProcessing = async (mid: string) => {
  const {data: {init}} = await fileUploadState(mid);

  if (!init) {
    await new Promise(res => {
      setTimeout(async () => res(await waitPreProcessing(mid)), 2000);
    });
  }
};

onMounted(() => {
  if (props.mode == "editor") {
    originUrl.value = props.originUrl;
    draftId.value = route.query?.draftId as string;
  } else {
    originUrl.value = route.query?.url;
    draftId.value = 0;
    // getEnhancePreview();
  }
});

onBeforeUnmount(() => {
  if (timer.value) {
    clearTimeout(timer.value);
    timer.value = null;
  }
});
</script>

<style lang="scss" scoped>
.enhancer-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 39px;
}

.enhancer-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  color: #000;
  font-family: Inter-variant;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 42px 0 28px 0;
}

.video-container {
  aspect-ratio: 16/9;
}
</style>
