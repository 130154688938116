import type { Component, InjectionKey, Ref } from "vue";
export interface CardItemInfo {
  name: string;
  title: string;
  hasDiscount: boolean;
  yearlyPrice: string;
  monthlyDiscount: string;
  monthlyPrice: string;
  packageCode: number;
  planDescription: string;
  buttonText: string;
  priorityDescription: string;
  priorityList: string[];
  paymentDescription: string;
  cycleType: string;
  couponCode?: string;
}

export interface ModalArguments {
  type: Partial<ModalName>;
  card?: CardItemInfo | null;
  visible: boolean;
  downgradeInfo?: ResData.BoolvideoUpdateSubPopMsg;
  giftInfo?: ResData.QueryCancelDetails;
  subscribingInfo?: object;
}
/* prettier-ignore */
export type ModalName =
  'subscribeSuccess' |
  'upgradeSuccess' | 
  'upgrade' |
  'downgrade' |
  'downgradeSuccess' |
  'subscribing' |
  'cancelSuccess' |
	'recoverSuccess' |
  'contactUs' |
	'giftCard'|
	'cancelSuccess'|
	'yearToMonth' |
  'ltdTips' |
  'ltdRefundSuccess';

export interface ModalOperation {
  name: string;
  component: Component | null;
  handler: (info?: ModalInfo) => void;
}

export interface ModalInfo {
  card?: CardItemInfo;
  downgradeInfo?: ResData.BoolvideoUpdateSubPopMsg;
  giftInfo?: ResData.QueryCancelDetails;
}
export interface SubscriptionState {
  autoRenew?: boolean;
  cancelSubAtPeriodEndBoolean?: boolean;
  currentPeriodPaymentStatus?: string;
  currentPlanName?: string;
  currentSubCreateTime?: number;
  supportRefundBoolean?: boolean;
  nextRenewalDateTime?: number;
  trialingBoolean?: boolean;
  packageCode: number;
  subOrigin?: string;
  userId?: number;
}
/* prettier-ignore */
export type UserOperationName = 
	'subscribe' | 
	'subscribeSuccess' |
	'upgradeSuccess' |
	'subscribing' |
	'upgrade' |
	'downgrade' |
	'downgradeSuccess' |
	'cancel' |
	'cancelSuccess' |
	'recover' |
	'contactUs' |
	'yearToMonthTips' | 
	'recoverSuccess';

export type UserOperationMap = Record<
  UserOperationName,
  (card?: CardItemInfo, info?: ResData.BoolvideoUpdateSubPopMsg) => void
>;

export type ModalMap = Record<Partial<ModalName>, ModalOperation>;

// 不是type 是QuestionnaireVisible的provide函数的key
export const QuestionnaireVisible = Symbol() as InjectionKey<Ref<boolean>>;
