<template>
  <div class="landing-page-content" :style="`--base-size: ${baseSize}px`">
    <PageHeader
      @playPageHeaderVideo="handlePlayPageHeaderVideo"
      ref="pageHeaderRef"
      @timeDone="handleTimeDone"
    />
    <Overview />
    <Pricing :timeDone="timeDone" />
    <Roadmap ref="roadmapRef" @playTestimonialVideo="handleTestimonialVideo" />
    <Reviews />
    <Questions />
    <socials />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import PageHeader from "../components/pageHeader.vue";
import Overview from "../components/overview.vue";
import Pricing from "../components/pricing.vue";
import Roadmap from "../components/roadmap.vue";
import Reviews from "../components/reviews.vue";
import Questions from "../components/questions.vue";
import Socials from "../components/socials.vue";
import { useWindowSize } from "@vueuse/core";

const { width: windowWidth } = useWindowSize();
const roadmapRef = ref(null);
const pageHeaderRef = ref(null);
const timeDone = ref(false);

const baseSize = computed(() => {
  let baseSize = Math.max(10, ((windowWidth.value / 1440) * 100) | 0);
  return baseSize > 100 ? 100 : baseSize;
});

const handleTimeDone = (value) => {
  timeDone.value = value;
};

const handlePlayPageHeaderVideo = () => {
  roadmapRef.value.pause();
};

const handleTestimonialVideo = () => {
  pageHeaderRef.value.pause();
};
</script>

<style lang="scss">
.landing-page-content {
  padding-top: 64px;

  .part-one {
    background: #0b0b0b;
    padding-top: 34px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      color: #ffe600;
      text-align: center;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.96);
      font-style: normal;
      font-weight: 600;
      line-height: 174%;
      margin-bottom: 26px;
      margin-top: 26px;
    }

    .sub-title {
      color: #fff;
      text-align: center;
      font-family: Inter-variant;
      font-style: normal;
      font-size: calc(var(--base-size) * 0.68);
      font-weight: 600;
      margin-bottom: 78px;
    }

    .video {
      max-width: 1090px;
      max-height: 561px;
      flex-shrink: 0;
      margin-bottom: 120px;
      aspect-ratio: 1090/561;
    }

    .third-level-title {
      color: #fff;
      text-align: center;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.54);
      font-style: normal;
      font-weight: 600;
      line-height: 174%;
    }

    .logo-title {
      text-align: center;
      font-family: Inter-variant;
      font-style: normal;
      font-weight: 600;
      background: linear-gradient(93deg, #a245ff 4.24%, #2f5dff 96.12%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .image-container {
      margin-top: 100px;
      margin-bottom: 161px;
      width: 100%;
      max-width: 1308px;

      display: grid;
      grid-row-gap: 36px;
      grid-column-gap: 36px;

      .image-item {
        width: 100%;
        border-radius: 11.507px;
        aspect-ratio: 300/533;
      }
    }
  }
}
</style>
