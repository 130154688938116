import input_1 from './input_1.webp';
import input_2 from './input_2.webp';
import input_3 from './input_3.webp';
import output_1 from './output_1.png';
import output_2 from './output_2.png';
import output_3 from './output_3.png';
import thumbnail_1 from './thumbnail_1.webp';
import thumbnail_2 from './thumbnail_2.webp';
import thumbnail_3 from './thumbnail_3.webp';

export default [
  {
    input: input_1,
    output: output_1,
    thumbnail: thumbnail_1,
  },
  {
    input: input_2,
    output: output_2,
    thumbnail: thumbnail_2,
  },
  {
    input: input_3,
    output: output_3,
    thumbnail: thumbnail_3,
  },
];
