<template>
  <ol class="grid-container" :style="{ ...gridStyle }">
    <gridItem
      v-for="(item, index) in innerImageList"
      :mode="mode"
      :showMask="showMask"
      :multiple="multiple"
      :draftId="draftId"
      :key="item.mid"
      :item="item"
      :isActive="currentIndex === index"
      :selectedIdList="selectedIdList"
      :removeType="removeType"
      :handleProgress="handleProgress"
      :handleError="handleError"
      :handleSuccess="handleSuccess"
      @onProgress="handleUploadProgress"
      @onSuccess="handleUploadSuccess"
      @onError="handleUploadError"
      @removeImage="handleRemoveImage"
      @click="handleSelect(item, index)"
      @uploadRetry="handleUploadRetry"
      :accept="accept"
    >
      <template #upload>
        <slot :item="item" name="upload"> </slot>
      </template>

      <template #delete-icon>
        <slot name="delete-icon"></slot>
      </template>

      <template #select-icon>
        <slot name="select-icon"></slot>
      </template>
    </gridItem>
  </ol>
</template>

<script lang="ts" setup>
import { ref, watch, useSlots, type PropType, onMounted } from "vue";
import gridItem from "./assetGridItem.vue";
import type { ImageType } from "./type";
type SelectedType = string | number;

const emits = defineEmits([
  "uploadComplete",
  "removeImage",
  "select",
  "onProgress",
  "onSuccess",
  "onError",
]);
const props = defineProps({
  mode: String,
  selectedIdList: {
    type: Array as PropType<SelectedType[]>,
    default: () => {
      return [];
    },
  },
  draftId: String,
  removeType: String,
  showUpload: {
    type: Boolean,
    default: true,
  },
  imageList: {
    type: Array as PropType<ImageType[]>,
    default: () => {
      return [];
    },
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  grid: Object,
  showMask: {
    type: Boolean,
    default: true,
  },
  handleProgress: {
    type: Function,
    default: () => {},
  },
  handleError: {
    type: Function,
    default: () => {},
  },
  // 文件上传成功时的回调函数
  handleSuccess: {
    type: Function,
    default: () => {},
  },
  accept:  {
    type: String,
    default: 'image,video'
  },
});
const currentIndex = ref(-1);
const innerImageList = ref<ImageType[]>([]);
const gridStyle = ref({
  display: "grid",
  gridGap: "11px",
  gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
  listStyle: "none",
});

const initData = () => {
  if (props.showUpload) {
    innerImageList.value = [
      {
        id: "1",
        upload: true,
        url: "",
      },
    ];
  } else {
    innerImageList.value = [];
  }
};

watch(
  () => props.showUpload,
  () => {
    currentIndex.value = -1;
  },
);

watch(
  () => props.imageList,
  (value) => {
    initData();
    innerImageList.value = [...innerImageList.value, ...value];
  },
  {
    deep: true,
    immediate: true,
  },
);

watch(
  () => props.grid,
  () => {
    gridStyle.value = {
      ...gridStyle.value,
      ...props.grid,
    };
  },
  {
    deep: true,
  },
);

const handleUploadProgress = (data: ImageType) => {
  emits("onProgress", data);
};

const handleUploadSuccess = (data: ImageType) => {
  emits("onSuccess", data);
};

const handleUploadError = (data: ImageType) => {
  emits("onError", data);
};

const handleRemoveImage = (mid: string) => {
  emits("removeImage", mid);
};

const handleSelect = (item: ImageType, index: number) => {
  if (item.upload || !item.url) {
    return;
  }
  currentIndex.value = index;
  emits("select", item);
};

const handleUploadRetry = (item: ImageType) => {
  emits("uploadRetry", item);
};
</script>
<style lang="scss" scoped></style>
