<script setup>
import { getVideoList } from "@/api/library";
import { useNetwork } from "@/composables";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import { secondsToHms } from "../../utils";
import Header from "./libheader.vue";
import Empty from "./empty.vue";
import Loading from "./loading.vue";
import Previewer from "../../../space/components/material-previewer/index.vue";

const { getXY, secondToFrame, addVideoNode } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();
const { assertNetworkError } = useNetwork();

const scrollbar = ref(null);
const list = ref(null);
const files = ref([]);
const file = ref({});
const hoverFile = ref(null);
const previewer = ref(null);
const page = ref(1);
const total = ref(0);
const keyword = ref("");
const ratio = ref("");
const loading = ref(false);

watch(
  page,
  (newPage) => {
    if (newPage === 1) {
      scrollbar.value?.setScrollTop(0);
    }
    getVideos(newPage);
  },
  { immediate: true },
);
watch(
  files,
  (newFiles) => {
    if (list.value) {
      const width = 110;
      const gap = 10;
      const hs = [0, 0];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const element = list.value.children[i];
        const wrapper = element.querySelector(".image-wrapper");
        const height = file.height * (width / file.width);
        const index = hs.indexOf(Math.min(...hs));
        const left = (width + gap) * index;
        const top = hs[index];

        element.style.left = `${left}px`;
        element.style.top = `${top}px`;
        element.style.height = wrapper.style.height = `${height}px`;

        hs[index] += height + gap;
      }
      list.value.style.height = `${Math.max(...hs)}px`;
    }
  },
  { flush: "post" },
);

async function getVideos(page) {
  loading.value = true;
  const data = { page, size: 20 };

  if (keyword.value) {
    data.keyword = keyword.value;
  }
  if (ratio.value) {
    data.shape = ratio.value;
  }
  const response = await getVideoList(data);

  if (response.success) {
    const { records } = response.data;

    total.value = response.data.total;
    files.value = files.value.concat(records);
  }
  loading.value = false;
}

async function search() {
  files.value = [];

  if (page.value === 1) {
    await getVideos(1);
  } else {
    page.value = 1;
  }
}

function loadMore() {
  if (0 < files.value.length && files.value.length < total.value) {
    page.value++;
  }
}

async function addVideo(file) {
  assertNetworkError();

  if (file.previewUrl) {
    const {
      name,
      duration,
      width480,
      width1080,
      coverPic,
      preview1080Url: hdUrl,
      previewUrl: src,
    } = file;
    const newDuration = secondToFrame(duration);
    const materialMeta = { width480, width1080, url1080: hdUrl };

    await addVideoNode({ 
      name, 
      hdUrl, 
      src, 
      coverPic,
      materialMeta, 
      duration: newDuration,
    });
    commit();
    updateDraft();
  }
}

function handleDrag(e, dragTarget, file) {
  const dragElement = dragTarget.cloneNode(true);
  const {
    name,
    duration,
    coverPic,
    width480,
    width1080,
    preview1080Url: hdUrl,
    previewUrl: src,
  } = file;
  const materialMeta = { width480, width1080, url1080: hdUrl };

  Object.assign(dragElement.style, {
    borderRadius: "4px",
    backgroundColor: "rgba(100,100,100,.12)",
  });

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      ...getXY(),
      type: "video",
      name,
      src,
      hdUrl,
      materialMeta,
      duration,
      coverPic,
    },
  });
}

function handleMouseDown(e) {
  const target = e.target.closest("[candrag]");

  if (target === null || e.button !== 0) return;
  const dragTarget = target.querySelector("[drag-target]");

  if (dragTarget === null) return;
  const file = files.value[dragTarget.dataset.index];

  const mouseUpListener = () => {
    addVideo(file);
    target.removeEventListener("mousemove", moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener("mouseup", mouseUpListener);
    handleDrag(e, dragTarget, file);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  target.addEventListener("mousemove", moveListener, { once: true });
  target.addEventListener("mouseup", mouseUpListener, { once: true });
}

function preview(value) {
  file.value = { ...value, type: "video" };
  previewer.value.open = true;
}
</script>
<template>
  <Header
    v-model:keyword="keyword"
    v-model:ratio="ratio"
    :showColor="false"
    @search="search"
  />
  <el-scrollbar ref="scrollbar">
    <el-skeleton animated :loading="loading && page === 1">
      <template #template>
        <el-skeleton-item v-for="(_, i) in Array(20)" variant="rect" :key="i" />
      </template>
      <div v-if="files.length > 0" class="list-wrapper">
        <div
          ref="list"
          class="file-list"
          v-infinite-scroll="loadMore"
          :infinite-scroll-immediate="false"
          :infinite-scroll-distance="50"
          @mousedown="handleMouseDown"
        >
          <div
            class="file-item"
            v-for="(file, i) in files"
            :key="i"
            candrag
            @mouseenter="hoverFile = file"
            @mouseleave="hoverFile = null"
          >
            <div class="image-wrapper" drag-target :data-index="i">
              <img
                :src="file.coverPic"
                loading="lazy"
                draggable="false"
              />
            </div>
            <span
              v-if="file.duration"
              v-show="hoverFile !== file"
              class="duration"
            >
              {{ secondsToHms(file.duration) }}
            </span>
            <span
              v-show="hoverFile === file"
              class="preview"
              @mousedown.stop="preview(file)"
            >
              <svg-icon name="editor_library_preview" clickable :size="18" />
            </span>
          </div>
        </div>
        <loading v-show="loading && page > 1" />
      </div>
      <empty v-else />
      <previewer ref="previewer" :showArrow="false" :record="file" />
    </el-skeleton>
  </el-scrollbar>
</template>
<style scoped>
.list-wrapper {
  padding: 0 16px;
}
.file-list {
  width: 230px;
  position: relative;
}
:deep(.el-skeleton) {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  padding: 0 16px;
}
.file-item {
  width: 110px;
  position: absolute;
}

.image-wrapper,
:deep(.el-skeleton__item) {
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f3f5f7;
  border-radius: 4px;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}
:deep(.el-skeleton__item) {
  height: 70px;
}

.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.file-item .duration {
  padding: 0 8px;
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  right: 4px;
  bottom: 4px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.file-item .preview {
  padding: 3px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  border-radius: 2px;
  background-color: rgba(38, 38, 38, 0.7);
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%);
}
.empty p {
  color: #646a73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: -25px;
}
</style>
