<template>
  <card-item v-bind="cardItemProps">
    <div class="progress-box">
      <span class="progress-tip">{{ tip }}</span>
      <div class="progress-bar">
        <div class="progress" :style="{ width: `${value * 100}%` }"></div>
      </div>
    </div>
  </card-item>
</template>

<script setup lang="ts">
import CardItem from "./card-item.vue";
import { createGroupProxy } from "@/utils/type";
import { cardItemPropsSchema } from "../utils/type";
import type { PropType } from "vue";

const props = defineProps({
  ...cardItemPropsSchema,
  tip: {
    type: String,
    default: "",
  },
  value: {
    type: [Number, Object] as PropType<number>,
    required: true,
  },
  type: {
    type: String as PropType<"default" | "loop">,
    default: "default",
  },
});

const progressClass = computed(
  () => `progress ${props.type === "loop" ? "progress-loop" : ""}`,
);

const { rest: cardItemProps } = createGroupProxy(props, {
  customProps: ["value", "tip", "type"],
});
</script>

<style lang="scss" scoped>
.progress-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  inset: 0;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.progress-tip {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  margin-bottom: 16px;
}

.progress-bar {
  position: relative;
  width: 160px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow-x: hidden;
}

.progress {
  height: 100%;
  background-color: #ffffff;
  transition: width 0.2s;
}

.progress-loop {
  position: absolute;
  animation: progressLoop 1.5s linear infinite;
}

:deep(.video-name),
:deep(.update-time) {
  color: #bbbfc4;
}
</style>
