import { useTrackStore } from "@/store/modules/track";
import { throttled } from "@/utils";

export function useAttrTrackStore(node) {
  const { collectData, track, clearEventData } = useTrackStore(); 
  const id = node.conf.sourceId || node.id;
  const base = {
    element_type: node.type,
    element_id: id,
  };
  switch(node.type) {
    case "sticker":
    case "image":
    case "video":
      base.with_mask = false;
      break;
  };

  const trackHelper = () => {
    collectData("boolvideo_timeline_edit_click", {
      ...base,
    });
    track("boolvideo_timeline_edit_click");
    clearEventData("boolvideo_timeline_edit_click");
  };

  const delayTrackHelper = throttled(trackHelper, 10000);
  watch(
    [() => node.conf.start, () => node.conf.end],
    () => {
      base.click = "time_change";
      delayTrackHelper();
    },
    {
      deep: true,
    }
  );

  function observeNodeAttr() {
    const delayTrackHelper = throttled(trackHelper, 10000);
    watch(
      [() => node.conf.x, () => node.conf.y], 
      () => {
        base.click = "media_position_change";
        delayTrackHelper();
      }
    );
  }

  function observeMaskAttr() {
    const delayTrackHelper = throttled(trackHelper, 10000);
    watch(
      () => node.conf.mask,
      (newMask, oldMask) => {
        base.with_mask = Boolean(newMask);
        if (!node.mask && newMask) {
          base.click = "add_mask";
          trackHelper();
        }
        else if (!newMask && oldMask) {
          base.click = "delete_mask";
          trackHelper();
        }
      },
      {
        immediate: true,
      },
    );
    watch(
      [() => node.conf.mask?.x, () => node.conf.mask?.y],
      ([newX, newY]) => {
        if (node.mask && typeof newX === "number" && typeof newY === "number") {
          base.click = "mask_position_change";
          delayTrackHelper();
        }
      },
    );
  }

  function observeAudioAttr() {
    const delayTrackHelper = throttled(trackHelper, 10000);

    watch(
      () => node.conf.volume,
      () => {
        base.click = "music_volume_change";
        delayTrackHelper();
      },
    );
  }

  function observeTextAttr() {
    const delayTrackHelper = throttled(trackHelper, 10000);

    watch(
      () => node.conf.text,
      () => {
        base.click = "edit_text";
        delayTrackHelper();
      },
    );
  }

  switch (node.type) {
    case "image":
    case "sticker":
      observeNodeAttr();
      observeMaskAttr();
      break;
    case "effect":
      observeNodeAttr();
      break;
    case "video":
      observeNodeAttr();
      observeMaskAttr();
      observeAudioAttr();
      break;
    case "text":
      observeNodeAttr();
      observeTextAttr();
      break;
    case "audio":
    case "speech":
      observeAudioAttr();
      break;
    case "filter":
      break;
  }
}