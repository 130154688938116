<script setup>
import { useCreatorStore, useDraftStore, useHistoryStore, useKeyboard } from "../../stores";
import Transformer from "./transformer.vue";

const emit = defineEmits([
  "update:x",
  "update:y",
  "update:width",
  "update:height",
  "update:scale",
  "update:rotate",
]);
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  nodes: {
    type: Array,
    default: () => [],
  },
  node: {
    type: Object,
    default: null,
  },
  index: {
    type: Number,
    default: -1,
  },
  mask: {
    type: Object,
    default: null,
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  width: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 0,
  },
  scale: {
    type: Number,
    default: 1,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  anchor: {
    type: Array,
    default: [0.5, 0.5],
  },
  minw: {
    type: Number,
    default: 0,
  },
  minh: {
    type: Number,
    default: 0,
  },
  dirtyId: {
    type: Number,
    default: 0,
  },
});

const { attrTabMap, creator, setActiveNode, removeActiveNodes } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { pressed } = useKeyboard();

const x = ref(0);
const y = ref(0);
const width = ref(0);
const height = ref(0);
const scale = ref(1);
const rotation = ref(0);
const transformer = ref(null);
const isSubtitle = computed(() => props.node.type === "subtitle");

onMounted(fitWidgetRect);

watch(
  () => props.active,
  (newActive) => {
    const { node, mask } = props;

    if (newActive) {
      // unrefElement(transformer).focus({ preventScroll: true });
    } else if (!newActive && !mask && node.mask && attrTabMap[node.id] !== "mask") {
      attrTabMap[node.id] = "mask";
    }
  },
  { flush: "post" },
);
watch(
  [
    () => props.node.updateId, 
    () => props.dirtyId,
  ],
  () => {
    if (
      !transformer.value ||
      transformer.value.dragging ||
      transformer.value.resizing ||
      transformer.value.rotating
    ) {
      return;
    }
    fitWidgetRect();
  },
  { flush: "post" },
);

function submit() {
  commit();
  updateDraft();
}

function applyAllSubtitle(key, value) {
  const node = props.node;
  const subtitles = node.parent.children.filter((s) => s !== node);

  for (const subtitle of subtitles) {
    subtitle.conf[key] = value;
  }
}

function handleKeyDown(e) {
  const { x, y } = props;

  switch (e.code) {
    case "Delete":
    case "Backspace":
      removeActiveNodes();
      submit();
      break;
    case "ArrowLeft":
      emit("update:x", x - 1);
      isSubtitle.value && applyAllSubtitle("x", x - 1);
      submit();
      break;
    case "ArrowRight":
      emit("update:x", x + 1);
      isSubtitle.value && applyAllSubtitle("x", x + 1);
      submit();
      break;
    case "ArrowUp":
      emit("update:y", y - 1);
      isSubtitle.value && applyAllSubtitle("y", y - 1);
      submit();
      break;
    case "ArrowDown":
      emit("update:y", y + 1);
      isSubtitle.value && applyAllSubtitle("y", y + 1);
      submit();
      break;
  }
}

function handleDrag({ left, top }) {
  const [x, y] = getNodeXY(left, top);

  emit("update:x", x);
  emit("update:y", y);
  isSubtitle.value && applyAllSubtitle("x", x);
  isSubtitle.value && applyAllSubtitle("y", y);
}

function handleResize({ left, top, width, height }) {
  const [x, y] = getNodeXY(left, top);
  const [w, h] = getNodeSize(width, height);
  const scale = getNodeScale(width);

  emit("update:x", x);
  emit("update:y", y);
  emit("update:width", w);
  emit("update:height", h);
  emit("update:scale", scale);
  isSubtitle.value && applyAllSubtitle("x", x);
  isSubtitle.value && applyAllSubtitle("y", y);
  isSubtitle.value && applyAllSubtitle("scale", scale);
}

function handleRotate(rotation) {
  emit("update:rotate", rotation);
  isSubtitle.value && applyAllSubtitle("rotate", rotation);
}

function getWidgetShift() {
  const target = props.mask || props.node;
  const anchor = target.getAnchor();
  const shiftX = width.value * anchor.x;
  const shiftY = height.value * anchor.y;
  return [shiftX, shiftY];
}

function fitWidgetRect() {
  const target = props.mask || props.node;
  const [nodeX, nodeY] = target.getXY();
  const [nodeWidth, nodeHeight] = target.getWH();
  const nodeAnchor = target.getAnchor();
  const nodeScale = target.getScale();
  const nodeRotation = target.getRotation();

  const nodeTop = nodeY - nodeHeight * nodeAnchor.y;
  const nodeLeft = nodeX - nodeWidth * nodeAnchor.x;

  const { scaleX, scaleY } = creator.value;
  const newTop = nodeTop * scaleY;
  const newLeft = nodeLeft * scaleX;
  const newWidth = nodeWidth * scaleX;
  const newHeight = nodeHeight * scaleY;

  x.value = newLeft;
  y.value = newTop;
  width.value = newWidth;
  height.value = newHeight;
  rotation.value = nodeRotation;
  scale.value = nodeScale;

  // console.log('[fitWidget]', { newLeft, newTop, nodeWidth, newWidth });
}

function getNodeXY(x, y) {
  const { scaleX, scaleY } = creator.value;
  const [shiftX, shiftY] = getWidgetShift();
  const nodeX = (x + shiftX) / scaleX;
  const nodeY = (y + shiftY) / scaleY;
  return [nodeX, nodeY];
}

function getNodeSize(width, height) {
  const { scaleX, scaleY } = creator.value;
  const nodeWidth = width / scaleX;
  const nodeHeight = height / scaleY;
  return [nodeWidth, nodeHeight];
}

function getNodeScale(width) {
  const target = props.mask || props.node;
  const { scaleX } = creator.value;
  const texture = target.getTexture();

  if (!texture) {
    return 1;
  }
  const nodeWidth = width / scaleX;
  const scale = nodeWidth / texture.width;
  return scale;
}

function handleMouseDown() {
  setTimeout(() => setActiveNode(props.node, !pressed.value));
}

function handleMouseUp(e, moved) {
  if (!moved) {
    const nextElements = [];
    let element = e.target;

    while ((element = element.nextElementSibling)) {
      if (element.classList.contains("transformer")) {
        nextElements.push(element);
      }
    }
    for (let i = nextElements.length - 1; i >= 0; i--) {
      const element = nextElements[i];
      const { left, right, top, bottom } = element.getBoundingClientRect();

      if (
        left <= e.pageX &&
        e.pageX <= right &&
        top <= e.pageY &&
        e.pageY <= bottom
      ) {
        const node = props.nodes[element.dataset.index];
        setTimeout(() => setActiveNode(node, !pressed.value));
        break;
      }
    }
  }
}

function handleDblclick() {
  const { active, node, mask } = props;

  if (active && mask && attrTabMap[node.id]) {
    attrTabMap[node.id] = "props";
  }
}
</script>
<template>
  <Transformer
    v-if="width && height"
    ref="transformer"
    :data-index="index"
    :active="active"
    v-model:x="x"
    v-model:y="y"
    v-model:width="width"
    v-model:height="height"
    v-model:rotation="rotation"
    :anchor="anchor"
    :lock-aspect-ratio="!mask && node.type !== 'graphic'"
    :handlers="
      mask || node.type === 'graphic'
        ? ['tl', 'tm', 'tr', 'bl', 'bm', 'br', 'ml', 'mr']
        : ['tl', 'tr', 'bl', 'br']
    "
    @dblclick.stop="handleDblclick"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
    @keydown.stop="handleKeyDown"
    @dragging="handleDrag"
    @dragend="submit"
    @resizing="handleResize"
    @resizeend="submit"
    @rotating="handleRotate"
    @rotateend="submit"
  />
</template>

<style scoped></style>
