<script setup>
import { useNetwork } from "@/composables";
import { getCloudMaterialList } from "@/api/material";
import { fileUploadState } from "@/api/upload";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import { secondsToHms } from "../../utils";
import Empty from "./empty.vue";
import Loading from "./loading.vue";

const filterOptions = [
  {
    label: "Type",
    value: "type",
    items: [
      {
        label: "Image",
        value: "image",
      },
      {
        label: "Video",
        value: "video",
      },
      {
        label: "Audio",
        value: "audio",
      },
    ],
  },
  {
    label: "Source",
    value: "source",
    items: [
      {
        label: "Original",
        value: "original",
      },
      {
        label: "AI generated",
        value: "ai",
      },
    ],
  },
];

const {
  getXY,
  mediaTab,
  updateProject,
  updateCloud,
  addImageNode,
  addVideoNode,
  addAudioNode,
  addEffectNode,
  secondToFrame,
} = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();
const { assertNetworkError } = useNetwork();

const scrollbar = ref(null);
const files = ref([]);
const page = ref(1);
const total = ref(0);
const timer = ref(null);
const loading = ref(false);
const filters = reactive({ type: new Set(), source: new Set() });
const active = computed(() => filters.type.size > 0 || filters.source.size > 0);

onUnmounted(() => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = null;
});

watch(
  page,
  (newPage) => {
    if (newPage === 1) {
      scrollbar.value?.setScrollTop(0);
    }
    getCloudMaterial(newPage);
  },
  { immediate: true },
);
watch(updateProject, () => (mediaTab.value = "project"));
watch(updateCloud, () => getCloudMaterial(1, 1, true));

async function getCloudMaterial(page, size = 20, order) {
  loading.value = true;
  const params = { page, size };

  if (filters.type.size > 0) {
    params.filterTypes = Array.from(filters.type);
  }
  if (filters.source.size > 0) {
    params.source = Array.from(filters.source);
  }
  const response = await getCloudMaterialList(params);
  const { records } = response.data;

  for (const file of records) {
    if (file.state === 2) {
      file.fakeProgress = 0.5;
    } else {
      file.fakeProgress = 1;
    }
  }

  total.value = response.data.total;
  files.value = order
    ? records.concat(files.value)
    : files.value.concat(records);

  for (const file of files.value) {
    if (file.state === 2) {
      getUploadState(file.mid)
        .then((info) => Object.assign(file, info))
        .catch((e) => Object.assign(file, e));
    }
  }
  loading.value = false;
}

async function filter() {
  files.value = [];

  if (page.value === 1) {
    await getCloudMaterial(1);
  } else {
    page.value = 1;
  }
}

function loadMore() {
  if (0 < files.value.length && files.value.length < total.value) {
    page.value++;
  }
}

async function getUploadState(mid) {
  return new Promise((resolve, reject) => {
    const getState = async () => {
      const { success, data } = await fileUploadState(mid);

      if (success) {
        if (data.state === 0) {
          resolve({ ...data, fakeProgress: 1 });
        } else if (data.state === 1) {
          reject({ error: "failure", success: false });
        } else {
          timer.value = setTimeout(getState, 1000);
        }
      } else {
        reject({ error: "failure", success: false });
      }
    };
    getState();
  });
}

async function addAsset(file) {
  assertNetworkError();

  if (file.previewUrl && file.fakeProgress === 1) {
    const {
      name,
      type,
      duration,
      width480,
      width1080,
      coverPic,
      preview1080Url: hdUrl,
      previewUrl: src,
    } = file;
    const materialMeta = { width480, width1080, url1080: hdUrl };
    const newDuration = secondToFrame(duration);

    switch (type) {
      case "image":
        await addImageNode({ name, hdUrl, src, materialMeta });
        break;
      case "video":
        await addVideoNode({
          name,
          hdUrl,
          src,
          coverPic,
          materialMeta,
          duration: newDuration,
          transparent: file.aiType === "videoBgRemove",
        });
        break;
      case "audio":
        await addAudioNode({ name, src, duration: newDuration });
        break;
      case "effect":
        await addEffectNode({ name, src });
        break;
    }
    commit();
    updateDraft();
  }
}

function handleDrag(e, target, file) {
  const dragElement = target.querySelector("[drag-target]").cloneNode(true);
  dragElement.style.backgroundColor = "rgba(100,100,100,.12)";
  const { 
    type, 
    name, 
    duration, 
    aiType, 
    coverPic,
    width480,
    width1080,
    previewUrl: src, 
    preview1080Url: hdUrl, 
  } = file;
  const materialMeta = { width480, width1080, url1080: hdUrl };

  const getRestConfig = () => {
    switch (type) {
      case "image":
        return { ...getXY(), hdUrl, materialMeta, fit: "contain" };
      case "video":
        return { ...getXY(), hdUrl, materialMeta, duration, coverPic, fit: "contain", transparent: aiType === "videoBgRemove" };
      case "audio":
        return { duration };
      case "effect":
        return { ...getXY(), loop: true };
      default:
        return {};
    }
  }

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      type,
      name,
      src,
      ...getRestConfig(),
    },
  });
}

function handleMouseDown(e) {
  const target = e.target.closest(".file-content[candrag]");

  if (target === null || e.button !== 0) return;
  const file = files.value.find(({ mid }) => mid === target.dataset.id);

  const mouseUpListener = () => {
    addAsset(file);
    target.removeEventListener("mousemove", moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener("mouseup", mouseUpListener);
    handleDrag(e, target, file);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  target.addEventListener("mousemove", moveListener, { once: true });
  target.addEventListener("mouseup", mouseUpListener, { once: true });
}
</script>
<template>
  <div class="header">
    <group-select
      v-model="filters"
      canReset
      :teleported="false"
      :width="230"
      :options="filterOptions"
      @change="filter"
    >
      <icon-button
        border
        name="editor_library_filter"
        :size="18"
        :active="active"
        :color="active ? '#FFFFFF' : '#646A73'"
      />
    </group-select>
  </div>
  <el-scrollbar ref="scrollbar">
    <el-skeleton animated :loading="loading && page === 1">
      <template #template>
        <el-skeleton-item v-for="(_, i) in Array(20)" variant="rect" :key="i" />
      </template>
      <div
        v-if="files.length > 0"
        class="file-list"
        v-infinite-scroll="loadMore"
        :infinite-scroll-immediate="false"
        @mousedown="handleMouseDown"
      >
        <div class="file-item" v-for="file in files" :key="file.mid">
          <div class="file-content" :data-id="file.mid" candrag>
            <div class="image-wrapper" drag-target>
              <div
                class="image-mask"
                v-if="0 <= file.fakeProgress && file.fakeProgress < 1"
              >
                <span v-if="file.tag !== 'ai'">Uploading</span>
                <el-progress
                  :percentage="file.fakeProgress * 100"
                  :show-text="false"
                  :color="file.tag === 'ai' ? '#FFE39B' : '#FFFFFF'"
                />
              </div>
              <img
                v-if="file.coverPic"
                :src="file.coverPic"
                loading="lazy"
                draggable="false"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'image'"
                name="editor_thumb_image"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'video'"
                name="editor_thumb_video"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'audio'"
                name="editor_thumb_music"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-if="file.tag === 'ai'"
                class="ai-tag"
                name="editor_ai_tools"
                :size="16"
              />
            </div>
            <span
              v-if="['video', 'audio'].includes(file.type) && file.duration"
              class="duration"
              >{{ secondsToHms(file.duration) }}</span
            >
          </div>
          <div class="title">{{ file.name }}</div>
        </div>
      </div>
      <empty v-else />
      <loading v-show="loading && page > 1" />
    </el-skeleton>
  </el-scrollbar>
</template>
<style scoped>
.el-scrollbar {
  height: calc(100% - 60px);
}
.header {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.file-list,
:deep(.el-skeleton) {
  display: grid;
  flex-wrap: wrap;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(107px, 1fr));
  padding: 0 16px;
}

.file-item {
  width: 107px;
}

.file-content {
  margin-bottom: 4px;
  overflow: hidden;
  position: relative;
}

.image-wrapper,
:deep(.el-skeleton__item) {
  width: 107px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f3f5f7;
  border-radius: 4px;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}

.ai-tag {
  position: absolute;
  left: 8px;
  bottom: 10px;
}

.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.image-wrapper .image-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
}

.image-wrapper .image-mask span {
  color: #f8f5ff;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-content .duration {
  padding: 0 8px;
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  right: 8px;
  bottom: 8px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.title {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}
:deep(.icon-button.border) {
  border-radius: 4px;
  border: 1px solid #d5d6d7;
  margin-left: 12px;
}
:deep(.icon-button.border .icon-wapper) {
  padding: 8px;
}
:deep(.icon-button.active) {
  border: 1px solid #fff;
  background-color: #875eff;
}
</style>
