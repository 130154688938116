import { defineStore } from "pinia";
import { getBrandKitList } from "@/api/index";
import { setItem,sessionSetItem } from '@/utils/storage';

export const useFeatureEdit = defineStore("featureEdit", {
  state: () => ({
    formState: {},
    cacheImageList: [],
    imageMidsList: [],
    brandKitList: []
  }),
  actions: {
    updateFormState (formState) {
      this.formState = {
        productDesc: formState.productDesc,
        bkId: formState.bkId,
        url: formState.url,
        templateId: formState.templateId,
      };
      const cacheForm = {...this.formState, cacheImageList: this.cacheImageList }
      sessionSetItem('cacheForm', cacheForm);
    },

    updateImageList (imageList) {
      const list = imageList?.filter(image => image.mid && image.state == 0);
      this.cacheImageList = list
      this.imageMidsList = list.map(image => image.mid);
      const cacheForm = {...this.formState, cacheImageList: this.cacheImageList }
      sessionSetItem('cacheForm', cacheForm);
    },

    async getBrandKitListFn () {
      try {
        const { data } = await getBrandKitList();
        this.brandKitList = data?.map(item => {
          return {
            value: item.bkId,
            label: item.name,
          }
        })
      } catch (err) {
        console.log("err", err);
      }
    },
  }
});