<script setup>
import {
  useDrag,
  useDraftStore,
  useCreatorStore,
  useHistoryStore,
} from '../../stores';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useTrackStore } from '@/store/modules/track';
import { useNetwork } from '@/composables';
import { generateVoiceover } from '@/api/aiTools';
import { getLanguageList, queryVoiceList } from '@/api/language';
import { secondsToHms } from '../../utils';
import { useMessage } from '@/utils';
import Material from './material.vue';
import voiceoverPlay from '@/assets/icons/common/editor_voiceover_play.svg';
import voiceoverPause from '@/assets/icons/common/editor_voiceover_pause.svg';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

const {
  playing,
  materialTab,
  showMaterial,
  voiceoverText,
  addSpeechNode,
  secondToFrame,
  pause,
} = useCreatorStore();
const { collectData, track } = useTrackStore();
const { assertNetworkError } = useNetwork();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();
const message = useMessage();
const modalManager = useModalManager();

// const scrollbarRef = ref(null);
const languageOptions = ref([]);
const voiceOptions = ref([]);
const styleOptions = ref([]);
const text = ref('');
const voice = ref(null);
const style = ref(null);
const speed = ref(1);
const gender = ref('All');
const language = ref(null);
const generating = ref(false);
const hasGenerated = ref(false);
const generateDiabled = ref(false);
const subscribeModalVisible = ref(false);
const resultAudio = reactive({
  name: null,
  audio: null,
  url: null,
  duration: null,
});
const currentAudio = reactive({
  url: null,
  audio: null,
  playing: false,
  currentTime: 0,
});
const genderOptions = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
];

watch([showMaterial, materialTab, playing], ([isShow, newTab, playing]) => {
  if (!isShow || newTab !== 'voiceover' || playing) {
    destroyAudio();
  }
});

watch(
  voiceoverText,
  (value) => {
    if (value === null) {
      return;
    }
    updateText(value);
  },
  { immediate: true }
);

watch([text, gender, voice, style, speed, language], () => {
  if (hasGenerated.value) {
    generateDiabled.value = false;
  }
});

watch([language, gender], (newLanguage) => {
  getVoiceList();
});

watch(voice, (newVoice) => {
  const { styleList } = voiceOptions.value.find(
    (item) => item.value === newVoice
  );
  styleOptions.value = styleList.map((item) => ({
    ...item,
    label: capitalizeFirstLetter(item.styleName),
    value: item.styleName,
    url: item.audioFileEndpointWithSas,
  }));
  console.log(styleOptions.value);
  style.value = styleOptions.value[0].value;
});

onMounted(initData);
onBeforeUnmount(destroyAudio);

async function initData() {
  const languageHelper = (list) => {
    let temp = [];
    list.forEach((item) => {
      temp = [...temp, ...item.localInfoList];
    });
    return temp;
  };

  const res = await getLanguageList();

  languageOptions.value = languageHelper(res.data).map((item) => ({
    label: item.localName,
    value: item.locale,
  }));
  language.value = getLocale();
  getVoiceList();
}

const getLocale = () => {
  const { language } = window.navigator;
  const lang =
    languageOptions.value.find((item) => item.value.includes(language))
      ?.value || 'en-US';
  console.log(language, lang);
  return lang;
};

const getVoiceList = async () => {
  const params = {
    locale: language.value,
    gender: gender.value === 'All' ? '' : gender.value,
  };
  const res = await queryVoiceList(params);
  if (!res.success) return;
  const { data } = res;
  voiceOptions.value = data.map((item) => ({
    ...item,
    label: item.localName,
    value: item.shortName,
    url: item.styleList[0].audioFileEndpointWithSas,
  }));
  voice.value = voiceOptions.value[0].value;
  styleOptions.value = data[0].styleList.map((item) => ({
    ...item,
    label: capitalizeFirstLetter(item.styleName),
    value: item.styleName,
    url: item.audioFileEndpointWithSas,
  }));
  style.value = styleOptions.value[0].value;
};

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function updateText(value) {
  text.value = value;
}

function updateLanguage(value) {
  language.value = value;
}

function updateVoice(value) {
  voice.value = value;
}

function updateStyle(value) {
  style.value = value;
}

function updateSpeed(value) {
  speed.value = value;
}

function updateGender(value) {
  gender.value = value;
}

function resetSpeed() {
  speed.value = 1;
}

function submit() {
  commit();
  updateDraft();
}

async function generateVoice() {
  const trackHelper = () => {
    collectData('boolvideo_aitools_use', {
      tool_name: 'Voiceover',
      access: 'editor',
    });
    track('boolvideo_aitools_use');
  };
  // const scrollBottom = () => {
  //   const { wrapRef} = scrollbarRef.value;
  //   scrollbarRef.value.setScrollTop(wrapRef.scrollHeight);
  // };

  generating.value = true;

  const res = await generateVoiceover({
    text: text.value,
    speed: speed.value,
    voiceName: voice.value,
    language: language.value,
    style: style.value === 'default' ? '' : style.value,
  });
  const { success, code, data, msg } = res;
  if (success) {
    const url = data.audioUrl;
    const duration = data.audioDuration;
    const audio = new Audio(url);
    Object.assign(resultAudio, {
      name: text.value,
      url,
      audio,
      duration,
    });

    generateDiabled.value = true;
    hasGenerated.value = true;
    // nextTick(scrollBottom);

    trackHelper();
  } else {
    if (code === 103008) {
      message.error("Input language doesn't match your selection.");
    } else if (code === 50001 || code === 90001) {
      modalManager.applyTemplate('noCredits', {
        code,
        onConfirm: () => {
          subscribeModalVisible.value = true;
        },
      });
    } else if (code === 90002) {
      message.error('Text can not be empty.');
    } else {
      message.error('Error');
    }
  }

  generating.value = false;
}

function play(url) {
  pause();

  const currentUrl = url;
  if (currentAudio.url !== currentUrl) {
    destroyAudio();

    const audio = new Audio(currentUrl);
    audio.ontimeupdate = () => (currentAudio.currentTime = audio.currentTime);
    audio.onended = () => {
      currentAudio.playing = false;
      audio.currentTime = 0;
    };

    currentAudio.url = currentUrl;
    currentAudio.audio = audio;
  }

  currentAudio.playing = true;
  currentAudio.audio.play();
}

function pauseAudio() {
  currentAudio.playing = false;
  currentAudio.audio.pause();
}

function destroyAudio() {
  if (currentAudio.audio) {
    pauseAudio();
    currentAudio.url = null;
    currentAudio.audio.ontimeupdate = null;
    currentAudio.audio.onended = null;
    currentAudio.audio = null;
    currentAudio.currentTime = 0;
  }
}

function handleDrag(e, target, file) {
  const dragElement = target.querySelector('[drag-target]').cloneNode(true);
  dragElement.style.width = '200px';
  dragElement.style.height = 'fit-content';
  dragElement.style.borderRadius = '4px';

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      type: 'speech',
      name: file.name,
      src: file.url,
      duration: file.duration,
      sourceId: file.audio.sinkId,
    },
  });
}

function handleMouseDown(e) {
  if (!hasGenerated.value) return;
  const target = e.target.closest('.sub-content[candrag]');
  if (target === null || e.button !== 0) return;
  const mouseUpListener = (e) => {
    target.removeEventListener('mousemove', moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener('mouseup', mouseUpListener);
    handleDrag(e, target, resultAudio);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  target.addEventListener('mousemove', moveListener, { once: true });
  target.addEventListener('mouseup', mouseUpListener, { once: true });
}

async function add() {
  assertNetworkError();

  const { name, audio, url, duration } = resultAudio;
  await addSpeechNode({
    name,
    sourceId: audio.sinkId,
    duration: secondToFrame(duration),
    src: url,
  });
  submit();
}
</script>
<template>
  <Material title="Voiceover">
    <div class="voiceover-wrapper">
      <el-scrollbar
        class="voiceover-scrollbar"
        wrap-style="scroll-behavior: smooth;"
      >
        <div class="voiceover-content">
          <div class="textarea-form">
            <el-input
              type="textarea"
              v-model="text"
              placeholder="Enter your text here"
              @input="updateText"
            />
          </div>
          <div class="form">
            <div class="row">
              <div class="sub-header">
                <span>Language</span>
              </div>
              <div class="sub-content">
                <bv-select
                  v-model="language"
                  popper-class="voiceover-language-popper"
                  searchPlaceholder="Search"
                  :showSearch="true"
                  :popper-style="{ width: '226px' }"
                  :options="languageOptions"
                  @input="updateLanguage"
                >
                  <template #option="{ option }">
                    <div class="select-item">
                      <div>
                        <span>{{ option.label }}</span>
                        <span class="accent" v-if="false">
                          {{ ' ( ' + option.accent + ' )' }}
                        </span>
                      </div>
                    </div>
                  </template>
                </bv-select>
              </div>
            </div>
            <div class="row">
              <div class="sub-header">
                <span>Gender</span>
              </div>
              <div class="sub-content">
                <bv-select
                  v-model="gender"
                  :popper-style="{ width: '226px' }"
                  :options="genderOptions"
                  @input="updateGender"
                />
              </div>
            </div>
            <div class="row">
              <div class="sub-header">
                <span>Voice</span>
              </div>
              <div class="sub-content">
                <bv-select
                  v-model="voice"
                  popper-class="voiceover-voice-popper"
                  :popper-style="{ width: '226px' }"
                  :options="voiceOptions"
                  :showSelectIcon="false"
                  @input="updateVoice"
                >
                  <template #option="{ option }">
                    <div class="select-item">
                      <span>{{ option.label }}</span>
                      <!-- mousedown.prevent in order 
                        to prevent trigering blur -->
                      <img
                        :src="voiceoverPlay"
                        v-show="
                          !currentAudio.playing ||
                          currentAudio.url !== option.url
                        "
                        style="width: 18px"
                        @mousedown.prevent
                        @click.stop="play(option.url)"
                      />
                      <img
                        :src="voiceoverPause"
                        v-show="
                          currentAudio.playing &&
                          currentAudio.url === option.url
                        "
                        style="width: 18px"
                        @mousedown.prevent
                        @click.stop="pauseAudio()"
                      />
                    </div>
                  </template>
                </bv-select>
              </div>
            </div>
            <div class="row">
              <div class="sub-header">
                <span>Style</span>
              </div>
              <div class="sub-content">
                <bv-select
                  v-model="style"
                  popper-class="voiceover-style-popper"
                  :popper-style="{ width: '226px', height: '200px' }"
                  :options="styleOptions"
                  :showSelectIcon="false"
                  @input="updateStyle"
                >
                  <template #option="{ option }">
                    <div class="select-item">
                      <span>{{ option.label }}</span>
                      <!-- mousedown.prevent in order 
                        to prevent trigering blur -->
                      <img
                        :src="voiceoverPlay"
                        v-show="
                          !currentAudio.playing ||
                          currentAudio.url !== option.url
                        "
                        style="width: 18px"
                        @mousedown.prevent
                        @click.stop="play(option.url)"
                      />
                      <img
                        :src="voiceoverPause"
                        v-show="
                          currentAudio.playing &&
                          currentAudio.url === option.url
                        "
                        style="width: 18px"
                        @mousedown.prevent
                        @click.stop="pauseAudio()"
                      />
                    </div>
                  </template>
                </bv-select>
              </div>
            </div>
            <div class="row">
              <div class="sub-header">
                <span>Speed</span>
                <icon-button
                  name="editor_reset"
                  tip="Reset"
                  :size="14"
                  @click="resetSpeed"
                />
              </div>
              <div class="sub-content">
                <div class="slider-input">
                  <bv-slider
                    :model-value="speed"
                    :min="0.5"
                    :max="3.0"
                    :step="0.5"
                    @input="updateSpeed"
                    show-stops
                  />
                  <input-number
                    align-center
                    speed
                    v-model="speed"
                    :min="0.5"
                    :max="3.0"
                    :step="0.5"
                    @change="updateSpeed"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div class="voiceover-footer">
        <div class="row button-row">
          <el-button
            size="large"
            class="voiceover-generate-button"
            :class="{ active: generating }"
            :disabled="generateDiabled"
            @click="generateVoice"
          >
            <span>Generate Voice</span>
            <svg-icon
              v-if="generating"
              class="voiceover-loading-icon"
              name="icon_loading"
              color="#875EFF"
              :size="16"
            />
          </el-button>
        </div>
        <div class="row">
          <div class="sub-header">
            <span>Preview</span>
          </div>
          <div class="sub-content" candrag>
            <div
              class="preview-container"
              :class="{ hasGenerated: hasGenerated }"
              @mousedown="handleMouseDown"
              drag-target
            >
              <div class="left">
                <svg-icon
                  class="play"
                  v-show="
                    !(
                      currentAudio.playing &&
                      currentAudio.url === resultAudio.url
                    )
                  "
                  name="editor_play"
                  :color="hasGenerated ? '#6741FF' : '#BBBFC4'"
                  :size="24"
                  @click="play(resultAudio.url)"
                />
                <svg-icon
                  class="pause"
                  v-show="
                    currentAudio.playing && currentAudio.url === resultAudio.url
                  "
                  name="editor_pause"
                  :size="24"
                  :color="hasGenerated ? '#6741FF' : '#BBBFC4'"
                  @click="pauseAudio"
                />
                <span class="duration">{{
                  secondsToHms(resultAudio.duration || 0)
                }}</span>
              </div>
              <div class="right">
                <svg-icon
                  name="editor_plus"
                  :color="hasGenerated ? '#646A73' : '#BBBFC4'"
                  @click="add"
                  :size="18"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </Material>
</template>
<style>
.voiceover-language-popper .accent {
  color: #8f959e;
}

.voiceover-voice-popper .select-item-content,
.voiceover-style-popper .select-item-content {
  width: 100%;
}

.voiceover-voice-popper .bv-select-item,
.voiceover-style-popper .bv-select-item {
  color: #060606 !important;
}

.voiceover-style-popper > ul {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.voiceover-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.voiceover-scrollbar {
  flex: 1 1;
  min-height: 0;
}

.voiceover-content {
  padding-bottom: 120px;
}

.voiceover-footer {
  flex: 0 0 202px;
  padding: 32px 18px 32px;
  border-top: 1px solid #e8e9ec;
}

.textarea-form {
  padding: 18px 18px 0;
}

.form {
  padding: 24px 18px 0;
}

.row + .row {
  margin-top: 24px;
}

.sub-header {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.slider-input {
  display: flex;
  gap: 10px;
}

.select-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.voiceover-loading-icon {
  margin-left: 10px;
  animation: rotate 1s linear infinite;
}

.preview-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  border-radius: 4px;
  background: #ebedef;
  pointer-events: none;
}

.preview-container.hasGenerated {
  background: #f8f5ff;
  pointer-events: unset;
}

.preview-container svg {
  display: inline;
  cursor: pointer;
}

.play,
.pause {
  margin-right: 8px;
}

.item-suffix {
  margin-right: 6px;
}

.duration {
  color: #060606;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

:deep(.el-button.el-button--large) {
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #6741ff;
  color: #6741ff;
}

:deep(.el-button.el-button--large:hover) {
  background-color: #f8f5ff;
}

:deep(.voiceover-generate-button.active) {
  background-color: #f8f5ff;
  pointer-events: none;
}

:deep(.el-button.is-disabled) {
  border-color: #e5e7eb !important;
  background-color: #ffffff !important;
  color: #bbbfc4 !important;
}

:deep(.slider-input .el-input) {
  flex: 0 0 88px;
  height: 28px;
}

:deep(.slider-input .el-input__inner) {
  font-variant-numeric: tabular-nums;
}

:deep(.el-input__wrapper) {
  border: 0.5px solid #bbbfc4;
  outline: 0.5px solid transparent;
  box-shadow: none;
  transition: all 200ms;
  padding: 0 12px;
}

:deep(.el-input__inner) {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #060606;
}

:deep(.el-textarea__inner) {
  height: 104px;
  color: #060606;
  font-size: 12px;
  line-height: 20px;
  padding: 12px;
  border: 0.5px solid #bbbfc4;
  outline: 0.5px solid transparent;
  box-shadow: none;
  transition: all 200ms;
}

:deep(.el-input__wrapper),
:deep(.el-textarea__inner),
:deep(.el-select .el-input .el-input__wrapper) {
  border-color: #d5d6d7 !important;
}

:deep(.el-input .el-input__wrapper:hover),
:deep(.bv-select-button .select-wapper:hover),
:deep(.el-textarea__inner:hover) {
  outline-color: #be9fff;
  border-color: #be9fff;
}

:deep(.el-input .el-input__wrapper.is-focus),
:deep(.bv-select-button .select-wapper.border.is-focus),
:deep(.el-textarea__inner:focus) {
  border-color: #875eff !important;
  outline-color: #875eff !important;
  outline-offset: 0;
  box-shadow: none !important;
}

:deep(.bv-select-button .select-wapper) {
  border: 0.5px solid #bbbfc4;
  outline: 0.5px solid transparent;
}

:deep(.bv-select-button .select-value),
:deep(.bv-select-button .placeholder) {
  font-size: 12px;
  line-height: 20px;
}

:deep(.bv-select-button .select-value) {
  color: #060606;
}

:deep(.select-wapper) {
  padding: 4px 8px;
}
</style>
