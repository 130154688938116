<template>
  <li class="square-container">
    <div
      :class="{
        'square-box cursor-pointer border-2 ': true,
        'border-white': !isActive,
        'hover:border-hoverColor border-2 ': !innerItem.upload && !isActive,
        'border-primaryColor':
          selectedIdList.includes(innerItem.mid!) && !innerItem.upload,
      }"
      @mousemove="handleMouseMove"
      @mouseleave="handleMouseLeave"
    >
      <!-- 展示 -->

      <custom-Upload
        ref="uploadRef"
        v-if="innerItem.upload"
        uploadType="media"
        class="w-full h-full"
        :handleSuccess="handleSuccess"
        :handleProgress="handleProgress"
        :handleError="handleError"
        @uploadProcess="handleUploadProgress"
        @onSuccess="handleUploadSuccess"
        @onError="handleUploadError"
        :draftId="draftId"
        :accept="innerAccept"
      >
      </custom-Upload>
      <el-image
        style="width: 100%; height: 100%"
        :src="item.coverPic"
        :zoom-rate="1.2"
        :initial-index="4"
        fit="contain"
        v-else-if="item.state === 0 && !item.upload"
      >
        <template #error>
          <div
            style="width: 100%; height: 100%"
            class="flex justify-center items-center"
          >
            <svg-icon
              name="icon_error_image"
              :style="{ width: '30px', height: '30px' }"
            ></svg-icon>
          </div>
        </template>
      </el-image>

      <div
        style="width: 100%; height: 100%"
        class="flex justify-center items-center"
        v-else-if="item.state == 1"
        @click.capture="handleReTry"
      >
        <el-image
          style="width: 100%; height: 100%"
          :src="item.fileUrl"
          :zoom-rate="1.2"
          :initial-index="4"
          fit="contain"
        >
        </el-image>

        <div
          class="mask position absolute top-0 left-0 w-full h-full bg-black opacity-20"
        ></div>

        <div class="position absolute z-10 w-full h-full">
          <div class="flex justify-center items-center flex-col w-full h-full">
            <svg-icon
              name="icon_warning"
              :style="{ width: '24px', height: '24px', marginBottom: '6px' }"
            ></svg-icon>
            <p class="text-xs text-white mb-4 leading-22 font-normal">
              Upload failed
            </p>
          </div>
        </div>
      </div>

      <!-- 上传中 -->
      <div
        style="width: 100%; height: 100%"
        class="flex justify-center items-center"
        v-else-if="item.state == 2"
      >
        <svg-icon
          name="icon_video"
          :size="24"
          v-if="item.type == 'video'"
        ></svg-icon>

        <el-image
          style="width: 100%; height: 100%"
          :src="item.fileUrl"
          :zoom-rate="1.2"
          :initial-index="4"
          fit="contain"
          v-else
        >
        </el-image>

        <div
          class="mask position absolute top-0 left-0 w-full h-full bg-black opacity-20"
        ></div>

        <div class="position absolute z-10 w-full h-full">
          <div class="flex justify-center items-center flex-col w-full h-full">
            <p class="text-sm text-white mb-4 leading-22 font-normal">
              Uploading...
            </p>
            <el-progress
              :percentage="item.percent"
              :style="{ width: '60%' }"
              :show-text="false"
            />
          </div>
        </div>
      </div>

      <div
        style="width: 100%; height: 100%"
        class="flex justify-center items-center"
        v-else-if="item.state == 2"
      >
        <el-progress
          :percentage="item.percent"
          :style="{ width: '60%' }"
          :show-text="false"
        />
      </div>
      <slot name="upload" v-else></slot>

      <!-- delete -->
      <div
        class="absolute top bottom-0 left-0 right-0 w-full h-full"
        v-if="innerItem.url && isMove && !selectedIdList.includes(item.mid!)"
      >
        <div
          class="absolute top bottom-0 left-0 right-0 bg-black w-full h-full opacity-60"
          v-if="showMask"
        ></div>
        <div class="absolute top bottom-0 left-0 right-0 w-full h-full">
          <div class="h-full w-full relative" v-if="removeType == 'asset'">
            <div class="w-6 h-6 absolute bottom-3 right-3">
              <div
                class="h-full w-full flex justify-center items-center rounded bg-maskBg"
              >
                <svg-icon
                  name="icon_delete"
                  :style="{ width: '18px', height: '18px', color: '#fff' }"
                  @click.stop="handleRemove(innerItem)"
                ></svg-icon>
              </div>
            </div>
          </div>
          <div
            class="flex justify-center items-center w-full h-full"
            v-else-if="removeType == 'center'"
          >
            <div
              class="flex justify-center items-center h-8 w-20 border border-buttonBorderColor rounded-full"
              @click.stop="handleRemove(innerItem)"
            >
              <svg-icon name="icon_delete" style="{color: #fff;}"></svg-icon>
            </div>
          </div>
        </div>
      </div>

      <!-- select -->
      <div
        class="absolute top bottom-0 left-0 right-0 w-full h-full"
        v-if="selectedIdList.includes(innerItem.mid!) && multiple"
      >
        <div
          class="absolute top bottom-0 left-0 right-0 bg-black w-full h-full opacity-50"
          v-if="selectedIdList.includes(innerItem.mid!) && multiple"
        ></div>
        <div class="absolute top bottom-0 left-0 right-0 w-full h-full">
          <slot name="select-icon" v-if="slots['select-icon']"></slot>
        </div>
      </div>

      <div
        class="video-duration"
        v-if="item.state == 0 && item.type == 'video'"
      >
        {{ formatDuration(item.duration || 0) }}
      </div>
    </div>
  </li>
</template>
<script lang="ts" setup>
import { ref, watch, useSlots, type PropType } from "vue";
import {IMAGETYPE, IMAGEANDVIDEOTYPE } from '@/utils/type.ts'
import CustomUpload from "@/components/common/bv-upload/customUpload.vue";
import type { ImageType } from "./type";
type SelectedType = string | number;

const slots = useSlots();
const emits = defineEmits([
  "uploadComplete",
  "removeImage",
  "onProgress",
  "onSuccess",
  "onError",
  "uploadRetry",
]);
const props = defineProps({
  mode: {
    type: String,
    default: "template",
  },
  selectedIdList: {
    type: Array as PropType<SelectedType[]>,
    default: () => {
      return [];
    },
  },
  item: {
    type: Object as PropType<ImageType>,
    default: () => {
      return {};
    },
  },
  removeType: String,
  draftId: String,
  showMask: {
    type: Boolean,
    default: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  handleProgress: {
    type: Function,
    default: () => {},
  },
  handleError: {
    type: Function,
    default: () => {},
  },
  // 文件上传成功时的回调函数
  handleSuccess: {
    type: Function,
    default: () => {},
  },
  accept:  {
    type: String,
    default: 'image,video'
  },
});

const innerItem = ref(props.item);
const isMove = ref(false);
const uploadRef = ref(null);
const innerAccept = ref(IMAGETYPE);


watch(() => props.accept, (accept) => {
  const acceptArr = accept && accept.split(',')
  acceptArr.length > 1 ?  innerAccept.value = IMAGEANDVIDEOTYPE : innerAccept.value = IMAGETYPE;
}, {
  immediate: true
})

const handleUploadProgress = (data: ImageType) => {
  emits("onProgress", data);
};

const handleUploadSuccess = (data: ImageType) => {
  emits("onSuccess", data);
};

const handleUploadError = (data: ImageType) => {
  emits("onError", data);
};

const handleMouseMove = () => {
  isMove.value = true;
};

const handleMouseLeave = () => {
  isMove.value = false;
};

const handleRemove = (item: ImageType) => {
  const id = item.mid || item.uid;
  emits("removeImage", id);
};

const handleReTry = () => {
  emits("uploadRetry", props.item);
  if (!uploadRef.value) {
    return;
  }
  uploadRef.value?.handleUploadRetry(props.item.uid);
};

const formatDuration = (duration: number) => {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${minutes | 0}:${(seconds | 0).toString().padStart(2, "0")}`;
};
</script>

<style lang="scss" scoped>
.square-container {
  padding-bottom: 100%;
  height: 0;
  width: 100%;
  position: relative;
}

.square-container .square-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f7f7f8;
}

.square-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.square-container .video-duration {
  position: absolute;
  left: 14px;
  bottom: 14px;
  padding-inline: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
}

.mask {
  opacity: 54%;
}
</style>

<style lang="scss">
.square-container {
  .el-image__placeholder {
    background: #f7f7f8;
  }
}

.square-container {
  .el-progress-bar__outer {
    background-color: #7a7b7e;
    border-radius: 0;
    height: 4px !important;
  }

  .el-progress-bar__inner {
    background-color: #fff;
    border-radius: 0;
  }
}
</style>
