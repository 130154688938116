<script setup>
import { useCreatorStore } from "../../stores";

const emit = defineEmits(["update:tabWidth"]);
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
});
const { showMaterial } = useCreatorStore();
</script>
<template>
  <div class="material">
    <icon-button
      class="icon-close"
      name="editor_close"
      :size="20"
      @click="showMaterial = false"
    />
    <div v-if="title" class="material-header-wapper">
      <div class="material-header">
        <span>{{ title }}</span>
      </div>
    </div>
    <slot name="header"></slot>
    <div class="material-content-wapper">
      <slot></slot>
    </div>
  </div>
</template>
<style scoped>
.material {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.icon-close {
  position: absolute;
  top: 7px;
  right: 12px;
  z-index: 5;
}
.material-header-wapper {
  height: 42px;
  padding: 10px 18px;
  border-bottom: 1px solid #e8e9ec;
}
.material-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.material-header span {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.material-content-wapper {
  flex: 1 1;
  width: 100%;
  overflow: hidden;
}
</style>
