<template>
  <el-dialog 
    class="script-text-dialog"
    :model-value="modelValue" 
    :show-close="false"
    :align-center="true"
    :before-close="close"
  >
    <div class="header">
      <span>Idea to scene</span>
      <svg-icon
        name="editor_close"
        :size="24"
        @click="close"
      />
    </div>
    <el-input
      v-model="text"
      type="textarea"
      placeholder="Enter your idea to generate"
      @blur="blur"
      :autosize="{ min: 1, max: 3 }"
    />
    <PrimaryButton
      class="button-container"
      padding="7px 28px" 
      :disabled="text === ''"
      @click="confirm"
    > 
      <div class="button-text">
        <svg-icon
          class="star"
          name="icon_generate_star"
          :size="16"
        />
        <span>
          Generate
        </span>
      </div>
    </PrimaryButton>
  </el-dialog>
</template>

<script setup>
import { useMessage, removeEscapeHtml } from '@/utils';

const message = useMessage();
const props = defineProps({
  modelValue: {
    type: Boolean,
  }
});
const emits = defineEmits([
  "update:modelValue",
  "confirm",
]);

const text = ref("");

const close = () => {
  emits("update:modelValue", false);
  text.value = "";
};

const blur = () => {
  text.value = removeEscapeHtml(text.value);
};

const confirm = () => {
  text.value = removeEscapeHtml(text.value);
  if (text.value.trim() === "") {
    message.error("Prompt can not be empty");
    return;
  };
  emits('confirm', text.value);
  close();
};
</script>

<style lang="scss">
.script-text-dialog {
  width: 415px;
  border-radius: 4px;
}
.script-text-dialog .el-dialog__body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.script-text-dialog .el-dialog__header{
  padding: 0;
  margin: 0;  
}
.script-text-dialog .el-textarea__inner{
  padding: 8px 12px !important; 
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
</style>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  & > span {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  
  & > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: rgba(222, 224, 227, 0.6);
    }
  }
}

.button-container {
  width: fit-content !important;
  margin-top: 24px;
}

.button-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  & > .star {
    margin-right: 4px;
  }
}
</style>