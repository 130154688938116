<template>
  <Teleport to="body">
    <Overlay :open="open">
      <div class="modal-container">
        <header>
          <div class="modal-close-btn" @click="handleClose">
            <SvgIcon :size="20" name="icon_close" :style="{ color: '#646A73' }" />
          </div>
        </header>

        <div class="modal-content-container flex justify-between">
          <InfoPanel ref="infoPanelNoVoice" title="No Al voiceover" :previewUrl="case_no_voice" @onplay="handleNoVoicePlay"
            :isVideoPlay="isNoVoiceoverPlay"
            videoType='Synchronizing music and stunning visual elements in a captivating 15-second visual treat.'
            perfectFor="Slideshow videos and short videos." />
          <InfoPanel ref="infoPanelVoice" title="With AI voiceover" :previewUrl="case_voice" @onplay="handleVoicePlay"
            :isVideoPlay="isVoiceoverPlay"
            videoType='Blending visuals, text and narration to unfold compelling, logic-driven stories tailored to any desired duration'
            perfectFor="Unboxing videos, Step by step introductions, Listicle videos." />
        </div>
      </div>
    </Overlay>
  </Teleport>
</template>

<script setup lang="ts">
import InfoPanel from "./infoPanel.vue";
import Overlay from "@/components/common/custom-modal/components/overlay.vue";
import case_voice from '../../commonAssets/case_voice.mp4';
import case_no_voice from '../../commonAssets/case_no_voice.mp4';
import voiceBroadcastPreview from './imgs/voice_broadcast_preview.png';

const emits = defineEmits(["update:open"]);
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
});

const isVoiceoverPlay = ref(false)
const isNoVoiceoverPlay = ref(false)
const infoPanelNoVoice = ref(null)
const infoPanelVoice = ref(null)

function handleClose() {
  infoPanelNoVoice.value?.destroy()
  infoPanelVoice.value?.destroy()
  emits("update:open", false);
}

const handleNoVoicePlay = (e) => {

  if (e) {
    isNoVoiceoverPlay.value = true
    isVoiceoverPlay.value = false
  }

}

const handleVoicePlay = (e) => {
  if (e) {
    isNoVoiceoverPlay.value = false
    isVoiceoverPlay.value = true
  }
}
</script>

<style scoped lang="scss">
.modal-container {
  width: 840px;
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
}

.modal-content-container {
  display: flex;
  // gap: 54px;
  align-items: center;
  padding-inline: 47px;
  // margin-block: 12px 8px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-inline: 24px;
}

.modal-close-btn {
  width: 24px;
  height: 24px;
  margin-inline: auto 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
}
</style>
