import axios from "axios";

export function normalizeConfig(screen, type, conf) {
  if (["track", "scene", "audio"].includes(type)) {
    return conf;
  }

  const { x, y, width, height, radius, rotate, mask, keyframes } = conf;
  const pi = 3.1415927;

  conf.x = x * screen.width;
  conf.y = y * screen.height;

  switch (type) {
    case "text":
      const { fontSize, wordWrapWidth } = conf;

      if (fontSize) {
        conf.fontSize = fontSize * screen.height;
      }
      if (wordWrapWidth) {
        conf.wordWrapWidth = wordWrapWidth * screen.width;
      }
      break;
    case "graphic":
      conf.strokeThickness = conf.strokeThickness * screen.width;
      break;
  }
  if (width) {
    conf.width = width * screen.width;
  }
  if (height) {
    conf.height = height * screen.height;
  }
  if (radius) {
    conf.radius = radius * screen.width;
  }
  if (rotate) {
    conf.rotate = rotate * pi;
  }
  if (mask) {
    conf.mask = normalizeConfig("mask", mask);
  }
  if (keyframes) {
    for (const keyframe of Object.values(keyframes)) {
      const { x, y, width, height, rotate } = keyframe;
      if (x) {
        keyframe.x = x * screen.width;
      }
      if (y) {
        keyframe.y = y * screen.height;
      }
      if (width) {
        keyframe.width = width * screen.width;
      }
      if (height) {
        keyframe.height = height * screen.height;
      }
      if (rotate) {
        keyframe.rotate = rotate * pi;
      }
    }
  }

  return conf;
}

export async function validMaterial(config) {
  const primaryTrack = config.children[0];
  const proList = [];
  for (const i of primaryTrack.children) {
    const node = i.children[0];  
    if (!node.active) continue;
    if (node.src.startsWith("https://player.vimeo.com/")) {
      proList.push(new Promise((resolve) => {
        axios.head(node.src)
          .catch((e) => {
            console.log(e);
            node.active = false;
          })
          .finally(resolve);
      }));
    }
  }
  await Promise.all(proList);
  return config;
}