<template>
  <div class="h-full w-full relative edit-render" @mousemove="handleShowActionBar" @mouseleave="handleHiddenActionBar">
    <div :class="{ 'border border-borderColor min-h-full': !isLoaded }" v-loading="!isLoaded">

      <bv-player ref="playerRef" :src="src" :loop="loop" :autoplay="autoplay" :editAssets="editAssets"
        @onReady="handleAnimReady" @onActive="handleAnimActive" @onIdle="handleAnimIdle"
        @onEnterFrame="handleAnimEnterFrame" />

      <!-- <div class="resizer-box" v-if="!isPlaying && assetType == 1">
        <bv-draggable-container>
          <bv-draggable-resizable classNameDraggable="dragger" :lockAspectRatio="true" :active="isResizing && !isPlaying"
            :min-w="resizerMinW" :min-h="resizerMinH" :x="resizerX" :y="resizerY" :w="resizerW" :h="resizerH"
            :customStyle="{ transform: resizingRotateTransform }" :handles="['tl', 'tr', 'bl', 'br']"
            @resize-start="onResizeStart" @resizing="onResizing" @resize-end="onResizeEnd" @dragging="onDragging"
            @drag-end="onDragEnd" />
        </bv-draggable-container>
      </div> -->
    </div>
    <div className="rang-box absolute -bottom-[56] py-4 text-white">
      <div class="h-full w-full flex flex-col justify-between">
        <div class="flex justify-between items-center w-full px-4">
          <svg-icon :name="isPlaying ? 'icon_edit_pause' : 'icon_edit_play'"
            :style="{ width: '24px', height: '24px', color: '#fff' }" color="#fff" class="mr-3 cursor-pointer"
            @click="handlePlayClick"></svg-icon>

          <div class="text-sm mr-3" v-if="isLoaded">
            {{ currentTime }} / {{ totalTime }}
          </div>
          <div class="text-sm mr-3" v-else>0:00 / 0:00</div>

          <div class="flex-1 mr-3">
            <bv-slider v-model="currentFrame" :show-tooltip="false" :max="duration" :min="0" :colors="{
              main: '#fff',
              runway: 'rgba(255, 255, 255, 0.30)',
              track: '#fff',
              stop: '#535558',
              tooltip: '#1F2329',
            }" @input="handleSeekChange" />
          </div>
          <svg-icon :name="isMuted ? 'icon_edit_mute' : 'icon_edit_unmute'"
            :style="{ width: '20px', height: '20px', color: '#fff' }" color="#fff" class="cursor-pointer"
            @click="handleVolumeClick"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref, computed, onUnmounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import BvPlayer, { type AnimationItem } from "@/components/boolv-player";
import BvDraggableResizable, {
  VueDraggableContainer as BvDraggableContainer,
} from "@/components/common/bv-draggable-resizable";
import { useEditVideo } from "@/store/modules/editVideo";
import animationAPIFactory, { type AnimationAPI } from "../libs/edit-api";

export interface OffsetUpdateEventArgs {
  layerId: string;
  offsetData: EditOffsetData;
}

interface Emits {
  (e: "onOffsetUpdate", args: OffsetUpdateEventArgs): void;
  (e: "onLoaded", args: boolean): void;
  (e: "onPlaying", args: boolean): void;
}

interface Props {
  src: string;
  assetType: number;
  autoplay?: boolean;
  loop?: boolean;
  editAssets?: EditableAssetItem[];
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const editVideoStore = useEditVideo();
const { fps, duration } = storeToRefs(editVideoStore);

const isLoaded = ref(false);
const currentFrame = ref(0);

const resizerX = ref(0);
const resizerY = ref(0);
const resizerW = ref(0);
const resizerH = ref(0);
const isResizing = ref(false);
const centerX = ref(0);
const centerY = ref(0);

const resizingLayerId: Ref<string | null> = ref(null);
const resizingLayerOriginRect: Ref<Rect | null> = ref(null);
const resizingRotateTransform: Ref<String | null> = ref(null);

const animation: Ref<AnimationItem | null> = ref(null);
const animationApi: Ref<AnimationAPI | null> = ref(null);
const playerRef: Ref<typeof BvPlayer | null> = ref(null);

const animationData: Ref<any> = ref(null);
const timer: any = ref(null);

const showRange = ref(false);
const isPlaying = ref(false);
const isMuted = ref(false);

const currentTime = computed(() => {
  return "00:" + Math.ceil(currentFrame.value / fps.value);
});
const totalTime = computed(() => {
  return "00:" + Math.floor(duration.value / fps.value);
});

const resizerMinW = computed(() => {
  // 限制缩放的最小宽度为原始宽度的10%
  return (resizingLayerOriginRect.value?.width || 0) * 0.1;
});

const resizerMinH = computed(() => {
  // 限制缩放的最小高度为原始高度的10%
  return (resizingLayerOriginRect.value?.height || 0) * 0.1;
});

const parseConfig = (animationData: any) => {
  editVideoStore.initEditVideo(animationData);
};

const handleAnimReady = (animItem: AnimationItem) => {
  isLoaded.value = true;
  animation.value = animItem;
  animationApi.value = animationAPIFactory.createAnimationApi(animItem);
  animationData.value = playerRef.value?.getConfig();
  parseConfig(animationData.value);
  emits("onLoaded", true);
};

const handleAnimActive = () => {
  isPlaying.value = true;
  emits("onPlaying", isPlaying.value);
  hideLayerResizerBox();
};

const handleAnimIdle = () => {
  isPlaying.value = false;
  emits("onPlaying", isPlaying.value);
};

const handleAnimEnterFrame = (frame: number) => {
  currentFrame.value = frame;
};

const handleVolumeClick = () => {
  isMuted.value = !isMuted.value;

  if (isMuted.value) {
    playerRef.value?.mute();
  } else {
    playerRef.value?.unmute();
  }
};

const handleSeekChange = (frameNum: number) => {
  if (!isLoaded.value) {
    return;
  }
  playerRef.value?.goToAndStop(frameNum, true);
};

const handlePlayClick = () => {
  isPlaying.value = !isPlaying.value;
  emits("onPlaying", isPlaying.value);
  if (isPlaying.value) {
    playerRef.value?.play();
  } else {
    playerRef.value?.pause();
  }
};

const handleShowActionBar = () => {
  showRange.value = true;
};

const handleHiddenActionBar = () => {
  showRange.value = false;
};

const onDragging = ({ x, y }: { x: number; y: number }) => {
  resizerX.value = x;
  resizerY.value = y;
  updateOffsetData();
};

const onDragEnd = () => {
  updateOffsetData(true);
};

const onResizeStart = (event: {
  x: number;
  y: number;
  w: number;
  h: number;
}) => {
  centerX.value = event.x + event.w / 2;
  centerY.value = event.y + event.h / 2;
};

const onResizing = (event: { x: number; y: number; w: number; h: number }) => {
  const newX = centerX.value - event.w / 2;
  const newY = centerY.value - event.h / 2;
  const newWidth = event.w;
  const newHeight = event.h;

  resizerX.value = newX;
  resizerY.value = newY;
  resizerW.value = newWidth;
  resizerH.value = newHeight;

  updateOffsetData();
};

const onResizeEnd = () => {
  updateOffsetData(true);
};

const showLayerResizerBox = (
  layerId: string,
  originRect: Rect,
  offsetRect: Rect,
  rotateTransform: string,
) => {
  isResizing.value = true;
  resizingLayerId.value = layerId;
  resizingLayerOriginRect.value = originRect;
  resizingRotateTransform.value = rotateTransform;
  initResizerBoxRect(offsetRect);
};

const hideLayerResizerBox = () => {
  isResizing.value = false;
  resizingLayerId.value = null;
  resizingLayerOriginRect.value = null;
};

const initResizerBoxRect = (srcRect: Rect) => {
  resizerX.value = srcRect?.x || 0;
  resizerY.value = srcRect?.y || 0;
  resizerW.value = srcRect?.width;
  resizerH.value = srcRect?.height;
};

const fixedFloatNumber = (val: number): number => {
  return parseFloat(val.toFixed(2));
};

const getActiveLayerRect = (): Rect | null => {
  const layerNode = document.querySelector(".layer-active");
  const renderNode = document.querySelector(".edit-render");
  if (!layerNode || !renderNode) {
    return null;
  }

  // 获取node1和node2元素的位置和大小信息
  const rect1 = layerNode.getBoundingClientRect();
  const rect2 = renderNode.getBoundingClientRect();

  // 计算node1相对于node2的位置和大小信息
  const relativeRect: Rect = {
    x: fixedFloatNumber(rect1.left - rect2.left),
    y: fixedFloatNumber(rect1.top - rect2.top),
    width: fixedFloatNumber(rect1.width),
    height: fixedFloatNumber(rect1.height),
  };
  return relativeRect;
};

const updateOffsetData = (notify: boolean = false) => {
  if (
    isResizing.value &&
    resizingLayerId.value &&
    resizingLayerOriginRect.value &&
    animationApi.value
  ) {
    const layerId = resizingLayerId.value;
    const layerOriginRect = resizingLayerOriginRect.value;
    // 计算偏移数据
    let offsetData = {
      x: fixedFloatNumber(resizerX.value - layerOriginRect.x),
      y: fixedFloatNumber(resizerY.value - layerOriginRect.y),
      scaleX: fixedFloatNumber(resizerW.value / layerOriginRect.width),
      scaleY: fixedFloatNumber(resizerH.value / layerOriginRect.height),
    };
    // 还原真实值
    offsetData = animationApi.value.computeOffsetData(layerId, {
      ...offsetData,
    });
    // 应用偏移值到动画中
    animationApi.value.updateOffsetData(layerId, offsetData);
    // 发送事件通知外部
    if (notify) {
      const eventArgs = { layerId, offsetData: { ...offsetData } };
      emits("onOffsetUpdate", eventArgs);
    }
  }
};

/* 对外暴露的方法 */

const makeLayerEditable = (assetItem: EditableAssetItem) => {
  if (animationApi.value) {
    const { positioningFrame, layerId, offsetData, imageData } = assetItem;
    // 定位动画到指定帧
    animation.value?.goToAndStop(positioningFrame, true);
    // 若之前有过偏移数据，需要先提前应用上
    if (offsetData) {
      animationApi.value.updateOffsetData(layerId, { ...offsetData });
    } else {
      animationApi.value.updateOffsetData(layerId, {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
      });
    }
    if (imageData) {
      animationApi.value.updateImageData(layerId, imageData);
    }
    // 给指定layer标记active状态
    // animation.value?.markLayerActive(layerId);
    // 获取标记为active的layer在动画容器中的位置大小，经过偏移后的区域
    // const layerOffsetRect = getActiveLayerRect();
    // if (!layerOffsetRect) {
    //   return;
    // }
    const layerOffsetRect = animationApi.value.getLayerRectById(layerId, true);
    // // 获取指定layer偏移前的原始区域
    const layerOriginRect = animationApi.value.getLayerRectById(layerId);

    const rotateTransform = animationApi.value.getLayerRotateById(layerId);
    // // 显示拖拽拉伸框
    showLayerResizerBox(
      layerId,
      layerOriginRect,
      layerOffsetRect,
      rotateTransform,
    );
  }
};

const resizeLayerByZoom = (zoom: number) => {
  const originRect = resizingLayerOriginRect.value;
  if (originRect) {
    const scale = fixedFloatNumber(zoom / 100);

    centerX.value = resizerX.value + resizerW.value / 2;
    centerY.value = resizerY.value + resizerH.value / 2;

    const newWidth = originRect.width * scale;
    const newHeight = originRect.height * scale;
    const newX = centerX.value - newWidth / 2;
    const newY = centerY.value - newHeight / 2;

    resizerX.value = newX;
    resizerY.value = newY;
    resizerW.value = newWidth;
    resizerH.value = newHeight;

    updateOffsetData(true);
  }
};

const updateDocumentData = (assetItem: EditableAssetItem) => {
  if (animationApi.value) {
    const { layerId, fillColor, fontSize, material } = assetItem;
    const documentData = {
      fillColor: fillColor,
      fontSize: fontSize,
      material: material,
    };
    animationApi.value.updateDocumentData(layerId, documentData);
  }
};

// 此方法会重新渲染当前帧
const goToAndStop = (frame: number, isFrame: boolean) => {
  animation.value?.goToAndStop(frame, isFrame);
};

const destroy = () => {
  playerRef.value?.stop();
  playerRef.value?.destroy();
};

const pause = () => {
  playerRef.value?.pause();
}

const replay = () => {
  playerRef.value?.replay();
}

onUnmounted(() => {
  clearInterval(timer.value);
  timer.value = null;
});

onBeforeUnmount(() => {
  destroy();
});

defineExpose({
  makeLayerEditable,
  resizeLayerByZoom,
  updateDocumentData,
  goToAndStop,
  hideLayerResizerBox,
  destroy,
  replay,
  pause
});
</script>
<style lang="scss">
.edit-render {
  svg {
    border-radius: 0 !important;
  }
}

.resizer-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.vdr-container.active {
  border-color: #875eff;
  border-width: 2px;
}

.vdr-container .vdr-handle {
  background: #875eff !important;
  border: 1px solid #fff !important;
  border-radius: 5px;
  width: 10px;
  height: 10px;
}

.dragger.active {
  cursor: move;
}

.edit-render .rang-mask {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 56px;
}

.edit-render .rang-box {
  width: 100%;
  height: 56px;
  font-size: 14px;
  background-color: #151515;
}
</style>

<style lang="scss" scoped>
:deep(.el-slider__bar) {
  border-radius: 10px;
  height: 4px;
}

:deep(.el-slider__runway) {
  border-radius: 10px;
  height: 4px;
}

:deep(.el-slider__button) {
  width: 14px;
}

:deep(.el-loading-spinner .path) {
  stroke: #646a73 !important;
}
</style>
