<script setup>
import Player from "../player/player.vue";
import Attrs from "../attrs/attrs.vue";
import { useCreatorStore } from "../../stores";

const { creator } = useCreatorStore();
</script>
<template>
  <div class="spane-container">
    <div class="player-wrapper">
      <Player />
    </div>
    <div class="attrs-wrapper">
      <Attrs v-if="creator" />
    </div>
  </div>
</template>
<style scoped>
.spane-container {
  height: 100%;
  display: flex;
}
.player-wrapper {
  flex: 1 1;
  overflow: hidden;
}
.attrs-wrapper {
  flex: 0 0 262px;
  z-index: 1;
}
</style>
