<template>
  <el-slider
    :class="['bv-slider', elSliderProps.showStops ? 'show-stops' : '']"
    v-bind="elSliderProps"
    :style="sliderStyle"
    :tooltipClass="
      concatClassNames(tooltipClass || '', ['bv-slider-tooltip', rcTooltip])
    "
    @update:modelValue="$emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { computed, toRefs, reactive, watch } from "vue";
import { sliderProps } from "element-plus";
import { concatClassNames, randomClassName, useStyle } from "@/utils/dom";
import {
  createDefaultValueProxy,
  createGroupProxy,
  createRef,
} from "@/utils/type";
import type { PropType } from "vue";

defineEmits(["update:modelValue"]);

const props = defineProps({
  ...sliderProps,
  colors: {
    type: Object as PropType<
      Partial<Record<"main" | "runway" | "track" | "stop" | "tooltip", string>>
    >,
    default: {},
  },
});

const { customProps, rest: elSliderProps } = createGroupProxy(props, {
  customProps: ["colors", "size", "tooltipClass"],
});

const rcTooltip = randomClassName();
const styleRule = useStyle();
const usedColors = createDefaultValueProxy(createRef(customProps, "colors"), {
  main: "#646A73",
  runway: "#E8E9EC",
  track: "#646A73",
  stop: "#535558",
  tooltip: "#1F2329",
});

const sliderStyle = computed(() => ({
  "--slider-main-color": usedColors.main,
  "--slider-runway-color": usedColors.runway,
  "--slider-track-color": usedColors.track || usedColors.main,
  "--slider-stop-color": usedColors.stop,
}));

const tooltipStyle = styleRule.insertStyle(`.${rcTooltip}`, {
  "--el-text-color-primary": usedColors.tooltip,
});

watch(
  () => usedColors.tooltip,
  (color: string) => {
    tooltipStyle.setProperty("--el-text-color-primary", color);
  },
);
</script>

<style lang="scss" scoped>
.bv-slider {
  --el-slider-border-radius: 0;
  --el-slider-height: 2px;
  --el-slider-button-size: 12px;
  --el-slider-button-wrapper-size: 12px;

  height: auto;
  min-height: var(--el-slider-button-wrapper-size);

  &.is-vertical {
    :deep(.el-slider__button-wrapper) {
      top: auto;
      left: 50%;
      transform: translate(-50%, 50%);
    }

    :deep(.el-slider__stop),
    :deep(> .el-slider__runway::before),
    :deep(> .el-slider__runway::after) {
      top: auto;
      left: 50%;
      width: 6px;
      height: 1px;
    }

    :deep(> .el-slider__runway::before) {
      top: 0;
    }

    :deep(> .el-slider__runway::after) {
      bottom: 0;
      transform: translate(-50%, 50%);
    }
  }

  :deep(> .el-slider__runway) {
    background-color: var(--slider-runway-color);

    &.is-disabled {
      background-color: #d9d9d9;

      & > .el-slider__bar {
        background-color: #d9d9d9;
      }

      & .el-slider__button {
        border-color: #d9d9d9;
      }
    }

    &::before,
    &::after {
      content: "";
      display: none;
      position: absolute;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  :deep(.el-slider__bar) {
    background-color: var(--slider-track-color);
  }

  :deep(.el-slider__button) {
    border-width: 1px;
    border-color: var(--slider-main-color);
    transform: scale(1);
  }

  :deep(.el-slider__button-wrapper) {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  :deep(.el-slider__stop),
  :deep(> .el-slider__runway::before),
  :deep(> .el-slider__runway::after) {
    top: 50%;
    width: 1px;
    height: 6px;
    transform: translate(-50%, -50%);
    background-color: var(--slider-stop-color);
    border-radius: 0;
  }

  :deep(> .el-slider__runway::after) {
    transform: translate(50%, -50%);
  }
}

.bv-slider.show-stops {
  :deep(> .el-slider__runway) {
    &::before,
    &::after {
      display: block;
    }
  }
}
</style>

<style lang="scss">
.bv-slider-tooltip {
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);

  &.el-popper > .el-popper__arrow::before {
    border-radius: 0;
  }
}
</style>
