type Args = {
  val: number;
  max: number;
  min?: number;
  itemsToShow?: number;
};

export function mapNumberToRange({
  val,
  max,
  min = 0,
  itemsToShow = 1,
}: Args): number {
  // if (val > max) {
  //   return mapNumberToRange({ val: val - (max + 1), max, min })
  // }
  // if (val < min) {
  //   return mapNumberToRange({ val: val + (max + 1), max, min })
  // }
  const page = Math.ceil(val / itemsToShow);
  return page;
}
