<template>
  <div class="tool-list-container">
    <!-- <p class="subtitle">{{ subtitle }}</p> -->
    <ul class="tool-list">
      <li v-for="(tool, index) in toolList">
        <ToolCard :tool="tool" :key="tool.name" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import ToolCard from "./tool-card.vue";
import type { Tool } from "../../type";

const props = defineProps({
  toolList: {
    type: Array<Tool>,
    required: true,
  },
});

const online = props.toolList[0].online;
const type = props.toolList[0].type;
const subtitle = computed(() => {
  if (online) {
    return subtitleMap[type];
  } else {
    return "More tools,vote which you like";
  }
});
const subtitleMap = {
  image: "Image Tools",
  copywriting: "Copywriting Tools",
  video: "Video Tools",
};
</script>

<style lang="scss" scoped>
.tool-list {
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  gap: 60px;
}

.subtitle {
  margin-bottom: 46px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
</style>
