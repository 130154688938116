<template>
  <div id="player">

  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
#player {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}
</style>