import { ref, computed } from "vue";
import type { BrandInfo } from "../type";
import {
  getBrandList,
  updateBrandList,
  addBrand,
  deleteBrand,
} from "@/api/brand";
import { useSingleMessage } from "@/utils";
import modalInstance from "@/components/common/custom-modal/instance";

const brandList: Ref<BrandInfo[]> = ref([]);
const activeBrandId: Ref<string | null> = ref("");
const activeBrand = computed(() => {
  if (activeBrandId.value) {
    return getBrandById(activeBrandId.value);
  } else {
    return null;
  }
});

const getList = async () => {
  const { data, code } = await getBrandList();
  if (code === 0) {
    brandList.value = data;
  }
};

const updateBrand = async (brand: BrandInfo) => {
  brandList.value = brandList.value.map((item) => {
    if (brand.bkId === item.bkId) {
      item = brand;
    }
    return item;
  });
  updateBrandList(brand);
};

const setDefaultBrand = async (id: string) => {
  brandList.value = brandList.value.map((item) => {
    item.defaultUse = false;
    if (id === item.bkId) {
      item.defaultUse = true;
    }
    return item;
  });
  const brand = brandList.value.find((item) => item.bkId === id);
  if (brand) updateBrandList(brand);
};

const addNewBrand = async (url: string) => {
  const { code, msg } = await addBrand({
    coverPic: url,
  });
  if (code === 0) {
    await getList();
  } else if (code === 60001) {
    modalInstance.modalManager!.applyTemplate("upgradeTips", { msg, code });
  } else {
    const Message = useSingleMessage();
    Message.error(msg, { duration: 2000 });
  }
};

const removeBrand = async (id: string) => {
  await deleteBrand({
    bkId: id,
  });
  brandList.value = brandList.value.filter((item) => id !== item.bkId);
};

const getBrandById = (id: string) => {
  return brandList.value.find((item) => item.bkId === id);
};

const setActiveBrand = (id: string) => {
  activeBrandId.value = id || null;
};

export const useBrandStore = () => ({
  brandList,
  getList,
  updateBrand,
  addNewBrand,
  removeBrand,
  setDefaultBrand,
  getBrandById,
  activeBrand,
  setActiveBrand,
});
