import { EventListener } from "@/utils/common";
import { usePointerMove, type MoveEvent } from "@/utils/hook";
import type { VNode } from "vue";

interface Point {
  x: number;
  y: number;
}

interface BrushConfig {
  type: string;
  size: number;
  color: string;
  opacity: number;
  compositeOperation: string;
}

interface DrawboardConfig {
  // 画板宽高
  abilities: {
    editable: boolean;
    scalable: boolean;
    // rotatable: boolean;
    // recordable: boolean;
    // undoable: boolean;
    // redoable: boolean;
  };
  brush: BrushConfig;
  board: {
    x: number;
    y: number;
    width: number;
    height: number;
    scale: number;
    // rotate: number;
  };
}

export interface ExportConfig {
  type?: string;
  quality?: number;
}

export interface ResizeEvent {
  width: number;
  height: number;
}

type DrawboardListeners = {
  resize: ((size: ResizeEvent) => void)[];
  draw: ((e: MoveEvent) => void)[];
  updated: (() => void)[];
  beforeUpdate: (() => void)[];
};

class DrawRecord {
  config: BrushConfig;
  points: Point[];
  _isEnd = false;

  constructor(config: BrushConfig, points: Point[] = []) {
    this.config = config;
    this.points = points;
  }

  addPoint(point: Point) {
    this.points.push(point);
  }

  end(point?: Point) {
    if (point !== undefined) {
      this.addPoint(point);
    }

    this._isEnd = true;
  }

  get isEnd() {
    return this._isEnd;
  }
}

export default class Drawboard extends EventListener<DrawboardListeners> {
  private _record?: DrawRecord;
  private _canvas: HTMLCanvasElement | null = null;
  private _context: CanvasRenderingContext2D | null = null;
  config: DrawboardConfig;
  vnode: VNode;
  drawRecords: DrawRecord[] = [];

  constructor(config: DeepPartial<DrawboardConfig> = {}) {
    // 初始化事件监听
    super({
      resize: [],
      draw: [],
      updated: [],
      beforeUpdate: [],
    });

    // 初始化画板配置
    this.config = reactive({
      abilities: {
        editable: true,
        scalable: true,
      },
      brush: {
        type: "brush",
        size: 5,
        opacity: 1,
        color: "#000",
        compositeOperation: "source-over",
      },
      board: {
        x: 0,
        y: 0,
        width: 400,
        height: 400,
        scale: 1,
      },
    });

    Object.assign(this.config.abilities, config.abilities);
    Object.assign(this.config.brush, config.brush);
    Object.assign(this.config.board, config.board);

    // 创建画板节点
    this.vnode = h("canvas", {
      onVnodeMounted: (vnode) => {
        this.vnode = vnode;
        this._canvas = vnode.el as HTMLCanvasElement;
        this._context = this._canvas.getContext("2d")!;
        this._initCanvas();
      },
      onVnodeBeforeUnmount: () => {
        this._canvas = null;
        this._context = null;
      },
    });
  }

  get canvas() {
    return this._canvas;
  }

  get context() {
    return this._context;
  }

  get currentRecord() {
    return this._record;
  }

  // 初始化画布
  private _initCanvas() {
    this._canvas!.width = this.config.board.width;
    this._canvas!.height = this.config.board.height;

    usePointerMove({ handler: this._drawHandle.bind(this) }).ref.value =
      this._canvas;
  }

  // 调整画布大小
  resize(size: [number, number]) {
    if (this._canvas === null) return;

    this._canvas.width = this.config.board.width = size[0];
    this._canvas.height = this.config.board.height = size[1];

    this.update();
    this.emit("resize", {
      width: this.config.board.width,
      height: this.config.board.height,
    });
  }

  // 监听鼠标移动
  private _drawHandle(event: MoveEvent) {
    if (!this.config.abilities.editable) {
      event.abort();
      return;
    } else if (event.state === "start") {
      event.originalEvent.stopPropagation();
    }

    const scale = this.config.board.scale;
    const x = event.x / scale;
    const y = event.y / scale;

    if (this._record === undefined) {
      this._record = new DrawRecord(
        {
          ...this.config.brush,
          size: this.config.brush.size / this.config.board.scale,
        },
        [{ x, y }],
      );

      this.drawRecords.push(this._record);
    } else {
      this._record.addPoint({ x, y });
    }

    if (event.state === "end") {
      this._record.end();
      this._record = undefined;
    }

    this.emit("draw", event);
  }

  // 更新画布
  update() {
    const ctx = this.context;
    if (ctx === null) return;

    this.emit("beforeUpdate");
    ctx.clearRect(0, 0, this.config.board.width, this.config.board.height);

    for (const record of this.drawRecords) {
      this.draw(record);
    }

    this.emit("updated");
  }

  draw(record: DrawRecord) {
    const ctx = this.context;
    if (ctx === null || record.config.opacity === 0) return;

    ctx.save();
    ctx.beginPath();
    ctx.lineCap = ctx.lineJoin = "round";
    ctx.strokeStyle = record.config.color;
    ctx.lineWidth = record.config.size * 2;
    ctx.globalAlpha = record.config.opacity;

    for (const point of record.points) {
      ctx.lineTo(point.x, point.y);
    }

    ctx.stroke();
    ctx.restore();
  }

  clear() {
    if (this._context === null) return;

    this.drawRecords = [];
    this._record = undefined;
    this._context.clearRect(
      0,
      0,
      this.config.board.width,
      this.config.board.height,
    );
  }

  toOffscreen() {
    const output = document.createElement("canvas");

    output.width = this.config.board.width;
    output.height = this.config.board.height;
    output.getContext("2d")!.drawImage(this._canvas!, 0, 0);

    return output;
  }

  toImage(config: ExportConfig = {}) {
    const img = new Image(this.config.board.width, this.config.board.height);
    img.src = this.toOffscreen().toDataURL(config.type, config.quality);

    return img;
  }

  toFile(config: ExportConfig = {}) {
    return new Promise<File>((res) => {
      this._canvas!.toBlob(
        (blob) => {
          res(new File([blob!], "export"));
        },
        config.type,
        config.quality,
      );
    });
  }
}
