<template>
  <div class="previewer image-previewer">
    <div class="preview-box">
      <img
        class="previewer"
        no-close
        :draggable="false"
        :ref="pointerMove.ref"
        :src="record.preview1080Url"
        :style="{
          transform: `scale(${state.scale}) rotate(${state.rotate}deg) translate(${state.left}px, ${state.top}px)`,
        }"
        @transitionend="handleTransitionEnd"
      />
    </div>
    <tool-bar
      no-close
      @scale="handleScale"
      @reset="handleReset"
      @rotate="handleRotate"
    />
  </div>
</template>

<script setup lang="ts">
import ToolBar from "./tool-bar.vue";
import { usePointerMove } from "@/utils";

defineProps({
  record: {
    type: Object as PropType<SpaceResData.MaterialLibDetailVO>,
    required: true,
  },
});

const initState = {
  top: 0,
  left: 0,
  scale: 1,
  rotate: 0,
};

const state = reactive({ ...initState });
const pointerMove = usePointerMove({
  handler(e) {
    let { deltaX, deltaY } = e;
    const rotate = ((state.rotate % 360) + 360) % 360;

    if (rotate === 90) {
      [deltaX, deltaY] = [deltaY, -deltaX];
    } else if (rotate === 270) {
      [deltaX, deltaY] = [-deltaY, deltaX];
    } else if (rotate === 180) {
      [deltaX, deltaY] = [-deltaX, -deltaY];
    }

    state.top += deltaY / state.scale;
    state.left += deltaX / state.scale;
  },
});

function handleScale(delta: number) {
  state.scale = Math.max(Math.min(state.scale + delta, 3), 0.5);
}

function handleRotate(delta: number) {
  state.rotate += delta;
  (pointerMove.ref.value as HTMLElement).style.transition = "transform .2s";
}

function handleReset() {
  Object.assign(state, initState);
}

function handleMouseWheel(e: WheelEvent) {
  handleScale(-Math.sign(e.deltaY) * 0.1);
  e.stopPropagation();
}

function handleTransitionEnd() {
  (pointerMove.ref.value as HTMLElement).style.transition = "";
}

onMounted(() => {
  window.addEventListener("wheel", handleMouseWheel);
});

onBeforeMount(() => {
  window.removeEventListener("wheel", handleMouseWheel);
});

defineExpose({
  reset: handleReset,
  scale: handleScale,
  rotate: handleRotate,
});
</script>

<style lang="scss" scoped>
.image-previewer {
  height: 100%;
}

.preview-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: calc(100% - 74px);
  padding-block: 24px;

  & > img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    will-change: transform;
    cursor: grab;
  }
}
</style>
