import { _get } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

interface ListParams {
  page: number;
  size: number;
}

interface GetMusicListParams extends ListParams {
  genre?: string;
}

export async function getMusicList(params: GetMusicListParams) {
  if (params.genre === "All") {
    delete params.genre;
  }
  return await _get(`${VITE_API_BASE}/library/audio`, params);
}

export async function getSoundEffectList(params: ListParams) {
  return await _get(`${VITE_API_BASE}/library/sound_effects`, params);
}

export async function getStickerList() {
  return await _get(`${VITE_API_BASE}/library/stickers`);
}

export async function getFilterList() {
  return await _get(`${VITE_API_BASE}/library/filters`);
}

export async function getEffectList() {
  return await _get(`${VITE_API_BASE}/library/effects`);
}

export async function getTransitionList() {
  return await _get(`${VITE_API_BASE}/library/transitions`);
}
