<template>
  <div class="fourth-part-container" id="roadmap">
    <div class="fourth-part-content">
      <p class="title text-42px">Roadmap</p>

      <p class="sub-title mt-5">AI director 2.0</p>
      <p class="content">
        The AI director will become more creative and even 'wild'. It will be
        able to generate more innovative and groundbreaking videos - November
        2023.
      </p>

      <p class="sub-title mt-34">High-quality cloud-based asset library</p>
      <p class="content">
        Includes empty shots, background images, and more stickers - December
        2023.
      </p>

      <p class="sub-title mt-34">AI director 3.0</p>
      <p class="content">
        Your AI Screen Writer will be launched. The AI will be able to generate
        customized video scripts based on user inputs - March 2024.
      </p>

      <p class="sub-title mt-34">Magic switch</p>
      <p class="content">
        Convert your video to any desired size - April 2024.
      </p>

      <p class="sub-title mt-34">AI copilot 1.0</p>
      <p class="content">
        Clearly state what you want the AI to help you achieve during the video
        editing process, and the AI will automatically edit your draft - June
        2024.
      </p>

      <p
        class="title text-42px"
        :style="{ marginBottom: '64px', paddingTop: '120px' }"
        id="testimonial"
      >
        Testimonial
      </p>
      <youtube-player
        src="https://www.youtube.com/watch?v=MLyzQIgDAHE&t=86s"
        class="yt-video"
        autoplay
        width="100%"
        height="100%"
        id="FnQC7b9iNGyOPAv63"
        @play="handlePlayTestimonialVideo"
        @pause="pause"
        ref="YTPlayerRef"
      ></youtube-player>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from "vue";
import YoutubePlayer from "./youtubePlayer.vue";

const emit = defineEmits(["playTestimonialVideo"]);
const YTPlayerRef = ref(null);
const isPlaying = ref(false);

const handlePlayTestimonialVideo = () => {
  isPlaying.value = true;
  emit("playTestimonialVideo");
};

const pause = () => {
  isPlaying.value = false;
  YTPlayerRef.value.pause();
};

const play = () => {
  isPlaying.value = true;
  YTPlayerRef.value.play();
};

const observeVideo = () => {
  // 目标元素
  const targetElement = document.getElementsByClassName("yt-video")[0];

  // 创建 Intersection Observer 实例
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 元素进入窗口内
        console.log("元素离开窗口2");
        if (!isPlaying.value) {
          play();
        }
      } else {
        // 元素离开窗口
        console.log("元素离开窗口2");
        if (isPlaying.value) {
          pause();
        }
      }
    });
  });

  // 开始观察目标元素
  observer.observe(targetElement);
};

onMounted(async () => {
  await nextTick();
  observeVideo();
});

defineExpose({ pause });
</script>

<style lang="scss">
.fourth-part-container {
  padding: 120px 50px 0px 50px;
  display: flex;
  justify-content: center;

  .fourth-part-content {
    width: 100%;
    max-width: 1024px;

    .sub-title {
      color: #060606;
      font-family: Inter-variant;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 211%;
    }

    .mt-34 {
      margin-top: 34px;
    }

    .content {
      color: #646a73;
      font-family: Inter-variant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 211%;
    }

    .yt-video {
      height: calc(var(--base-size) * 5.58);
      flex-shrink: 0;
      aspect-ratio: 1024/558;
    }
  }

  .title {
    color: #000;
    font-family: Inter-variant;
    font-style: normal;
    font-weight: 700;
    line-height: 15.368px;
    margin-bottom: 54px;
  }

  .content-box {
    padding-left: 22px;

    li {
      color: var(--text-t-1-primary, #060606);
      font-family: Inter-variant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 211%;
      list-style: disc;
    }
  }
}
</style>
