<script setup>
import { getTransitionList } from "@/api/resources";
import { useNetwork } from "@/composables";
import { useMessage } from "@/utils";
import Material from "./material.vue";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";

const { addTransitionNode } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { assertNetworkError } = useNetwork();
const { dragData } = useDrag();
const message = useMessage();

const hoverFile = ref(null);
const files = ref([]);
const loading = ref(false);

onMounted(getTransitions);

async function getTransitions() {
  loading.value = true;
  const { success, data } = await getTransitionList();

  if (success) {
    files.value = data;
  }
  loading.value = false;
}

async function addTransition(name) {
  assertNetworkError();
  const success = await addTransitionNode({ name }, undefined, { shouldRefresh: true });

  if (success) {
    commit();
    updateDraft();
  } else {
    message.warn("Transitions can only be added between 2 consecutive clips");
  }
}

function handleDrag(e, dragTarget, file) {
  const { name, id: sourceId } = file;
  const dragElement = dragTarget.cloneNode();

  Object.assign(dragElement.style, {
    width: "70px",
    height: "70px",
    borderRadius: "4px",
  });

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      type: "transition",
      name, 
      sourceId, 
    },
  });
}

function handleMouseDown(e) {
  const target = e.target.closest("[candrag]");

  if (target === null || e.button !== 0) return;
  const dragTarget = target.querySelector("[drag-target]");

  if (dragTarget === null) return;
  const file = files.value[dragTarget.dataset.index];

  const mouseUpListener = () => {
    addTransition(file.name);
    target.removeEventListener("mousemove", moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener("mouseup", mouseUpListener);
    handleDrag(e, dragTarget, file);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  target.addEventListener("mousemove", moveListener, { once: true });
  target.addEventListener("mouseup", mouseUpListener, { once: true });
}
</script>
<template>
  <Material title="Transitions">
    <el-scrollbar>
      <div class="material-content">
        <el-skeleton animated :loading="loading">
          <template #template>
            <div v-for="(_, i) in Array(8)" class="skeleton-item" :key="i">
              <el-skeleton-item variant="rect" />
              <el-skeleton-item variant="text" />
            </div>
          </template>
          <div class="file-list" @mousedown="handleMouseDown">
            <div 
              v-for="(file, i) in files"
              class="file-item"
              :key="i" 
              @mouseenter="hoverFile = file" 
              @mouseleave="hoverFile = null"
            >
              <div class="image-wrapper" candrag>
                <img
                  drag-target
                  loading="lazy"
                  draggable="false"
                  :src="hoverFile === file ? file.dynamicPic : file.coverPic"
                  :data-index="i"
                />
              </div>
              <div class="title">{{ file.name }}</div>
            </div>
          </div>
        </el-skeleton>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style scoped>
.material-content {
  margin-top: 22px;
}
.file-list,
:deep(.el-skeleton) {
  display: grid;
  flex-wrap: wrap;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
}
.file-item {
  width: 70px;
}
.skeleton-item {
  display: flex;
  flex-wrap: wrap;
}
.image-wrapper,
:deep(.el-skeleton__rect) {
  width: 70px;
  height: 70px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}
:deep(.el-skeleton__text) {
  margin: 4px 0;
  height: 12px;
}
.image-wrapper:hover {
  border-width: 2px;
  border-color: #875eff;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.title {
  padding: 0 2px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
</style>
