<template>
  <div class="w-full h-full">
    <common-upload ref="uploadRef" uploadUrl="" :uploadType="uploadType" :drag="uploadType == 'drag' || drag"
      :fileList="fileList" :handleSuccess="handleSuccess" :getFileListLength="getFileListLength"
      :handleError="handleError" :accept="accept" :draftId="draftId" :beforeUpload="beforeUpload" class="w-full h-full"
      :handleProgress="handleProgress" @onError="handleOnError" @onSuccess="handleOnSuccess" :videoNumber="videoNumber"
      :videoDuration="videoDuration" @uploadProcess="handleUploadProgress" @onBeforeUpload="handleOnBeforeUpload"
      @onStopUpload="handleStopUpload">
      <template #trigger>
        <slot name="trigger" v-if="slots['trigger']"></slot>

        <!-- bg-uploadBg -->
        <div v-else-if="uploadType == 'media'"
          class="cursor-pointer w-full h-full flex justify-center items-center hover:border-0 bg-uploadBg">
          <svg-icon name="icon_upload" style="width: 24px; height: 24px; color: #646a73" />
        </div>

        <!-- drag -->
        <div v-else-if="uploadType == 'drag' || drag"
          class="w-uploadWidth rounded-md h-uploadHeight upload-button flex justify-center items-center border hover:bg-cardBgHover">
          <div class="flex flex-col items-center justify-center">
            <slot name="icon" v-if="slots['icon']"></slot>
            <svg-icon name="icon_common_upload" :style="{ width: '46px', height: '58px', color: '#FFE68E' }"
              v-else></svg-icon>
            <p class="upload-tip">
              <span v-if="tips">{{ tips }}</span>
              <span v-else> {{
                "Click or drop " +
                (accept.includes(".mp4") ? "video" : "images") +
                " here to upload"
              }}</span>
            </p>
            <slot name="tip"> </slot>
          </div>
        </div>

        <!-- button -->
        <div v-else="uploadType == 'default' || !uploadType" class="w-56 h-9 upload-button">
          <el-button class="w-56 h-9">
            <svg-icon name="icon_upload" style="color: #1c1b1e" />
            <span class="ml-1.5">Upload media</span>
          </el-button>
        </div>
      </template>
    </common-upload>
  </div>
</template>
<script setup lang="ts">
import { useSlots, type PropType } from "vue";
import { CommonUpload, PrimaryButton } from "@/components/common/index";

const emits = defineEmits([
  "uploadProcess",
  "onSuccess",
  "onError",
  "onBeforeUpload",
  "onStopUpload",
]);
const slots = useSlots();
const props = defineProps({
  uploadType: String,
  draftId: String,
  tips: String,
  // 上传地址
  uploadUrl: {
    type: String,
    default: "",
  },
  // 上传的请求头部
  uploadHeaders: {
    type: Object,
    default: () => { },
  },
  // 上传时附带的额外参数
  uploadData: {
    type: Object,
    default: () => { },
  },
  // 是否支持多选文件
  multiple: {
    type: Boolean,
    default: true,
  },
  // 是否显示已上传文件列表
  showFileList: {
    type: Boolean,
    default: false,
  },
  // 已上传的文件列表
  fileList: {
    type: Array,
    default: () => [],
  },
  // 点击文件列表中已上传的文件时的回调函数
  handlePreview: {
    type: Function,
    default: () => { },
  },
  // 点击文件列表中已上传的文件的删除按钮时的回调函数
  handleRemove: {
    type: Function,
    default: () => { },
  },
  // 文件上传成功时的回调函数
  handleSuccess: {
    type: Function,
    default: () => { },
  },
  // 上传文件之前的钩子函数，返回 false 或 Promise.reject() 可以取消上传
  beforeUpload: {
    type: Function,
    default: () => { },
  },
  // 文件超出个数限制时的钩子函数
  handleExceed: {
    type: Function,
    default: () => { },
  },
  // 是否禁用上传功能
  disabled: {
    type: Boolean,
    default: false,
  },
  // 上传文件个数限制
  limit: {
    type: Number,
    default: 0,
  },
  // 接受上传的文件类型（详见 input 标签的 accept 属性）
  accept: {
    type: String,
    default: "",
  },
  // 是否启用拖拽上传
  drag: {
    type: Boolean,
    default: false,
  },
  httpRequest: {
    type: Function,
    default: null,
  },
  handleProgress: {
    type: Function,
    default: () => { },
  },
  handleError: {
    type: Function,
    default: () => { },
  },
  defaultFileList: {
    type: Function,
    default: () => { },
  },
  //
  disabledFileList: {
    type: Function,
    default: () => { },
  },
  // 上传icon
  uploadIcon: {
    type: String,
  },
  // 上传文案
  uploadText: {
    type: String,
  },
  tip: {},
  listType: {},
  autoUpload: {},
  getFileListLength: {
    type: Function,
    default: () => { },
  },
  handleUploadCompleted: {
    type: Function,
    default: () => { },
  },
  videoDuration: {
    type: Number,
    default: 0,
  },
  videoNumber: {
    type: Number,
    default: 0,
  },
});

type ImageType = {
  id: string | number;
  url?: string;
  status?: string;
  mid?: string;
  type?: string;
  upload?: boolean;
  selected?: boolean;
};

const uploadRef = ref(null);
const handleUploadProgress = (data: ImageType) => {
  emits("uploadProcess", data);
};

const handleOnSuccess = (data: ImageType) => {
  emits("onSuccess", data);
};

const handleOnError = (data: ImageType) => {
  emits("onError", data);
};

const handleOnBeforeUpload = (file: File) => {
  emits("onBeforeUpload", file);
};

const handleStopUpload = () => {
  emits("onStopUpload");
};

const handleUploadRetry = () => {
  // uploadRef.value?.uploadRetry();
};

const clearFileArr = () => {
  uploadRef.value?.clearFileArr();
};

const stopUpload = () => {
  uploadRef.value?.stopUpload();
};

defineExpose({
  handleUploadRetry,
  clearFileArr,
  stopUpload,
});
</script>

<style lang="scss" scoped>
.upload-button {
  :deep(.el-button) {
    color: #1c1b1e;
    font-family: Inter-variant;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    height: 36px;
    border-radius: 6px;
  }
}

.upload-tip {
  color: #444;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
}
</style>
