<template>
  <div>
    <el-upload
      ref="elUploadRef"
      :action="uploadUrl"
      :headers="uploadHeaders"
      :data="uploadData"
      :multiple="multiple"
      :show-file-list="showFileList"
      :file-list="innerFileList"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :before-upload="_beforeUpload"
      :on-exceed="handleExceed"
      :disabled="disabled"
      :limit="limit"
      :drag="drag"
      :list-type="listType"
      :auto-upload="autoUpload"
      :with-credentials="withCredentials"
      :http-request="customHttpRequest"
      :on-error="handleError"
      :default-file-list="defaultFileList"
      :disabled-file-list="disabledFileList"
      class="h-full w-full"
    >
      <slot name="trigger" v-if="slots['trigger']"></slot>
      <!-- 框 -->
      <slot name="tip" v-if="slots['tip']"></slot>
      <slot name="tipIcon" v-if="slots['tipIcon']"></slot>
    </el-upload>
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, useSlots, onMounted } from "vue";
import { Uppy } from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { useRouter } from "vue-router";
import {
  fileUploadPre,
  fileUploadState,
  getListParts,
  completeMultipartUpload,
  deleteMultipartUpload,
} from "@/api/upload";
import { getSuffix, getImageType } from "@/utils/index";
import modalInstance from "@/components/common/custom-modal/instance";
import SubscribeDialog from "@/layout/components/workspace/subscribeDialog/index.vue";
import {
  useSingleMessage,
  FileType,
  useTimedTask,
  type AcceptType,
} from "@/utils";
import type {
  UploadEvent,
  UploadFailEvent,
  UploadProgressEvent,
  UploadSuccessEvent,
  FileReceivedEvent,
} from "./type";
import type { PropType } from "vue";
import type { UploadInstance } from "element-plus";
import { useTrackStore } from "@/store/modules/track";

interface PreUploadParams {
  suffix: string;
  fileType: string;
  draftId: string | number;
  isLogo: boolean;
  partCount?: number;
  multipartUpload?: boolean;
}

interface Timer {
  mid: string;
  rounds: number;
  fn: () => void;
}

const { track, collectData } = useTrackStore();
const slots = useSlots();
const emits = defineEmits([
  "uploadProcess",
  "onSuccess",
  "onError",
  "onBeforeUpload",
  "onStopUpload",
  "onPreprocessingError",
]);
const props = defineProps({
  uploadType: String,
  draftId: {
    type: String,
  },
  tips: String,
  isLogo: {
    type: Boolean,
    default: false,
  },
  // 上传地址
  uploadUrl: {
    type: String,
    default: "",
  },
  // 上传的请求头部
  uploadHeaders: {
    type: Object,
    default: () => {},
  },
  // 上传时附带的额外参数
  uploadData: {
    type: Object,
    default: () => {},
  },
  // 是否支持多选文件
  multiple: {
    type: Boolean,
    default: true,
  },
  withCredentials: Boolean,
  // 是否显示已上传文件列表
  showFileList: {
    type: Boolean,
    default: false,
  },
  // 已上传的文件列表
  fileList: {
    type: Array,
    default: () => [],
  },
  // 点击文件列表中已上传的文件时的回调函数
  handlePreview: {
    type: Function,
    default: () => {},
  },
  // 点击文件列表中已上传的文件的删除按钮时的回调函数
  handleRemove: {
    type: Function,
    default: () => {},
  },
  // 文件上传成功时的回调函数
  handleSuccess: {
    type: Function as PropType<(e: UploadSuccessEvent) => void>,
    default: () => {},
  },
  // 上传文件之前的钩子函数，返回 false 或 Promise.reject() 可以取消上传
  beforeUpload: {
    type: Function as PropType<(e: UploadEvent) => boolean>,
    default: () => true,
  },
  // 文件超出个数限制时的钩子函数
  handleExceed: {
    type: Function,
    default: () => {},
  },
  // 是否禁用上传功能
  disabled: {
    type: Boolean,
    default: false,
  },
  // 上传文件个数限制
  limit: {
    type: Number,
    default: 0,
  },
  // 接受上传的文件类型（详见 input 标签的 accept 属性）
  accept: {
    type: [String, Array, Object] as PropType<
      string | AcceptType | AcceptType[]
    >,
    default: "",
  },
  // 是否启用拖拽上传
  drag: {
    type: Boolean,
    default: false,
  },
  customUploadRequest: {
    type: Function,
    default: null,
  },
  handleFileReceived: {
    type: Function as PropType<(e: FileReceivedEvent) => void>,
    default: () => {},
  },
  handleProgress: {
    type: Function as PropType<(e: UploadProgressEvent) => void>,
    default: () => {},
  },
  handleError: {
    type: Function as PropType<(e: UploadFailEvent) => void>,
    default: () => {},
  },
  handlePreprocessingError: {
    type: Function as PropType<(e: UploadFailEvent) => void>,
    default: () => {},
  },
  defaultFileList: {
    type: Function,
    default: () => {},
  },
  //
  disabledFileList: {
    type: Function,
    default: () => {},
  },
  // 上传icon
  uploadIcon: {
    type: String,
  },
  // 上传文案
  uploadText: {
    type: String,
  },
  tip: {},
  listType: {},
  autoUpload: {},
  handleUploadCompleted: {
    type: Function,
    default: () => {},
  },
  getFileListLength: {
    type: Function,
    default: () => {},
  },
  videoDuration: {
    type: Number,
    default: 0,
  },
  videoNumber: {
    type: Number,
    default: 0,
  },
});

const uppy = ref<any>(null);
const timers = reactive(new Map());
// 上传的文件数组
const fileListArr = ref<File[]>([]);
const innerFileList = ref([]);
const timerMap = new Map<string, Timer>();
const elUploadRef = ref(null as unknown as UploadInstance);
const router = useRouter();
const fileMap = ref(new Map<number, any>());
const fileType = new FileType(props.accept);

const Message = useSingleMessage();
const videoLength = ref(0);
const checkFileList = ref<Boolean[]>([]);
const uploadList = ref<UploadEvent[]>([]);
const timer = ref(null);
const subscribeModalVisible = ref(false);

const timedTask = useTimedTask(() => {
  let flag = true;
  for (const item of timerMap.values()) {
    item.rounds++;

    // 前三秒时，每隔1s判断一次状态；3s后，每隔3s判断一次状态
    if (item.rounds < 3 || item.rounds % 3 === 0) {
      item.fn();
    }

    flag = false;
  }

  flag && timedTask.stop();
}, 1000);

onMounted(() => {
  uppy.value = new (Uppy as any)({
    autoProceed: true,
  }).use(AwsS3, {
    id: "AWSPlugin",
    shouldUseMultipart(file: any) {
      return file.size > 10 * 1024 * 1024;
    },
    async getUploadParameters(file: any, { signal }: any) {
      const data = await getSignedURL(file, signal);
      return { url: data.preSignUrl, method: "PUT" };
    },
    async createMultipartUpload(file: any, signal: any) {
      signal?.throwIfAborted();

      const minPartSize = 5 * 1024 * 1024;
      const maxPartCount = 10000;
      const chunkSize = Math.max(Math.ceil(file.size / 10000), minPartSize);
      const partCount = Math.min(
        Math.ceil(file.size / chunkSize),
        maxPartCount
      );
      const data = await getSignedURL(file, signal, {
        partCount,
        multipartUpload: true,
      });
      return data;
    },
    async abortMultipartUpload(file: any, { key, uploadId, signal }: any) {
      const response = await deleteMultipartUpload(
        encodeURIComponent(uploadId),
        { key: encodeURIComponent(key) },
        signal
      );
      return response.data;
    },
    signPart(file: any, { partNumber, signal }: any) {
      signal?.throwIfAborted();

      for (const presigned of file.presignedUrls) {
        if (partNumber === presigned.partNumber) {
          return presigned;
        }
      }
    },
    async listParts(file: any, { key, uploadId }: any, signal: any) {
      signal?.throwIfAborted();

      const response = await getListParts(
        encodeURIComponent(uploadId),
        {
          key: encodeURIComponent(key),
        },
        signal
      );
      return response.data;
    },
    async completeMultipartUpload(
      file: any,
      { key, uploadId, parts }: any,
      signal: any
    ) {
      signal?.throwIfAborted();

      const newParts = [];

      for (const part of parts) {
        newParts.push({
          PartNumber: part.PartNumber,
          ETag: part.ETag.replace(/^"|"$/g, ""),
        });
      }
      const response = await completeMultipartUpload(
        encodeURIComponent(uploadId),
        encodeURIComponent(key),
        {
          parts: newParts,
        },
        signal
      );
      return response.data;
    },
  });
  uppy.value.on("files-added", async (files: any) => {
    for (const file of files) {
      file.uid = file.data.uid;
      fileMap.value.set(file.uid, file);
    }
  });
  uppy.value.on(
    "upload-progress",
    (file: any, { bytesUploaded, bytesTotal }: any) => {
      const complete = ((bytesUploaded / bytesTotal) * 100) | 0;
      const percent = complete > 1 ? complete - 10 : complete;
      const event: UploadProgressEvent = {
        ...file,
        percent,
        state: 2,
        url: "",
      };
      // state: 0-success 1-failed 2-ing
      emits("uploadProcess", event);
      props.handleProgress(event);
    }
  );
  uppy.value.on("upload-success", async (file: any) => {
    props.handleFileReceived({
      mid: file.mid,
      file: file.data,
      uid: file.uid,
    });
    try {
      const data = await getUploadState(file);
      const event: UploadSuccessEvent = {
        ...data,
        ...file,
        url: data.preview480Url,
        batch: file.data.batch,
        type: getImageType(file.type),
        "1080url": data.preview1080Url,
      };
      emits("onSuccess", event);
      props.handleSuccess(event);
    } catch (e) {
      const event = { ...file, state: 1, msg: "This file is corrupted. Please check and try again." };
      emits("onPreprocessingError", event);
      props.handlePreprocessingError(event);
    }
  });
  uppy.value.on("upload-error", (file: any) => {
    const event = { ...file, state: 1, url: "" };
    emits("onError", event);
    props.handleError(event);
  });
});
onUnmounted(() => {
  for (const timer of timers.values()) {
    clearTimeout(timer);
  }
  timers.clear();

  if (uppy.value) {
    uppy.value.destroy();
  }
  uppy.value = null;
});

// 上传函数
const uploadFn = async (file: UploadEvent) => {
  uppy.value.addFiles([
    {
      name: file.name,
      type: file.type,
      data: file,
    },
  ]);
};

const getSignedURL = async (file: any, signal: any, options: any = {}) => {
  const fileUrl = getFileUrl(file.data);
  const type = getImageType(file.type);
  const uploadParams: PreUploadParams = {
    suffix: getSuffix(file.name),
    fileType: file.type,
    draftId: props.draftId || 0,
    isLogo: props.isLogo,
    partCount: options.partCount,
    multipartUpload: options.multipartUpload,
  };
  const { code, data, msg } = await fileUploadPre(uploadParams, signal);

  if (code === 401) {
    router.push("/login");
  }
  if (code === 40001) {
    modalInstance.modalManager!.applyTemplate("upgradeTips", {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    trackEvent(code);
    throw new Error(msg);
  }
  if (code !== 0) {
    throw new Error(msg);
  }
  file.fileUrl = fileUrl;
  file.mid = data.mid;
  file.presignedUrls = data.presignedUrls;
  const event: any = {
    ...file,
    state: 2,
    percent: 0,
    url: "",
    type,
    fileUrl,
  };
  emits("uploadProcess", event);
  props.handleProgress(event);

  return data;
};

// file转url
const getFileUrl = (file: UploadEvent | undefined) => {
  if (file) {
    return URL.createObjectURL(file);
  }
  return "";
};

// 上传视频限制
const checkVideoSizeAndLength = (file: File) => {
  return new Promise((resolve) => {
    const isVideo = file.type.includes("video");

    if (!isVideo) {
      resolve(true);
    }

    let video = document.createElement("video");
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = function (e) {
      const duration = video.duration;
      if (duration > props.videoDuration) {
        resolve(false);
      } else {
        if (videoLength.value >= props.videoNumber) {
          resolve(false);
        }
        videoLength.value += 1;
        resolve(true);
      }
    };
  });
};

/**
 * 预处理
 * @param file
 */
const _beforeUpload = async (file: UploadEvent) => {
  // 如果有限制视频数量和duration
  if (props.videoNumber) {
    if (!timer.value) {
      // 睡5s再执行上传操作 保证本地文件已经读完了
      timer.value = setTimeout(() => {
        handleUpload();
      }, 500);
    }

    uploadList.value.push(file);
    fileListArr.value.push(file);
    const data = await checkVideoSizeAndLength(file);
    checkFileList.value.push(data as Boolean);
    return false;
  }

  const checkResult = fileType.check(file);
  if (!checkResult.passed) {
    Message.error(checkResult.msg, { duration: 2000 });
    return false;
  }

  emits("onBeforeUpload", file);

  const isUpload = props.beforeUpload(file as UploadEvent);
  if (isUpload === false) {
    return false;
  }

  // 缓存上传的文件， 便于计算长度和手动上传
  fileListArr.value.push(file);
};

// 执行手动上传
const handleUpload = () => {
  timer.value = null;
  videoLength.value = 0;

  // 存在一个条件不满足，阻断上传
  const idx = checkFileList.value.findIndex((item) => !item);
  if (idx >= 0) {
    clearFileArr();
    checkFileList.value = [];
    Message.error(
      ` Upload failed. Max ${props.videoNumber} videos, each video < ${props.videoDuration}S.`,
      { duration: 2000 }
    );
    return;
  }

  fileListArr.value.forEach((file) => {
    file.batch = fileListArr.value.length > 1;
    emits("onBeforeUpload", file);

    props.getFileListLength(fileListArr.value.length);
    uploadFn(file);
  });
  clearFileArr();
};

const fileUpload = (fileList) => {
  fileList.forEach((file: File) => {
    file.batch = fileList.length > 1;
    emits("onBeforeUpload", file);
    props.getFileListLength(fileList.length);
    uploadFn(file);
  });
};

const clearFileArr = () => {
  fileListArr.value = [];
};

const stopUpload = () => {
  timerMap.clear();
  timer.value = null;
  clearFileArr();
};

/***
 * 默认上传
 */

async function getUploadState(file: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const getState = async () => {
      const { success, data } = await fileUploadState(file.mid);
      if (success) {
        if (data.state === 0) {
          timers.delete(file.mid);
          resolve(data);
        } else if (data.state === 1) {
          timers.delete(file.mid);
          reject();
        } else {
          const timer = setTimeout(getState, 1000);
          timers.set(file.mid, timer);
        }
      } else {
        timers.delete(file.mid);
        reject();
      }
    };
    getState();
  });
}

const trackEvent = (code: number) => {
  const codeTrackMap = {
    20001: "export",
    30001: "premium_assets",
    40001: "cloud",
  };
  collectData("boolvideo_cta_view", {
    view_title: codeTrackMap[code],
  });
  track("boolvideo_cta_view");
};

const uploadRetry = (uid: number) => {
  const file = fileMap.value.get(uid);
  if (!file) return;
  emits("onBeforeUpload", file.data);
  const shouldUpload = props.beforeUpload(file.data);
  if (!shouldUpload) return;
  uppy.value.retryUpload(file.id);
};

// 自定义上传
const defaultHttpRequest = async ({ file }: { file: UploadEvent }) => {
  props.getFileListLength(fileListArr.value.length);
  // 存储file文件，用于后续retry
  file.batch = fileListArr.value.length > 1;
  uploadFn(file);
};

// 外部自定义上传
const customHttpRequest = (e) => {
  if (props.customUploadRequest) {
    props.customUploadRequest(e);
  } else {
    defaultHttpRequest(e);
  }
};

onMounted(() => {
  getCurrentInstance()!
    .vnode.el!.querySelector("input")
    .setAttribute("accept", fileType.formats);
});

defineExpose({
  uploadFn,
  fileUpload,
  elUploadRef,
  uploadRetry,
  clearFileArr: clearFileArr,
  stopUpload: stopUpload,
});
</script>
<style lang="scss" scoped>
:deep(.el-upload-dragger) {
  border: none;
  padding: 0;

  &:hover {
    border: none;
  }
}

:deep(.el-upload) {
  width: 100%;
  height: 100%;
}

:deep(.el-upload-dragger.is-dragover) {
  background-color: #f8f9fd !important;
}
</style>
