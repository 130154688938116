<script setup>
import Materials from "../materials/materials.vue";
import Spane from "../spane/spane.vue";
import Timeline from "../timeline/timeline.vue";
import { clamp } from "../../utils";

const active = ref(false);
const height = ref(253);
const beforePosition = reactive({ pageY: 0, height: 0 });

const style = computed(() => ({
  height: `${height.value}px`,
}));

onBeforeMount(resetPosition);

onMounted(() => {
  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);
});
onBeforeUnmount(() => {
  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp);
});

function mouseMove(e) {
  if (active.value) {
    resize(e);
  }
}

function handlerDown(e) {
  active.value = true;

  savePosition(e);
}

function mouseUp() {
  active.value = false;

  resetPosition();
}

function resize(e) {
  const deltaY = beforePosition.pageY - e.pageY;
  const newHeight = clamp(beforePosition.height + deltaY, 220, 532);

  height.value = newHeight;
}

function savePosition(e) {
  beforePosition.pageY = e.pageY;
  beforePosition.height = height.value;
}

function resetPosition() {
  beforePosition.pageY = 0;
  beforePosition.height = 0;
}
</script>
<template>
  <div class="editor-container">
    <Materials />
    <div class="editor-content">
      <div class="spane-wapper">
        <Spane />
      </div>
      <div class="timeline-wrapper" :style="style">
        <div class="handler" :class="{ active }" @mousedown="handlerDown"></div>
        <Timeline />
      </div>
    </div>
  </div>
</template>

<style scoped>
.editor-container {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  overflow: hidden;
  user-select: none;
}
.editor-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.spane-wapper {
  flex: 1 1 0%;
  overflow: hidden;
}
.timeline-wrapper {
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;
}
.handler {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  z-index: 1;
}
.handler:before {
  width: 100%;
  content: "";
  position: absolute;
  height: calc(100% + 6px);
  transform: translateY(-3px);
  cursor: row-resize;
}
.handler.active {
  background-color: #875eff;
}
</style>
