import { defineStore } from "pinia";
import { videoPurpose, getBrandKitList } from "@/api/index";
import { setItem, sessionSetItem } from '@/utils/storage';

export const useFeatureEdit = defineStore("featureEdit", {
  state: () => ({
    formState: {},
    imageMidsList: [],
    typeOptions: [
      {
        value: '0',
        label: 'No AI voiceover',
      },
      {
        value: '1',
        label: 'With AI voiceover',
      },
    ],
    subEffectList: [
      {
        value: '0',
        label: 'Stock video / image',
      }, {
        value: '1',
        label: 'Auto-change image background',
      }, {
        value: '2',
        label: 'Stickers',
      }, {
        value: '3',
        label: 'Filter',
      }
    ],
    effectList: [
      {
        id: 0,
        value: '0',
        label: 'Conservative',
        children: []
      },
      {
        id: 1,
        value: '1',
        label: 'Moderate',
        children: ['Stock video / image']
      },
      {
        id: 2,
        value: '2',
        label: 'Be wild',
        children: ['Stock video / image', 'Auto-change image background', 'Stickers', 'Filter']
      },
      {
        id: 3,
        value: '3',
        label: 'Customize',
        children: []
      },
    ],
    purposeList: [],
    subPurposeList: [],
    brandKitList: [],
    cacheImageList: []
  }),
  actions: {
    formatPurposeIds (purposeId, subPurposeSelects){
      const subPurposeList = this.purposeList.find(item => item.id == Number(purposeId) )?.children || []
      let subPurposeIds  = []

       subPurposeSelects?.forEach(item => {
        const subPurposeItem = subPurposeList.find(subPurpose => item == subPurpose.name)
        if(subPurposeItem) {
          subPurposeIds.push(subPurposeItem.id)
        }
      })
      return subPurposeIds
    },
    
    setSubPurpose (purposeId) {
      this.purposeList.forEach(purpose => {
        if (purpose.id == Number(purposeId)) {
          this.subPurposeList = purpose.children?.map((item) => {
            return {
              label: item.name,
              value: item.id + '',
              id: item.id
            };
          });
        }
      })
    },
    
    async getVideoPurpose () {
      try {
        const { data } = await videoPurpose();
        this.purposeList = data?.map((item) => {
          return {
            label: item.name,
            value: item.id + '',
            id: item.id,
            children: item.children,
          };
        });

        this.setSubPurpose(this.purposeList[0].id)
      } catch (err) {
        console.log("err", err);
      }
    },

    async getBrandKitListFn () {
      try {
        const { data } = await getBrandKitList();
        this.brandKitList = data?.map(item => {
          return {
            value: item.bkId,
            label: item.name,
          }
        })
      } catch (err) {
        console.log("err", err);
      }
    },

    formatCustom (customize) {
      let customizeIds = []
      this.subEffectList.forEach((item) => {
        if (customize.includes(item.label)) {
          customizeIds.push(Number(item.value))
        }
      })

      return customizeIds
    },

     updateFormState (formState) {
      const purposeIds = Number(formState.type) == 1 ? [] :  this.formatPurposeIds(formState.purposeId, formState.purposeIds)
      this.formState = {
        bkId: formState.bkId,
        customize: formState.effectId == '3' ? this.formatCustom(formState.customize) : [],
        duration: formState.duration * 60,
        effectCustomization: Number(formState.effectId),
        language: formState.language,
        productDesc: formState.productDesc,
        purposeIds: purposeIds,
        size: formState.size,
        type: Number(formState.type),
        url: formState.url
      };

      const cacheForm = {...this.formState, cacheImageList: this.cacheImageList }
      sessionSetItem('cacheForm', cacheForm);
    },

    updateImageList (imageList) {
      const list = imageList.filter(image => image.mid && image.state == 0);
      this.imageMidsList = list.map(image => image.mid)
      this.cacheImageList = list

      const cacheForm = {...this.formState, cacheImageList: this.cacheImageList }
      sessionSetItem('cacheForm', cacheForm);
    },

    getPurposeName(id) {
      for (const items of this.purposeList) {
        for (const item of items.children) {
          if (item.id === id) {
            return item.name;
          }
        }
      }
      return "";
    },
  },

  getters: {

  },
});