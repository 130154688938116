<template>
  <div class="h-full flex flex-col items-center info-panel">
    <p class="panel-title">
      {{ title }}
    </p>
    <div class="preview-box" @click="handleControlPlay">
      <video ref="videoRef" :src="previewUrl"></video>
      <svg-icon class="icon-play-large" name="icon_big_play" :size="80" v-if="!played" />
    </div>

    <ul class="info-list">
      <li class="flex">
        <p class="title">Video type :</p>
        <p class="dec"> {{ videoType }}</p>
      </li>
      <li class="flex">
        <p class="title">Perfect for :</p>
        <p class="dec"> {{ perfectFor }}</p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  videoType: {
    type: String,
    required: true
  },
  perfectFor: {
    type: String,
    required: true,
  },
  previewUrl: {
    type: String,
    required: true,
  },
  isVideoPlay: {
    type: Boolean,
  }
});

const emit = defineEmits(['onplay'])
const played = ref(false)
const videoRef = ref(null)

watch(() => props.isVideoPlay, (value) => {
  if(!value) {
    videoRef.value?.pause()
    played.value = false
  }
}, {
  immediate: true
})

const handleControlPlay = () => {
  if (played.value == true) {
    videoRef.value?.pause()
    played.value = false
  } else {
    videoRef.value?.play()
    played.value = true
  }

  emit('onplay', played.value)
}

const destroy = () => {
  videoRef.value?.pause()
  videoRef.value.currentTime = 0
}

defineExpose({
  destroy
})

</script>

<style scoped lang="scss">
.panel-title {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #060606;
}

.preview-box {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 12px auto 18px;
  width: 294px;
  height: 375px;
  position: relative;

  .icon-play-large {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
  }

  &>video,
  &>img {
    width: 210px;
    height: 375px;
    object-fit: cover;
    object-position: center;
  }
}

.info-panel {
  width: 319px;
  height: 524px;
}

.info-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  color: #646A73;
  height: 100px;

  &>li>.title {
    width: 70px;
    color: #060606;
  }

  &>li>.dec {
    flex: 1;
    // padding-right: 20px;
  }
}
</style>
