<script setup>
import {
  tagOptions,
  fontSizeOptions,
  fontFamilyOptions,
  defaultFontFamily,
  fontIcons,
} from "@/constants/text";
import { blendOptions } from "@/constants/image";
import { useDraftStore, useHistoryStore } from "../../stores";
import Attr from "./attr.vue";
import Keyframe from "./keyframe.vue";
import Extra from "./extra.vue";

const styleButtons = [
  {
    type: "bold",
    icon: "editor_bold",
  },
  {
    type: "textDecoration",
    icon: "editor_text_decoration",
  },
  {
    type: "italic",
    icon: "editor_italic",
  },
  {
    type: "alignLeft",
    icon: "editor_align_left",
  },
  {
    type: "alignCenter",
    icon: "editor_align_center",
  },
  {
    type: "alignRight",
    icon: "editor_align_right",
  },
];

const textStyleButtons = [
  {
    style: "none",
    icon: "editor_text_none",
  },
  {
    style: "white_black",
    icon: "editor_text_white_black",
  },
  {
    style: "bright_green",
    icon: "editor_text_bright_green",
  },
  {
    style: "black_green",
    icon: "editor_text_black_green",
  },
  {
    style: "light_red",
    icon: "editor_text_light_red",
  },
  {
    style: "light_blue",
    icon: "editor_text_light_blue",
  },
];

const defaultStyle = {
  fill: "#000000",
  stroke: "#000000",
  strokeThickness: 0,
  background: "",
  dropShadow: false,
  dropShadowAlpha: 1,
  dropShadowAngle: Math.PI / 6,
  dropShadowBlur: 0,
  dropShadowColor: "#000000",
  dropShadowDistance: 5,
};

const emit = defineEmits([
  "update:text",
  "update:fontFamily",
  "update:fontSize",
  "update:fill",
  "update:fontWeight",
  "update:fontStyle",
  "update:align",
  "update:letterSpacing",
  "update:leading",
  "update:x",
  "update:y",
  "update:scale",
  "update:rotate",
  "update:opacity",
  "update:anchor",
  "update:paddingX",
  "update:paddingY",
  "update:stroke",
  "update:strokeThickness",
  "update:background",
  "update:backgroundRadius",
  "update:backgroundOpacity",
  "update:dropShadow",
  "update:dropShadowAlpha",
  "update:dropShadowAngle",
  "update:dropShadowBlur",
  "update:dropShadowColor",
  "update:dropShadowDistance",
  "update:keyframes",
  "update:blend",
  "update:tag",
  "update:preset",
]);

const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  initialState: {
    type: Object,
    default: {},
  },
  text: {
    type: String,
    default: "",
  },
  fontFamily: {
    type: Array,
    default: defaultFontFamily,
  },
  fontSize: {
    type: Number,
    default: 26,
  },
  fill: {
    type: String,
    default: "",
  },
  fontWeight: {
    type: String,
    default: "normal",
  },
  fontStyle: {
    type: String,
    default: "normal",
  },
  align: {
    type: String,
    default: "center",
  },
  letterSpacing: {
    type: Number,
    default: 0,
  },
  leading: {
    type: Number,
    default: 0,
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  scale: {
    type: Number,
    default: 1,
  },
  opacity: {
    type: Number,
    default: 1,
  },
  anchor: {
    type: Array,
    default: () => [0.5, 0.5],
  },
  paddingX: {
    type: Number,
    default: 8,
  },
  paddingY: {
    type: Number,
    default: 4,
  },
  stroke: {
    type: String,
    default: "#000000",
  },
  strokeThickness: {
    type: Number,
    default: 0,
  },
  background: {
    type: String,
    default: null,
  },
  backgroundRadius: {
    type: Number,
    default: 0,
  },
  backgroundOpacity: {
    type: Number,
    default: 1,
  },
  dropShadow: {
    type: Boolean,
    default: false,
  },
  dropShadowAlpha: {
    type: Number,
    default: 1,
  },
  dropShadowAngle: {
    type: Number,
    default: Math.PI / 6,
  },
  dropShadowBlur: {
    type: Number,
    default: 0,
  },
  dropShadowColor: {
    type: String,
    default: "#000000",
  },
  dropShadowDistance: {
    type: Number,
    default: 5,
  },
  keyframes: {
    type: Object,
    default: null,
  },
  tag: {
    type: String,
    default: null,
  },
  preset: {
    type: String,
    default: null,
  },
  blend: {
    type: String,
    default: "normal",
  },
});

const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

const x = ref(props.x);
const y = ref(props.y);
const rotate = ref(props.rotate);
const scale = ref(props.scale);
const opacity = ref(props.opacity);
const preseting = ref(false);

const dirty = reactive({
  x: 0,
  y: 0,
  rotate: 0,
  scale: 0,
  opacity: 0,
});

watch(
  () => props.node.updateId,
  () => {
    const node = props.node;
    const [nodeX, nodeY] = node.getXY();
    const nodeScale = node.getScale();
    const nodeRotation = node.getRotation();
    const nodeOpacity = node.getOpacity();

    x.value = nodeX;
    y.value = nodeY;
    scale.value = nodeScale;
    rotate.value = nodeRotation;
    opacity.value = nodeOpacity;
  },
  { immediate: true },
);
watch(
  () => props.x,
  (newX) => {
    updateX(newX, false, true);
  },
);
watch(
  () => props.y,
  (newY) => {
    updateY(newY, false, true);
  },
);
watch(
  () => props.scale,
  (newScale) => {
    updateScale(newScale, false, true);
  },
);
watch(
  () => props.rotate,
  (newRotation) => {
    updateRotate(newRotation, false, true);
  },
);
watch(
  () => props.opacity,
  (newOpacity) => {
    updateOpacity(newOpacity, false, true);
  },
);

function submit() {
  commit();
  updateDraft();
}

function resetBasicProperty() {
  const { initialState } = props;
  emit("update:fontFamily", initialState.fontFamily);
  emit("update:fontSize", parseInt(initialState.fontSize));
  emit("update:fill", initialState.fill);
  emit("update:opacity", initialState.opacity);
  emit("update:fontWeight", initialState.fontWeight);
  emit("update:fontStyle", initialState.fontStyle);
  emit("update:align", initialState.align);
  emit("update:letterSpacing", initialState.letterSpacing);
  emit("update:leading", initialState.leading);
  emit("update:stroke", initialState.stroke);
  emit("update:strokeThickness", initialState.strokeThickness);
  emit("update:background", initialState.background);
  emit("update:backgroundRadius", initialState.backgroundRadius);
  emit("update:backgroundOpacity", initialState.backgroundOpacity);
  submit();
}

function resetLayoutProperty() {
  const { initialState } = props;
  emit("update:x", initialState.x);
  emit("update:y", initialState.y);
  emit("update:rotate", initialState.rotation);
  emit("update:scale", initialState.scale);
  emit("update:opacity", initialState.opacity);
  emit("update:keyframes", initialState.keyframes);
  submit();
}

function resetExtraProperty() {
  const { initialState } = props;
  emit("update:tag", initialState.tag);
  submit();
}

function updateText(value, shouldSubmit) {
  emit("update:text", value);
  shouldSubmit && submit();
}

function updateFontFamily(value) {
  emit("update:fontWeight", "normal");
  emit("update:fontStyle", "normal");
  emit("update:fontFamily", value);
  submit();
}

function updateFontSize(value, shouldSubmit) {
  emit("update:fontSize", parseInt(value));
  shouldSubmit && submit();
}

function updateFill(value, shouldSubmit) {
  !preseting.value && emit("update:preset", null);
  emit("update:fill", value);
  shouldSubmit && submit();
}

function updateLetterSpacing(value, shouldSubmit) {
  emit("update:letterSpacing", value);
  shouldSubmit && submit();
}

function updateLeading(value, shouldSubmit) {
  emit("update:leading", value);
  shouldSubmit && submit();
}

function updateStroke(value, shouldSubmit) {
  !preseting.value && emit("update:preset", null);
  emit("update:stroke", value);
  shouldSubmit && submit();
}

function updateStrokeThickness(value, shouldSubmit) {
  !preseting.value && emit("update:preset", null);
  emit("update:strokeThickness", value);
  shouldSubmit && submit();
}

function updateBackground(value, shouldSubmit) {  
  !preseting.value && emit("update:preset", null);
  emit("update:background", value);
  shouldSubmit && submit();
}

function updateBackgroundRadius(value) {  
  emit("update:backgroundRadius", value);
  submit();
}

function updateBackgroundOpacity(value) {  
  emit("update:backgroundOpacity", value);
  submit();
}

function updateDropShadowColor(value, shouldSubmit) {
  !preseting.value && emit("update:preset", null);
  emit("update:dropShadow", true);
  emit("update:dropShadowColor", value);
  shouldSubmit && submit();
}

function updateDropShadowAlpha(value, shouldSubmit) {
  !preseting.value && emit("update:preset", null);
  emit("update:dropShadow", true);
  emit("update:dropShadowAlpha", value);
  shouldSubmit && submit();
}

function updateDropShadowAngle(value, shouldSubmit) {
  !preseting.value && emit("update:preset", null);
  emit("update:dropShadow", true);
  emit("update:dropShadowAngle", value);
  shouldSubmit && submit();
}

function updateDropShadowBlur(value, shouldSubmit) {
  !preseting.value && emit("update:preset", null);
  emit("update:dropShadow", true);
  emit("update:dropShadowBlur", value);
  shouldSubmit && submit();
}

function updateDropShadowDistance(value, shouldSubmit) {
  !preseting.value && emit("update:preset", null);
  emit("update:dropShadow", true);
  emit("update:dropShadowDistance", value);
  shouldSubmit && submit();
}

function updateX(value, shouldSubmit, noEmit) {
  x.value = value;
  dirty.x++;
  !noEmit && emit("update:x", value);
  shouldSubmit && submit();
}

function updateY(value, shouldSubmit, noEmit) {
  y.value = value;
  dirty.y++;
  !noEmit && emit("update:y", value);
  shouldSubmit && submit();
}

function updateRotate(value, shouldSubmit, noEmit) {
  rotate.value = value;
  dirty.rotate++;
  !noEmit && emit("update:rotate", value);
  shouldSubmit && submit();
}

function updateScale(value, shouldSubmit, noEmit) {
  scale.value = value;
  dirty.scale++;
  !noEmit && emit("update:scale", value);
  shouldSubmit && submit();
}

function updateOpacity(value, shouldSubmit, noEmit) {
  opacity.value = value;
  dirty.opacity++;
  !noEmit && emit("update:opacity", value);
  shouldSubmit && submit();
}

function updateAnchor(value, shouldSubmit) {
  emit("update:anchor", value);
  shouldSubmit && submit();
}

function updatePaddingX(value, shouldSubmit) {
  emit("update:paddingX", value);
  shouldSubmit && submit();
}

function updatePaddingY(value, shouldSubmit) {
  emit("update:paddingY", value);
  shouldSubmit && submit();
}

function updateKeyframes(value) {
  emit("update:keyframes", value);
}

function updateBlend(value) {
  emit("update:blend", value);
  submit();
}

function updateTag(value) {
  emit("update:tag", value);
  submit();
}

function updateTextStyle(type) {
  const { fontWeight, fontStyle } = props;

  switch (type) {
    case "bold": {
      emit("update:fontWeight", fontWeight !== "bold" ? "bold" : "normal");
      break;
    }
    case "italic":
      emit("update:fontStyle", fontStyle !== "italic" ? "italic" : "normal");
      break;
    case "alignLeft":
      emit("update:align", "left");
      break;
    case "alignCenter":
      emit("update:align", "center");
      break;
    case "alignRight":
      emit("update:align", "right");
      break;
  }
  submit();
}

function updateTextPresetStyle(style) {
  const resetDefaultShadow = () => {
    emit("update:dropShadow", defaultStyle.dropShadow);
    emit("update:dropShadowAlpha", defaultStyle.dropShadowAlpha);
    emit("update:dropShadowAngle", defaultStyle.dropShadowAngle);
    emit("update:dropShadowBlur", defaultStyle.dropShadowBlur);
    emit("update:dropShadowColor", defaultStyle.dropShadowColor);
    emit("update:dropShadowDistance", defaultStyle.dropShadowDistance);
  }
  preseting.value = true;
  emit("update:preset", style);
  resetDefaultShadow();
  switch (style) {
    case "none": {
      emit("update:fill", defaultStyle.fill);
      emit("update:stroke", defaultStyle.stroke);
      emit("update:strokeThickness", defaultStyle.strokeThickness);
      emit("update:background", defaultStyle.background);
      break;
    }
    case "white_black": {
      emit("update:fill", "#FFFFFF");
      emit("update:stroke", "#000000");
      emit("update:strokeThickness", 0.18);
      break;
    }
    case "bright_green": {
      emit("update:fill", "#DFFF5D");
      emit("update:stroke", "#50A51C");
      emit("update:strokeThickness", 0.18);
      break;
    }
    case "black_green" : {
      emit("update:fill", "#000000");
      emit("update:stroke", "#000000");
      emit("update:strokeThickness", defaultStyle.strokeThickness);
      emit("update:dropShadow", true);
      emit("update:dropShadowAlpha", defaultStyle.dropShadowAlpha);
      emit("update:dropShadowAngle", Math.PI / 4);
      emit("update:dropShadowBlur", defaultStyle.dropShadowBlur);
      emit("update:dropShadowColor", "#2EDE7F");
      emit("update:dropShadowDistance", 2);
      break;
    }
    case "light_red" : {
      emit("update:fill", "#FFBABA");
      emit("update:stroke", "#D96969");
      emit("update:strokeThickness", 0.18);
      break;
    }
    case "light_blue" : {
      emit("update:fill", "#E3EEFF");
      emit("update:stroke", "#5C86F2");
      emit("update:strokeThickness", 0.18);
      break;
    }
  }
  preseting.value = false;
  submit();
}

function getActive(type) {
  const { fontWeight, fontStyle, align } = props;

  switch (type) {
    case "bold":
      return fontWeight === "bold";
    case "italic":
      return fontStyle === "italic";
    case "alignLeft":
      return align === "left";
    case "alignCenter":
      return align === "center";
    case "alignRight":
      return align === "right";
    default:
      return false;
  }
}

function disabledStyle(type) {
  if (["bold", "italic"].includes(type)) {
    for (const { descriptors } of props.fontFamily) {
      if (descriptors.weight === type || descriptors.style === type) {
        return false;
      }
    }
    return true;
  }
  return false;
}
</script>
<template>
  <Attr title="Text">
    <el-scrollbar>
      <div class="attr-content">
        <div class="textarea-form">
          <el-input
            type="textarea"
            :model-value="text"
            @input="updateText"
            @change="updateText($event, true)"
          />
        </div>
        <div class="form">
          <div class="form-header">
            <span>Basic</span>
            <icon-button
              name="editor_reset"
              tip="Reset"
              :size="14"
              @click="resetBasicProperty"
            />
          </div>
          <div class="form-content">
            <div class="col col-input col-small">
              <bv-select
                :popper-style="{ width: '226px' }"
                :options="
                  fontFamilyOptions.sort((a, b) => (a.label > b.label ? 1 : -1))
                "
                :model-value="fontFamily"
                @change="updateFontFamily"
              >
                <template #option="{ option }">
                  <img
                    :src="fontIcons[option.label]"
                    :style="{ height: '14px' }"
                    draggable="false"
                    loading="lazy"
                  />
                </template>
              </bv-select>
              <bv-select
                numberInput
                :popper-style="{ width: '106px' }"
                :options="fontSizeOptions"
                :model-value="Math.round(fontSize)"
                @input="updateFontSize"
                @change="updateFontSize($event, true)"
              />
            </div>
            <div class="col col-input col-small col-single">
              <input-color-picker nullable :value="fill" @input="updateFill" @change="updateFill($event, true)" />
            </div>
            <div class="col style-group">
              <icon-button
                v-for="(item, i) in styleButtons"
                border
                :key="i"
                :name="item.icon"
                :size="16"
                :disabled="disabledStyle(item.type)"
                :color="getActive(item.type) ? '#6741FF' : '#1C1B1E'"
                :active="getActive(item.type)"
                @click="updateTextStyle(item.type)"
              />
            </div>
            <div class="col col-input col-medium">
              <bv-tip content="Letter spacing">
                <input-number
                  align-right
                  percent
                  :model-value="letterSpacing"
                  :min="0"
                  :max="1"
                  :step="0.01"
                  @input="updateLetterSpacing"
                  @change="updateLetterSpacing($event, true)"
                >
                  <template #prefix>
                    <svg-icon name="icon_letter_spacing" :size="18" />
                  </template>
                </input-number>
              </bv-tip>
              <bv-tip content="Line height">
                <input-number
                  align-right
                  percent
                  :model-value="leading"
                  :min="-1"
                  :max="1"
                  :step="0.01"
                  @input="updateLeading"
                  @change="updateLeading($event, true)"
                >
                  <template #prefix>
                    <svg-icon name="icon_leading" :size="18" />
                  </template>
                </input-number>
              </bv-tip>
            </div>
            <div class="col style-group">
              <icon-button
                v-for="(item, i) in textStyleButtons"
                border
                :key="i"
                :name="item.icon"
                :size="16"
                :class="{ selected: preset === item.style }"
                @click="updateTextPresetStyle(item.style)"
              />
            </div>
            <div class="form-title">
              <span>Stroke</span>
            </div>
            <div class="col col-input col-medium">
              <input-color-picker :value="stroke" @input="updateStroke" @change="updateStroke($event, true)" />
              <input-number
                align-right
                percent
                :model-value="strokeThickness"
                :min="0"
                :max="1"
                :step="0.01"
                @input="updateStrokeThickness"
                @change="updateStrokeThickness($event, true)"
              >
                <template #prefix>size</template>
              </input-number>
            </div>
            <div class="form-title">
              <span>Background</span>
            </div>
            <div class="form-content">
              <div class="col col-input col-medium">
                <input-color-picker
                  nullable
                  :value="background"
                  @input="updateBackground"
                  @change="updateBackground($event, true)"
                />
                <bv-tip content="Opacity">
                  <input-number
                    percent
                    :model-value="backgroundOpacity"
                    :min="0"
                    :max="1"
                    :step="0.01"
                    @input="updateBackgroundOpacity"
                    @change="updateBackgroundOpacity($event, true)"
                  >
                    <template #prefix>
                      <svg-icon name="editor_opacity" :size="16" />
                    </template>
                  </input-number>
                </bv-tip>
              </div>
              <div class="col col-input col-single">
                <bv-tip content="Radius">
                  <input-number
                    :model-value="backgroundRadius"
                    :min="0"
                    @input="updateBackgroundRadius"
                    @change="updateBackgroundRadius($event, true)"
                  >
                    <template #prefix>
                      <svg-icon name="editor_radius" :size="18" />
                    </template>
                  </input-number>
                </bv-tip>
              </div>
            </div>
          </div>
        </div>
        <extra 
          resetable 
          :config="node.conf" 
          @reset="resetExtraProperty"
        >
          <div class="col col-input col-small">
            <input-number
              align-right
              percent
              :model-value="anchor[0]"
              :min="0"
              :max="1"
              :step="0.01"
              @input="updateAnchor([$event, anchor[1]])"
              @change="updateAnchor([$event, anchor[1]], true)"
            >
              <template #prefix>AnchorX</template>
            </input-number>
            <input-number
              align-right
              percent
              :model-value="anchor[1]"
              :min="0"
              :max="1"
              :step="0.01"
              @input="updateAnchor([anchor[0], $event])"
              @change="updateAnchor([anchor[0], $event], true)"
            >
              <template #prefix>AnchorY</template>
            </input-number>
          </div>
          <div class="col col-input col-small">
            <input-number
              align-right
              :model-value="paddingX"
              @input="updatePaddingX"
              @change="updatePaddingX($event, true)"
            >
              <template #prefix>PaddingX</template>
            </input-number>
            <input-number
              align-right
              :model-value="paddingY"
              @input="updatePaddingY"
              @change="updatePaddingY($event, true)"
            >
              <template #prefix>PaddingY</template>
            </input-number>
          </div>
          <div class="col col-input col-small col-single">
            <bv-select
              clearable
              placeholder="Select tag"
              width="226px"
              :model-value="tag"
              :options="tagOptions"
              @change="updateTag"
            />
          </div>
        </extra>
        <ElCollapse accordion>
          <el-collapse-item title="Layout" name="1">
            <div class="form keyframe">
              <div class="form-header align-right">
                <div class="right">
                  <icon-button
                    name="editor_reset"
                    tip="Reset"
                    :size="14"
                    @click="resetLayoutProperty"
                  />
                  <Keyframe
                    :should-add="false"
                    :properties="{ x, y, rotate, scale, opacity }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
              </div>
              <div class="form-content">
                <div class="col col-input">
                  <input-number
                    rounded
                    :model-value="x"
                    @input="updateX"
                    @change="updateX($event, true)"
                  >
                    <template #prefix>X</template>
                  </input-number>
                  <input-number
                    rounded
                    :model-value="y"
                    @input="updateY"
                    @change="updateY($event, true)"
                  >
                    <template #prefix>Y</template>
                  </input-number>
                  <Keyframe
                    :properties="{ x, y }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Rotation">
                    <input-number
                      angle
                      :model-value="rotate"
                      :step="Math.PI / 180"
                      @input="updateRotate"
                      @change="updateRotate($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_angle" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ rotate }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Scale">
                    <input-number
                      percent
                      :model-value="scale"
                      :min="0.01"
                      :max="10"
                      :step="0.01"
                      @input="updateScale"
                      @change="updateScale($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_scale" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ scale }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
                <div class="col col-input col-single">
                  <bv-tip content="Opacity">
                    <input-number
                      percent
                      :model-value="opacity"
                      :min="0"
                      :max="1"
                      :step="0.01"
                      @input="updateOpacity"
                      @change="updateOpacity($event, true)"
                    >
                      <template #prefix>
                        <svg-icon name="editor_opacity" :size="16" />
                      </template>
                    </input-number>
                  </bv-tip>
                  <Keyframe
                    :properties="{ opacity }"
                    :keyframes="keyframes"
                    :dirty="dirty"
                    @change="updateKeyframes"
                  />
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Blend" name="2">
            <div class="form">
              <div class="col col-input col-small">
                <bv-select
                  width="226px"
                  style="margin-right: 0"
                  :model-value="blend"
                  :options="blendOptions"
                  @change="updateBlend"
                />
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="Shadow" name="3">
            <div class="form">
              <div class="col col-input col-medium">
                <input-color-picker
                  :value="dropShadowColor"
                  @input="updateDropShadowColor"
                  @change="updateDropShadowColor($event, true)"
                />
                <input-number
                  align-right
                  percent
                  :min="0"
                  :max="1"
                  :step="0.01"
                  :model-value="dropShadowAlpha"
                  @input="updateDropShadowAlpha"
                  @change="updateDropShadowAlpha($event, true)"
                >
                  <template #prefix>Opacity</template>
                </input-number>
              </div>
              <div class="col col-input col-medium">
                <input-number
                  align-right
                  angle
                  :model-value="dropShadowAngle"
                  :step="Math.PI / 180"
                  @input="updateDropShadowAngle"
                  @change="updateDropShadowAngle($event, true)"
                >
                  <template #prefix>Angle</template>
                </input-number>
                <input-number
                  align-right
                  :model-value="dropShadowBlur"
                  @input="updateDropShadowBlur"
                  @change="updateDropShadowBlur($event, true)"
                >
                  <template #prefix>Blur</template>
                </input-number>
              </div>
              <div class="col col-input col-medium">
                <div>
                  <input-number
                    align-right
                    :model-value="dropShadowDistance"
                    @input="updateDropShadowDistance"
                    @change="updateDropShadowDistance($event, true)"
                  >
                    <template #prefix>Distance</template>
                  </input-number>
                </div>
                <div></div>
              </div>
            </div>
          </el-collapse-item>
        </ElCollapse>
      </div>
    </el-scrollbar>
  </Attr>
</template>
<style scoped>
.icon-button.active {
  border-color: #6741FF;
}
.textarea-form {
  padding: 18px 18px 0;
}
.form .col.style-group {
  justify-content: space-between;
}
.icon-button.selected {
  border-color: transparent;
  outline: 1px solid #6741FF;
}
:deep(.el-input__wrapper),
:deep(.el-textarea__inner),
:deep(.el-select .el-input .el-input__wrapper) {
  border-color: #d5d6d7 !important;
}
:deep(.style-group .el-button) {
  width: 24px;
  height: 24px;
  padding: 0;
}
:deep(.style-group .el-button.active) {
  background-color: #e8e9ec;
}
:deep(.style-group .el-icon) {
  width: 16px;
  height: 16px;
}
:deep(.el-collapse .form) {
  padding: 1px 18px 24px;
}
:deep(.el-collapse .form.keyframe) {
  padding: 0 14px 24px 18px;
}
:deep(.el-collapse-item__header .el-collapse-item__arrow) {
  margin: 0 7.5px 0 auto;
}
</style>
