<template>
  <div class="checkout-container">
    <p class="title" v-if="isPaymentReady">Payment Details</p>
    <email v-model:email="email" v-if="isPaymentReady" />
    <checkout-form
      :clientSecret="info.clientSecret"
      :handleCreateSubscription="handleCreateSubscription"
      :isSetup="packageMap[packageCode].isSetup"
      :email="email"
      @ready="(value) => (isPaymentReady = value)"
      v-if="info.clientSecret"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineProps } from "vue";
import { useRouter } from "vue-router";
import CheckoutForm from "../payment/checkout-form.vue";
import Email from "./email.vue";
import { comfirmLTD, comfirmAnnual } from "@/api/premium";

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
  couponCode: {
    type: String,
    required: true,
  },
  packageCode: {
    type: Number,
    required: true,
  },
});

interface Package {
  packageCode: number;
  isSetup: boolean;
  handler?: () => Promise<any>;
}

const router = useRouter();
const isPaymentReady = ref(false);
const email = ref("");
const packageMap: Record<number, Package> = reactive({
  300204: {
    packageCode: 300204,
    isSetup: false,
  },
  300304: {
    packageCode: 300304,
    isSetup: false,
  },
  300202: {
    packageCode: 300202,
    isSetup: true,
  },
  300302: {
    packageCode: 300302,
    isSetup: true,
  },
});

const isSetup = computed(() => {
  return packageMap[props.packageCode].isSetup;
});

const ltdSubscribe = async () => {
  return await comfirmLTD({
    couponCode: props.couponCode || null,
    paymentIntentId: props.info.intentId,
    userEmail: email.value,
    packageCode: props.packageCode,
  })
    .then((res) => {
      const { code, data, msg } = res;
      if (code === 0) {
        return { ...res, packageCode: props.packageCode, error: false };
      } else {
        throw msg;
      }
    })
    .catch((error) => {
      return { error };
    });
};

const commonSubscribe = async () => {
  return await comfirmAnnual({
    couponCode: props.couponCode || null,
    setupIntentId: props.info.intentId,
    userEmail: email.value,
    packageCode: props.packageCode,
    customerId: props.info.customerId,
  })
    .then((res) => {
      const { code, data, msg } = res;
      if (code === 0) {
        router.replace({
          path: "ltd/query",
          query: {
            orderId: data.orderId,
            packageCode: props.packageCode,
          },
        });
        return { ...res, error: false };
      } else {
        throw msg;
      }
    })
    .catch((error) => {
      return { error };
    });
};

const handleCreateSubscription = () => {
  if (isSetup.value) {
    return commonSubscribe();
  } else {
    return ltdSubscribe();
  }
};
</script>

<style scoped lang="scss">
.checkout-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 24px 16px 16px;
  border-top: 1px solid #ebedef;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 36px;
}

@media screen and (min-width: 992px) {
  .checkout-container {
    width: 50%;
    margin: 0;
    padding: 30px 91px;
    border-top: none;
    border-left: 1px solid #ebedef;
  }
}
</style>
