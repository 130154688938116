<template>
  <div class="empty flex mt-20 flex-col items-center">
    <svg-icon name="icon_empty" style="color: #bbbfc4; height: 200px"></svg-icon>
    <div class="flex flex-col items-center -mt-4">
      <p class="leading-8 text-defaultColor text-sm font-normal -mt-2">
        No asset
      </p>
      <p class="leading-8 text-defaultColor text-sm font-normal" v-if="showSpaceLink">
        Upload in
        <span class="hover:text-primaryColor cursor-pointer text-content border-bottom" @click="handleToSpace">My
          space_media</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const props = defineProps({
  showSpaceLink: Boolean
})

const router = useRouter();


const handleToSpace = () => {;
  const href = router.resolve({
    path: "/space",
    query: {
      tab: "media",
    },
  });

  // 点击事件
  window.open(href.href, "_blank");
};

</script>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #8F959E;
  &:hover {
    border-bottom: 1px solid #875EFF;
  }
}
</style>