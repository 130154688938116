<template>
  <div class="about-us-page-container">
    <div id="about-us-page">
      <div>
        <div class="landing-page">
          <advanced-video :src="landingPageVideo" loop lazy autoplay></advanced-video>
          <div class="content-container">
            <div class="content">
              <h1 class="text-center">Boolvideo AI Video Generator - About Boolvideo</h1>
              <div class="description">

                <p class="text-center">Hey! Welcome to Boolv,</p>
                <p class="text-center">where we specialize in the creation of innovative,</p>
                <p class="text-center">high-quality content using cutting-edge artificial intelligence technology.</p>
              </div>
              <div class="down-icon flex justify-center cursor-pointer">
                <div :style="{ width: '54px' }" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave">
                  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isActive"
                    @click="handleScroll">
                    <circle cx="27" cy="27" r="27" fill="white" fill-opacity="0.1" />
                    <circle cx="27" cy="27" r="26.5" stroke="white" stroke-opacity="0.3" />
                    <path
                      d="M26.2929 38.7071C26.6834 39.0976 27.3166 39.0976 27.7071 38.7071L34.0711 32.3431C34.4616 31.9526 34.4616 31.3195 34.0711 30.9289C33.6805 30.5384 33.0474 30.5384 32.6569 30.9289L27 36.5858L21.3431 30.9289C20.9526 30.5384 20.3195 30.5384 19.9289 30.9289C19.5384 31.3195 19.5384 31.9526 19.9289 32.3431L26.2929 38.7071ZM26 17V38H28V17H26Z"
                      fill="white" />
                  </svg>
                  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="arrow-down" v-else>
                    <circle cx="27" cy="27" r="26.5" stroke="white" stroke-opacity="0.3" />
                    <path
                      d="M26.2929 38.7071C26.6834 39.0976 27.3166 39.0976 27.7071 38.7071L34.0711 32.3431C34.4616 31.9526 34.4616 31.3195 34.0711 30.9289C33.6805 30.5384 33.0474 30.5384 32.6569 30.9289L27 36.5858L21.3431 30.9289C20.9526 30.5384 20.3195 30.5384 19.9289 30.9289C19.5384 31.3195 19.5384 31.9526 19.9289 32.3431L26.2929 38.7071ZM26 17V38H28V17H26Z"
                      fill="white" />
                  </svg>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="text-container" id="text-container">
        <div v-for="item in list" :key="item.id">
          <h2 class="title mb-30px font-semibold text-40px">{{ item.title }}</h2>
          <template v-if="item.paragraph">
            <div class="paragraph text-base font-normal text-textColor " v-for="text in item.paragraph"
              :key="text">
              <div v-html="text"></div>
            </div>
          </template>
          <template v-if="item.sentence">
            <div class="sentence" v-for="text in item.sentence" :key="item">
              <div v-html="text"></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import landingPageVideo from '@/assets/videos/about/about_us.mp4';

const isActive = ref(false);
const list = [
  {
    id: 1,
    title: 'About Boolv',
    paragraph: [
      '<p>At our core, we believe that AI-generated content is the future of content creation, and we are committed to staying at the forefront of this exciting new field. Our solutions are designed to empower businesses, content creators, and individuals with the tools they need to create compelling, high-quality content quickly and efficiently.</p>',
      `<p>Founded in 2022, we have raised seed funding $10M from top-tier investors. Our team of experts includes experienced developers, designers, and content creators who are dedicated to using AI to deliver cutting-edge content that meets the unique needs of our clients. Whether you're a small business looking to promote your products or services, a marketing agency in need of compelling visual content for your clients, or a content creator looking to take your creation to the next level, we're here to help. Our AI generation tools can be customized to meet your unique needs.</p>`
    ]
  },
  {
    id: 2,
    title: 'Join Us',
    sentence: [
      '<p style="font-size: 16px">At BOOLV Tech, our people are intelligent, compassionate and creative. </p>',
      `<p style="font-size: 16px">We engage in meaningful work, delivering creative video solutions to our clients. Here you'll find limitless opportunities waiting for dreamers who want to make differences happen every day.</p>`,
    ]
  }, {
    id: 3,
    title: 'Contact Us',
    sentence: [
      `<p style="font-size: 16px">Email: <a href="mailto:support@boolvector.come" target="_blank" rel="noreferrer">
          support@boolvector.com
        </a>.</p>`,
      '<p style="font-size: 16px">Call: +1 (424) 722-5123</p>',
      '<p>US Office: 1309 COFFEEN AVENUE STE 1200, SHERIDAN, WYOMING 82801</p>'
    ]
  }
];  

const handleScroll = () => {
  document.getElementById('text-container').scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })
};

const handleMouseMove = () => {
  isActive.value = true
};

const handleMouseLeave = () => {
  isActive.value = false
};

onMounted(() => {
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth'
  });
});
</script>

<style lang="scss" scoped>
.about-us-page-container {
  width: 100%;
  font-family: 'Poppins';
}

.text-container {
  width: 1220px;
  padding-top: 120px;
  padding-bottom: 270px;
}

.text-container > div {
  margin-bottom: 140px;
}

.paragraph + .paragraph {
  margin-top: 30px;
}

.title {
  margin-bottom: 30px;
}

@screen ipad {
  .text-container {
    width: 90vw;
  }

  .title {
    font-size: clamp(28px, 5vw, 40px);
  }
}

@screen phone {
  .text-container {
    width: 90%;
    padding-block: 100px 0;
  }

  .text-container > div:not(:first-of-type) {
    margin-top: 80px;
  }
}

.landing-page {
  position: relative;
  width: 100%;
}

.landing-page>video {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #000 url(@/assets/images/about/about_us_bg.jpg) no-repeat center center/cover;
}

.content-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.72%, rgba(0, 0, 0, 0.45) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));

}

.content {
  width: 1220px;
  padding-block: 110px;
}

.content>h1 {
  font-weight: 600;
  font-size: 68px;
  line-height: 128%;
  text-transform: capitalize;
  background: linear-gradient(90.28deg, #D9A0FF 18.93%, #8549FF 82.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.description {
  margin: 36px 0;
  font-weight: 300;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 180%;
}

.explore-button {
  padding: 10px 28px;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
  border-radius: 6px;
}

.down-icon > div {
  margin-top: 100px;
}

@screen ipad {
  .content {
    width: 90vw;
  }

  .down-icon > div {
    margin-top: 0;
  }

  .content > h2 {
    font-size: clamp(48px, 7vw, 63px);
  }

  .description {
    font-size: 18px;
    margin-block: 24px;
  }
}

@screen phone {
  .content {
    width: 90%;
  }
}
</style>