<template>
  <div class="flex items-center cut-down-time">
    <SvgIcon name="icon_hourglass" class="icon-hourglass" />
    <p class="time-box">
      <span> {{ countdownDays || "0" }} Days </span>
      <span class="time">
        {{ countdownTime ? countdownHour : "00" }}
      </span>
      :
      <span class="time">
        {{ countdownTime ? countdownMinutes : "00" }}
      </span>
      :
      <span class="time">
        {{ countdownTime ? countdownSeconds : "00" }}
      </span>
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const emit = defineEmits("timeDone");

dayjs.extend(utc);
dayjs.extend(timezone);
const countdownTime = ref("");
const countdownHour = ref("");
const countdownMinutes = ref("");
const countdownSeconds = ref("");
const countdownDays = ref("");
const timerId = ref(null);

// 获取当前时间的东部标准时间（EST）
function getCurrentEST() {
  dayjs.tz.setDefault("America/New_York");
  return dayjs.tz().valueOf();
}

// 更新倒计时的函数
function updateCountdown() {
  // 当前时间
  const currentDate = getCurrentEST();
  // 目标时间
  const targetDate = dayjs.tz("2023-11-23 08:00:00").valueOf(); // 设置目标时间为11月9日早上8点EST
  // 计算剩余时间（单位：毫秒）
  const remainingTime = targetDate - currentDate;
  // 计算剩余时间的小时、分钟和秒数
  const day = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  countdownDays.value = day <= 0 ? 0 : day;
  countdownHour.value = hours < 10 ? "0" + hours : hours;
  countdownMinutes.value = minutes < 10 ? "0" + minutes : minutes;
  countdownSeconds.value = seconds < 10 ? "0" + seconds : seconds;
  // 将剩余时间显示在页面上
  countdownTime.value = `${hours}:${minutes}:${seconds}`;
  emit("timeDone", false);
  // 如果剩余时间小于等于0，停止倒计时
  if (remainingTime <= 0) {
    clearInterval(timerId.value);
    timerId.value = null;
    countdownTime.value = null;
    emit("timeDone", true);
  }
}

// 每秒钟更新一次倒计时
timerId.value = setInterval(updateCountdown, 1000);
</script>

<style lang="scss" scoped>
.time-box {
  font-size: calc(var(--base-size) * 0.36);
  color: #fff;
  text-align: center;
  font-family: Inter-variant;
  font-style: normal;
  font-weight: 600;
  margin-left: 24px;
}

.time {
  display: inline-block;
  width: calc(var(--base-size) * 0.45);
}

.icon-hourglass {
  max-width: 27.75px;
  max-height: 32.25px;
  width: calc(var(--base-size) * 0.2775);
  height: calc(var(--base-size) * 0.3225);
}
</style>
