import ecommerceSellerList from './assets/ECommerceSeller';
import artistList from './assets/Artist';
import contentCreatorList from './assets/ContentCreator';
import designerList from './assets/Designer';
import marketerList from './assets/Marketer';
import photographerList from './assets/Photographer';

export default [
  {
    key: '0',
    name: "E-commerce Seller",
    assets: ecommerceSellerList,
  },
  {
    key: '1',
    name: "Content Creator",
    assets: contentCreatorList,
  },
  {
    key: '2',
    name: "Marketer",
    assets: marketerList,
  },
  {
    key: '3',
    name: "Designer",
    assets: designerList,
  },
  {
    key: '4',
    name: "Photographer",
    assets: photographerList ,
  },
  {
    key: '5',
    name: "Artist",
    assets: artistList,
  },
];

export const routeMap: Record<string, string> = {
  'case-ecommerce-seller': '0',
  'case-content-creator': '1',
  'case-social-media-marketer': '2',
  'case-photographer': '4',
  'case-artist': '5',
};
