<template>
  <div class="part-two">
    <section class="text">
      <p class="paragraph paragraph-mb">
        In just clicks, {{currentProject.projectName}} can effortlessly produce polished,
        ready-to-use videos that capture attention and drive results. No need to
        brainstorm complex video ideas and no fancy equipment or technical
        skills necessary - our AI generates captivating videos that go beyond
        what you could conceive.
      </p>

      <p class="paragraph paragraph-mb">
        Just feed {{currentProject.projectName}}'s AI your raw assets - images, logo, text. It
        handles the rest - crafting tailored scripts, editing timing, music
        selection and flow, outputting captivating video content designed to
        resonate with your audience.
      </p>

      <p class="paragraph">
        But we don't restrict your creativity. Create videos to perfectly fit
        your brand with unrivaled freedom - just add your own flair and style in
        'editor' to make videos distinctly yours.
      </p>
    </section>

    <section class="text">
      <p class="paragraph-title text-42px">Key Highlights</p>
      <ul class="paragraph-content-list">
        <li>
          AI is core of {{currentProject.projectName}}, powering automated and customized video
          creation
        </li>
        <li>
          Range of options from fully automated AI Video Director to
          customizable templates to AI-assisted editor
        </li>
        <li>
          Solutions for users of all skill levels to make high-quality,
          effective brand videos easily
        </li>
      </ul>
    </section>

    <section class="text card-container">
      <p class="paragraph-title text-42px">At-a-glance</p>
      <ul
        class="card-content-list"
        :class="gridStyle"
        :style="`--base-size: ${baseSize}px`"
      >
        <li
          v-for="(card, index) in cardList"
          :key="index"
          class="card-content-item"
        >
          <p class="card-title">{{ card.title }}</p>
          <p class="card-content" v-for="item in card.content">{{ item }}</p>
        </li>
      </ul>
    </section>

    <section class="text" id="overview">
      <p class="paragraph-title text-42px">Overview</p>
      <p class="overview-paragraph overview-paragraph-mb">
        {{currentProject.projectName}} is the ultimate video generation and editing app that pushes
        the boundaries of creativity! No matter the kind of short video you
        envision, {{currentProject.projectName}} is your go-to platform for turning ideas into
        reality. One-click is all it takes to generate captivating videos that
        will leave your audience in awe.
      </p>
      <p class="overview-paragraph overview-paragraph-mb">
        {{currentProject.projectName}} is an essential tool for small business owners and creators.
        It transforms any static assets, including pictures or text, into
        high-quality short videos ready for your use (Reels, Ideal Pins, or
        video ads) directly.
      </p>

      <ul class="overview-paragraph-list overview-paragraph-mb">
        <li>Key features of {{currentProject.projectName}}:</li>
        <li>
          🎬 Feed our AI with existing images and text, and receive limitless
          cinematic videos in return.
        </li>
        <li>🔥 Customize your branding with logos, fonts, colors, and more.</li>
        <li>
          🤩 Generate unlimited variations of product videos with just one
          click.
        </li>
        <li>
          🤗 Add your own creatives! Explore our brand new powerful video
          editor.
        </li>
        <li>
          🎉 Store all your assets and content in an integrated cloud library.
        </li>
      </ul>

      <p class="overview-paragraph">
        Check out our feature intro on the product page:<span
          class="link"
          @click="handleOpenLink"
          >{{currentProject.url}}</span
        >
      </p>
    </section>

    <section class="text" id="plans">
      <p class="paragraph-title text-42px">Plans & features</p>
      <p class="item-title">Deal terms & conditions</p>
      <ul class="paragraph-content-list">
        <li>All future Custom Package updates</li>
        <li>
          If Package name changes, deal will be mapped to the new Package name
          with all accompanying updates
        </li>
        <li>Ability to upgrade or downgrade within the same type of package</li>
        <li>GDPR compliant</li>
        <li>
          7-day money-back guarantee. Try it out for 1 week to ensure it's right
          for you!
        </li>
        <li><span class="high-light">Features included in all plans</span></li>
      </ul>
    </section>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";

const globalDomain = inject('globalDomain');
const projectConfig = {
  BoolVideo: {
    name: 'Boolv.video',
    projectName: 'Boolvideo',
    url: 'https://boolv.video/',
    title: 'Boolvideo AI Video Generator - Terms',
  },
  SimilarVideo: {
    name: 'similarvideo.ai',
    projectName: 'Similarvideo',
    url: 'https://similarvideo.ai/',
    title: 'Similar Video Generator - Terms',
  },
};

const currentProject = computed(() => {
  if (globalDomain == 2) {
    return projectConfig.SimilarVideo;
  }

  if (globalDomain == 1) {
    return projectConfig.Boolvideo;
  }
});

const cardList = ref([
  {
    title: "Best for",
    content: ["E-commerce", "Marketers", "Content Creators"],
  },
  {
    title: "Alternative to",
    content: [
      "Final Cut Pro",
      "Adobe Premiere",
      "IMovie",
      "Vimeo",
      "Capcut",
      "invideo",
    ],
  },
  {
    title: "Integrations",
    content: ["Shopify", "GPT-4"],
  },
  {
    title: "Features",
    content: ["GDPR-compliant"],
  },
]);

const handleOpenLink = () => {
  window.open("https://boolv.video/");
};

const cardWidth = ref(0);

const baseSize = ref("");
const gridStyle = ref("");

const computedCardSize = () => {
  const windowWidth = window.innerWidth;
  if (windowWidth <= 570) {
    gridStyle.value = "custom-mini-grid";
  } else if (windowWidth > 570 && windowWidth <= 750) {
    gridStyle.value = "custom-phone-grid";
  } else if (windowWidth > 750 && windowWidth <= 1390) {
    gridStyle.value = "custom-ipad-grid";
  } else {
    gridStyle.value = "custom-ipad-grid";
  }
};

window.addEventListener("resize", () => {
  computedCardSize();
});

onMounted(async () => {
  computedCardSize();

  window.addEventListener("resize", () => {
    computedCardSize();
  });

  await nextTick();
  const observer = new ResizeObserver((entries) => {
    for (let entry of entries) {
      const target = entry.target;
      const { width } = target.getBoundingClientRect();
      cardWidth.value = width;
      baseSize.value = Math.min(100, ((width / 246) * 100) | 0);
      // computedCardSize()
    }
  });

  const element = document.getElementsByClassName("card-content-item")[0];
  observer.observe(element);
});
</script>
<style lang="scss" scoped>
.part-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;

  .text {
    padding-top: 120px;
    width: 100%;
    max-width: 1024px;
    text-align: left;
    color: #060606;
    font-family: Inter-variant;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 174%;

    .item-title {
      margin-top: 54px;
      color: #060606;
      font-family: Inter-variant;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 211%;
    }
  }

  .paragraph {
    line-height: 174%;
  }

  .paragraph-mb {
    margin-bottom: 27.84px;
  }

  .paragraph-title {
    color: #000;
    font-family: Inter-variant;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 54px;
  }

  .paragraph-content-list {
    padding-left: 24px;

    li {
      color: #060606;
      font-family: Inter-variant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 211%;
      list-style-type: disc;
    }
  }

  .card-content-list {
    width: 100%;
    max-width: 1026px;

    li {
      width: 100%;
      aspect-ratio: 246 / 328;
      border-radius: calc(var(--base-size) * 0.18);
      border: 1px solid #e9e9e9;
      background: #fff;
      display: flex;
      flex-direction: column;
      padding-top: calc(var(--base-size) * 0.42);
      padding-left: calc(var(--base-size) * 0.48);
    }

    .card-title {
      margin-bottom: calc(var(--base-size) * 0.21);
      color: #060606;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.16);
      font-style: normal;
      font-weight: 700;
      line-height: 211%;
    }

    .card-content {
      color: #060606;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.16);
      font-style: normal;
      font-weight: 400;
      line-height: 211%;
    }
  }

  .overview-paragraph {
    line-height: 211%;

    .link {
      color: #6741ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .overview-paragraph-mb {
    margin-bottom: 33.76px;
  }

  .overview-paragraph-list {
    li {
      color: #060606;
      font-family: Inter-variant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 211%;
    }

    .high-light {
      color: #ff5449;
    }

    .link {
      color: #6741ff;
    }
  }

  .paragraph-content-list {
    li {
      color: #060606;
      font-family: Inter-variant;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 211%;
    }

    .high-light {
      color: #ff5449;
    }
  }
}

.card-container {
  .custom-mini-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .card-content-item {
      aspect-ratio: 246 / 328;
      max-width: 300px;
      margin-bottom: 14px;
    }

    .card-content-item:last-child {
      margin-bottom: 0px;
    }
  }

  .custom-phone-grid {
    display: grid;
    grid-row-gap: 14px;
    grid-column-gap: 14px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .card-content-item {
      aspect-ratio: 246 / 328;
    }

    .card-content-item:last-child {
      margin-bottom: 0px;
    }
  }

  .custom-ipad-grid {
    display: grid;
    grid-row-gap: 14px;
    grid-column-gap: 14px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .card-content-item {
      aspect-ratio: 246 / 328;
      max-width: 246px;
    }
  }
}
</style>
