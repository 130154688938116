<template>

  <form-item>
    <template #label>
      <p class="flex items-center">Type
        <svg-icon class="cursor-pointer" name="icon_describe" style="margin-left: 6px" :size="18"
          @click="modalOpenState = true"></svg-icon>
      </p>
    </template>

    <el-select v-model="type" :popper-append-to-body="false" popper-class="feature-edit-select" placeholder="Select"
      size="large" :suffix-icon="SelectSuffix" :popper-options="{
          modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
        }" @change="handleChangeType">
      <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
        <div class="flex justify-between" :class="{ 'active': type == item.value }">
          <p class="label">{{ item.label }}</p>
          <p v-if="type == item.value"><svg-icon name="icon_option_selected" :size="18"></svg-icon></p>
        </div>
      </el-option>
    </el-select>

    <div class="voice-time voice-time-flex" v-if="type == '1'">
      <div class="title flex flex-col ">
        <div class="time-num flex">{{ showDuration }}</div>
        <p class="time-tip">Maximum duration</p>
      </div>
      <bv-slider class="slider-box" v-model="duration"  show-stops :show-tooltip="false" :max="5" :min="0.5" :step="0.5"
        @input="handleChangeTime" />
    </div>

    <TypeDiffModal :open="modalOpenState" @update:open="(value) => modalOpenState = value" />
  </form-item>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import FormItem from './form-item.vue'
import SelectSuffix from "@/components/common/selectSuffix.vue";
import TypeDiffModal from '../typeDiffModal/index.vue';

const props = defineProps({
  value: String
})

const emit = defineEmits(['update:value', 'update:duration'])

const modalOpenState = ref(false);
const type = ref('0')

const duration = ref(0)
const typeOptions = [
  {
    value: '0',
    label: 'No Al voiceover',
  },
  {
    value: '1',
    label: 'With AI voiceover',
  },
]

const showDuration = computed(() => {
  return duration.value < 1 ? `${duration.value * 60} s` : `${duration.value} min`
})

watch(() => props.value, (value) => {
  type.value = value
})

const handleChangeType = (item) => {
  emit('update:value', item)
}

const handleChangeTime = (value) => {
  duration.value = value
  emit('update:duration', value)
}


</script>

<style lang="scss">
@import './common.scss';
</style>

<style lang="scss" scoped>
.voice-time {
  width: 100%;
  min-height: 76px;
  border-radius: 6px;
  background: #F7F7F8;
  margin-top: 18px;
  padding: 16px 21px;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  .title {
    width: 126px;
    margin-right: 30px;
  }

  .time-num {
    color: #060606
  }

  .time-tip {
    color: #646A73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .voice-slider {
    flex: 1
  }
}

:deep(.voice-time .el-slider__stop),
:deep(.voice-time .el-slider__runway::after),
:deep(.voice-time .el-slider__runway::before)  {
  width: 1px;
  height: 2px;
  background: #8F959E;
}


.voice-time-flex {
  display: flex;
}

.slider-box {
  flex: 1;
}

@media only screen and (max-width: 750px) {
  .voice-time-flex {
    display: block;
  }

  .slider-box {
    margin-top: 12px;
  }
}
</style>