<template>
  <div class="landing-page-layout">
    <HomeHeaderInPC v-if="isPC" />
    <DefaultHeaderInPE v-else-if="!isPC" />
    <Content v-track:exposure="'boolvideo_deal_page_view'"></Content>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useWindowSize } from "@vueuse/core";
import { tailwindConfig as sharedConfig } from "@/configs/shared.config.ts";

import HomeHeaderInPC from "./HomeHeaderInPC.vue";
import DefaultHeaderInPE from "./DefaultHeaderInPE.vue";
import Content from "./content.vue";

const route = useRoute();
const { width: innerWidth } = useWindowSize();
const isPC = computed(() => sharedConfig.screens.phone.max < innerWidth.value);
</script>
