<template>
  <div>
    <div
      class="collection-item"
      :class="{ 'active-item': innerItem.isSelected || innerItem.isPlay }"
      @click="handleSelected(innerItem)"
    >
      <div class="image-box" @click.stop="handlePlay(innerItem)">
        <img
          :src="innerItem.coverPic"
          v-if="innerItem.coverPic"
          class="coverPic"
        />
        <audio
          ref="audioRef"
          :src="innerItem.url"
          loop
          preload="metadata"
        ></audio>
        <img
          :src="icon_play_video"
          class="icon-play"
          v-if="!innerItem.isPlay"
        />
        <img
          :src="icon_playing"
          class="icon_playing"
          v-if="innerItem.isPlay"
          @click.stop="handlePlay(innerItem)"
        />
      </div>
      <div class="detail">
        <div class="flex items-start">
          <p class="video-name">{{ innerItem.name }}</p>
          <div class="image-select">
            <img
              :src="icon_selected"
              v-if="innerItem.isSelected"
              @click.stop="handleCancelSelected(innerItem)"
            />
            <img
              :src="icon_select"
              v-else
              @click.stop="handleSelected(innerItem)"
            />
          </div>
        </div>
        <p class="time" v-if="innerItem.isPlay">
          <span class="time-number">{{ formatDuration(currentFrame) }}</span> /
          <span class="time-number">{{ formatDuration(duration) }}</span>
        </p>

        <p class="time" v-else>{{ formatDuration(duration) }}</p>
        <div @click.stop="(e) => {}">
          <el-slider
            v-model="currentFrame"
            v-if="innerItem.isPlay"
            @input="handleSeekTo"
            :show-tooltip="false"
            :step="1"
            :min="0"
            :max="Math.ceil(duration)"
          />
        </div>
      </div>
    </div>
    <teleport :to="previewerRoot">
      <material-previewer
        ref="previewerRef"
        :record="previewData"
        @change="changePreview"
        :canScroll="false"
      />
    </teleport>
  </div>
</template>
<script setup>
import { ref, nextTick } from 'vue';
import header from '@/assets/similar-video/header_portrait.png';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
import icon_play_video from '@/assets/similar-video/icon_play_video.svg';
import icon_playing from '@/assets/similar-video/icon_playing.svg';
import icon_scale from '@/assets/similar-video/icon_scale.svg';
import MaterialPreviewer from '@/components/material-previewer/index.vue';

const props = defineProps({
  item: Object,
  list: Array,
  selectedMediaId: Number,
});

const emit = defineEmits([
  'onSelected',
  'onPlay',
  'onPause',
  'onCancelSelected',
]);

const previewerRoot = document.createElement('div');
document.body.appendChild(previewerRoot);

const audioRef = ref(null);
const currentFrame = ref(0);
const innerItem = ref(props.item);

const previewList = ref([]);
const previewData = ref(null);
const previewerRef = ref(null);

const duration = ref(0);

watch(
  () => props.list,
  (list) => {
    previewList.value = list
      .filter((item) => item.url)
      .map((image) => {
        return {
          mid: image.mediaId,
          coverPic: image.coverPic,
          preview1080Url: image.url,
          type: image.type || 'video',
        };
      });
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(
  props.item,
  (value) => {
    previewData.value = {
      mid: value.mediaId,
      coverPic: value.coverPic,
      preview1080Url: value.url,
      type: value.type || 'video',
      duration: value.duration,
    };
  },
  {
    immediate: true,
  }
);

const changePreview = (mid, direction) => {
  const index = previewList.value?.findIndex((e) => e.mid == mid);
  let item = null;
  if (direction == 1) {
    item = previewList.value[index + 1];
  } else if (direction == -1) {
    item = previewList.value[index - 1];
  }

  if (!item) {
    return;
  }

  previewData.value = {
    mid: item.mid,
    preview1080Url: item.preview1080Url || item.url,
    type: item.type || 'video',
    coverPic: item.coverPic,
    duration: item.duration,
  };
};

const handlePreview = (item) => {
  previewData.value = {
    mid: item.mediaId,
    preview1080Url: item.url,
    type: item.type || 'video',
    coverPic: item.coverPic,
    duration: item.duration,
  };

  previewerRef.value = true;
};

const handleCancelSelected = (item) => {
  emit('onCancelSelected', item.mediaId);
};

const handleSelected = (item) => {
  item.isSelected = !item.isSelected;

  if (!item.isSelected) {
    emit('onCancelSelected', item.mediaId);
    emit('onPause', item.mediaId);
    return;
  }

  emit('onSelected', item.mediaId, item.voiceId);
  emit('onPlay', item.mediaId);
};

const handleScale = (item) => {};

function formatDuration(duration) {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${(minutes | 0).toString().padStart(2, '0')}:${(seconds | 0)
    .toString()
    .padStart(2, '0')}`;
}

const handlePlay = async (item) => {
  item.isPlay = !item.isPlay;

  if (item.isPlay == false) {
    currentFrame.value = 0;
    emit('onPause', item.mediaId);
    audioRef.value.pause();
    return;
  }

  emit('onPlay', item.mediaId);
  emit('onSelected', item.mediaId, item.voiceId);
  audioRef.value.play();
};

const handleSeekTo = (value) => {
  audioRef.value.currentTime = value;
};

watch(
  () => props.item.isSelected,
  (isSelected) => {
    if (!isSelected && audioRef.value) {
      audioRef.value.pause();
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.item.isPlay,
  (isPlay) => {
    if (!isPlay && audioRef.value) {
      audioRef.value.pause();
    }

    if (isPlay) {
      innerItem.value.isSelected = true;
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.selectedMediaId,
  () => {
    if (props.item.mediaId == props.selectedMediaId) {
      innerItem.isSelected = true;
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => audioRef.value,
  (audioRef) => {
    if (audioRef) {
      addAudioEvents();
    }
  },
  {
    immediate: true,
  }
);

function addAudioEvents() {
  const audio = audioRef.value;

  audio.addEventListener('loadedmetadata', handleAudioLoadMetadata);
  audio.addEventListener('timeupdate', handleAudioTimeupdate);
};

function handleAudioLoadMetadata() {
  duration.value = audioRef.value.duration;
}

function handleAudioTimeupdate() {
  currentFrame.value = audioRef.value.currentTime;
}

onBeforeUnmount(() => {
  const audio = audioRef.value;

  if (audioRef.value) {
    audio.removeEventListener('loadedmetadata', handleAudioLoadMetadata);
    audio.removeEventListener('timeupdate', handleAudioTimeupdate);
  }
  audioRef.value = null;
})
</script>
<style lang="scss" scoped>
.collection-item {
  width: 100%;
  cursor: pointer;
  height: 124px;
  padding: 12px;
  display: flex;
  border-radius: 8px;

  &:hover {
    background: #f8f5ff;
  }

  :deep(.el-slider) {
    max-width: 187px;
  }

  :deep(.el-slider__runway) {
    height: 4px;
    background: #e5e7eb;
  }

  :deep(.el-slider__bar) {
    height: 4px;
  }

  :deep(.el-slider__button) {
    border: 1px solid #e5e7eb;
    transition: none;
    width: 14px;
    height: 14px;
    box-shadow: 2px 2px 4px #eae8e8;
    transform: none;
    &:hover {
      width: 14px;
      height: 14px;
      transform: none;
    }
  }

  :deep(.el-slider__button-wrapper) {
    top: -16px;
    &:hover {
      :deep(.el-slider__button) {
        transform: none;
      }
    }
  }

  .video-name {
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 36px;
    flex: 1;
  }

  .image-select {
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.active-item {
  background: #f8f5ff;
}

.detail {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  flex: 1;
}

.time {
  color: #646a73;
  margin-top: 6px;
  line-height: 22px;
  display: flex;
}

.time-number {
  display: block;
  width: 45px;
  text-align: center;
}

.image-box {
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 4px;
  position: relative;
  margin-right: 12px;
  background: #00be57;
  .coverPic {
    width: 100px;
    height: 100px;
  }
  audio {
    width: 100px;
    height: 100px;
    border-radius: 4px;
  }
}

.icon-play {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  width: 36px;
  height: 36px;
}

.icon_playing {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  width: 36px;
  height: 36px;
}
</style>
