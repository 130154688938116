<template>
  <li class="square-container">
    <div
      :class="{
        'square-box cursor-pointer border-2 ': true,
        'border-white': !isActive || selectedLogoUrl !== innerItem.url,
        'hover:border-hoverColor border-2 ': !isActive,
        'border-primaryColor': selectedLogoUrl == innerItem.url,
      }"
      @mousemove="handleMouseMove"
      @mouseleave="handleMouseLeave"
    >
      <el-image
        style="width: 100%; height: 100%"
        :src="innerItem.url"
        :zoom-rate="1.2"
        :initial-index="4"
        fit="contain"
      >
      </el-image>
    </div>
  </li>
</template>
<script setup>
import { ref, useSlots } from "vue";

const slots = useSlots();
const props = defineProps({
  isActive: Boolean,
  selectedLogoUrl: {
    type: String,
    default: "",
  },
  item: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const innerItem = ref(props.item);
const isMove = ref(false);

const handleMouseMove = () => {
  isMove.value = true;
};

const handleMouseLeave = () => {
  isMove.value = false;
};
</script>

<style lang="scss" scoped>
.square-container {
  padding-bottom: 100%;
  height: 0;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.square-container .square-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f7f7f8;
}

.square-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.mask {
  opacity: 54%;
}
</style>

<style lang="scss">

.square-container {
  .el-image__placeholder {
    background: #f7f7f8;
  }
}

.square-container {
  .el-progress-bar__outer {
    background-color: #7a7b7e;
    border-radius: 0;
    height: 4px !important;
  }

  .el-progress-bar__inner {
    background-color: #fff;
    border-radius: 0;
  }
}
</style>
