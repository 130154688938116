<template>
  <div class="h-full w-full relative overflow-auto">
    <Upload
      @nextStep="handleNextStep"
      @onReadFileComplete="handleReadFileComplete"
      @onSelectCase="handleSelectCase"
      @onSelectCloud="handleSelectCloud"
      v-if="step == 1"
      @onLoading="handleLoading"
    />
    <FeatureEdit
      v-if="step == 2"
      :imageList="imageList"
      :productInfo="productInfo"
      @onLoading="handleLoading"
      @onGenerated="handleGenerated"
      uploadType="image"
      :fileList="fileList"
    />
    <div class="loading-mask" v-if="loading"></div>
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onBeforeMount, watch } from 'vue';
import Upload from './ImportFile.vue';
import FeatureEdit from './components/featureEdit/index.vue';
import { visualsToVideoRender } from '@/api/index';
import { useRouter, useRoute } from 'vue-router';
import { sessionSetItem, sessionGetItem, sessionRemoveItem } from '@/utils/storage';
import { useSingleMessage } from '@/utils';
import { useTrackStore } from '@/store/modules/track';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useFeatureEdit } from './components/featureEdit/store/index.js';
import { validImport } from '@/pages/createVideos/utils/import';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import { checkResource } from '@/api/script';

const modalManager = useModalManager();
const featureEditStore = useFeatureEdit();
const { clearEventData, collectData, track } = useTrackStore();
const Message = useSingleMessage();
const router = useRouter();
const route = useRoute();

const step = ref(1);
const imageList = ref([]);
const productInfo = ref({});
const loading = ref(false);
const generateLoading = ref(false);
const leftPage = ref(false);
const fileList = ref([]);
const subscribeModalVisible = ref(false);

clearEventData('boolvideo_create_input');

const trackSubmit = () => {
  collectData('boolvideo_create_upload', {
    video_type: 'visuals_to_video',
  });
  track('boolvideo_create_upload');
};

const handleNextStep = ({ _imageList = [], _productInfo = {} }) => {
  imageList.value = _imageList;
  productInfo.value = _productInfo;
};

const handleReadFileComplete = (files) => {
  fileList.value = files;
  productInfo.value = [];
  step.value = 2;
  sessionRemoveItem('caseInfo');
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      type: 'feature',
    },
  });

  trackSubmit();
};

const handleSelectCase = (list, info) => {
  imageList.value = list;
  productInfo.value = { ...productInfo.value, ...info };
  step.value = 2;

  let cacheForm = sessionGetItem('cacheForm') || {};
  cacheForm = { ...cacheForm, ...productInfo.value };
  sessionSetItem('cacheForm', cacheForm);
  sessionSetItem('caseInfo', info);
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      type: 'feature',
    },
  });

  trackSubmit();
};

const handleSelectCloud = (list) => {
  imageList.value = list;
  productInfo.value = [];
  step.value = 2;
  sessionRemoveItem('caseInfo');
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      type: 'feature',
    },
  });

  trackSubmit();
};

const handleLoading = (value) => {
  loading.value = value;
};

const handleGenerated = async (formState, cacheForm) => {
  // 生成接口
  generateLoading.value = true;
  formState.caseId = productInfo.value.caseId;
  formState.caseMaterial = productInfo.value.caseMaterial
    ?.map((material) => {
      if (formState.mids.includes(material.mid)) {
        return material;
      }
    })
    .filter((item) => item);

  cacheForm.caseId = productInfo.value.caseId;
  cacheForm.caseMaterial = productInfo.value.caseMaterial;

  sessionSetItem('generateParams', formState);
  sessionSetItem('cacheForm', cacheForm);

  if (formState.type == 0) {
    router.push({
      path: '/visuals-to-video/generating',
    });
    return;
  }

  const { noCredit, msg, code } = await checkResource({
    resource: [90001, 70001],
  });

  if (code == 101011) {
    modalManager.applyTemplate('paymentFail', {});
    return;
  }

  if (noCredit) {
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  router.push({
    path: '/visuals-to-video/voice/generating',
  });
};

watch(
  () => route.query.type,
  (type) => {
    if (type == 'feature') {
      step.value = 2;
      const cacheForm = sessionGetItem('cacheForm');
      if (!cacheForm) {
        return;
      }

      imageList.value = cacheForm.cacheImageList;
      productInfo.value = {
        productDesc: cacheForm.productDesc,
        bkId: cacheForm.bkId,
        duration: cacheForm.duration,
        effectCustomization: cacheForm.effectCustomization,
        language: cacheForm.language,
        purposeIds: cacheForm.purposeIds,
        size: cacheForm.size,
        type: cacheForm.type,
        url: cacheForm.url,
      };

      if (cacheForm.caseId) {
        productInfo.value.caseId = cacheForm.caseId;
      }

      if (cacheForm.caseMaterial) {
        productInfo.value.caseMaterial = cacheForm.caseMaterial;
      }

      return;
    }
    step.value = 1;
  },
  {
    immediate: true,
  }
);

onBeforeMount(async () => {
  await featureEditStore.getVideoPurpose();
});

onBeforeUnmount(() => {
  leftPage.value = true;
});
</script>

<style lang="scss" scoped>
.loading-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.35);
}

.loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>
