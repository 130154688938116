<template>
  <div
    :ref="pointerMove.ref"
    class="relative w-full h-full flex justify-center"
    :class="{ 'cursor-move': isDrag }"
    :style="{ left: position.left + 'px', top: position.top + 'px' }"
  >
    <slot></slot>
  </div>
</template>
<script setup>
import { watch } from "vue";
import { usePointerMove } from "@/utils/hook";

const props = defineProps({
  isDrag: Boolean,
  isRest: Boolean,
});

const position = reactive({ left: 0, top: 0 });
console.log("aaaaaa");
watch(
  () => props.isRest,
  (value) => {
    if (value) {
      console.log(value, "11111");
      position.left = 0;
      position.top = 0;
    }
  },
);

const pointerMoveConfig = {
  handler: (e) => {
    if (e.state !== "move") return;
    if (!props.isDrag) {
      e.abort();
      return;
    }

    Object.assign(position, {
      left: position.left + e.deltaX,
      top: position.top + e.deltaY,
    });
  },
};

const pointerMove = usePointerMove(pointerMoveConfig);

defineExpose({ position, el: pointerMove.ref });
</script>

<style></style>
