<template>
  <form-item label="Idea prompt" class="form-item">
    <el-input 
      class="perfect-scrollbar" 
      type="textarea" 
      :rows="6" 
      v-model="productDesc" 
      @input="handleInputProductDesc"
      @blur="handleBlur"
      placeholder="Describe your product style, product selling point, or brand story." />
  </form-item>
</template>

<script setup>
import { ref, watch } from 'vue'
import { removeEscapeHtml } from "@/utils";
import FormItem from './form-item.vue'

const props = defineProps({
  value: String,
})

const emit = defineEmits(['update:value'])

const productDesc = ref('')

watch(() => props.value, (value) => {
  productDesc.value = value
}, {
  immediate: true
})

const handleInputProductDesc = (inputValue) => {
  if (inputValue.trim() === '') {
    productDesc.value = ''
  } else {
    productDesc.value = inputValue
  }
  emit('update:value', productDesc.value)
};

const handleBlur = () => {
  productDesc.value = removeEscapeHtml(productDesc.value);
  emit('update:value', productDesc.value);
};

</script>

<style lang="scss">
.form-item .el-textarea .el-textarea__inner {
  border-radius: 6px;
  color: #060606 !important;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 18px 16px;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }


  &:focus {
    box-shadow: 0 0 0 1px #875eff inset !important;
  }


  --sb-track-color: transparent;
  --sb-thumb-color: rgba(0, 0, 0, 0.25);
  --sb-size: 12px;
  &::-webkit-scrollbar {
    width: var(--sb-size);
  }

  &::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--sb-thumb-color)
  }
}
</style>
