<template>
  <div class="content-container">
    <div class="other-container">
      <div class="col">
        <svg-icon 
          name="script_volume"
          :size="18"
        />
        <span>Voice {{ Math.round(volume * 100) + "%" }}</span>
        <bv-slider
          :show-tooltip="false"
          :model-value="volume"
          :min="0"
          :max="1"
          :step="0.01"
          @input="(v) => $emit('update:volume', v)"
        />
      </div>
      <div class="col">
        <svg-icon 
          name="script_speed"
          :size="18"
        />
        <span>Speed {{ Math.round(speed * 100) + "%" }}</span>
        <bv-slider
          show-stops
          :show-tooltip="false"
          :model-value="speed"
          :min="0.5"
          :max="2"
          :step="0.25"
          @input="(v) => $emit('update:speed', v)"
        />
      </div>
    </div>
    <div class="filter-container">
      <div class="left-header">
        <div class="language">
          <p>Language</p>
          <bv-tip
            :content="languageOptions.find(item => item.value === language)?.label"
            placement="top"
          >
            <bv-select
              v-model="language"
              popper-class="idea-language-select"
              searchPlaceholder="Search"
              :key="switchId"
              :showSearch="true"
              :popper-style="{ width: 'calc((max(65vw, 840px) - 102px) / 3)' }"
              :options="languageOptions"
              @change="languageInput"
            />
          </bv-tip>
        </div>
        <div class="gender">
          <p>Gender</p>
          <bv-tip
            :content="genderOptions.find(item => item.value === gender)?.label"
            placement="top"
          >
            <bv-select
              v-model="gender"
              :key="switchId"
              :popper-style="{ width: 'calc((max(65vw, 840px) - 102px) / 3)' }"
              :options="genderOptions"
              @change="genderInput"
            />
          </bv-tip>
        </div>
        <div class="style">
          <p>Style</p>
          <bv-tip
            :content="styleOptions.find(item => item.value === style)?.label"
            placement="top"
          >
            <bv-select
              v-model="style"
              popper-class="idea-language-select"
              :key="switchId"
              :popper-style="{ width: 'calc((max(65vw, 840px) - 102px) / 3)' }"
              :options="styleOptions"
              @change="styleInput"
            />
          </bv-tip>
        </div>
      </div>
    </div>
    <div class="language-container">
      <div class="left">
        <div class="left-content">
          <bv-tip
            v-for="item in voiceList"
            :content="item.localName"
            placement="top"
          >
            <div 
              class="voice-item"
              :class="{active: currentVoice?.shortName === item.shortName}"
              @click.capture="clickVoice(item)"
            > 
              <div class="voice-item-left">
                <svg-icon
                  :name="item.gender === 'Male' ? 'script_male': 'script_female'"
                  :color="currentVoice?.shortName === item.shortName ? '#6741FF' : '#646A73'"
                  :size="18"
                />
                <span>{{ item.localName }}</span>
              </div>
              <svg-icon
                v-if="currentAudio.playing && currentAudio.loading && item.styleList.some(i => i.audioFileEndpointWithSas === currentAudio.url)"
                name="icon_loading"
                class="animate-spin duration-300 p-0.5 button-loading"
                :size="24"
                :color="currentVoice?.shortName === item.shortName ? '#6741FF' : '#646A73'"
              />
              <svg-icon
                v-else-if="currentAudio.playing && item.styleList.some(i => i.audioFileEndpointWithSas === currentAudio.url)"
                name="script_pause"
                :size="24"
                :color="currentVoice?.shortName === item.shortName ? '#6741FF' : '#646A73'"
                @click="$emit('pause')"
              />
              <svg-icon
                v-else
                name="script_play"
                :color="currentVoice?.shortName === item.shortName ? '#6741FF' : '#646A73'"
                :size="24"
                @click="$emit('play', currentStyle.audioFileEndpointWithSas)"
              />
            </div>
          </bv-tip>
        </div>
      </div>
      <div class="right">
        <el-scrollbar
          class="style-scrollbar"
        >
          <div class="style-list">
            <div
              v-for="item in styleList"
              class="style-item"
              :class="{ active: currentStyle.styleName === item.styleName }"
              @click.capture="clickStyle(item)"
            >
              <div class="style-item-left">
                <svg-icon
                  :name="getEmoji(item.styleName)"
                  :size="18"
                />
                <span>{{ capitalizeFirstLetter(item.styleName) }}</span>
              </div>
              <svg-icon
                v-if="currentAudio.playing && currentAudio.loading && item.audioFileEndpointWithSas === currentAudio.url"
                name="icon_loading"
                class="animate-spin duration-300 p-0.5 button-loading"
                :size="24"
                :color="currentStyle.styleName === item.styleName ? '#6741FF' : '#646A73'"
              />
              <svg-icon
                v-else-if="currentAudio.playing && item.audioFileEndpointWithSas === currentAudio.url"
                name="script_pause"
                :size="24"
                :color="currentStyle.styleName === item.styleName ? '#6741FF' : '#646A73'"
                @click="$emit('pause')"
              />
              <svg-icon
                v-else="!(currentAudio.playing && item.audioFileEndpointWithSas === currentAudio.url)"
                name="script_play"
                :color="currentStyle.styleName === item.styleName ? '#6741FF' : '#646A73'"
                :size="24"
                @click="$emit('play', item.audioFileEndpointWithSas)"
              />
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getLanguageList, queryVoiceList } from "@/api/language";
import { styleEmojiList } from "@/constants/style";
import { useScriptStore } from "../../stores/script";
import { capitalizeFirstLetter, formatStyle, extractLocale } from "../../utils";

const props = defineProps({
  node: Object,
  currentAudio: Object,
  currentVoice: Object,
  currentStyle: Object,
  volume: Number,
  speed: Number,
});
const emits = defineEmits([
  "play",
  "pause",
  "update:speed",
  "update:volume",
  "selectVoice", 
  "selectStyle"
]);

const { 
  defaultVoiceStyle,
  defaultVoiceName,
  languageList,
} = useScriptStore();

const switchId = ref(0);
const styleOptions = ref([]);
const genderOptions = computed(() => {
  const target = languageList.value.find(item => 
    item.locale === language.value
  );
  const res = [{ label: "All", value: "All"}];
  for (const item of target.genders) {
    res.push({ label: item, value: item})
  }
  return res;
});
const languageOptions = computed(() => {
  return languageList.value.map(item => ({ 
    label: item.localName, 
    value: item.locale,
  }));
});
const voiceList = ref([]);
const style = ref("All");
const gender = ref("All");
const language = ref(extractLocale(props.node.conf.voiceName));
const initStyle = ref(null);
const initVoice = ref(null);

const styleList = computed(() => {
  const { currentVoice } = props;
  if(!currentVoice) return [];
  else {
    const voice = voiceList.value.find(item => 
      item.shortName === currentVoice.shortName
    );
    if (!voice) return [];
    else {
      const { styleList } = voice;
      return styleList; 
    }
  }
});

const getEmoji = (style) => {
  return styleEmojiList.find(i => {
    return i.label === formatStyle(style)
  })?.icon
};

const clickVoice = (item) => {
  if (item.shortName == props.currentVoice.shortName) return ;
  emits("selectVoice", item);
  emits("selectStyle", item.styleList[0]);
};

const clickStyle = (item) => {
  emits("selectStyle", item);
};

const languageInput = (value) => {
  language.value = value;
  gender.value = "All";
  style.value = "All";
  getVoiceList().then(data => {
    emits("selectVoice", data[0]);
    emits("selectStyle", data[0]?.styleList[0] || null);
  });
};

const genderInput = (value) => {
  gender.value = value;
  style.value = "All";
  getVoiceList().then(data => {
    emits("selectVoice", data[0]);
    emits("selectStyle", data[0]?.styleList[0] || null);
  });
};

const styleInput = (value) => {
  style.value = value;
  getVoiceList().then(data => {
    emits("selectVoice", data[0]);
    emits("selectStyle", data[0]?.styleList[0] || null);
  });
}

const getStyleOptions = async (params) => {
  const helper = (list) => {
    const target = list.find(item => 
      item.locale === language.value
    );
    const res = [{ label: "All", value: "All"}];
    for (const item of target.styleNames) {
      res.push({ label: capitalizeFirstLetter(item), value: item})
    }
    return res;
  }
  const res = await getLanguageList(params);
  let temp = [];
  res.data.forEach(item => {
    temp = [ ...temp, ...item.localInfoList ];
  });

  styleOptions.value = helper(temp);
  style.value = styleOptions.value[0].value;
};

const getVoiceList = async () => {
  const params = {
    locale: language.value,
    gender: gender.value === "All" ? "" : gender.value,
    style: style.value === "All" ? "" : style.value,
  };
  const res = await queryVoiceList(params);
  if (!res.success) return;
  const { data } = res;
  voiceList.value = data;

  return data;
};

const setup = async () => {
  const { 
    voiceName = defaultVoiceName.value,
    voiceStyle = defaultVoiceStyle.value,
  } = props.node.conf;
  await getVoiceList()
    .then(() => {
      initVoice.value = voiceList.value.find(
        item => item.shortName === voiceName
      );
      initStyle.value = initVoice.value.styleList.find(
        item => item.styleName === voiceStyle
      ) || initVoice.value.styleList[0];
      emits("selectVoice", initVoice.value);
      emits("selectStyle", initStyle.value);
    });
  getStyleOptions();
};

watch(
  [gender, language],
  () => {
    const params = {
      locale: language.value,
      gender: gender.value === "All" ? "" : gender.value,
    };
    getStyleOptions(params);
  },
);

watch(
  () => props.currentVoice,

);

onActivated(async () => {
  const { currentVoice, currentStyle } = props;
  if (!currentVoice) return;
  const needRefresh = !voiceList.value.some(item => 
    item.shortName === currentVoice.shortName
  );
  
  if (needRefresh) {
    language.value = currentVoice.locale;
    gender.value = "All";
    style.value = "All";
    await getVoiceList();
  }
  const newVoice = voiceList.value.find(item => 
    item.shortName === currentVoice.shortName
  );
  const newStyle = newVoice.styleList.find(item => 
    item.styleName === currentStyle.styleName
  );
  emits("selectVoice", newVoice);
  emits("selectStyle", newStyle);
  switchId.value ++;
});

onDeactivated(() => {
  emits("pause");
});

onBeforeMount(setup);
</script>
<style lang="scss">
.bv-select-dropdown.idea-language-select ul{
  max-height: 220px;
}
</style>
<style lang="scss" scoped>
.content-container {
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.other-container {
  width: 100%;
  flex: 0 0 110px;
  padding: 30px 30px 53px;
  display: flex;
  gap: 24px;
}

.col {
  flex: 1 1;
  display: flex;
  align-items: center;
  color: #060606;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  & > span {
    margin-left: 10px;
    width: 80px;
  }

  & > .el-slider {
    margin-left: 14px;
    flex: 1 1;
  }
}
.filter-container {
  padding: 0 30px;
}

.left{
  flex: 1 1;
}

.language-container {
  flex: 1 1;
  min-height: 0;
  padding: 0 30px;
  display: flex;
  color: #060606;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; 
  gap: 10px;
}

.left-header {
  display: flex;
  gap: 21px;
  margin-bottom: 40px;

  & div > p {
    margin-bottom: 12px;
  }
}
.language {
  flex: 1 1;
  min-width: 0;
}

.gender {
  flex: 1 1;
  min-width: 0;
}

.style-scrollbar {
  height: 100%;
  padding-left: 10px;
  border-left: 1px solid #E5E7EB;
}

.style {
  flex: 1 1;
  min-width: 0;
}

.left-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
  margin-bottom: 150px;
}

.voice-item {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #F3F5F7;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.08s;
}

.voice-item.active {
  color: #6741FF;
  background-color: #F8F5FF;
  border-color: #6741FF;
}

.voice-item-left {
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 6px;
  }

  & > span {
    width: 96px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
  }
}

.right {
  width: calc((max(65vw, 840px) - 123px) / 4);
  min-width: 0;
}

.style-item {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 7px 6px 7px 9px;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: transparent;
  transition: all 0.08s;
  cursor: pointer;

  &:hover {
    background-color: #F3F5F7;
  }

  &.active {
    border-color: #6741FF;
    background-color: #F8F5FF;
    color: #6741FF;
    
  }
}

.style-item + .style-item {
  margin-top: 4px;
}

.style-item-left {
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 5px;
  }

  & > span {
    width: 96px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
  }
}

.button-loading {
  cursor: default;
}
</style>