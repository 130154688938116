<template>
  <div
    class="edit-container"
    :class="{ islock: activeBrand?.lock }"
    v-if="mounted"
  >
    <div class="tip" v-if="activeBrand?.lock">
      <span class="left">
        <img :src="lockIcon" alt="" />
        This brand kit has been locked, upgrade to unlock.
      </span>
      <span class="upgrade" @click="openPremiumInTab">Upgrade</span>
    </div>
    <teleport to="#workspace-header">
      <div class="back" @click.stop="router.back">
        <img :src="back" alt="" />
        <span>Back</span>
      </div>
    </teleport>
    <el-form
      label-position="top"
      :model="activeBrand"
      style="width: 50%; min-width: 220px; max-width: 720px"
      v-if="activeBrand"
    >
      <el-form-item label="Brand name">
        <el-input
          placeholder="Enter brand name"
          v-model="activeBrand.name"
          show-word-limit
          maxlength="100"
        />
      </el-form-item>
      <el-form-item label="Slogan" style="margin-bottom: 42px">
        <slogan-list v-model="activeBrand.slogans" placeholder="Enter slogan" />
      </el-form-item>
      <el-form-item label="About your brand">
        <el-input
          :class="{ 'perfect-scrollbar': true, isFocus: areaFocus }"
          type="textarea"
          placeholder="Tell us about your brand..."
          v-model="activeBrand.aboutBrand"
          show-word-limit
          maxlength="2000"
          @focus="() => changeAreaFocus(true)"
          @blur="() => changeAreaFocus(false)"
        />
      </el-form-item>
      <el-form-item label="Logo">
        <logo-list v-model="activeBrand.logos" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { updateBrandList, getBrand } from "@/api/brand";
import SloganList from "./sloganList.vue";
import LogoList from "./logoList.vue";
import lockIcon from "../../assets/images/lock.svg";
import back from "../../assets/images/back.svg";
import { openPremiumInTab } from "@/utils/premium";
import type { BrandInfo } from "../../type";

const router = useRouter();
const route = useRoute();
const timer: Ref<any> = ref(null);
const activeBrand: Ref<BrandInfo | null> = ref(null);
const areaFocus = ref(false);
const mounted = ref(false);

watch(
  activeBrand,
  (value) => {
    if (value) {
      if (timer.value) {
        clearTimeout(timer.value);
      }
      timer.value = setTimeout(() => {
        updateBrandList(value);
      }, 1000);
    }
  },
  {
    deep: true,
  },
);

const changeAreaFocus = (b: boolean) => {
  areaFocus.value = b;
};

const setup = async () => {
  const { bkId } = route.query;
  const { success, data } = await getBrand({ bkId: bkId as string });
  if (!success) {
    return;
  } else {
    activeBrand.value = data;
  }
};

onBeforeMount(setup);

onMounted(() => {
  mounted.value = true;
});
</script>

<style scoped lang="scss">
.tip {
  display: flex;
  justify-content: space-between;
  width: 50%;
  min-width: 220px;
  max-width: 720px;
  margin-bottom: 48px;
  padding: 15px 20px;
  border: 1px solid #875eff;
  border-radius: 4px;
  background-color: #f8f5ff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  .left {
    display: flex;
    align-items: center;
  }

  & img {
    width: 24px;
    margin-right: 24px;
    padding: 4px;
    border-radius: 4px;
    background-color: #060606;
  }
}

.upgrade {
  color: #875eff;
  font-weight: 500;
  cursor: pointer;
  pointer-events: all;
}

.islock {
  cursor: none;
  pointer-events: none;
}

.back {
  position: absolute;
  top: 84px;
  left: 72px;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  pointer-events: all;
}

.edit-container {
  position: relative;
  padding-top: 72px;
  padding-bottom: 154px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :deep(.el-form-item) {
    margin-bottom: 60px;
  }

  :deep(.el-form-item__label) {
    color: #060606;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 18px !important;
  }

  :deep(.el-input__wrapper) {
    padding: 2px;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    box-shadow: none;
    transition: border-color 0.3s ease-in;

    &:hover {
      border-color: #a378ff;
    }
  }

  :deep(.el-input__wrapper.is-focus) {
    border-color: #6741ff;
  }

  :deep(.el-input__inner) {
    height: 46px;
    padding: 12px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #000000;

    &::placeholder {
      color: #8f959e;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  :deep(.el-textarea) {
    padding: 18px 16px 47px;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    box-shadow: none;
    transition: border-color 0.3s ease-in;

    &:hover {
      border-color: #a378ff;
    }
  }

  :deep(.el-textarea.isFocus) {
    border-color: #6741ff;
  }

  :deep(.el-textarea__inner) {
    min-height: 198px !important;
    color: #000000;
    box-shadow: none !important;
    padding: 0;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }

    &::placeholder {
      color: #8f959e;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  :deep(.el-textarea .el-input__count) {
    bottom: 12px;
    right: 2px;
    background: transparent;
  }

  :deep(.el-input__count) {
    margin-right: 16px;
  }
}
</style>
