<template>
  <section class="affiliate-use-case-part">
    <h2>Viral Promotional Videos </h2>
    <div class="video-container" id="video-container">
      <youtube-player v-for="item in list" :src="item.url" :isPlay="item.isPlay" :id="item.id" :width="playerBox.width"
        :height="playerBox.height" @play="handlePlay" @pause="handlePause" :playerVars="playerVars" ></youtube-player>
    </div>
  </section>
</template>

<script setup>
import { onMounted, reactive, nextTick } from 'vue'
import youtubePlayer from './youtubePlayer.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const showPlayer = ref(false)
const playerVars = reactive({
  autoplay: 0, // 禁止自动播放
})
const playerBox = reactive({
  width: 547,
  height: 308
})
const list = ref([
  {
    id: '1001',
    url: 'https://www.youtube.com/watch?v=MyslleUW1-8&t=466s&ab_channel=BoolvideoForContentCreator',
    isPlay: false
  }, 
  {
    id: '1002',
    url: 'https://www.youtube.com/watch?v=SZVJ7A4wnxk&ab_channel=BoolvideoForContentCreator',
    isPlay: false
  }, 
  {
    id: '1003',
    url: 'https://www.youtube.com/watch?v=E1k3bEYBib0&t=8s&ab_channel=BoolvideoForContentCreator',
    isPlay: false
  }, 
  {
    id: '1004',
    url: 'https://www.youtube.com/watch?v=MLyzQIgDAHE&ab_channel=RyanHoguePassiveIncome',
    isPlay: false
  }
])

const handlePlay = (id) => {
  list.value.forEach(item => {
    if (item.id == id) {
      item.isPlay = true
    } else {
      item.isPlay = false
    }
  })
}

const handlePause = (id) => {
  list.value.forEach(item => {
    if (item.id == id) {
      item.isPlay = false
    }
  })
}

const computedBox = () => {
  const playerDom = document.getElementById('video-container');
  if (!playerDom) {
    return
  }
  // 获取容器宽度
  const containerWidth = playerDom.clientWidth;
  const width = (containerWidth - 156) / 2
  playerBox.width = width
  playerBox.height = (width * 9) / 16
}

const observerElement = () => {
  // 选择你要监测的元素
  const target = document.getElementById('video-container')
  // 创建一个 Intersection Observer 实例
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      // 如果目标元素不可见
      if (!entry.isIntersecting) {
        list.value.forEach(item => {
          item.isPlay = false
        })
      }
    });
  });

  if (!target) {
    return;
  }
  // 开始观察目标元素
  observer.observe(target);
}

onMounted(async () => {
  await nextTick()
  computedBox()

  setTimeout(() => {
    showPlayer.value = true
  }, 1000)

  onresize = () => {
    computedBox()
  }
  observerElement()
})

</script>

<style scoped lang="scss">
.affiliate-use-case-part {
  padding: 0px calc((100% - var(--view-width)) / 2) 120px calc((100% - var(--view-width)) / 2);

  .video-container {
    background: #f6faff;
    border-radius: 18px;
    padding: 80px 60px;
    display: grid;
    grid-template-rows: auto auto;
    /* 定义两行，高度根据内容自动调整 */
    grid-template-columns: auto auto;
    /* 定义两列，宽度根据内容自动调整 */
    gap: 36px;
    /* 设置行列之间的间距 */
  }

  &>h2 {
    margin-bottom: 90px;
    font-size: 60px;
    font-weight: 700;
    text-align: center;
  }
}
</style>
