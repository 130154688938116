<script setup>
import { getMusicList } from "@/api/resources";
import { useNetwork } from "@/composables";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import { secondsToHms } from "../../utils";
import Empty from "./empty.vue";
import Loading from "./loading.vue";

const genres = [
  "All",
  "Pop",
  "Electronic",
  "Classical",
  "Rock",
  "Hip Hop",
  "Jazz",
  "Cinematic",
  "Acoustic",
  "Funk",
  "Lounge",
  "Ambient",
  "Lofi & Chill Beats",
  "Country",
  "Soul & RnB",
  "Latin",
  "Blues",
  "Reggae",
  "Retro",
  "Corporate",
  "World",
  "Folk",
  "Fantasy",
];
const props = defineProps({
  currentTab: {
    type: String,
    default: "music",
  },
});
const { playing, materialTab, showMaterial, secondToFrame, addAudioNode, pause } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { assertNetworkError } = useNetwork();
const { dragData } = useDrag();

const currentGenre = ref("All");
const active = ref(false);
const files = ref([]);
const page = ref(1);
const total = ref(0);
const loading = ref(false);
const currentAudio = reactive({
  url: null,
  audio: null,
  playing: false,
  duration: 0,
  currentTime: 0,
});

watch(page, getAudios, { immediate: true });
watch(currentGenre, genreChange);
watch([showMaterial, materialTab, playing, () => props.currentTab], ([isShow, newTab, playing, newSubTab]) => {
  if (!isShow || newTab !== "audio" || playing || newSubTab !== "music") {
    destroyAudio();
  }
});
onBeforeUnmount(destroyAudio);

async function getAudios(page) {
  loading.value = true;
  const params = { page, size: 20, genre: currentGenre.value };
  const { success, data } = await getMusicList(params);

  if (success) {
    total.value = data.total;
    files.value = files.value.concat(data.records);
  }
  loading.value = false;
}

async function genreChange() {
  destroyAudio();

  active.value = false;
  total.value = 0;
  files.value = [];

  if (page.value === 1) {
    await getAudios(1);
  } else {
    page.value = 1;
  }
}

function loadMore() {
  if (0 < files.value.length && files.value.length < total.value) {
    page.value++;
  }
}

async function addAudio(file) {
  assertNetworkError();

  const { name, url, duration, id } = file;
  const newDuration = secondToFrame(duration);

  await addAudioNode({ name, sourceId: id, duration: newDuration, src: url });
  commit();
  updateDraft();
}

function handleDrag(e, target, file) {
  const dragElement = target.querySelector("[drag-target]").cloneNode(true);
  dragElement.style.width = "70px";
  dragElement.style.height = "70px";
  dragElement.style.borderRadius = "4px";

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      type: "audio",
      name: file.name,
      src: file.url,
      duration: file.duration,
      sourceId: file.id,
    },
  });
}

function handleMouseDown(e) {
  const target = e.target.closest(".item-top[candrag]");
  const disableMove = e.target.closest("[move-disable]") !== null;
  if (target === null || e.button !== 0 || disableMove) return;

  const targetID = Number(target.dataset.id);
  const file = files.value.find(({ id }) => id === targetID);
  const mouseUpListener = (e) => {
    target.removeEventListener("mousemove", moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener("mouseup", mouseUpListener);
    handleDrag(e, target, file);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  target.addEventListener("mousemove", moveListener, { once: true });
  target.addEventListener("mouseup", mouseUpListener, { once: true });
}

function playAudio(file) {
  pause();

  if (currentAudio.url !== file.url) {
    destroyAudio();

    const audio = new Audio(file.url);
    audio.ontimeupdate = () => (currentAudio.currentTime = audio.currentTime);
    audio.onended = () => {
      currentAudio.playing = false;
      audio.currentTime = 0;
    };

    currentAudio.duration = file.duration;
    currentAudio.url = file.url;
    currentAudio.audio = audio;
  }

  currentAudio.playing = true;
  currentAudio.audio.play();
}

function destroyAudio() {
  if (currentAudio.audio) {
    pauseAudio();
    currentAudio.url = null;
    currentAudio.audio.ontimeupdate = null;
    currentAudio.audio.onended = null;
    currentAudio.audio = null;
    currentAudio.currentTime = 0;
    currentAudio.duration = 0;
  }
}

function pauseAudio() {
  currentAudio.playing = false;
  currentAudio.audio.pause();
}

function handleSeek(value) {
  currentAudio.audio.currentTime = value;
}
</script>
<template>
  <div class="genre">
    <div class="genre-wapper" :class="{ active }">
      <div class="genre-content">
        <div
          v-for="(genre, i) in genres"
          class="genre-tag"
          :class="{ active: genre === currentGenre }"
          :key="i"
          @click="currentGenre = genre"
        >
          {{ genre }}
        </div>
      </div>
      <div class="icon-wapper">
        <svg-icon
          clickable
          name="editor_material_arrow_down"
          :size="20"
          @click="active = !active"
        />
      </div>
    </div>
  </div>
  <el-scrollbar>
    <el-skeleton animated :loading="loading && page === 1">
      <template #template>
        <div v-for="(_, i) in Array(16)" class="skeleton-item" :key="i">
          <el-skeleton-item variant="rect" />
          <div class="skeleton-right">
            <el-skeleton-item variant="text" />
            <el-skeleton-item variant="text" />
          </div>
        </div>
      </template>
      <div
        v-if="files.length > 0"
        class="file-list"
        v-infinite-scroll="loadMore"
        :infinite-scroll-immediate="false"
        @mousedown="handleMouseDown"
      >
        <div
          class="file-item"
          v-for="(file, i) in files"
          :key="i"
          @mouseenter="file.hover = true"
          @mouseleave="file.hover = false"
        >
          <div class="item-top" candrag :data-id="file.id">
            <div class="image-wrapper">
              <div v-show="file.hover" class="image-mask">
                <svg-icon
                  clickable
                  v-show="
                    currentAudio.url !== file.url ||
                    (currentAudio.url === file.url && !currentAudio.playing)
                  "
                  name="editor_music_play"
                  :size="24"
                  @click="playAudio(file)"
                />
                <svg-icon
                  clickable
                  v-show="
                    currentAudio.url === file.url && currentAudio.playing
                  "
                  name="editor_music_pause"
                  :size="24"
                  @click="pauseAudio(file)"
                />
              </div>
              <img
                v-if="file.coverPic"
                :src="file.coverPic"
                loading="lazy"
                draggable="false"
                drag-target
              />
            </div>
            <div class="right">
              <span class="title">{{ file.name }}</span>
              <div class="item-rb">
                <span
                  class="duration"
                  v-if="
                    currentAudio.url === file.url && currentAudio.currentTime
                  "
                  >{{
                    `${secondsToHms(
                      currentAudio.currentTime,
                    )} / ${secondsToHms(currentAudio.duration)}`
                  }}</span
                >
                <span class="duration" v-else>{{
                  secondsToHms(file.duration)
                }}</span>
                <div class="button-group" v-show="file.hover" move-disable>
                  <icon-button
                    primary
                    round
                    name="editor_material_plus"
                    :size="18"
                    @click="addAudio(file)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="currentAudio.url === file.url && currentAudio.playing"
            class="item-bottom"
          >
            <bv-slider
              :model-value="currentAudio.currentTime"
              :min="0"
              :max="currentAudio.duration"
              :step="1"
              :show-tooltip="false"
              @input="handleSeek"
            />
          </div>
        </div>
      </div>
      <empty v-else />
      <loading v-show="loading && page > 1" />
    </el-skeleton>
  </el-scrollbar>
</template>
<style scoped>
.el-scrollbar {
  height: calc(100% - 51px);
}
.genre {
  position: relative;
  margin: 0 16px;
  height: 50px;
}
.genre-wapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e8e9ec;
  background-color: #fff;
  z-index: 1;
}
.genre-content {
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  padding: 7px 20px 7px 0;
  -webkit-mask-image: linear-gradient(
    90deg,
    #121215 calc(100% - 63.4px),
    rgba(18, 18, 21, 0.32) calc(100% - 40px),
    rgba(18, 18, 21, 0.08) calc(100% - 30px),
    rgba(18, 18, 21, 0) calc(100% - 48px),
    rgba(18, 18, 21, 0)
  );
  mask-image: linear-gradient(
    90deg,
    #121215 calc(100% - 63.4px),
    rgba(18, 18, 21, 0.32) calc(100% - 40px),
    rgba(18, 18, 21, 0.08) calc(100% - 30px),
    rgba(18, 18, 21, 0) calc(100% - 48px),
    rgba(18, 18, 21, 0)
  );
}
.genre-wapper.active .genre-content {
  white-space: normal;
  -webkit-mask-image: none;
  mask-image: none;
}

.genre-wapper.active svg {
  transform: rotate(180deg);
}
.genre-wapper svg {
  transition: transform 200ms;
}
.genre-wapper .icon-wapper {
  position: absolute;
  top: 15px;
  right: 0;
}

.genre-tag {
  cursor: pointer;
  margin: 7px 16px 7px 0;
  color: #646a73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
  display: inline-flex;
}
.genre-tag.active {
  color: #000;
}
.genre-tag:hover {
  color: #6741ff;
}
.file-header {
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
.file-header span {
  font-size: 14px;
  line-height: 22px;
  color: #060606;
}
.file-list,
:deep(.el-skeleton) {
  display: flex;
  flex-direction: column;
  padding: 22px 16px 0;
}
.file-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.item-top {
  display: flex;
  align-items: center;
}
.skeleton-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.item-rb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
}
.item-bottom {
  margin-top: 10px;
  display: flex;
}
.button-group {
  display: flex;
  align-items: center;
}
.button-group svg + svg {
  margin-left: 8px;
}
.image-wrapper,
:deep(.el-skeleton__rect) {
  flex: 0 0 70px;
  height: 70px;
  border-radius: 4px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
}
:deep(.el-skeleton__text) {
  margin: 2px 0;
  height: 14px;
}
.image-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.image-wrapper .image-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
}
.file-list .right,
.skeleton-right {
  height: 70px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  color: #646a73;
  font-size: 14px;
  line-height: 22px;
}
.duration {
  color: #8f959e;
  font-size: 12px;
  line-height: 20px;
  font-variant: tabular-nums;
}
</style>
