<script setup lang="ts">
import { ref, watchEffect, onMounted } from "vue";
import login from "./components/login-form.vue";
import signup from "./components/register-form.vue";
import { useRoute } from "vue-router";
import { useWindowSize } from "@vueuse/core";
import bgImage from "@/assets/images/login/login-page-bgimg.svg";
import displayImage from "@/assets/images/login/login_display.png";
import similarVideoDisplayImage from "@/assets/images/login/similar_video_login_display.png";

import { tailwindConfig as sharedConfig } from "@/configs/shared.config.ts";

const globalDomain = inject('globalDomain');
const route = useRoute();
const tabs = {
  login,
  signup,
};

const { width: innerWidth } = useWindowSize();
//监听当前路由变化，变化就切到相对应的组件
const currentTab = ref("login");
const isPC = computed(() => sharedConfig.screens.ipad.max < innerWidth.value);

watchEffect(() => {
  document.title = route.meta.title!;
  currentTab.value = route.path.replace("/", "");
});

onMounted(() => {
})
</script>

<template>
  <div
    class="h-full flex justify-center login-page"
    v-track:exposure="'boolvideo_signup_view'"
  >
    <div class="left-box flex-1 flex justify-center align-center" v-if="isPC">
      <div class="login-page-bgimg">
        <img :src="bgImage" class="login-page-bgimg-bg" />
      </div>
      <div class="left-info">
        <div class="welcome-box">
          <h1 class="font-medium">
            Unlimited top-notch videos for your product showcase
          </h1>
        </div>
        <img :src="globalDomain == 1 ? displayImage : similarVideoDisplayImage" class="login-display" />
      </div>
    </div>
    <component
      :is="tabs[currentTab]"
      :key="currentTab"
      class="right-box flex-1 flex justify-center items-center"
    >
    </component>
  </div>
</template>
<style lang="scss" scoped>
.right-box {
  min-width: 400px;
  flex-direction: column;
}

.left-box {
  overflow: hidden;
  min-width: 565px;
  position: relative;
}

.login-page-bgimg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
}

.login-page-bgimg-bg {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.login-page {
  height: 100vh;
}

.left-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 640px;
  color: white !important;
  height: fit-content;
  margin: auto;
  z-index: 9;
}

.login-display {
  width: 90%;
  max-width: 520px;
}

.welcome-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  h1 {
    font-size: 34px;
    line-height: 38px;
    color: white;
    text-align: center;
    text-transform: capitalize;
  }
}
</style>
