<script setup>
import { blendOptions } from "@/constants/image";
import { useDraftStore, useHistoryStore } from "../../stores";
import Attr from "./attr.vue";
import Keyframe from "./keyframe.vue";
import Extra from "./extra.vue";

const emit = defineEmits([
  "update:x",
  "update:y",
  "update:rotate",
  "update:opacity",
  "update:keyframes",
  "update:scale",
  "update:blend",
]);
const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  initialState: {
    type: Object,
    default: {},
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  scale: {
    type: Number,
    default: 1,
  },
  opacity: {
    type: Number,
    default: 1,
  },
  keyframes: {
    type: Object,
    default: null,
  },
  blend: {
    type: String,
    default: "normal",
  },
});
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

const x = ref(props.x);
const y = ref(props.y);
const rotate = ref(props.rotate);
const scale = ref(props.scale);
const opacity = ref(props.opacity);

const dirty = reactive({
  x: 0,
  y: 0,
  rotate: 0,
  scale: 0,
  opacity: 0,
});

watch(
  () => props.node.updateId,
  () => {
    const node = props.node;
    const [nodeX, nodeY] = node.getXY();
    const nodeScale = node.getScale();
    const nodeRotation = node.getRotation();
    const nodeOpacity = node.getOpacity();

    x.value = nodeX;
    y.value = nodeY;
    scale.value = nodeScale;
    rotate.value = nodeRotation;
    opacity.value = nodeOpacity;
  },
  { immediate: true },
);
watch(
  () => props.x,
  (newX) => {
    updateX(newX, false, true);
  },
);
watch(
  () => props.y,
  (newY) => {
    updateY(newY, false, true);
  },
);
watch(
  () => props.scale,
  (newScale) => {
    updateScale(newScale, false, true);
  },
);
watch(
  () => props.rotate,
  (newRotation) => {
    updateRotate(newRotation, false, true);
  },
);
watch(
  () => props.opacity,
  (newOpacity) => {
    updateOpacity(newOpacity, false, true);
  },
);

function submit() {
  commit();
  updateDraft();
}

function resetLayoutProperty() {
  const { initialState } = props;
  emit("update:x", initialState.x);
  emit("update:y", initialState.y);
  emit("update:rotate", initialState.rotation);
  emit("update:scale", initialState.scale);
  emit("update:opacity", initialState.opacity);
  emit("update:keyframes", initialState.keyframes);
  submit();
}

function resetBlendProperty() {
  const { initialState } = props;
  emit("update:blend", initialState.blend);
  submit();
}

function updateX(value, shouldSubmit, noEmit) {
  x.value = value;
  dirty.x++;
  !noEmit && emit("update:x", value);
  shouldSubmit && submit();
}

function updateY(value, shouldSubmit, noEmit) {
  y.value = value;
  dirty.y++;
  !noEmit && emit("update:y", value);
  shouldSubmit && submit();
}

function updateRotate(value, shouldSubmit, noEmit) {
  rotate.value = value;
  dirty.rotate++;
  !noEmit && emit("update:rotate", value);
  shouldSubmit && submit();
}

function updateScale(value, shouldSubmit, noEmit) {
  scale.value = value;
  dirty.scale++;
  !noEmit && emit("update:scale", value);
  shouldSubmit && submit();
}

function updateOpacity(value, shouldSubmit, noEmit) {
  opacity.value = value;
  dirty.opacity++;
  !noEmit && emit("update:opacity", value);
  shouldSubmit && submit();
}

function updateKeyframes(value) {
  emit("update:keyframes", value);
}

function updateBlend(value) {
  emit("update:blend", value);
  submit();
}
</script>
<template>
  <Attr title="Sticker">
    <el-scrollbar>
      <div class="attr-content">
        <div class="form keyframe">
          <div class="form-header">
            <span>Layout</span>
            <div class="right">
              <icon-button
                name="editor_reset"
                tip="Reset"
                :size="14"
                @click="resetLayoutProperty"
              />
              <Keyframe
                :should-add="false"
                :properties="{ x, y, rotate, scale, opacity }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
          </div>
          <div class="form-content">
            <div class="col col-input">
              <input-number
                rounded
                :model-value="x"
                @input="updateX"
                @change="updateX($event, true)"
              >
                <template #prefix>X</template>
              </input-number>
              <input-number
                rounded
                :model-value="y"
                @input="updateY"
                @change="updateY($event, true)"
              >
                <template #prefix>Y</template>
              </input-number>
              <Keyframe
                :properties="{ x, y }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
            <div class="col col-input col-single">
              <bv-tip content="Rotation">
                <input-number
                  angle
                  :model-value="rotate"
                  :step="Math.PI / 180"
                  @input="updateRotate"
                  @change="updateRotate($event, true)"
                >
                  <template #prefix>
                    <svg-icon name="editor_angle" :size="16" />
                  </template>
                </input-number>
              </bv-tip>
              <Keyframe
                :properties="{ rotate }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
            <div class="col col-input col-single">
              <bv-tip content="Scale">
                <input-number
                  percent
                  :model-value="scale"
                  :min="0.01"
                  :max="10"
                  :step="0.01"
                  @input="updateScale"
                  @change="updateScale($event, true)"
                >
                  <template #prefix>
                    <svg-icon name="editor_scale" :size="16" />
                  </template>
                </input-number>
              </bv-tip>
              <Keyframe
                :properties="{ scale }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
            <div class="col col-input col-single">
              <bv-tip content="Opacity">
                <input-number
                  percent
                  :model-value="opacity"
                  :min="0"
                  :max="1"
                  :step="0.01"
                  @input="updateOpacity"
                  @change="updateOpacity($event, true)"
                >
                  <template #prefix>
                    <svg-icon name="editor_opacity" :size="16" />
                  </template>
                </input-number>
              </bv-tip>
              <Keyframe
                :properties="{ opacity }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
          </div>
        </div>
        <div class="form has-keyframe">
          <div class="form-header">
            <span>Blend</span>
            <icon-button
              name="editor_reset"
              tip="Reset"
              :size="14"
              @click="resetBlendProperty"
            />
          </div>
          <div class="form-content">
            <div class="col col-input col-small">
              <bv-select
                width="226px"
                style="margin-right: 0"
                :model-value="blend"
                :options="blendOptions"
                @change="updateBlend"
              />
            </div>
          </div>
        </div>
        <extra :config="node.conf" />
      </div>
    </el-scrollbar>
  </Attr>
</template>
<style scoped></style>
