<template>
  <dialog-container
    :visible="isVisible"
    @close="handleClose"
    :httpRequest="getAssets"
    @next="handleNext"
    :activeButton="activeButton"
    :pagination="pagination"
  >
    <template #filter>
      <Filter
        @updateType="handleUpdateType"
        :filterType="filterType"
        :filterSource="filterSource"
        @updateSource="handleUpdateSource"
        :accept="accept"
        :showSource="true"
        :filterTypes="filterTypes"
      >
      </Filter>
    </template>

    <div class="grid-image-wrapper">
      <Empty v-if="showEmpty" :showSpaceLink="showSpaceLink" />
      <grid-image
        :showMask="false"
        :multiple="multiple"
        :imageList="materialList"
        :selectedIdList="selectedMidList"
        @select="getSelectedMid"
        :showUpload="false"
        v-else
      >
        <template #select-icon>
          <div class="h-full w-full relative">
            <div class="absolute bottom-3 right-3">
              <svg-icon
                name="icon_select_image"
                :style="{ width: '16px', height: '16px', color: '#fff' }"
              ></svg-icon>
            </div>
          </div>
        </template>
      </grid-image>
    </div>
    <div class="loading" v-if="loading">
      <svg-icon
        name="icon_loading"
        :style="{ width: '24px', height: '24px' }"
        class="animate-spin duration-300 mr-2"
      ></svg-icon>
    </div>
  </dialog-container>
</template>

<script setup>
import { ref, computed, defineExpose } from 'vue';
import { GridImage } from '../edit-cloud-grid-image/index.ts';
import dialogContainer from './dialogContainer.vue';
import Filter from '../components/filter.vue';
import { materialCloud } from '@/api/index';
import Empty from '../components/empty.vue';

const emit = defineEmits(['close']);

const props = defineProps({
  multiple: {
    type: Boolean,
    default: true,
  },
  accept: {
    type: String,
    default: 'image,video',
  },
  httpRequest: Function,
  isAiType: Boolean,
  visible: Boolean,
});

const isVisible = ref(false);
const filterType = ref(['image']);
const filterSource = ref(['original']);
const selectedMidList = ref([]);
const materialList = ref([]);
const showEmpty = ref(false);
const loading = ref(false);

const activeButton = computed(() => {
  return selectedMidList.value.length;
});

const showSpaceLink = computed(() => {
  return (
    filterSource.value.includes('original') && filterSource.value.length == 1
  );
});

const pagination = reactive({
  size: 40,
  page: 1,
});

watch(
  () => props.accept,
  (accept) => {
    if (props.accept) {
      const acceptArr = accept && accept.split(',');
      filterType.value = acceptArr;
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => props.visible,
  (visible) => {
    if (visible) {
      getAssets();
    }
    isVisible.value = visible;
  },
  {
    immediate: true,
  }
);

const handleClose = () => {
  emit('close');
  selectedMidList.value = [];
  isVisible.value = false;
};

const handleUpdateType = (type) => {
  filterType.value = type;
};

const handleUpdateSource = (source) => {
  filterSource.value = source;
};

const formateList = (originList) => {
  const list = originList.map((image) => {
    return {
      ...image,
      state: 0,
      url: image.preview480Url,
      '1080url': image.preview1080Url,
    };
  });
  return list;
};

watch(
  () => [filterSource.value, filterType.value],
  () => {
    pagination.page = 1;
    materialList.value = [];
    getAssets();
  },
  {
    deep: true,
  }
);

const getAssets = async (currentPage) => {
  loading.value = true;
  pagination.page = currentPage ? currentPage : pagination.page;
  const params = {
    filterTypes: filterType.value,
    page: pagination.page,
    size: pagination.size,
    source: filterSource.value,
    draftId: 0,
  };

  if (props.isAiType) {
    params.excludeAi = ['videoBgRemove'];
  }

  try {
    const { data } = await materialCloud(params);
    const { records, current, total } = data;

    const list = formateList(records);
    pagination.page = current;
    pagination.total = total;
    materialList.value = [...materialList.value, ...list];
    loading.value = false;

    if (!materialList.value.length) {
      showEmpty.value = true;
    } else {
      showEmpty.value = false;
    }
  } catch {}
};

const getSelectedMid = (item) => {
  if (!props.multiple) {
    selectedMidList.value = [item.mid];
    return;
  }

  let ind = selectedMidList.value.findIndex(
    (selectItem) => selectItem === item.mid
  );
  if (ind >= 0) {
    selectedMidList.value.splice(ind, 1);
    return;
  }
  selectedMidList.value.push(item.mid);
};

const handleNext = () => {
  let selectedList = [];

  selectedMidList.value.forEach((mid) => {
    const image = materialList.value.find((item) => item.mid == mid);
    if (image) {
      selectedList.push(image);
    }
  });
  emit('confirm', selectedList);
};

const clearSelectedMidList = () => {
  selectedMidList.value = [];
};

defineExpose({
  clearSelectedMidList
});
</script>

<style lang="scss" scoped>
.grid-image-wrapper {
  position: relative;
}

.loading {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss"></style>
