<script setup>
import { useKeyboard } from "../../stores";
import { getComputedSize } from "../../utils";

const emit = defineEmits([
  "mousedown",
  "mouseup",
  "stickdown",
  "dragging",
  "dragend",
  "resizing",
  "resizeend",
  "rotating",
  "rotateend",
  "update:x",
  "update:y",
  "update:width",
  "update:height",
  "update:rotation",
]);

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  rotation: {
    type: Number,
    default: 0,
  },
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  anchor: {
    type: Array,
    default: [0.5, 0.5],
  },
  minWidth: {
    type: Number,
    default: 1,
  },
  minHeight: {
    type: Number,
    default: 1,
  },
  handlers: {
    type: Array,
    default: () => ["tl", "tr", "bl", "br"],
  },
  lockAspectRatio: {
    type: Boolean,
    default: true,
  },
  dragAdsorptionValue: {
    type: Number,
    default: 10,
  },
  resizeAdsorptionValue: {
    type: Number,
    default: 10,
  },
});

const { modifier } = useKeyboard();

const showRefLineLeft = inject("showRefLineLeft");
const showRefLineCenter = inject("showRefLineCenter");
const showRefLineRight = inject("showRefLineRight");
const showRefLineTop = inject("showRefLineTop");
const showRefLineMiddle = inject("showRefLineMiddle");
const showRefLineBottom = inject("showRefLineBottom");

const left = ref(props.x);
const top = ref(props.y);
const right = ref(null);
const bottom = ref(null);
const rotation = ref(props.rotation);

const width = ref(null);
const height = ref(null);
const aspectRatio = ref(null);
const parentWidth = ref(null);
const parentHeight = ref(null);

const dragStart = ref(false);
const dragging = ref(false);
const resizeStart = ref(false);
const resizing = ref(false);
const rotateStart = ref(false);
const rotating = ref(false);

const element = ref(null);
const currentHandler = ref(null);
const bounds = ref(null);
const ro = ref(null);

const beforePosition = reactive({ pageX: 0, pageY: 0, width: 0, height: 0 });

const lockAspectRatio = computed(() => props.lockAspectRatio || modifier.shift);
const rect = computed(() => ({
  left: left.value,
  top: top.value,
  width: width.value,
  height: height.value,
}));
const style = computed(() => ({
  transform: `translate(${left.value}px, ${top.value}px) rotate(${rotation.value}rad)`,
  transformOrigin: `${props.anchor[0] * 100}% ${props.anchor[1] * 100}%`, 
  width: `${width.value}px`,
  height: `${height.value}px`,
}));

watch(
  () => props.x,
  (newX) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    left.value = newX;
    right.value = parentWidth.value - width.value - left.value;
  },
);
watch(
  () => props.y,
  (newY) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    top.value = newY;
    bottom.value = parentHeight.value - height.value - top.value;
  },
);
watch(
  () => props.width,
  (newWidth) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    const ratio = newWidth / height.value;
    width.value = newWidth;
    aspectRatio.value = ratio;
  },
);
watch(
  () => props.height,
  (newHeight) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    const ratio = width.value / newHeight;
    height.value = newHeight;
    aspectRatio.value = ratio;
  },
);
watch(
  () => props.rotation,
  (newRotation) => {
    if (dragging.value || resizing.value || rotating.value) {
      return;
    }
    rotation.value = newRotation;
  },
);

onBeforeMount(resetPosition);

onMounted(() => {
  const { parentElement } = element.value;
  const [pw, ph] = getComputedSize(parentElement);
  const [w, h] = getComputedSize(element.value);

  parentWidth.value = pw;
  parentHeight.value = ph;

  width.value = props.width || w;
  height.value = props.height || h;
  aspectRatio.value = width.value / height.value;

  right.value = parentWidth.value - width.value - left.value;
  bottom.value = parentHeight.value - height.value - top.value;

  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);

  ro.value = new ResizeObserver(parentResize);
  ro.value.observe(parentElement);
});

onBeforeUnmount(() => {
  ro.value.unobserve(element.value.parentElement);
  ro.value.disconnect();
  ro.value = null;

  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp);

  element.value = null;
  currentHandler.value = null;
});

function parentResize() {
  const [w, h] = getComputedSize(element.value.parentElement);

  parentWidth.value = w;
  parentHeight.value = h;

  right.value = parentWidth.value - width.value - left.value;
  bottom.value = parentHeight.value - height.value - top.value;
}

function mouseMove(e) {
  if (rotateStart.value) {
    rotate(e);
  } else if (resizeStart.value) {
    resize(e);
  } else if (dragStart.value) {
    drag(e);
  }
}

function mouseUp(e) {
  resetPosition();

  if (dragStart.value) {
    dragStart.value = false;
    emit("mouseup", e, dragging.value);
  }
  if (dragging.value) {
    dragging.value = false;
    emit("dragend", rect.value);
  }
  if (resizing.value) {
    resizing.value = false;
    emit("resizeend", rect.value);
  }
  if (rotating.value) {
    rotating.value = false;
    emit("rotateend", rect.value);
  }

  resizeStart.value = false;
  rotateStart.value = false;
  currentHandler.value = null;

  showRefLineLeft.value = false;
  showRefLineCenter.value = false;
  showRefLineRight.value = false;
  showRefLineTop.value = false;
  showRefLineMiddle.value = false;
  showRefLineBottom.value = false;
}

function elementDown(e) {
  emit("mousedown", e);
  dragStart.value = true;
  savePosition(e);
}

function handlerDown(e, handler) {
  resizeStart.value = true;
  currentHandler.value = handler;

  savePosition(e);
  calcResizeBounds();
}

function rotateDown() {
  rotateStart.value = true;

  const { x, y, width, height } = element.value.getBoundingClientRect();
  const pageX = x + width / 2;
  const pageY = y + height / 2;

  savePosition({ pageX, pageY });
}

function drag(e) {
  dragging.value = true;

  const deltaX = beforePosition.pageX - e.pageX;
  const deltaY = beforePosition.pageY - e.pageY;

  let newLeft = beforePosition.left - deltaX;
  let newRight = beforePosition.right + deltaX;
  let newTop = beforePosition.top - deltaY;
  let newBottom = beforePosition.bottom + deltaY;

  if (rotation.value === 0) {
    [newLeft, newRight, newTop, newBottom] = align(
      newLeft,
      newRight,
      newTop,
      newBottom,
      props.dragAdsorptionValue,
    );
  }

  left.value = newLeft;
  right.value = newRight;
  top.value = newTop;
  bottom.value = newBottom;

  emit("update:x", newLeft);
  emit("update:y", newTop);
  emit("dragging", { ...rect.value, pageY: e.pageY });
}

function resize(e) {
  resizing.value = true;

  const deltaX = beforePosition.pageX - e.pageX;
  const deltaY = beforePosition.pageY - e.pageY;
  const handler = currentHandler.value;
  const boundsRef = bounds.value;
  const ratio = aspectRatio.value;

  let newLeft = left.value;
  let newRight = right.value;
  let newTop = top.value;
  let newBottom = bottom.value;

  if (handler.includes("l")) {
    newLeft = restrictToBounds(
      beforePosition.left - deltaX,
      boundsRef.maxLeft,
    );
  } else if (handler.includes("r")) {
    newRight = restrictToBounds(
      beforePosition.right + deltaX,
      boundsRef.maxRight,
    );
  }
  if (handler.includes("t")) {
    newTop = restrictToBounds(
      beforePosition.top - deltaY,
      boundsRef.maxTop,
    );
    if (lockAspectRatio.value) {
      if (handler[1] === "l") {
        newLeft = left.value - (top.value - newTop) * ratio;
      } else if (handler[1] === "r") {
        newRight = right.value - (top.value - newTop) * ratio;
      }
    }
  } else if (handler.includes("b")) {
    newBottom = restrictToBounds(
      beforePosition.bottom + deltaY,
      boundsRef.maxBottom,
    );
    if (lockAspectRatio.value) {
      if (handler[1] === "l") {
        newLeft = left.value - (bottom.value - newBottom) * ratio;
      } else if (handler[1] === "r") {
        newRight = right.value - (bottom.value - newBottom) * ratio;
      }
    }
  }

  if (rotation.value === 0) {
    [newLeft, newRight, newTop, newBottom] = align(
      newLeft,
      newRight,
      newTop,
      newBottom,
      props.resizeAdsorptionValue,
      handler,
    );
  }

  newLeft = restrictToBounds(newLeft, boundsRef.maxLeft);
  newRight = restrictToBounds(newRight, boundsRef.maxRight);
  newTop = restrictToBounds(newTop, boundsRef.maxTop);
  newBottom = restrictToBounds(newBottom, boundsRef.maxBottom);

  left.value = newLeft;
  right.value = newRight;
  top.value = newTop;
  bottom.value = newBottom;

  const newWidth = computeWidth(parentWidth.value, newLeft, newRight);
  const newHeight = computeHeight(parentHeight.value, newTop, newBottom);

  width.value = newWidth;
  height.value = newHeight;
  aspectRatio.value = newWidth / newHeight;

  // console.log('[]', { newLeft, newRight, newTop, newBottom })

  emit("update:x", newLeft);
  emit("update:y", newTop);
  emit("update:width", newWidth);
  emit("update:height", newHeight);
  emit("resizing", rect.value);
}

function rotate(e) {
  rotating.value = true;

  const deltaX = beforePosition.pageX - e.pageX;
  const deltaY = beforePosition.pageY - e.pageY;
  const newRotation = Math.atan2(deltaX, -deltaY);

  rotation.value = newRotation;

  emit("update:rotation", newRotation);
  emit("rotating", newRotation);
}

function align(left, right, top, bottom, asp, handler) {
  const ratio = aspectRatio.value;

  let newLeft = left;
  let newRight = right;
  let newTop = top;
  let newBottom = bottom;

  let isAdsorptionLeft = false;
  let isAdsorptionCenter = false;
  let isAdsorptionRight = false;
  let isAdsorptionTop = false;
  let isAdsorptionMiddle = false;
  let isAdsorptionBottom = false;

  let isLeft = false;
  let isRight = false;
  let isTop = false;
  let isBottom = false;

  if (-asp <= left && left <= asp) {
    isLeft = isAdsorptionLeft = true;
    newLeft = 0;
  } else if (parentWidth.value - asp <= left && left <= parentWidth.value + asp) {
    isLeft = isAdsorptionRight = true;
    newLeft = parentWidth.value;
  }
  if (isLeft) {
    if (!handler) {
      newRight = parentWidth.value - width.value - newLeft;
    } else if (lockAspectRatio.value && handler.includes("l")) {
      if (handler[0] === "t") {
        newTop = top - (left - newLeft) / ratio;
      } else if (handler[0] ==="b") {
        newBottom = bottom - (left - newLeft) / ratio;
      }
    }
  } 

  if (-asp <= right && right <= asp) {
    isRight = isAdsorptionRight = true;
    newRight = 0;
  } else if (parentWidth.value - asp <= right && right <= parentWidth.value + asp) {
    isRight = isAdsorptionLeft = true;
    newRight = parentWidth.value;
  }
  if (isRight) {
    if (!handler) {
      newLeft = parentWidth.value - width.value - newRight;
    } else if (lockAspectRatio.value && handler.includes("r")) {
      if (handler[0] === "t") {
        newTop = top - (right - newRight) / ratio;
      } else if (handler[0] ==="b") {
        newBottom = bottom - (right - newRight) / ratio;
      }
    }
  }

  if (-asp <= top && top <= asp) {
    isTop = isAdsorptionTop = true;
    newTop = 0;
  } else if (parentHeight.value - asp <= top && top <= parentHeight.value + asp) {
    isTop = isAdsorptionBottom = true;
    newTop = parentHeight.value;
  }
  if (isTop) {
    if (!handler) {
      newBottom = parentHeight.value - height.value - newTop;
    } else if (lockAspectRatio.value && handler.includes("t")) {
      if (handler[1] === "l") {
        newLeft = left - (top - newTop) * ratio;
      } else if (handler[1] === "r") {
        newRight = right - (top - newTop) * ratio;
      }
    }
  }

  if (-asp <= bottom && bottom <= asp) {
    isBottom = isAdsorptionBottom = true;
    newBottom = 0;
  } else if (
    parentHeight.value - asp <= bottom &&
    bottom <= parentHeight.value + asp
  ) {
    isBottom = isAdsorptionTop = true;
    newBottom = parentHeight.value;
  }
  if (isBottom) {
    if (!handler) {
      newTop = parentHeight.value - height.value - newBottom;
    } else if (lockAspectRatio.value && handler.includes("b")) {
      if (handler[1] === "l") {
        newLeft = left - (bottom - newBottom) * ratio;
      } else if (handler[1] === "r") {
        newRight = right - (bottom - newBottom) * ratio;
      }
    }
  }

  if (-asp <= left - right && left - right <= asp) {
    isAdsorptionCenter = true;

    if (!handler) {
      newLeft = (parentWidth.value - width.value) / 2;
      newRight = newLeft;
    }
  }
  if (-asp <= top - bottom && top - bottom <= asp) {
    isAdsorptionMiddle = true;

    if (!handler) {
      newTop = (parentHeight.value - height.value) / 2;
      newBottom = newTop;
    }
  }

  // console.log('[]', { isLeft, isRight, isTop, isBottom })

  showRefLineLeft.value = isAdsorptionLeft;
  showRefLineCenter.value = isAdsorptionCenter;
  showRefLineRight.value = isAdsorptionRight;
  showRefLineTop.value = isAdsorptionTop;
  showRefLineMiddle.value = isAdsorptionMiddle;
  showRefLineBottom.value = isAdsorptionBottom;

  return [newLeft, newRight, newTop, newBottom];
}

function calcResizeBounds() {
  const boundsRef = bounds.value;
  const ratio = aspectRatio.value;
  let minW = props.minWidth;
  let minH = props.minHeight;

  if (lockAspectRatio.value) {
    if (minW / minH > ratio) {
      minH = minW / ratio;
    } else {
      minW = ratio * minH;
    }
  }
  boundsRef.maxLeft = left.value + width.value - minW;
  boundsRef.maxRight = right.value + width.value - minW;
  boundsRef.maxTop = top.value + height.value - minH;
  boundsRef.maxBottom = bottom.value + height.value - minH;
}

function savePosition(e) {
  beforePosition.pageX = e.pageX;
  beforePosition.pageY = e.pageY;
  beforePosition.left = left.value;
  beforePosition.right = right.value;
  beforePosition.top = top.value;
  beforePosition.bottom = bottom.value;
  beforePosition.width = width.value;
  beforePosition.height = height.value;
}

function resetPosition() {
  beforePosition.pageX = 0;
  beforePosition.pageY = 0;
  beforePosition.width = 0;
  beforePosition.height = 0;

  bounds.value = {
    maxLeft: null,
    maxRight: null,
    maxTop: null,
    maxBottom: null,
  };
}

function restrictToBounds(value, max) {
  if (max !== null && max < value) {
    return max;
  }
  return value;
}

function computeWidth(parentWidth, left, right) {
  return parentWidth - left - right;
}

function computeHeight(parentHeight, top, bottom) {
  return parentHeight - top - bottom;
}

function shouldShowHandler(handler) {
  if (props.active) {
    if (handler[0] === "m" && height.value < 50 || handler[1] === "m" && width.value < 50) {
      return false;
    }
    if (resizing.value && currentHandler.value !== handler || dragging.value || rotating.value) {
      return false;
    }
    return true;
  }
  return false;
}

defineExpose({
  width,
  height,
  dragging,
  resizing,
  rotating,
});
</script>
<template>
  <div
    ref="element"
    class="transformer"
    tabindex="-1"
    :class="{ active }"
    :style="style"
    @mousedown.stop="elementDown"
  >
    <span
      v-for="(handler, i) in handlers"
      class="handler"
      :key="i"
      v-show="shouldShowHandler(handler)"
      :class="'handler-' + handler"
      @mousedown.stop="handlerDown($event, handler)"
    >
    </span>
    <span v-show="active && !dragging && !resizing" class="handler-rotate" @mousedown.stop="rotateDown">
      <svg-icon name="editor_rotation" :size="18" />
    </span>
  </div>
</template>
<style scoped>
.transformer {
  position: absolute;
  z-index: 0;
}
.transformer.active {
  border: 1px solid #875eff;
  z-index: 1;
}
.handler {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #875eff;
}
.handler-rotate {
  border-radius: 50%;
  padding: 5px;
  background-color: #fff;
  left: 50%;
  transform: translate(-50%, 100%);
  bottom: -8px;
  cursor: pointer;
  position: absolute;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.08);
}
.handler-rotate img {
  width: 18px;
  height: 18px;
}
.handler-tm,
.handler-bm {
  width: 32px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: ns-resize;
}
.handler-ml,
.handler-mr {
  height: 32px;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: ew-resize;
}
.handler-tl,
.handler-tm,
.handler-tr {
  top: -5px;
}
.handler-tl,
.handler-ml,
.handler-bl {
  left: -5px;
}
.handler-tr,
.handler-mr,
.handler-br {
  right: -5px;
}
.handler-bl,
.handler-bm,
.handler-br {
  bottom: -5px;
}
.handler-tl,
.handler-br {
  cursor: nwse-resize;
}
.handler-tr,
.handler-bl {
  cursor: nesw-resize;
}
</style>
