import { _post, _get } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

export function toolVote(params: AiToolsReqParams.Vote) {
  return _get<boolean>(`${VITE_API_BASE}/tools/vote`, params);
}

export function getToolVoteNum(params: AiToolsReqParams.VoteNum) {
  return _get<number>(`${VITE_API_BASE}/tools/vote_num`, params);
}

export function getToolVoteState(params: AiToolsReqParams.VoteState) {
  return _get<boolean>(`${VITE_API_BASE}/tools/vote_state`, params);
}

export function getVideoEnhanceResult(params: AiToolsReqParams.VideoEnhance) {
  return _post<AiToolsResData.VideoEnhance>(
    `${VITE_API_BASE}/tools/video_HD`,
    params,
  );
}

export function getVideoEnhancePreview(
  params: AiToolsReqParams.VideoEnhancePreview,
) {
  return _post<AiToolsResData.VideoEnhance>(
    `${VITE_API_BASE}/tools/video_HD/preview`,
    params,
  );
}

export function getImageEnhanceResult(params: AiToolsReqParams.ImageEnhance) {
  return _post<AiToolsResData.ImageEnhance>(
    `${VITE_API_BASE}/tools/image_HD`,
    params,
  );
}

export async function getImageBgRemove(params) {
  const response = await _post(
    `${VITE_API_BASE}/tools/image_remove_bg`,
    params,
  );
  return response;
}

export async function getVideoBgRemovePreview(params) {
  const response = await _post(
    `${VITE_API_BASE}/tools/video_remove_bg/preview`,
    params,
  );
  return response;
}

export async function getVideoBgRemove(params) {
  const response = await _post(
    `${VITE_API_BASE}/tools/video_remove_bg`,
    params,
  );
  return response;
}

export async function getVideoBgRemoveState(
  params: AiToolsReqParams.TaskState,
) {
  const response = await _get<AiToolsResData.TaskState>(
    `${VITE_API_BASE}/tools/task_state`,
    params,
  );
  return response;
}

export async function applyImageErase(
  params: AiToolsReqParams.ApplyImageErase,
) {
  return await _post<AiToolsResData.ApplyImageErase>(
    `${VITE_API_BASE}/tools/image_erase`,
    params,
  );
}

export async function generateVoiceover( params: AiToolsReqParams.GenerateVoiceover ) {
  return await _post<AiToolsResData.GenerateVoiceover>(`${VITE_API_BASE}/tools/text_to_audio`, params);
}
  

