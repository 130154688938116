<script setup>
import Layout from "./components/layout/layout.vue";
import { useTrackStore } from "@/store/modules/track";
import { useRoute } from "vue-router";

const route = useRoute();
const { collectData } = useTrackStore();
document.title = route.meta.title;
collectData("boolvideo_editor_page_view", {
  editor_type: "timeline_editor",
});

onMounted(() => {
  const { style } = document.documentElement;

  style.overflowY = "hidden";
  style.overscrollBehaviorX = "none";
});
onUnmounted(() => {
  const { style } = document.documentElement;

  style.removeProperty("overflow-y");
  style.removeProperty("overscroll-behavior-x");
});

</script>
<template>
  <Layout v-track:exposure="'boolvideo_editor_page_view'"/>
</template>
<style scoped>
:deep(.el-tabs) {
  width: 100%;
  height: 100%;
  overflow: hidden;
  --el-tabs-header-height: 42px;
}

:deep(.el-tabs .el-tabs__content) {
  width: 100%;
  height: calc(100% - 42px);
}

:deep(.el-tab-pane) {
  width: 100%;
  height: 100%;
}

:deep(.el-tabs__header) {
  margin-bottom: 0;
}

:deep(.el-tabs__nav-scroll) {
  padding: 0 18px;
}

:deep(.el-tabs__nav-wrap::after) {
  height: 1px;
  background-color: #e8e9ec;
}

:deep(.el-tabs__item) {
  box-shadow: none;
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #646a73;
}

:deep(.el-tabs__item.is-active) {
  color: #000000;
}

:deep(.el-tabs__active-bar) {
  height: 1px;
  background-color: #000000;
}

:deep(.el-button--large) {
  width: 116px;
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1c1b1e;
  background-color: #ffffff;
  border: 1px solid #d5d6d7;
  border-radius: 36px;
}

:deep(.el-button--primary.is-plain) {
  border: 1px solid #875eff;
  color: #875eff;
}

:deep(.el-button.el-button--large svg) {
  margin-right: 4px;
}

:deep(.el-slider__button-wrapper) {
  width: 10px;
  height: 10px;
}

:deep(.el-slider__button) {
  width: 10px;
  height: 10px;
  border: 1px solid #646a73;
}
</style>
