<script setup>
import Segment from "./segment.vue";
import { useCreatorStore } from "../../stores";

defineEmits(["update:start", "update:end", "update:keyframes"]);

const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  start: {
    type: Number,
    default: 0,
  },
  end: {
    type: Number,
    default: 0,
  },
  keyframes: {
    type: Object,
    default: {},
  },
});

const { showMaterial, materialTab, voiceoverText } = useCreatorStore();
const globalDomain = inject("globalDomain");

const uniqueOptions =
  globalDomain === 1
    ? [
        {
          label: "Text to voice",
          prefix: {
            name: "icon_voiceover",
          },
          onClick: () => {
            showMaterial.value = true;
            materialTab.value = "voiceover";
            voiceoverText.value = props.node.conf.text;
          },
        },
      ]
    : [];
</script>

<template>
  <Segment
    :style="{ background: '#7E8999' }"
    :node="node"
    :start="start"
    :end="end"
    :keyframes="keyframes"
    :uniqueOptions="uniqueOptions"
    @update:start="$emit('update:start', $event)"
    @update:end="$emit('update:end', $event)"
    @update:keyframes="$emit('update:keyframes', $event)"
  >
    <svg-icon name="editor_segment_subtitle" :size="18" />
    <div class="segment-title">{{ node.conf.text }}</div>
  </Segment>
</template>
<style scoped></style>
