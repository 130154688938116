<template>
  <ToolModal title="Object Eraser" v-model="visible" @close="handleClose">
    <Tool :src="src" mode="editor" @submit="handleSubmit" />
  </ToolModal>
</template>

<script setup lang="ts">
import Tool from "./index.vue";
import ToolModal from "../../modal/toolsModal.vue";

interface SubmitEvent {
  url: string;
  HDUrl: string;
  width480: number;
  width1080: number;
}

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "update:src", "change"]);
const visible = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    visible.value = newValue;
  },
);
watch(visible, (value) => {
  emit("update:modelValue", value);
});

const handleClose = () => {
  visible.value = false;
};

function handleSubmit({ url, HDUrl, width480, width1080 }: SubmitEvent) {
  emit("update:src", url);
  emit("change", url, HDUrl, width480, width1080);
  handleClose();
}
</script>

<style scoped lang="scss"></style>
