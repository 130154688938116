<template>
  <div>
    <Topic :key="route.name" />
    <Introduction :info="info" />
    <Workflow :key="route.name" />
    <UseCase/>
    <BottomPart />
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import Workflow from './components/Workflow/index.vue';
import UseCase from './components/affiliateProgram/useCase.vue';
import BottomPart from './components/affiliateProgram/bottom-part.vue';
import Topic from './components/Topic/index.vue';
import Introduction from './components/affiliateProgram/introduction.vue';
import {step_1, step_2, step_3 } from './components/affiliateProgram/assets/index.js'

import { useRoute } from 'vue-router';

const route = useRoute();
const info = reactive({
  title: 'Why Partner with Boolvideo?',
  list: [
    {
      title: 'Earn',
      icon: step_1,
      des: `By sharing your knowledge and creative content, you'll simultaneously open up opportunities to generate revenue. Earn a generous 40% commission on each sale you generate.`
    }, {
      title: 'Growth',
      icon: step_2,
      des: `We'll provide you with promotional assets, training manuals, and helpful tips and tricks. Our affiliates often grow their followers while earning commissions by promoting Boolvideo. So let's succeed together!`
    }, {
      title: 'Support',
      icon: step_3,
      des: `Our partnership goes beyond just approving an application. Contact our team for support whenever necessary. We're always here for you!`
    },
  ]
})

</script>
