<template>
  <div class="color-picker isPick" ref="colorPicker">
    <el-tooltip
      class="box-item"
      effect="light"
      placement="bottom-start"
      :show-arrow="false"
      trigger="click"
      :teleported="false"
      popper-class="custom-tooltip"
    >
      <template #default>
        <slot></slot>
      </template>
      <template #content>
        <transition>
          <picker
            class="picker isPick"
            ref="picker"
            :value="selectedColor"
            :format="format"
            :show-alpha="showAlpha"
            :colors="colors"
            v-show="true"
            @input="onPickChange"
            @change="onPickChange"
          />
        </transition>
      </template>
    </el-tooltip>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  provide,
  computed,
  watch,
  nextTick,
  unref,
} from "vue";
import Picker from "./picker";
import ColorItem from "./color-item";
import AddColorItem from "./add-color-item";

export default defineComponent({
  name: "ColorPicker",
  components: {
    ColorItem,
    Picker,
    AddColorItem,
  },
  props: {
    value: {
      type: [String, Array],
    },
    size: {
      type: Number,
      default: 24,
    },
    format: {
      type: String,
      default: "hex",
    },
    showAlpha: {
      type: Boolean,
      default: false,
    },
    addColor: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 13,
    },
    colors: {
      type: Array,
      default: () => [
        "",
        "#FFFFFF",
        "#000000",
        "#7F3BF5",
        "#3075FF",
        "#34C724",
        "#FFBA2E",
        "#F54A45",
      ],
    },
  },
  emits: ["change", "update:value", "overflowMax", "close"],
  setup(props, { emit }) {
    let valueList = [];
    const selectedIndex = ref(0);
    // 设置添加初始值
    const selectedColor = computed(
      () => unref(valueList)[unref(selectedIndex)],
    );
    const isPickerShow = ref(false);
    const isInitPickColor = ref(props.addColor);
    const colorPicker = null;
    const picker = ref(null);

    const handleValueList = () => {
      if (props.value == null) {
        return ref([""]);
      } else {
        return computed(() =>
          Array.isArray(props.value) ? props.value : [props.value],
        );
      }
    };

    watch(
      () => props.value,
      () => {
        valueList = handleValueList();
      },
      {
        immediate: true,
      },
    );

    const colorItemStyle = computed(() => ({
      width: `${props.size}px`,
      height: `${props.size}px`,
    }));

    const colorItemSelected = (index) => {
      return (
        (props.addColor
          ? unref(valueList).length > 0
          : unref(valueList).length > 1) && unref(selectedIndex) === index
      );
    };

    const onOpenPickerShow = () => {
      isPickerShow.value = true;
      isInitPickColor.value = false;
    };

    watch(
      () => selectedIndex.value,
      () => {
        isInitPickColor.value = true;
      },
    );

    const onClosePickerShow = () => {
      selectedIndex.value = undefined;
      isPickerShow.value = false;
      picker.value?.resetValue();
    };

    const onColorClick = (e) => {
      e?.stopPropagation();
      const target = e.target;
      const popper = unref(picker)?.$el;
      const isPopperContains = popper?.contains(target) || false;
      // 点击卡片区域
      if (isPopperContains) {
        return;
      }
      const index = target.dataset?.index;
      const isColorItem =
        !unref(colorPicker)?.isEqualNode(target) &&
        unref(colorPicker)?.contains(target);
      if (!isColorItem || index == null) {
        // 关闭卡片
        if (unref(isPickerShow)) onClosePickerShow();
        return;
      }
      selectedIndex.value = +index;
      nextTick(() => {
        onOpenPickerShow();
      });
    };

    const addColorItemShow = ref(props.max > unref(valueList).length);
    const oldColorValue = ref(undefined);
    const onPickChange = (color) => {
      if (unref(isInitPickColor)) {
        return;
      }
      const index = unref(selectedIndex);
      if (unref(oldColorValue) === color) {
        return;
      }

      oldColorValue.value = color;

      // 改变数值
      let value = "";
      const isArr = Array.isArray(props.value);
      if (isArr) {
        const temp = unref(valueList).slice();
        temp[index] = color;
        value = temp;
      } else {
        value = color;
      }
      const valueArr = isArr ? value : [value];
      if (props.value == null) {
        valueList.value = valueArr;
        emit("update:value", value);
        emit("change", value, color, index);
      } else {
        emit("update:value", value);
        emit("change", value, color, index);
      }
    };

    onMounted(() => {
      document.addEventListener("click", onColorClick, false);
    });
    onUnmounted(() => {
      document.removeEventListener("click", onColorClick, false);
    });
    return {
      valueList,
      colorItemStyle,
      colorItemSelected,
      selectedColor,
      selectedIndex,
      isPickerShow,
      addColorItemShow,
      onPickChange,
      colorPicker,
      onColorClick,
      picker,
    };
  },
});
</script>
<style lang="scss">
.custom-tooltip.el-popper.is-light {
  background: none !important;
  border: none !important;
}
</style>

<style lang="scss" scoped>
:deep(.custom-tooltip.el-popper.is-light) {
  background: none !important;
  border: none !important;
}

:deep(.custom-tooltip.el-popper) {
  padding: 0;
}

.color-picker {
  display: inline-block;
  cursor: pointer;
  z-index: 999;
}

.color-item {
  margin: 5px;
}

.add-color-item {
  margin: 5px;
}

.picker {
  will-change: transform;
  z-index: 9;
  transition:
    left 60ms ease-in-out,
    top 60ms ease-in-out;
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 150ms ease-in-out,
    transform 150ms ease-in-out;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: center top;
}

[data-popper-placement="top"] {
  transform-origin: center bottom;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: scaleY(0);
}
</style>
