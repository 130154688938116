<template>
  <div class="setting-wrapper">
    <el-scrollbar>
      <div class="setting-container">
        <div class="music-wrapper">
          <div class="bgm-replace-row">
            <label>Music</label>
            <div class="bgm-replace-container">
              <div class="bgm-desc">
                <span>
                  {{ setting.bgmName }}
                </span>
                <icon-button
                  name="script_reset"
                  tip="Reset"
                  :color="bgmResetDisabled? '#BBBFC4': '#646A73'"
                  :size="18"
                  :disabled="bgmResetDisabled"
                  @click="resetAudio"
                />
              </div>
              <span 
                class="bgm-replace-button"
                @click="openAudioDialog"
              >
                Replace
              </span>          
            </div>
          </div>
          <div class="row">
            <label>Music volume</label>
            <div class="select-container row-content">
              <svg-icon 
                v-if="setting.bgmVol > 0"
                class="suffix"
                name="script_volume"
                color="#646A73"
                stroke="#646A73"
                :size="18"
              />
              <svg-icon 
                v-else
                class="suffix"
                name="script_mute"
                color="#646A73"
                :size="18"
              />
              <bv-slider 
                v-model="setting.bgmVol" 
                height="2" 
                :min="0" 
                :max="1" 
                :step="0.01" 
                :showTooltip="false" 
              />
            </div>
          </div>
          <div class="row">
            <label>Vocal</label>
            <div class="select-container row-content">
              <svg-icon
                class="suffix"
                name="script_voice"
                color="#646A73"
                :size="18"
              />
              <bv-slider 
                v-model="setting.speechVol" 
                height="2" 
                :min="0" 
                :max="1" 
                :step="0.01" 
                :showTooltip="false" 
              />
            </div>
          </div>
        </div>
        <div class="text-wrapper" :style="settingStyle">
          <div class="style-row">
            <div class="row label-row">
              <label>Caption</label>
              <bv-tip
                placement="bottom"
                :content="settingTips"
                :showAfter="0"
              >
                <el-switch 
                  size="large"
                  v-model="settingVisible" 
                  @change="updateFontOpacity" 
                />
              </bv-tip>
            </div>
          </div>
          <div class="text-setting-container" v-show="settingVisible">
            <div class="row">
              <el-scrollbar class="text-scrollbar" height="252">
                <div class="caption-grid animation-grid">
                  <button 
                    v-for="option in animationOptions" 
                    :key="option.name" 
                    :class="{ active: setting.fontSetting.animation === option.animation }" 
                    @click="clickAnimation(option.animation)"
                  >
                    {{ option.name }}
                  </button>
                </div>
                <div class="caption-grid">
                  <button
                    v-for="[key, config] of Object.entries(presetTypes)"
                    :key="key"
                    :class="{active: setting.presetId === key}"
                    @click="clickTextStyle(key)"
                  >
                    <img 
                      :src="config.url" 
                      alt=""
                    />
                  </button>
                </div>
              </el-scrollbar>
            </div>
            <div class="row font">
              <label>Font</label>
              <div class="row-content">
                <div class="col">
                  <bv-select
                    :key="switchId"
                    placement="top-start"
                    :popper-style="{ width: '226px' }"
                    :options="
                      fontFamilyOptions.sort((a, b) => (a.label > b.label ? 1 : -1))
                    "
                    :model-value="setting.fontSetting.fontFamily"
                    @change="updateFontFamily"
                  >
                    <template #option="{ option }">
                      <img
                        :src="fontIcons[option.label]"
                        :style="{ height: '14px' }"
                        draggable="false"
                        loading="lazy"
                      />
                    </template>
                  </bv-select>
                </div>
                <div class="col">
                  <bv-select
                    :key="switchId"
                    numberInput
                    placement="top-start"
                    :popper-style="{ width: '106px' }"
                    :options="fontSizeOptions"
                    :model-value="Math.round(setting.fontSetting.fontSize)"
                    @change="updateFontSize"
                  />
                </div>
                <div class="col color-col">
                  <bv-tip
                    content="Fill"
                    :showAfter="0"
                  >
                    <color-picker 
                      :value="setting.fontSetting.fill" 
                      :size="30" 
                      @change="updateFontFill"
                    />
                  </bv-tip>
                </div>
                <div class="col color-col" v-show="setting.fontSetting.animation === 'ColorUp'">
                  <bv-tip
                    content="Highlight"
                    :showAfter="0"
                  >
                    <color-picker 
                      :value="setting.fontSetting.highlight" 
                      :size="30" 
                      @change="updateHighlight"
                    />
                  </bv-tip>
                </div>
                <div class="col color-col">
                  <bv-tip
                    content="Stroke"
                    :showAfter="0"
                  >
                    <color-picker 
                      :value="setting.fontSetting.stroke" 
                      :size="30" 
                      :colors="nullableColors"
                      @change="updateFontStroke"
                    />
                  </bv-tip>
                </div>
                <div class="col color-col">
                  <bv-tip
                    content="Background"
                    :showAfter="0"
                  >
                    <color-picker 
                      :value="setting.fontSetting.background" 
                      :size="30" 
                      :colors="nullableColors"
                      @change="updateFontBackground"
                    />
                  </bv-tip>
                </div>
              </div>
            </div>
            <div class="row positiony">
              <label>Position Y</label>
              <div class="select-container row-content">
                <bv-slider 
                  v-model="setting.posY" 
                  height="2" 
                  :min="60" 
                  :max="getPostionYMax()" 
                  :step="1" 
                  :showTooltip="false" 
                />
              </div>
            </div>
            <!-- <div class="row">
              <label>Gradient background</label>
              <div class="select-container row-content">
                <bv-slider 
                  v-model="gradientBg" 
                  height="2" 
                  :min="0" 
                  :max="100" 
                  :step="1" 
                  :showTooltip="false" 
                />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
  <AudioDialog
    v-model:visible="audioVisible" 
    @replace="handleReplaceAudio"
  />
</template>

<script setup>
import {
  fontFamilyOptions,
  fontIcons,
} from "@/constants/text";
import { 
  defaultStyle, 
  presetTypes,
  animationOptions,
} from "../../constants/text";
import { throttled } from "@/utils";
import AudioDialog from "../dialog/audiodialog.vue";
import { useTrackStore } from "@/store/modules/track";
import { useSettingStore } from "../../stores/setting";
import { useScriptStore } from "../../stores/script";
import { useDraftStore } from "../../stores/draft";

const { 
  totalFrame,
  playing,
  creator,
  pause, 
  refresh,
  getTrack,
  removeNode,
  addAudioNode,
  updateBgmDuration,
} = useScriptStore();
const { 
  ratio, 
  videoType,
  updateDraft 
} = useDraftStore();
const {
  setting,
} = useSettingStore();
const { collectData, track } = useTrackStore();

const switchId = ref(0);
const audioVisible = ref(false);
const audioReplacing = ref(false);
const settingVisible = ref(setting.value.fontSetting.opacity === 1);
const settingTips = computed(() => {
  if (settingVisible.value) {
    return "Caption turn off";
  }
  else {
    return "Caption turn on";
  }
});
const settingStyle = computed(() => {
  if (settingVisible.value) {
    return "flex: 1 1";
  }
  else {
    return "flex: 0 0";
  }
});
const bgmResetDisabled = computed(() => {
  const { defBgmConf, bgmName } = setting.value;
  return bgmName === defBgmConf.name;
});
const nullableColors = [
  "",
  "#FFFFFF",
  "#000000",
  "#7F3BF5",
  "#3075FF",
  "#34C724",
  "#FFBA2E",
  "#FF922E",
];

const fontSizeOptions = [
  {
    label: "33",
    value: 33,
  },
  {
    label: "36",
    value: 36,
  },
  {
    label: "40",
    value: 40,
  },
  {
    label: "48",
    value: 48,
  },
  {
    label: "55",
    value: 55,
  },
];

const bgmVolTrackHelper = throttled(() => {
  collectData("boolvideo_scene_edit_click", {
    click: "music_volume",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
}, 50000);
watch(
  () => setting.value.bgmVol,
  (value) => {
    const bgmTrack = getTrack("audio");
    for (const node of bgmTrack.children) {
      node.conf.volume = value;
      node.setVolume(value);
    }
    bgmVolTrackHelper();
    updateDraft();
  },
);

const vocalVolTrackHelper = throttled(() => {
  collectData("boolvideo_scene_edit_click", {
    click: "music_vocal",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
}, 50000);
watch(
  () => setting.value.speechVol,
  (value) => {
    const speechTrack = getTrack("speech");
    for (const node of speechTrack.children) {
      if(node.type === "speech"){
        node.conf.volume = value;
        node.setVolume(value);
      }
    }
    vocalVolTrackHelper();
    updateDraft();
  }
);

watch(
  () => setting.value.posY,
  (value) => {
    // 边播放边调整会卡顿
    if (playing.value) pause();
    const textTrack = getTrack("subtitle");
    for (const node of textTrack.children) {
      node.conf.y = value;
    }
    nextTick(() => {
      creator.value.refresh();
      updateDraft();
    });
  }
);

const openAudioDialog = () => {
  pause();
  audioVisible.value = true;
};

const resetAudio = async () => {
  const trackHelper = () => {
    collectData("boolvideo_scene_edit_click", {
      click: "music_reset",
      video_type: videoType.value,
    });
    track("boolvideo_scene_edit_click");
  };
  
  trackHelper();
  const { 
    name, 
    duration,
    ss, 
    src: url,
    sourceId : id,
  } = setting.value.defBgmConf;
  replaceAudio({ 
    name,
    url,
    id,
    duration,
    ss,
  });
};

const handleReplaceAudio = (file) => {
  const trackHelper = () => {
    collectData("boolvideo_scene_edit_click", {
      click: "music_replace",
      video_type: videoType.value,
    });
    track("boolvideo_scene_edit_click");
  };
  trackHelper();
  replaceAudio(file);
};

const replaceAudio = async (file) => {
  if (audioReplacing.value) return;  
  audioReplacing.value = true;

  const { name, url, id, duration, ss = 0 } = file;
  if (setting.value.bgmName !== name) {
    const bgmNodes = [ ...getTrack("audio").children ];
    await addAudioNode({ 
      name, 
      sourceId: id, 
      src: url, 
      start: 0, 
      end: 0, 
      ss,
      duration, 
      volume: setting.value.bgmVol,
    });
    for (const node of bgmNodes) {
      removeNode(node);
    }
    await updateBgmDuration(totalFrame.value);
    nextTick(refresh);
    updateDraft();

    setting.value.bgmName = name;
  }
  
  audioReplacing.value = false;
  audioVisible.value = false;
};

const updateFontOpacity = (value) => {
  const opacity = value ? 1 : 0;
  const newStyle = {
    ...setting.value.fontSetting,
    opacity,
  };
  setting.value.fontSetting = newStyle;
  
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    node.conf.opacity = value;
  }

  nextTick(() => {
    creator.value.render();
    updateDraft();
  });
};

const updateFontFamily = (value) => {
  const newStyle = {
    ...setting.value.fontSetting,
    fontFamily: value,
  };
  setting.value.fontSetting = newStyle;
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    node.conf.fontFamily = value;
  }
  updateDraft();
};

const updateFontSize = (value) => {
  const newStyle = {
    ...setting.value.fontSetting,
    fontSize: value,
  };
  setting.value.fontSetting = newStyle;
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    node.conf.fontSize = value;
  }
  nextTick(() => {
    creator.value.render();
    updateDraft();
  });
};

const updateFontFill = (value) => {
  const newStyle = {
    ...setting.value.fontSetting,
    fill: value,
  };
  setting.value.fontSetting = newStyle;
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    node.conf.fill = value;
  }
  nextTick(() => {
    creator.value.render();
    updateDraft();
  });
};

const updateHighlight = (value) => {
  const newStyle = {
    ...setting.value.fontSetting,
    highlight: value,
  };
  setting.value.fontSetting = newStyle;
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    node.conf.highlight = value;
  }
  nextTick(() => {
    creator.value.refresh();
    updateDraft();
  });
};

const updateFontStroke = (value) => {
  const newStyle = {
    ...setting.value.fontSetting,
    stroke: value,
  };
  setting.value.fontSetting = newStyle;
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    node.conf.stroke = value;
  }
  nextTick(() => {
    creator.value.render();
    updateDraft();
  });
};

const updateFontBackground = (value) => {
  const newStyle = {
    ...setting.value.fontSetting,
    background: value,
  };
  setting.value.fontSetting = newStyle;
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    node.conf.background = value;
    node.conf.backgroundRadius = 10;
  }
  nextTick(() => {
    creator.value.render();
    updateDraft();
  });
}

const getPostionYMax = () => {
  let max = 0;
  switch(ratio.value) {
    case "1:1":
    case "16:9":
      max = 425;
      break;
    case "9:16":
      max = 800;
      break;
  }
  return max;
};

const clickAnimation = (animation) => {
  const fontSetting = setting.value.fontSetting;

  if (fontSetting.animation === animation) {
    return;
  }
  const newStyle = {
    ...fontSetting,
    animation,
  };
  setting.value.fontSetting = newStyle;
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    node.conf.animation = animation;
  }
  nextTick(() => {
    creator.value.refresh();
    updateDraft();
  });
}

const clickTextStyle = (key) => {
  if (setting.value.presetId === key) {
    return;
  }
  
  setting.value.presetId = key;
  const newStyle = { ...defaultStyle, ...presetTypes[key] };
  setting.value.fontSetting = {
    ...setting.value.fontSetting,
    opacity: 1,
    fontFamily: newStyle.fontFamily[0].family,
    fill: newStyle.fill,
    stroke: newStyle.stroke,
    background: newStyle.background,
  };
  
  const textTrack = getTrack("subtitle");
  for (const node of textTrack.children) {
    for (const [key, value] of Object.entries(newStyle)){
      node.conf[key] = value;
    }
  }
  nextTick(() => {
    creator.value.render();
    updateDraft();
  });
};

onActivated(() => {
  switchId.value ++;
});
onDeactivated(() => {
  audioVisible.value = false;
});
</script>

<style lang="scss" scoped>
.setting-wrapper {
  height: 100%;
  padding-bottom: 46px;
}
.setting-container {
  padding-right: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
}

.setting-container label {
  flex: 0 0 142px;
  margin-right: 70px;
  color: #646A73;
}

.music-wrapper {
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  border: 0.5px solid #E5E7EB;
  margin-bottom: 26px;
}

.bgm-replace-row {
  flex: 1 1;
  min-width: 0;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.bgm-replace-container {
  flex: 1 1;
  min-width: 0;
  display: flex;
  align-items: center;
}

.bgm-desc {
  flex: 1 1;
  min-width: 0;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 4px;
  background: #F3F5F7;

  & > span {
    color: #646A73;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > .icon-button {
    flex: 0 0 26px;
  }
}

.bgm-replace-button {
  margin-left: 14px;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
}

.label-row {
  justify-content: space-between;
}

.music-wrapper > .row + .row {
  margin-top: 15px;
}

.text-setting-container {
  & > .row + .row{
    margin-top: 30px;
  } 

  & > .row.font,.row.positiony {
    padding: 0 24px;
  }
}

.text-setting-container > .row

.text-scrollbar {
  width: 100%;
  padding: 0 24px;
  margin-top: 22px;
}

.row-content {
  display: flex;
  align-items: center;
  flex: 1 1;
}

.select-container {
  position: relative;

  & > .suffix {
    position: absolute;
    left: -40px;
  }
}

.text-wrapper {
  flex: 1 1;
  width: 100%;
  padding: 20px 0;
  border-radius: 10px;
  border: 0.5px solid #E5E7EB;
}

.style-row > .row{
  padding: 0 24px;
}

.style-row > label {
  margin-bottom: 16px;
  display: block;
}

.caption-grid {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(107px, 1fr));
}

.animation-grid {
  margin-bottom: 46px;
}
.caption-grid button {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  color: #000000;
  background-color: #f3f5f7;
  transition: background-color 200ms;

  & > img {
    -webkit-user-drag: none;
  }
}

.animation-grid {
  & > button {
    font-size: 14px;
    font-weight: 500;
    height: 38px;

    &.active {
      background-color: #f8f5ff;
    }
  }
}


.caption-grid button:hover {
  background-color: #e8e9ec;
}

.caption-grid button.active {
  border: 1px solid #6741FF;
}

:deep(.select-container .el-slider) {
  --el-slider-height: 2px;
  --el-slider-button-size: 20px;
  --el-slider-button-wrapper-size: 20px;
  --slider-main-color: #646A73 !important;
  --slider-runway-color: #E5E7EB !important;
  --slider-track-color: #646A73 !important;
}

:deep(.select-container .el-slider__button) {
  border: 0.5px solid #E5E7EB;
  box-shadow: 0px 1.2px 4.802px rgba(0, 0, 0, 0.10);
}

:deep(.bv-select-button .select-wapper) {
  padding: 0 8px
}

.setting-container :deep(.bv-select-button .select-value){
  line-height: 30px;
  color: #060606;
  font-size: 12px;
  font-weight: 400;
}

.label-row :deep(.bv-tooltip-content-container) {
  width: fit-content;
}

.col {
  flex: 1 1;
}

.color-col.col {
  flex: 0 0;
}

.col:not(:last-child) {
  margin-right: 14px;
}

:deep(.color-picker .color-item) {
  margin: 0;
  border-radius: 4px !important;
  border-color: rgba(31, 35, 41, 0.08);
  outline: none;
}

.setting-container :deep(.el-switch__core) {
  --el-switch-on-color: #6741FF;
  --el-switch-off-color: #BBBFC4;

  height: 28px;
  border-radius: 14px;
}

.setting-container :deep(.icon-button) {
  &.disabled {
    cursor: default;
  }

  & svg.disabled {
    cursor: default;
  }
}
</style>