<template>
  <div
    class="p-1 w-fit rounded hover:bg-closeBtnHoverColor cursor-pointer"
    style="z-index: 2000"
    @click="handleBack"
  >
    <svg
      t="1691045055874"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2280"
      width="24"
      height="24"
    >
      <path
        d="M927.859 478.061H168.863l308.722-308.868c11.685-11.69 11.435-30.88-0.558-42.868-11.992-11.986-31.183-12.226-42.868-0.536l-358.96 359.14a30.551 30.551 0 0 0-5.294 5.713c-4.048 5.373-6.014 11.84-5.906 18.328-0.13 7.795 2.725 15.561 8.596 21.432L434.16 892.147c11.685 11.691 30.876 11.45 42.868-0.535 11.993-11.986 12.243-31.177 0.558-42.869L168.442 539.454h759.417c16.527 0 29.925-13.739 29.925-30.694s-13.398-30.699-29.925-30.699z"
        p-id="2281"
      ></path>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
const router = useRouter();
const handleBack = () => {
  router.back();
};
</script>

<style scoped></style>
