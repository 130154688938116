<template>
  <div>
    <ToolList
      v-if="onlineToolList.length > 0"
      :toolList="onlineToolList"
      style="margin-bottom: 80px"
    />
    <ToolList v-if="offlineToolList.length > 0" :toolList="offlineToolList" />
    <ToolInfoModal
      :list="toolList"
      v-bind="infoModal"
      @update:index="infoModalState.index = $event"
      @update:open="infoModalState.open = $event"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import ToolList from "../card/tool-list.vue";
import { useToolStore } from "../../stores/tool";
import ToolInfoModal from "../modal/tool-info-modal.vue";
import type { InfoModal } from "../../type";
import { createGroupProxy } from "@/utils";

const { toolList } = useToolStore();
const infoModal = reactive<InfoModal>({
  open: false,
  index: 0,
  getIndex(name) {
    return toolList.findIndex((item) => item.name === name);
  },
});

const { rest: infoModalState } = createGroupProxy(infoModal, {
  _: ["getIndex"],
});

const onlineToolList = computed(() => {
  return toolList.filter((tool) => tool.online);
});

const offlineToolList = computed(() => {
  return toolList.filter((tool) => !tool.online);
});

const types = ["image", "copywriting", "video"];

const getTypeToolList = (type: string) => {
  return onlineToolList.value.filter((tool) => tool.type === type);
};

watch(
  () => infoModalState.index,
  (index) => {
    const length = toolList.length;
    infoModalState.index = ((index % length) + length) % length;
  },
);

provide("infoModal", infoModal);
</script>

<style lang="scss" scoped></style>
