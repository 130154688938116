<template>
  <li v-if="'items' in item" class="group-item">
    <span>{{ item.label }}</span>
    <ul class="select-list-group">
      <select-item
        v-for="subItem of item.items"
        v-model="props.modelValue"
        :item="subItem"
        :group="item.value"
      />
    </ul>
  </li>
  <li
    v-else
    @click="handleToggle"
    :class="modelValue[group].has(item.value) ? 'active' : ''"
  >
    {{ item.label }}
    <svg-icon
      v-if="modelValue[group].has(item.value)"
      name="icon_select"
      :size="16"
    />
  </li>
</template>

<script setup lang="ts">
import SelectItem from "./item.vue";
import type { PropType } from "vue";
import type { SelectItemType, ModelValue } from "./type";

const props = defineProps({
  item: {
    type: Object as PropType<SelectItemType>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<ModelValue>,
    required: true,
  },
  group: {
    type: String,
    default: "_default",
  },
});

function handleToggle() {
  const data = props.modelValue[props.group];

  if (data.has(props.item.value)) {
    data.delete(props.item.value);
  } else {
    data.add(props.item.value);
  }
}
</script>

<style scoped lang="scss">
.group-item > span {
  display: inline-block;
  padding-block: 5px;
  color: #646a73;
}

.select-list-group {
  padding-left: 16px;
}

li:not(.group-item) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding-block: 5px;
  cursor: pointer;

  &:hover::after {
    background-color: #ebedef;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    height: 32px;
    background-color: transparent;
    z-index: -1;
    transition: background-color 0.2s;
  }
}

li.active {
  color: #875eff;
}
</style>
