<script setup>
import { useCreatorStore } from "../../stores";
import { getComputedSize, getInnerBounds, clamp } from "../../utils";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  event: {
    type: Object,
    default: undefined,
  },
});
const emit = defineEmits(["update:modelValue", "selectend"]);

const wrapper = inject("wrapper");
const scrollRef = inject("scrollRef");
const { nodeMap, activeNodeMap, setActiveNode } = useCreatorStore();

const ro = ref(null);
const selection = ref(null);
const selecting = ref(false);
const parentWidth = ref(0);
const parentHeight = ref(0);
const left = ref(0);
const right = ref(0);
const top = ref(0);
const bottom = ref(0);
const width = ref(0);
const height = ref(0);
const beforePosition = reactive({ pageX: 0, pageY: 0 });

const ticker = ref(null);
const scrollX = ref(null);
const scrollY = ref(null);
const scrollBounds = ref(null);

const style = computed(() => ({
  left: `${left.value}px`,
  top: `${top.value}px`,
  width: `${width.value}px`,
  height: `${height.value}px`,
}));

onMounted(() => {
  resetParentSize();

  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);
  
  ro.value = new ResizeObserver(resetParentSize);
  ro.value.observe(selection.value.parentElement);
});
onBeforeUnmount(() => {
  ro.value.unobserve(selection.value.parentElement);
  ro.value.disconnect();
  ro.value = null;

  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp);
});

watch(() => props.event, (e) => {
  left.value = e.x;
  top.value = e.y;
  
  resetPosition();
  resetParentSize();
  savePosition(e);
  calcScrollBounds();
});

function mouseMove(e) {
  if (props.modelValue) {
    select(e);
  }
}

function mouseUp() {
  emit("update:modelValue", false);
  
  left.value = 0;
  top.value = 0;
  width.value = 0;
  height.value = 0;

  if (selecting.value) {
    selecting.value = false;
    emit("selectend");
  }
  stopScroll();
  resetPosition();
}

function select(e) {
  const scrollElement = scrollRef.value.wrapRef;
  const scrollLeft = beforePosition.scrollLeft - scrollElement.scrollLeft;
  const scrollTop = beforePosition.scrollTop - scrollElement.scrollTop;
  const deltaX = beforePosition.pageX - e.pageX + scrollLeft;
  const deltaY = beforePosition.pageY - e.pageY + scrollTop;

  if (deltaX === 0 && deltaY === 0) {
    return;
  }
  selecting.value = true;

  let newLeft = beforePosition.left;
  let newRight = beforePosition.right;
  let newTop = beforePosition.top;
  let newBottom = beforePosition.bottom;

  if (deltaX > 0) {
    newLeft = clamp(beforePosition.left - deltaX, 0, beforePosition.left);
  } else {
    newRight = clamp(beforePosition.right + deltaX, 0, beforePosition.right);
  }
  if (deltaY > 0) {
    newTop = clamp(beforePosition.top - deltaY, 0, beforePosition.top);
  } else {
    newBottom = clamp(beforePosition.bottom + deltaY, 0, beforePosition.bottom);
  }

  const newWidth = computeWidth(parentWidth.value, newLeft, newRight);
  const newHeight = computeHeight(parentHeight.value, newTop, newBottom);

  scroll(e);
  contains(newLeft, newTop, newWidth, newHeight);

  left.value = newLeft;
  right.value = newRight;
  top.value = newTop;
  bottom.value = newBottom;

  width.value = newWidth;
  height.value = newHeight;
}

function scroll(e) {
  const bounds = scrollBounds.value;

  if (e.pageX <= bounds.minLeft) {
    scrollX.value = -10;
  } else if (e.pageX >= bounds.minRight) {
    scrollX.value = 10;
  } else {
    scrollX.value = 0;
  }
  if (e.pageY <= bounds.minTop) {
    scrollY.value = -5;
  } else if (e.pageY >= bounds.minBottom) {
    scrollY.value = 5;
  } else {
    scrollY.value = 0;
  }
  if (scrollX.value || scrollY.value) {
    startScroll();
  } else {
    stopScroll();
  }
}

function tick() {
  const scrollElement = scrollRef.value.wrapRef;
  const bounds = scrollBounds.value;

  if (scrollX.value) {
    if (
      scrollX.value < 0 && scrollElement.scrollLeft <= 0 || 
      scrollX.value > 0 && scrollElement.scrollLeft >= bounds.maxLeft
    ) {
      scrollX.value = 0;
    }
    let newLeft = left.value;
    let newRight = right.value;

    if (
      (scrollX.value > 0 && newLeft < beforePosition.left) ||
      (scrollX.value < 0 && newRight >= beforePosition.right)
    ) {
      newLeft = clamp(left.value + scrollX.value, 0, beforePosition.left);
    } else {
      newRight = clamp(right.value - scrollX.value, 0, beforePosition.right);
    }
    const newWidth = computeWidth(parentWidth.value, newLeft, newRight);

    left.value = newLeft;
    right.value = newRight;
    width.value = newWidth;

    scrollElement.scrollLeft = clamp(scrollElement.scrollLeft + scrollX.value, 0, bounds.maxLeft);
  }
  if (scrollY.value) {
    if (
      scrollY.value < 0 && scrollElement.scrollTop <= 0 || 
      scrollY.value > 0 && scrollElement.scrollTop >= bounds.maxTop
    ) {
      scrollY.value = 0;
    }
    let newTop = top.value;
    let newBottom = bottom.value;

    if (
      (scrollY.value > 0 && newTop < beforePosition.top) ||
      (scrollY.value < 0 && newBottom >= beforePosition.bottom)
    ) {
      newTop = clamp(top.value + scrollY.value, 0, beforePosition.top);
    } else {
      newBottom = clamp(bottom.value - scrollY.value, 0, beforePosition.bottom);
    }
    const newHeight = computeHeight(parentHeight.value, newTop, newBottom);

    top.value = newTop;
    bottom.value = newBottom;
    height.value = newHeight;

    scrollElement.scrollTop = clamp(scrollElement.scrollTop + scrollY.value, 0, bounds.maxTop);
  }
  contains(left.value, top.value, width.value, height.value);
  if (scrollX.value || scrollY.value) {
    ticker.value = requestAnimationFrame(tick);
  }
}

function startScroll() {
  if (!ticker.value) {
    ticker.value = requestAnimationFrame(tick);
  }
}

function stopScroll() {
  if (ticker.value) {
    cancelAnimationFrame(ticker.value);
  }
  ticker.value = null;
}

function contains(left, top, width, height) {
  const elements = document.querySelectorAll(".segment");
  
  for (const element of elements) {
    const [x, y] = getInnerBounds(wrapper.value, element);
    const [w, h] = getComputedSize(element);

    const right = left + width;
    const bottom = top + height;
    const r = x + w;
    const b = y + h;

    if (x <= right && left <= r && y <= bottom && top <= b) {
      setActiveNode(nodeMap.get(element.dataset.id), false);
    } else {
      activeNodeMap.delete(element.dataset.id);
    }
  }
}

function calcScrollBounds() {
  const bounds = scrollBounds.value;
  const scrollElement = scrollRef.value.wrapRef;
  const scrollBox = scrollElement.getBoundingClientRect();

  bounds.minLeft = scrollBox.left + 20;
  bounds.maxLeft = scrollElement.scrollWidth - scrollBox.width - 1;
  bounds.minRight = scrollBox.right - 20;
  bounds.minTop = scrollBox.top + 20;
  bounds.maxTop = scrollElement.scrollHeight - scrollBox.height - 1;
  bounds.minBottom = scrollBox.bottom - 20;
}

function savePosition(e) {
  const scrollElement = scrollRef.value.wrapRef;

  beforePosition.pageX = e.pageX;
  beforePosition.pageY = e.pageY;
  beforePosition.left = left.value;
  beforePosition.right = right.value;
  beforePosition.top = top.value;
  beforePosition.bottom = bottom.value;
  beforePosition.scrollLeft = scrollElement.scrollLeft;
  beforePosition.scrollTop = scrollElement.scrollTop;
}

function resetPosition() {
  beforePosition.pageX = 0;
  beforePosition.pageY = 0;

  scrollBounds.value = {
    maxLeft: null,
    maxRight: null,
    maxTop: null,
    maxBottom: null,
  };
}

function resetParentSize() {
  const [w, h] = getComputedSize(selection.value.parentElement);

  parentWidth.value = w;
  parentHeight.value = h;

  right.value = parentWidth.value - width.value - left.value;
  bottom.value = parentHeight.value - height.value - top.value;
}

function computeWidth(parentWidth, left, right) {
  return parentWidth - left - right;
}

function computeHeight(parentHeight, top, bottom) {
  return parentHeight - top - bottom;
}
</script>
<template>
  <div v-show="selecting" ref="selection" class="selection" :style="style"></div>
</template>
<style scoped>
.selection {
  position: absolute;
  border: 1px solid #875EFF;
  background-color: rgba(103, 65, 255, 0.05);
  z-index: 5;
}
</style>