<script setup>
import { useCreatorStore, useCopyStore } from "../../stores";
import SceneSegment from "../segments/scenesegment.vue";
import TextSegment from "../segments/textsegment.vue";
import SubtitleSegment from "../segments/subtitlesegment.vue";
import GraphicSegment from "../segments/graphicsegment.vue";
import ImageSegment from "../segments/imagesegment.vue";
import VideoSegment from "../segments/videosegment.vue";
import EffectSegment from "../segments/effectsegment.vue";
import StickerSegment from "../segments/stickersegment.vue";
import FilterSegment from "../segments/filtersegment.vue";
import AudioSegment from "../segments/audiosegment.vue";
import SpeechSegment from "../segments/speechsegment.vue";
import TransitionSegment from "../segments/transitionsegment.vue";
import { clamp } from "../../utils";

const {
  empty,
  timeline,
  totalFrame,
  tracks,
  frameToWidth,
} = useCreatorStore();
const { canPaste, paste } = useCopyStore();

const timelineRef = inject("timeline");
const ro = ref(null);
const width = ref(0);
const reversedTracks = computed(() => {
  const reversed = [];
  for (let i = tracks.value.length; i--; i >= 0) {
    const track = tracks.value[i];
    reversed.push(track);
  }
  return reversed;
});
const style = computed(() => ({
  width: `${width.value}px`,
}));
const contextMenuOptions = computed(() => [
  {
    label: 'Paste',
    prefix: {
      name: 'icon_paste',
    },
    disabled: !canPaste.value,
    onClick: paste,
  },
]);

onMounted(() => {
  ro.value = new ResizeObserver(resize);
  ro.value.observe(timelineRef.value);
});
onBeforeUnmount(() => {
  ro.value.disconnect();
  ro.value = null;
});

watch(totalFrame, (newTotalFrame) => {
  if (timeline.autoFit && newTotalFrame > 0) {
    const newScale = Math.round(frameToWidth(timelineRef.value.clientWidth) / frameToWidth(newTotalFrame * 1.2) / timeline.baseline);
    timeline.scale = clamp(newScale, timeline.minScale, 60);
  }
  resize();
});
watch(() => timeline.frameWidth, resize);

function resize() {
  const newWidth = frameToWidth(totalFrame.value * 1.2);
  width.value = Math.max(newWidth, timelineRef.value.clientWidth);
}
</script>
<template>
  <bv-contextmenu
    :options="contextMenuOptions"
    :width="187"
  >
    <div class="track-list" :style="style">
      <div v-if="empty" class="empty-container">
        <div class="empty">
          <svg-icon name="editor_media" color="#8F959E" :size="18" />
          <span>Drop media here to get started</span>
        </div>
      </div>
      <div
        v-else
        v-for="track in reversedTracks"
        class="track"
        tabindex="-1"
        :key="track.id"
        :class="track.kind"
      >
        <template v-for="node in track.children" :key="node.id">
          <scene-segment
            v-if="node.type === 'scene'"
            :node="node.children[0]"
          />
          <transition-segment
            v-if="node.type === 'transition'" 
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            :node="node"
          />
          <text-segment
            v-else-if="node.type === 'text'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:keyframes="node.conf.keyframes"
            :node="node"
          />
          <subtitle-segment
            v-else-if="node.type === 'subtitle'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:keyframes="node.conf.keyframes"
            :node="node"
          />
          <graphic-segment
            v-else-if="node.type === 'graphic'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:keyframes="node.conf.keyframes"
            :node="node"
          />
          <image-segment
            v-else-if="node.type === 'image'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:keyframes="node.conf.keyframes"
            v-model:mask="node.conf.mask"
            :node="node"
            :src="node.conf.src"
          />
          <video-segment
            v-else-if="node.type === 'video'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:ss="node.conf.ss"
            v-model:keyframes="node.conf.keyframes"
            v-model:mask="node.conf.mask"
            :node="node"
            :src="node.conf.src"
          />
          <sticker-segment
            v-else-if="node.type === 'sticker'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:keyframes="node.conf.keyframes"
            :node="node"
          />
          <audio-segment
            v-else-if="node.type === 'audio'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:ss="node.conf.ss"
            :node="node"
          />
          <speech-segment
            v-else-if="node.type === 'speech'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:ss="node.conf.ss"
            :node="node"
          />
          <effect-segment
            v-else-if="node.type === 'effect'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            v-model:keyframes="node.conf.keyframes"
            :node="node"
          />
          <filter-segment
            v-else-if="node.type === 'filter'"
            v-model:start="node.conf.start"
            v-model:end="node.conf.end"
            :node="node"
          />
        </template>
      </div>
    </div>
  </bv-contextmenu>
</template>
<style scoped>
.track-list {
  display: flex;
  flex-direction: column;
  padding: 54px 0 26px;
  position: relative;
}
.track {
  background-color: #f3f5f7;
  color: #d4d4d4;
  position: relative;
  width: 100%;
  height: 26px;
}
.track + .track {
  margin-top: 10px;
}
.track:focus {
  background-color: #ebedef;
}
.track.primary,
.track.image {
  height: 56px;
}
.empty-container {
  width: 100%;
  height: 56px;
  padding-left: 16px;
  padding-right: 26px;
}
.empty svg {
  margin-right: 12px;
}
.empty {
  width: 100%;
  height: 100%;
  background: #f3f5f7;
  color: #8f959e;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.segment-shadow) {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #d5d6d7;
  pointer-events: none;
}
</style>
