<template>
  <ol class="" :style="{ ...gridStyle }">
    <GridItem
      v-for="(item, index) in innerImageList"
      :key="item.url"
      :item="item"
      @click="handleSelect(item, index)"
      :selectedLogoUrl="selectedLogoUrl"
      :isActive="selectUrl == item.url"
      v-if="innerImageList && innerImageList.length"
    />
    <div class="empty-logo-box" v-else>no image</div>
  </ol>
</template>

<script setup>
import { ref, watch } from "vue";
import GridItem from "./grid-item.vue";

const emits = defineEmits(["select"]);

const props = defineProps({
  selectedLogoUrl: String,
  imageList: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
const selectUrl = ref("");
const innerImageList = ref([]);

const gridStyle = ref({
  display: "grid",
  gridGap: "11px",
  gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
  listStyle: "none",
});

watch(
  () => props.imageList,
  (value) => {
    innerImageList.value = value;
  },
  {
    deep: true,
    immediate: true,
  },
);

const handleSelect = (item, index) => {
  if (!item.url) {
    return;
  }
  selectUrl.value = item.url;
  emits("select", item);
};
</script>

<style scoped>
.empty-logo-box {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ebedef;
}
</style>
