<template>
  <div 
    class="scene-content"
  >
    <div 
      :class="{ hide: !loading, appear: loading }"
      class="input-loading"
    >
      Generating...
    </div>
    <el-input
      class="scene-content-input"
      :class="{ hide: loading, appear: !loading }"
      v-model="userInput"
      type="textarea"
      placeholder="Enter scene copywriting"
      :autosize="true"
      @change="change"
    />
  </div>
</template>

<script setup>
import { useScriptStore } from "../../stores/script";
import { observeNode } from "../../utils/observe";
import { removeEscapeHtml } from "@/utils";

const { 
  loadingScene,
} = useScriptStore();

const emits = defineEmits(["change"]);
const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
  nodes: {
    type: Array,
  },
});

const userInput = ref("");
const loading = computed(() => 
  loadingScene.value?.sceneId === props.scene.sceneId
);

const setup = () => {
  if(props.nodes && props.nodes.length > 0) {
    let newText = "";
    for (const node of props.nodes) {
      observeNode(node);
      newText += node.conf.text;
    }
    userInput.value = newText;
  }
};

const change = (value) => {
  userInput.value = removeEscapeHtml(value);
  emits("change", userInput.value);
}

const resetText = () => {
  if(props.nodes && props.nodes.length > 0) {
    let newText = "";
    for (const node of props.nodes) {
      newText += node.conf.text;
    }
    userInput.value = newText;
  }
};

defineExpose({ resetText, userInput });

onMounted(setup);
</script>

<style lang="scss" scoped>
.scene-content{
  :deep(.el-textarea__inner) {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border: none;
    box-shadow: none;
    outline: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }  
}

.input-loading {
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  border-radius: 4px;
  color: #060606;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  background-color: #FFFFFF;
}

.hide {
  position: absolute;
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.appear {
  position: relative;
  opacity: 1;
  pointer-events: all;
}
</style>