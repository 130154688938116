import type {
  VNode,
  PropType,
  RendererNode,
  RendererElement,
  ExtractPropTypes,
} from "vue";

export type CardOperationName =
  | "edit"
  | "delete"
  | "download"
  | "duplicate"
  | "rename"
  | "reuse";

export interface CardItemInfo {
  id: string;
  title: string;
  poster: string;
  duration: number;
  updateTime: number;
}

export interface CardOperation {
  text: string;
  iconName: string;
  handler(id: string): void;
}

export const cardItemPropsSchema = {
  info: {
    type: Object as PropType<CardItemInfo>,
    required: true,
  },
  operations: {
    type: Array as PropType<CardOperation[]>,
    default: () => [],
  },
  handlePosterLoadFail: {
    type: Function as PropType<(e: Event) => void>,
    default: () => {},
  },
  handleClickPoster: {
    type: Function as PropType<(e: MouseEvent) => void>,
    default: () => {},
  },
  posterStyle: {
    type: String,
    default: "",
  },
  selected: {
    type: Boolean,
    default: false,
  },
} as const;

export type CardItemVNode = VNode<
  RendererNode,
  RendererElement,
  ExtractPropTypes<typeof cardItemPropsSchema>
>;

export interface CardItemData<T> {
  key: string;
  vnode: CardItemVNode;
  record: T;
  containerClass?: string;
}

export type CardData<T> =
  | {
      key: `#${string}`;
      vnode: VNode;
      record: null;
      containerClass?: string;
    }
  | CardItemData<T>;

export interface SelectItemType {
  label: string;
  value: string;
  items?: {
    label: string;
    value: string;
  }[];
}
