import { _get, _post } from "@/utils/https";
const { VITE_API_BASE } = import.meta.env;

interface QueryVoiceListParams {
  country?: string;
  gender?: string;
  language?: string;
  limit?: number;
  locale?: string;
};

interface GetLanguageListParams {
  locale?:  string;
  gender?: string;
  style?: string;
}

export const getLanguageList = async (data: GetLanguageListParams) => {
  return await _post(VITE_API_BASE + "/tools/locals", data);
}

export const getTranslateLanguageList = async (data) => {
  return await _get(VITE_API_BASE + "/tools/query_translation_languages", data);
}

export const queryVoiceList = async (data: QueryVoiceListParams) => {
  return await _get(VITE_API_BASE + "/tools/voices", data);
}

export const getRecentVoiceList = async () => {
  return await _get(VITE_API_BASE + "/tools/voice_recent_use_record");
}

