<template>
  <div class="previewer video-previewer" no-close>
    <video
      autoplay
      muted
      ref="videoRef"
      :src="record.preview1080Url"
      :poster="record.coverPic"
      @click="togglePlayState"
      @timeupdate="handleTimeUpdate"
      @play="isPlay = true"
      @pause="isPlay = false"
      @loadedmetadata="handleLoadedMetadata"
    ></video>
    <div class="tool-bar" :disabled="disable ? '' : null">
      <svg-icon
        :size="24"
        :name="isPlay ? 'icon_edit_pause' : 'icon_edit_play'"
        @click="togglePlayState"
      ></svg-icon>
      <span class="time-text">{{ timeText }}</span>
      <div class="progress-bar flex-1" :ref="pointerMove.ref">
        <div class="progress">
          <div class="progress-dot"></div>
        </div>
      </div>
      <svg-icon
        v-if="!muted"
        :size="20"
        :name="isMuted ? 'icon_edit_mute' : 'icon_edit_unmute'"
        @click="toggleMute"
      ></svg-icon>
      <svg-icon
        :size="20"
        style="margin-left: 8px"
        name="icon_edit_fullscreen"
        @click="handleFullscreen"
      ></svg-icon>
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce } from '@/utils/debounce';
import { usePointerMove, type MoveEvent } from '@/utils/hook';

const props = defineProps({
  record: {
    type: Object as PropType<SpaceResData.MaterialLibDetailVO>,
    required: true,
  },
  muted: Boolean,
  ignoreDisabled: Boolean,
});

let progressAnimation = null as unknown as Animation;
const timeText = ref('0:00 / 0:00');
const isMuted = ref(true);
const isPlay = ref(false);
const videoRef = ref(null as unknown as HTMLVideoElement);
const disable = ref(true);
const pointerMove = usePointerMove({
  handler: handleProgress,
});

watch(
  () => isPlay.value && !disable.value,
  (value) => {
    if (value) {
      progressAnimation.play();
    } else {
      progressAnimation.pause();
    }
  }
);

function formatTime(time: number) {
  if (isNaN(time)) return '0:00';
  const min = Math.floor(time / 60).toString();
  const sec = Math.floor(time % 60).toString();
  return `${min}:${sec.padStart(2, '0')}`;
}

function updateProgress(duration: number, ratio: number) {
  if (progressAnimation) {
    progressAnimation.currentTime = duration * 1000 * ratio;
  }
  timeText.value = `${formatTime(duration * ratio)} / ${formatTime(duration)}`;
}

function handleTimeUpdate(e: Event) {
  const target = e.target as HTMLVideoElement;
  const ratio = target.currentTime / target.duration;

  timeText.value = `${formatTime(target.duration * ratio)} / ${formatTime(
    target.duration
  )}`;
}

function togglePlayState() {
  if (disable.value) return;

  videoRef.value.paused ? videoRef.value.play() : videoRef.value.pause();
}

function toggleMute() {
  isMuted.value = !isMuted.value;
  videoRef.value.muted = isMuted.value;
}

function handleFullscreen() {
  if (document.fullscreenElement === null) {
    videoRef.value.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
}

const updateVideo = debounce((ratio) => {
  videoRef.value.currentTime = ratio * videoRef.value.duration;
}, 100);

let playState = isPlay.value;
function handleProgress(e: MoveEvent) {
  const ratio = Math.max(
    0,
    Math.min(1, e.x / pointerMove.ref.value!.clientWidth)
  );

  if (e.state === 'start') {
    videoRef.value.pause();
    videoRef.value.currentTime = ratio * videoRef.value.duration;
    playState = isPlay.value;
  } else if (e.state === 'end') {
    playState && videoRef.value.play();
  } else {
    nextTick(() => updateVideo(ratio));
  }

  updateProgress(videoRef.value.duration, ratio);
}

function handleLoadedMetadata() {
  disable.value = false;
  if (pointerMove.ref.value) {
    progressAnimation = new Animation(
      new KeyframeEffect(
        pointerMove.ref.value!.querySelector('.progress'),
        [{ width: '0%' }, { width: '100%' }],
        {
          duration: videoRef.value.duration * 1000,
          iterations: 1,
          easing: 'linear',
          fill: 'forwards',
        }
      )
    );
    updateProgress(videoRef.value.duration, 0);
  }
}

watch(
  () => props.record,
  () => {
    if (!props.ignoreDisabled) {
      disable.value = true;
    }

    isPlay.value = false;
    updateProgress(0, 0);
  }
);

onBeforeUnmount(() => {
  if (progressAnimation) {
    progressAnimation.cancel();
  }
});
</script>

<style lang="scss" scoped>
.video-previewer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: min(66.666%, 1920px);
  background-color: #000000;
  aspect-ratio: 16 / 9;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.tool-bar {
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  bottom: -0.2px;
  width: 100%;
  height: 56px;
  padding: 16px 24px;
  color: #ffffff;
  font-size: 14px;
  background-color: rgba(21, 21, 21, 0.75);

  &[disabled] {
    cursor: not-allowed;

    & > * {
      pointer-events: none;
    }
  }

  & > svg {
    cursor: pointer;
  }
}

.time-text {
  min-width: 75px;
  text-align: justify;
}

.progress-bar {
  height: 4px;
  margin-inline: 7px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.progress {
  position: relative;
  width: 0;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
}

.progress-dot {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 0;
  top: 50%;
  transform: translate3d(50%, -50%, 0);
  border-radius: 50%;
  background-color: #ffffff;
}
</style>
