<template>
  <div class="image-enhancer-container">
    <div class="image-container">
      <div class="image-box" :style="boxStyle">
        <drag :isDrag="isDrag" :isRest="isRest">
          <context-menu :operations="operations">
            <img class="image" :src="currentUrl" alt="" @mousedown.prevent />
          </context-menu>
        </drag>
      </div>
    </div>
  </div>
  <!-- button -->
  <div class="button-container" v-if="mode == 'editor' && !processComplete">
    <primary-button
      :disabled="loading"
      :showDefaultIcon="false"
      padding="20px 65px"
      @click="handleProcess"
    >
      <template #preIcon>
        <svg-icon
          name="icon_magic"
          :style="{ width: '20.7px', height: '20.7px', color: '#fff' }"
        ></svg-icon>
      </template>
      <p
        class="text-base font-normal flex items-center justify-center leading-22"
        :style="{ marginLeft: '10px' }"
      >
        Enhancer
      </p>
    </primary-button>
  </div>
  <div class="tool-bar-box" :style="{ bottom: positionBottom }" v-else>
    <tool-bar
      class="tool-bar"
      :config="toolConfig"
      :disable="loading"
      @onScale="handleScale"
      @onCompare="handleCompare"
      @onReset="handleReset"
      @onDrag="handleDrag"
      @onSubmit="handleSubmit"
      @onApply="handleApply"
      :buttonText="buttonText"
      :loading="isSubmitting"
    />
  </div>
  <loading-mask v-if="loading" />
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRouter, useRoute } from "vue-router";
import ToolBar from "../../components/toolBar/index.vue";
import Drag from "../../components/drag/index.vue";
import LoadingMask from "../../components/loading.vue";
import ContextMenu from "../../components/contextMenu/index.vue";
import { getImageEnhanceResult } from "@/api/aiTools";
import {
  useSingleMessage,
  base64ToUrl,
  getImageType,
  urlToBlob,
  downloadFile,
  generateGID,
} from "@/utils";
import imageExample from "@/assets/images/imageexample.png";
import { fileUploadPre, fileUploadState, uploadFile } from "@/api";
import { createMenuItem, useProcessRequestHandler } from "../helper";
import { getBase64FromUrl } from "../../utils/index.js";
import cases from '../../assets/cases/image-enhancer';

const emit = defineEmits(["processComplete", "apply"]);
const props = defineProps({
  buttonText: String,
  positionBottom: {
    type: String,
    default: "41px",
  },
  originUrl: String,
  mode: String,
});

const Message = useSingleMessage();
const router = useRouter();
const route = useRoute();
const handleProcessRequest = useProcessRequestHandler();
const boxWidth = ref(90);
const originalBoxWidth = ref(90);
const boxStyle = computed(() => {
  return {
    height: boxWidth.value + "%",
    aspectRatio: 16 / 9,
  };
});
const toolConfig = {
  erase: false,
  restore: false,
  slider: false,
  reset: true,
  drag: true,
  scale: true,
  compare: true,
  step: false,
};
const toolName = "imageEnhancer";
const timer: Ref<any> = ref(null);
const loading = ref(false);
const isSubmitting = ref(false);
const isCompare = ref(false);
const isDrag = ref(false);
const isRest = ref(false);
const zoomRatio = ref(1);
const originUrl = ref(imageExample);
const resultUrl = ref("");
const isCancelTask = ref(false);
const usedCase = cases.find(item => item.input === route.query.url) ?? null;

const currentUrl = computed(() =>
  isCompare.value || !processComplete.value ? originUrl.value : resultUrl.value,
);
const processComplete = ref(false);
const operations = [
  {
    label: createMenuItem("icon_download", "Download"),
    handler: () => {
      downloadFile(currentUrl.value, `image-enhance-${generateGID()}`);
    },
  },
];

const handleScale = (value: number) => {
  isRest.value = false;
  boxWidth.value = originalBoxWidth.value * value;
  zoomRatio.value = value;
  console.log(zoomRatio.value);
};

const handleCompare = (value: boolean) => {
  isCompare.value = value;
};

const handleReset = () => {
  isRest.value = true;
  boxWidth.value = originalBoxWidth.value;
  zoomRatio.value = 1;
  setTimeout(() => {
    isRest.value = false;
  }, 200)
};

const handleDrag = (value: boolean) => {
  isDrag.value = value;
};

const handleToSpace = () => {
  const href = router.resolve({
    path: "/space",
    query: {
      tab: "media",
    },
  });
  window.open(href.href, "_blank");
};

const getSuffix = (type: string) => {
  const typeArr = type.split("/");
  if (typeArr[typeArr.length - 1]) {
    return "." + typeArr[typeArr.length - 1];
  }
  return ".png";
};

const uploadMaterials = async (url: string) => {
  const filename = `image-enhancer-${Date.now()}`;
  const blob = await urlToBlob(url);
  const file = new File([blob], filename);
  const uploadParams = {
    suffix: getSuffix(file.type),
    size: file.size,
    type: getImageType(file.type),
    draftId: (route.query?.draftId as string) || 0,
    toolName,
  };

  const preResult = await fileUploadPre(uploadParams);
  if (preResult.code !== 0) {
    throw new Error("error, save failed");
  }
  const { preSignUrl, mid } = preResult.data;
  const uploadResult = await uploadFile(preSignUrl, file);
  if (uploadResult.code !== 0) {
    throw new Error("error, save failed");
  }
  return { mid };
};

// 保存结果
const handleSubmit = async () => {
  isSubmitting.value = true;
  try {
    const { mid } = await uploadMaterials(resultUrl.value);
    await getUploadState(mid);
  } catch (err: any) {
    Message.error(err.message, { duration: 2000 });
  }
};

const getUploadState = async (id: string) => {
  const res = await fileUploadState(id);
  const { data } = res;
  const { preview480Url, preview1080Url, width480, width1080, state, init } = data;

  if (props.mode === "editor") {
    if (state === 0) {
      // 成功
      emit("apply", preview480Url, preview1080Url, width480, width1080);
      isSubmitting.value = false;
      clearTimeout(timer.value);
      timer.value = null;
      return;
    } else if (state === 2) {
      timer.value = setTimeout(async () => {
        await getUploadState(id);
      }, 2000);
    }
  } else {
    if (!init) {
      handleToSpace();
      isSubmitting.value = false;
      clearTimeout(timer.value);
      timer.value = null;
      return;
    } else {
      timer.value = setTimeout(async () => {
        await getUploadState(id);
      }, 2000);
    }
  }
};

const handleApply = async () => {
  isSubmitting.value = true;
  try {
    const { mid } = await uploadMaterials(resultUrl.value);
    await getUploadState(mid);
  } catch (err: any) {
    Message.error(err.message, { duration: 2000 });
  }
};

const handleProcess = async () => {
  await setupImage();
};

const handleClose = () => {
  isCancelTask.value = true;
  loading.value = false;
};

const setupImage = async () => {
  loading.value = true;
  Message.loading("Processing, it will take a while", {
    duration: 0,
    onClose: handleClose,
  });

  if (usedCase === null) {
    // const originBase64 = splitBase64(await urlToBase64(originUrl.value) as string);
    const result = await handleProcessRequest(getImageEnhanceResult, {
      imgUrl: originUrl.value,
    }).catch(() => {});
  
    loading.value = false;
    if (result === undefined) return;
    if (isCancelTask.value) {
      return;
    }
    resultUrl.value = base64ToUrl(result.imgBase64);
  } else {
    resultUrl.value = await getBase64FromUrl(usedCase.output);
  }

  Message.close();
  processComplete.value = true;
  emit("processComplete", resultUrl.value);
};

onMounted(() => {
  if (props.mode === "editor") {
    originUrl.value = props.originUrl;
  } else {
    originUrl.value = route.query?.url;
    setupImage();
  }
});

onBeforeUnmount(() => {
  if (timer.value) {
    clearTimeout(timer.value);
    timer.value = null;
  }
});
</script>

<style scoped lang="scss">
.image-enhancer-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-container {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  height: 100%;
}

.button-container {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 36px;
}

.button-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.tool-bar-box {
  width: 100%;
  height: 114px;
  position: absolute;
}

.tool-bar {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
