<script setup>
import { render } from "vue";
import TrackList from "./tracklist.vue";
import TimelineAxis from "./timelineaxis.vue";
import TimelineCursor from "./timelinecursor.vue";
import TimelineTools from "./timelinetools.vue";
import Selection from "./selection.vue";
import ImageSegment from "../segments/imagesegment.vue";
import VideoSegment from "../segments/videosegment.vue";
import AudioSegment from "../segments/audiosegment.vue";
import SpeechSegment from "../segments/speechsegment.vue";
import TextSegment from "../segments/textsegment.vue";
import StickerSegment from "../segments/stickersegment.vue";
import FilterSegment from "../segments/filtersegment.vue";
import GraphicSegment from "../segments/graphicsegment.vue";
import EffectSegment from "../segments/effectsegment.vue";
import TransitionSegment from "../segments/transitionsegment.vue";
import { useCreatorStore, useDraftStore, useHistoryStore, useDrag } from "../../stores";
import { clamp } from "../../utils";

const {
  tracks,
  activeNodeMap,
  empty,
  timeline,
  currentFrame,
  totalFrame,
  seekTo,
  frameToWidth,
  widthToFrame,
  secondToFrame,
  removeActiveNodes,
} = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();
const instance = getCurrentInstance();

const timelineRef = ref(null);
const scrollRef = ref(null);
const wrapper = ref(null);
const axisX = ref(10);
const cursorX = ref(0);

const refLineLeft = ref(0);
const refLineRight = ref(0);
const refLineY = ref(0);
const showRefLineLeft = ref(false);
const showRefLineRight = ref(false);
const showRefLineY = ref(false);

const selectStart = ref(false);
const selectEnd = ref(false);
const event = ref();

const refLineLeftStyle = computed(() => ({
  left: `${refLineLeft.value}px`,
}));
const refLineRightStyle = computed(() => ({
  left: `${refLineRight.value}px`,
}));
const refLineYStyle = computed(() => ({
  top: `${refLineY.value}px`,
}));
const maxLeft = computed(() => frameToWidth(totalFrame.value));

const segmentMap = {
  image: ImageSegment,
  video: VideoSegment,
  audio: AudioSegment,
  speech: SpeechSegment,
  text: TextSegment,
  sticker: StickerSegment,
  filter: FilterSegment,
  graphic: GraphicSegment,
  effect: EffectSegment,
  transition: TransitionSegment,
};

watch(tracks, (newTracks, oldTrakcs) => {
  if (newTracks.length > 0 && oldTrakcs.length === 0) {
    const element = scrollRef.value.wrapRef;
    const bounding = element.getBoundingClientRect();
    element.scrollTop = element.scrollHeight - bounding.height;
  }
}, { flush: "post" });
watch(
  () => timeline.frameWidth,
  (newStep, oldStep) => {
    computeScrollBar(oldStep, newStep);
  },
  { flush: "post" },
);
watch([currentFrame, () => timeline.frameWidth], ([newCurrentFrame]) => {
  cursorX.value = frameToWidth(newCurrentFrame);
});

function handleMouseDown(e) {
  const bounding = wrapper.value.getBoundingClientRect();

  selectStart.value = true;
  selectEnd.value = false;
  event.value = {
    pageX: e.pageX,
    pageY: e.pageY,
    x: e.pageX - bounding.x,
    y: e.pageY - bounding.y,
  };
}

function scroll({ scrollLeft }) {
  axisX.value = 10 - scrollLeft;
}

function handleSelectEnd() {
  selectEnd.value = true;
}

function handleClick(e) {
  if (selectEnd.value) {
    return;
  }
  const bounding = wrapper.value.getBoundingClientRect();
  const frame = widthToFrame(e.pageX - bounding.x);
  seekTo(frame);
}

function handleEnterContainer(e) {
  if (
    dragData.target === null ||
    dragData.entered
  )
    return;
  dragData.entered = true;
  activeNodeMap.clear();

  const trackList = document.querySelector(".track-list");
  const bounding = trackList.getBoundingClientRect();
  const x = e.pageX - bounding.x;
  const y = e.pageY - bounding.y;
  const container = document.createElement("div");
  const { type, duration = 3, ...restConfig } = dragData.meta;
  const segment = segmentMap[type];
  const start = widthToFrame(x);
  const end = start + secondToFrame(duration);
  const conf = { ...dragData.meta, start, end, active: true };
  const node = { type, conf, id: "dragTarget", isPrepared: true, getZIndex: () => 0 };
  const newProps = { node, start, end, y, class: "active" };

  if (type === "image") newProps.src = restConfig.src;
  if (type === "video") newProps.coverPic = restConfig.coverPic;
  const vnode = h(segment, newProps);

  vnode.appContext = {
    ...instance.appContext,
    provides: { ...instance.appContext.provides, ...instance.provides },
  };
  dragData.dragNode = dragData.target;
  dragData.target = container;
  render(vnode, container);

  const element = container.firstElementChild;
  element.style.height = `${["image", "video", "transition"].includes(type) ? 56 : 26}px`;
  trackList.appendChild(element);
  element.dispatchEvent(new MouseEvent("mousedown", e));
}

function handleLeaveContainer() {
  dragData.entered = false;
}

function cursorDrag(left) {
  const currentTime = widthToFrame(left);
  seekTo(currentTime);
}

function remove() {
  removeActiveNodes();
  commit();
  updateDraft();
}

function computeScrollBar(oldStep, newStep) {
  if (!oldStep || !newStep) return;
  const scrollElement = scrollRef.value.wrapRef;
  const newCursorX = cursorX.value;
  const oldCursorX = cursorX.value * (oldStep / newStep);
  scrollElement.scrollLeft += newCursorX - oldCursorX;
}

function handleWheel(e) {
  if (e.ctrlKey) {
    timeline.scale = clamp(timeline.scale - timeline.scale * e.deltaY * 0.01, timeline.minScale, timeline.maxScale);
    timeline.autoFit = false;
  }
}

provide("timeline", timelineRef);
provide("scrollRef", scrollRef);
provide("wrapper", wrapper);
provide("refLineLeft", refLineLeft);
provide("refLineRight", refLineRight);
provide("refLineY", refLineY);
provide("showRefLineLeft", showRefLineLeft);
provide("showRefLineRight", showRefLineRight);
provide("showRefLineY", showRefLineY);
</script>

<template>
  <div class="timeline-container" tabindex="-1" @keydown.delete.stop="remove">
    <TimelineTools />
    <div
      class="timeline"
      ref="timelineRef"
      @wheel="handleWheel"
      @click="handleClick"
      @mousedown="handleMouseDown"
      @mouseenter="handleEnterContainer"
      @mouseleave="handleLeaveContainer"
    >
      <el-scrollbar ref="scrollRef" @scroll="scroll">
        <div ref="wrapper" class="track-list-wrapper">
          <TrackList />
          <TimelineCursor
            v-show="!empty"
            v-model:x="cursorX"
            :maxLeft="maxLeft"
            @dragging="cursorDrag"
          />
          <Selection v-model="selectStart" :event="event" @selectend="handleSelectEnd"  />
          <div
            v-show="showRefLineLeft"
            class="ref-line-x"
            :style="refLineLeftStyle"
          ></div>
          <div
            v-show="showRefLineRight"
            class="ref-line-x"
            :style="refLineRightStyle"
          ></div>
          <div
            v-show="showRefLineY"
            class="ref-line-y"
            :style="refLineYStyle"
          ></div>
        </div>
      </el-scrollbar>
      <TimelineAxis :x="axisX" />
    </div>
  </div>
</template>
<style scoped>
:deep(.el-scrollbar__view) {
  width: 100%;
  height: 100%;
  position: relative;
}
:deep(.el-scrollbar__bar) {
  z-index: 7;
}
.timeline-container {
  width: 100%;
  height: 100%;
  outline: none;
}
.timeline {
  width: 100%;
  height: calc(100% - 48px);
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
}
.track-list-wrapper {
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 10px;
  display: flex;
  align-items: stretch;
}
.ref-line-x {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  background-color: #875eff;
  z-index: 9;
}
.ref-line-y {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #875eff;
  z-index: 5;
}
</style>
