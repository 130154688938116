<template>
  <section class="challenge-part">
    <h2>
      {{ info.title }}
    </h2>
    <article>
      <v-render :vnode="info.content" />
    </article>
  </section>
</template>

<script setup lang="ts">
import { useData } from './data';

const info = useData()!;
</script>

<style scoped lang="scss">
.challenge-part {
  padding: 120px calc((100% - var(--view-width)) / 2) 0;

  & > h2 {
    font-size: 48px;
    text-align: center;
    font-weight: 700;
    white-space: pre-line;
  }

  & > article {
    padding: 70px 105px;
    margin-top: 100px;
    border-radius: 18px;
    font-weight: 16px;
    background-color: #F6FAFF;

    & > :deep(b) {
      font-weight: 600;
    }
  }
}
</style>