<template>
  <div 
    class="scene-header"
  >
    <div class="left">
      <div class="title">Scene {{ sequence }}</div>
    </div>
    <div 
      class="right"
      :class="{ disabled: !canHeader }"
    >
      <bv-tip 
        v-if="globalDomain === 1"
        content="Voice replace" 
        :showAfter="0"
      >
        <div 
          class="voice" 
          @click="$emit('voiceClick')"
        >
          <svg-icon
            name="script_voice"
            :size="18"
          />
          <span>{{ voiceName }}</span>
        </div>
      </bv-tip>
      <div class="play">
        <svg-icon
          name="editor_play"
          :size="24"
          @click="playVideo(scene)"
        />
      </div>
      <span class="duration">
        {{ duration }}
      </span>
    </div>
  </div>
  
</template>

<script setup>
import { useScriptStore } from "../../stores/script";
import { observeNode } from "../../utils/observe";
import { frameToHms, extractVoiceName } from "../../utils";

defineEmits(["voiceClick"]);
const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
  index: {
    type: Number,
    default: 0,
  },
  node: {
    type: Object,
  },
});

const { 
  loadingScene,
  defaultVoiceName,
  seekTo,
  play,
  setScenePlaying,
} = useScriptStore();
const globalDomain = inject('globalDomain');
const currentPlay = inject("currentPlay");
const canHeader = computed(() => {
  return (loadingScene.value != props.scene) && props.node;
});
const sequence = computed(() => 
  props.index + 1
);
const voiceName = computed(() => {
  if (!props.node) return extractVoiceName(defaultVoiceName.value);
  const { voiceName } = props.node.conf;
  return extractVoiceName(voiceName);
});
const duration = computed(() => {
  const { start, end } = props.scene;
  const newDuration = frameToHms(end - start);
  return newDuration;
});

const playVideo = async (scene) => {
  currentPlay.value = scene;
  await seekTo(scene.start);
  await play();
  setScenePlaying(true);
};

const setup = () => {
  if (props.node) {
    observeNode(props.node);
  }
};

onMounted(setup);
</script>

<style lang="scss" scoped>
.scene-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646A73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
  margin-bottom: 30px;

  .right {
    display: flex;
    align-items: center;
  }

  .right.disabled {
    pointer-events: none;
    color: #BBBFC4;

    & svg {
      color: #BBBFC4;
    }
  }
}

.voice { 
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 16px;

  &:hover {
    color: #1C1B1E;
  }

  & > svg {
    margin-right: 4px;
  }
  
  & > span {
    max-width: 60px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
  }
}

.play {
  cursor: pointer;
  margin-right: 12px;

  &:hover {
    color: #1C1B1E;
  }
}
</style>