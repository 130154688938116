import loadImage from "blueimp-load-image";

export const createCanvasCtx = (size, id) => {
  let tempCtx = null;
  var tempCanvas = null;

  if (id) {
    tempCanvas = document.getElementById(id);
    if (!tempCanvas) {
      return;
    }

    tempCanvas.width = size?.width || 0;
    tempCanvas.height = size?.height || 0;
    tempCtx = tempCanvas.getContext("2d");
  } else {
    tempCanvas = document.createElement("canvas");
    tempCanvas.width = size?.width || 0;
    tempCanvas.height = size?.height || 0;
    tempCtx = tempCanvas.getContext("2d");
  }
  return tempCtx;
};

export const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const base64ToArrayBuffer = (base64) => {
  base64 = base64.replace(/^data\:([^\;]+)\;base64,/gim, "");
  var binary = atob(base64);
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return buffer;
};

export const imageToImageData = (url) => {
  if (!url) {
    return;
  }
  return new Promise((resolve, reject) => {
    loadImage(
      url,
      async (img) => {
        if (img.type === "error") {
          console.log("error");
          reject();
        } else {
          const tempCtx = createCanvasCtx(img);
          tempCtx.drawImage(img, 0, 0);
          let imageData = await tempCtx.getImageData(
            0,
            0,
            img.width,
            img.height,
          );
          resolve(imageData);
        }
      },
      {
        cover: true,
        crop: true,
        canvas: true,
        crossOrigin: "Anonymous",
      },
    );
  });
};

export const checkMediaType = (url) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const videoExtensions = ["mp4", "mov", "avi", "wmv"];

  const extension = url.split(".").pop().toLowerCase();

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
};

// canvas 生成图片
export const getCanvasFromImage = (imageData) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  ctx.putImageData(imageData, 0, 0);
  return canvas;
};

const convertCanvasToImage = (canvas) => {
  var image = new Image();
  image.src = canvas.toDataURL("image/png");
  return image;
};

export const downloadFn = (dom, name) => {
  const image = convertCanvasToImage(dom);
  var a = document.createElement("a");
  a.href = image.src;
  a.download = name || "remover-background.png";
  a.click();
  window.URL.revokeObjectURL(image.src);
};

export const getBase64FromUrl = (() => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return async (url) => {
    const img = new Image();
    img.src = url;

    await new Promise(res => img.onload = res);
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL();
  }
})();
