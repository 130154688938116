<script setup>
import { useCreatorStore, useDraftStore, useHistoryStore, ratioMap } from "../../stores";
import { useTrackStore } from "@/store/modules/track";

const { collectData, clearEventData, track } = useTrackStore();
const { resize } = useCreatorStore();
const { ratio, setRatio, updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

const options = [
  {
    label: "1:1",
    value: "1:1",
  },
  {
    label: "9:16",
    value: "9:16",
  },
  {
    label: "16:9",
    value: "16:9",
  },
  {
    label: "4:5",
    value: "4:5",
  },
  {
    label: "2:3",
    value: "2:3",
  },
];

const map = Object.fromEntries([...ratioMap.entries()].map((entry) => entry.reverse()));

function sizeChange(value) {
  const trackHelper = () => {
    collectData("boolvideo_export_click", {
      ratio: value.replace(":", "_"),
    });
    collectData("boolvideo_timeline_edit_click", {
      click: "resize",
      resize_ratio: value.replace(":", "_"),
    });
    track("boolvideo_timeline_edit_click");
    clearEventData("boolvideo_timeline_edit_click");
  };
  if (value === ratio.value) return;

  const [width, height] = map[value].split("*");

  setRatio(value);
  resize(parseInt(width), parseInt(height));
  updateDraft();
  commit();
  trackHelper();
}
</script>

<template>
  <div class="controls">
    <bv-select
      placement="bottom-end"
      :popperStyle="{ width: '126px' }"
      :showBorder="false"
      :options="options"
      :model-value="ratio"
      @change="sizeChange"
    >
      <template #suffix>
        <svg-icon name="editor_arrow_down" :size="24" />
      </template>
    </bv-select>
  </div>
</template>

<style scoped>
.controls {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 16px;
}

:deep(.bv-select-button) {
  width: 66px;
  height: 32px;
  border-radius: 4px;
  z-index: 1;
}

:deep(.bv-select-button .select-wapper) {
  padding: 0 4px 0 6px;
}

:deep(.bv-select-button .select-wapper.is-focus),
:deep(.bv-select-button .select-wapper:hover) {
  background-color: #e8e9ec;
}

:deep(.select-value) {
  color: #000;
  font-size: 14px;
  line-height: 22px;
}
</style>
