<template>
  <div class="five-part-container" id="reviews">
    <div class="five-part">
      <h2 class="primary-title text-42px">Reviews</h2>
      <p class="sub-title">
        A review's worth a thousand gifts:
        <span class="link" @click="handleOpenLink"
          >https://page.famewall.io/boolvideo</span
        >
      </p>
    </div>
    <div
      class="famewall-embed"
      data-src="boolvideo"
      data-format="grid"
      style="width: 100%"
    ></div>
  </div>
</template>

<script setup lang="ts">
const embedScript = document.createElement("script");

embedScript.src = "https://embed.famewall.io/frame.js";
document.body.appendChild(embedScript);

const handleOpenLink = () => {
  window.open("https://page.famewall.io/boolvideo");
};
</script>

<style lang="scss">
.five-part-container {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 161px;

  .famewall-embed {
    width: 100%;
    min-height: 300px;
    max-width: 1055px;
  }

  .primary-title {
    color: #000;
    font-family: Inter-variant;
    font-style: normal;
    font-weight: 700;
    line-height: 15.368px;
  }

  .sub-title {
    margin-top: 54px;
    margin-bottom: 10px;
    color: #000;
    font-family: Inter-variant;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 211%;
  }

  .link {
    color: #6741ff;
    cursor: pointer;
  }

  .five-part {
    width: 100%;
    max-width: 1024px;
  }
}
</style>
