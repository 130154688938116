<template>
  <div
    class="p-[34px] generate-template h-full"
    id="generate-template"
    v-infinite-scroll="handleGetList"
    height="100vh"
    :infinite-scroll-immediate="false"
    :infinite-scroll-disabled="disabled"
    :infinite-scroll-distance="500"
  >
    <p class="text-titleColor text-[46px] text-center pt-5 font-semibold">
      Start with stunning templates
    </p>
    <div class="flex justify-center mt-68 mb-12">
      <el-select
        v-model="aspectRatio"
        class="w-208 mr-[18px]"
        popper-class="custom-select-container"
        :popper-append-to-body="false"
        :suffix-icon="SelectSuffix"
        placeholder="select aspectRatio"
        @change="handleSelectAspectRatio"
      >
        <el-option
          :value="item.value"
          v-for="item in aspectRatioOptions"
          :key="item.value"
          v-track:click="'boolvidero_template_ratio_click'"
        >
          <div class="h-full flex items-center justify-between text-titleColor">
            <p>{{ item.label }}</p>
            <svg-icon
              name="icon_right"
              :style="{ width: '16px', height: '16px', color: '#875EFF' }"
              v-if="item.value == aspectRatio"
            ></svg-icon>
          </div>
        </el-option>
      </el-select>

      <el-select
        v-model="tags"
        class="w-208"
        popper-class="custom-select-container"
        :popper-append-to-body="false"
        multiple
        clearable
        placeholder="All Tag"
        @change="handleSelectTag"
        :suffix-icon="SelectSuffix"
        :clear-icon="ClearIcon"
      >
        <el-option
          :value="item.value"
          v-for="item in tagsOptions"
          :key="item.value"
        >
          <div
            class="h-full flex items-center justify-between label-content text-titleColor"
          >
            <p>{{ item.label }}</p>
            <svg-icon
              name="icon_right"
              :style="{ width: '16px', height: '16px', color: '#875EFF' }"
              v-if="
                tags.includes(item.value) ||
                tags.includes(item.value + ',') ||
                tags.includes(item.value.replace(',', ''))
              "
            ></svg-icon>
          </div>
        </el-option>
      </el-select>
    </div>

    <div class="tags-container" v-if="!tags || !tags.length">
      <p class="leading-3" :style="{ opacity: 0 }">All Tags</p>
      <bv-tag
        bgColor="#875eff"
        :text="item.name"
        v-for="item in tagsList"
        :key="item.tagId"
        @click="handleTagClick(item)"
        :active="selectedTagIds.includes(item.tagId)"
        class="cursor-pointer mb-6"
      />
    </div>

    <div class="tags-container" v-for="item in tagsList" v-else>
      <p class="tag-title">{{ item.type }}</p>
      <bv-tag
        bgColor="#875eff"
        :text="subItem.name"
        v-for="subItem in item.tags"
        :key="item.tagId"
        @click="handleTagClick(subItem)"
        :active="selectedTagIds.includes(subItem.tagId)"
        class="cursor-pointer mb-6"
      />
    </div>

    <div
      class="empty-box flex justify-center items-center"
      v-if="!templateList.length && !loading"
    >
      <svg-icon
        name="icon_empty"
        style="color: #bbbfc4; height: 200px"
      ></svg-icon>
    </div>

    <div
      class="sketch-container"
      v-else-if="(!templateList || !templateList.length) && loading"
    >
      <div
        class="flex justify-center video-item items-center bg-loadingColor relative w-full h-full"
        v-for="item in skeletonList"
        :style="{
          'aspect-ratio': aspectRatio == '9:16 ( Portrait )' ? '9/16' : '1/1',
        }"
      >
        <svg-icon
          name="icon_loading"
          :style="{ width: '30px', height: '30px', color: ' #BBBFC4' }"
          class="absolute left-1/2 top-1/2 -ml-5 -mt-5 cursor-pointer animate-spin"
        ></svg-icon>
      </div>
    </div>

    <div class="videos-container" id="videos-container">
      <videoItem
        :item="item"
        v-for="item in templateList"
        :key="item.templateId"
        :src="item.previewUrl"
        class="video-item"
        @onplay="handlePlay"
        @onPause="handlePause"
        @onIsMute="handleIsMute"
        :isAllMute="isAllMute"
        :aspectRatio="aspectRatio"
        :style="{
          'aspect-ratio': aspectRatio == '9:16 ( Portrait )' ? '9/16' : '1/1',
        }"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import { BvTag } from "@/components/common/index";
import videoItem from "./components/videoItem.vue";
import SelectSuffix from "@/components/common/selectSuffix.vue";
import ClearIcon from "./components/clearIcon.vue";
import { useSingleMessage } from "@/utils";
import { getTemplateList, getTemplateTagList } from "@/api";
import { useTrackStore } from "@/store/modules/track";

const route = useRoute();
const router = useRouter();
type TagItem = {
  tagId: Number;
  name: string;
};

type Tag = {
  name: string;
  tagId: number;
};

type TagListOption = {
  tags: Tag[];
  type: string;
};

type TagOption = {
  value: string;
  label: string;
};

type Template = {
  aspectRatio: string;
  previewImageIndex: number;
  previewUrl: string;
  templateId: string;
  isPlaying?: boolean;
  isReplay?: boolean;
};

const pagination = reactive({
  page: 1,
  size: 24,
});

const { collectData, track } = useTrackStore();

const Message = useSingleMessage();
const tags = ref(["Theme,", "Genre,"]);
const loading = ref(false);
const noMore = ref(false);
const aspectRatio = ref("9:16 ( Portrait )");
const isAllMute = ref(false);

const templateList = ref<Template[]>([]);
const allTags = ref<TagListOption[]>([]);
const allTagsList = ref<Tag[]>([]);
const selectedTagIds = ref([]);
const tagsList = ref<any[]>([]);
const newTags = ref<string[]>([]);
const skeletonList = ref([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
const disabled = computed(() => loading.value || noMore.value);
const lastPlayItemId = ref("");
const aspectRatioOptions = ref([
  {
    value: "9:16 ( Portrait )",
    label: "9:16 ( Portrait )",
  },
  {
    value: "1:1 ( Square )",
    label: "1:1 ( Square )",
  },
]);

const tagsOptions = ref<TagOption[]>([]);

// 选择tag
const handleTagClick = (item: TagItem) => {
  const idx = selectedTagIds.value.findIndex((id) => id === item.tagId);

  if (idx >= 0) {
    selectedTagIds.value.splice(idx, 1);
  } else {
    collectData("boolvideo_template_tag_click", {
      tag_name: item.name,
    });
    track("boolvideo_template_tag_click");
    selectedTagIds.value.push(item.tagId);
  }
};

// 获取tag列表
const getTags = async () => {
  try {
    const { data } = await getTemplateTagList();
    allTags.value = data;
    const tagTypeList = data.map((item: TagListOption) => {
      return {
        value: item.type + ",",
        label: item.type,
      };
    });

    tagsOptions.value = [...tagsOptions.value, ...tagTypeList];
  } catch (err) {
    console.log("err");
  }
};

const handleGetList = () => {
  pagination.page += 1;
  getList();
};

// 获取模版列表
const getList = async () => {
  loading.value = true;
  const params: TemplateVideoEditReqParams.TemplateListParams = {
    page: pagination.page,
    size: pagination.size,
    ratio: aspectRatio.value == "9:16 ( Portrait )" ? "9:16" : "1:1",
    tagIds: selectedTagIds.value,
  };

  try {
    const { data } = await getTemplateList(params);

    if (!data.records.length) {
      noMore.value = true;
    } else {
      noMore.value = false;
    }

    let list = data.records.map((item) => {
      return {
        ...item,
        cover:
          item.coverPic ||
          "https://p16-capcut-sign-sg.ibyteimg.com/tos-alisg-v-643f9f/oAgfZXB7ELWIDQA4otCwTIamyHASjzFbaAhwBA~tplv-4d650qgzx3-1:500:0.webp?x-expires=1724902749&x-signature=Wc0mpa4%2BN4nhPOnewBOr6LE85ls%3D",
      };
    });

    templateList.value = [...templateList.value, ...list] as Template[];
    pagination.page = data.current;
    loading.value = false;
  } catch (err) {
    loading.value = false;
    Message.error("get template list failed", { duration: 1000 });
  }
};

const handleSelectTag = () => {
  generateTags();
  getTagList();

  if (selectedTagIds.value && selectedTagIds.value.length) {
    selectedTagIds.value.forEach((id, index) => {
      const idx = allTagsList.value.findIndex((tag) => tag.tagId === id);
      // 不存在即从选择中删除
      if (idx < 0) {
        selectedTagIds.value.splice(index, 1);
      }
    });
  }
};

const handleSelectAspectRatio = () => {
  collectData("boolvidero_template_ratio_click", {
    ratio: aspectRatio.value == "9:16 ( Portrait )" ? "9_16" : "1_1",
  });
  templateList.value = [];
  getList();
};

watch(
  () => selectedTagIds.value,
  () => {
    pagination.page = 1;
    templateList.value = [];
    getList();
  },
  {
    deep: true,
  },
);

const generateTags = () => {
  let tag = tags.value[tags.value.length - 1];

  tagsOptions.value.forEach((item) => {
    if (item.value == tag) {
      item.value = item.value?.replace(",", "");
    } else {
      item.value = item.value.includes(",") ? item.value : item.value + ",";
    }
  });

  tags.value.forEach((item, index) => {
    if (tags.value.length - 1 == index) {
      tags.value[tags.value.length - 1] = item.includes(",")
        ? item?.replace(",", "")
        : item;
    } else {
      tags.value[index] = item.includes(",") ? item : item + ",";
    }
  });
};

const getTagList = () => {
  allTagsList.value = [];
  let currentTags: any[] = [];

  // 全部tag
  if (!tags.value || !tags.value.length) {
    allTags.value.forEach((item) => {
      currentTags = [...currentTags, ...item.tags];
      allTagsList.value = [...currentTags, ...item.tags];
    });
  } else {
    allTags.value.forEach((item) => {
      const value = item.type;

      let idx = tags.value.findIndex((tag) => {
        return (
          value == tag || value == tag + "," || value == tag.replace(",", "")
        );
      });

      if (idx >= 0) {
        currentTags.push(item);
        allTagsList.value = [...allTagsList.value, ...item.tags];
      }
    });
  }

  tagsList.value = currentTags;
};

// 监听tag 改变 tagList
watch(
  () => [tags.value, allTags.value],
  () => {
    if (!allTags.value || !allTags.value.length) {
      return;
    }
    pagination.page = 1;
    getTagList();
    generateTags();
  },
  {
    immediate: true,
    deep: true,
  },
);

const handlePlay = (item: Template) => {
  templateList.value.forEach((template) => {
    if (template.templateId === item.templateId) {
      template.isPlaying = true;
    } else {
      if (lastPlayItemId.value == item.templateId) {
        template.isReplay = false;
      } else {
        template.isReplay = true;
      }

      template.isPlaying = false;
    }
  });
  lastPlayItemId.value = item.templateId;
};

const handlePause = (item: Template) => {
  templateList.value.forEach((template) => {
    if (template.templateId === item.templateId) {
      template.isPlaying = false;

      if (lastPlayItemId.value == item.templateId) {
        template.isReplay = false;
      } else {
        template.isReplay = true;
      }
    }
  });
};

const handleIsMute = (isMute: boolean) => {
  isAllMute.value = isMute;
};

onMounted(async () => {
  document.title = route.meta.title!;
  await getTags();
  getList();
});
</script>

<style lang="scss" scoped>
.videos-container {
  width: 100%;
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 108px;
  grid-column-gap: 37px;
  padding-bottom: 120px;
}

.sketch-container {
  width: 100%;
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-row-gap: 108px;
  grid-column-gap: 36px;
}

// 隐藏全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none;
  z-index: -1;
}

.custom-select-container {
  .el-select__popper.el-zoom-in-top-enter-active,
  .el-select__popper.el-zoom-in-top-leave-active {
    display: block !important;
    transform: none !important;
    background-color: red !important;
  }

  .el-select-dropdown__item {
    font-family: Inter-variant;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .el-select-dropdown__item:hover {
    background: rgba(31, 35, 41, 0.08);
  }
}

.tag-title {
  color: #000;
  font-family: Inter-variant;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 16px;
}

.empty-box {
  height: calc(100vh - 450px);
}
</style>

<style lang="scss">
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  position: relative;
}

.custom-select-container .el-popper__arrow {
  display: none;
}

.generate-template {
  .el-select__tags {
    .el-select-tags-wrapper {
      width: 100%;
      display: flex;
    }

    .el-select__tags-text {
      font-size: 14px;
      color: #060606;
      background-color: #fff;
    }

    .el-tag {
      background-color: #fff !important;
      display: flex;
      align-items: center;
      margin-right: 0;
      padding-left: 0px;
    }

    .el-tag__content {
      line-height: 17px;
    }
  }

  .el-select__tags .el-tag__close {
    display: none;
  }

  .el-input__wrapper {
    padding: 4px 12px;

    .el-input__inner {
      height: 34px;
      font-size: 14px;
      color: #060606;
      padding-left: 0px;

      &::placeholder {
        color: #060606;
      }
    }

    .el-input__suffix,
    .el-input__suffix-inner,
    .el-icon {
      width: 22px;
      height: 22px;
    }

    .el-tag__content {
      line-height: 22px;
    }
  }
}
</style>

<style lang="scss">
.custom-select-container.el-zoom-in-top-enter-active,
.custom-select-container.el-zoom-in-top-leave-active {
  transform: none !important;
}
</style>
