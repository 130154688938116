import { _post } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

interface RedemptionFormReqParams {
  activeEmail: string;
  activationCode: string;
}

interface RedemptionFormResData {
  packageCode: number;
}

export function submitRedemptionForm(data: RedemptionFormReqParams) {
  return _post<RedemptionFormResData>(
    `${VITE_API_BASE}/subscribe/v1/activation_code/active`,
    data,
  );
}
