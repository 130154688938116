<template>
  <div class="processing-bar">
    <div class="progress loop" :style="{ width: `${percent}%` }"></div>
  </div>
</template>
<script setup>
const props = defineProps({
  isCompleted: Boolean,
});

const percent = ref(1);
const timer = ref(null);

watch(
  () => props.isCompleted,
  () => {
    if (props.isCompleted) {
      percent.value = 100
      clearInterval(timer.value);
      timer.value = null;
    }
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  timer.value = setInterval(() => {
    if(percent.value >= 98) {
      return
    }
    percent.value += 1;
  }, 200);
});

onUnmounted(() => {
  clearInterval(timer.value);
  timer.value = null;
});

</script>
<style lang="scss">
.processing-bar {
  width: 80%;
  height: 4px;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

.progress {
  height: 100%;
  background-color: #ffffff;
  transition: width 0.2s;
}
</style>
