<template>
  <bv-context-menu
    class="flex"
    ref="contextMenuRef"
    :options="operations"
    :width="185"
    @click="handleCommand"
  >
    <slot></slot>
  </bv-context-menu>
</template>

<script setup lang="ts">
import { createRef } from "@/utils/type";
import bvContextMenu from "@/components/common/bv-contextmenu/index.vue";
import type { PanelOperation } from "./type";

defineProps({
  operations: {
    type: Array as PropType<PanelOperation[]>,
    required: true,
  },
});

const contextMenuRef = ref(
  null as unknown as InstanceType<typeof bvContextMenu>,
);
const menuState = createRef(contextMenuRef, "value");
const emits = defineEmits(["visiable"]);

function updatePosition() {
  const clientRect = menuState.el.getBoundingClientRect();
  const rootRect = menuState.el.offsetParent!.getBoundingClientRect();

  menuState.top += 4;
  if (rootRect.bottom < clientRect.bottom + 4) {
    menuState.top = clientRect.top - clientRect.height - 4;
  }

  if (rootRect.right < clientRect.right + 4) {
    menuState.left = clientRect.left - clientRect.width - 4;
  }
}

function handleCommand(command: PanelOperation) {
  command.handler();
}

function handleResize() {
  menuState.visible = false;
}

onMounted(() => {
  watch(
    () => menuState.visible,
    async (value) => {
      if (!value) return;
      await nextTick();
      updatePosition();
    },
  );

  watch(
    () => menuState.visible,
    (value) => {
      emits("visiable", value);
    },
  );

  window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
</script>
