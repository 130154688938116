import * as zip from "@zip.js/zip.js";
// @ts-ignore
import md5 from "md5";

export class EncryptedDataHandler {
  handleFontPath(fontName: string) {
    let fPath =
      import.meta.env.MODE === "dev"
        ? "/fontUrl"
        : import.meta.env.VITE_FONT_URL;
    return `${fPath}/static/fonts/v2/${fontName}`;
  }

  async handleZipResponse(data: any, renderInfo: any) {
    const uuid = renderInfo?.url?.split("/").splice(-2)[0];
    const passwd = md5(`${uuid}y*eUFC.nF2jb`);
    let config,
      audioData,
      dataMap = {},
      assetMap = {};
    const reader = new zip.ZipReader(new zip.BlobReader(data));
    const entries = await reader.getEntries();

    if (entries.length) {
      for (let i = 0; i < entries.length; i++) {
        if (
          entries[i].filename.endsWith("/config.json") &&
          !entries[i].filename.includes("__MACOSX")
        ) {
          //解析config.json
          config = JSON.parse(
            await entries[i].getData(new zip.TextWriter(), {
              password: passwd,
            }),
          );
        } else if (
          entries[i].filename.endsWith(".mp3") &&
          !entries[i].filename.includes("__MACOSX")
        ) {
          // 解析音频
          let audio = await entries[i].getData(new zip.Data64URIWriter(), {
            password: passwd,
          });
          const contentType = "audio/mp3";
          audioData = audio.slice(0, 5) + contentType + audio.slice(5);
        } else if (
          entries[i].filename.endsWith(".json") &&
          entries[i].filename.includes("datas") &&
          !entries[i].filename.includes("__MACOSX")
        ) {
          // 解析datas目录下的文件
          const name = entries[i].filename.split("/").splice(-1)[0];
          if (!name.includes("_")) {
            const dataJson = JSON.parse(
              await entries[i].getData(new zip.TextWriter(), {
                password: passwd,
              }),
            );
            dataMap[name] = dataJson;
          }
        } else if (
          entries[i].filename.includes("/assets/") &&
          !entries[i].filename.includes("__MACOSX")
        ) {
          //解析assets目录下的文件
          const name = entries[i].filename.split("/").splice(-1)[0];
          if (name && !name.includes("._")) {
            let assetBlob = await entries[i].getData(new zip.BlobWriter(), {
              password: passwd,
            });

            assetMap[name] = URL.createObjectURL(assetBlob);
          }
        }
      }
    }

    await reader.close();

    //解析接口返回的可替换资源
    // 处理assets字段,此处素材替换
    for (let asset of config.assets) {
      // 通过key去后端返回的替换素材中去取，如果没有从assetMap中去取值，兜底
      let data = assetMap[asset.name];
      if (data) {
        asset.name = data;
        asset.isUrl = false;
      }
      //将不可替换文字素材的类型改为图片渲染
      if (asset.type == 6 && !asset.ui) {
        asset.textPicture = true;
      }
      let textAnim = dataMap[asset.text_anim];
      if (textAnim) {
        asset.text_anim = textAnim;
      }

      let assetsList = renderInfo.assets;
      assetsList?.forEach((ele) => {
        if (ele.type === 6) {
          if (ele.key == asset.key) {
            if (asset.text_anim) {
              asset.text_anim.text_property.default = ele.material;
              if (ele.fontSize) {
                asset.ui.size = Number(ele.fontSize);
                asset.text_anim.text_property.size = Number(ele.fontSize);
              }
              if (ele.fillColor) {
                asset.ui.fill = ele.fillColor;
                asset.text_anim.text_property.fill = ele.fillColor;
              }

              if (ele.reverseColor && ele.isReverseColor) {
                asset.reverseColor = ele.reverseColor;
                asset.isReverseColor = ele.isReverseColor;
              }
            }
          }
        } else if (ele.type === 1) {
          if (ele.key == asset.key) {
            asset.name = ele.material;
            asset.highLight = ele.highLight || false;

            // 如果是 logo 不裁剪，等比缩放，保证logo全部显示
            if (ele.logo === true) {
              asset.imagePreserveAspectRatio = "xMidYMid meet";
            }

            if (ele.reverseColor && ele.isReverseColor) {
              asset.reverseColor = ele.reverseColor;
              asset.isReverseColor = ele.isReverseColor;
            }
          }
        }
      });
    }
    // 处理图层里的transform
    for (let comp of config.comps) {
      for (let layer of comp.layers) {
        let data = dataMap[layer.transform];

        if (data) {
          layer.transform = data;
        }
        // 处理图层里的effects
        if (layer.effects.length) {
          layer.effects.forEach((item) => {
            let effect = dataMap[item.data];
            if (effect) {
              item.data = effect;
            }
          });
        }
      }
    }

    // 解析字体
    let fonts = [];
    config.assets.forEach((asset) => {
      if (asset.type === 6) {
        const textProperty = asset.text_anim?.text_property;
        if (textProperty) {
          let fontIdx = fonts.findIndex((font) => {
            return font.font === textProperty.font;
          });
          //font_file返回为空时，自定义拼接字体去请求
          let { font, font_family } = textProperty;
          //处理font_family
          //拼接font-family和font-style 得到具体的字体和后缀
          if (fontIdx < 0) {
            let item = {
              font: font,
              fFamily: font_family,
              fOrigin: "p",
              fPath: this.handleFontPath(font),
            };
            fonts.push(item);
          }
        }
      }
    });
    config.fonts = fonts;
    config.dataMap = dataMap;
    config.audioData = audioData;
    config.assetMap = assetMap;
    return config;
  }
}
