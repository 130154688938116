<template>
  <tools-modal
    v-model="visible"
    @close="handleClose"
    title="Background Remover"
  >
    <imageBgRemove
      buttonText="Apply"
      positionBottom="30px"
      @onApply="handleApply"
      :originUrl="src"
      :draftId="draftId"
      mode="editor"
    />
  </tools-modal>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import toolsModal from "../../modal/toolsModal.vue";
import imageBgRemove from "./imageBgRemove.vue";
import { useRoute } from "vue-router";

const emit = defineEmits(["update:src", "update:modelValue", "change"]);
const props = defineProps({
  src: String,
  modelValue: Boolean,
});

const visible = ref(false);
const imgUrl = ref("");
const route = useRoute();
const draftId = ref("");

watch(
  () => props.modelValue,
  (newValue) => {
    visible.value = newValue;
  },
);

watch(
  () => props.src,
  (value) => {
    if (value) {
      imgUrl.value = value;
    }
  },
);

watch(visible, (value) => {
  emit("update:modelValue", value);
});

const handleClose = () => {
  visible.value = false;
};

const handleApply = (preview480Url: string, preview1080Url: string, width480: number, width1080: number) => {
  emit("update:src", preview480Url);
  emit("change", preview480Url, preview1080Url, width480, width1080);
  handleClose();
};

onMounted(() => {
  draftId.value = route.query?.draftId as string;
});
</script>

<style lang="scss" scoped></style>
