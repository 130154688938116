import { useScriptStore } from "../stores/script";

const { creator } = useScriptStore();

export function observeNode(node) {
  const parent = node.parent;

  watch(
    [() => node.conf.start, () => node.conf.end],
    ([newStart, newEnd]) => {
      node.setDuration(newStart, newEnd);
    },
    { deep: true }
  );

  if (parent.type === "scene") {
    watch(
      [
        () => parent.conf.backgroundImage,
        () => parent.conf.backgroundOpacity,
        () => parent.conf.backgroundColor,
        () => parent.conf.backgroundBlur,
      ],
      ([
        newBackgroundImage, 
        newBackgroundOpacity,
        newBackgroundColor,
        newBackgroundBlur,
      ]) => {
        if (newBackgroundImage && newBackgroundOpacity) {
          parent.setBackgroundImage(newBackgroundImage, newBackgroundOpacity).then(() => creator.value.render());
        } else if (newBackgroundColor) {
          parent.setBackgroundColor(newBackgroundColor);
        } else if (newBackgroundBlur) {
          parent.setBackgroundBlur(newBackgroundBlur);
        } else {
          parent.removeBackground();
        }
        creator.value.render();
      }
    );
  }

  function observeSsAttr() {
    watch(
      () => node.conf.ss,
      (newSs) => {
        node.setSs(newSs);
        creator.value.refresh();
      }
    );
  }

  function observeNodeAttr() {
    watch([() => node.conf.x, () => node.conf.y], ([newX, newY]) => {
      node.setXY(newX, newY);
    });
    watch(
      () => node.conf.scale,
      (newScale) => {
        node.setScale(newScale);
        creator.value.refresh();
      }
    );
    watch(
      () => node.conf.rotate,
      (newRotate) => {
        node.setRotate(newRotate);
        creator.value.refresh();
      }
    );
    watch(
      () => node.conf.opacity,
      (newOpacity) => {
        node.setOpacity(newOpacity);
      }
    );
    watch(
      () => node.conf.anchor,
      (newAnchor) => {
        node.setAnchor(newAnchor);
        creator.value.refresh();
      }
    );
    watch(
      () => node.conf.keyframes,
      (newKeyframes) => {
        node.setKeyframes(newKeyframes);
        creator.value.refresh();
      }
    );
    watch(
      () => node.conf.blend,
      (newBlend) => {
        node.setBlend(newBlend);
        creator.value.refresh();
      }
    );
  }

  function observeSourceAttr() {
    watch(
      () => node.conf.src,
      async (newSrc) => {
        await node.updateSource(newSrc);
        creator.value.refresh();
      }
    );
  }

  function observeMaskAttr() {
    watch(
      () => node.conf.mask,
      (newMask, oldMask) => {
        if (newMask && (!node.mask || newMask.shape !== oldMask.shape)) {
          node.removeMask();
          nextTick(() => {
            node.createMask(newMask);
            node.mask.start().then(() => creator.value.refresh());
          });
        } else if (!newMask) {
          node.removeMask();
          creator.value.render();
        }
      }
    );
    watch(
      [() => node.conf.mask?.x, () => node.conf.mask?.y],
      ([newX, newY]) => {
        if (node.mask && typeof newX === "number" && typeof newY === "number") {
          node.mask.setXY(newX, newY);
          creator.value.render();
        }
      }
    );
    watch(
      [() => node.conf.mask?.width, () => node.conf.mask?.height],
      ([newWidth, newHeight]) => {
        if (
          node.mask &&
          typeof newWidth === "number" &&
          typeof newHeight === "number"
        ) {
          node.mask.setSize(newWidth, newHeight);
          creator.value.render();
        }
      }
    );
    watch(
      () => node.conf.mask?.rotate,
      (newRotate) => {
        if (node.mask && typeof newRotate === "number") {
          node.mask.setRotate(newRotate);
          creator.value.render();
        }
      }
    );
    watch(
      () => node.conf.mask?.radius,
      (newRadius) => {
        if (node.mask && typeof newRadius === "number") {
          node.mask.setRadius(newRadius);
          creator.value.render();
        }
      }
    );
    watch(
      () => node.conf.mask?.keyframes,
      (newKeyframes) => {
        if (node.mask) {
          node.mask.setKeyframes(newKeyframes);
          creator.value.render();
        }
      }
    );
  }

  function observeAudioAttr() {
    watch(
      () => node.conf.volume,
      (newVolume) => {
        node.setVolume(newVolume);
      }
    );
    watch(
      () => node.conf.fadeIn,
      (newFadeIn) => {
        node.setFadeIn(newFadeIn);
      }
    );
    watch(
      () => node.conf.fadeOut,
      (newFadeOut) => {
        node.setFadeOut(newFadeOut);
      }
    );
    watch(
      () => node.conf.speed,
      (newSpeed) => {
        node.setSpeed(newSpeed);
      }
    );
  }

  function observeTextAttr() {
    watch(
      () => node.conf.fontSize,
      (newFontSize) => {
        node.updateStyle({ fontSize: newFontSize });
      }
    );
    watch(
      () => node.conf.text,
      (newText) => {
        node.setText(newText);
      }
    );
    watch(
      () => node.conf.fill,
      (newFill) => {
        node.updateStyle({ fill: newFill });
      }
    );
    watch(
      () => node.conf.fontFamily,
      (newFontFamily) => {
        node.setFont(newFontFamily).then(() => {
          creator.value.render();
          node.updateId++;
        });
      }
    );
    watch(
      () => node.conf.fontWeight,
      (newFontWeight) => {
        node.updateStyle({ fontWeight: newFontWeight });
      }
    );
    watch(
      () => node.conf.fontStyle,
      (newFontStyle) => {
        node.updateStyle({ fontStyle: newFontStyle });
      }
    );
    watch(
      () => node.conf.align,
      (newAlign) => {
        node.updateStyle({ align: newAlign });
      }
    );
    watch(
      () => node.conf.letterSpacing,
      (newLetterSpacing) => {
        node.updateStyle({ letterSpacing: newLetterSpacing });
      }
    );
    watch(
      () => node.conf.leading,
      (newLeading) => {
        node.updateStyle({ leading: newLeading });
      }
    );
    watch(
      () => node.conf.stroke,
      (newStroke) => {
        node.updateStyle({ stroke: newStroke });
      }
    );
    watch(
      () => node.conf.strokeThickness,
      (newStrokeThickness) => {
        node.updateStyle({ strokeThickness: newStrokeThickness });
      }
    );
    watch(
      () => node.conf.backgroundRadius,
      (newBackgroundRadius) => {
        node.updateStyle({ backgroundRadius: newBackgroundRadius });
      },
    );
    watch(
      () => node.conf.backgroundOpacity,
      (newBackgroundOpacity) => {
        node.updateStyle({ backgroundOpacity: newBackgroundOpacity });
      },
    );
    watch(
      () => node.conf.background,
      (newBackground) => {
        node.updateStyle({ background: newBackground });
      }
    );
    watch(
      () => node.conf.dropShadow,
      (newDropShadow) => {
        node.updateStyle({ dropShadow: newDropShadow });
      }
    );
    watch(
      () => node.conf.dropShadowColor,
      (newDropShadowColor) => {
        node.updateStyle({ dropShadowColor: newDropShadowColor });
      }
    );
    watch(
      () => node.conf.dropShadowAlpha,
      (newDropShadowAlpha) => {
        node.updateStyle({ dropShadowAlpha: newDropShadowAlpha });
      }
    );
    watch(
      () => node.conf.dropShadowAngle,
      (newDropShadowAngle) => {
        node.updateStyle({ dropShadowAngle: newDropShadowAngle });
      }
    );
    watch(
      () => node.conf.dropShadowBlur,
      (newDropShadowBlur) => {
        node.updateStyle({ dropShadowBlur: newDropShadowBlur });
      }
    );
    watch(
      () => node.conf.dropShadowDistance,
      (newDropShadowDistance) => {
        node.updateStyle({ dropShadowDistance: newDropShadowDistance });
      }
    );
    watch(
      () => node.conf.wordWrapWidth,
      (newWordWrapWidth) => {
        node.updateStyle({ wordWrapWidth: newWordWrapWidth });
      }
    );
    watch(
      () => node.conf.paddingX,
      (newPaddingX) => {
        node.updateStyle({ paddingX: newPaddingX });
      },
    );
    watch(
      () => node.conf.paddingY,
      (newPaddingY) => {
        node.updateStyle({ paddingY: newPaddingY });
      },
    );
  }

  function observeLoopAttr() {
    watch(
      () => node.conf.loop,
      (newLoop) => {
        node.setLoop(newLoop);
      },
    );
  }

  function observeSubtitleAttr() {
    watch(
      () => node.conf.highlight,
      (newHighlight) => {
        node.setHighlight(newHighlight);
      },
    );
    watch(
      () => node.conf.wordBoundars,
      (newWordBoundars) => {
        node.setWordBoundars(newWordBoundars);
      },
    );
  }

  function observeAnimationAttr() {
    watch(
      () => node.conf.animation,
      (newAnimation) => {
        node.setAnimation(newAnimation);
      },
    );
  }
  
  switch (node.type) {
    case "image":
    case "sticker":
      observeNodeAttr();
      observeMaskAttr();
      observeSourceAttr();
      break;
    case "effect":
      observeNodeAttr();
      observeLoopAttr();
      break;
    case "video":
      observeNodeAttr();
      observeMaskAttr();
      observeAudioAttr();
      observeSsAttr();
      observeSourceAttr();
      break;
    case "text":
      observeNodeAttr();
      observeTextAttr();
      break;
    case "subtitle":
      observeNodeAttr();
      observeTextAttr();
      observeSubtitleAttr();
      observeAnimationAttr();
      break;
    case "audio":
    case "speech":
      observeSourceAttr();
      observeAudioAttr();
      observeSsAttr();
      break;
  }
}
