import { clamp } from "./index";

export function calcRate(d1, d2) {
  const res = Math.trunc((Math.floor(d1) / d2) * 10) / 10;
  const min = 0.1;
  const max = 4;
  return clamp(res, min, max);
}

export function parseNode(nodes) {
  let subtitles = [];
  let videos = [];
  let images = [];
  let speech = null;
  let primary = null;
  let effect = null;
  let text = "";
  let sticker = "";
  for (const node of nodes) {
    if (node.conf.type === "subtitle") {
      subtitles.push(node);
      text += node.conf.text;
    } else if (node.conf.type === "effect") {
      effect = node;
    } else if (node.conf.type === "speech") {
      speech = node;
    } else if (node.conf.type === "sticker") {
      sticker = node;
    } else if (node.parent?.type === "scene") {
      primary = node;
    } else if (node.conf.type === "video") {
      videos.push(node);
    } else if (node.conf.type === "image") {
      images.push(node);
    }
  }
  return {
    text,
    subtitles,
    effect,
    speech,
    primary,
    sticker,
    videos,
    images,
  };
}
