<template>
  <section class="brand-endorsement">
    <ul class="product-hunt-ranking">
      <li v-for="item of productHuntRanking">
        <a
          :href="item.link"
          target="_blank"
        >
          <img
            :src="item.imgUrl"
            :alt="item.alt"
            loading="lazy"
            width="250"
            height="54"
          />
        </a>
      </li>
    </ul>
    <img 
      src="@/assets/images/home/app_rating.png" 
      loading="lazy" 
      height="117" 
      alt="App Rating" 
    />
    <img 
      src="@/assets/images/home/partner.png" 
      loading="lazy" 
      height="113" 
      alt="Partner | OpenAI, TikTok, Microsoft, Google, Nvidia" 
    />
  </section>
</template>

<script setup lang="ts">
const productHuntRanking = [
  {
    link: 'https://www.producthunt.com/posts/boolvideo?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-boolvideo',
    imgUrl: 'https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=406440&theme=light&period=daily',
    alt: 'Boolvideo - Turn your creatives into cinematic videos with AI | Product Hunt',
  },
  {
    link: 'https://www.producthunt.com/posts/boolvideo?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-boolvideo',
    imgUrl: 'https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=406440&theme=light&period=monthly&topic_id=44',
    alt: 'Boolvideo - Turn your creatives into cinematic videos with AI | Product Hunt',
  },
  {
    link: 'https://www.producthunt.com/posts/boolvideo?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-boolvideo',
    imgUrl: 'https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=406440&theme=light&period=weekly&topic_id=46',
    alt: 'Boolvideo - Turn your creatives into cinematic videos with AI | Product Hunt',
  },
];
</script>

<style scoped lang="scss">
.brand-endorsement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 70px calc((100% - var(--view-width)) / 2);
  background-color: #F6FAFF;

  & > img {
    width: 100%;
  }
}

.product-hunt-ranking {
  display: flex;
  gap: 36px;
}
</style>
