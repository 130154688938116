<template>
  <el-skeleton class="space-card-skeleton" :loading="true" animated>
    <template #template>
      <el-skeleton-item
        variant="rect"
        style="aspect-ratio: 1/1; height: 100%"
      />
      <div class="card-skeleton-info">
        <el-skeleton-item
          variant="h3"
          style="width: 50%; height: 21px; margin-bottom: 6px"
        />
        <div class="flex items-center justify-between">
          <el-skeleton-item
            variant="text"
            style="height: 18px; margin-right: 16px; flex: 1"
          />
          <el-skeleton-item
            variant="text"
            style="width: 24px; height: 24px; border-radius: 2px"
          />
        </div>
      </div>
    </template>
  </el-skeleton>
</template>

<style scoped>
.space-card-skeleton {
  border: 1px solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  overflow: hidden;
}

.card-skeleton-info {
  padding: 14px;
  border-top: 1px solid var(--card-border-color);
}

:deep(.el-skeleton__item) {
  display: block;
  border-radius: 0;
}
</style>
