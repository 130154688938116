<template>
  <div class="form-item-wrap">
    <p class="form-item-label">
      <slot name="label" v-if="slots['label']"></slot>
      <span v-else>{{ label }}</span>
    </p>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, useSlots } from 'vue'
const slots = useSlots();
const props = defineProps({
  label: String
})
</script>

<style lang="scss" scoped>
.form-item-wrap {
  margin-bottom: 36px;
  margin-right: 36px;
}

.form-item-label {
  color: #060606;
  text-align: left;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 18px;
}
</style>