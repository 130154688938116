<template>
  <section
    class="topic-container"
    :style="`--base-size:${baseSize}px`"
  >
    <img
      v-if="typeof info.preview === 'string'"
      loading="lazy"
      :src="info.preview"
      :alt="`Poster`"
    />
    <advancedVideo
      v-else
      loop autoplay muted
      :poster="info.preview.poster"
      :src="info.preview.src"
    />
    <section class="topic-content">
      <h1>
        {{ info.title }}
      </h1>
      <p>
        {{ info.description }}
      </p>
      <starButton :link="info.link.path">
        {{ info.link.text }}
      </starButton>
    </section>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useData } from './data';
import { useWindowSize } from '@vueuse/core';
import starButton from '../star-button.vue';

const { width: windowWidth } = useWindowSize();
const baseSize = computed(
  () => Math.max(100, (windowWidth.value / 1440) * 100) | 0,
);

const info = useData()!;
</script>

<style scoped lang="scss">
.topic-container {
  display: flex;
  gap: calc(var(--base-size) * .74);
  padding: calc(var(--base-size) * 2) calc(var(--base-size) * .68);
  background: url(./assets/topic-bg.webp) no-repeat center / cover, url(./assets/topic-bg.png) no-repeat center / cover;

  & > img, & > video {
    width: calc(var(--base-size) * 6.25);
    height: fit-content;
    border-radius: calc(var(--base-size) * .12);
  }
}

.topic-content {
  flex: 1;

  & > h1 {
    font-size: calc(var(--base-size) * .48);
    font-weight: 600;
  }

  & > p {
    margin-top: calc(var(--base-size) * .3);
    font-size: calc(var(--base-size) * .24);
    font-weight: 300;
  }

  & > button {
    margin-top: calc(var(--base-size) * .7);
  }
}
</style>
