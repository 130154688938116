import type {
  AnimationItem,
  LayerRect,
  OffsetData,
  ImageData,
  DocumentData,
} from "@boolv/renderer";

export interface ScaleData {
  scaleYOffset: number;
  scaleXOffset: number;
  scale: number;
}

export interface AnimationItemAPI {
  getCurrentFrame(): number;
  getCurrentTime(): number;
  getLayerRectById(layerId: string, withOffset?: boolean): LayerRect;
  getLayerRotateById(layerId: string): string;
  computeOffsetData(layerId: string, offsetData: OffsetData): OffsetData;
  updateOffsetData(layerId: string, offsetData: OffsetData): void;
  updateImageData(layerId: string, imageData: ImageData): void;
  updateDocumentData(layerId: string, documentData: DocumentData): void;
  toContainerPoint(point: number[]): void;
  fromContainerPoint(point: number[]): void;
  getScaleData(): ScaleData;
}

interface AnimationState {
  animation: AnimationItem;
  boundingRect: DOMRect | null;
  scaleData: ScaleData | null;
}

export function animationItemAPIFactory(
  animation: AnimationItem,
): AnimationItemAPI {
  var state: AnimationState = {
    animation: animation,
    boundingRect: null,
    scaleData: null,
  };

  function getCurrentFrame(): number {
    return animation.currentFrame;
  }

  function getCurrentTime(): number {
    return animation.currentFrame / animation.frameRate;
  }

  function getLayerRectById(layerId: string, withOffset: boolean) {
    const layerRect = animation.getLayerRectById(layerId, withOffset);
    const scaleData = getScaleData();
    // 动画图层的渲染位置和大小是被缩放后的，需要还原
    const finalRect = {
      x: parseFloat(
        (layerRect.x * scaleData.scale + scaleData.scaleXOffset).toFixed(2),
      ),
      y: parseFloat(
        (layerRect.y * scaleData.scale + scaleData.scaleYOffset).toFixed(2),
      ),
      width: parseFloat((layerRect.width * scaleData.scale).toFixed(2)),
      height: parseFloat((layerRect.height * scaleData.scale).toFixed(2)),
    };
    return finalRect;
  }

  function roundMatrixProperty(val: number) {
    const v = 10000;
    if ((val < 0.000001 && val > 0) || (val > -0.000001 && val < 0)) {
      return Math.round(val * v) / v;
    }
    return val;
  }

  function getLayerRotateById(layerId: string) {
    const matrix = animation.getTransformToMatrix(layerId, {
      withOffset: true,
    });
    const scaleX = roundMatrixProperty(matrix.props[0]);
    const skewX = roundMatrixProperty(matrix.props[1]);
    // 根据数学公式换算旋转角度
    const angle = Math.atan2(skewX, scaleX) * (180 / Math.PI);
    return `rotate(${angle}deg)`;
  }

  function computeOffsetData(layerId: string, offsetData: OffsetData) {
    // 动画容器的位置和大小是被缩放后的，需要还原
    const scaleData = getScaleData();
    offsetData.x = parseFloat((offsetData.x / scaleData.scale).toFixed(2));
    offsetData.y = parseFloat((offsetData.y / scaleData.scale).toFixed(2));

    // 通过目标图层的所有父级图层的缩放、旋转来还原其真实偏移值
    const offsetX = offsetData.x;
    const offsetY = offsetData.y;
    const matrix = animation.getTransformToMatrix(layerId, {
      exceptSelf: true,
    });
    const rotateRad = -Math.atan2(matrix.props[1], matrix.props[0]);
    const scaleX = Math.sqrt(matrix.props[0] ** 2 + matrix.props[1] ** 2);
    const scaleY = Math.sqrt(matrix.props[4] ** 2 + matrix.props[5] ** 2);
    offsetData.x =
      (offsetX * Math.cos(rotateRad) - offsetY * Math.sin(rotateRad)) / scaleX;
    offsetData.y =
      (offsetX * Math.sin(rotateRad) + offsetY * Math.cos(rotateRad)) / scaleY;

    return offsetData;
  }

  function updateOffsetData(layerId: string, offsetData: OffsetData) {
    animation.updateOffsetData(layerId, offsetData);
  }

  function updateImageData(layerId: string, imageData: ImageData) {
    animation.updateImageData(layerId, imageData);
  }

  function updateDocumentData(layerId: string, documentData: DocumentData) {
    animation?.updateDocumentData(layerId, documentData);
  }

  function calculateScaleData(boundingRect: DOMRect): ScaleData {
    // @ts-ignore
    var compWidth = animation.animationData.size[0];
    // @ts-ignore
    var compHeight = animation.animationData.size[1];
    var compRel = compWidth / compHeight;
    var elementWidth = boundingRect.width;
    var elementHeight = boundingRect.height;
    var elementRel = elementWidth / elementHeight;
    var scale, scaleXOffset, scaleYOffset;
    var xAlignment, yAlignment;
    var aspectRatio =
      animation.renderer.renderConfig.preserveAspectRatio.split(" ");
    if (aspectRatio[1] === "meet") {
      scale =
        elementRel > compRel
          ? elementHeight / compHeight
          : elementWidth / compWidth;
    } else {
      scale =
        elementRel > compRel
          ? elementWidth / compWidth
          : elementHeight / compHeight;
    }
    xAlignment = aspectRatio[0].substr(0, 4);
    yAlignment = aspectRatio[0].substr(4);
    if (xAlignment === "xMin") {
      scaleXOffset = 0;
    } else if (xAlignment === "xMid") {
      scaleXOffset = (elementWidth - compWidth * scale) / 2;
    } else {
      scaleXOffset = elementWidth - compWidth * scale;
    }

    if (yAlignment === "YMin") {
      scaleYOffset = 0;
    } else if (yAlignment === "YMid") {
      scaleYOffset = (elementHeight - compHeight * scale) / 2;
    } else {
      scaleYOffset = elementHeight - compHeight * scale;
    }

    return {
      scaleYOffset: scaleYOffset,
      scaleXOffset: scaleXOffset,
      scale: scale,
    };
  }

  function recalculateSize() {
    // @ts-ignore
    var container = animation.wrapper;
    state.boundingRect = container.getBoundingClientRect();
    state.scaleData = calculateScaleData(state.boundingRect!!);
  }

  function toContainerPoint(point: number[]) {
    // @ts-ignore
    if (!animation.wrapper || !animation.wrapper.getBoundingClientRect) {
      return point;
    }
    if (!state.boundingRect) {
      recalculateSize();
    }

    var boundingRect = state.boundingRect!!;
    var scaleData = state.scaleData!!;

    var newPoint = [point[0] - boundingRect.left, point[1] - boundingRect.top];
    newPoint[0] = (newPoint[0] - scaleData.scaleXOffset) / scaleData.scale;
    newPoint[1] = (newPoint[1] - scaleData.scaleYOffset) / scaleData.scale;

    return newPoint;
  }

  function fromContainerPoint(point: number[]) {
    // @ts-ignore
    if (!animation.wrapper || !animation.wrapper.getBoundingClientRect) {
      return point;
    }
    if (!state.boundingRect) {
      recalculateSize();
    }
    var boundingRect = state.boundingRect!!;
    var scaleData = state.scaleData!!;

    var newPoint = [
      point[0] * scaleData.scale + scaleData.scaleXOffset,
      point[1] * scaleData.scale + scaleData.scaleYOffset,
    ];
    newPoint = [
      newPoint[0] + boundingRect.left,
      newPoint[1] + boundingRect.top,
    ];

    return newPoint;
  }

  function getScaleData(): ScaleData {
    if (!state.boundingRect) {
      recalculateSize();
    }
    return state.scaleData!!;
  }

  var methods = {
    recalculateSize: recalculateSize,
    getScaleData: getScaleData,
    getCurrentFrame: getCurrentFrame,
    getCurrentTime: getCurrentTime,
    toContainerPoint: toContainerPoint,
    fromContainerPoint: fromContainerPoint,
    getLayerRectById: getLayerRectById,
    getLayerRotateById: getLayerRotateById,
    computeOffsetData: computeOffsetData,
    updateOffsetData: updateOffsetData,
    updateImageData: updateImageData,
    updateDocumentData: updateDocumentData,
  };

  return Object.assign({}, methods);
}
