<template>
  <div class="pagination-container">
    <div class="flex items-center">
      <svg-icon
        name="icon_pre_step"
        :size="24"
        :color="step == 1 ? '#BBBFC4' : '#646A73'"
        :class="{ 'cursor-pointer': step != 1 }"
        @click="handlePre"
      ></svg-icon>
      <BvIndicator class="indicator" :length="length" :step="step" />
      <svg-icon
        name="icon_next_step"
        :size="24"
        :color="step == length ? '#BBBFC4' : '#646A73'"
        :class="{ 'cursor-pointer': step != length }"
        @click="handleNext"
      ></svg-icon>
    </div>
  </div>
</template>

<script setup>
import { BvIndicator } from '@/components/common/index';
import icon_pre from '@/assets/similar-video/icon_pre.svg';
import icon_next from '@/assets/similar-video/icon_next.svg';
import svgIcon from '@/components/common/bv-svgIcon/svgIcon.vue';
const props = defineProps({
  length: {
    type: Number,
    default: 3,
  },
  step: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(['onPre', 'onNext']);

const handlePre = () => {
  if (props.step == 1) {
    return;
  }
  emit('onPre');
};

const handleNext = () => {
  if (props.step == props.length) {
    return;
  }
  emit('onNext');
};
</script>

<style lang="scss" scoped>
.pagination-container {
  display: inline-block;

  .indicator {
    margin: 0px !important;
  }
}
</style>
