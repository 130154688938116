import { defineStore } from "pinia";
import { sessionGetItem, sessionSetItem, sessionRemoveItem } from '@/utils/storage';
import { generateSimilarScript, getSimilarHookList, getSimilarStickersList, getSimilarVoiceList, renderSimilarVideo, getSimilarViralHook } from '@/api/similarVideo/index.js';
import { useMessage } from '@/utils';

const originParams = sessionGetItem('similarVideo');
const mediaList = sessionGetItem('cacheMediaList');
const scriptData = sessionGetItem('scriptData');
const selectedScriptItem = sessionGetItem('selectedScript');
const styleId = sessionGetItem('selectedStyleId') ? sessionGetItem('selectedStyleId') : 1;
const mediaId = sessionGetItem('selectedMediaId');
const voiceId = sessionGetItem('selectedVoiceId');
const collection = sessionGetItem('currentCollection');
const message = useMessage();

export const useSimilarVideo = defineStore("similarVideo", {
  state: () => ({
    reqId: null,
    checkSource: true,

    formParams: originParams, // 用户表单信息
    generateScript: scriptData, // 初次生成的脚本
    selectedScriptItem: selectedScriptItem, // 用户选择的用于生成的脚本

    errorMessage: '',
    fetchCode: 0,
    completedFetch: false,

    hookList: [],
    stickerList: [],
    voiceList: [],
    viralHookList: [],
    fetchLoading: false,
    collectionList: [],
    currentCollection: collection,

    selectedStyleId: styleId || 1,
    selectedMediaId: mediaId,
    selectedVoiceId: voiceId,

    cacheMediaList: mediaList
  }),

  actions: {
    setMediaList(params) {
      this.cacheMediaList = params
      sessionSetItem('cacheMediaList', params);
    },

    setFormParams (params) {
      this.formParams = params;
      sessionSetItem('similarVideo', params);
    },

    setGenerateScript (scripts) {
      this.generateScript = scripts
      sessionSetItem('scriptData', scripts);
    },

    setSelectedScript (script) {
      this.selectedScriptItem = script;
      sessionSetItem('selectedScript', script);
    },


    setSelectedStyleId (id) {
      this.selectedStyleId = id;
      sessionSetItem('selectedStyleId', id);
    },

    setSelectedMediaId (id) {
      this.selectedMediaId = id;
      sessionSetItem('selectedMediaId', id);
    },

    setSelectedVoiceId (id) {
      this.selectedVoiceId = id;
      sessionSetItem('selectedVoiceId', id);
    },

    setCurrentCollection (item) {
      this.currentCollection = item;
      sessionSetItem('currentCollection', item);
    },

    removeSimilarEditCache () {
      this.currentCollection = null
      this.selectedMediaId = null
      this.selectedVoiceId = null
      sessionRemoveItem('selectedStyleId')
      sessionRemoveItem('selectedMediaId')
      sessionRemoveItem('currentCollection')
      sessionRemoveItem('selectedVoiceId')
      sessionRemoveItem('selectedScript')
      sessionRemoveItem('scriptData')
      sessionRemoveItem('cacheMediaList')
    },

    removeSimilarVideoCache () {
      this.formParams = null
      this.generateScript = null
      this.selectedScriptItem = null
      this.currentCollection = null
      this.selectedMediaId = null
      this.selectedVoiceId = null
      sessionRemoveItem('similarVideo')
      sessionRemoveItem('scriptData')
      sessionRemoveItem('selectedScript')
      sessionRemoveItem('selectedStyleId')
      sessionRemoveItem('selectedMediaId')
      sessionRemoveItem('currentCollection')
      sessionRemoveItem('selectedVoiceId')
      sessionRemoveItem('cacheMediaList')
    },

    async generateSimilarScriptFn (params) {
      // 存储参数
      this.completedFetch = false
      this.errorMessage = ''
      this.setFormParams(params)
      const { msg, code, data } = await generateSimilarScript(params);

      this.completedFetch = true
      this.fetchCode = code

      if (code == 130506 || code == 130503) {
        this.errorMessage = msg;
        return;
      }

      if (code != 0) {
        message.error('Rewrite failed, try again');
        return;
      }

      this.setGenerateScript(data)
    },

    async getSimilarHookListFn () {
      this.fetchLoading = true
      const { msg, code, data } = await getSimilarHookList()
      this.fetchLoading = false
      if (code != 0) {
        message.error('error, try again');
        this.collectionList = []
        return
      }

      this.hookList = data || []
      this.collectionList = data || []
    },

    async getSimilarStickersListFn () {
      this.fetchLoading = true
      const { msg, code, data } = await getSimilarStickersList()
      this.fetchLoading = false
      if (code != 0) {
        message.error('error, try again');
        return
      }

      this.stickerList = data || []
      this.collectionList = data || []
    },

    async getSimilarVoiceListFn () {
      this.fetchLoading = true
      this.collectionList = []
      const { msg, code, data } = await getSimilarVoiceList()
      this.fetchLoading = false
      if (code != 0) {
        message.error('error, try again');
        this.collectionList = []
        return
      }

      this.voiceList = data || []
      this.collectionList = data || []
    },


    async getSimilarViralHookFn () {
      this.fetchLoading = true
      this.collectionList = []
      const { msg, code, data } = await getSimilarViralHook()
      this.fetchLoading = false
      if (code != 0) {
        message.error('error, try again');
        this.collectionList = []
        return
      }

      this.viralHookList = data || []
      this.collectionList = data || []
    }
  },

  getters: {
    getFormParams: (state) => {
      return state.formParams || originParams
    },
    getGenerateScript: (state) => {
      return state.generateScript || scriptData
    },
    getSelectedScript: (state) => {
      return state.selectedScript || selectedScript
    },
    getStyleId: (state) => {
      return state.selectedStyleId || styleId
    },
    getMediaId: (state) => {
      return state.selectedMediaId || mediaId
    },
    getVoiceId: (state) => {
      return state.selectedVoiceId || voiceId
    },
  },
});