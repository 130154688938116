<script setup>
import Material from "./material.vue";
import MusicMaterial from "./musicmaterial.vue";
import EffectMaterial from "./soundeffectmaterial.vue";

const currentTab = ref("music");
</script>
<template>
  <Material>
    <el-tabs v-model="currentTab">
      <el-tab-pane label="Music" name="music" lazy>
        <music-material :currentTab="currentTab" />
      </el-tab-pane>
      <el-tab-pane label="Sound effects" name="effect" lazy>
        <effect-material :currentTab="currentTab" />
      </el-tab-pane>
    </el-tabs>
  </Material>
</template>
<style scoped>
</style>
