<template>
  <Topic />
  <BrandEndorsement />
  <Workflow />
  <CommentQuote />
  <BottomPart />
</template>

<script setup>
import Topic from './components/Topic/index.vue';
import BrandEndorsement from './components/BrandEndorsement/index.vue';
import Workflow from './components/Workflow/index.vue';
import CommentQuote from './components/CommentQuote/index.vue';
import BottomPart from './components/bottom-part.vue';
import { useViewStore } from "@/store/view";

const viewStore = useViewStore();

onMounted(() => {
  const prevTheme = viewStore.header.theme;

  viewStore.header.theme = "light";
  onBeforeUnmount(() =>  {
    viewStore.header.theme = prevTheme;
  });
});
</script>
