import { _get, _post, _put, _delete } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

export async function addBrand(params: BrandReqParams.AddBrand) {
  return await _post(`${VITE_API_BASE}/brand_kit`, params);
}

export async function updateBrandList(params: BrandReqParams.BrandInfo) {
  return await _put(`${VITE_API_BASE}/brand_kit`, params);
}

export async function getBrandList() {
  return await _get<BrandResData.BrandInfo[]>(
    `${VITE_API_BASE}/brand_kit/list`,
  );
}

export async function getBrand(params: BrandReqParams.GetBrand) {
  return await _get<BrandResData.BrandInfo>(
    `${VITE_API_BASE}/brand_kit/${params.bkId}`,
  );
}

export async function deleteBrand(params: BrandReqParams.DeleteBrand) {
  return await _delete(`${VITE_API_BASE}/brand_kit/${params.bkId}`);
}
