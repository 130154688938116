<template>
  <div class="form-wrapper" id="form-wrapper">
    <el-scrollbar class="form-scrollbar">
      <div class="similar-video-form-container">
        <div class="url-container item-container">
          <div class="title flex justify-between items-center">
            <p class="flex items-center">
              Paste a voice-over video URL, or upload from computer
              <el-tooltip
                effect="dark"
                placement="bottom"
                popper-class="box-item"
              >
                <template #content>
                  <div class="px-6 py-3 tips-content">
                    Support pasting voice-over video URL <br />
                    from Youtube, Tiktok, or uploading <br />
                    videos directly from your computer. <br />
                    <span class="high-light" @click="handleOpenLink">
                      Learn how to download videos from <br />
                      youtube, tiktok
                    </span>
                  </div>
                </template>
                <img :src="icon_tip" class="icon-tip" />
              </el-tooltip>
            </p>
            <div class="flex">
              <bv-uploader
                :multiple="false"
                v-model="files"
                ref="uploader"
                :accept="accept"
                @checkFail="handleCheckFail"
                @success="handleUploadSuccess"
                @beforeAddFiles="handleBeforeAddFiles"
              />
              <div class="upload-button" @click="handleUpload">
                <img :src="icon_upload" class="icon-upload" />
                <span>Upload</span>
              </div>
            </div>
          </div>
          <div
            class="input-container"
            :class="{ 'error-container': urlErrorMessage }"
          >
            <div class="file-input-container" v-if="fileInfo?.mid">
              <div class="file-name">
                <div class="flex h-full items-center">
                  {{ fileInfo?.videoName }}
                  <div class="icon-close-box" @click="handleClearFile">
                    <svg-icon
                      class="icon-close"
                      name="icon_close"
                      :size="14"
                      color="#646A73"
                    ></svg-icon>
                  </div>
                </div>
              </div>
            </div>

            <el-input
              v-else
              @input="handleVideoUrlInput"
              @focus="handleFocus"
              v-model="videoUrl"
              class="url-input"
              placeholder="Videos from Tiktok or Youtube (within 2mins)"
            />

            <!-- case -->
            <div
              class="case-modal ignore"
              v-if="showModal"
              @click="handleSelect"
            >
              <p class="title ignore">{{ title || 'Try this URL' }}</p>
              <div class="content ignore">
                <div class="image ignore" v-if="icon">
                  <img :src="icon" />
                </div>
                <p class="ignore">https://www.youtube.com/shorts/6SXznrYYISk</p>
              </div>
            </div>

            <p class="error-message">
              {{ urlErrorMessage }}
            </p>
          </div>
        </div>

        <div class="name-container item-container">
          <p class="title">Product or brand name</p>
          <div
            class="select-container"
            :class="{ 'error-container': nameErrorMessage }"
          >
            <el-input
              @focus="handleVideoUrlInputBlur"
              @input="handleProductNameInput"
              v-model="productName"
              placeholder="What is your product or brand name"
            />
            <p class="error-message">
              {{ nameErrorMessage }}
            </p>
          </div>
        </div>

        <div class="prompt-container item-container">
          <div class="title">
            <p>
              Description of your brand or product
              <span class="tips">( optional )</span>
            </p>
          </div>
          <el-input
            @input="handleDescriptionInput"
            :class="{
              exceeded: description?.length >= 2000,
            }"
            v-model="description"
            resize="none"
            type="textarea"
            show-word-limit
            placeholder="e.g: I am marketing this outdoor sportswear, and it is waterproof, sun-proof, and breathable. A special sale will be offered this summer with favorable pricing."
            :rows="6"
            :maxlength="2000"
          />
        </div>

        <!-- media upload -->
        <div class="media-container item-container">
          <div class="title">
            <div class="flex items-center w-full">
              <p>Media</p>
              <p class="tips">( optional )</p>
              <el-tooltip
                popper-class="box-item"
                effect="dark"
                placement="bottom"
              >
                <template #content>
                  <div class="px-6 py-3 tips-content">
                    <p class="list-item">
                      Upload your own media, or skip this <br />
                      option let AI ​​match automatically
                    </p>
                    <p class="list-item">
                      Up to 15 files, including max 5 videos <br />
                      ( each &lt 30s ), visuals over 1080P will <br />
                      be compressed and take more time.
                    </p>
                  </div>
                </template>
                <img :src="icon_tip" class="icon-tip" />
              </el-tooltip>
            </div>
          </div>

          <div class="media-box">
            <div
              v-for="item in mediaList"
              :key="item.url"
              class="image-box"
              :class="{ 'bg-uploadBg': item.type != 'upload' }"
            >
              <media-uploader
                v-if="item.type == 'upload'"
                :isMultiple="true"
                v-model="mediaFile"
                ref="mediaUploader"
                :accept="mediaAccept"
                :limitNumber="15"
                :limitVideoNumber="5"
                @checkLimitFail="handleCheckLimitFail"
                @success="handleUploadMediaSuccess"
                @beforeUpload="handleBeforeUploadMediaFiles"
              />

              <!-- 上传按钮 -->
              <div
                class="upload-btn"
                v-if="item.type == 'upload'"
                @mousemove="handleBtnMouseMove"
                @mouseleave="handleBtnMouseLeave"
              >
                <div class="upload-content" v-if="showUploadBtn">
                  Add images from
                  <div class="upload-computer" @click="handleMediaUpload">
                    Computer
                  </div>
                  <div class="upload-cloud" @click="handleUpFromCloud">
                    Cloud
                  </div>
                </div>
                <img :src="icon_add_file" class="icon-add-file" v-else />
              </div>

              <div
                v-else
                class="media-box"
                @click="handlePreview(item)"
                @mouseenter="(e) => handleShowDelete(e, item)"
                @mouseleave="(e) => handleHiddenDelete(e, item)"
              >
                <div
                  class="flex justify-center items-center"
                  v-if="item.type == 'video' && item.state == 2"
                >
                  <img :src="poster" class="poster-image" />
                </div>
                <img :src="item.coverPic" v-else />
                <div
                  class="video-duration"
                  v-if="item.type == 'video' && item.state == 0"
                >
                  {{ formatDuration(item.duration) }}
                </div>

                <div
                  class="w-6 h-6 absolute bottom-3 right-3 delete-btn"
                  v-if="item.state == 0 && item.showRemove"
                >
                  <div
                    class="h-full w-full flex justify-center items-center rounded bg-maskBg"
                  >
                    <svg-icon
                      name="icon_delete"
                      :style="{ width: '18px', height: '18px', color: '#fff' }"
                      @click.stop="handleRemove(item)"
                    ></svg-icon>
                  </div>
                </div>
                <div
                  class="upload-loading"
                  v-if="item.state == 2 && item.tempId"
                >
                  <div>uploading...</div>
                  <ProgressBar :isCompleted="item.state == 0" />
                </div>
              </div>
            </div>
          </div>

          <teleport :to="previewerRoot">
            <material-previewer
              ref="previewerRef"
              :record="previewData"
              @change="changePreview"
              :canScroll="false"
            />
          </teleport>
        </div>
      </div>
    </el-scrollbar>

    <PrimaryButton class="button-container" padding="16px 32px" @click="submit">
      <div class="button-text">
        <svg-icon
          v-if="loading"
          class="loading-icon"
          name="icon_loading"
          :size="14"
        />
        Similar script rewrite
      </div>
    </PrimaryButton>

    <div class="upload-mask" v-if="uploadLoading"></div>
    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />

    <div class="loading-container" v-if="loading">
      <AnalysisLoading :stopLoading="stopLoading" />
    </div>

    <CloudAssetModal
      ref="cloudAssetModal"
      :confirmNoClose="true"
      :visible="isOpenAssetDialog"
      @close="handleCloseAssetDialog"
      @confirm="handleConfirm"
      :filterTypes="filterTypes"
    />
  </div>
</template>

<script setup lang="jsx">
import { onBeforeUnmount, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';

import { checkResource } from '@/api/script';
import { getLanguageList } from '@/api/language';
import { useMessage, removeEscapeHtml } from '@/utils';
import { useRoute, useRouter } from 'vue-router';

import { DataUnit } from '@/utils';
import { VIDEOTYPE, IMAGETYPE, IMAGEANDVIDEOTYPE } from '@/utils/type.ts';
import { useTrackStore } from '@/store/modules/track';
import { useModalManager } from '@/components/common/custom-modal/instance';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import PrimaryButton from '@/components/common/bv-button/components/primary-button.vue';
import CloudAssetModal from '@/components/asset-modal/cloudAssetDialog/index.vue';
import MaterialPreviewer from '@/components/material-previewer/index.vue';
import MediaUploader from './uploader.vue';
import ProgressBar from './progressBar.vue';

import icon_upload from '@/assets/similar-video/icon_upload.svg';
import icon_tip from '@/assets/similar-video/icon_tip.svg';
import icon_warning from '@/assets/similar-video/icon_warning.svg';
import icon_add_file from '@/assets/similar-video/icon_add_file.svg';
import poster from '@/assets/icons/space/icon_video.svg';

import AnalysisLoading from './analysisLoading.vue';
import { useSimilarVideo } from '../store/index.js';

const similarVideoStore = useSimilarVideo();
const { formParams, cacheMediaList, errorMessage, fetchCode, completedFetch } =
  storeToRefs(similarVideoStore);
const { collectData, track } = useTrackStore();

const previewerRoot = document.createElement('div');
document.body.appendChild(previewerRoot);

const modalManager = useModalManager();
const route = useRoute();
const router = useRouter();
const message = useMessage();
const handleMessage = ref(null);
const videoUrl = ref('');
const urlErrorMessage = ref('');
const productName = ref('');
const nameErrorMessage = ref('');
const description = ref('');

const loading = ref(false);
const stopLoading = ref(false);
const leftPage = ref(false);
const subscribeModalVisible = ref(false);

const isFocus = ref(false);

const uploader = ref(null);
const files = ref([]);
const originFileInfo = ref(null);
const accept = [
  {
    types: VIDEOTYPE.split(','),
    maxSize: 50 * DataUnit.MB,
  },
];

const mediaList = ref([{ type: 'upload' }]);
const previewList = ref([]);
const previewData = ref([]);
const previewerRef = ref(null);
const uploadLoading = ref(false);
const fileInfo = ref(null);

const cloudAssetModal = ref(null);
const filterTypes = ref(['image', 'video']);
const showUploadBtn = ref(false);
const isOpenAssetDialog = ref(false);
const mediaUploader = ref(null);
const mediaFile = ref([]);
const mediaAccept = [
  {
    types: IMAGEANDVIDEOTYPE.split(','),
    maxSize: 100 * DataUnit.MB,
  },
];

const showModal = computed(() => {
  return isFocus.value && !videoUrl.value;
});

const handleOpenLink = () => {
  window.open('https://similarvideo.ai/blog/free-youtube-video-downloader');
};

const handleVideoUrlInput = (inputValue) => {
  urlErrorMessage.value = '';
  nameErrorMessage.value = '';
  if (inputValue.trim() === '') {
    videoUrl.value = '';
    isFocus.value = true;
  } else {
    videoUrl.value = inputValue;
  }
};

const handleFocus = () => {
  isFocus.value = true;
};

const handleVideoUrlInputBlur = () => {
  isFocus.value = false;
};

const handleDocumentClick = (event) => {
  const classList = event.target.classList;
  const specificParent = event.target.closest('.url-input');

  if (
    classList.contains('ignore') ||
    (specificParent && classList.contains('el-input__inner'))
  ) {
    isFocus.value = true;
  } else {
    handleVideoUrlInputBlur();
  }
};

const handleSelect = () => {
  videoUrl.value = 'https://www.youtube.com/shorts/6SXznrYYISk';
  setTimeout(() => {
    isFocus.value = false;
  }, 1000);
  urlErrorMessage.value = '';
  nameErrorMessage.value = '';
};

const handleProductNameInput = (inputValue) => {
  nameErrorMessage.value = '';
  if (inputValue.trim() === '') {
    productName.value = '';
  } else {
    productName.value = inputValue;
  }
};

const handleDescriptionInput = (inputValue) => {
  if (inputValue.trim() === '') {
    description.value = '';
  } else {
    description.value = inputValue;
  }
};

const checkSimilarVideoSource = () => {
  return new Promise(async (resolve, reject) => {
    const resourceCode = videoUrl.value ? 90010 : 90012;
    const { msg, code } = await checkResource({
      resource: [resourceCode],
    });

    if (code == 101011) {
      modalManager.applyTemplate('paymentFail', {});
      reject();
    }

    if (code == 90010 || code == 90011) {
      modalManager.applyTemplate('upgradeTips', {
        msg,
        code,
        onConfirm: () => {
          subscribeModalVisible.value = true;
        },
      });
      reject();
    }

    resolve();
  });
};

const submit = async () => {
  prompt.value = removeEscapeHtml(prompt.value);

  if (videoUrl.value.trim() === '' && !fileInfo.value?.videoName) {
    urlErrorMessage.value = 'Please provide the required input';
  }

  if (productName.value.trim() === '') {
    nameErrorMessage.value = 'Please provide the required input';
  }

  if (urlErrorMessage.value || nameErrorMessage.value) {
    return;
  }

  if (mediaList.value.find((item) => item.state == 2)) {
    message.error('Please wait, the file is uploading.', { duration: 2000 });
    return;
  }

  await checkSimilarVideoSource();

  loading.value = true;
  stopLoading.value = false;
  const params = {
    url: videoUrl.value,
    mid: fileInfo.value?.mid,
    videoName: fileInfo.value?.videoName,
    name: productName.value,
    description: description.value,
    mids: mediaList.value.map((item) => item.mid).filter((id) => id),
  };

  collectData('similarvideo_similar_input', {
    video_url: videoUrl.value,
    mid: fileInfo.value?.mid,
    product_name: productName.value,
    product_description: description.value,
  });
  track('similarvideo_similar_input');
  // 生成script
  similarVideoStore.setMediaList(mediaList.value);
  similarVideoStore.generateSimilarScriptFn(params);
};

watch(
  () => errorMessage.value,
  (msg) => {
    urlErrorMessage.value = msg;
  }
);

watch(
  () => completedFetch.value,
  (value) => {
    if (value) {
      stopLoading.value = true;
      setTimeout(() => {
        loading.value = false;
      }, 500);
      if (value && fetchCode.value == 0) {
        router.push({
          path: '/similar-video/script-write',
        });
      }
    }
  }
);

const handleBlur = () => {
  description.value = removeEscapeHtml(description.value);
};

const clickPrompt = (value) => {
  description.value = value;
};

const handleClearFile = () => {
  fileInfo.value = '';
  originFileInfo.value = '';
};

onBeforeUnmount(() => {
  leftPage.value = true;
});

onMounted(() => {
  // 官网携带参数
  const link = route.query.link;

  if (link) {
    videoUrl.value = link;
    return;
  }

  similarVideoStore.removeSimilarEditCache();
  const similarVideoParams = formParams.value;

  if (similarVideoParams) {
    fileInfo.value = {
      mid: similarVideoParams.mid,
      videoName: similarVideoParams.videoName,
    };

    originFileInfo.value = fileInfo.value;

    videoUrl.value = similarVideoParams.url;
    productName.value = similarVideoParams.name;
    description.value = similarVideoParams.description;
    mediaList.value = cacheMediaList.value?.length
      ? cacheMediaList.value
      : mediaList.value;
  }

  document.addEventListener('click', handleDocumentClick);
});

const warningModal = () => {
  modalManager.applyTemplate('info', {
    title: '',
    content: (
      <>
        <div class="relative warning-model">
          <div class="warning-title flex items-center justify-between">
            <div class="flex items-center">
              <img src={icon_warning} class="mr-4" />
              <p>Will replace the video, continue upload?</p>
            </div>
            <div
              class="warning-model-close-box"
              onClick={() => modalManager.modal.onClose()}>
              <svg-icon
                class="icon-close"
                name="icon_close"
                size={16}
                color="#646A73"></svg-icon>
            </div>
          </div>

          <div class="content">
            Continuing to upload will replace the uploaded video file.
          </div>

          <div class="button-box">
            <PrimaryButton
              size="mini"
              padding="7px 20px"
              onClick={handleContinueUpload}>
              <span class="text-sm leading-6">Continue upload</span>
            </PrimaryButton>
          </div>
        </div>
      </>
    ),
    footer: '',
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
  };
};

const handleContinueUpload = () => {
  uploader.value?.handleClickUpload();
  modalManager.modal.onClose();
};

const handleUpload = () => {
  if (videoUrl.value) {
    warningModal();
    return;
  }

  if (fileInfo.value && fileInfo.value.mid) {
    warningModal();
    return;
  }

  uploader.value?.handleClickUpload();
};

const handleBeforeAddFiles = (files) => {
  originFileInfo.value = files && files[0];
  uploadLoading.value = true;
  handleMessage.value = message.loading('Uploading, it will take a while', {
    duration: 0,
  });
};

const handleCheckFail = () => {
  uploadLoading.value = false;
  handleMessage.value?.close();
};

function handleUploadSuccess(file) {
  handleMessage.value.close();
  handleMessage.value = message.success('Upload Successfully');
  uploadLoading.value = false;
  fileInfo.value = file;
  fileInfo.value.videoName = originFileInfo.value.name;
  videoUrl.value = '';

  urlErrorMessage.value = '';
  nameErrorMessage.value = '';
}

// 素材上传
const handleMediaUpload = () => {
  if (mediaUploader.value[0]) {
    mediaUploader.value[0].handleClickUpload();
  }
};

const handleConfirm = async (list) => {
  const tempList = [...mediaList.value, ...list];

  try {
    await checkLimit(tempList);
  } catch (e) {
    handleCheckLimitFail(e);
    return;
  }

  mediaList.value = tempList;
  isOpenAssetDialog.value = false;
  cloudAssetModal.value.clearSelectedMidList();
};

const handleUpFromCloud = () => {
  isOpenAssetDialog.value = true;
};

const handleCloseAssetDialog = () => {
  console.log('handleCloseAssetDialog====');
  isOpenAssetDialog.value = false;
};

const handleBtnMouseMove = () => {
  showUploadBtn.value = true;
};

const handleBtnMouseLeave = () => {
  showUploadBtn.value = false;
};

const handleCheckLimitFail = ({ errorType }) => {
  if (errorType == 'video') {
    message.error(`Upload failed. Max 5 videos, each video < 30S.`, {
      duration: 2000,
    });
  }

  if (errorType == 'max') {
    message.error(`Upload failed. Up to 15 files.`, {
      duration: 2000,
    });
  }
};

const handleUploadMediaSuccess = (file) => {
  const index = mediaList.value.findIndex((item) => item.tempId == file.tempId);

  if (index >= 0) {
    mediaList.value.splice(index, 1, file);
  }
};

const handleBeforeUploadMediaFiles = async (files) => {
  const list = [...mediaList.value, ...files];
  try {
    await checkLimit(list);
  } catch (e) {
    handleCheckLimitFail(e);
    return;
  }
  mediaList.value = list;
};

const checkLimit = (files) => {
  return new Promise((resolve, reject) => {
    if (files.length > 16) {
      reject({
        errorType: 'max',
      });
    }

    let videoCount = 0;
    files.forEach((file) => {
      if (file.type?.includes('video')) {
        videoCount += 1;
      }
    });

    if (videoCount > 5) {
      reject({
        errorType: 'video',
      });
    }

    resolve(true);
  });
};

const handlePreview = (item) => {
  previewData.value = {
    mid: item.mid,
    preview1080Url: item.preview1080Url || item.url,
    type: item.type,
    coverPic: item.coverPic,
    duration: item.duration,
  };

  if (previewerRef.value) {
    previewerRef.value.open = true;
  }
};

watch(
  () => mediaList.value,
  (list) => {
    previewList.value = list
      .filter((item) => item.preview1080Url)
      .map((image) => {
        return {
          mid: image.mid,
          coverPic: image.coverPic,
          preview1080Url: image.preview1080Url || image.url,
          type: image.type,
        };
      });
  },
  {
    deep: true,
    immediate: true,
  }
);

const changePreview = (mid, direction) => {
  const index = previewList.value?.findIndex((e) => e.mid == mid);
  let item = null;
  if (direction == 1) {
    item = previewList.value[index + 1];
  } else if (direction == -1) {
    item = previewList.value[index - 1];
  }
  console.log('changePreview', item);
  if (!item) {
    return;
  }

  previewData.value = {
    mid: item.mid,
    preview1080Url: item.preview1080Url || item.url,
    type: item.type,
    coverPic: item.coverPic,
    duration: item.duration,
  };
};

const handleShowDelete = (e, item) => {
  item.showRemove = true;
  mediaList.value.forEach((media) => {
    if (media.mid != item.mid) {
      media.showRemove = false;
    }
  });
};

const handleHiddenDelete = (e, item) => {
  item.showRemove = false;
  mediaList.value.forEach((media) => {
    if (media.mid != item.mid) {
      media.showRemove = false;
    }
  });
};

const handleRemove = (item) => {
  const idx = mediaList.value.findIndex((media) => media.mid == item.mid);
  if (idx >= 0) {
    mediaList.value.splice(idx, 1);
  }
};

const formatDuration = (duration) => {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${(minutes | 0).toString().padStart(2, '0')}:${(seconds | 0)
    .toString()
    .padStart(2, '0')}`;
};
</script>

<style lang="scss" scoped>
:deep(.el-scrollbar) {
  width: 100%;
}

:deep(.form-scrollbar .el-scrollbar__view) {
  display: flex;
  justify-content: center;
}

.form-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 5rem 0;
}

.similar-video-form-container {
  width: 50%;
  min-width: 500px;
  max-width: 712px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  color: #060606;
  padding-bottom: 200px;

  & > div {
    width: 100%;
  }
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon_douyin {
    width: 24.697px;
    height: 26.689px;
    margin-right: 16px;
  }

  .icon_youtube {
    width: 24px;
  }
}

.item-container {
  :deep(.el-input) {
    height: 100%;
  }

  :deep(.el-input__inner) {
    padding-left: 10px;
  }
}
.input-container {
  height: 54px;
  position: relative;
}

.error-message {
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #ff5449;
  padding-left: 24px;
}

.error-container {
  :deep(.el-input__wrapper) {
    border: 1px solid #ff5449;
    box-shadow: none;
    transition: all 200ms;
    padding: 0 12px;
  }
}

.select-container {
  height: 46px;
}

.prompt-container {
  position: relative;

  & > :deep(.el-textarea) {
    padding-bottom: 40px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    transition: border-color 0.2s;

    &:hover {
      border-color: #be9fff;
    }

    &.focused {
      border-color: #875eff;
    }

    &.exceeded > .el-input__count {
      color: #ff5449;
    }

    & > .el-textarea__inner {
      padding: 20px 20px 0;
    }

    & > .el-input__count {
      right: 12px;
      bottom: 12px;
      transition: color 0.2s;
    }
  }
}

.tips {
  color: rgb(100, 106, 115);
}

.media-container {
  .media-box {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 18px;
    cursor: pointer;
    border-radius: 4px;
  }

  .poster-image {
    width: 36px;
    height: 36px;
  }

  .video-duration {
    position: absolute;
    left: 14px;
    bottom: 14px;
    padding-inline: 8px;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    background-color: #00000073;
    border-radius: 2px;
  }

  .delete-btn {
    cursor: pointer;
  }

  .upload-loading {
    position: absolute;
    background: #00000080;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
  }

  .image-box {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    border-radius: 4px;
  }

  .upload-btn {
    width: 100%;
    height: 100%;
    border: 1px solid #e5e7eb;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }

  .upload-content {
    height: 100%;
    padding: 36px 0;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .upload-content {
    color: #060606;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .upload-computer,
  .upload-cloud {
    display: flex;
    width: 138px;
    padding: 7px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    border: 1px solid #875eff;
    color: #a378ff;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    &:hover {
        background: #f8f5ff;
    }
  }

  .media-box {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .icon-add-file {
    width: 36px;
    height: 36px;
  }

  img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
  }
}

.hide {
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.appear {
  opacity: 1;
  pointer-events: all;
}

.prompt-popover {
  position: absolute;
  width: 100%;
  bottom: -4px;
  transform: translateY(100%);
  padding: 24px;
  border-radius: 4px;
  border: 0.5px solid #e5e7eb;
  background: #fff;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
  z-index: 2;
  transition: opacity 0.08s linear 0s;

  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 18px;
  }
}

.button-container {
  position: fixed;
  bottom: 58px;
  width: fit-content !important;
}

.button-text {
  position: relative;
  width: 155px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  & > .star {
    margin-right: 6px;
  }

  & > .loading-icon {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    animation: rotate 1s linear infinite;
  }
}

.loading-container {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

:deep(.el-slider) {
  --el-slider-button-size: 18px;
  --el-slider-button-wrapper-size: 18px;
}

:deep(.el-input__inner) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #060606;
}

:deep(.el-textarea__inner) {
  font-family: Inter-variant !important;
  color: #060606;
  font-size: 14px;
  line-height: 20px;
  border: none;
  box-shadow: none;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::placeholder {
    color: #8f959e;
  }
}

:deep(.bv-select-button .select-wapper) {
  padding: 12px 21px;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

:deep(.bv-select-button .select-wapper) {
  padding: 12px 21px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e5e7eb inset !important;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

:deep(.bv-select-button .select-wapper.show-box-shadow.is-focus) {
  padding: 12px 21px;
  box-shadow: 0 0 0 1px #875eff inset !important;
}

:deep(.bv-select-button .select-value) {
  color: #060606;
  font-size: 14px;
  line-height: 20px;
}

:deep(.el-input__wrapper) {
  border: 1px solid #e5e7eb;
  box-shadow: none;
  transition: all 200ms;
  padding: 0 12px;
}

:deep(.el-input .el-input__wrapper:hover),
:deep(.el-textarea__inner:hover) {
  outline-color: #be9fff;
  border-color: #be9fff;
  box-shadow: none !important;
}

:deep(.el-input .el-input__wrapper.is-focus),
:deep(.el-textarea__inner:focus) {
  border-color: #875eff !important;
  outline-color: #875eff !important;
  outline-offset: 0;
  box-shadow: none !important;
}

:deep(.el-input__prefix) {
  margin-right: 6px;
  color: #646a73;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.el-input__prefix-inner > :last-child) {
  margin: 0;
}

:deep(.idea-language-select .bv-select-dropdown) {
  height: 400px;
}

.case-modal {
  position: absolute;
  width: 100%;
  max-height: 158px;
  top: 66px;
  border-radius: 4px;
  border: 0.5px solid #e5e7eb;
  background: #fff;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 26px 23px;
  z-index: 2;

  .title {
    color: #000;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .image {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    margin-right: 15px;

    img {
      width: 70px;
      height: 70px;
      border-radius: 4px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #646a73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
  }
}

.icon-tip {
  width: 18px !important;
  height: 18px;
  margin-left: 6px;
  cursor: pointer;
}

.tips-content {
  background: #000;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.upload-button {
  display: flex;
  width: 95px;
  height: 34px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #f8f5ff;
  color: #6741ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}

.icon-upload {
  width: 14.63px;
  height: 14.63px;
  margin-right: 9.69px;
}

.high-light {
  color: #a378ff;
  text-decoration: underline;
  cursor: pointer;
}

.file-input-container {
  width: 100%;
  height: 54px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0px 18px;

  .file-name {
    display: inline-block;
    border-radius: 4px;
    background: #ebedef;
    height: 30px;
    line-height: 30px;
    flex-shrink: 0;
    padding: 0px 6px;
    color: #060606;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .icon-close-box {
    margin-left: 12px;
    cursor: pointer;
    padding: 2px;
    border-radius: 2px;
    &:hover {
      background: #d9dbdd;
    }
  }
}

.upload-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background: #fff;
  opacity: 0.7;
}
</style>

<style lang="scss">
.warning-model {
  .warning-title {
    color: #000;
    font-family: Inter-variant;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .warning-model-close-box {
    margin-left: 12px;
    cursor: pointer;
    padding: 4px;
    border-radius: 2px;
    &:hover {
      background: #ebedef;
    }
  }

  .content {
    margin-top: 8px;
    color: #000;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-left: 40px;
  }

  .button-box {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
}

.bv-select-dropdown.idea-language-select ul {
  max-height: 250px;
}

.box-item.el-popper {
  padding: 0;
}

.box-item.el-popper.is-dark {
  border: 1px solid #000;
}

.box-item.el-popper.is-dark .el-popper__arrow::before {
  background: #000 !important;
  border: 1px solid #000 !important;
}
</style>
