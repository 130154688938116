<template>
  <div class="steps-container">
    <div class="step-description">
      <p class="title">Cloning the video script</p>
      <p class="tip">Takes proximately 50 seconds</p>
    </div>
    <div class="progress">
      <div class="progress-bar" :style="{ width: percent + '%' }"></div>
      <div class="progress-sub-bar" :style="{ width: percent + '%' }">
        <div class="progress-animation"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted , onBeforeUnmount} from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  stopLoading:  Boolean
})

let timer = null;
const route = useRoute();

const percent = ref(3);
const currentStep = ref(null);
const generateSteps = ref(null);

watch(() => props.stopLoading, (value) => {
  if(value) {
    percent.value = 100
  }
})

onMounted(() => {
  timer = setInterval(() => {
    percent.value = percent.value + 1;
  }, 300);
});

onBeforeUnmount(() => {
  clearInterval(timer)
  timer = null
})
</script>

<style scoped lang="scss">
.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background: #fff;
  height: 100%;
  margin-top: -200px;

  .title {
    color: #1c1b1e;
    font-family: Inter-variant;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .title {
    color: #1c1b1e;
    font-family: Inter-variant;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .tip {
    color: #646a73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.step-description {
  position: relative;
}

.step-title {
  position: absolute;
  right: 0;
  top: 0;
  width: max-content;
  padding: 20px 24px;
  background-color: #6741ff;
  border-radius: 99px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  transform: translate(100%, -100%);
  transition:
    top 0.2s,
    right 0.2s;
  transform-origin: left bottom;
  animation: init-scale 0.2s;

  &::after {
    content: '';
    position: absolute;
    left: 6.5px;
    bottom: -8px;
    width: 20px;
    height: 20px;
    background: url('./assets/icon_tail.svg') no-repeat;
  }
}

.progress {
  position: relative;
  width: 440px;
  height: 8px;
  margin-top: 26px;
  background-color: #f2edff;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  height: 100%;
  background: #7b5aff;
}

.progress-sub-bar {
  position: absolute;
  z-index: 2;
  height: 100%;
  overflow: hidden;
}

.progress-animation {
  height: 100%;
  width: 100%;
  animation: progressLoop 1s linear infinite;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 12.6%,
    rgba(226, 144, 255, 0.82) 49.07%,
    rgba(255, 255, 255, 0) 86.38%
  );
}
</style>
