<template>
  <div 
    class="control-container" 
    ref="controlContainer"
    @mousedown.stop="mousedown"
  >
    <div class="progress-bar" />
    <div 
      v-for="knot in frameKnots"
      class="knot"
      :style="{
        left: knot.left,
        background: knot.background,
        opacity: knot.opacity,
      }"
    />
    <div class="readed-progress-bar"
      :style="{
        width: currenrFrameWidth
      }"
    >
    </div>
    <div class="current-frame-knot" 
      :style="{
        left: currenrFrameWidth
      }"
    />
  </div>
</template>

<script setup>
import { useScriptStore } from "../../stores/script";

const {
  scenes,
  currentFrame,
  totalFrame,
  seekTo,
  pause,
} = useScriptStore();

const currenrFrameWidth = ref("0");
const controlContainer = ref(null);
const dragging = ref(false);

const frameKnots = computed(() => {
  const sceneArray = scenes.value
    .filter(item => item.type !== "voice_sticker")
    .slice(0, -1);
  const newKnots = sceneArray.map((item) => {
    const { end } = item;
    const readed = currentFrame.value > end;
    const left = end / totalFrame.value * 100 + "%";
    const background = readed ? "#FFFFFF" : "#000000";
    const opacity =  readed ? 0.65 : 0.45;
    return {
      left,
      background,
      opacity,
    }
  });
  return newKnots;
});

watch(
  currentFrame,
  (value) => {
    if (dragging.value) return;
    currenrFrameWidth.value = value / totalFrame.value * 100 + "%";
  },
);

const mousedown = (e) => {
  if (e.button !== 0) return;
  drag(e);
  dragging.value = true;
};

const mousemove = (e) => {
  if (!dragging.value) return;
  drag(e);
};

const mouseup = () => {
  if (dragging.value) {
    dragging.value = false;
  }
};

const drag = (e) => {
  const element = controlContainer.value;
  const containerBox = element.getBoundingClientRect();
  let left = e.pageX - containerBox.x;
  if (left < 0) {
    left = 0;
  }
  const width = containerBox.width;
  const percentage = left / width;
  let frame = Math.round(percentage * totalFrame.value);
  if (frame > totalFrame.value) {
    frame = totalFrame.value;
  }

  currenrFrameWidth.value = frame / totalFrame.value * 100 + "%",
  seekTo(frame);
};

const visibilityChange = () => {
  if (document.visibilityState === 'hidden') {
    pause();
  }
};

onMounted(() => {
  document.addEventListener("mousemove", mousemove);
  document.addEventListener("mouseup", mouseup);
  document.addEventListener("visibilitychange", visibilityChange);
});

onBeforeUnmount(() => {
  document.removeEventListener("mousemove", mousemove);
  document.removeEventListener("mouseup", mouseup);
  document.removeEventListener("visibilitychange", visibilityChange);
});

</script>

<style lang="scss" scoped>
.control-container {
  position: relative;
  margin-top: 44px;
  cursor: pointer;
}

.progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: #EBEDEF;
}

.knot {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3px;
  height: 4px;
  background: #000000;
  opacity: 0.45;
  z-index: 2;
}

.readed-progress-bar {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  height: 4px;
  border-radius: 2px;
  background: #1C1B1E;
  z-index: 1;
}

.current-frame-knot {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #BBBFC4;
  z-index: 3;
}
</style>