<script setup>
import Material from "./material.vue";
import ImageMaterial from "./libimagematerial.vue";
import VideoMaterial from "./libvideomaterial.vue";

const currentTab = ref("image");
</script>
<template>
  <Material>
    <el-tabs v-model="currentTab">
      <el-tab-pane label="Image" name="image" lazy>
        <image-material />
      </el-tab-pane>
      <el-tab-pane label="Video" name="video" lazy>
        <video-material />
      </el-tab-pane>
    </el-tabs>
  </Material>
</template>
<style scoped>
:deep(.el-scrollbar) {
  height: calc(100% - 146px);
}
</style>
