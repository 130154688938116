<script setup>
import { useCreatorStore, useDraftStore, useHistoryStore } from "../../stores";
import { useTrackStore } from "@/store/modules/track";
import { throttled } from "@/utils";

const emit = defineEmits(["change"]);
const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  keyframes: {
    type: Object,
    default: undefined,
  },
  dirty: {
    type: Object,
    default: null,
  },
  shouldAdd: {
    type: Boolean,
    default: true,
  },
});
const { collectData, track, clearEventData } = useTrackStore();
const { currentFrame, activeNodeMap, seekTo } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const visible = ref(false);
const keyNames = computed(() => Object.keys(props.properties));
const frameKeys = computed(() =>
  props.keyframes ? Object.keys(props.keyframes) : [],
);
const active = computed(() => hasFrameKey(currentFrame.value.toString()));
const shouldAdd = computed(() => {
  if (!props.shouldAdd) {
    return false;
  }
  for (const frameString of frameKeys.value) {
    if (hasFrameKey(frameString)) {
      return true;
    }
  }
  return false;
});
const prevFrame = computed(() => {
  for (let i = frameKeys.value.length - 1; i >= 0; i--) {
    const frameString = frameKeys.value[i];
    const frame = parseInt(frameString);

    if (frame < currentFrame.value && hasFrameKey(frameString)) {
      return frame;
    }
  }
  return -1;
});
const nextFrame = computed(() => {
  for (const frameString of frameKeys.value) {
    const frame = parseInt(frameString);

    if (frame > currentFrame.value && hasFrameKey(frameString)) {
      return frame;
    }
  }
  return -1;
});

for (const key of keyNames.value) {
  watch(
    () => props.dirty[key],
    () => {
      if (shouldAdd.value) {
        setKeyframes();
      }
    },
    { flush: "post" },
  );
}

function submit() {
  commit();
  updateDraft();
}

function hasFrameKey(frameString) {
  const { keyframes } = props;
  if (keyframes && keyframes[frameString]) {
    for (const key of keyNames.value) {
      if (typeof keyframes[frameString][key] === "number") {
        return true;
      }
    }
  }
  return false;
}

const addTracker = throttled(() => {
  const activeNode = activeNodeMap.values().next().value;
  const id = activeNode.conf.sourceId || activeNode.id;
  collectData("boolvideo_timeline_edit_click", {
    element_type: activeNode.type,
    element_id: id,
    click: "add_keyframe",
  });
  track("boolvideo_timeline_edit_click");
  clearEventData("boolvideo_timeline_edit_click");
}, 10000);

function addKeyframe() {
  addTracker();
  setKeyframes();
  submit();
}

function setKeyframes() {
  const { keyframes, properties } = props;
  const frameString = currentFrame.value.toString();
  const newKeyframes = { ...keyframes };

  if (!newKeyframes[frameString]) {
    newKeyframes[frameString] = {};
  }
  const newKeyframe = newKeyframes[frameString];
  for (const key of keyNames.value) {
    newKeyframe[key] = properties[key];
  }
  emit("change", newKeyframes);
}

const removeTracker = throttled(() => {
  const activeNode = activeNodeMap.values().next().value;
  const id = activeNode.conf.sourceId || activeNode.id;
  collectData("boolvideo_timeline_edit_click", {
    element_type: activeNode.type,
    element_id: id,
    click: "delete_keyframe",
  });
  track("boolvideo_timeline_edit_click");
  clearEventData("boolvideo_timeline_edit_click");
}, 10000);

function removeKeyframe() {
  const { keyframes } = props;
  const frameString = currentFrame.value.toString();
  const newKeyframes = { ...keyframes };
  const keyframe = newKeyframes[frameString];

  for (const key of keyNames.value) {
    delete keyframe[key];
  }
  const length = Object.keys(keyframe).length;

  if (keyframe.easing && length === 1 || length === 0) {
    delete newKeyframes[frameString];
  }
  removeTracker();
  emit("change", newKeyframes);
  submit();
}
</script>
<template>
  <div class="keyframe" :class="{ active }">
    <svg-icon
      clickable
      class="icon-left"
      v-show="prevFrame >= 0"
      name="editor_keyframe_back"
      :size="12"
      @click="seekTo(prevFrame)"
    />
    <bv-tip :content="active ? 'Delete keyframe' : 'Add keyframe'">
      <div
        class="center"
        @mouseenter.stop="visible = true"
        @mouseleave.stop="visible = false"
      >
        <div class="rhombus"></div>
        <svg-icon
          clickable
          v-show="!active && visible"
          name="editor_keyframe_plus"
          color="#646A73"
          :size="12"
          @click="addKeyframe"
        />
        <svg-icon
          clickable
          v-show="active && visible"
          name="editor_keyframe_minus"
          :size="12"
          @click="removeKeyframe"
        />
      </div>
    </bv-tip>
    <svg-icon
      clickable
      class="icon-right"
      v-show="nextFrame >= 0"
      name="editor_keyframe_forward"
      :size="12"
      @click="seekTo(nextFrame)"
    />
  </div>
</template>
<style scoped>
.keyframe {
  display: flex;
  position: relative;
  width: 38px;
  height: 100%;
}
.keyframe .center {
  width: 14.14px;
  height: 14.14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-left {
  left: 0;
}
.icon-right {
  right: 0;
}
.keyframe svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.center svg {
  z-index: 1;
}
.rhombus {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 2px;
  border: 0.5px solid #8f959e;
  background-color: #fff;
  transform: rotate(45deg);
}
.keyframe.active .rhombus {
  background-color: #875eff;
  border: none;
}
</style>
