<script setup>
import { useDraftStore, useHistoryStore } from "../../stores";
import Attr from "./attr.vue";
import Extra from "./extra.vue";

const emit = defineEmits(["update:mix"]);
const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  initialState: {
    type: Object,
    default: {},
  },
  name: {
    type: String,
    default: "",
  },
  mix: {
    type: Number,
    default: 1,
  },
});
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

function submit() {
  commit();
  updateDraft();
}

function resetAdjustmentProperty() {
  const { initialState } = props;
  emit("update:mix", initialState.mix);
  submit();
}

function updateMix(value, shouldSubmit) {
  emit("update:mix", value);
  shouldSubmit && submit();
}
</script>
<template>
  <Attr title="Filter">
    <el-scrollbar>
      <div class="attr-content">
        <div class="form">
          <div class="form-header">
            <span>Adjustments</span>
            <icon-button
              name="editor_reset"
              :size="14"
              @click="resetAdjustmentProperty"
            />
          </div>
          <div class="form-content">
            <div class="col">
              <div class="desc">
                <span>Name: </span>
                <span>{{ name }}</span>
              </div>
            </div>
            <div class="col col-slider">
              <svg-icon name="editor_filter_mix" :size="18" />
              <bv-slider
                :model-value="mix"
                :min="0"
                :max="1"
                :step="0.01"
                :show-tooltip="false"
                @input="updateMix"
                @change="updateMix($event, true)"
              />
              <input-number
                align-center
                percent
                :model-value="mix"
                :min="0"
                :max="1"
                :step="0.01"
                @input="updateMix"
                @change="updateMix($event, true)"
              />
            </div>
          </div>
        </div>
        <extra :config="node.conf" />
      </div>
    </el-scrollbar>
  </Attr>
</template>
<style scoped>
.desc span {
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}
</style>
