<script setup>
import ColorPicker from "./ColorPicker.vue";

const defaultColors = [
  "#FFFFFF",
  "#000000",
  "#7F3BF5",
  "#3075FF",
  "#34C724",
  "#FFBA2E",
  "#FF922E",
];

const emit = defineEmits(["input", "change"]);
const props = defineProps({
  value: {
    type: String,
    default: "#000000",
  },
  nullable: {
    type: Boolean,
    default: false,
  },
});

const inputRef = ref(null);
const visible = ref(false);
const colors = ref(
  props.nullable ? ["", ...defaultColors] : [...defaultColors, "#F54A45"],
);
</script>
<template>
  <div ref="inputRef" class="el-input" @click="visible = !visible">
    <div class="el-input__wrapper" :class="{ 'is-focus': visible }">
      <span class="el-input__prefix">
        <span class="el-input__prefix-inner">
          <color-picker
            v-model:visible="visible"
            popper-class="input-color-picker"
            :excludes="[inputRef]"
            :size="22"
            :value="value"
            :colors="colors"
            @input="emit('input', $event)"
            @change="emit('change', $event)"
          />
        </span>
      </span>
      <span class="input-value">{{ value && value.substring(1) }}</span>
    </div>
  </div>
</template>
<style scoped>
.el-input {
  cursor: pointer;
}
.input-value {
  flex: 1 1;
  color: #646a73;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
<style>
.input-color-picker {
  margin-left: -7px;
}
</style>
