<template>
  <Teleport to="body">
    <Overlay :open="isOpen" :can-scroll="false" @click="handleClick">
      <template v-if="record !== null && isOpen">
        <video-previewer v-if="record.type === 'video'" :record="record" />
        <image-previewer
          v-else-if="record.type === 'image'"
          ref="imagePreviewerRef"
          :record="record"
        />
      </template>
      <div no-close>
        <button class="close-btn" @click="handleClose">
          <Close />
        </button>
        <button
          v-if="showArrow"
          class="prev-btn"
          :custom-disabled="disablePrev ? '' : null"
          @click="!disablePrev && $emit('change', record!.mid, -1)"
        >
          <ArrowLeft />
        </button>
        <button
          v-if="showArrow"
          class="next-btn"
          :custom-disabled="disableNext ? '' : null"
          @click="!disableNext && $emit('change', record!.mid, 1)"
        >
          <ArrowRight />
        </button>
      </div>
    </Overlay>
  </Teleport>
</template>

<script setup lang="ts">
import ImagePreviewer from "./components/image-previewer.vue";
import VideoPreviewer from "./components/video-previewer.vue";
import Overlay from "@/components/common/custom-modal/components/overlay.vue";
import { Close, ArrowLeft, ArrowRight } from "@element-plus/icons-vue";

const props = defineProps({
  record: {
    type: [Object, null] as PropType<SpaceResData.MaterialLibDetailVO | null>,
    required: true,
  },
  showArrow: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["close", "change"]);
const isOpen = ref(false);
const disablePrev = ref(false);
const disableNext = ref(false);
const imagePreviewerRef = ref(
  null as null | InstanceType<typeof ImagePreviewer>,
);
const handleClose = () => {
  isOpen.value = false;
};

function handleClick(e: Event) {
  if ((e.target as HTMLElement).closest("[no-close]") !== null) return;
  handleClose();
}

watch(
  () => props.record,
  () => {
    if (imagePreviewerRef.value === null) return;

    imagePreviewerRef.value.reset();
  },
);

defineExpose({
  open: isOpen,
  disableNext,
  disablePrev,
});
</script>

<style lang="scss" scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.65);
}

.modal-close-btn {
  width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
}

.close-btn {
  position: fixed;
  top: 40px;
  right: 40px;
  padding: 10px;
  background-color: rgba(96, 98, 102, 0.8);
  border-radius: 50%;
  color: #cccccc;

  & > svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: #ffffff;
  }
}

.prev-btn,
.next-btn {
  position: fixed;
  top: 0;
  bottom: 0;
  height: fit-content;
  padding: 21px;
  margin-block: auto;
  background-color: rgba(96, 98, 102, 0.8);
  color: #cccccc;
  border-radius: 50%;

  & > svg {
    width: 24px;
    height: 24px;
  }

  &:not([custom-disabled]):hover {
    color: #ffffff;
  }

  &[custom-disabled] {
    cursor: not-allowed;
  }
}

.prev-btn {
  left: 75px;
}

.next-btn {
  right: 75px;
}

.image-previewer {
  max-width: calc(100% - 346px);
}
</style>
