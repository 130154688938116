<script setup>
import { watch, nextTick } from 'vue'
import Cor from "@boolv/creator";

const emit = defineEmits(["loaded", "onIsPlaying"]);
const props = defineProps({
  config: {
    type: String,
    default: null,
  },
});

const player = ref(null);
const creator = ref(null);
const currentFrame = ref(0);
const totalFrame = ref(0);
const playing = ref(false);
const played = ref(false);
const visible = ref(false);
const muted = ref(false);
const loading = ref(true);

// onMounted(setup);
onBeforeUnmount(destroy);

async function setup () {
  const config = props.config;
  await nextTick()
  await start(config);
}


watch(() => props.config, (value) => {
  if (!value) {
    return
  }
  setup()
}, {
  immediate: true
})

function start (config) {
  return new Promise((resolve, reject) => {
    if (creator.value) {
      destroy();
    }
    loading.value = true
    const cor = new Cor.Creator(config, { container: player.value });
    cor.on("durationchange", (frameNum) => (totalFrame.value = frameNum));
    cor.on("timeupdate", (frame) => (currentFrame.value = frame));
    cor.on("ended", () => (playing.value = played.value = false));
    cor.on("error", reject);
    cor.on("canplay", () => {
      creator.value = cor;
      loading.value = false;
      seekTo(cor.cover).then(() => {
        emit("loaded");
        resolve();
      });
    });
    cor.start();
  })
}

async function play () {
  if (creator.value) {
    if (!played.value) {
      await seekTo(0);
    }
    playing.value = true;
    played.value = true;

    emit("onIsPlaying", playing.value);
    await creator.value.play();
  }
}

async function pause () {
  if (creator.value) {
    playing.value = false;
    await creator.value.pause();
  }

  emit("onIsPlaying", playing.value);
}

const onPlay = () => {
  play();
};

const onStop = async () => {
  if (creator.value) {
    played.value = false;
    await pause();
    await seekTo(creator.value.cover);
  }
};

const onPause = () => {
  pause();
};

async function seekTo (value) {
  if (creator.value) {
    await creator.value.seekTo(value);
  }
}

function setVolume (volume) {
  if (creator.value) {
    muted.value = volume === 0;
    creator.value.setVolume(volume);
  }
}

function destroy () {
  creator.value?.destroy();
  creator.value = null;
}

function frameToHms (frame) {
  const sec = frame / 30;

  let minutes = Math.floor(sec / 60);
  let seconds = Math.floor(sec - minutes * 60);

  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return minutes + ":" + seconds;
}

defineExpose({
  onPlay,
  onPause,
  onStop,
  destroy,
});
</script>
<template>
  <div class="player-container" @mouseenter.stop="visible = true"
    @mouseleave.stop="visible = false">
    <div class="loading" v-if="loading">
      <svg-icon name="icon_loading" color="#BBBFC4" :size="24" />
    </div>
    <div id="player" ref="player" ></div>
    <div class="duration" v-show="!played">
      <span>{{ frameToHms(totalFrame) }}</span>
    </div>
    <svg-icon v-show="!played && visible" class="icon-play-large" name="player_play_large" :size="80" @click="play" />
    <div class="controls" v-show="played && visible"  >
      <bv-slider :model-value="currentFrame" :step="30" :min="0" :max="totalFrame" :show-tooltip="false"
        @input="seekTo" />

      <div class="control-btns">
        <div class="flex">
          <svg-icon class="icon-play" v-show="!playing" name="player_play" :size="24" @click="play" />
          <svg-icon class="icon-play" v-show="playing" name="player_pause" :size="24" @click="pause" />

          <span>{{ frameToHms(currentFrame) }}/{{
    frameToHms(totalFrame)
  }}</span>
        </div>
        <svg-icon class="icon-volume" v-show="!muted" name="player_volume" :size="20" @click="setVolume(0)" />
        <svg-icon class="icon-volume" v-show="muted" name="player_mute" :size="20" @click="setVolume(1)" />
      </div>
    </div>

  </div>
</template>
<style scoped>
.loading {
  z-index: 1;
  width: 101%;
  height: 101%;
  left: 0px;
  top: 0px;
  position: absolute;
  background: #f6f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading svg {
  animation: rotate 1s linear infinite;
}

.player-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
}

.player-container.border {
  border-width: 0.5px;
}

#player {
  position: relative;
  max-width: 101%;
  width: 101%;
  height: 101%;
  display: flex;
  justify-content: center;
  align-items: center;
}


#player canvas {
  width: 100% !important;
  height: 100%;
}

.icon-share {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
}

.icon-play-large {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.duration {
  position: absolute;
  left: 18px;
  bottom: 18px;
  border-radius: 200px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 4px 12px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.controls {
  height: 66px;
  width: 100%;
  padding: 8.5px 16px 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
}

.controls .control-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.controls svg {
  cursor: pointer;
}

.controls .icon-play {
  margin-right: 8px;
  margin-left: -6px;
}

.controls .icon-volume {
  margin-right: -3px;
}

:deep(.controls .el-slider) {
  margin: 0 12px;
  flex: 1 1;
}

:deep(.controls .el-slider__runway) {
  background: rgba(255, 255, 255, 0.4);
  height: 4px;
  border-radius: 40px;
  overflow: hidden;
}

:deep(.controls .el-slider__bar) {
  background-color: #ffffff;
  height: 4px;
}

:deep(.controls .el-slider__button-wrapper) {
  display: none;
}
</style>
