<template>
  <card-item v-bind="cardItemProps">
    <div class="mask-box" @click="onClickMask">
      <div class="content-container">
        <img class="upload-fail-icon" :src="uploadFailIcon" alt="warn icon" />
        <span class="tip-text">{{ tip }}</span>
      </div>
    </div>
  </card-item>
</template>

<script setup lang="ts">
import CardItem from "./card-item.vue";
import { createGroupProxy } from "@/utils/type";
import { cardItemPropsSchema } from "../utils/type";
import uploadFailIcon from "@/assets/icons/space/icon_upload_fail.svg";
import type { PropType } from "vue";

const props = defineProps({
  ...cardItemPropsSchema,
  tip: {
    type: String,
    default: "Upload Failed",
  },
  onClickMask: {
    type: Function as PropType<(payload: MouseEvent) => void>,
    default: () => {},
  },
});

const { rest: cardItemProps } = createGroupProxy(props, {
  customProps: ["tip", "onClickMask"],
});
</script>

<style lang="scss" scoped>
.mask-box {
  position: absolute;
  inset: 0;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upload-fail-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 16px;
}

.tip-text {
  color: #ffffff;
  font-size: 14px;
}
</style>
