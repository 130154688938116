<template>
  <el-card
    class="card-item"
    shadow="never"
    :body-style="{ padding: 0 }"
    :selected="isSelected ? '' : null"
    @contextmenu="handleContextMenu"
    @click.capture="handleSelect"
  >
    <img
      v-if="batchDelete.size !== 0 && canSelete"
      class="select-icon"
      :src="isSelected ? selectedIcon : unselectedIcon"
    />
    <div
      ref="posterContainer"
      class="preview"
      @click="handleClickPoster"
    >
      <slot></slot>
      <picture
        class="poster"
        :style="`${props.posterStyle}`"
      >
        <img
          :src="info.poster"
          @error="handlePosterLoadFail"
          draggable="false"
        />
      </picture>
      <span v-if="info.duration !== 0" class="video-duration">{{
        formatDuration(info.duration)
      }}</span>
    </div>
    <div class="card-footer">
      <p class="video-name">
        <slot name="title">
          {{ info.title }}
        </slot>
      </p>
      <div class="bottom-part">
        <span class="update-time">{{ updateTime }}</span>
        <el-dropdown
          v-show="canSelete && !disableMenu"
          popper-class="video-info-card-dropdown"
          placement="bottom-end"
          trigger="click"
          ref="dropdownRef"
          @command="handleCommand"
          @visibleChange="handleMenuVisibleChange"
          :teleported="false"
          :popper-options="{
            modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
          }"
        >
          <img
            v-show="batchDelete.size === 0"
            :class="['more-icon', isFocusMenu ? 'active' : '']"
            width="16"
            height="16"
            :src="moreIcon"
          />
          <template #dropdown v-if="isFocusMenu">
            <el-dropdown-menu class="video-info-card-menu">
              <el-dropdown-item
                v-for="operation of usedOperations"
                :key="operation.text"
                :command="operation"
              >
                <svgIcon :name="operation.iconName" />
                <span>{{ operation.text }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </el-card>
</template>

<script setup lang="tsx">
import { ref, watch, inject, onMounted, getCurrentInstance } from "vue";
import moreIcon from "@/assets/icons/common/icon_more.svg";
import { cardItemPropsSchema, type CardOperation } from "../utils/type";
import { formatDate } from "@/utils";
import { useTrackStore } from "@/store/modules/track";
import modalInstance from "@/components/common/custom-modal/instance";
import selectedIcon from "@/assets/icons/space/icon_selected.svg";
import unselectedIcon from "@/assets/icons/space/icon_unselected.svg";
import type { DropdownInstance } from "element-plus/lib/components";
import type { MutexSpace } from "@/utils";

// ANCHOR - 变量声明
const posterContainer = ref<HTMLElement>();
const { collectData, track } = useTrackStore();
const props = defineProps(cardItemPropsSchema);
const batchDelete = inject<Set<string>>("batchDelete")!;
const mutexSpace = inject("mutexSpace") as MutexSpace;
const isFocusMenu = mutexSpace.ref();
const instance = getCurrentInstance();
const dropdownRef = ref(null as unknown as DropdownInstance);
const modalManager = modalInstance.modalManager!;
const updateTime = computed(() =>
  props.info.updateTime === -1 ? "00:00" : formatDate(props.info.updateTime),
);

const batchDeleteOperation: CardOperation = {
  iconName: "icon_delete",
  text: "Delete all",
  handler: () => {
    modalManager.applyTemplate("batch_delete_card_item", {});
  },
};

const canSelete = computed(() => {
  return (
    props.operations.find((item) => item.text === "Delete") !== undefined
  );
});

const isSelected = computed(() => {
  return batchDelete.has(props.info.id);
});

const usedOperations = computed(() => {
  return isSelected.value ? [batchDeleteOperation] : props.operations;
});

const disableMenu = computed(() => {
  return batchDelete.size !== 0 && !isSelected.value;
});

const deleteSetItem = (() => {
  const target = toRaw(batchDelete);

  return (id: string) => {
    const deleteFn = target.delete;

    target.delete = Set.prototype.delete;
    batchDelete.delete(id);
    target.delete = deleteFn;
  };
})();

let popperRef: undefined | DropdownInstance["popperRef"];

function formatDuration(duration: number) {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${minutes | 0}:${(seconds | 0).toString().padStart(2, "0")}`;
}

function handleClickPoster(e: MouseEvent) {
  if (
    posterContainer.value !== e.target &&
    (e.target as HTMLElement).closest(".preview > .poster") === null
  ) return;

  props.handleClickPoster(e);
}

function handleCommand(operation: CardOperation) {
  operation.handler(props.info.id);
  collectData("boolvideo_space_click", {
    click: operation.text.toLowerCase(),
  });
  track("boolvideo_space_click");
}

function handleMenuVisibleChange(visible: boolean) {
  isFocusMenu.value = visible;
}

function handleContextMenu(e: PointerEvent) {
  if (
    popperRef === undefined ||
    (e.target as HTMLElement).closest(".video-info-card-dropdown") !== null ||
    disableMenu.value
  )
    return;

  isFocusMenu.value = true;

  const el = instance!.vnode.el as HTMLElement;
  const targetRect = el.getBoundingClientRect();
  const menu = el.querySelector(".video-info-card-dropdown") as HTMLElement;
  const contentRef = popperRef!.contentRef.contentRef;

  setTimeout(() => {
    const style = contentRef.contentStyle[1];
    const offsetX =
      e.clientX + menu.offsetWidth > innerWidth ? menu.offsetWidth + 4 : 0;
    const offsetY =
      e.clientY + menu.offsetHeight > innerHeight ? menu.offsetHeight : 0;

    style.top = `${e.clientY - targetRect.y - offsetY}px`;
    style.left = `${e.clientX - targetRect.x - offsetX}px`;
    style.right = "auto";
    style.bottom = "auto";
  }, 1);

  e.preventDefault();
  e.stopPropagation();
}

function handleSelect(e: MouseEvent) {
  if (
    batchDelete.size === 0 ||
    !canSelete.value ||
    (e.target as HTMLElement).closest(".video-info-card-dropdown") !== null
  )
    return;

  const id = props.info.id;
  batchDelete.has(id) ? deleteSetItem(id) : batchDelete.add(id);
  e.stopPropagation();
}

watch(isFocusMenu, (value) => {
  value ? dropdownRef.value.handleOpen() : dropdownRef.value.handleClose();
});

onMounted(() => {
  popperRef = dropdownRef.value?.popperRef;
});
</script>

<style lang="scss" scoped>
.card-item {
  position: relative;
  border-color: var(--card-border-color);
  border-radius: var(--card-border-radius);
  user-select: none;
  overflow: visible;
}

.preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f6;
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  overflow: hidden;
  // height: var(--card-poster-height);
  aspect-ratio: 1 / 1;
}

.video-duration {
  position: absolute;
  right: 14px;
  bottom: 14px;
  padding-inline: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
}

.card-footer {
  padding: 14px;
  border-top: 1px solid var(--card-border-color);
}

.video-name {
  margin-bottom: 6px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bottom-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  & > .el-dropdown {
    position: static;
  }
}

.update-time {
  font-size: 12px;
  color: #646a73;
}

.card-item:hover .more-icon,
.more-icon.active {
  opacity: 1;
}

.more-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 4px;
  outline: none;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: #eaeaea;
    border-radius: 2px;
  }
}

.video-info-card-menu {
  min-width: 150px;
  padding-block: 8px;

  & > :deep(li) {
    color: #1f2329;
    padding-inline: 12px;

    & > svg {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    & > span {
      font-size: 14px;
    }
  }
}

.poster {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.select-icon {
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 3;
  cursor: pointer;
}

.card-item[selected] {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: var(--card-border-radius);
    z-index: 2;
    cursor: pointer;
  }
}

:deep(.video-info-card-dropdown) {
  border: 1px solid var(--card-border-color);
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);

  & > .el-popper__arrow {
    display: none;
  }
}
</style>
