<template>
  <div class="card-list-container">
    <Transition>
      <div v-if="isLoading" class="loadingIcon">
        <img :src="loadingIcon" />
      </div>
    </Transition>
    <ul class="card-list" v-if="!isLoading">
      <li>
        <card-upload :listLength="brandList.length" @upload="add" />
      </li>
      <li v-for="(info, index) in displayList">
        <card-item
          :info="info"
          :index="index"
          @delete="remove"
          @default="setDefault"
          @toEdit="toEdit"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import CardItem from "./carditem.vue";
import CardUpload from "./cardupload.vue";
import loadingIcon from "@/assets/icons/space/icon_loading.svg";
import { defineProps } from "vue";
import { MutexSpace } from "@/utils";
import { useRouter } from "vue-router";
import { useBrandStore } from "../../stores/brand";
import { useTrackStore } from "@/store/modules/track";
import type { BrandInfo } from "../../type";

provide("mutexSpace", new MutexSpace());

defineProps({
  isLoading: Boolean,
});

const router = useRouter();
const { collectData, track } = useTrackStore();
const { brandList, addNewBrand, removeBrand, setDefaultBrand } =
  useBrandStore();

const sortfn = (a: BrandInfo, b: BrandInfo) => {
  if (a.createTime <= b.createTime) {
    if (b.defaultUse && !a.defaultUse) {
      return 1;
    } else {
      return -1;
    }
  } else {
    if (a.defaultUse && !b.defaultUse) {
      return -1;
    } else {
      return 1;
    }
  }
};

const displayList = computed(() => {
  return brandList.value.toSorted(sortfn);
});

const add = async (url: string) => {
  await addNewBrand(url);
  collectData("boolvideo_brandkit_click", {
    click: "add",
  });
  track("boolvideo_brandkit_click");
};

const remove = async (id: string) => {
  await removeBrand(id);
  collectData("boolvideo_brandkit_click", {
    click: "delete",
  });
  track("boolvideo_brandkit_click");
};

const setDefault = async (id: string) => {
  await setDefaultBrand(id);
};

const toEdit = (id: string) => {
  router.push({
    path: "/brand/edit",
    query: {
      bkId: id,
    },
  });
};
</script>

<style scoped lang="scss">
.card-list-container {
  position: relative;
  height: 100%;
  padding-block: 36px;
  padding-right: 10px;
}

.card-list {
  --card-list-row-gap: 38px;
  --card-list-col-gap: 36px;
  --card-border-radius: 4px;
  --card-poster-height: 250px;
  --card-border-color: #dee0e3;
}

.card-list {
  display: grid;
  position: relative;
  min-height: 328px;
  grid-gap: var(--card-list-row-gap) var(--card-list-col-gap);
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--card-poster-height), 1fr)
  );
}

.loadingIcon {
  position: absolute;
  width: 100%;
  inset: 1px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 1;

  & > img {
    animation: spin 0.4s linear infinite;
  }
}
</style>
