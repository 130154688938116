/**
 * return a debounced version of the function
 * @param fn
 * @param delay
 */
// eslint-disable-next-line no-unused-vars
export function debounce(
  fn: (...args: any[]) => unknown,
  delay: number,
): typeof fn {
  let timerId: ReturnType<typeof setTimeout> | null;
  return function (...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}
