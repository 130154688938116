import {
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  reactive,
  Ref,
  ref,
  watch,
  watchEffect,
} from "vue";
import { debounce } from "lodash";
import {
  ComputeTransformConfigConfig,
  GetValidTransformParametersConfig,
  ImageSources,
  InitMattingBaseConfig,
  InitMattingConfig,
  InitMattingResult,
  MattingProps,
  TransformParameters,
  TransformParametersConfig,
  UseInitListenersConfig,
  UseInitMattingBoardsConfig,
} from "../types/init-matting";
import { BoardRect, GapSize, RectSize, TransformConfig } from "../types/common";
import { GetImageSourceConfig } from "../types/dom";
import {
  InitHiddenBoardConfig,
  InitHiddenBoardWithImageConfig,
} from "../types/init-matting";
import {
  EventType,
  DEFAULT_IMAGE_SMOOTH_CHOICE,
  HIDDEN_BOARD_GAP_SIZE,
  HIDDEN_BOARD_MAX_SIZE,
  INITIAL_GAP_SIZE,
  INITIAL_SCALE_RATIO,
  INITIAL_TRANSFORM_CONFIG,
  UPDATE_BOARDRECT_DEBOUNCE_TIME,
} from "../helpers/constants";
import { fixed } from "../helpers/util";
import ListenerManager from "../helpers/listener-manager";
import { initDrawingListeners } from "../helpers/init-drawing-listener";
import {
  createContext2D,
  resizeCanvas,
  transformedDrawImage,
} from "../helpers/dom-helper";
import {
  InitMattingDragConfig,
  InitMattingScaleConfig,
} from "../types/transform";
import {
  redrawMattingBoardsWhileScaling,
  updateRangeByMovements,
} from "../helpers/transform-helper";

export function useMattingBoard(props: MattingProps) {
  const width = ref(0);
  const height = ref(0);
  const inputCtx: Ref<CanvasRenderingContext2D | null> = ref(null);
  const inputHiddenCtx = ref(createContext2D());
  const inputDrawingCtx: CanvasRenderingContext2D = createContext2D();
  const isDrawing = ref(false);
  const draggingInputBoard = ref(props.isDrag);
  const transformConfig: TransformConfig = reactive(INITIAL_TRANSFORM_CONFIG);
  const imageSources: Ref<ImageSources | null> = ref(null);
  const boardRect: Ref<BoardRect | null> = ref(null);
  const initialized = ref(false);
  const initMattingResult: Ref<InitMattingResult | null> = ref(null);
  const listenerManager = new ListenerManager();

  const initMattingConfig: InitMattingBaseConfig = {
    boardContexts: { inputCtx, inputDrawingCtx, inputHiddenCtx },
    initMattingResult,
    transformConfig,
    imageSources,
    initialized,
    boardRect,
  };

  const initListenersConfig = {
    ...initMattingConfig,
    draggingInputBoard,
    isDrawing,
    listenerManager,
  };

  useInitMattingBoards(props, { ...initMattingConfig, width, height });
  useInitDrawingListeners(props, initListenersConfig);
  // useInitTransformListener(initListenersConfig, props);
  return {
    width,
    height,
    inputCtx,
    inputHiddenCtx,
    draggingInputBoard,
    transformConfig,
    imageSources,
  };
}

function useInitMattingBoards(
  props: MattingProps,
  useInitMattingBoardsConfig: UseInitMattingBoardsConfig,
) {
  const { sessionId, rawImage, mattingImage } = props;
  const {
    width,
    height,
    boardRect,
    initialized,
    imageSources,
    transformConfig,
    initMattingResult,
    boardContexts,
    boardContexts: { inputCtx, inputHiddenCtx },
  } = useInitMattingBoardsConfig;
  const updateBoardRect = () => {
    boardRect.value = computeBoardRect(
      (inputCtx.value as CanvasRenderingContext2D).canvas,
    );
  };
  const resizeBoards = () => {
    requestAnimationFrame(() => {
      const commonConfig = {
        targetHeight: height.value,
        targetWidth: width.value,
        transformConfig,
      };
      resizeCanvas({
        ctx: inputCtx.value as CanvasRenderingContext2D,
        hiddenCtx: inputHiddenCtx.value,
        ...commonConfig,
      });
    });
  };
  watchEffect(async () => {
    if (
      sessionId.value &&
      rawImage.value &&
      mattingImage.value &&
      width.value &&
      height.value
    ) {
      initialized.value = false;
      initMattingResult.value = await initMatting({
        boardContexts,
        rawImage: rawImage.value,
        mattingImage: mattingImage.value,
        targetSize: { width: width.value, height: height.value },
        gapSize: { horizontal: 0, vertical: 0 },
        transformConfig: {},
      });
      const { rawSource, mattingSource, positionRange, scaleRatio } =
        initMattingResult.value;
      transformConfig.positionRange = positionRange;
      transformConfig.scaleRatio = scaleRatio;
      imageSources.value = { raw: rawSource, matting: mattingSource };
      updateBoardRect();
      resizeBoards();
      initialized.value = true;
    }
  });
  onMounted(() => {
    window.addEventListener(EventType.Resize, resizeBoards);
    window.addEventListener(
      EventType.Scroll,
      debounce(updateBoardRect, UPDATE_BOARDRECT_DEBOUNCE_TIME),
    );
  });
  onUnmounted(() => {
    window.removeEventListener(EventType.Resize, resizeBoards);
  });
}

function useInitDrawingListeners(
  props: MattingProps,
  config: UseInitListenersConfig,
) {
  const { radius, hardness, isErasing, zoomRatio, drawingEndCallback } = props;

  const {
    boardContexts,
    transformConfig,
    imageSources,
    draggingInputBoard,
    initialized,
    boardRect,
    listenerManager,
  } = config;

  const { inputCtx } = boardContexts;

  watchEffect(() => {
    if (initialized.value) {
      initDrawingListeners({
        drawingEndCallback,
        listenerManager,
        imageSources: imageSources.value,
        boardContexts,
        initDrawingConfig: { radius, hardness, zoomRatio, transformConfig },
        isErasing: isErasing.value,
        draggingInputBoard: draggingInputBoard.value,
        boardRect: boardRect.value as BoardRect,
      });
    }
  });
  onBeforeUnmount(() => {
    listenerManager.removeMouseListeners(
      (inputCtx.value as CanvasRenderingContext2D).canvas,
    );
  });
}

export function useInitTransformListener(
  config: UseInitListenersConfig,
  props: any,
) {
  const {
    boardContexts,
    initialized,
    draggingInputBoard,
    transformConfig,
    isDrawing,
    listenerManager,
  } = config;
  const { inputCtx, inputHiddenCtx } = boardContexts;
  watch(
    [initialized, draggingInputBoard, isDrawing],
    () => {
      if (initialized.value && !isDrawing.value) {
        const initConfig = {
          inputContexts: {
            ctx: inputCtx.value as CanvasRenderingContext2D,
            hiddenCtx: inputHiddenCtx.value,
          },
          draggingInputBoard: draggingInputBoard.value,
          listenerManager,
          transformConfig,
        };
        initDragListener(initConfig);
        initScaleListener(initConfig);
        // 触发重新绑定绘制监听器,必须输入画板拖动结束时才能重新绑定，否则绘制监听器会覆盖拖动监听器
        if (!draggingInputBoard.value) {
          transformConfig.positionRange = { ...transformConfig.positionRange };
        }
      }
    },
    { deep: true },
  );
  watch([transformConfig], async () => {
    if (initialized.value) {
      const { positionRange, scaleRatio } = transformConfig;
      const commonConfig = { positionRange, scaleRatio };
      transformedDrawImage({
        ctx: inputCtx.value as CanvasRenderingContext2D,
        hiddenCtx: inputHiddenCtx.value,
        ...commonConfig,
      });
    }
  });
  onBeforeUnmount(() => {
    if (initialized.value) {
      listenerManager.removeMouseListeners(
        (inputCtx.value as CanvasRenderingContext2D).canvas,
      );
      listenerManager.removeWheelListeners();
    }
  });
}

async function initMatting(
  initMattingConfig: InitMattingConfig,
): Promise<InitMattingResult> {
  const {
    boardContexts: { inputCtx, inputHiddenCtx, inputDrawingCtx },
    rawImage,
    mattingImage,
    transformConfig,
    targetSize,
    gapSize,
  } = initMattingConfig;
  (inputCtx.value as CanvasRenderingContext2D).imageSmoothingEnabled =
    DEFAULT_IMAGE_SMOOTH_CHOICE;
  const rawSource = await createImageBitmap(rawImage);
  const mattingSource = await createImageBitmap(mattingImage);
  const { scaleRatio, positionRange } = getValidTransformConfig({
    imageSource: mattingSource,
    transformConfig,
    targetSize,
    gapSize,
  });
  const validImageSize = computeValidImageSize(
    mattingSource,
    HIDDEN_BOARD_MAX_SIZE,
    HIDDEN_BOARD_GAP_SIZE,
  );
  initHiddenBoardWithSource({
    imageSource: mattingSource,
    targetSize: validImageSize,
    hiddenCtx: inputHiddenCtx.value,
    drawingCtx: inputDrawingCtx,
  });
  transformedDrawImage({
    hiddenCtx: inputHiddenCtx.value,
    ctx: inputCtx.value as CanvasRenderingContext2D,
    scaleRatio,
    positionRange,
  });

  return { rawSource, mattingSource, positionRange, scaleRatio };
}

function initHiddenBoardWithSource(initConfig: InitHiddenBoardWithImageConfig) {
  initHiddenBoard(initConfig);
  const {
    hiddenCtx: ctx,
    imageSource,
    targetSize: { width, height },
  } = initConfig;
  return getImageSourceFromCtx({ ctx, imageSource, width, height });
}

/** 初始化隐藏的绘制画板和成果图画板 */
function initHiddenBoard(initConfig: InitHiddenBoardConfig): void {
  const { targetSize, hiddenCtx, drawingCtx } = initConfig;
  const { width, height } = targetSize;
  hiddenCtx.canvas.width = width;
  hiddenCtx.canvas.height = height;
  drawingCtx.canvas.width = width;
  drawingCtx.canvas.height = height;
}

/** 获取有效的变换配置 */
function getValidTransformConfig(
  getParametersConfig: GetValidTransformParametersConfig,
): TransformConfig {
  const { transformConfig, ...computeConfig } = getParametersConfig;
  if (isInvalidTransformConfig(transformConfig)) {
    const { scaleRatio, positionRange } = computeTransformConfig(computeConfig);
    transformConfig.scaleRatio = scaleRatio;
    transformConfig.positionRange = positionRange;
  }
  return transformConfig as TransformConfig;
}

/** 判断变换配置是否无效 */
function isInvalidTransformConfig(transformConfig: Partial<TransformConfig>) {
  const { scaleRatio, positionRange } = transformConfig;
  return !scaleRatio || !positionRange;
}

/** 计算画板的变换配置对象 */
function computeTransformConfig(
  computeConfig: ComputeTransformConfigConfig,
): TransformConfig {
  const { imageSource, targetSize, gapSize = INITIAL_GAP_SIZE } = computeConfig;
  const imageSize = computeValidImageSize(
    imageSource,
    HIDDEN_BOARD_MAX_SIZE,
    HIDDEN_BOARD_GAP_SIZE,
  );
  return computeTransformParameters({
    gapSize,
    imageSize,
    targetSize,
  });
}

/** 计算合法的图片尺寸(低于指定分辨率的尺寸) */
export function computeValidImageSize(
  imageSource: ImageBitmap | ImageData,
  targetSize: RectSize,
  gapSize: GapSize,
): any {
  let { width, height } = imageSource;
  const imageScaleRatio = computeScaleRatio({
    imageSize: { width, height },
    gapSize,
    targetSize,
  });
  width *= imageScaleRatio;
  height *= imageScaleRatio;
  return { width, height, imageScaleRatio };
}

/** 计算自适应缩放比例 */
export function computeScaleRatio(
  transformParametersConfig: TransformParametersConfig,
): number {
  const { imageSize, gapSize, targetSize } = transformParametersConfig;
  const drawingAreaSize = getDrawingAreaSize(targetSize, gapSize);
  return Math.min(
    drawingAreaSize.width / imageSize.width,
    drawingAreaSize.height / imageSize.height,
  );
}

/** 计算自适应变换(缩放、平移)参数 */
function computeTransformParameters(
  transformParametersConfig: TransformParametersConfig,
): TransformParameters {
  const scaleRatio = computeScaleRatio(transformParametersConfig);
  const positionRange = computePositionRange(
    transformParametersConfig,
    scaleRatio,
  );
  return { scaleRatio, positionRange };
}

/** 计算自适应变换后的绘制区域 */
function computePositionRange(
  transformParametersConfig: TransformParametersConfig,
  scaleRatio: number,
) {
  const scaledImageSize = computeScaledImageSize(
    transformParametersConfig.imageSize,
    scaleRatio,
  );
  return {
    minX: getPositionRangeMinX(transformParametersConfig, scaledImageSize),
    maxX: getPositionRangeMaxX(transformParametersConfig, scaledImageSize),
    minY: getPositionRangeMinY(transformParametersConfig, scaledImageSize),
    maxY: getPositionRangeMaxY(transformParametersConfig, scaledImageSize),
  };
}

/** 获取图片缩放到画框区域内的实际尺寸 */
function computeScaledImageSize(
  imageSize: RectSize,
  scaleRatio: number,
): RectSize {
  return {
    width: imageSize.width * scaleRatio,
    height: imageSize.height * scaleRatio,
  };
}

/** 计算画板的左上角坐标及宽高 */
function computeBoardRect(canvas: HTMLCanvasElement): BoardRect {
  const inputBoardRect: DOMRect = canvas.getBoundingClientRect();
  const domRect: DOMRect = document.documentElement.getBoundingClientRect();
  return computeBoardRectSize(inputBoardRect, domRect);
}

function computeBoardRectSize(inputBoardRect: DOMRect, domRect: DOMRect) {
  const { width, height, left: boardLeft, top: boardTop } = inputBoardRect;
  const { left: domLeft, top: domTop } = domRect;
  const left = boardLeft - domLeft;
  const top = boardTop - domTop;
  return { left, top, width, height };
}

/** 计算绘制区域范围最小x坐标(相对于画布左上角) */
function getPositionRangeMinX(
  transformParametersConfig: TransformParametersConfig,
  scaledImageSize: RectSize,
) {
  const { gapSize, targetSize } = transformParametersConfig;
  return (
    fixed(
      (getDrawingAreaSize(targetSize, gapSize).width - scaledImageSize.width) /
        2,
    ) + gapSize.horizontal
  );
}

function getPositionRangeMinY(
  transformParametersConfig: TransformParametersConfig,
  scaledImageSize: RectSize,
) {
  const { gapSize, targetSize } = transformParametersConfig;
  return (
    fixed(
      (getDrawingAreaSize(targetSize, gapSize).height -
        scaledImageSize.height) /
        2,
    ) + gapSize.vertical
  );
}

function getPositionRangeMaxX(
  transformParametersConfig: TransformParametersConfig,
  scaledImageSize: RectSize,
) {
  return fixed(
    getPositionRangeMinX(transformParametersConfig, scaledImageSize) +
      scaledImageSize.width,
  );
}

function getPositionRangeMaxY(
  transformParametersConfig: TransformParametersConfig,
  scaledImageSize: RectSize,
) {
  return fixed(
    getPositionRangeMinY(transformParametersConfig, scaledImageSize) +
      scaledImageSize.height,
  );
}

/** 默认最大绘制区的尺寸(即画框尺寸减去间隙) */
function getDrawingAreaSize(boardSize: RectSize, gapSize: GapSize): RectSize {
  return {
    width: boardSize.width - gapSize.horizontal * 2,
    height: boardSize.height - gapSize.vertical * 2,
  };
}

/** 获取画布全屏绘制后的图像 */
function getImageSourceFromCtx(config: GetImageSourceConfig) {
  const { ctx, imageSource, width, height } = config;
  ctx.drawImage(imageSource, 0, 0, width, height);
  return createImageBitmap(ctx.canvas);
}

/** 初始化画板变换的监听器 */
export function initDragListener(
  mattingTransformConfig: InitMattingDragConfig,
) {
  const {
    inputContexts: { ctx: inputCtx2D },
    transformConfig,
    listenerManager,
  } = mattingTransformConfig;
  listenerManager.initMouseListeners({
    mouseTarget: inputCtx2D.canvas,
    move(ev) {
      const { positionRange } = transformConfig;
      updateRangeByMovements(ev, positionRange);
    },
  });
}

/** 初始化缩放监听器 */
export function initScaleListener(
  mattingTransformConfig: InitMattingScaleConfig,
): VoidFunction {
  const {
    inputContexts: { ctx: inputCtx },
    listenerManager,
  } = mattingTransformConfig;
  return listenerManager.initWheelListener({
    mattingBoards: [inputCtx.canvas],
    wheel(ev) {
      redrawMattingBoardsWhileScaling(ev, mattingTransformConfig);
    },
  });
}
