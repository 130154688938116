<template>
  <svg-icon
    name="icon_empty_color"
    :style="{ width: size + 'px', height: size + 'px', color: 'red' }"
    v-if="!value"
  ></svg-icon>
  <canvas
    class="color-item cursor-pointer isPick"
    :style="colorItemStyle"
    ref="canvas"
    :draggable="draggable"
    v-else
  />
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  onMounted,
  computed,
  inject,
  unref,
} from "vue";
import type { PropType } from "vue";
import type { Format } from "../constant";
export default defineComponent({
  name: "ColorItem",
  props: {
    size: {
      type: Number,
      default: 24,
    },
    value: {
      type: String,
      default: "",
    },
    format: {
      type: String as PropType<Format>,
      default: "hex",
    },
    border: {
      type: Boolean,
      default: true,
    },
    borderRadius: {
      type: Number,
      default: 5,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select"],
  setup(props) {
    const canvas = ref<HTMLCanvasElement>();
    const colorItemStyle = computed(() => ({
      width: `${props.size}px`,
      height: `${props.size}px`,
      border: props.border ? `1px solid #DEE0E3` : "",
      borderRadius: `${props.borderRadius}px`,
    }));
    const renderColor = () => {
      const ctx = canvas.value?.getContext("2d");
      if (!ctx || !canvas.value) {
        return;
      }
      canvas.value.width = props.size;
      canvas.value.height = props.size;
      ctx.fillStyle = props.value;
      ctx.fillRect(0, 0, props.size, props.size);
    };
    watch(
      () => props.value,
      (value) => {
        renderColor();
      },
      {
        flush: "post",
      }
    );
    onMounted(() => {
      renderColor();
    });
    return {
      canvas,
      colorItemStyle,
    };
  },
});
</script>

<style scoped lang="scss">
.color-item {
  display: inline-block;
  vertical-align: top;
}
</style>
