<template>
  <span class="tag-container" :style="activeStyle">
    {{ text || "text" }}
  </span>
</template>
<script setup>
import { watch } from 'vue'
const props = defineProps({
  text: String,
  active: Boolean,
  bgColor: String
});

const activeStyle  = ref(null)

watch(() => [props.active, props.bgColor], () => {
  if(!props.active) {
    activeStyle.value = null
    return
  }

  if(!props.bgColor) {
      activeStyle.value = {
      background: '#875eff',
      color: '#fff',
    }
  }

  activeStyle.value = {
    background: props.bgColor,
    color: '#fff',
  }
}, {
  immediate: true
})

</script>
<style lang="scss" scoped>
.tag-container {
  padding: 4px 12px;
  background: #ebedef;
  border-radius: 2px;
  margin-right: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: inline-block;
  overflow: hidden;
  /* 超出部分隐藏 */
  white-space: nowrap;
  /* 不换行 */
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
}

// .active-tag {
//   background: #875eff;
//   color: #fff;
// }
</style>
