function removeExistingScript(src) {
  const scripts = document.getElementsByTagName('script');

  for (let i = scripts.length - 1; i >= 0; i--) {
    if (scripts[i].src === src) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
  }
}

const precisoTrackEvent = (data: Object) => {
  const scriptSrc = 'https://cdn.preciso.net/aud/clientjs/ptag.js?7492';
  removeExistingScript('scriptSrc');

  window.data_7492 = [];
  const defaultData = {
    gdpr: 0,
    gdpr_consent: '${GDPR_CONSENT_874}',
    gdpr_pd: 0,
    us_privacy: '',
  };

  const obj = { ...defaultData, ...data };
  window.data_7492.push(obj);

  (function (d) {
    var s = d.createElement('script');
    s.async = true;
    s.id = 'DLFNPMMP1';
    s.type = 'text/javascript';
    s.src = scriptSrc;
    var a = d.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(s, a);
  })(document);
};

export default precisoTrackEvent;
