import { createImageFromSprite } from "@/utils";
import avatarSpriteUrl from './assets/avatar_sprite.webp';

const avatarSprite = new Image();
avatarSprite.src = avatarSpriteUrl;

export interface CommentQuote {
  key: number;
  avatar: ReturnType<typeof createImageFromSprite>,
  name: string;
  office: string;
  comment: string;
};

export default [
  {
    key: 0,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(0, 0, 108, 108)),
    name: 'Sandra Alicia M.',
    office: 'Writer',
    comment: `
      "A good program when you are looking to get videos quickly.

      My experience is very good and I will continue to use this program because it has gotten me out of a lot of trouble. Thanks to it I can create videos for social networks quickly.
      
      It is an application that I have used to my satisfaction. The use I give it is to create videos quickly. These videos are perfect for use on social networks. In particular, I really like to use them in Instagram Stories and Reels. One feature I love about this program is that with it you get good results with almost no video editing. It's very useful when you're busy and need to have a quick video. The animations that the program generates with the images are very nice and suitable for my use.”
    `,
  },
  {
    key: 1,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 1, 0, 108, 108)),
    name: 'Max N.',
    office: 'CMO',
    comment: `
      "There's not really any tool better than this out there...

      It's the phenomenal job by somebody that has assessed real pain points

      All the tools, convenient to use without using a suite like Adobe, or shuffling through different software suites for the use cases one definitely may face when getting your brand image out there.”
    `,
  },
  {
    key: 2,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 2, 0, 108, 108)),
    name: 'Verified Reviewer',
    office: 'Social Media Manager',
    comment: `
      "User-Friendly Video Editing Software for Beginners.

      I used Boolvideo for my basic reels and posts; it was easy to use, and there are options for stickers, voiceovers, filters, and music. However, if you need more robust editing software, then this may not be your choice. It is pretty good for those who are just starting out with video creation.
      
      Super user-friendly. Even if you're tech-challenged, you'll get the hang of it. It also has a lot of editing options, which is always great for video editing software.”
    `,
  },
  {
    key: 3,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 3, 0, 108, 108)),
    name: 'Maria',
    office: 'Marketing, Retail, E-commerce',
    comment: `
      "It's good. Boolv helped me create a reel to show our top products of this summer. I create videos more quickly with it."
    `,
  },
  {
    key: 4,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 4, 0, 108, 108)),
    name: 'Robert R.',
    office: 'Media Production owner',
    comment: `
      "When Video Creation Is Made Easy.

      Overall, this has been a great learning experience and they seem to constantly improve their offering. service and features. We can create a host of marketing videos, educational, sales, and promos, and the software can customize the video for the various social media formats with ease.

      Boolvideo has a tremendous amount of templates which makes getting a video created much faster and allows for various iterations without wasting time or overthinking. As an AI-curious consumer and video marketer, Boolvideo is allowing me to use AI in my video creations and have some very interesting and persuasive content.”
    `,
  },
  {
    key: 5,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 5, 0, 108, 108)),
    name: 'Shaira',
    office: 'Social Media Manager, Retail E-commerce',
    comment: `
      "I give it 5 stars! As a social media manager, I create daily social media content for brands. Made video editing easy for short-form videos by Boolv."
    `,
  },
  {
    key: 6,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 6, 0, 108, 108)),
    name: 'Anonymous',
    office: 'Writer, teacher',
    comment: `
      "I wish to post products for sale on Instagram. I will give them a picture of my product and Boolv will make an eye-catching display for it. This would be amazing. 

      As a retired English teacher aged 80, I love to write and create digital products. Unfortunately, I am an idiot when it comes to promotion. I have a course for teachers I never promoted, I have several ebooks with one for couples on how they can enhance their relationship. Boolv gives me hope!"
    `,
  },
  {
    key: 7,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 7, 0, 108, 108)),
    name: 'William',
    office: 'Owner & CEO, E-commerce and Online Retail',
    comment: `
      "We do love Boolv. 

      It is an ingenious piece of software and technology and we are extremely grateful for minds like Boolv."
    `,
  },
  {
    key: 8,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 8, 0, 108, 108)),
    name: 'Fay',
    office: 'Social Media Manager',
    comment: `
      "Impressed by this amazing tool"
      I recently discovered this AI video tool and I am impressed. 

      I explain : It helped me create high quality videos efficiently. I could generate a multiple range of high quality videos with just one click, and the product image and information extraction feature was a game changer.

      There are some interesting AI features like background remover feature or image enhancer feature. It allowed me to create custom compositions that showcased some products in a captivating way.
      What I loved most was Boolvideo’s team involvement and their adherence to the roadmap.”
    `,
  },
  {
    key: 9,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 9, 0, 108, 108)),
    name: 'Anonymous',
    office: 'Self-employed Musician',
    comment: `
      "A liter among artificial intelligence software for any business.

      I like the UI / UX or graphical user interface of the app I also like how easy it is for a newcomer or a new user to figure out how to use the software it was pretty easy to acclimate myself to it and I think anyone even someone without technological knowledge could probably pick it up pretty quickly."
    `,
  },
  {
    key: 10,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 10, 0, 108, 108)),
    name: 'Godwin O',
    office: 'Content Director',
    comment: `
      "Brilliant Experience.

      The simplicity and ease of use, with a minimal learning curve."
    `,
  },
  {
    key: 11,
    avatar: createImageFromSprite(avatarSprite, new DOMRect(110 * 11, 0, 108, 108)),
    name: 'Vinz',
    office: 'Digital Marketer, Marketing & Advertising',
    comment: `
      "BOOLV-New Revolutionary AI Tool for Boost Marketing.

      Easily Generate videos with it very great artificial intelligence ( AI ) capability."
    `,
  },
] as CommentQuote[];
