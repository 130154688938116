<template>
  <form-item label="Language">
    <div class="select-language-container">
      <bv-select :showBoxShadow="true" :showBorder="false" v-model="language" popper-class="idea-language-select" searchPlaceholder="Search" :showSearch="true"
        :teleported="false" :options="languageList" :popperStyle="{ width: '100%' }"
        @change="handleChangeLanguage" :popperOptions="{
            modifiers: [{ name: 'offset', options: { offset: [-5, 4] } }],
          }">

        <template #suffix>
          <SelectSuffix />
        </template>
      </bv-select>
    </div>

  </form-item>
</template>

<script setup>
import { ref, watch, onBeforeMount } from 'vue'
import FormItem from './form-item.vue'
import SelectSuffix from "@/components/common/selectSuffix.vue";
import { getLanguageList } from "@/api/index";

const props = defineProps({
  value: String,
})

const emit = defineEmits(['update:value'])

const language = ref('')
const languageList = ref([])

const getLanguageData = async () => {
  const languageHelper = (list) => {
    let temp = [];
    list.forEach(item => {
      for (const i of item.localInfoList) {
        const newItem = {
          label: i.localName,
          value: i.locale,
        };
        temp.push(newItem);
      }
    });
    return temp;
  };

  const { success, data } = await getLanguageList({ filterSpecial: true });
  if (!success) return;

  languageList.value = languageHelper(data);
  language.value = "en-US";
};


const handleChangeLanguage = (value) => {
  language.value = value;
  emit("update:value", value);
}

onBeforeMount(() => {
  getLanguageData()
})
</script>


<style lang="scss">
.select-language-container {
  width: 100%;
  height: 46px;
  position: relative;

  .select-wapper {
    box-shadow: 0 0 0 1px #E5E7EB inset !important;
    padding: 12px 8px 12px 21px !important;

    &:hover {
      box-shadow: 0 0 0 1px #be9fff inset !important;
    }
  }

  .select-content span {
    color: #060606 !important;
    font-size: 14px !important;
  }

  .el-popper {
    width: 100%;
    translate: 0px ;
    left: -5px !important;
  }

  .search-empty {
    padding: 20px;
  }


  .bv-select-button .select-wapper.show-box-shadow.is-focus {
    box-shadow: 0 0 0 1px #875eff inset !important;
  }

  .bv-select-button .select-wapper.show-box-shadow {
    border-radius: 6px;
  }
}

</style>