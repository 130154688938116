<script setup>
import Material from "./material.vue";
import ProjectMaterial from "./projectmaterial.vue";
import CloudMaterial from "./cloudmaterial.vue";
import { useCreatorStore } from "../../stores";

const { mediaTab } = useCreatorStore();
</script>
<template>
  <Material>
    <el-tabs v-model="mediaTab">
      <el-tab-pane label="Project" name="project" lazy>
        <project-material />
      </el-tab-pane>
      <el-tab-pane label="Cloud" name="cloud" lazy>
        <cloud-material />
      </el-tab-pane>
    </el-tabs>
  </Material>
</template>
<style scoped>
:deep(.el-progress) {
  width: 66px;
  height: 3px;
}
:deep(.el-progress-bar__outer) {
  height: 3px !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
}
:deep(.el-progress-bar__inner) {
  background-color: #ffffff;
  border-radius: 0;
}
</style>
