<template>
  <div class="bar-container flex items-center">
    <div
      class="brush-area"
      v-if="
        'brushLabel' in $slots || currentConfig.erase || currentConfig.restore
      "
    >
      <slot name="brushLabel" v-if="'brushLabel' in $slots"></slot>
      <div class="erase-restore-container flex items-center bar-item" v-else>
        <div
          class="switch-button"
          @click="handleEraseRestore('erase')"
          :class="{
            'switch-button-active': isErase,
            'switch-button-disable': disable,
          }"
        >
          Erase
        </div>
        <div
          class="switch-button"
          @click="handleEraseRestore('restore')"
          :class="{
            'switch-button-active': !isErase,
            'switch-button-disable': disable,
          }"
        >
          Restore
        </div>
      </div>

      <bv-slider
        class="slider bar-item"
        :colors="disable ? { main: '#BBBFC4', track: '#BBBFC4' } : {}"
        :model-value="brushSize"
        :min="1"
        :max="50"
        :step="1"
        :show-tooltip="false"
        @input="handleBrushSize"
        @change="$emit('onBrushSizeChange', $event)"
        v-if="currentConfig.slider"
      />

      <div
        class="divider mr-18"
        v-if="
          (currentConfig.erase || currentConfig.restore) && currentConfig.slider
        "
      ></div>
    </div>

    <!-- reset -->
    <el-tooltip placement="top" effect="dark" v-if="currentConfig.reset">
      <template #content> <span class="text-xs">Zoom Reset</span> </template>
      <div
        class="hover-box mr-18"
        @click="handleReset"
        :class="[disable ? 'disable' : '']"
      >
        <svg-icon name="icon_tool_reset"></svg-icon>
      </div>
    </el-tooltip>

    <!-- drag -->
    <div
      v-if="currentConfig.drag"
      :class="[
        'hover-box mr-18',
        active & codeMap.drag || isDrag ? 'active' : '',
        disable ? 'disable' : '',
      ]"
      @click="handleDrag"
    >
      <svg-icon name="icon_tool_drag"></svg-icon>
    </div>
    <!-- zoom out -->
    <div
      v-if="currentConfig.zoomout"
      class="hover-box mr-18"
      @click="handleScale(0.8)"
      :class="[disable ? 'disable' : '']"
    >
      <svg-icon name="icon_tool_zoomout"></svg-icon>
    </div>
    <!-- scale -->
    <div
      v-if="currentConfig.scale"
      class="hover-box mr-18"
      @click="handleScale(1.25)"
      :class="[disable ? 'disable' : '']"
    >
      <svg-icon name="icon_tool_scale"></svg-icon>
    </div>

    <!-- compare -->
    <div
      v-if="currentConfig.compare"
      :class="[
        'hover-box mr-18',
        active & codeMap.compare ? 'active' : '',
        disable ? 'disable' : '',
      ]"
      @mousedown="handleCompare"
    >
      <svg-icon name="icon_tool_compare"></svg-icon>
    </div>

    <!-- back -->
    <el-tooltip v-if="currentConfig.step" placement="top">
      <template #content> <span class="text-xs">Undo (Ctrl+Z)</span> </template>
      <div
        class="hover-box mr-18"
        :class="[disable || undoDisable ? 'disable' : '']"
        @click="handleBack"
      >
        <svg-icon name="icon_tool_back"></svg-icon>
      </div>
    </el-tooltip>

    <!-- pre -->
    <el-tooltip v-if="currentConfig.step" placement="top">
      <template #content>
        <span class="text-xs">Redo (Ctrl+Shift+Z)</span>
      </template>
      <div
        class="hover-box mr-18"
        :class="[disable || redoDisable ? 'disable' : '']"
        @click="handlePre"
      >
        <svg-icon name="icon_tool_pre"></svg-icon>
      </div>
    </el-tooltip>

    <template v-if="currentConfig.submit">
      <div class="divider bar-item"></div>
      <!-- Save -->
      <div
        class="save-button"
        :class="[
          disable ? 'disable' : '',
          loading ? 'pointer-events-none' : '',
        ]"
        @click="handleCom"
      >
        <svg-icon
          v-if="loading"
          name="icon_loading"
          :style="{ width: '18px', height: '18px', color: '#fff' }"
          class="animate-spin mr-2"
        ></svg-icon>
        {{ buttonText }}
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { useShortcutKeyManager, ShortcutKey, keyMap } from "@/utils/dom";

const codeMap = {
  drag: 1 << 0,
  compare: 1 << 1,
};

const emits = defineEmits([
  "onEraseOrRestore",
  "onBrushSize",
  "onReset",
  "onDrag",
  "onScale",
  "onCompare",
  "onBack",
  "onPre",
  "onSubmit",
  "onApply",
  "onBrushSizeChange",
]);
const props = defineProps({
  config: Object,
  brushMoveList: Array,
  disable: Boolean,
  undoDisable: Boolean,
  redoDisable: Boolean,
  loading: Boolean,
  buttonText: {
    type: String,
    default: "Save",
  },
  active: {
    type: Number,
    default: 0,
  },
});

const defaultConfig = reactive({
  erase: true,
  restore: true,
  slider: true,
  reset: true,
  drag: true,
  zoomout: false,
  scale: true,
  compare: true,
  step: true,
  submit: true,
});

const shortcutKeyManager = useShortcutKeyManager();
const isErase = ref(true);
const brushSize = ref(5);
const currentScale = ref(1);
const isDrag = ref(false);
const currentConfig = computed(() => {
  return {
    ...defaultConfig,
    ...props.config,
  };
});

const handleEraseRestore = (type) => {
  if (type === "erase") {
    isErase.value = true;
  }

  if (type === "restore") {
    isErase.value = false;
  }
  isDrag.value = false;
  emits("onIsErasing", isErase.value);
};

const handleBrushSize = (size) => {
  brushSize.value = size;
  emits("onBrushSize", size);
};

const handleReset = () => {
  currentScale.value = 1;
  emits("onReset");
};

const handleDrag = () => {
  isDrag.value = !isDrag.value;
  emits("onDrag", isDrag.value);
};

const handleScale = (delta) => {
  currentScale.value = Math.max(0.5, Math.min(4, currentScale.value * delta));
  emits("onScale", currentScale.value);
};

const handleCompare = (e) => {
  if (e.button !== 0) return;

  emits("onCompare", true);
  window.addEventListener("mouseup", () => emits("onCompare", false), {
    once: true,
  });
};

const handleBack = () => {
  emits("onBack");
};

const handlePre = () => {
  emits("onPre");
};

const handleCom = () => {
  if (props.buttonText == "Apply") {
    emits("onApply");
  } else {
    emits("onSubmit");
  }
};

shortcutKeyManager.register(new ShortcutKey(keyMap.KeyZ, 0b100), (e) => {
  if (e.type !== "keyup") return;
  !props.disable && !props.undoDisable && handleBack();
});

shortcutKeyManager.register(new ShortcutKey(keyMap.KeyZ, 0b101), (e) => {
  if (e.type !== "keyup") return;
  !props.disable && !props.redoDisable && handlePre();
});

defineExpose({
  codeMap,
  data: {
    currentScale,
    brushSize,
  },
});
</script>
<style lang="scss" scoped>
:deep(.el-slider__bar) {
  border-radius: 10px;
  height: 3px;
}

:deep(.bar-item .el-slider__runway) {
  border-radius: 10px;
  height: 3px;
}

:deep(.bar-item .el-slider__button) {
  width: 16px;
  border-width: 1.5px;
}

:deep(.bar-item .bv-slider) {
  --el-slider-button-wrapper-size: 6px;
}

.slider-disable {
  :deep(.bar-item .el-slider__bar) {
    background-color: #bbbfc4;
  }
}

.bar-container {
  max-width: fit-content;
  height: 114px;
  background-color: #fff;
  border: 1px solid #e8e9ec;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  padding: 30px 32px;
}

.erase-restore-container {
  width: 186px;
  height: 54px;
  padding: 3px;
  border-radius: 500px;
  border: 1px solid #e8e9ec;
  background: #fff;
}

.switch-button {
  width: 90px;
  height: 48px;
  border-radius: 500px;
  color: #262626;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  cursor: pointer;
}

.switch-button-active {
  background: #262626;
  color: #fff;
}

.switch-button-disable {
  color: #bbbfc4;
}

.switch-button-active.switch-button-disable {
  background: #bbbfc4;
  color: #fff;
}

.slider {
  width: 160px;
}

.bar-item {
  margin-right: 30px;
}

.mr-18 {
  margin-right: 18px;
}

.divider {
  width: 2px;
  height: 47px;
  background: #e8e9ec;
}

.hover-box {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover,
  &.active {
    background: #ededed;
  }

  & > svg {
    width: 24px;
    height: 24px;
  }

  &.disable {
    color: #bbbfc4;
    pointer-events: none;
  }
}

.save-button {
  width: 120px;
  height: 100%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  color: #fff;
  cursor: pointer;

  &:hover {
    background: linear-gradient(289deg, #6f46f4 8.11%, #957aec 99.95%);
  }
}

.save-button.disable {
  background: #bbbfc4;
  opacity: 0.9;
  color: #fff;
  pointer-events: none;
}

.brush-area {
  display: flex;
  align-items: center;
}
</style>
