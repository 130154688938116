<template>
  <div
    class="h-full w-full relative preview-render-box"
    @mousemove="handleShowActionBar"
    @mouseleave="handleHiddenActionBar"
    @click="handlePause"
  >
    <bv-player
      ref="playerRef"
      :src="src"
      :loop="loop"
      :autoplay="autoplay"
      :editAssets="editAssets"
      :showBorder="showBorder"
      @onReady="handleAnimReady"
      :style="{ 'border-radius': '8px' }"
    />

    <div
      v-if="!isStop && showRange"
      className="rang-mask absolute bottom-0 left-0"
      :style="rangeMaskStyle"
      @click.stop
    ></div>
    <div
      v-if="!isStop && showRange"
      className="rang-box absolute bottom-0 pl-4 pr-4 text-white"
      :style="rangeMaskStyle"
      @click.stop
    >
      <div class="h-full w-full flex flex-col" :style="{ paddingTop: '14px' }">
        <div :style="{ marginBottom: '10px' }">
          <bv-slider
            v-model="currentFrame"
            :show-tooltip="false"
            :max="totalFrames"
            :min="0"
            :colors="{
              main: '#fff',
              runway: 'rgba(255, 255, 255, 0.30)',
              track: '#fff',
              stop: '#535558',
              tooltip: '#1F2329',
            }"
            @input.stop="handleChangeFrame"
          />
        </div>

        <div class="flex justify-between">
          <div class="flex">
            <div class="cursor-pointer" @click.stop="handlePlay">
              <svg-icon
                :name="isPlay ? 'icon_edit_pause' : 'icon_edit_play'"
                :style="{ width: '24px', height: '24px', marginLeft: '-6px' }"
              />
            </div>
            <div class="px-3">{{ currentTime }} / {{ totalTime }}</div>
          </div>

          <div class="ml-3 cursor-pointer" @click.stop="handleVolume">
            <svg-icon
              :name="isMuted ? 'icon_edit_mute' : 'icon_edit_unmute'"
              :style="{ width: '20px', height: '20px', marginRight: '-3px' }"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="absolute bottom-5 left-5 px-3 py-1 text-white text-xs font-medium rounded-xl"
      style="background-color: rgba(0, 0, 0, 0.4)"
      v-if="isStop && isLoaded"
    >
      {{ totalTime }}
    </div>

    <div
      class="absolute left-0 top-0 bg-loadingColor h-full w-full duration-150"
      v-if="!isLoaded"
    >
      <svg-icon
        name="icon_loading"
        :style="{ width: '24px', height: '24px', color: '#BBBFC4' }"
        class="absolute left-1/2 top-1/2 -ml-6 -mt-5 cursor-pointer animate-spin"
      ></svg-icon>
    </div>

    <svg-icon
      v-if="isLoaded && showRange && isStop"
      name="icon_big_play"
      :style="{ width: '80px', height: '80px' }"
      class="absolute left-1/2 top-1/2 -ml-10 -mt-10 cursor-pointer"
      @click.stop="handlePlay"
    >
    </svg-icon>

    <svg-icon
      name="icon_pro"
      :style="{ width: '61px', height: '30px' }"
      class="absolute cursor-pointer right-4 top-4 z-10"
      v-if="level === 'Pro'"
    >
    </svg-icon>
  </div>
</template>
<script setup lang="ts">
import {
  ref,
  watch,
  computed,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
} from 'vue';
import BvPlayer from '@/components/boolv-player';
import { ParseConfig } from '@/libs/parse-config/index';

const emits = defineEmits(['onLoaded', 'onIsPlaying']);
const props = defineProps({
  level: {
    type: String,
    default: '',
  },
  showBorder: {
    type: Boolean,
    default: false,
  },
  rangeWidth: {
    type: String,
    default: '304px',
  },
  editAssets: {
    type: Array,
    default: [],
  },
  src: {
    type: String,
    default: '',
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  currentVolum: {
    type: Number,
    default: 5,
  },
  isShowWaterPrinter: {
    type: Boolean,
    default: true,
  },
});

const playerRef: any = ref(null);
const timer: any = ref(null);
const isLoaded = ref(false);
const showRange = ref(false);
const isPlay = ref(false);
const isMuted = ref(false);
const currentFrame = ref(0);
const totalFrames = ref(0);
const frameRate = ref(0);
const currentTime = ref('0:00');
const isStop = ref(true);

const formattedTime = (time: any, rate: number) => {
  let totalSeconds = Number(time) / Number(rate);
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds % 60);
  let formattedTime = `${String(minutes).padStart(2, '0')}:${String(
    seconds
  ).padStart(2, '0')}`;
  return formattedTime;
};

const totalTime = computed(() => {
  return formattedTime(totalFrames.value, frameRate.value);
});

const rangeMaskStyle = computed(() => {
  return {
    width: '100%',
  };
});
const getRenderResult = () => {
  timer.value = setInterval(() => {
    let frame = playerRef.value?.getCurrentFrame() || 0;
    currentFrame.value = frame;
    getTotalFrames();
  }, 1000);
};

watch(
  () => currentFrame.value,
  () => {
    currentTime.value = formattedTime(currentFrame.value, frameRate.value);
  }
);

const handleAnimReady = () => {
  isLoaded.value = true;
  const animationData = playerRef.value?.getConfig();
  const { fps, duration } = new ParseConfig(animationData);
  totalFrames.value = duration;
  frameRate.value = fps;
  emits('onLoaded');
};

const getTotalFrames = () => {};

const handleShowActionBar = () => {
  showRange.value = true;
};

const handleHiddenActionBar = () => {
  showRange.value = false;
};

// 静音控制
const handleVolume = () => {
  isMuted.value = !isMuted.value;
  if (!isMuted.value) {
    playerRef.value?.unmute();
  } else {
    playerRef.value?.mute();
  }
};

// 播放控制
const handlePlay = () => {
  if (!isPlay.value) {
    playerRef.value?.play();
  } else {
    playerRef.value?.pause();
  }
  isPlay.value = !isPlay.value;
  isStop.value = false;
  emits('onIsPlaying', isPlay.value);
};

const handlePause = () => {
  if (isPlay.value) {
    playerRef.value?.pause();
    isPlay.value = !isPlay.value;
    isStop.value = true;
    emits('onIsPlaying', isPlay.value);
  }
};

// 快进
const handleChangeFrame = (val: number) => {
  playerRef.value.goToAndStop(val);
};

const destroy = () => {
  playerRef.value.goToAndStop(1);
  playerRef.value?.destroy();
};

const onPlay = () => {
  playerRef.value?.play();
  isPlay.value = true;
};

const onPause = () => {
  playerRef.value?.pause();
  isPlay.value = false;
};

const onStop = () => {
  playerRef.value?.stop();
  isPlay.value = false;
};

const goToAndPlay = (val: number) => {
  playerRef.value.goToAndPlay(val);
};

defineExpose({
  isLoaded,
  totalTime,
  showRange,
  onPlay,
  onPause,
  onStop,
  destroy,
  handleChangeFrame,
  goToAndPlay,
});

onMounted(() => {
  getRenderResult();
});

onBeforeUnmount(() => {
  destroy();
});

onUnmounted(() => {
  clearInterval(timer.value);
  timer.value = null;
});
</script>
<style lang="scss" scoped>
.rang-mask {
  background-color: rgba(0, 0, 0, 0.6);
  height: 66px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.preview-render-box {
  .rang-box {
    height: 66px;
    font-size: 14px;
  }
}
</style>

<style lang="scss" scoped>
.preview-render-box {
  :deep(.el-slider__bar) {
    border-radius: 10px;
    height: 4px;
  }

  :deep(.el-slider__runway) {
    border-radius: 10px;
    height: 4px;
  }

  :deep(.el-slider__button) {
    width: 0px;
    height: 0px;
  }

  :deep(.bv-slider) {
    --el-slider-button-wrapper-size: 6px;
  }
}
</style>
