<template>
  <div class="steps-container">
    <div class="step-description">
      <img
        class="current-icon"
        width="128"
        height="128"
        :src="step.icon"
        :key="stepIndex"
      />
      <img
        v-if="stepIndex !== 0"
        class="prev-icon"
        width="128"
        height="128"
        :src="displaySteps[stepIndex - 1].icon"
        :key="stepIndex - 1"
      />
      <p
        class="step-title"
        :style="{
          top: `${step.title.offset[1]}px`,
          right: `${step.title.offset[0]}px`,
        }"
      >
        {{ step.title.content }}
      </p>
    </div>
    <div class="progress">
      <div class="progress-bar" :style="{ width: percent + '%' }"></div>
      <div class="progress-sub-bar" :style="{ width: percent + '%' }">
        <div class="progress-animation"></div>
      </div>
    </div>

    <div class="generate-step">
      <div v-for="item in innerShowSteps" :key="item.id" class="flex flex-col">
        <p class="flex">
          <img :src="icon_complete" v-if="item.state == 0" />
          <img :src="icon_free" v-else-if="item.state == 1" />
          <img class="animate-spin" :src="icon_loading" v-else />
          {{ item.name }}
        </p>
      </div>
      <!-- <p v-if="currentStep"> 
        <img :src="icon_complete" v-if="currentStep.state == 0"/>
         <img class="animate-spin" :src="icon_loading" v-else />
         {{ currentStep.name }} </p> -->
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import icon_loading from './assets/icon_loading.svg';
import icon_complete from './assets/icon_complete.svg';
import icon_free from './assets/icon_free.svg';
import { routeMap } from './data.js';

const props = defineProps({
  tips: Array,
  showSteps: Array,
  stepsLength: Number,
  current: Object,
  perStepPercent: {
    type: Array,
    default: [60, 80, 90, 100],
  },
  stepNum: {
    type: Number,
    default: 6,
  },
});
import { steps } from './config';

let timer = null;
let generateTimer = null;
const route = useRoute();

const innerShowSteps = ref(null)
const stepIndex = ref(0);
const displaySteps = ref([]);
const step = ref(steps[0]);
const percent = ref(3);
const currentStep = ref(null);
const generateSteps = ref(null);

watch(() => props.showSteps, (showSteps) => {
  if(!innerShowSteps.value) {

    innerShowSteps.value = showSteps
    return
  }

  showSteps.forEach(item => {
     console.log('showSteps', showSteps,innerShowSteps.value)
    innerShowSteps.value.forEach(step => {
      if(item.state == step.name) {
        step.state = item.state == 0 ? 0 : step.state == 2 ? 2 : 1
      }
    })
  })

}, {
  immediate: true
})

// 更新percent
function handleUpdatePercent() {
  const max = props.perStepPercent[currentStep.value?.id - 1];

  if (percent.value >= 99) {
    return;
  }

  if (percent.value >= max - 1) {
    return;
  }

  percent.value = percent.value + Math.floor(Math.random() * props.stepNum);
}

watch(
  () => props.current,
  (current) => {
    if (current) {
      if (
        currentStep.value &&
        current != currentStep.value.id &&
        current.id != props.stepsLength
      ) {
        percent.value =
          props.perStepPercent[
            currentStep.value.id - 1 >= 0 ? currentStep.value.id - 1 : 0
          ];
      }

      currentStep.value = current;

      // last one
      if (current.id == props.stepsLength && current.state == 0) {
        percent.value = 100;
        clearInterval(generateTimer);
        return;
      }

      generateTimer = setInterval(handleUpdatePercent, 500);
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

function handleUpdate() {
  stepIndex.value = (stepIndex.value + 1) % steps.length;
}

watch(stepIndex, () => {
  step.value = steps[stepIndex.value];
});

onMounted(() => {
  timer = setInterval(handleUpdate, 1000);
  displaySteps.value = steps;
  if (props.tips) {
    displaySteps.value.forEach((i, index) => {
      displaySteps.value[index].title.content = props.tips[index];
    });
    step.value = displaySteps.value[0];
  }
});

onUnmounted(() => {
  clearInterval(timer);
  clearInterval(generateTimer);
});
</script>

<style scoped lang="scss">
.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  // padding: 0 0 100px;
  background: #fff;
  height: 100%;
}

.step-description {
  position: relative;
}

.step-title {
  position: absolute;
  right: 0;
  top: 0;
  width: max-content;
  padding: 20px 24px;
  background-color: #6741ff;
  border-radius: 99px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  transform: translate(100%, -100%);
  transition:
    top 0.2s,
    right 0.2s;
  transform-origin: left bottom;
  animation: init-scale 0.2s;

  &::after {
    content: '';
    position: absolute;
    left: 6.5px;
    bottom: -8px;
    width: 20px;
    height: 20px;
    background: url('./assets/icon_tail.svg') no-repeat;
  }
}

.progress {
  position: relative;
  width: 440px;
  height: 8px;
  margin-top: 26px;
  background-color: #f2edff;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  height: 100%;
  background: #7b5aff;
}

.progress-sub-bar {
  position: absolute;
  z-index: 2;
  height: 100%;
  overflow: hidden;
}

.progress-animation {
  height: 100%;
  width: 100%;
  animation: progressLoop 1s linear infinite;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 12.6%,
    rgba(226, 144, 255, 0.82) 49.07%,
    rgba(255, 255, 255, 0) 86.38%
  );
}

.generate-step {
  margin-top: 45px;
  color: #646a73;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  p {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  img {
    margin-right: 6px;
  }
}

.prev-icon {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.current-icon {
  animation: fadeIn 0.2s;
}

.prev-icon {
  animation: fadeIn 0.2s reverse;
}

@keyframes init-scale {
  from {
    transform: translate(100%, -100%) scale(0);
  }

  to {
    transform: translate(100%, -100%) scale(1);
  }
}
</style>