<template>
  <div>
    <span class="tag" @click="clickTrigger">AI writes for me</span>
  </div>

  <teleport to="body" v-show="showBox">
    <div class="assistance-wrapper script-wrapper">
      <div class="assistance-container">
        <box v-model="showBox" />
      </div>
    </div>
  </teleport>
</template>

<script setup>
import { watch } from 'vue';
import Box from '@/components/ai-assistant/box.vue';
import { useAssistStore } from '@/store/modules/assist';

const { clearMessages } = useAssistStore();
const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  isShowBox: Boolean,
});

const showBox = ref(false);

const clickTrigger = () => {
  const parentElement = document.getElementsByClassName('common-wrapper')[0];
  if (parentElement) {
    const element = parentElement.getElementsByClassName('box-layout')[0];
    const styles = window.getComputedStyle(element);

    if (styles.opacity == 1) {
      element.classList.remove('appear');
      element.classList.add('hide');
    }
  }
  showBox.value = !showBox.value;
};

onBeforeUnmount(clearMessages);

const observe = () => {
  let targetElement = null;
  const parentElement = document.getElementsByClassName('script-wrapper')[0];
  if (parentElement) {
    targetElement = parentElement.getElementsByClassName('box-layout')[0];
  }

  const observer = new MutationObserver(function (mutationsList, observer) {
    for (let mutation of mutationsList) {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'class'
      ) {
        const classNameStr = targetElement.className + '';
        if (classNameStr.includes('appear')) {
          showBox.value = true;
        } else {
          showBox.value = false;
        }
      }
    }
  });

  // 配置观察选项
  const config = { attributes: true, attributeFilter: ['class'] };

  // 开始观察目标元素
  observer.observe(targetElement, config);
};

onMounted(() => {
  observe();
  // 弹窗互斥
  watch(showBox, (value) => {
    if (value) {
      const { Gleap } = window;
      if (Gleap) {
        const gleapVisible = Gleap.isOpened();
        gleapVisible && Gleap.close();
      }
    }
  });

  window.Gleap?.on('open', () => {
    if (showBox.value) {
      showBox.value = false;
    }
  });
});
</script>

<style lang="scss" scoped>
.hide {
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.appear {
  opacity: 1;
  pointer-events: all;
}

.assistance-wrapper {
  color: #060606;
  z-index: 2000;
  position: fixed;
  bottom: 88px;
  right: 20px;
}

.assistance-container {
  position: relative;
}

.tag {
  cursor: pointer !important;
  color: #875eff !important;
  text-align: center !important;
  font-family: Inter-variant !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px; /* 157.143% */
}
</style>
