<template>
  <el-dialog
    title="Subscription Details"
    v-model="visible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="false"
    :align-center="true"
    class="subscribing-modal"
  >
    <template #header>
      <div class="flex justify-between items-center">
        <div class="flex">
          <div
            :class="`cursor-pointer rounded-sm  hover:bg-closeBtnHoverColor mr-1`"
          ></div>
          <p class="title">Cancel will lose all premium benefits</p>
        </div>
        <div
          class="hover:bg-closeBtnHoverColor rounded-sm cursor-pointer"
          @click="$emit('update:modelValue', false)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7 7L17 17"
              stroke="#646A73"
              stroke-width="1.2"
              stroke-linecap="round"
            />
            <path
              d="M7 17L17 7"
              stroke="#646A73"
              stroke-width="1.2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </template>
    <div class="h-full flex flex-col items-center">
      <el-scrollbar height="200px" class="w-full">
        <div class="w-full mt-5 flex flex-col justify-between text-black">
          <div v-for="item in step2MaterialsList" class="cancel-box">
            <img style="height: 47px" :src="item.image" />
            <p class="image-details">{{ item.text }}</p>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <template #footer>
      <Space reversed :size="20">
        <PrimaryButton
          size="extra-small"
          @click="$emit('update:modelValue', false)"
        >
          <p class="w-[88px] flex justify-center">No, keep my plan</p>
        </PrimaryButton>
        <CommonButton size="extra-small" @click="handleContinueCancel">
          <p class="w-[88px] flex justify-center">Continue</p>
        </CommonButton>
      </Space>
    </template>
  </el-dialog>
  <QuestionnaireModal
    v-if="questionnaireVisible"
    v-model="questionnaireVisible"
    @close="questionnaireVisible = false"
    @submit="submit"
  />
</template>

<script setup lang="tsx">
import QuestionnaireModal from './questionnaire.vue';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { queryCancelAlert, ltdRefund } from '@/api/premium';
import { useSubscriptionInfo } from '@/store/modules/user';
import { useTrackStore } from '@/store/modules/track';
import {
  Export,
  Cloud,
  Templates,
  modal_banner,
  Success,
} from '@/assets/images/premium';

const modalManager = useModalManager();
const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { updateSubscriptionState } = useSubscriptionInfo();
const { collectData, track } = useTrackStore();

const emits = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: Boolean,
});

const visible = ref(false);
const step2MaterialsList = reactive([
  {
    text: "You can't export videos anymore",
    image: Export,
  },
  {
    text: 'You will lose 100G cloud storage',
    image: Cloud,
  },
  {
    text: 'Lose 500+ premium templates',
    image: Templates,
  },
]);
const questionnaireVisible = ref(false);

watch(
  () => props.modelValue,
  async (value) => {
    if (value) {
      await setup();
    }
    visible.value = value;
  }
);

const handleContinueCancel = async () => {
  collectData('boolvideo_subscribe_cancel', {
    click: 'warning_tips',
  });
  track('boolvideo_subscribe_cancel');
  emits('update:modelValue', false);
  // 打开调查问卷
  questionnaireVisible.value = true;
};

const cancelLtd = async () => {
  const res = await ltdRefund();
  if (res.success) {
    refundSuccessModal();
  } else {
    return;
  }
};

const submit = async () => {
  if (subscriptionState.value.supportRefundBoolean) {
    await cancelLtd();
    updateSubscriptionState();
  }
  questionnaireVisible.value = false;
};

const setup = async () => {
  const res = await queryCancelAlert({
    packageCode: subscriptionState.value.packageCode,
  });
  if (res.success) {
    const { data } = res;
    if (!data) {
      return;
    }
    data.quotaAlertList.forEach((item, index) => {
      step2MaterialsList[index].text = item;
    });
  } else {
    return;
  }
};

const refundSuccessModal = () => {
  modalManager.applyTemplate('info', {
    title: '',
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="w-max flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Refund has been initiated
            </p>
          </div>
        </div>
        <div class="absolute z-10 top-5 right-5">
          <div
            class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={() => modalManager.modal.onClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p class="pb-6">
            Your bank may take upto 5-7 business days to credit the refund to
            your account. If you have any issues, please contact{' '}
            <a href="mailto:support@boolvector.com" class="text-primaryColor">
              support@boolvector.com
            </a>
          </p>
        </div>
      </>
    ),
    footer: '',
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
  };
};

// onMounted(setup);
</script>

<style scoped lang="scss">
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.image-details {
  margin-left: 8px;
  text-align: center;
  color: #ff4204;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.cancel-box {
  display: flex;
  align-items: center;
  background: #fff4f3;
  border-radius: 4px;
  margin-bottom: 13px;
}

.modal-container {
  padding: 0;
  position: relative;
  z-index: 10;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 36%;
  max-width: 530px;
  min-width: 430px;
  border-radius: 8px;
}

.modal-header {
  width: 100%;

  p:first-child {
    width: 100%;
  }
}

.modal-footer {
  padding: 24px;
  margin-top: 0;
}

.modal-content {
  position: relative;
}

.modal-content {
  margin-top: 0;
}
</style>
