<template>
  <div class="space-view">
    <SpaceTabs :tabs="tabs" v-model="activeName" @tab-click="handleClickTab">
      <template #extra>
        <template v-if="batchDelete.size === 0">
          <group-select
            v-if="filterStore.allQueryTypes.length !== 0"
            v-model="filterStore.queryTypes"
            canReset
            :width="210"
            :options="filterStore.allQueryTypes"
          >
            <span
              :class="{
                'filter-type': true,
                active: filterStore.queryTypeCount.value !== 0,
              }"
            >
              <svg-icon name="icon_filter" :size="18" />
            </span>
          </group-select>
        </template>
        <Space v-else :size="14" class="batch-delete">
          <CommonButton size="extra-small" @click="cancelBatchDelete">
            Cancel
          </CommonButton>
          <CommonButton
            size="extra-small"
            color="#FF5449"
            fontColor="#FFF"
            @click="handleBatchDelete"
          >
            <SvgIcon name="icon_delete" :size="18" style="margin-right: 4px" />
            Delete ( {{ batchDelete.size }} )
          </CommonButton>
        </Space>
      </template>
    </SpaceTabs>
  </div>
</template>

<script setup lang="tsx">
import { ref, h, provide } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";
import SpaceTabs from "./components/space-tabs.vue";
import GroupSelect from "@/components/common/group-select/index.vue";
import DraftList from "./views/draftList.vue";
import ExportList from "./views/exportList.vue";
import MaterialList from "./views/materialList.vue";
import Space from "@/components/common/space/index.vue";
import modalInstance from "@/components/common/custom-modal/instance";
import { useTrackStore } from "@/store/modules/track";
import {
  CommonButton,
  PrimaryButton,
} from "@/components/common/bv-button/index";
import {
  useScopeId,
  randomClassName,
  apiCall,
  useStyle,
  useSingleMessage,
} from "@/utils";
import { deleteCloudMaterials } from "@/api/space";
import { asyncDebounce } from "@/utils/debounce";
import { useSpaceStore } from "@/store/modules/user";
import { useFilterStore } from "./utils/store";

// ANCHOR - 变量声明
const scopeId = useScopeId();
const route = useRoute();
const router = useRouter();
const spaceInfo = useSpaceStore();
const Message = useSingleMessage();
const filterStore = useFilterStore();
const activeName = ref<string>((route.query.tab as string) || "drafts");
const modalManager = modalInstance.modalManager!;
const { collectData } = useTrackStore();
const batchDelete = reactive(new Set<string>());
const globalDomain = inject("globalDomain");

const tabs = {
  drafts: {
    label: "Drafts",
    name: "drafts",
    vnode: <DraftList />,
  },
  exported: {
    label: "Exported",
    name: "exported",
    vnode: <ExportList />,
  },
  media: {
    label: "Media",
    name: "media",
    vnode: <MaterialList />,
  },
};

useHead({
  title: route.meta.title,
  meta: [
    {
      property: "og:title",
      content: route.meta.title,
    },
    {
      name: "twitter:title",
      content: route.meta.title,
    },
  ],
});

provide("batchDelete", batchDelete);
useStyle([[".el-main", { padding: "0" }]]);

if (!(activeName.value in tabs)) {
  changeTab("drafts");
}

modalManager.registerTemplate("delete_card_item", function (props) {
  const handleDelete = asyncDebounce(async () => {
    if (!this.open) return;
    // TODO：错误处理
    await props.onDelete().finally(() => {
      this.onClose();
    });
  });

  modalManager.applyTemplate("info", {
    title: props.title || "Delete file?",
    content: props.content || "Unable to recover after deletion.",
    footer: (
      <Space key={randomClassName()} size={12} reversed>
        <CommonButton
          size="extra-small"
          onclick={handleDelete}
          color="#FF5449"
          fontColor="#FFF"
        >
          Delete
        </CommonButton>
        <CommonButton size="extra-small" onclick={this.onClose}>
          Cancel
        </CommonButton>
      </Space>
    ),
  });
});

modalManager.registerTemplate("duplicate_card_item", function (props) {
  const handleDuplicate = asyncDebounce(async () => {
    if (!this.open) return;
    // TODO：错误处理
    await props.onDuplicate().finally(() => {
      this.onClose();
    });
  });

  modalManager.applyTemplate("info", {
    title: props.title || "Duplicate draft",
    content: props.content || "Are you sure you want to create a copy of this draft?",
    footer: (
      <Space key={randomClassName()} size={12} reversed>
        <PrimaryButton
          size="extra-small"
          onclick={handleDuplicate}
        >
          Duplicate
        </PrimaryButton>
        <CommonButton size="extra-small" onclick={this.onClose}>
          Cancel
        </CommonButton>
      </Space>
    ),
  });
});

modalManager.registerTemplate(
  "rename_card_item",
  function ({ value, onRename, inputRef }) {
    const handleRename = () => {
      // TODO：错误处理
      onRename(inputRef.value.value).finally(handleClose);
    };

    const handleClose = () => {
      inputRef.value = null;
      this.onClose();
    };

    modalManager.applyTemplate("info", {
      key: randomClassName(),
      title: "Rename",
      content: h("input", {
        [scopeId]: "",
        class: "rename-input",
        type: "text",
        value: value,
        placeholder: value,
        maxlength: 50,
        style: "margin-top: 8px",
        onVnodeMounted: (vnode) => {
          inputRef.value = vnode.el;
        },
      }),
      footer: (
        <Space size={12} reversed>
          <PrimaryButton
            size="extra-small"
            onclick={handleRename}
            style={{ width: "100px" }}
          >
            OK
          </PrimaryButton>
          <CommonButton size="extra-small" onclick={handleClose}>
            Cancel
          </CommonButton>
        </Space>
      ),
    });
  },
);

modalManager.registerTemplate("batch_delete_card_item", function () {
  modalManager.applyTemplate("delete_card_item", {
    title: "Delete all file?",
    content: "Unable to recover after deletion.",
    onDelete: async () => {
      Message.loading("Processing, it will take a while");

      if (route.query.tab === "media") {
        apiCall(deleteCloudMaterials, { mid: [...batchDelete] }).then(() => {
          for (const id of batchDelete) {
            batchDelete.delete(id);
          }

          refreshSpaceSize();
          Message.close();
        });
      }
    },
  });
});

watchEffect(() => {
  collectData("boolvideo_space_click", {
    view_title: activeName.value,
  });
});

// ANCHOR - 事件处理
function handleClickTab(name: string) {
  changeTab(name);
}

function cancelBatchDelete() {
  batchDelete.clear();
}

function refreshSpaceSize() {
  if (globalDomain === 2) return;
  spaceInfo.refresh();
}

async function changeTab(name: string) {
  await router.replace({ query: { tab: name } });
  activeName.value = name;
}

function handleBatchDelete() {
  modalManager.applyTemplate("batch_delete_card_item", {});
}

refreshSpaceSize();
provide("refreshSpaceSize", refreshSpaceSize);

onBeforeUnmount(() => {
  modalManager.deleteTemplate("delete_card_item");
  modalManager.deleteTemplate("batch_delete_card_item");
  modalManager.deleteTemplate("rename_card_item");
});
</script>

<style lang="scss" scoped>
.space-view {
  --primary-color: #060606;

  position: relative;
  z-index: 0;
}

.space-tabs > :deep(.space-tab-header) {
  margin-inline: 50px;
}

.rename-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #875eff;
  border-radius: 4px;
}

.batch-delete {
  :deep(button.el-button) {
    min-width: 126px;
    padding-inline: 20px;
    font-size: 12px;
  }
}

.filter-type {
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  background-color: #ffffff;
  transition: background-color 0.2s;
  outline: 1px solid #d5d6d7;
  border-radius: 4px;
  color: #646a73;
  cursor: pointer;

  &.active,
  &.active:hover {
    background-color: #875eff;
    color: #ffffff;
    outline: none;
  }

  &:hover {
    background-color: rgba(222, 224, 227, 0.6);
  }
}
</style>
