<template>
  <div class="material-container flex" id="material-container"  v-if="item?.type === 1 || item?.type === 3 || item?.type === 6">
    <p class="num mr-3 text-sm text-tipColor font-medium">{{ index!! + 1 }}</p>
    <div class="flex-1 w-full">
      <div v-if="item && item.type === 1" class="flex w-full">
        <!-- 图片区域 -->
        <div @mousemove="handleEdit" @mouseleave="handleCancelEdit" :layerId="item.layerId" :class="{
          'image-box': true,
          'border-2': isActive && editImageMode,
          'border-primaryColor': isActive && editImageMode,
          'border-white': !isActive && !editImageMode,
        }">
          <!-- 反色滤镜 -->
          <svg :width="isActive && editImageMode ? '126' : '130'" :height="isActive && editImageMode ? '126' : '130'">
            <defs>
              <filter :id="item.key">
                <feColorMatrix in="SourceGraphic" type="matrix" :values="getFilterValue(item.reverseColor, item.isReverseColor)
                  " />
              </filter>
            </defs>
            <image preserveAspectRatio="xMidYMid meet" :xlink:href="item.cropUrl" :filter="`url(#${item.key})`"
              :width="isActive && editImageMode ? '126' : '130'" :height="isActive && editImageMode ? '126' : '130'" />
          </svg>

          <!-- mask -->
          <div class="absolute top-0 left-0 text-white h-full w-full rounded duration-150" v-if="isHoverEdit && !isActive"
            @click="handleEditImage">
            <div class="mask absolute h-full w-full rounded duration-150"></div>
            <div class="mask-content absolute flex items-center justify-center w-full h-full text-white">
              Edit
            </div>
          </div>
        </div>

        <!-- 操作区域 -->
        <div class="ml-4" v-if="isActive || isHoverEdit">
          <!-- update -->
          <div class="mb-3 inline-block cursor-pointer text-sm" :class="{
            'text-primaryColor': isHoverUpdate && !isHoverEdit,
            'text-indicatorColor': isHoverEdit && !isActive,
          }" @mouseleave="handleCancelHoverUpdate" @mousemove="handleHoverUpdate">
            <div @click.stop="handleOperate(1)" class="flex">
              <svg-icon name="icon_update" class="icon-btn mr-2" :style="{ width: '18px', height: '18px' }" :color="isHoverUpdate
                ? '#875EFF'
                : isHoverEdit && !isActive
                  ? '#BBBFC4'
                  : '#1C1B1E'
                "></svg-icon>
              <span class="mr-2">Update</span>
              <el-tooltip placement="bottom" v-if="item.logo">
                <template #content>
                  <div :style="{ padding: '6px 0px' }">
                    <div class="flex">
                      <svg-icon name="icon_solid_logo" :style="{
                        width: '70px',
                        height: '70px',
                        marginRight: '8px',
                        marginBottom: '8px',
                      }"></svg-icon>
                      <svg-icon name="icon_transparent_logo" :style="{ width: '70px', height: '70px' }"></svg-icon>
                    </div>
                    <div>
                      <h3>Logo format:</h3>
                      <p>Transparent background</p>
                      <p>Support PNG, WEBP</p>
                    </div>
                  </div>
                </template>
                <svg-icon name="icon_tip" :style="{ width: '18px', height: '18px', color: '#E5E6E8' }"></svg-icon>
              </el-tooltip>
            </div>
          </div>

          <!-- crop -->
          <div class="mb-3 cursor-pointer text-sm" :class="{
            'text-primaryColor': isHoverCrop && !isHoverEdit,
            'text-indicatorColor': isHoverEdit && !isActive,
          }" @mouseleave="handleCancelHoverCrop" @mousemove="handleHoverCrop">
            <div class="inline-block active:text-primaryColor" @click.stop="handleOperate(2)">
              <div class="flex">
                <svg-icon name="icon_crop" :style="{ width: '18px', height: '18px' }" class="mr-2" :color="isHoverCrop
                  ? '#875EFF'
                  : isHoverEdit && !isActive
                    ? '#BBBFC4'
                    : '#1C1B1E'
                  "></svg-icon>
                Crop
              </div>
            </div>
          </div>

          <!-- 图片 反色 -->
          <EditColorPicker :value="item.reverseColor" @change="handleImageColorChange" v-if="item.logo"
            id="pick-container">
            <div class="mb-3 flex cursor-pointer text-sm" :class="{
              'text-primaryColor':
                (isHoverColor && !isHoverEdit) || currentActive === 3,
              'text-indicatorColor': isHoverEdit && !isActive,
            }" @click.stop="handleOperate(3)" @mouseleave="handleCancelHoverColor" @mousemove="handleHoverColor">
              <svg-icon name="icon_color" :style="{ width: '18px', height: '18px' }" class="mr-2" :color="isHoverColor || currentActive === 3
                ? '#875EFF'
                : isHoverEdit && !isActive
                  ? '#BBBFC4'
                  : '#1C1B1E'
                "></svg-icon>
              Color
            </div>
          </EditColorPicker>

          <!-- zoom -->
          <div class="mb-3 flex cursor-pointer text-sm items-center" :class="{
            'text-indicatorColor': isHoverEdit && !isActive,
          }">
            <div @click.stop="handleOperate(4)" class="flex">
              <svg-icon name="icon_scale" :style="{ width: '18px', height: '18px' }" class="mr-2"
                :color="isHoverEdit && !isActive ? '#BBBFC4' : '#1C1B1E'"></svg-icon>
              <span class="mr-2">Zoom</span>
            </div>
            <div :style="{ width: '152px', height: '3px' }" class="flex items-center">
              <bv-slider :min="10" :max="500" :step="10" :model-value="zoom" :format-tooltip="(val) => val + '%'"
                :style="hoverSliderStyle" @input="handleZoomChange" />
            </div>
          </div>

          <div class="mb-3 flex cursor-pointer text-sm items-center" :class="{
            'text-primaryColor': isHoverDelete && !isHoverEdit && isActive,
            'text-indicatorColor': isHoverEdit && !isActive,
          }" v-if="item.logo">
            <div class="inline-block active:text-primaryColor" @click.stop="handleOperate(5)"
              @mouseleave="handleCancelHoverDelete" @mousemove="handleHoverDelete">
              <div class="flex">
                <svg-icon name="icon_remove" :style="{ width: '18px', height: '18px' }" class="mr-2" :color="isHoverDelete
                  ? '#875EFF'
                  : isHoverEdit && !isActive
                    ? '#BBBFC4'
                    : '#1C1B1E'
                  "></svg-icon>
                <span class="mr-2">Delete</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="(item && item.type === 6) || (item && item.type === 3)">
        <div @click.stop="handleFocusInput" ref="inputBox" id="input-box"
          class="border rounded border-borderColorGray duration-200" :class="{
            'border-primaryColor': isActive,
            'hover:border-primaryColor': !isFocus && !isActive,
          }">
          <el-input v-model="item.material" :autosize="{ minRows: 1 }" type="textarea" placeholder="Please input"
            show-word-limit :maxlength="item.max || 100" @change="handleTextChange">
          </el-input>
          <div class="h-7 my-2 flex px-2 text-sm text-tipColor items-center text-edit-container justify-between"
            v-if="isActive">
            <div class="flex items-center edit-text">
              <bv-select inputable hignlightBackground v-model="item.fontSize" :isScroll="isScroll" :showBorder="false"
                :showSearch="false" justifyAlign="end" width="80px" :options="sizeOptions" placement="bottom-start"
                @change="handleFontSizeChange">
              </bv-select>
              <el-divider direction="vertical" />
              <color-picker :value="item.fillColor" @input="hanColorChange" @change="hanColorChange" @close="handlePickerClose" />
            </div>
            <svg-icon @click="handleResetText" name="icon_reset" :style="{
              width: '22px',
              height: '22px',
              cursor: isEditText ? 'pointer' : 'pointer',
              color: isEditText ? '#646A73' : '#909399',
            }"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, type PropType } from "vue";
import { ColorPicker, EditColorPicker } from "@/components/color-pick/index";
import { getFilterValue } from "@/utils/index";
import { secondsToHms } from "@/utils/util.ts";
import audioCover from '@/assets/images/audio_cover.png'


const props = defineProps({
  item: {
    type: Object as PropType<EditableAssetItem>,
  },
  index: Number,
  isScroll: Boolean,
  isActive: Boolean,
  isPlaying: Boolean,
  isVideoPlaying: Boolean,
  duration: {
    type: Number,
    default: 0
  }
});

const emits = defineEmits([
  "selectItem",
  "openAssetDialog",
  "onRefresh",
  "openCropModal",
  "openAudioModal",
  "onAudioPlaying",
  "onResetAudio",
  "onZoomChange",
  "onDeleteLogo",
  "onSizeChange",
  "onTextColorChange",
  "onImageReversColor",
  "onResetText",
  "onTextContentChange",
]);

const currentActive = ref(0);
const isFocus = ref(false);
const zoom = ref(100);
const inputBox: any = ref(null);
const sizeOptions = [
  {
    value: "16",
    label: "16",
  },
  {
    value: "20",
    label: "20",
  },
  {
    value: "24",
    label: "24",
  },
  {
    value: "32",
    label: "32",
  },
  {
    value: "36",
    label: "36",
  },
  {
    value: "40",
    label: "40",
  },
  {
    value: "48",
    label: "48",
  },
  {
    value: "64",
    label: "64",
  },
  {
    value: "96",
    label: "96",
  },
  {
    value: "128",
    label: "128",
  },
  {
    value: "160",
    label: "160",
  },
];

const isHoverEdit = ref(false);
const editImageMode = ref(true);
const isHoverUpdate = ref(false);
const isHoverCrop = ref(false);
const isHoverDelete = ref(false);
const isHoverColor = ref(false);
const isEditText = ref(false);

const currentTime = ref(0)
const defaultAudioDuration = ref(100)

const hoverSliderStyle = computed(() => {
  if (isHoverEdit.value && !props.isActive) {
    return {
      "--slider-main-color": "#BBBFC4",
      "--slider-runway-color": "#BBBFC4",
      "--slider-track-color": "#BBBFC4",
      "--slider-stop-color": "#BBBFC4",
    };
  }
  return {};
});

// 监听偏移值变化，使图层拖拽拉伸框和缩放seekbar联动
watch(
  () => props.item,
  (assetItem) => {
    const offsetData = assetItem?.offsetData;

    if (offsetData) {
      zoom.value = parseInt((offsetData.scaleX * 100).toString());
    } else {
      zoom.value = 100;
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

watch(() => props.isVideoPlaying, (value) => {
  if (value) {
    audioRef.value?.pause()
    isAudioPlaying.value = false
    currentTime.value = 0
  }
})

const handleZoomChange = (zoomValue: number) => {
  zoom.value = zoomValue;
  currentActive.value == 4;
  emits("onZoomChange", zoomValue);
};

const handleHoverUpdate = () => {
  isHoverUpdate.value = true;
};

const handleCancelHoverUpdate = () => {
  isHoverUpdate.value = false;
};

const handleCancelHoverCrop = () => {
  isHoverCrop.value = false;
};

const handleCancelHoverColor = () => {
  isHoverColor.value = false;
};

const handleHoverColor = () => {
  isHoverColor.value = true;
};

const handleHoverCrop = () => {
  isHoverCrop.value = true;
};

const handleCancelHoverDelete = () => {
  isHoverDelete.value = false;
};

const handleHoverDelete = () => {
  isHoverDelete.value = true;
};

const handleOperate = (num: number) => {
  observeElement();
  emits("selectItem", props.item);
  currentActive.value = num;

  if (num === 1) {
    emits("openAssetDialog");
  }

  if (num === 2) {
    emits("openCropModal", props.item);
  }

  if (num === 4) {
    audioRef.value?.pause()
    isAudioPlaying.value = false
    currentTime.value = 0
    emits("openAudioModal", props.item?.key);
  }

  if (num === 5) {
    emits("onDeleteLogo", props.item?.key);
  }

  if (num === 6) {
    audioRef.value?.pause()
    isAudioPlaying.value = false
    currentTime.value = 0
    emits("onResetAudio", props.item?.key);
  }

};

const handleFocusInput = () => {
  isFocus.value = true;
  emits("selectItem", props.item);
};

const handleEdit = () => {
  isHoverEdit.value = true;
};

const handleCancelEdit = () => {
  isHoverEdit.value = false;
};

// 图片编辑
const handleEditImage = () => {
  editImageMode.value = true;
  emits("selectItem", props.item);
};

// 改变字体颜色
const hanColorChange = (color: string) => {
  if (!props.item) {
    return;
  }
  isEditText.value = true;
  emits("onTextColorChange", { key: props.item.key, fillColor: color });
};

const handlePickerClose = () => {
  currentActive.value = 0;
};

// 图片反色
const handleImageColorChange = (color: string) => {
  observeElement();
  if (!props.item) {
    return;
  }

  emits("onImageReversColor", {
    key: props.item.key,
    reverseColor: color,
    isReverseColor: color ? true : false,
  });
};

// 改变字体
const handleFontSizeChange = (fontSize: number) => {
  if (!props.item) {
    return;
  }
  isEditText.value = true;
  emits("onSizeChange", {
    key: props.item.key,
    fontSize: fontSize,
  });
};

const handleResetText = () => {
  isEditText.value = false;
  emits("onResetText", {
    key: props.item?.key,
    fillColor: props.item?.originFillColor,
    fontSize: props.item?.originFontSize,
    material: props.item?.originMaterial,
  });
};

const handleTextChange = (value: string) => {
  isEditText.value = true;
  emits("onTextContentChange", {
    key: props.item?.key,
    material: value,
  });
};

// 监听pick
const observeElement = async () => {
  const options = {
    childList: true,
    attributes: true,
  };
  var observer = new MutationObserver((mutations, observer) => {
    mutations.forEach((mutation) => {
      if (!mutation.addedNodes.length) {
        currentActive.value = 0;
      } else {
        currentActive.value = 3;
      }
    });
  });

  const colorPick = document.getElementById("pick-container");

  if (!colorPick) {
    return;
  }

  observer.observe(colorPick, options);
};

// audio
const showAudioIcon = ref(false)
const audioRef = ref(null)
const isAudioPlaying = ref(false)
const handleAudioHover = () => {
  showAudioIcon.value = true
}

const handleAudioLeave = () => {
  showAudioIcon.value = false
}

const handlePlayAudio = () => {
  if (audioRef.value?.paused) {
    audioRef.value?.play()
    isAudioPlaying.value = true
    emits('onAudioPlaying', isAudioPlaying.value)
  } else {
    audioRef.value?.pause()
    isAudioPlaying.value = false
  }

}

const handleTimeupdate = () => {
  currentTime.value = audioRef.value?.currentTime
}

const handleCanplay = () => {
  defaultAudioDuration.value = audioRef.value?.duration
}

const handleSeekAudioTime = (value) => {
  if (!audioRef.value) {
    return
  }
  currentTime.value = value
  audioRef.value.currentTime = value
}

onMounted(() => {
  observeElement();
});
</script>

<style lang="scss" scoped>
.material-container {
  margin-bottom: 46px;

  :deep(.el-textarea) {
    --el-input-focus-border: none;
    --el-input-border: none;
    --el-input-hover-border: none;
    --el-input-border-color: none;
    --el-input-hover-border-color: none;
    --el-input-focus-border-color: none;
  }

  :deep(.el-textarea__inner) {
    padding: 8px 60px 8px 12px;
    --el-input-focus-border: none font-size: 16px;
  }

  :deep(.el-textarea.is-exceed .el-input__count) {
    color: #909399;
  }

  :deep(.el-textarea.is-exceed .el-textarea__inner) {
    box-shadow: none;
  }

  :deep(.el-textarea .el-input__count) {
    bottom: 10px;
  }
}

.text-edit-container {
  :deep(.el-select) {
    --el-select-border-color-hover: none;
    --el-select-input-focus-border-color: none;
    --el-input-border-color: none;
  }

  :deep(.el-input__wrapper) {
    box-shadow: none;
  }

  :deep(.el-select .el-input.is-focus .el-input__wrapper) {
    background-color: #f8f5ff !important;

    .el-input__inner {
      color: #875eff;
    }
  }
}

.audio-title {
  color: #060606;

  &:hover {
    color: #875eff;
  }
}

.audio-name {
  color: #646a73;
  padding: 8px 0 4px 0;
  min-width: 100px;
}

.audio-time {
  color: #646a73;
  padding-bottom: 8px;
}

.image-box {
  width: 130px;
  height: 130px;
  border-radius: 4px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.audio-cover {
  width: 130px;
  height: 130px;
  border-radius: 4px;
}

.audio-box {
  width: 130px;
  height: 130px;
  border-radius: 4px;
  background-color: #f1f1f1;
  position: relative;
  cursor: pointer;

  .control-icon {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }

  p {
    color: #606266;
    font-size: 12px;
  }
}

.audio-slider {
  width: 100%;
  min-width: 300px;
}

.mask {
  background: rgba(0, 0, 0, 1);
}

.audio-mask {
  background: rgba(0, 0, 0, 0.5);
}

#input-box {
  min-width: 200px;
}

.icon-btn {
  &:hover {
    color: #875eff;
  }
}
</style>
<style lang="scss">
.edit-text {
  .bv-select-button .el-input__inner {
    font-size: 14px;
  }
}
</style>
