<template>
  <div
    class="landing-page-header"
    id="landing-page-header"
    :style="`--base-size: ${baseSize}px`"
  >
    <RouterLink to="/" target="_blank">
      <img
        class="boolv-logo cursor-pointer"
        :src="logo"
        v-if="globalDomain == 1"
      />
      <img
        :src="similarVideo_logo"
        class="boolv-logo cursor-pointer"
        v-if="globalDomain == 2"
      />
    </RouterLink>

    <div class="w-full flex justify-center">
      <div
        v-for="item in navList"
        :key="item.id"
        class="nav-title"
        :class="{ 'active-title': activeId == item.id }"
        @click="handleActive(item)"
      >
        <router-link :to="{ hash: item.hash }">{{ item.title }}</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import logo from './image/logo.svg';
import similarVideo_logo from '@/assets/similar-video/similarVideo_logo.svg?raw';

import { useWindowSize } from '@vueuse/core';
import { useRoute } from 'vue-router';

const globalDomain = inject('globalDomain');
const { width: windowWidth } = useWindowSize();
const route = useRoute();
const currentHash = ref();

const baseSize = computed(() => {
  let baseSize = Math.max(10, ((windowWidth.value / 1440) * 100) | 0);
  return baseSize > 100 ? 100 : baseSize;
});

const activeId = ref(1);
const navList = ref([
  {
    id: 1,
    path: '',
    title: 'Overview',
    hash: '#overview',
    idName: 'overview',
  },
  {
    id: 2,
    path: '',
    title: 'Plans & features',
    hash: '#plans',
    idName: 'plans',
  },
  {
    id: 3,
    path: '',
    title: 'Roadmap',
    hash: '#roadmap',
    idName: 'roadmap',
  },
  {
    id: 4,
    path: '',
    title: 'Testimonial',
    hash: '#testimonial',
    idName: 'testimonial',
  },
  {
    id: 6,
    path: '',
    title: 'Reviews',
    hash: '#reviews',
    idName: 'reviews',
  },
  {
    id: 5,
    path: '',
    title: 'Questions',
    hash: '#questions',
    idName: 'questions',
  },
]);

const setActiveSection = () => {
  // 遍历每个锚点区域，检查其在视窗中的位置
  for (const section of navList.value) {
    const element = document.getElementById(section.idName);
    const rect = element?.getBoundingClientRect();

    if (rect?.top <= 100) {
      currentHash.value = section.hash;
      if (section.idName == 'questions') {
      }

      activeId.value = section.id;
    }
  }
};

const handleScroll = () => {
  if (route.hash) {
    const targetElement = document.querySelector(route.hash);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'auto' });
    }
  }
};

const handleActive = (item) => {
  activeId.value = item.id;
  handleScroll();
};

onMounted(() => {
  window.addEventListener('scroll', setActiveSection);
  handleScroll();
});
</script>
<style lang="scss">
.landing-page-header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 64px;
  background: #000;
  border-bottom: 0.5px solid rgba(222, 224, 227, 0.16);
  padding-left: 40px;
  display: flex;
  align-items: center;

  .nav-list-container {
    margin-left: calc(var(--base-size) * -1.44);
  }

  .boolv-logo {
    width: calc(var(--base-size) * 1.44);
    height: calc(var(--base-size) * 0.36);
    // margin-right: calc(var(--base-size) * 1.26);
  }

  .nav-title {
    color: #fff;
    font-family: Inter-variant;
    font-size: calc(var(--base-size) * 0.16);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 2px solid #000;
    cursor: pointer;
    margin-right: calc(var(--base-size) * 0.48);
  }

  .active-title {
    border-bottom: 2px solid #fff;
    // transition: all 0.5s;
  }
}
</style>
