<template>
  <el-dialog
    destroy-on-close
    class="tool-dialog-container"
    title=""
    width="1060px"
    align-center
    :modelValue="modelValue"
    :show-close="false"
    :before-close="handleClose"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="dialog-header">
        <p :id="titleId">Preview</p>
        <div
          class="absolute top-6 right-6 h-6 w-6 hover:bg-uploadBg flex items-center justify-center rounded-sm cursor-pointer"
          @click="handleClose"
        >
          <svg-icon
            name="icon_close"
            :style="{ width: '20px', height: '20px' }"
            color="#646A73"
          ></svg-icon>
        </div>
      </div>
    </template>
    <div class="dialog-image-container">
      <div class="logo-preview-container">
        <div class="image-container">
          <div class="image-box" :style="boxStyle">
            <drag :isDrag="isDrag" :isRest="isRest">
              <img class="image" :src="src" alt="" @mousedown.prevent />
            </drag>
          </div>
        </div>
      </div>
      <div class="tool-bar-box" :style="{ bottom: '30px' }">
        <tool-bar
          class="tool-bar"
          :config="toolConfig"
          @onScale="handleScale"
          @onReset="handleReset"
          @onDrag="handleDrag"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import ToolBar from "@/pages/aiTools/components/toolBar/index.vue";
import Drag from "@/pages/aiTools/components/drag/index.vue";
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: Boolean,
  src: String,
});

const boxWidth = ref(90);
const originalBoxWidth = ref(90);
const boxStyle = computed(() => {
  return {
    height: boxWidth.value + "%",
    aspectRatio: 16 / 9,
  };
});
const toolConfig = {
  erase: false,
  restore: false,
  slider: false,
  reset: true,
  drag: true,
  zoomout: true,
  scale: true,
  compare: false,
  step: false,
  submit: false,
};
const isDrag = ref(false);
const isRest = ref(false);
const zoomRatio = ref(1);

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      handleReset();
    }
  },
);

const handleScale = (value: number) => {
  isRest.value = false;
  boxWidth.value = originalBoxWidth.value * value;
  zoomRatio.value = value;
};

const handleReset = () => {
  isRest.value = true;
  boxWidth.value = originalBoxWidth.value;
  zoomRatio.value = 1;
};

const handleDrag = (value: boolean) => {
  isDrag.value = value;
};

const handleClose = () => {
  emit("update:modelValue", false);
};
</script>

<style lang="scss">
.el-dialog {
  border-radius: 4px;
}

.tool-dialog-container {
  min-width: 1060px;

  .el-dialog__body {
    padding: 0;
    color: #000000;
  }

  .el-dialog__header {
    padding: 0;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.dialog-image-container {
  width: 100%;
  height: 77vh;
}

.dialog-header {
  height: 70px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  padding: 0 28px;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.logo-preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ebedef;
  border-radius: 4px;
}

.image-container {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.tool-bar-box {
  width: 100%;
  height: 114px;
  position: absolute;
}

.tool-bar {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
