export function usePermission() {
  const route = useRoute();
  const permission = computed(() => {
    const permission = route.query.permission || route.query.per || route.query.p;
    return permission ? parseInt(permission) : 0;
  });

  return {
    permission,
  };
}
