<template>
  <el-scrollbar>
    <div class="show-images-container perfect-scrollbar h-full w-full">
      <p class="title flex items-center mt-10">Media
        <el-tooltip placement="bottom" effect="dark" popper-class="upload-tooltip">
          <template #content>
            Up to 15 files, including max 5 videos ( each &lt; <br />30s ), visuals over 1080P will be compressed and
            take
            more time.
          </template>
          <svg-icon name="icon_tip_warning" :size="18" class="icon_tip_warning cursor-pointer"></svg-icon>
        </el-tooltip>
      </p>
      <gridImage :imageList="innerImageList" @uploadComplete="handleUploadComplete" @removeImage="handleRemoveImage"
        @uploadRetry="handleUploadRetry" showUpload>

        <template #upload>
          <div class="square-box border bg-white h-full pt-9 pb-11 px-11" @mousemove="handleShowUploadBtn"
            @mouseleave="handleHiddenUploadBtn">
            <div class="flex flex-col justify-center items-center h-full" v-show="!showUploadBtn">
              <svg-icon name="icon_add" :style="{ width: '36px', height: '36px' }"></svg-icon>
            </div>

            <div class="flex flex-col justify-center items-center h-full" v-show="showUploadBtn">
              <p class="text-titleColor text-sm text-center">
                Add media from
              </p>

              <div class="mb-6 mt-6">
                <common-upload ref="uploadRef" :handleProgress="handleUploadProgress" :handleSuccess="handleSuccess"
                  :handleError="handleError" :videoNumber="5" :videoDuration="30" :accept="accept">
                  <template #trigger>
                    <plain-button size="extra-small">
                      <p class="text-primaryColor font-normal" :style="{ width: '65.47px' }">
                        Computer
                      </p>
                    </plain-button>
                  </template>
                </common-upload>
              </div>

              <plain-button size="extra-small" @click="handleUpFromCloud">
                <p class="text-primaryColo font-normal" :style="{ width: '65.47px' }">
                  Cloud
                </p>
              </plain-button>
            </div>
          </div>
        </template>

        <template #delete-icon="{ item, index }">
          <div class="h-full w-full relative">
            <div class="absolute bottom-3 right-4" @click.capture="handleDelete(item, index)">
              <svg-icon class="cursor-pointer" name="icon_delete_bg"
                :style="{ width: '30px', height: '30px' }"></svg-icon>
            </div>
          </div>
        </template>

      </gridImage>

      <CloudAssetModal :visible="isOpenAssetDialog" @close="handleCloseAssetDialog" @confirm="handleConfirm"
        :filterTypes="filterTypes" />
    </div>
  </el-scrollbar>

</template>

<script setup>
import { ref, watch } from 'vue'
import gridImage from "./gridImage/gridContainer.vue";
import CloudAssetModal from "@/components/asset-modal/cloudAssetDialog/index.vue";
import { IMAGETYPE, IMAGEANDVIDEOTYPE } from '@/utils/type.ts'
import { useFeatureEdit } from "./store/index.js";
import { useTrackStore } from '@/store/modules/track';
const featureEditStore = useFeatureEdit();
const { collectData, track } = useTrackStore();

const props = defineProps({
  imageList: Array,
  fileListArr: Array,
  uploadType: String
})

const innerImageList = ref([]);
const showUploadBtn = ref(false)
const isOpenAssetDialog = ref(false)
const uploadRef = ref(null)
const accept = ref('')
const filterTypes = ref(['image', 'video'])

watch(() => props.uploadType, (type) => {
  if (type == 'image') {
    accept.value = IMAGETYPE
    filterTypes.value = ['image']
    return
  }

  accept.value = IMAGEANDVIDEOTYPE
  filterTypes.value = ['image', 'video']
}, {
  immediate: true
})

watch(() => props.imageList, () => {
  innerImageList.value = props.imageList
}, {
  immediate: true
})

watch(() => innerImageList.value, () => {
  featureEditStore.updateImageList(innerImageList.value)
}, {
  immediate: true,
  deep: true
})

const handleUploadComplete = (item) => {
  innerImageList.value.splice(1, 0, item);
};

const handleRemoveImage = (id) => {
  const index = innerImageList.value.findIndex((image) => image.mid == id);
  if (index >= 0) {
    innerImageList.value.splice(index, 1);
  }
};

const handleSuccess = (data) => {
  // 成功之后根据mid 复写数据
  const index = innerImageList.value.findIndex(
    (image) => image.uid == data.uid,
  );

  if (index >= 0) {
    innerImageList.value[index] = { ...innerImageList.value[index], ...data };
  }

  collectData("boolvideo_upload_media", {
    media_type: data.type,
    access: "generation_upload",
    is_batch_upload: data.batch,
  })
  track("boolvideo_upload_media");
};

const handleUploadProgress = (data) => {
  const index = innerImageList.value.findIndex(
    (image) => image.uid == data.uid,
  );

  if (index >= 0) {
    innerImageList.value[index] = { ...innerImageList.value[index], ...data };
  } else {
    innerImageList.value.unshift(data);
  }
};

const handleError = (data) => {
  // 因为失败可能不存在 mid 的现象， 所以这里使用uid查找
  const index = innerImageList.value.findIndex(
    (image) => image.uid == data.uid,
  );

  if (index >= 0) {
    innerImageList.value[index] = { ...innerImageList.value[index], ...data };
  } else {
    innerImageList.value.unshift(data);
  }
};

// 重新上传
const handleUploadRetry = (data) => {
  uploadRef.value?.uploadRetry(data.uid);
};

const handleShowUploadBtn = () => {
  showUploadBtn.value = true;
};

const handleHiddenUploadBtn = () => {
  showUploadBtn.value = false;
};

const handleDelete = (item, index) => {
  innerImageList.value.splice(index, 1);
};


const handleCloseAssetDialog = () => {
  isOpenAssetDialog.value = false;
};

const handleConfirm = (list) => {
  innerImageList.value = [...list, ...innerImageList.value];
  isOpenAssetDialog.value = false;
};


const handleUpFromCloud = () => {
  isOpenAssetDialog.value = true;
};

</script>

<style lang="scss" scoped>
.show-images-container {
  padding-bottom: 350px;
  padding-right: 20px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .title {
    width: 100%;
    color: #060606;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 18px;
  }

  .icon_tip_warning {
    margin-left: 6px;
  }
}
</style>

<style lang="scss">
.upload-tooltip.el-popper.is-dark {
  width: 347px;
  background: #000;
  border-radius: 4px;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  transform: translate3d(78px, 132px, 0px) !important;

  .el-popper__arrow {
    transform: translate3d(50px, 0px, 0px) !important;
  }
}

.upload-tooltip.el-popper.is-dark .el-popper__arrow::before {
  background: #000;
}

.upload-tooltip.el-popper {
  padding: 12px 16px 12px 24px;
}

@media only screen and (max-width: 1000px) {
  .upload-tooltip.el-popper.is-dark {
    transform: translate3d(30px, 132px, 0px) !important;
  }
}
</style>