<template>
  <div class="h-full w-full relative flex justify-center items-center">
    <div class="relative video-box z-0" :style="{ aspectRatio: ratio }">
      <context-menu :operations="operations">
        <video
          id="video"
          ref="videoRef"
          :src="src"
          preload="metadata"
          class="cursor-pointer video-player"
          :controls="false"
          @canplay="handleCanPlay"
        ></video>
      </context-menu>
      <div className="rang-mask absolute bottom-0 left-0  z-100"></div>

      <div
        class="rang-box absolute items-center bottom-0 pl-4 pr-4 text-white z-100"
      >
        <div
          class="flex flex-col w-full h-full"
          :style="{ paddingTop: '14px' }"
        >
          <bv-slider
            v-model="currentDuration"
            :show-tooltip="false"
            :max="duration"
            :min="0"
            :colors="{
              main: '#fff',
              runway: 'rgba(255, 255, 255, 0.30)',
              track: '#fff',
              stop: '#535558',
              tooltip: '#1F2329',
            }"
            @input="handleChangeFrame"
          />

          <div
            class="flex justify-between items-center"
            :style="{ marginTop: '10px' }"
          >
            <div class="flex">
              <div class="cursor-pointer" @click="handlePlay">
                <svg-icon
                  :name="isPlay ? 'icon_edit_pause' : 'icon_edit_play'"
                  :style="{
                    width: '24px',
                    height: '24px',
                    marginLeft: '-6px',
                    marginRight: '14px',
                  }"
                />
              </div>
              <div class="font-normal leading-6">
                {{ formatTime(currentDuration) }} / {{ formatTime(duration) }}
              </div>
            </div>

            <div class="ml-3 cursor-pointer" @click="handleVolume">
              <svg-icon
                :name="isMuted ? 'icon_edit_mute' : 'icon_edit_unmute'"
                :style="{ width: '20px', height: '20px', marginRight: '-4px' }"
              />
            </div>
          </div>
        </div>
      </div>

      <svg-icon
        name="icon_loading"
        :style="{ width: '30px', height: '30px', color: '#BBBFC4' }"
        class="absolute left-1/2 top-1/2 -ml-5 -mt-5 cursor-pointer animate-spin"
        v-if="!isLoaded"
      ></svg-icon>

      <!-- <svg-icon
        name="icon_pro"
        :style="{ width: '61px', height: '30px' }"
        class="absolute cursor-pointer right-4 top-4 z-10"
        v-if="item?.lever === 'Pro'"
      >
      </svg-icon> -->
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  ref,
  reactive,
  watch,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import ContextMenu from "../../components/contextMenu/index.vue";
import { createMenuItem } from "../helper";
import { downloadFile, generateGID } from "@/utils";

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
});

const videoRef: any = ref(null);
const isLoaded = ref(false);
const isPlay = ref(false);
const isMuted = ref(false);

const ratio = ref(9 / 16);
const duration = ref(15);
const currentTime = ref(0);
const currentDuration = ref(0);
const videoWidth = ref("");
const videoHeight = ref("");
const operations = [
  {
    label: createMenuItem("icon_download", "Download"),
    handler: () => {
      downloadFile(props.src, `video-${generateGID()}`);
    },
  },
];

// 静音控制
const handleVolume = () => {
  isMuted.value = !isMuted.value;
  if (!isMuted.value) {
    videoRef.value.muted = false;
  } else {
    videoRef.value.muted = true;
  }
};

// 播放控制
const handlePlay = () => {
  if (!isPlay.value) {
    videoRef.value?.play();

    if (!isMuted.value) {
      videoRef.value.muted = false;
    } else {
      videoRef.value.muted = true;
    }
  } else {
    videoRef.value?.pause();
  }

  isPlay.value = !isPlay.value;
};

// 快进
const handleChangeFrame = (val: number) => {
  videoRef.value.currentDuration = val;
  videoRef.value.currentTime = val;
};

const destroy = () => {
  videoRef.value?.pause();
};

const handleCanPlay = () => {
  ratio.value = videoRef.value.videoWidth / videoRef.value.videoHeight;
  setTimeout(() => {
    isLoaded.value = true;
    getVideoCurrentTime();
  }, 1000);
};

const judgeVideoWithOrHeight = () => {
  return {
    width: videoWidth.value,
    height: videoHeight.value,
  };
};

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);  // 计算分钟数
  const seconds = time % 60;  // 计算剩余的秒数

  // 格式化为（分：秒）
  const formatMinute = minutes ? ("0" + minutes).slice(-2) : "0";
  const formatSecond = ("0" + seconds).slice(-2);
  const formattedTime = `${formatMinute}:${formatSecond}`;
  return formattedTime;
};

const getVideoCurrentTime = () => {
  if (!videoRef.value) {
    return;
  }

  videoRef.value?.addEventListener(
    "loadeddata",
    function () {
      if (!videoRef.value) {
        return;
      }
      videoWidth.value = videoRef.value.videoWidth;
      videoHeight.value = videoRef.value.videoHeight;
      ratio.value = videoRef.value.videoWidth / videoRef.value.videoHeight;
      duration.value = Math.ceil(videoRef.value?.duration);
    },
    false,
  );

  videoRef.value?.addEventListener(
    "timeupdate",
    function () {
      if (!videoRef.value) {
        return;
      }

      ratio.value = videoRef.value.videoWidth / videoRef.value.videoHeight;
      duration.value = Math.ceil(videoRef.value?.duration);
      let value = Math.ceil(videoRef.value.currentTime);
      currentDuration.value = value;
      currentTime.value = value < 10 ? `0:0${value}` : `0:${value}`;
    },
    false,
  );

  videoRef.value?.addEventListener(
    "ended",
    function () {
      if (!videoRef.value) {
        return;
      }
      isPlay.value = false;
    },
    false,
  );
};

defineExpose({
  judgeVideoWithOrHeight,
});

onMounted(() => {
  getVideoCurrentTime();
});

onBeforeUnmount(() => {
  destroy();
});
</script>
<style lang="scss" scoped>
.rang-mask {
  background-color: rgba(0, 0, 0, 0.6);
  height: 66px;
  width: 100%;
}

.rang-box {
  width: 100%;
  height: 66px;
  font-size: 14px;
}

.video-player {
  max-width: 100%;
  max-height: 100%;
}
</style>

<style lang="scss" scoped>
.video-box {
  max-width: 100%;
  max-height: 100%;

  :deep(.el-slider__bar) {
    border-radius: 10px;
    height: 4px;
  }

  :deep(.el-slider__runway) {
    border-radius: 10px;
    height: 4px;
  }

  :deep(.el-slider__button) {
    width: 0px;
    height: 0px;
  }

  :deep(.bv-slider) {
    --el-slider-button-wrapper-size: 6px;
  }
}
</style>
