<template>
  <div class="page-container">
    <div class="left-part" v-if="windowWidth > 1200">
      <img class="left-bottom-shape" :src="leftBottomShape">
      <img class="right-top-shape" :src="rightTopShape">
      <div class="left-part-content">
        <img :src="leftPartPoster">
      </div>
    </div>
    <div class="right-part">
      <user-form style="flex: 1" />
      <p class="policy">
        Agree to the
        <a href="/terms" class="underline">Terms of Service</a>,
        <a href="/privacy-policy" class="underline">Privacy Policy</a>,
        and
        <a href="/cookies-policy" class="underline">Cookie Policy</a>.
      </p>
    </div>
  </div>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';
import UserForm from './form.vue';
import rightTopShape from './assets/right_top_shape.svg';
import leftBottomShape from './assets/left_bottom_shape.svg';
import leftPartPoster from './assets/left_part_poster.png';

const { width: windowWidth } = useWindowSize();
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.left-part, .right-part {
  flex: 1;
  width: 100%;
}

.left-part {
  padding-inline: 24px;
  background-image: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
}

.right-part {
  display: flex;
  flex-direction: column;
  padding: 60px 24px 0px;
}

.policy {
  color: #8F959E;
  text-align: center;
  font-size: 14px;
  padding-bottom: 40px;
}

.left-bottom-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}

.right-top-shape {
  position: absolute;
  right: 0;
  top: 0;
}

.left-part {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.left-part-content {
  position: relative;
  width: 80%;
  height: 80%;
  min-width: 200px;
  max-width: 700px;
  z-index: 1;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

@media screen and (any-hover: none) {
  a:hover {
    color: unset;
    text-decoration: underline;
  }
}
</style>