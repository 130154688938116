<template>
  <div class="form has-keyframe" v-if="permission">
    <div class="form-header">
      <span>Extra</span>
      <icon-button
        v-if="resetable"
        name="editor_reset"
        tip="Reset"
        :size="14"
        @click="$emit('reset')"
      />
    </div>
    <div class="form-content">
      <slot></slot>
      <div class="col">
        <button class="plain" @click="handleCopy">Copy</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useMessage } from "@/utils";
import { usePermission } from "../../composables";

const emit = defineEmits(["reset"]);
const props = defineProps({
  resetable: {
    type: Boolean,
    default: false,
  },
  config: {
    type: Object,
    default: null,
  },
});

const message = useMessage();
const { permission } = usePermission();

async function handleCopy() {
  try {
    await navigator.clipboard.writeText(JSON.stringify(props.config));
    message.success("Copied to clipboard");
  } catch (e) {
    message.error(JSON.stringify(e));
  }
}
</script>