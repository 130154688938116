<template>
  <div class="page-header" id="page-header">
    <CutDownTime @timeDone="handleTimeDone" />
    <div class="title cursor-pointer" @click="handleToPricing">
      <RouterLink to="#pricing">70% Off Golden Deal</RouterLink>
    </div>
    <div class="sub-title">One Week Only</div>

    <youtube-player
      src="https://www.youtube.com/watch?v=_fscJPoRPD8"
      class="video"
      autoplay
      width="100%"
      height="100%"
      id="zANdjtLiwGnZPQfB"
      @play="handleVideoPaly"
      @pause="pause"
      ref="YTPlayer"
    ></youtube-player>

    <p class="third-level-title">
      Level up your video production with
      <span class="logo-title">Boolvideo</span>
    </p>
    <p class="third-level-title">The ultimate video maker</p>
    <p class="third-level-title">that makes imagination a reality</p>

    <div
      class="image-container desktop:grid-cols-4 laptop:grid-cols-4 ipad:grid-cols-4 phone:grid-cols-2 mini:grid-cols-1"
    >
      <img :src="src" v-for="src in imageList" :key="src" class="image-item" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
import image_1 from "./images/image_1.png";
import image_2 from "./images/image_2.png";
import image_3 from "./images/image_3.png";
import image_4 from "./images/image_4.png";
import CutDownTime from "../components/cutDownTime.vue";
import YoutubePlayer from "../components/youtubePlayer.vue";
import { useRoute } from "vue-router";

const emit = defineEmits(["playPageHeaderVideo", "timeDone"]);
const route = useRoute();
const YTPlayer = ref(null);
const isPlaying = ref(false);
const imageList = ref([image_1, image_2, image_3, image_4]);

const handleToPricing = () => {
  if (route.hash) {
    const targetElement = document.querySelector(route.hash);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }
};

const handleVideoPaly = () => {
  isPlaying.value = true;
  emit("playPageHeaderVideo");
};

const handleTimeDone = (value) => {
  emit("timeDone", value);
};

const pause = () => {
  isPlaying.value = false;
  YTPlayer.value.pause();
};

const play = () => {
  isPlaying.value = true;
  YTPlayer.value.play();
};

const observeVideo = () => {
  // 目标元素
  const targetElement = document.getElementsByClassName("video")[0];

  // 创建 Intersection Observer 实例
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 元素进入窗口内
        console.log("元素进入窗口内");
        if (!isPlaying.value) {
          play();
        }
      } else {
        console.log("元素离开窗口");
        // 元素离开窗口
        if (isPlaying.value) {
          pause();
        }
      }
    });
  });

  // 开始观察目标元素
  observer.observe(targetElement);
};

onMounted(async () => {
  await nextTick();
  observeVideo();
});

defineExpose({ pause });
</script>

<style lang="scss">
.page-header {
  background: #0b0b0b;
  padding-top: 34px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #ffe600;
    text-align: center;
    font-family: Inter-variant;
    font-size: calc(var(--base-size) * 0.96);
    font-style: normal;
    font-weight: 600;
    line-height: 174%;
    margin-bottom: 26px;
    margin-top: 26px;
  }

  .sub-title {
    color: #fff;
    text-align: center;
    font-family: Inter-variant;
    font-style: normal;
    font-size: calc(var(--base-size) * 0.68);
    font-weight: 600;
    margin-bottom: 78px;
  }

  .video {
    max-width: 1090px;
    max-height: 561px;
    flex-shrink: 0;
    margin-bottom: 120px;
    aspect-ratio: 1090/561;
  }

  .third-level-title {
    color: #fff;
    text-align: center;
    font-family: Inter-variant;
    font-size: calc(var(--base-size) * 0.54);
    font-style: normal;
    font-weight: 600;
    line-height: 174%;
  }

  .logo-title {
    text-align: center;
    font-family: Inter-variant;
    font-style: normal;
    font-weight: 600;
    background: linear-gradient(93deg, #a245ff 4.24%, #2f5dff 96.12%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .image-container {
    margin-top: 100px;
    margin-bottom: 161px;
    width: 100%;
    max-width: 1308px;
    display: grid;
    grid-row-gap: 36px;
    grid-column-gap: 36px;

    .image-item {
      width: 100%;
      border-radius: 11.507px;
      aspect-ratio: 300/533;
    }
  }
}
</style>
