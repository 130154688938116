<script setup>
import { useDraftStore, useHistoryStore } from "../../stores";
import { blendOptions } from "@/constants/image";
import Attr from "./attr.vue";
import Keyframe from "./keyframe.vue";
import Extra from "./extra.vue";

const emit = defineEmits([
  "update:x",
  "update:y",
  "update:width",
  "update:height",
  "update:rotate",
  "update:opacity",
  "update:radius",
  "update:fill",
  "update:stroke",
  "update:strokeThickness",
  "update:keyframes",
  "update:blend",
]);
const props = defineProps({
  node: {
    type: Object,
    default: null,
  },
  initialState: {
    type: Object,
    default: {},
  },
  x: {
    type: Number,
    default: 0,
  },
  y: {
    type: Number,
    default: 0,
  },
  width: {
    type: Number,
    default: 1,
  },
  height: {
    type: Number,
    default: 1,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  opacity: {
    type: Number,
    default: 1,
  },
  radius: {
    type: Number,
    default: 0,
  },
  fill: {
    type: String,
    default: "",
  },
  stroke: {
    type: String,
    default: "#000000",
  },
  strokeThickness: {
    type: Number,
    default: 0,
  },
  keyframes: {
    type: Object,
    default: undefined,
  },
  blend: {
    type: String,
    default: "normal",
  },
});

const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();

const x = ref(props.x);
const y = ref(props.y);
const rotate = ref(props.rotate);
const width = ref(props.width);
const height = ref(props.height);
const opacity = ref(props.opacity);

const dirty = reactive({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  rotate: 0,
  opacity: 0,
});

watch(
  () => props.node.updateId,
  () => {
    const node = props.node;
    const [nx, ny] = node.getXY();
    const [nw, nh] = node.getWH();
    const nr = node.getRotation();
    const no = node.getOpacity();

    x.value = nx;
    y.value = ny;
    width.value = nw;
    height.value = nh;
    rotate.value = nr;
    opacity.value = no;
  },
  { immediate: true },
);
watch(
  () => props.x,
  (newX) => {
    updateX(newX, false, true);
  },
);
watch(
  () => props.y,
  (newY) => {
    updateY(newY, false, true);
  },
);
watch(
  () => props.width,
  (newWidth) => {
    updateWidth(newWidth, false, true);
  },
);
watch(
  () => props.height,
  (newHeight) => {
    updateHeight(newHeight, false, true);
  },
);
watch(
  () => props.rotate,
  (newRotation) => {
    updateRotate(newRotation, false, true);
  },
);
watch(
  () => props.opacity,
  (newOpacity) => {
    updateOpacity(newOpacity, false, true);
  },
);

function submit() {
  commit();
  updateDraft();
}

function resetBasicProperty() {
  const { initialState } = props;
  emit("update:radius", initialState.radius);
  emit("update:fill", initialState.fill);
  submit();
}

function resetLayoutProperty() {
  const { initialState } = props;
  emit("update:x", initialState.x);
  emit("update:y", initialState.y);
  emit("update:rotate", initialState.rotation);
  emit("update:width", initialState.width);
  emit("update:height", initialState.height);
  emit("update:opacity", initialState.opacity);
  emit("update:keyframes", initialState.keyframes);
  submit();
}

function resetBlendProperty() {
  const { initialState } = props;
  emit("update:blend", initialState.blend);
  submit();
}

function resetStrokeProperty() {
  const { initialState } = props;
  emit("update:stroke", initialState.stroke);
  emit("update:strokeThickness", initialState.strokeThickness);
  submit();
}

function updateX(value, shouldSubmit, noEmit) {
  x.value = value;
  dirty.x++;
  !noEmit && emit("update:x", value);
  shouldSubmit && submit();
}

function updateY(value, shouldSubmit, noEmit) {
  y.value = value;
  dirty.y++;
  !noEmit && emit("update:y", value);
  shouldSubmit && submit();
}

function updateWidth(value, shouldSubmit, noEmit) {
  width.value = value;
  dirty.width++;
  !noEmit && emit("update:width", value);
  shouldSubmit && submit();
}

function updateHeight(value, shouldSubmit, noEmit) {
  height.value = value;
  dirty.height++;
  !noEmit && emit("update:height", value);
  shouldSubmit && submit();
}

function updateRotate(value, shouldSubmit, noEmit) {
  rotate.value = value;
  dirty.rotate++;
  !noEmit && emit("update:rotate", value);
  shouldSubmit && submit();
}

function updateOpacity(value, shouldSubmit, noEmit) {
  opacity.value = value;
  dirty.opacity++;
  !noEmit && emit("update:opacity", value);
  shouldSubmit && submit();
}

function updateRadius(value, shouldSubmit) {
  emit("update:radius", value);
  shouldSubmit && submit();
}

function updateFill(value, shouldSubmit) {
  emit("update:fill", value);
  shouldSubmit && submit();
}

function updateStroke(value, shouldSubmit) {
  emit("update:stroke", value);
  shouldSubmit && submit();
}

function updateStrokeThickness(value, shouldSubmit) {
  emit("update:strokeThickness", value);
  shouldSubmit && submit();
}

function updateKeyframes(value) {
  emit("update:keyframes", value);
}

function updateBlend(value) {
  emit("update:blend", value);
  submit();
}
</script>
<template>
  <Attr title="Graphic">
    <el-scrollbar>
      <div class="attr-content">
        <div class="form">
          <div class="form-header">
            <span>Basic</span>
            <icon-button
              name="editor_reset"
              tip="Reset"
              :size="14"
              @click="resetBasicProperty"
            />
          </div>
          <div class="form-content">
            <div class="col col-input col-single">
              <input-color-picker nullable :value="fill" @input="updateFill" @change="updateFill($event, true)" />
            </div>
            <div
              v-if="['rect', 'triangle'].includes(node.shape)"
              class="col col-input col-single"
            >
              <bv-tip content="Radius">
                <input-number
                  :model-value="radius"
                  :min="0"
                  @input="updateRadius"
                  @change="updateRadius($event, true)"
                >
                  <template #prefix>
                    <svg-icon name="editor_radius" :size="18" />
                  </template>
                </input-number>
              </bv-tip>
            </div>
          </div>
        </div>
        <div class="form keyframe">
          <div class="form-header">
            <span>Layout</span>
            <div class="right">
              <icon-button
                name="editor_reset"
                tip="Reset"
                :size="14"
                @click="resetLayoutProperty"
              />
              <Keyframe
                :should-add="false"
                :properties="{ x, y, rotate, width, height, opacity }"
                :shouldAdd="false"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
          </div>
          <div class="form-content">
            <div class="col col-input">
              <input-number
                rounded
                :model-value="x"
                @input="updateX"
                @change="updateX($event, true)"
              >
                <template #prefix>X</template>
              </input-number>
              <input-number
                rounded
                :model-value="y"
                @input="updateY"
                @change="updateY($event, true)"
              >
                <template #prefix>Y</template>
              </input-number>
              <Keyframe
                :properties="{ x, y }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
            <div class="col col-input">
              <input-number
                rounded
                :min="1"
                :model-value="width"
                @input="updateWidth"
                @change="updateWidth($event, true)"
              >
                <template #prefix>W</template>
              </input-number>
              <input-number
                rounded
                :min="1"
                :model-value="height"
                @input="updateHeight"
                @change="updateHeight($event, true)"
              >
                <template #prefix>H</template>
              </input-number>
              <Keyframe
                :properties="{ width, height }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
            <div class="col col-input col-single">
              <bv-tip content="Rotation">
                <input-number
                  angle
                  :model-value="rotate"
                  :step="Math.PI / 180"
                  @input="updateRotate"
                  @change="updateRotate($event, true)"
                >
                  <template #prefix>
                    <svg-icon name="editor_angle" :size="16" />
                  </template>
                </input-number>
              </bv-tip>
              <Keyframe
                :properties="{ rotate }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
            <div class="col col-input col-single">
              <bv-tip content="Opacity">
                <input-number
                  percent
                  :model-value="opacity"
                  :min="0"
                  :max="1"
                  :step="0.01"
                  @input="updateOpacity"
                  @change="updateOpacity($event, true)"
                >
                  <template #prefix>
                    <svg-icon name="editor_opacity" :size="16" />
                  </template>
                </input-number>
              </bv-tip>
              <Keyframe
                :properties="{ opacity }"
                :keyframes="keyframes"
                :dirty="dirty"
                @change="updateKeyframes"
              />
            </div>
          </div>
        </div>
        <div class="form has-keyframe">
          <div class="form-header">
            <span>Blend</span>
            <icon-button
              name="editor_reset"
              tip="Reset"
              :size="14"
              @click="resetBlendProperty"
            />
          </div>
          <div class="form-content">
            <div class="col col-input col-small">
              <bv-select
                width="226px"
                style="margin-right: 0"
                :model-value="blend"
                :options="blendOptions"
                @change="updateBlend"
              />
            </div>
          </div>
        </div>
        <div class="form">
          <div class="form-header">
            <span>Stroke</span>
            <icon-button
              name="editor_reset"
              tip="Reset"
              :size="14"
              @click="resetStrokeProperty"
            />
          </div>
          <div class="col col-input col-medium">
            <input-color-picker :value="stroke" @input="updateStroke" @change="updateStroke($event, true)" />
            <input-number
              align-right
              rounded
              :model-value="strokeThickness"
              :min="0"
              :max="100"
              @input="updateStrokeThickness"
              @change="updateStrokeThickness($event, true)"
            >
              <template #prefix>size</template>
            </input-number>
          </div>
        </div>
        <extra :config="node.conf" />
      </div>
    </el-scrollbar>
  </Attr>
</template>
<style scoped>
</style>
