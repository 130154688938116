 <script setup>
import Cover from "./cover.vue";
import Control from "./control.vue";
import { useCreatorStore, useToolStore } from "../../stores";
import { clamp } from "../../utils";

const { creator, activeNodeMap } = useCreatorStore();
const { 
  autoResize,
  left, 
  top, 
  scale, 
  mode, 
  modifier, 
  beforePosition, 
  bounds, 
  playerContainer, 
  playerContent, 
  player, 
  updateCursorStyle, 
  resetSize, 
  calcDragBounds,
  zoom,
  fit,
} = useToolStore();

const ro = ref(null);
const dragStart = ref(false);
const style = computed(() => ({
  transform: `translate(${left.value}px, ${top.value}px)`,
  visibility: creator.value ? "visible" : "hidden",
}));

onMounted(() => {
  ro.value = new ResizeObserver(resize);
  ro.value.observe(playerContent.value);

  window.addEventListener("keydown", keyDown);
  window.addEventListener("keyup", keyUp);
  document.addEventListener("mousemove", mouseMove);
  window.addEventListener("mouseup", mouseUp);
});
onBeforeUnmount(() => {
  ro.value.disconnect();
  ro.value = null;

  window.removeEventListener("keydown", keyDown);
  window.removeEventListener("keyup", keyUp);
  document.removeEventListener("mousemove", mouseMove);
  window.removeEventListener("mouseup", mouseUp);
});

watch(creator, (newCreator, oldCreator) => {
  if (newCreator !== oldCreator && newCreator) {
    resetSize();
    newCreator.on("resize", () => resetSize(scale.value));
  }
});

function keyDown(e) {
  if (["TEXTAREA", "INPUT"].includes(e.target.tagName)) {
    return;
  }
  switch (e.code) {
    case "KeyV":
      mode.value = 0;
      break;
    case "KeyH":
      mode.value = 1;
      break;
    case "KeyZ":
      modifier.keyZ = e.metaKey ? false : true;
      break;
    case "Equal":
      e.shiftKey && zoom(-10);
      break;
    case "Minus":
      e.shiftKey && zoom(10);
      break;
    case "Digit0":
      e.shiftKey && zoom(0, 1);
      break;
    case "Digit1":
      e.shiftKey && fit();
      break;
  }
  modifier.alt = e.altKey;
  updateCursorStyle();
}

function keyUp(e) {
  if (["TEXTAREA", "INPUT"].includes(e.target.tagName)) {
    return;
  }
  switch (e.code) {
    case "KeyZ":
      modifier.keyZ = false;
      break;
  }
  modifier.alt = e.altKey;
  updateCursorStyle();
}

function handleMouseDown(e) {
  if (modifier.keyZ) {
    modifier.alt ? zoom(10) : zoom(-10);
  } else if (mode.value === 0) {
    setTimeout(() => activeNodeMap.clear());
  } else {
    dragStart.value = true;
    beforePosition.pageX = e.pageX;
    beforePosition.pageY = e.pageY;
    beforePosition.left = left.value;
    beforePosition.top = top.value;
  }
}

function mouseMove(e) {
  if (dragStart.value && mode.value === 1) {
    const deltaX = beforePosition.pageX - e.pageX;
    const deltaY = beforePosition.pageY - e.pageY;

    left.value = clamp(beforePosition.left - deltaX, bounds.minLeft, bounds.maxLeft);
    top.value = clamp(beforePosition.top - deltaY, bounds.minTop, bounds.maxTop);
  }
}

function mouseUp() {
  if (dragStart.value) {
    dragStart.value = false;
  }
}

function resize() {
  autoResize.value && fit();
  calcDragBounds();
}

function wheel(e) {
  if (e.ctrlKey) {
    zoom(e.deltaY);
  } else {
    left.value = clamp(left.value - e.deltaX, bounds.minLeft, bounds.maxLeft);
    top.value = clamp(top.value - e.deltaY, bounds.minTop, bounds.maxTop);
  }
  autoResize.value = false;
}
</script>

<template>
  <div ref="playerContainer" class="player-container" @mousedown.stop="handleMouseDown" @wheel="wheel" @contextmenu.prevent>
    <div class="player-header">
      <span>Preview : 480P</span>
    </div>
    <div ref="playerContent" class="player-content">
      <div class="canvas-space">
        <div ref="player" id="player" :style="style">
          <Cover v-if="creator" :disabled="mode === 1 || modifier.keyZ" />
        </div>
      </div>
    </div>
    <Control />
  </div>
</template>

<style scoped>
.player-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3f5f7;
  outline: none;
}

.player-header {
  padding: 10px 24px;
}

.player-header span {
  color: #060606;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  z-index: 1;
  position: relative;
}

.player-content {
  flex: 1 1;
  overflow: hidden;
  padding: 5px 20px;
}

.canvas-space {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
}

#player {
  width: 1000px;
  height: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
