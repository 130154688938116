<template>
  <el-dialog
    append-to-body
    destroy-on-close
    class="tool-dialog-container"
    title=""
    width="1060px"
    align-center
    :modelValue="modelValue"
    :show-close="false"
    :before-close="handleClose"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="dialog-header">
        <p :id="titleId">{{ title }}</p>
        <div
          class="absolute top-6 right-6 h-6 w-6 hover:bg-uploadBg flex items-center justify-center rounded-sm cursor-pointer"
          @click="handleClose"
        >
          <svg-icon
            name="icon_close"
            :style="{ width: '20px', height: '20px' }"
            color="#646A73"
          ></svg-icon>
        </div>
      </div>
    </template>
    <div class="dialog-image-container">
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import { useTrackStore } from "@/store/modules/track";
const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  modelValue: Boolean,
});

const { collectData, track } = useTrackStore();

const handleClose = () => {
  emit("update:modelValue", false);
};

onMounted(() => {
  collectData("boolvideo_aitools_use", {
    tool_name: props.title,
    access: "editor",
  });
  track("boolvideo_aitools_use");
});

</script>

<style lang="scss">
.tool-dialog-container.el-dialog {
  width: 65%;
  min-width: 1060px;
  border-radius: 4px;

  .el-dialog__body {
    padding: 0;
    color: #000000;
  }

  .el-dialog__header {
    padding: 0;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.dialog-image-container {
  width: 100%;
  height: 77vh;
}

.dialog-header {
  height: 70px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  padding: 0 28px;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
</style>
