<template>
  <div class="brand-view perfect-scrollbar">
    <banner v-show="showBanner && clientWidth > 1080" v-model="showBanner" />
    <card-list :isLoading="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { useHead } from "@vueuse/head";
import CardList from "./components/card/cardlist.vue";
import Banner from "./components/banner/banner.vue";
import { useBrandStore } from "./stores/brand";

const route = useRoute();
const { getList } = useBrandStore();

const isLoading = ref(true);

useHead({
  title: route.meta.title,
  meta: [
    {
      property: "og:title",
      content: route.meta.title,
    },
    {
      name: "twitter:title",
      content: route.meta.title,
    },
  ],
});

const showBanner = ref(true);
const clientWidth = ref(0);

const watchWindowWidth = () => {
  const width = document.documentElement.clientWidth;
  clientWidth.value = width;
};

const setup = async () => {
  isLoading.value = true;
  await getList();
  isLoading.value = false;
};

onBeforeMount(setup);

onMounted(() => {
  watchWindowWidth();
  window.onresize = () => {
    watchWindowWidth();
  };
});
</script>

<style scoped lang="scss">
.brand-view {
  overflow-y: auto;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}

.banner {
  width: 100%;
}
</style>
