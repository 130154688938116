<script setup>
import { useCreatorStore, useDraftStore } from "../../stores";
import Attr from "./attr.vue";
import Extra from "./extra.vue"

const { creator, currentFrame } = useCreatorStore();
const { updateDraft } = useDraftStore();
const cover = ref(null);
const backgroundColor = ref("#FFFFFF");
const coverString = computed(() => cover.value === null ? '' : `(${cover.value})`);

onMounted(() => {
  if (creator.value) {
    cover.value = creator.value.cover;
    backgroundColor.value = creator.value.backgroundColor;
  }
});

function updateBackgroundColor(value, shouldSubmit) {
  backgroundColor.value = value;

  creator.value.setBackgroundColor(value);
  creator.value.render();
  shouldSubmit && updateDraft();
}

function setCover() {
  cover.value = currentFrame.value;
  creator.value.setCover(currentFrame.value);
  updateDraft();
}
</script>
<template>
  <Attr>
    <div class="attr-content">
      <div class="form">
        <div class="form-header">
          <span>Canvas background</span>
        </div>
        <div class="form-content">
          <div class="col col-input">
            <input-color-picker :value="backgroundColor" @input="updateBackgroundColor" @change="updateBackgroundColor($event, true)" />
          </div>
        </div>
      </div>
      <extra :config="creator.conf.conf">
        <div class="col">
          <button class="plain" @click="setCover">Cover {{ coverString }}</button>
        </div>
      </extra>
    </div>
  </Attr>
</template>
<style scoped>
.attr-content .form {
  padding: 18px;
}
.attr-content .form .form-header {
  margin-bottom: 18px;
}
.attr-content .form .form-header span {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.attr-content .form-content .col.col-input > div {
  margin-right: 0;
}
</style>
