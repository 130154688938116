import { useMessage } from "@/utils";

export function useNetwork() {
  const message = useMessage();
  const onLine = ref(true);

  onMounted(() => {
    window.addEventListener("online", networkStateChange);
    window.addEventListener("offline", networkStateChange);
  });
  onUnmounted(() => {
    window.removeEventListener("online", networkStateChange);
    window.removeEventListener("offline", networkStateChange);
  });

  function networkStateChange() {
    onLine.value = navigator.onLine;
  }

  function assertNetworkError() {
    if (!onLine.value) {
      message.error("Network error, please check your network");
      throw new Error("Network Error");
    }
  }

  return {
    onLine,
    assertNetworkError,
  };
}
