<template>
  <card-list
    ref="cardListRef"
    :items="items"
    :isFirstLoad="page === 1"
    :isLoading="isLoading"
    :isLoaded="isLoaded"
    @loadMore="handleLoadMore"
  />
</template>

<script setup lang="tsx">
import { useRouter } from "vue-router";
import CardItem from "../components/card-item.vue";
import CardProgressItem from "../components/card-progress-item.vue";
import CardList from "../components/card-list.vue";
import {
  apiCall,
  downloadFile,
  getFileExtension,
  toFixed,
  createRef,
  useTimedTask,
  type TimedTask,
} from "@/utils";
import { wrapperCardOperations } from "../utils/helper";
import modalInstance from "@/components/common/custom-modal/instance";
import videoIcon from "@/assets/icons/space/icon_video.svg";
import { getVideoExportState } from "@/api";
import { useSubscriptionInfo } from "@/store/modules/user";
import { useTrackStore } from "@/store/modules/track";
import {
  getExportVideoList,
  deleteExportVideo,
  renameExportVideo,
} from "@/api/space";
import type { CardItemData, CardOperation, CardItemVNode } from "../utils/type";

type UsedCardItemData = CardItemData<SpaceResData.ExportVideoVO>;

const { collectData, track } = useTrackStore(); 

// ANCHOR - 变量声明
let requestTimedTask: TimedTask;
let progressTimedTask: TimedTask;
const size = 30;
const page = ref(1);
const router = useRouter();
const isLoading = ref(false);
const isLoaded = ref(false);
const renameInputRef = ref<null | HTMLInputElement>(null);
const cardListRef = ref(null as unknown as InstanceType<typeof CardList>);
const subscriptionInfo = useSubscriptionInfo();
const modalManager = modalInstance.modalManager!;
const batchDelete = inject<Set<string>>("batchDelete")!;
const refreshSpaceSize = inject("refreshSpaceSize") as () => void;
const items = reactive<UsedCardItemData[]>([]);
const cardOperations: CardOperation[] =
  wrapperCardOperations<SpaceResData.ExportVideoVO>(
    [
      {
        text: "Rename",
        iconName: "icon_rename",
        handler: (item) =>
          modalManager.applyTemplate("rename_card_item", {
            inputRef: renameInputRef,
            value: item.record.name,
            async onRename(name: string) {
              if (name === item.record.name || name === "") return;

              await apiCall(
                renameExportVideo,
                item.key,
                name.replace(/&/g, "%26"),
              );
              item.vnode.props!.info.title = item.record.name = name;
            },
          }),
      },
      // {
      //   text: 'Reuse',
      //   iconName: 'icon_reuse',
      //   handler: () => {},
      // },
      {
        text: "Download",
        iconName: "icon_download",
        handler: (item) =>
          downloadFile(
            item.record.downloadUrl,
            `${item.record.name}.${getFileExtension(item.record.downloadUrl)}`,
          ).then(() => {
            const { rid, reqId, draftId } = item.record;
            collectData("boolvideo_download", {
              rid,
              reqId,
              draftId
            });
            track("boolvideo_download");
          }),
      },
      {
        text: "Delete",
        iconName: "icon_delete",
        handler: (item, index) =>
          modalManager.applyTemplate("delete_card_item", {
            async onDelete() {
              await apiCall(deleteExportVideo, item.key);
              index !== -1 && items.splice(index, 1);
              refreshSpaceSize();
            },
          }),
      },
    ],
    items,
  );

// ANCHOR - 事件处理
async function handleLoadMore() {
  isLoading.value = true;

  const { records, total } = await apiCall(getExportVideoList, {
    page: page.value,
    size,
  }).finally(() => {
    isLoading.value = false;
  });

  if (total <= page.value++ * size) {
    isLoaded.value = true;
  }

  items.push(
    ...records.map((record) => ({
      key: record.exportId,
      record,
      vnode: renderCardItem(record),
    })),
  );

  nextTick(() => {
    cardListRef.value.canLoadMore() && nextTick(handleLoadMore);
  });

  progressTimedTask.run();
  requestTimedTask.run();
}

type ProgressRef = {
  id: string;
  ref: Ref<number>;
  startTime: number;
};

const progressRefs = [] as ProgressRef[];
function renderCardItem(record: SpaceResData.ExportVideoVO) {
  const size = (record.ratio || "9:16").split(":").map(Number);
  const posterStyle = ref(record.renderStatus === 0 ? "cursor: pointer" : "");
  const info = reactive({
    id: record.exportId,
    title: record.name,
    poster: record.coverPic,
    duration: record.duration,
    updateTime: record.exportTime,
    selected: false,
  });

  const handleClickPoster = () => {
    window.open(
      router.resolve({ path: "/space/previewer", query: { ...record } })
        .fullPath,
    );
  };

  const handlePosterLoadFail = () => {
    posterStyle.value = "width: 100%; background-color: #FFF";
    info.poster = videoIcon;

    if (size.length === 2) {
      posterStyle.value = `background-color: #FFF; aspect-ratio: ${size[0]}/${size[1]};`;
      if (size[0] > size[1]) {
        posterStyle.value += `width: min(100%, ${toFixed(
          (size[0] / size[1]) * 250,
          3,
        )}px); height: auto`;
      } else {
        posterStyle.value += "width: auto";
      }
    }
  };

  const usedProps = {
    info,
    posterStyle: createRef(posterStyle, "value"),
    operations: record.renderStatus === 2 ? [] : cardOperations,
    handleClickPoster:
      record.renderStatus === 0 ? handleClickPoster : undefined,
    handlePosterLoadFail:
      record.renderStatus === 1 ? undefined : handlePosterLoadFail,
  };

  switch (record.renderStatus) {
    case 1: {
      handlePosterLoadFail();
      usedProps.operations = usedProps.operations.filter(
        (operation) => operation.text === "Delete",
      );
    }
    case 0:
      return (<CardItem {...usedProps} />) as CardItemVNode;
    case 2: {
      const progressRef = {
        ref: ref(0),
        id: record.exportId,
        startTime: record.exportTime,
      };

      updateTime(progressRef);
      progressRefs.push(progressRef);

      return (
        <CardProgressItem
          {...usedProps}
          value={createRef(progressRef.ref, "value")}
          tip={"Exporting..."}
        />
      ) as CardItemVNode;
    }
  }
}

function updateTime(progressRef: ProgressRef) {
  if (progressRef.ref.value < 0.98) {
    progressRef.ref.value = Math.min(
      0.98,
      (Date.now() - progressRef.startTime) / 2e4,
    );
  }
}

batchDelete.delete = ((originalDelete) => {
  let lock = false;

  onBeforeUnmount(() => {
    batchDelete.delete = originalDelete;
  });

  return function (id: string) {
    if (lock) return Set.prototype.delete.call(toRaw(batchDelete), id);

    const index = items.findIndex((item) => item.key === id)!;
    index !== -1 && items.splice(index, 1);
    lock = true;

    const reslut = originalDelete.call(batchDelete, id);
    lock = false;

    return reslut;
  };
})(batchDelete.delete);

progressTimedTask = useTimedTask(
  Array.prototype.forEach.bind(progressRefs, updateTime),
);

requestTimedTask = useTimedTask(async () => {
  for (let i = progressRefs.length - 1; i >= 0; i--) {
    const progressRef = progressRefs[i];
    const record = await apiCall(getVideoExportState, progressRef.id).catch(
      () => {},
    );
    const targetNode = items.find(
      (item) => item.key === progressRef.id,
    ) as UsedCardItemData;

    // 网络错误
    if (record === undefined) {
      progressRefs.splice(i, 1);
      continue;
    }

    if (record.renderStatus === 2) {
      if (targetNode.record.coverPic !== record.coverPic) {
        targetNode.record.coverPic = record.coverPic;
        targetNode.vnode.props!.info.poster = record.coverPic;
      }

      continue;
    } else {
      progressRefs.splice(i, 1);
      targetNode.record = record;
      targetNode.vnode = renderCardItem(record);
    }

    if (record.renderStatus === 0) {
      subscriptionInfo.refresh();
    }
  }

  progressRefs.length === 0 && requestTimedTask.stop();
}, 3000);

watch(renameInputRef, (input) => {
  if (input !== null) {
    setTimeout(() => {
      input.focus();
      input.select();
    });
  }
});
</script>
