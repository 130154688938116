<template>
  <tools-modal v-model="visible" @close="handleClose" title="Image Enhancer">
    <image-enhancer
      @apply="handleApply"
      @processComplete="handleComplete"
      buttonText="Apply"
      positionBottom="30px"
      :originUrl="src"
      mode="editor"
    />
  </tools-modal>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import ImageEnhancer from "./imageEnhancer.vue";
import ToolsModal from "../../modal/toolsModal.vue";

const emit = defineEmits(["update:src", "update:modelValue", "change"]);
const props = defineProps({
  src: String,
  modelValue: Boolean,
});

const visible = ref(false);

watch(
  () => props.modelValue,
  (newValue) => {
    visible.value = newValue;
  },
);
watch(visible, (value) => {
  emit("update:modelValue", value);
});

const handleClose = () => {
  visible.value = false;
};

const handleApply = (url: string, previewUrl: string, width480: number, width1080: number) => {
  emit("update:src", url);
  emit("change", url, previewUrl, width480, width1080);
  handleClose();
};

const handleComplete = (url: string) => {
  console.log("complete", url);
};
</script>

<style lang="scss" scoped></style>
