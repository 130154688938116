import { CropRect, RectSize } from "../types/common";
import { DrawingCircularConfig } from "../types/dom";
import {
  MattingDrawingConfig,
  RenderInterpolationConfig,
} from "../types/drawing";
import {
  GLOBAL_COMPOSITE_OPERATION_DESTINATION_IN,
  GLOBAL_COMPOSITE_OPERATION_SOURCE_OVER,
  GRADIENT_BEGIN_OFFSET,
  GRADIENT_END_OFFSET,
  GRADIENT_INNER_RADIUS,
  ONE_TURN_DEGREES,
  REPAIR_POINT_INNER_COLOR,
  REPAIR_POINT_OUTER_COLOR,
  ZERO_DEGREES,
} from "./constants";
import { createContext2D, transformedDrawImage } from "./dom-helper";
import {
  computeInterpolationStep,
  computeMovements,
  needDrawInterpolation,
  needDrawInterpolationPoint,
} from "./drawing-compute";
import { fixed } from "./util";

/** 隐藏的辅助插值绘制的绘制上下文对象 */
const interpolationCtx = createContext2D();

/** 批量执行抠图(修补/擦除)绘制 */
export function executeMattingDrawing(config: MattingDrawingConfig) {
  const { radius } = config;
  const { maxMovement, unsignedMovementX, unsignedMovementY } =
    computeMovements(config);
  if (needDrawInterpolation(maxMovement, radius)) {
    renderMattingInterpolation({
      drawingConfig: config,
      unsignedMovementX,
      unsignedMovementY,
      maxMovement,
    });
  } else {
    drawMattingPoint(config);
  }
  drawResultArea(config);
}

/** 渲染插值图像区域 */
function renderMattingInterpolation(
  interpolationConfig: RenderInterpolationConfig,
) {
  const { drawingConfig, maxMovement } = interpolationConfig;
  const { step, stepBase, drawingCtx, radius, hardness } = drawingConfig;
  let { x, y } = drawingConfig;
  const { stepX, stepY } = computeInterpolationStep(interpolationConfig);
  resetInterpolationCtx(drawingCtx);
  for (
    let movement = 0, moved = movement;
    movement < maxMovement;
    movement += stepBase, x += stepX, y += stepY
  ) {
    if (needDrawInterpolationPoint(movement, moved, step)) {
      moved = movement;
      drawBrushPoint({
        ctx: interpolationCtx,
        x: fixed(x),
        y: fixed(y),
        radius,
        hardness,
      });
    }
  }
  drawMattingInterpolationTrack(drawingConfig);
}

/** 绘制插值轨迹 */
function drawMattingInterpolationTrack(drawingConfig: MattingDrawingConfig) {
  const { isErasing, hiddenCtx, drawingCtx } = drawingConfig;
  if (isErasing) {
    hiddenCtx.value.drawImage(interpolationCtx.canvas, 0, 0);
  } else {
    drawMattingTrack(drawingConfig, () => {
      drawingCtx.drawImage(interpolationCtx.canvas, 0, 0);
    });
  }
}

/** 重置用于插值绘制的画板 */
function resetInterpolationCtx(drawingCtx: CanvasRenderingContext2D) {
  const { width, height } = drawingCtx.canvas;
  interpolationCtx.canvas.width = width;
  interpolationCtx.canvas.height = height;
  interpolationCtx.clearRect(0, 0, width, height);
}

/** 绘制擦补/抠图区域的圆点 */
function drawMattingPoint(drawingConfig: MattingDrawingConfig) {
  const { isErasing, hiddenCtx, drawingCtx } = drawingConfig;
  const { x, y, radius, hardness } = drawingConfig;
  if (isErasing) {
    drawBrushPoint({ ctx: hiddenCtx.value, x, y, radius, hardness });
  } else {
    drawMattingTrack(drawingConfig, () => {
      drawBrushPoint({ ctx: drawingCtx, x, y, radius, hardness });
    });
  }
}

/** 绘制修补/扣除的轨迹 */
function drawMattingTrack(
  drawingConfig: MattingDrawingConfig,
  drawingCallback: VoidFunction,
) {
  const { hiddenCtx, drawingCtx, mattingSource } = drawingConfig;
  drawingCtx.globalCompositeOperation = GLOBAL_COMPOSITE_OPERATION_SOURCE_OVER;
  // mattingSource是指原图
  drawingCtx.drawImage(
    mattingSource,
    0,
    0,
    drawingCtx.canvas.width,
    drawingCtx.canvas.height,
  );
  drawingCtx.globalCompositeOperation =
    GLOBAL_COMPOSITE_OPERATION_DESTINATION_IN;
  drawingCallback();
  hiddenCtx.value.drawImage(drawingCtx.canvas, 0, 0);
}

/** 绘制擦补画笔圆点 */

export function drawBrushPoint(drawingConfig: DrawingCircularConfig) {
  const { ctx, x, y, radius, hardness } = drawingConfig;
  const {
    innerColor = REPAIR_POINT_INNER_COLOR,
    outerColor = REPAIR_POINT_OUTER_COLOR,
  } = drawingConfig;
  ctx.beginPath();
  const gradient = ctx.createRadialGradient(
    x,
    y,
    GRADIENT_INNER_RADIUS,
    x,
    y,
    radius,
  );
  gradient.addColorStop(GRADIENT_BEGIN_OFFSET, innerColor);
  gradient.addColorStop(hardness, innerColor);
  gradient.addColorStop(GRADIENT_END_OFFSET, outerColor);
  ctx.fillStyle = gradient;
  ctx.arc(x, y, radius, ZERO_DEGREES, ONE_TURN_DEGREES);
  ctx.fill();
}

/** 在呈现的画布上绘制图像 */
function drawResultArea(drawingConfig: MattingDrawingConfig) {
  const { ctx, hiddenCtx, positionRange, scaleRatio, isErasing } =
    drawingConfig;
  transformedDrawImage({
    ctx: ctx.value as CanvasRenderingContext2D,
    hiddenCtx: hiddenCtx.value,
    positionRange,
    scaleRatio,
    withBorder: isInResultBoard(isErasing),
  });
}

/** 绘制图像的画板是否为输出画板 */
export function isInResultBoard(isErasing: boolean | undefined) {
  return isErasing !== undefined;
}

/* 计算cover的裁剪区域 */
export function computeCropCropRect(
  sourceSize: RectSize,
  destSize: RectSize,
): CropRect {
  const sourceRatio = sourceSize.width / sourceSize.height;
  const destRatio = destSize.width / destSize.height;
  var sx;
  var sy;
  var tx;
  var ty;
  if (sourceRatio > destRatio) {
    sx = destSize.height / sourceSize.height;
    sy = destSize.height / sourceSize.height;
    tx =
      (sourceSize.width * (destSize.height / sourceSize.height) -
        destSize.width) /
      2;
    ty = 0;
  } else {
    sx = destSize.width / sourceSize.width;
    sy = destSize.width / sourceSize.width;
    tx = 0;
    ty =
      (sourceSize.height * (destSize.width / sourceSize.width) -
        destSize.height) /
      2;
  }
  return { sx, sy, tx, ty };
}

/* 计算cover的裁剪区域 */
export function computeCoverCropRect(
  sourceSize: RectSize,
  destSize: RectSize,
): CropRect {
  const sourceRatio = sourceSize.width / sourceSize.height;
  const destRatio = destSize.width / destSize.height;
  var sx;
  var sy;
  var tx;
  var ty;
  if (sourceRatio > destRatio) {
    sx = destSize.height / sourceSize.height;
    sy = destSize.height / sourceSize.height;
    tx =
      (sourceSize.width * (destSize.height / sourceSize.height) -
        destSize.width) /
      2;
    ty = 0;
  } else {
    sx = destSize.width / sourceSize.width;
    sy = destSize.width / sourceSize.width;
    tx = 0;
    ty =
      (sourceSize.height * (destSize.width / sourceSize.width) -
        destSize.height) /
      2;
  }
  return { sx, sy, tx, ty };
}

/* 在canvas上按cover的方式绘制背景图片 */
export function renderCoverBgImageInCanvas(
  bgImage: ImageBitmap,
  ctx: CanvasRenderingContext2D,
) {
  const cropRect = computeCoverCropRect(bgImage, ctx.canvas);
  ctx.save();
  ctx.translate(-cropRect.tx, -cropRect.ty);
  ctx.scale(cropRect.sx, cropRect.sy);
  ctx.drawImage(bgImage, 0, 0);
  ctx.restore();
}
