export const blendOptions = [
  {
    label: "Normal",
    value: "normal",
  },
  {
    label: "Add",
    value: "add"
  },
  {
    label: "Multiply",
    value: "multiply",
  },
  {
    label: "Screen",
    value: "screen",
  },
];

export const tagOptions = [
  {
    label: "logo",
    value: "logo",
  },
  {
    label: "simple",
    value: "simple",
  },
  {
    label: "shot",
    value: "shot",
  },
  {
    label: "hold",
    value: "hold",
  },
];

export const maskOptions = [
  {
    title: "None",
    name: "editor_none",
    size: 18,
    color: "#BBBFC4",
    style: { 
      background: "#F3F5F7",
    },
  },
  {
    title: "Rectangle",
    shape: "rect",
    name: "editor_rectangle",
    size: 46,
    color: "#FFFFFF",
    style: { 
      background: "#e5e7eb",
    },
  },
  {
    title: "Circle",
    shape: "circle",
    name: "editor_circle",
    size: 50,
    color: "#FFFFFF",
    style: { 
      background: "#e5e7eb",
    },
  },
];

export const toolOptions = [
  {
    id: 0,
    title: "Background Remover",
  },
  {
    id: 1,
    title: "Object Eraser",
  },
  {
    id: 2,
    title: "Image Enhancer",
  },
];