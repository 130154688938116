<template>
  <Slogan />
  <BrandEndorsement />
  <AiFeature />
  <Workflow />
  <UseCase />
  <CommentQuote />
  <BottomPart />
</template>

<script setup>
import { useTrackStore } from '@/store/modules/track';
import Slogan from './components/Slogan/index.vue';
import BrandEndorsement from './components/BrandEndorsement/index.vue';
import AiFeature from './components/AiFeature/index.vue';
import Workflow from './components/Workflow/index.vue';
import UseCase from './components/UseCase/index.vue';
import CommentQuote from './components/CommentQuote/index.vue';
import BottomPart from './components/bottom-part.vue';
import { useViewStore } from "@/store/view";
import precisoTrackEvent from '@/utils/precisoTrackEvent.ts';

const { track } = useTrackStore();
const viewStore = useViewStore();

onMounted(() => {
  const prevTheme = viewStore.header.theme;

  viewStore.header.theme = "light";
  onBeforeUnmount(() => {
    viewStore.header.theme = prevTheme;
  });
  precisoTrackEvent({
    'PageType': 'home'
  })
  track("boolvideo_homepage_view");
});

</script>
