<template>
  <div class="form-wrapper">
    <el-scrollbar>
      <div class="script-render-form-container">
        <div class="prompt-container">
          <p class="title">Idea prompt</p>

          <el-input
            v-model="prompt"
            resize="none"
            type="textarea"
            placeholder="Enter your idea to generate video"
            :rows="4"
            @input="handleInput"
            @focus="focus"
            @blur="blur"
          />

          <div
            class="prompt-popover"
            :class="{ hide: !showPrompt, appear: showPrompt }"
          >
            <p>Try one of these:</p>
            <div class="prompt-preset-grid">
              <div
                v-for="item in promptPreset"
                class="prompt-preset-grid-item"
                @mousedown.stop="clickPrompt(item.value)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
        <div class="duration-container">
          <p class="title">
            <span>Maximum duration </span>
            <span>{{ displayDuration }}</span>
          </p>
          <div class="input-container">
            <bv-slider
              v-model="duration"
              height="1"
              :min="30"
              :max="300"
              :step="30"
              :showStops="true"
              :showTooltip="false"
              @change="(v) => (duration = v)"
            />
          </div>
        </div>
        <div class="ratio-container">
          <p class="title">Ratio</p>
          <div class="select-container">
            <bv-select
              :showBoxShadow="true"
              :showBorder="false"
              v-model="ratio"
              :teleported="false"
              :options="ratioPreset"
              :popperStyle="{
                width: '50vw',
                maxWidth: '712px',
                minWidth: '500px',
              }"
              @change="(v) => (ratio = v)"
            />
          </div>
        </div>
        <div class="language-container">
          <p class="title">Language</p>
          <div class="select-container">
            <bv-select
              :showBoxShadow="true"
              :showBorder="false"
              v-model="language"
              popper-class="idea-language-select"
              searchPlaceholder="Search"
              :teleported="false"
              :showSearch="true"
              :options="languageOptions"
              :popperStyle="{
                width: '50vw',
                maxWidth: '712px',
                minWidth: '500px',
              }"
              @change="(v) => (language = v)"
            />
          </div>
        </div>
      </div>
    </el-scrollbar>

    <PrimaryButton class="button-container" padding="16px 48px" @click="submit">
      <div class="button-text">
        <svg-icon
          v-if="loading"
          class="loading-icon"
          name="icon_loading"
          :size="14"
        />
        <svg-icon class="star" name="icon_generate_star" :size="18" />
        Generate
      </div>
    </PrimaryButton>

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
  <div v-if="loading" class="loading-container">
    <GenerateLoading :tips="loadingTips" />
  </div>
</template>

<script setup>
import GenerateLoading from '@/components/common/generateLoading/index.vue';
import { renderVideoFromIdea } from '@/api/script';
import { getLanguageList } from '@/api/language';
import { useTrackStore } from '@/store/modules/track';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useMessage, removeEscapeHtml } from '@/utils';
import { validImport } from '@/pages/createVideos/utils/import';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import { sessionSetItem } from '@/utils/storage';
import { checkResource } from '@/api/script';

const { collectData, track } = useTrackStore();
const router = useRouter();
const message = useMessage();
const modalManager = useModalManager();

const prompt = ref('');
const ratio = ref('9:16');
const duration = ref(60);
const language = ref('');
const errorMsg = ref('');
const loading = ref(false);
const showPrompt = ref(false);
const languageOptions = ref([]);
const leftPage = ref(false);
const subscribeModalVisible = ref(false);

const promptPreset = [
  {
    label: 'Introducing Boolvideo',
    value:
      "Boolvideo - one of our flagship products - is designed to provide marketers with a powerful and easy-to-use video production tool that allows users to convert any form of content they have on hand into video. Not only that, but we've delved deep into video content commonly used in the marketing space, allowing users to intervene and customize it before it's generated, to ensure that the content generated is more in line with expectations",
  },
  {
    label: 'Introducing our real estate service',
    value:
      'Introducing our real estate service. Explain our professional agents and a wide range of services such as creating listings, taking pictures, and scheduling open houses, etc',
  },
  {
    label: 'Recipe for making classic cheesecake',
    value: 'Recipe for making classic cheesecake',
  },
  {
    label: 'Explain the importance of exercise',
    value:
      'Explain the importance of exercise and how it can benefit our physical and mental health',
  },
];
const ratioPreset = [
  {
    label: '9:16 ( Portrait )',
    value: '9:16',
  },
  {
    label: '1:1 ( Square )',
    value: '1:1',
  },
  {
    label: '16:9 ( Landscape )',
    value: '16:9',
  },
];
const loadingTips = [
  'Script generating',
  'Visuals generating',
  'Take a while...',
];
const displayDuration = computed(() => {
  if (duration.value < 60) {
    return duration.value + 's';
  } else {
    return duration.value / 60 + 'min';
  }
});

const clickPrompt = (value) => {
  prompt.value = value;
};

const handleInput = (inputValue) => {
  if (inputValue.trim() === '') {
    prompt.value = '';
  } else {
    prompt.value = inputValue;
  }

  showPrompt.value = prompt.value === '';
};

const submit = async () => {
  prompt.value = removeEscapeHtml(prompt.value);
  if (prompt.value.trim() === '') {
    message.error('Prompt can not be empty');
    return;
  }

  const params = {
    language: language.value,
    size: ratio.value,
    prompt: prompt.value,
    duration: duration.value,
  };

  sessionSetItem('generateParams', params);

  const { noCredit, msg, code } = await checkResource({
    resource: [90001],
  });

  if (code == 101011) {
    modalManager.applyTemplate('paymentFail', {});
    return;
  }

  if (noCredit) {
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  router.push({
    path: '/idea-to-video/generating',
  });
};

const setup = async () => {
  const languageHelper = (list) => {
    let temp = [];
    list.forEach((item) => {
      for (const i of item.localInfoList) {
        const newItem = {
          label: i.localName,
          value: i.locale,
        };
        temp.push(newItem);
      }
    });
    return temp;
  };
  const { success, data } = await getLanguageList({ filterSpecial: true });
  if (!success) return;

  languageOptions.value = languageHelper(data);
  language.value = getLocale();
};

const getLocale = () => {
  const { language } = window.navigator;
  if (['en', 'en-US'].includes(language)) return 'en-US';
  if (['zh', 'zh-CN'].includes(language)) return 'zh-CN';
  let lang = 'en-US';
  for (const item of languageOptions.value) {
    if (item.value.startsWith(language)) {
      lang = item.value;
      break;
    }
  }
  return lang;
};

const focus = () => {
  showPrompt.value = prompt.value === '';
};

const blur = () => {
  showPrompt.value = false;
  prompt.value = removeEscapeHtml(prompt.value);
};

onBeforeMount(setup);
onUnmounted(() => {
  leftPage.value = true;
});
</script>

<style lang="scss" scoped>
:deep(.el-scrollbar) {
  width: 100%;
}

:deep(.el-scrollbar__view) {
  display: flex;
  justify-content: center;
}

.form-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
}

.script-render-form-container {
  width: 50%;
  min-width: 500px;
  max-width: 712px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  color: #060606;
  padding-top: 48px;
  padding-bottom: 400px;

  & > div {
    width: 100%;
  }
}

.input-container {
  width: 100%;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.select-container {
  height: 46px;
}

:deep(.prompt-container .el-textarea__inner) {
  padding: 18px 20px;
}

.prompt-container {
  position: relative;
}

.prompt-popover {
  position: absolute;
  width: 100%;
  bottom: -4px;
  transform: translateY(100%);
  padding: 24px;
  border-radius: 4px;
  border: 0.5px solid #e5e7eb;
  background: #fff;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.06);
  z-index: 2;
  transition: opacity 0.08s linear 0s;

  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 18px;
  }
}

.prompt-preset-grid {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.prompt-preset-grid-item {
  width: 100%;
  height: 100%;
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fd;
  }
}

.button-container {
  position: absolute;
  bottom: 58px;
  width: fit-content !important;
}

.button-text {
  position: relative;
  width: 94px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  & > .star {
    margin-right: 6px;
  }

  & > .loading-icon {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    animation: rotate 1s linear infinite;
  }
}

.loading-container {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.hide {
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.appear {
  opacity: 1;
  pointer-events: all;
}

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
}

:deep(.script-render-form-container .el-slider__stop),
:deep(.script-render-form-container .el-slider__runway::after),
:deep(.script-render-form-container .el-slider__runway::before) {
  width: 1px;
  height: 2px;
  background: #8f959e;
}

:deep(.script-render-form-container .el-slider__runway) {
  height: 11px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  background-clip: padding-box;
}

:deep(.script-render-form-container .el-slider) {
  --el-slider-height: 1px;
  --el-slider-button-size: 18px;
  --el-slider-button-wrapper-size: 18px;
  --slider-main-color: #8f959e !important;
  --slider-runway-color: #e5e7eb !important;
  --slider-track-color: #8f959e !important;
}

:deep(.el-input__inner) {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #060606;
}

:deep(.el-textarea__inner) {
  font-family: Inter-variant !important;
  color: #060606;
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  border: 1px solid #e5e7eb;
  box-shadow: none;
  transition: all 200ms;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::placeholder {
    color: #8f959e;
  }
}

:deep(.bv-select-button .select-wapper) {
  padding: 12px 21px;
  box-shadow: 0 0 0 1px #e5e7eb inset !important;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

:deep(.bv-select-button .select-wapper.show-box-shadow.is-focus) {
  padding: 12px 21px;
  box-shadow: 0 0 0 1px #875eff inset !important;
}

:deep(.bv-select-button .select-value) {
  color: #060606;
  font-size: 14px;
  line-height: 20px;
}

.prompt-container :deep(.el-textarea__inner) {
  box-shadow: 0 0 0 1px #e5e7eb inset !important;
  border: none;
}

.prompt-container :deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #e5e7eb inset !important;
  box-shadow: none;
  transition: all 200ms;
  padding: 0 12px;
}

.prompt-container :deep(.el-input .el-input__wrapper:hover),
:deep(.el-textarea__inner:hover) {
  box-shadow: 0 0 0 1px #be9fff inset !important;
}

.prompt-container :deep(.el-input .el-input__wrapper.is-focus),
:deep(.el-textarea__inner:focus) {
  box-shadow: 0 0 0 1px #875eff inset !important;
}

.prompt-container :deep(.el-input__prefix) {
  margin-right: 6px;
  color: #646a73;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.idea-language-select .bv-select-dropdown) {
  height: 400px;
}
</style>

<style lang="scss">
.bv-select-dropdown.idea-language-select ul {
  max-height: 250px;
}
</style>
