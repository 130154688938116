<template>
  <li class="square-container">
    <div class="square-box bg-bgGrey" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave">
      <video :src="item.url" class="square-img cursor-pointer" :poster="item.coverPic"
        v-if="item.state == 0 && item.url && item.type == 'video'" @click="handlePreview(item)" />

      <el-image style="width: 100%; height: 100%; cursor: pointer" :src="item.url" :zoom-rate="1.2"
        :initial-index="index - 1" fit="contain" v-else-if="item.state == 0 && item.url" @click="handlePreview(item)">
        <template #error>
          <div style="width: 100%; height: 100%" class="flex justify-center items-center">
            <svg-icon name="icon_error_image" :style="{ width: '30px', height: '30px' }"></svg-icon>
          </div>
        </template>
      </el-image>

      <div style="width: 100%; height: 100%" class="flex justify-center items-center" v-else-if="item.state == 1"
        @click.capture="handleReTry">
        <el-image style="width: 100%; height: 100%" :src="item.fileUrl" :zoom-rate="1.2" fit="contain">
        </el-image>

        <div class="mask position absolute top-0 left-0 w-full h-full bg-black"></div>

        <div class="position absolute z-10 w-full h-full">
          <div class="flex justify-center items-center flex-col w-full h-full">
            <svg-icon name="icon_warning" :style="{ width: '24px', height: '24px', marginBottom: '6px' }"></svg-icon>
            <p class="text-sm text-white mb-4 leading-22 font-normal">
              Upload failed, Click retry
            </p>
          </div>
        </div>
      </div>

      <!-- 上传中 -->
      <div style="width: 100%; height: 100%" class="flex justify-center items-center cursor-default"
        v-else-if="item.state == 2">
        <svg-icon name="icon_video" v-if="item.type == 'video'" :size="36" style="margin-top: -4px"></svg-icon>

        <el-image style="width: 100%; height: 100%" :src="item.fileUrl" :zoom-rate="1.2" fit="contain" v-else>
        </el-image>

        <div class="mask position absolute top-0 left-0 w-full h-full bg-black"></div>

        <div class="position absolute z-10 w-full h-full">
          <div class="flex justify-center items-center flex-col w-full h-full">
            <p class="text-sm text-white mb-4 leading-22 font-normal">
              Uploading...
            </p>
            <el-progress :percentage="item.percent" :style="{ width: '60%' }" :show-text="false" />
          </div>
        </div>
      </div>

      <div style="width: 100%; height: 100%" class="flex justify-center items-center" v-else-if="item.state == 2">
        <el-progress :percentage="item.percent" :style="{ width: '60%' }" :show-text="false" />
      </div>

      <slot name="upload" v-else></slot>
      <div class="absolute top bottom-0 left-0 right-0 z-10" v-if="(isMove && !item.upload && item.state === 0 && !isPreviewing) ||
        (isMove && !item.upload && item.state === 1 && !isPreviewing)
        ">
        <slot name="delete-icon" v-if="slots['delete-icon']" :item="item"></slot>
      </div>
      <div class="video-duration" v-if="item.state == 0 && item.type == 'video'">
        {{ formatDuration(item.duration || 0) }}
      </div>
    </div>
    <teleport :to="previewerRoot">
      <material-previewer ref="previewerRef" :record="previewData" @change="changePreview" :canScroll="false" />
    </teleport>
  </li>
</template>
<script lang="ts" setup>
import { ref, useSlots, watch, type PropType } from "vue";
import type { ImageType } from "./type";
import MaterialPreviewer from "@/components/material-previewer/index.vue";

type PreviewType = {
  mid: string;
  preview1080Url: string;
  type: string;
  duration: number;
  coverPic: string;
};

const slots = useSlots();
const emits = defineEmits(["uploadRetry"]);
const props = defineProps({
  item: {
    type: Object as PropType<ImageType>,
    default: () => {
      return {};
    },
  },

  index: {
    type: Number,
    default: 1,
  },

  imageList: {
    type: Array as PropType<ImageType[]>,
    default: () => {
      return [];
    },
  },
  selectedIdList: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const previewList = ref<PreviewType[]>([]);
const previewData = ref<PreviewType>(props.item as any);
const isMove = ref(false);
const isPreviewing = ref(false);
const previewerRef = ref(null);
const previewerRoot = document.createElement("div");
document.body.appendChild(previewerRoot);

watch(
  props.item as any,
  (value) => {
    previewData.value = {
      mid: value.mid!,
      coverPic: value.coverPic,
      preview1080Url: value.preview1080Url || value.url,
      type: value.type!,
      duration: value.duration,
    };
  },
  {
    immediate: true,
  },
);

watch(
  () => props.imageList,
  (list) => {
    previewList.value = list
      .filter((item) => item.url)
      .map((image: any) => {
        return {
          mid: image.mid,
          coverPic: image.coverPic,
          preview1080Url: image.preview1080Url || image.url,
          type: image.type,
        };
      }) as PreviewType[];
  },
  {
    deep: true,
    immediate: true,
  },
);

const handleMouseMove = () => {
  isMove.value = true;
};

const handleMouseLeave = () => {
  isMove.value = false;
};

const handleReTry = () => {
  emits("uploadRetry", props.item);
};

const changePreview = (mid: string, direction: number) => {
  const index = previewList.value?.findIndex((e) => e.mid == mid);
  let item = null;
  if (direction == 1) {
    item = previewList.value[index + 1] as PreviewType;
  } else if (direction == -1) {
    item = previewList.value[index - 1] as PreviewType;
  }

  if (!item) {
    return;
  }

  previewData.value = {
    mid: item.mid,
    preview1080Url: item.preview1080Url || item.url,
    type: item.type,
    coverPic: item.coverPic,
    duration: item.duration,
  } as PreviewType;
};

const handlePreview = (item: any) => {
  previewData.value = {
    mid: item.mid,
    preview1080Url: item.preview1080Url || item.url,
    type: item.type,
    coverPic: item.coverPic,
    duration: item.duration,
  };
  previewerRef.value!.open = true;
};

const formatDuration = (duration: number) => {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${minutes | 0}:${(seconds | 0).toString().padStart(2, "0")}`;
};
</script>

<style lang="scss" scoped>
.square-container {
  padding-bottom: 100%;
  height: 0;
  width: 100%;
  position: relative;
}

.square-container .square-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.square-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.mask {
  opacity: 54%;
}

.video-duration {
  position: absolute;
  left: 14px;
  bottom: 14px;
  padding-inline: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
}
</style>

<style lang="scss">
.square-container {
  .el-progress-bar__outer {
    background-color: #7a7b7e;
    border-radius: 0;
    height: 4px !important;
  }

  .el-progress-bar__inner {
    background-color: #fff;
    border-radius: 0;
  }
}
</style>
