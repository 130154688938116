<template>
  <div class="privitePolicyOuter">
    <div class="privitePolicyWrapper">
      <div class="privitePolicyContainer">
        <h1 class="headerTitle">{{currentProject.title}}</h1>
        <section class="paragraph">
          Welcome to Boolv.tech Ltd. ("<span class="fontBold">Company</span>", "<span class="fontBold">we</span>", "<span
            class="fontBold">us</span>", or "<span class="fontBold">our</span>"). We are committed to
          protecting your personal information and your right to privacy. When you visit our website
          <a class="linkName" href="/" target="_blanck">
            {{currentProject.name}} (the "<span class="fontBold">Website</span>")
          </a>
          , and more generally, use any of our services (the "<span
            class="fontBold">Services</span>", which
          include the Website), we appreciate that you are trusting us with your personal information.
        </section>
        <section class="paragraph">
          We take your privacy very seriously. In this privacy notice, we seek to explain to you in the
          clearest way possible what information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it carefully, as it is important. If there
          are any terms in this privacy notice that you do not agree with, please discontinue the use of our
          Services immediately.
        </section>
        <section class="paragraph">
          This privacy notice applies to all information collected through our Services (which, as described
          above, includes our Website), as well as any related services, sales, marketing or events.
        </section>

        <h2 class="title">WHAT INFORMATION DO WE COLLECT</h2>
        <p class="smallTitle">The personal information you disclose to us</p>
        <p class="inShort">We collect information that you provide to us.</p>
        <section class="paragraph">
          We may collect personal information that you voluntarily provide to us when you register on the
          Website, express an interest in obtaining information about us or our products and Services or
          otherwise when you contact us.
        </section>
        <section class="paragraph">
          The personal information that we may collect depends on the context of your interactions with us
          and the Website, the choices you make and the products and features you use. The personal
          information we collect may include the following:
        </section>
        <section class="paragraph">
          Personal Information Provided by You - We collect names; email addresses; passwords;
          uploaded content and metadata such as the source of that content (collectively, "<span class="fontBold">Personal
            Information</span>").
        </section>

        <p class="smallTitle">Information automatically collected</p>
        <p class="inShort">
          Some information — such as your Internet Protocol (IP) address and/or browser and
          device characteristics — is collected automatically when you visit our Website.
        </p>
        <section class="paragraph">
          When you access our websites, we, our service providers and our partners may automatically
          collect information about you, your computer or mobile device, and your activity on our
          websites. Typically, this information includes your computer or mobile device operating system
          type and version number, manufacturer and model, device identifier, browser type, screen
          resolution, IP address, the website you visited before browsing to our website, general location
          information such as city, state or geographic area; and information about your use of and actions
          on or in our websites, such as pages or screens you accessed, how long you spent on a page or
          screen, navigation paths between pages or screens, information about your activity on a page or
          screen, access times, and length of access.
        </section>
        <section class="paragraph">
          Our service providers and business partners may collect this type of information over time and
          across third-party websites. This information is collected via various mechanisms, such as via
          cookies, web beacons, embedded scripts, and similar technologies. This type of information may
          also be collected when you read our HTML-enabled emails.
        </section>
        <section class="paragraph">
          The information we collect includes:
        </section>
        <ul>
          <li class="listItem">
            <span class="fontBold">Log and Usage Data.</span>
            Log and usage data is service-related, diagnostic usage and
            performance information our servers automatically collect when you access or use our
            Website and which we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser type and settings and
            information about your activity on the Website (such as the date/time stamps associated
            with your usage, pages and files viewed, searches and other actions you take such as
            which features you use), device event information (such as system activity, error reports
            (sometimes called 'crash dumps') and hardware settings).
          </li>
          <li class="listItem">
            <span class="fontBold">Device Data.</span>
            We collect device data such as information about your computer, phone,
            tablet or other devices you use to access the Website. Depending on the device used, this
            device data may include information such as your IP address (or proxy server), device
            application identification numbers, location, browser type, hardware model Internet
            service provider and/or mobile carrier, and operating system configuration information.
          </li>
          <li class="listItem">
            <span class="fontBold">Location Data.</span>
            We collect information data such as information about your device's
            location, which can be either precise or imprecise. How much information we collect
            depends on the type of settings of the device you use to access the Website. For example,
            we may use GPS and other technologies to collect geolocation data that tells us your
            current location (based on your IP address). You can opt out of allowing us to collect this
            information either by refusing access to the information or by disabling your Locations
            settings on your device. Note however, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </li>
        </ul>

        <h2 class="title">HOW DO WE USE YOUR INFORMATION</h2>
        <p class="inShort">
          We process your information for purposes based on legitimate business interests, the
          fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
        </p>

        <section class="paragraph">
          We use personal information collected via our Website for a variety of business purposes
          described below. We process your personal information for these purposes in reliance on our
          legitimate business interests, in order to enter into or perform a contract with you, with your
          consent, and/or for compliance with our legal obligations. We indicate the specific processing
          grounds we rely on next to each purpose listed below.
        </section>

        <section class="paragraph">
          We use the information we collect or receive:
        </section>

        <ul>
          <li class="listItem">
            <span class="fontBold">To facilitate account creation and logon process.</span>
            If you choose to link your account
            with us to a third-party account (such as your Google account), we use the information
            you allowed us to collect from those third parties to facilitate account creation and the
            logon process for the performance of the contract. See the section below headed "HOW
            DO WE HANDLE YOUR SOCIAL LOGINS" for further information.
          </li>
          <li class="listItem">
            <span class="fontBold">To edit information.</span>
            You may review and edit any of your Information by using your
            e-mail address and password. If you cancel your account, your Personal Information shall
            be deleted from our database except for residual information within our databases,
            aggregate information access logs, backup logs and other records, which may or may not
            contain personally identifiable information.
          </li>
          <li class="listItem">
            <span class="fontBold">To send administrative information to you.</span>
            We may use your personal information to
            send you product, service and new feature information and/or information about changes
            to our terms, conditions, and policies.
          </li>
          <li class="listItem">
            <span class="fontBold">To provide and protect our Services.</span>
            We may use your personal information as part of
            our efforts to keep our Website safe and secure (for example, for fraud monitoring and
            prevention) and provide you with our video making and image editing services.
          </li>
          <li class="listItem">
            <span class="fontBold">
              To enforce our terms, conditions and policies for business purposes, to comply with
              legal and regulatory requirements or in connection with our contract.
            </span>
          </li>
          <li class="listItem">
            <span class="fontBold">To respond to legal requests and prevent harm. </span>
            If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to
            respond.
          </li>
        </ul>

        <h2 class="title">WILL YOUR INFORMATION BE SHARED WITH ANYONE</h2>
        <p class="smallTitle">
          We only share information with your consent, to comply with laws, to provide you with
          services, to protect your rights, or to fulfill business obligations.
        </p>
        <section class="paragraph">
          We may process or share the data that we hold based on the following legal basis:
        </section>
        <ul>
          <li class="listItem">
            <span class="fontBold">Consent: </span>
            We may process your data if you have given us specific consent to use your
            personal information for a specific purpose.
          </li>
          <li class="listItem">
            <span class="fontBold">Legitimate Interests: </span>
            We may process your data when it is reasonably necessary to
            achieve our legitimate business interests.
          </li>
          <li class="listItem">
            <span class="fontBold">Performance of a Contract: </span>
            Where we have entered into a contract with you, we may
            process your personal information to fulfil the terms of our contract.
          </li>
          <li class="listItem">
            <span class="fontBold">Legal Obligations: </span>
            We may disclose your information where we are legally required to
            do so in order to comply with applicable law, governmental requests, a judicial
            proceeding, court order, or legal processes, such as in response to a court order or a
            subpoena (including in response to public authorities to meet national security or law
            enforcement requirements).
          </li>
          <li class="listItem">
            <span class="fontBold">Vital Interests: </span>
            We may disclose your information where we believe it is necessary to
            investigate, prevent, or take action regarding potential violations of our policies,
            suspected fraud, situations involving potential threats to the safety of any person and
            illegal activities, or as evidence in litigation in which we are involved.
          </li>
        </ul>

        <section class="paragraph">
          More specifically, we may need to process your data or share your personal information in the following
          situations:
        </section>

        <ul>
          <li class="listItem">
            <span class="fontBold">Business Transfers. </span>
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.
          </li>
          <li class="listItem">
            <span class="fontBold">Third-Party Advertisers. </span>
            We may use third-party advertising companies to serve ads
            when you visit or use the Website. These companies may use information about your
            visits to our Website(s) and other websites that are contained in web cookies and other
            tracking technologies in order to provide advertisements about goods and services of
            interest to you. For Example Google Analytics
          </li>
          <li class="listItem">
            <span class="fontBold">Affiliates. </span>
            We may share your information with our affiliates, in which case we will
            require those affiliates to honour this Policy. Affiliates include our parent company and
            any subsidiaries, joint venture partners or other companies that we control or that are
            under common control with us.
          </li>
          <li class="listItem">
            <span class="fontBold">Business Partners. </span>
            We may share your information with our business partners to offer
            you certain products, services or promotions.
          </li>
        </ul>

        <p class="title">HOW DO WE HANDLE YOUR SOCIAL LOGINS</p>
        <p class="inShort">
          If you choose to register or log in to our services using a social media account, we
          may have access to certain information about you.
        </p>
        <section class="paragraph">
          Our Website offers you the ability to register and login using your third-party social media
          account details (like your Google logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The profile Information we
          receive may vary depending on the social media provider concerned, but will often include your
          name, email address, friends list, profile picture as well as other information you choose to make
          public on such social media platform.
        </section>
        <section class="paragraph">
          We will use the information we receive only for the purposes that are described in this privacy
          notice or that are otherwise made clear to you on the relevant Website. Please note that we do not
          control, and are not responsible for, other uses of your personal information by your third-party
          social media provider. We recommend that you review their privacy notice to understand how
          they collect, use and share your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </section>

        <p class="title">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES</p>
        <p class="inShort">
          We may use cookies and other tracking technologies to collect and store your information.
        </p>
        <section class="paragraph">
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
          store information. Specific information about how we use such technologies and how you can
          refuse certain cookies is set out in our Cookie Policy.
        </section>

        <p class="title">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES</p>
        <p class="inShort">
          We are not responsible for the safety of any information that you share with third-party
          providers who advertise, but are not affiliated with, our Website.
        </p>
        <section class="paragraph">
          The Website may contain third parties links and which may link to other websites like Instagram,
          Facebook etc. We cannot guarantee the safety and privacy of the data you provide to any third
          parties. Any data collected by third parties is not covered by this Policy. We are not responsible
          for the content or privacy and security practices and policies of any third parties, including other
          websites, services or applications that may be linked to or from the Website. We strongly advise
          you to review the Privacy Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any third-party sites or services.
        </section>
        <section class="paragraph">
          WE HEREBY DISCLAIM LIABILITY FOR, ANY INFORMATION, MATERIALS,
          PRODUCTS, OR SERVICES POSTED OR OFFERED AT ANY OF THE THIRD-PARTY
          SITES LINKED TO THIS WEBSITE. BY CREATING A LINK TO A THIRD-PARTY
          WEBSITE, WE DO NOT ENDORSE OR RECOMMEND ANY PRODUCTS OR SERVICES
          OFFERED OR INFORMATION CONTAINED ON THAT WEBSITE, NOR ARE WE LIABLE
          FOR ANY FAILURE OF PRODUCTS OR SERVICES OFFERED OR ADVERTISED AT
          THOSE SITES. SUCH A THIRD PARTY MAY HAVE A PRIVACY POLICY DIFFERENT
          FROM THAT OF OURS AND THE THIRD-PARTY WEBSITE MAY PROVIDE LESS
          SECURITY THAN THIS SITE.
        </section>

        <p class="title">HOW LONG DO WE KEEP YOUR INFORMATION</p>
        <p class="inShort">
          We keep your information for as long as necessary to fulfill the purposes outlined in
          this privacy notice unless otherwise required by law.
        </p>
        <section class="paragraph">
          We will only keep your personal information for as long as it is necessary for the purposes set
          out in this Policy unless a longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this Policy will require us keeping your
          personal information for longer than the period of time in which users have an account with us.
          When we have no ongoing legitimate business need to process your personal information, we
          will either delete or anonymize such information or, if this is not possible (for example, because
          your personal information has been stored in backup archives), then we will securely store your
          personal information and isolate it from any further processing until deletion is possible.
        </section>

        <p class="title">HOW DO WE KEEP YOUR INFORMATION SAFE</p>
        <p class="inShort">
          We aim to protect your personal information through a system of organizational and
          technical security measures.
        </p>
        <section class="paragraph">
          We have implemented appropriate technical and organizational security measures designed to
          protect the security of any personal information we process. However, despite our safeguards and
          efforts to secure your information, no electronic transmission over the Internet or information
          storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that
          hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our
          security, and improperly collect, access, steal, or modify your information. Although we will do
          our best to protect your personal information, the transmission of personal information to and
          from our Website is at your own risk. You should only access the Website within a secure
          environment.
        </section>

        <p class="title">WHAT ARE YOUR PRIVACY RIGHTS</p>
        <p class="title">YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION REGULATION (GDPR).</p>
        <section class="paragraph">
          If you are a resident of the European Union (EU) and European Economic Area (EEA), you have
          certain data protection rights, covered by GDPR. We aim to take reasonable steps to allow you to
          correct, amend, delete, or limit the use of your Personal Data. If you wish to be informed of what
          Personal Data we hold about you and if you want it to be removed from our systems, please
          email us at support@boolvector.com.
        </section>

        <p class="title">Data Controller</p>
        <section class="paragraph">
          We are the data controller of the personal data we collect via the Service. Boolv.tech Ltd. is the
          data controller of the personal data we collect via the Services and use and share as further
          described above. We are located at 1309 Coffeen Avenue STE 1200, Sheridan, Wyoming, 82801.
        </section>

        <p class="title">Your EU Rights</p>
        <section class="paragraph">
          In certain circumstances, you have the following data protection rights:
          <p class="identation">1. The right to access, update or to delete the information we have on you;</p>
          <p class="identation">2. The right of rectification. You have the right to have your information rectified if
            thatinformation is inaccurate or incomplete;</p>
          <p class="identation">3. The right to object. You have the right to object to our processing of your Personal
            Data;</p>
          <p class="identation">4. The right of restriction. You have the right to request that we restrict the processing
            of your personal information;</p>
          <p class="identation">5. The right to data portability. You have the right to be provided with a copy of your
            Personal Data in a structured, machine-readable and commonly used format;</p>
          <p class="identation">6. The right to withdraw consent. You also have the right to withdraw your consent at any
            time where we rely on your consent to process your personal information;</p>
        </section>
        <section class="paragraph">
          Please note that we may ask you to verify your identity before responding to such requests.
          Please note, we may not be able to provide Service without some necessary data.
        </section>
        <section class="paragraph">
          You have the right to complain to a Data Protection Authority about our collection and use of
          your Personal Data. For more information, please contact your local data protection authority in
          the European Economic Area (EEA).
        </section>

        <p class="title">YOUR DATA PROTECTION RIGHTS UNDER THE CALIFORNIA PRIVACY PROTECTION ACT (CALOPPA).</p>
        <section class="paragraph">
          According to CalOPPA, we agree to the following:
          <p class="identation">1. our Privacy Policy link includes the word "Privacy", and can easily be found on the home
            page of our website;</p>
          <p class="identation">2. users will be notified of any privacy policy changes on our Privacy Policy Page;</p>
          <p class="identation">3. users are able to change their personal information by emailing us at the contact details
            mentioned in the "Contact Us" clause.</p>
        </section>
        <section class="paragraph">
          Our Policy on <span class="fontBold">"Do Not Track"</span> Signals:
        </section>
        <section class="paragraph">
          We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do
          Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web
          browser to inform websites that you do not want to be tracked.
        </section>

        <p class="title">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS.</p>
        <p class="inShort">Yes, if you are a resident of California, you are granted specific rights regarding access to
          your personal information.</p>
        <section class="paragraph">
          California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
          users who are California residents to request and obtain from us, once a year and free of charge,
          information about categories of personal information (if any) we disclosed to third parties for
          direct marketing purposes and the names and addresses of all third parties with which we shared
          personal information in the immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in writing to us using the
          contact information provided below.
        </section>
        <section class="paragraph">
          If you are under 18 years of age, reside in California, and have a registered account with the
          Website, you have the right to request removal of unwanted data that you publicly post on the
          Website. To request removal of such data, please contact us using the contact information
          provided below, and include the email address associated with your account and a statement that
          you reside in California. We will make sure the data is not publicly displayed on the Website, but
          please be aware that the data may not be completely or comprehensively removed from all our
          systems (e.g. backups, etc.).
        </section>

        <p class="title">CCPA Privacy Notice</p>
        <section class="paragraph">
          The California Code of Regulations defines a <span class="fontBold">"resident"</span> as:
          <p class="identation">a. every individual who is in the State of California for other than a temporary or
            transitory purpose</p>
          <p class="identation">b. every individual who is domiciled in the State of California who is outside the State of
            California for a temporary or transitory purpose</p>
          All other individuals are defined as <span class="fontBold">"non-residents."</span>
        </section>
        <section class="paragraph">
          If this definition of "resident" applies to you, certain rights and obligations apply regarding your
          personal information.
        </section>
        <section class="paragraph">
          <p class="smallTitle">Your rights with respect to your personal data</p>
          <div class="identation">
            <p class="smallTitle">a. Right to request deletion of the data - Request to delete</p>
            <div class="identation">
              You can ask for the deletion of your personal information. If you ask us to delete your
              personal information, we will respect your request and delete your personal information,
              subject to certain exceptions provided by law, such as (but not limited to) the exercise by
              another consumer of his or her right to free speech, our compliance requirements
              resulting from a legal obligation or any processing that may be required to protect against
              illegal activities.
            </div>
          </div>
          <div class="identation">
            <p class="smallTitle">b. Right to be informed - Request to know</p>
            <div class="identation">
              Depending on the circumstances, you have a right to know:
              <li class="listItem">whether we collect and use your personal information;</li>
              <li class="listItem">the categories of personal information that we collect;</li>
              <li class="listItem">the purposes for which the collected personal information is used;</li>
              <li class="listItem">whether we sell your personal information to third parties;</li>
              <li class="listItem">the categories of personal information that we sold or disclosed for a business purpose;
              </li>
              <li class="listItem">the categories of third parties to whom the personal information was sold or disclosed
                for</li>
              <li class="listItem">a business purpose;</li>
              <li class="listItem">the business or commercial purpose for collecting or selling personal information.</li>
              In accordance with applicable law, we are not obligated to provide or delete consumer
              information that is de-identified in response to a consumer request or to re-identify
              individual data to verify a consumer request
            </div>
          </div>
          <div class="identation">
            <p class="smallTitle">c. Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights</p>
            <div class="identation">
              <section class="paragraph">
                We will not discriminate against you if you exercise your privacy rights.
              </section>
              <section class="paragraph">
                Upon receiving your request, we will need to verify your identity to determine you are
                the same person about whom we have the information in our system. These verification
                efforts require us to ask you to provide information so that we can match it with the
                information you have previously provided us. For instance, depending on the type of
                request you submit, we may ask you to provide certain information so that we can match
                the information you provide with the information we already have on file, or we may
                contact you through a communication method (e.g. phone or email) that you have
                previously provided to us. We may also use other verification methods as the
                circumstances dictate.
              </section>
              <section class="paragraph">
                We will only use personal information provided in your request to verify your identity or
                authority to make the request. To the extent possible, we will avoid requesting additional
                information from you for the purposes of verification. If, however, if we cannot verify
                your identity from the information already maintained by us, we may request that you
                provide additional information for the purposes of verifying your identity, and for
                security or fraud-prevention purposes. We will delete such additionally provided
                information as soon as we finish verifying you.
              </section>
            </div>
          </div>
          <div class="identation">
            <p class="smallTitle">d. Other privacy rights</p>
            <div class="identation">
              <li class="listItem">you may object to the processing of your personal data.</li>
              <li class="listItem">you may request correction of your personal data if it is incorrect or no longer
                relevant, or ask to restrict the processing of the data.</li>
              <li class="listItem">
                you can designate an authorized agent to make a request under the CCPA on your
                behalf. We may deny a request from an authorized agent that does not submit
                proof that they have been validly authorized to act on your behalf in accordance
                with the CCPA.
              </li>
              <li class="listItem">
                you may request to opt-out from future selling of your personal information to
                third parties. Upon receiving a request to opt-out, we will act upon the request as
                soon as feasibly possible, but no later than 15 days from the date of the request
                submission.
              </li>
              To exercise these rights, you can contact us by referring to the contact details at the
              bottom of this document. If you have a complaint about how we handle your data, we
              would like to hear from you.
            </div>
          </div>
        </section>

        <p class="title">CONTROLS FOR DO-NOT-TRACK FEATURES</p>
        <section class="paragraph">
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature
          or setting you can activate to signal your privacy preference not to
          have data about your online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT signals has been finalized.
          As such, we do not currently respond to DNT browser signals or any other mechanism that
          automatically communicates your choice not to be tracked online. If a standard for online
          tracking is adopted that we must follow in the future, we will inform you about that practice in a
          revised version of this privacy notice.
        </section>

        <p class="title">CHILDREN'S PRIVACY</p>
        <section class="paragraph">
          Our Services are not for the use of children below the age of 18 ("Child" or "Children")
          without legal guardian's consent. We do not knowingly collect personally identifiable
          information from Children under 18. If you become aware that a Child has provided us with
          Personal Data without the legal guardian's consent, please contact us. If we become aware that
          we have collected Personal Data from Children without legal guardian's consent, we take steps
          to remove that information from our servers.
        </section>

        <p class="title">PAYMENTS</p>
        <section class="paragraph">
          We provide paid products and/or services within the Service. In that case, we use third-party
          services for payment processing (i.e Stripe). We don't store or collect your payment details. That
          information is provided directly to our third-party payment processors whose use of your
          personal information is governed by their Privacy Policy. These payment processors adhere to
          the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
          requirements help ensure the secure handling of payment information.
        </section>

        <p class="title">DO WE MAKE UPDATES TO THIS NOTICE</p>
        <p class="inShort">Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
        <section class="paragraph">
          We may update this privacy notice from time to time. The updated version will be indicated by
          an updated "Last updated" date and the updated version will be effective as soon as it is
          accessible. If we make material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending you a notification. We
          encourage you to review this privacy notice frequently to be informed of how we are protecting
          your information.
        </section>

        <p class="title">CONTACT US</p>
        <section class="paragraph">
          You may direct questions concerning this Privacy Policy by email to
          <a href="mailto:support@boolvector.come" target="_blank" rel="noreferrer">
            support@boolvector.com
          </a>
          by adding the word 'privacy' in the subject line.
        </section>
        <section class="paragraph">
          Last Updated: April 3, 2024
        </section>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useViewStore } from "@/store/view";

const viewStore = useViewStore();

const globalDomain = inject('globalDomain');
const projectConfig = {
  BoolVideo: {
    name: 'Boolv.video',
    url: 'https://boolv.tech/#/',
    title: 'Boolvideo AI Video Generator - Privacy Policy',
  },
  SimilarVideo: {
    name: 'similarvideo.ai',
    url: 'https://similarvideo.ai/#/',
    title: 'Similar Video Generator - Privacy Policy',
  },
};

const currentProject = computed(() => {
  if (globalDomain == 2) {
    return projectConfig.SimilarVideo;
  }

  if (globalDomain == 1) {
    return projectConfig.BoolVideo;
  }
});

onMounted(() => {
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth'
  });
  viewStore.header.theme = "light";
});
</script>
<style lang="scss" scoped>
.privitePolicyOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privitePolicyWrapper {
  padding: 140px 0;
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  text-align: left;
}

.privitePolicyContainer {
  max-width: 958px;
  padding-inline: 5%;
}

.headerTitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 36px;
}

.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
  margin-bottom: 24px;
}


.title {
  font-weight: 500;
  font-size: 26px;
  color: #000000;
  margin-bottom: 24px;
  line-height: 1.2;
}

.smallTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
}

.table {
  tr,
  td,
  th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 10px;
  }

  margin: 14px 0;
}

.anchorPoint {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #875eff;
  padding-bottom: 36px;
}

.linkName {
  color: #875eff;
}

.fontBold {
  font-weight: 500;
}

.identation {
  margin: 14px 0 14px 1.5rem;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
}

li.listItem {
  list-style-type: none;
}

.listItem {
  margin: 14px 0;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
}

.listItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

.subListItem {
  text-indent: 1.5rem;
  // padding-top: 10px;
}

.subListItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

.inShort {
  font-style: italic;
  padding-bottom: 12px;
}

.inShort:before {
  content: "In Short: ";
  font-weight: 500;
  color: #000000;
}

a {
  color: #875eff;

  &:hover {
    color: #a987ff;
  }
}</style>