<template>
  <el-popover
    placement="bottom-end"
    trigger="click"
    :popper-class="['space-filter', ...popperClass]"
    :width="width"
    :popper-options="popperOptions"
    :hide-after="0"
    :teleported="teleported"
    @before-enter="isShow = true"
    @before-leave="isShow = false"
  >
    <template #reference>
      <slot :active="isShow"></slot>
    </template>
    <div class="select-container">
      <ul class="select-list">
        <template v-for="item of options" :key="item.value">
          <select-item :item="item" v-model="props.modelValue" />
        </template>
      </ul>
      <button class="reset-button" v-if="canReset" @click="handleReset">
        Reset
      </button>
    </div>
  </el-popover>
</template>

<script setup lang="ts">
import SelectItem from "./item.vue";
import type { PropType } from "vue";
import type { SelectItemType, ModelValue } from "./type";

const emit = defineEmits(["reset", "change"]);
const props = defineProps({
  options: {
    type: Array as PropType<SelectItemType[]>,
    default: () => [],
  },
  modelValue: {
    type: Object as PropType<ModelValue>,
    default: () => ({ _default: new Set<string>() }),
  },
  popperOptions: {
    type: Object,
    default: () => ({
      modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
    }),
  },
  width: {
    type: Number,
  },
  popperClass: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  trigger: {
    type: String as PropType<"click" | "hover" | "focus" | "contextmenu">,
    default: "click",
  },
  placement: {
    type: String as PropType<
      | "top"
      | "top-start"
      | "top-end"
      | "bottom"
      | "bottom-start"
      | "bottom-end"
      | "left"
      | "left-start"
      | "left-end"
      | "right"
      | "right-start"
      | "right-end"
      | "auto"
      | "auto-start"
      | "auto-end"
    >,
    default: "bottom-end",
  },
  canReset: {
    type: Boolean,
    default: false,
  },
  teleported: {
    type: Boolean,
    default: true,
  },
});

const isShow = ref(false);

watch(
  () => props.modelValue,
  (newValue) => {
    emit("change", newValue);
  },
  { deep: true },
);

function handleReset() {
  for (const item of Object.values(props.modelValue)) {
    item.clear();
  }
  emit("reset");
}
</script>

<style scoped lang="scss">
.select-container {
  padding: 8px 14px;
}

.select-list {
  width: 100%;
  color: #060606;
}

.reset-button {
  width: 100%;
  padding: 7px 36px;
  border: 1px solid #bbbfc4;
  margin-block: 20px 12px;
  border-radius: 999px;
  font-size: 14px;
  color: #060606;
  cursor: pointer;

  &:hover {
    background-color: #f5f6f7;
  }
}
</style>

<style lang="scss">
.el-popover.space-filter {
  padding: 0;
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);
  border: 1px solid #dee0e3;

  & > .el-popper__arrow {
    display: none;
  }
}
</style>
