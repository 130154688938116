import { reactive } from "vue";
import { apiCall } from "@/utils/request";
import {
  imageBgRemove,
  videoEraser,
  videoEraserPoster,
  imageEnhancer,
  imageResizer,
  videoEnhancer,
  videoEnhancerPoster,
  faceSwapper,
  videoBackgroundRemove,
  videoBgRemovePoster,
  aiAvatarVideo,
  aiAvatarVideoPoster,
  videoDenoiser,
  videoDenoiserPoster,
  videoClipper,
  videoClipperPoster,
  videoSubtitlesRemover,
  videoSubtitlesRemoverPoster,
  videoWatermarksRemover,
  videoWatermarksRemoverPoster,
  aiWritingAssistant,
} from "../assets/index";
import type { Tool } from "../type";
import { getToolVoteNum, getToolVoteState } from "@/api/aiTools";

const initToolList: () => Tool[] = () => [
  {
    name: "backgroundRemover",
    title: "Background Remover",
    type: "image",
    description:
      "Remove unwanted background for your portrait image with remarkable precision",
    abbreviation: "BR",
    count: 0,
    vote: false,
    online: true,
    path: "/aiTools/image-background-remove",
    preview: {
      type: "image",
      url: imageBgRemove,
      ratio: "3 / 2",
    },
  },
  {
    name: "objectErase",
    title: "Object Eraser",
    type: "image",
    description:
      "Erase unwanted objects, people, defects or watermarks to create flawless images",
    abbreviation: "OE",
    count: 0,
    vote: false,
    online: true,
    path: "/aiTools/object-eraser",
    preview: {
      type: "video",
      url: videoEraser,
      poster: videoEraserPoster,
      ratio: "3 / 2",
    },
  },
  {
    name: "imageEnhancer",
    title: "Image Enhancer",
    type: "image",
    description:
      "Upscale your images by 2x in one click to meet your resolution requirements",
    abbreviation: "IE",
    count: 0,
    vote: false,
    online: true,
    path: "/aiTools/image-enhancer",
    preview: {
      type: "image",
      url: imageEnhancer,
      ratio: "3 / 2",
    },
  },
  // {
  // 	name: 'imageResizer',
  // 	title: 'Image Resizer',
  // 	type: 'image',
  // 	description: 'Change the dimensions of any picture for social media, print and web',
  // 	abbreviation: 'IR',
  // 	count: 0,
  // 	vote: false,
  // 	online: false,
  // 	preview: {
  // 		type: 'image',
  // 		url: imageResizer,
  // 		ratio: '3 / 2',
  // 	},
  // },
  {
    name: "videoBackgroundRemover",
    title: "Video Background Remover",
    type: "video",
    description:
      "Effortlessly isolate people from the background of any video, without the need of green screen",
    abbreviation: "VB",
    count: 0,
    vote: false,
    online: true,
    path: "/aiTools/video-background-remove",

    preview: {
      type: "video",
      url: videoBackgroundRemove,
      poster: videoBgRemovePoster,
      ratio: "3 / 2",
    },
  },
  {
    name: "videoEnhancer",
    title: "Video Enhancer",
    type: "video",
    description:
      "Upscale your video by 2x and improve video color balance, sharpness, and clarity",
    abbreviation: "VE",
    count: 0,
    disabled: true,
    vote: false,
    online: true,
    path: "/aiTools/video-enhancer",
    preview: {
      type: "video",
      url: videoEnhancer,
      poster: videoEnhancerPoster,
      ratio: "3 / 2",
    },
  },
  {
    name: "aiAvatarVideo",
    title: "AI Avatar Video",
    type: "video",
    description:
      "Transform photos into AI presenter videos with a simple text input. Easily create AI Avatars that speak your words in minutes",
    abbreviation: "AA",
    count: 0,
    vote: false,
    online: false,
    preview: {
      type: "video",
      url: aiAvatarVideo,
      poster: aiAvatarVideoPoster,
      ratio: "3 / 2",
    },
  },
  {
    name: "faceSwapper",
    title: "Face Swapper",
    type: "image",
    description:
      "Easily replace faces in photos and videos with just one click. Create content with any face you want",
    abbreviation: "FS",
    count: 0,
    vote: false,
    online: false,
    preview: {
      type: "image",
      url: faceSwapper,
      ratio: "3 / 2",
    },
  },
  {
    name: "videoDenoiser",
    title: "Video Denoiser",
    type: "video",
    description:
      "Reduce visual noise and enhance shake stabilization on your video to make it sharper and crisper",
    abbreviation: "VD",
    count: 0,
    vote: false,
    online: false,
    preview: {
      type: "video",
      url: videoDenoiser,
      poster: videoDenoiserPoster,
      ratio: "3 / 2",
    },
  },
  {
    name: "videoWatermarksRemover",
    title: "Video Watermarks Remover",
    type: "video",
    description:
      "Easily remove a watermark from a video that you downloaded or recorded, enabling seamless video content re-creation",
    abbreviation: "WR",
    count: 0,
    vote: false,
    online: false,
    preview: {
      type: "video",
      url: videoWatermarksRemover,
      poster: videoWatermarksRemoverPoster,
      ratio: "3 / 2",
    },
  },
  {
    name: "videoSubtitlesRemover",
    title: "Video Subtitles Remover",
    type: "video",
    description:
      "Effortlessly eliminate subtitles from your videos in one click, ensuring a seamless and distraction-free viewing experience",
    abbreviation: "SR",
    count: 0,
    vote: false,
    online: false,
    preview: {
      type: "video",
      url: videoSubtitlesRemover,
      poster: videoSubtitlesRemoverPoster,
      ratio: "3 / 2",
    },
  },
  {
    name: "aiVideoClipper",
    title: "AI Video Clipper",
    type: "video",
    description:
      "Automatically trim and extract video segments to transform lengthy videos into viral-ready clips, saving you time in post-production",
    abbreviation: "VC",
    count: 0,
    vote: false,
    online: false,
    preview: {
      type: "video",
      url: videoClipper,
      poster: videoClipperPoster,
      ratio: "3 / 2",
    },
  },
  {
    name: "aiWritingAssistant",
    title: "AI Writing Assistant",
    type: "copywriting",
    description:
      "Craft copywriting for your short videos that is tailored to your product info or video theme with your personal AI assistant",
    abbreviation: "WA",
    count: 0,
    vote: false,
    online: false,
    preview: {
      type: "image",
      url: aiWritingAssistant,
      ratio: "3 / 2",
    },
  },
];
const toolList: Tool[] = reactive(initToolList());

toolList.forEach(async (item) => {
  if (item.online) return;

  const toolName = `ai_tool_${item.name}`;
  item.vote = await apiCall(getToolVoteState, { toolName });

  if (item.vote) {
    item.count = await apiCall(getToolVoteNum, { toolName });
  }
});

export function useToolStore() {
  return {
    toolList,
  };
}
