<template>
  <tools-modal
    v-model="visible"
    @close="handleClose"
    title="Video Background Remover"
  >
    <videoBgRemove
      :isVideo="true"
      mode="editor"
      :src="src"
      @onAsyncProcess="handleAsyncProcess"
      :draftId="draftId"
    />
  </tools-modal>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import ToolsModal from "../../modal/toolsModal.vue";
import videoBgRemove from "./videoBgRemove.vue";

const emit = defineEmits(["complete", "update:modelValue"]);
const props = defineProps({
  src: String,
  draftId: String,
  modelValue: Boolean,
});

const visible = ref(false);

watch(
  () => props.src,
  (value) => {

  },
);

watch(
  () => props.modelValue,
  (value) => {
    visible.value = value;
  },
);

const handleClose = () => {
  visible.value = false;
  emit("update:modelValue", false);
};

const handleAsyncProcess = (taskId: string) => {
  emit("complete", taskId);
  handleClose();
};
</script>

<style lang="scss" scoped></style>
