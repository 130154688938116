<script setup lang="jsx">
import { withModifiers } from 'vue';
import avatar from '@/layout/components/avatar.vue';
import { useNetwork } from '@/composables';
import { useMessage } from '@/utils';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { postExportVideo } from '@/api/draft';
import defaultConfig from '@/assets/data/conf.json';
import { useCreatorStore, useDraftStore, useToolStore } from '../../stores';
import { useTrackStore } from '@/store/modules/track';
import { dateToHms } from '../../utils';
import { usePermission } from '../../composables';
import ScaleSelect from './scale-select.vue';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import similarVideo_logo from '@/assets/similar-video/similarVideo_logo.svg?raw';

const globalDomain = inject('globalDomain');
const extraOptions = [
  {
    label: 'Reset Json',
    handler: resetCofig,
  },
  {
    label: 'Copy Json',
    handler: copyConfig,
  },
  {
    label: 'Import Json',
    handler: importConfig,
  },
];

const options = [
  {
    label: '1080P',
    value: 1080,
  },
  {
    label: '720P',
    value: 720,
  },
  {
    label: '480P',
    value: 480,
  },
];

const displayText = {
  saving: 'Saving',
  saved: 'Saved',
  failed: 'Save failed',
};

const { collectData, track } = useTrackStore();
const { creator, tracks, canPlay, start } = useCreatorStore();
const { mode, updateCursorStyle } = useToolStore();
const {
  title,
  state,
  rid,
  reqId,
  lastUpdate,
  videoType,
  videoSubtype,
  updateDraft,
  renameDraft,
} = useDraftStore();
const modalManager = useModalManager();
const { permission } = usePermission();
const route = useRoute();
const router = useRouter();
const message = useMessage();
const { assertNetworkError } = useNetwork();

const ro = ref(null);
const width = ref(0);
const left = ref(0);
const exportLoading = ref(false);
const notShowSceneTip = ref(false);
const subscribeModalVisible = ref(false);

const form = reactive({
  name: '',
  resolution: globalDomain === 1 ? 1080 : 480,
});

const style = computed(() => ({
  left: `${left.value}px`,
  width: `${width.value}px`,
}));

watch(
  title,
  (newTitle) => {
    form.name = newTitle;
  },
  { immediate: true }
);

onMounted(() => {
  const wrapper = document.querySelector('.player-wrapper');

  if (wrapper) {
    ro.value = new ResizeObserver(resize);
    ro.value.observe(wrapper);
  }
});

function resize(enties) {
  for (const e of enties) {
    const element = e.target;
    const box = element.getBoundingClientRect();

    left.value = box.left;
    width.value = box.width;
    break;
  }
}

async function resetCofig() {
  modalManager.applyTemplate('confirm', {
    title: 'Do you want to reset?',
    onConfirm: () => start(defaultConfig),
  });
}

async function copyConfig() {
  try {
    await navigator.clipboard.writeText(JSON.stringify(creator.value.conf));
    message.success('Copied to clipboard');
  } catch (e) {
    message.error(JSON.stringify(e));
  }
}

async function importConfig() {
  const config = await navigator.clipboard.readText();

  try {
    await start(config);
    updateDraft();
    message.success('Import successfully');
  } catch (e) {
    message.error(e);
  }
}

function getEventData() {
  const data = {
    audio: [],
    filter: [],
    sticker: [],
  };

  const helper = (nodes) => {
    for (const node of nodes) {
      if (Array.isArray(data[node.type])) {
        data[node.type].push(node.conf.sourceId);
      } else if (node.children) {
        helper(node.children);
      }
    }
  };
  helper(tracks.value);

  return data;
}

function exportVideo() {
  assertNetworkError();
  exportLoading.value = true;

  modalManager.applyTemplate('confirm', {
    modalClass: 'export-modal',
    showCancel: false,
    confirmText: 'Export',
    title: 'Export',
    key: 'editor-export',
    content: (
      <el-form
        model={form}
        labelWidth="88px"
        labelPosition="left"
        style={{ marginTop: '24px' }}
        onSubmit={withModifiers(confirm, ['prevent'])}>
        <el-form-item label="Name">
          <el-input
            modelValue={form.name}
            onUpdate:modelValue={(v) => (form.name = v)}
          />
        </el-form-item>
        <el-form-item label="Resolution" style={{ marginBottom: 0 }}>
          <bv-select
            popperStyle={{ width: '279px' }}
            options={options}
            modelValue={form.resolution}
            onChange={(v) => (form.resolution = v)}
          />
        </el-form-item>
      </el-form>
    ),
    onConfirm: confirm,
  });
}

async function confirm() {
  const { draftId } = route.query;
  const { name, resolution } = form;
  const params = { draftId, name, resolution };
  const response = await postExportVideo(params);
  const { success, code, msg, noCredit } = response;

  if (!success) {
    if (noCredit) {
      modalManager.applyTemplate('upgradeTips', {
        msg,
        code,
        key: 'editor-uogradeTip',
        onConfirm: () => {
          subscribeModalVisible.value = true;
        },
      });
    } else {
      message.error('Can’t export, please try again');
    }
  } else {
    const eventData = getEventData();
    const { href } = router.resolve({
      path: '/space',
      query: { tab: 'exported' },
    });

    collectData('boolvideo_export_click', {
      music_id: eventData.audio,
      sticker_id: eventData.sticker,
      filter_id: eventData.filter,
      video_type: videoType.value,
      video_subtype: videoSubtype.value,
      editor_type: 'timeline_editor',
      draftId,
      rid: rid.value,
      reqId: reqId.value,
    });
    track('boolvideo_export_click');
    window.open(href, '_blank');
  }
  exportLoading.value = false;
}

function clickMove() {
  mode.value = 0;
  updateCursorStyle();
}

function clickHand() {
  mode.value = 1;
  updateCursorStyle();
}
</script>
<template>
  <header class="header">
    <bv-guide
      name="new_draft_guide"
      title="New draft"
      description="A copy of the draft has been created. Editing will not be synchronized to the previous draft."
      placement="bottom"
      :visible="true"
      :disappear="notShowSceneTip"
    >
      <template #reference>
        <div class="left">
          <svg-icon
            v-if="globalDomain == 1"
            class="logo"
            name="editor_logo"
            color="#FFFFFF"
            :style="{ width: '150px', height: '36px' }"
            @click="router.push('/workspace')"
          />

          <RouterLink
            v-if="globalDomain == 2"
            to="/workspace"
            v-html="similarVideo_logo"
            class="boolv-logo cursor-pointer"
          />

          <div class="draft-state">
            <svg-icon v-show="state === 'saved'" name="editor_cloud" :size="20" />
            <svg-icon
              v-show="state === 'failed'"
              name="editor_cloud_failure"
              :size="20"
            />
            <span class="time" v-show="state === 'saved' && lastUpdate">{{
              dateToHms(lastUpdate)
            }}</span>
            <span>{{ displayText[state] }}</span>
          </div>
        </div>
      </template>
      <template #extra>
        <el-checkbox
          v-model="notShowSceneTip"
          label="Don't remind me again"
          size="large"
        />
      </template>
    </bv-guide>
    <div class="center" :style="style">
      <el-input
        class="title-input"
        v-model="title"
        @change="renameDraft"
        maxlength="58"
      />
    </div>
    <div class="right">
      <div class="tools">
        <icon-button
          name="editor_move"
          tip="Move(V)"
          :size="18"
          :active="mode === 0"
          @click="clickMove"
        />
        <icon-button
          name="editor_hand"
          tip="Hand tool(H)"
          :size="18"
          :active="mode === 1"
          @click="clickHand"
        />
        <scale-select />
      </div>
      <primary-button :disabled="!canPlay" @click="exportVideo"
        >Export</primary-button
      >
      <avatar :options="permission ? extraOptions : []" />
    </div>

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </header>
</template>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e9ec;
  position: relative;
  height: 60px;
  background-color: #ffffff;
  flex: 0 0 auto;
  margin: 0;
  z-index: 3;
}

.left {
  margin-left: 26px;
  display: flex;
  align-items: center;
}

.logo {
  cursor: pointer;
  z-index: 1;
}

.center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
}

.title-input {
  position: relative;
  width: 440px;
}

.right {
  display: flex;
  margin-right: 34px;
  z-index: 1;
}

:deep(.right) > div + div {
  margin-left: 18px;
}

.tools {
  display: flex;
  align-items: center;
  margin-right: 38px;
}

.tools > span {
  margin-right: 12px;
}

.draft-state {
  margin-left: 46px;
  display: flex;
  align-items: center;
  color: #8f959e;
  font-size: 12px;
  line-height: 20px;
  user-select: none;
}

.draft-state svg {
  margin-right: 10px;
}

.draft-state .time {
  margin-right: 5px;
}

:deep(.title-input .el-input__wrapper) {
  padding: 1px 12px;
  border: none;
  box-shadow: none;
  transition: all 300ms;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

:deep(.title-input .el-input__wrapper.is-focus) {
  border-color: #875eff;
  background-color: #fff !important;
  text-align: left;
}

:deep(.title-input .el-input__wrapper:hover) {
  background-color: #f8f5ff;
}

:deep(.title-input .el-input__inner) {
  background-color: transparent;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

:deep(.title-input .el-input__inner::selection) {
  background-color: #dbcfff;
}

:deep(.title-input .el-input__wrapper.is-focus .el-input__inner) {
  text-align: left;
}

:deep(.right .el-button) {
  height: 36px;
  width: 116px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
</style>
<style>
.modal-container.export-modal {
  width: 415px;
}

.export-modal .el-form-item__label {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
}

.export-modal .el-input__wrapper {
  border: 1px solid #d5d6d7;
  box-shadow: none;
  transition: border-color 200ms;
  padding: 0 8px;
}

.export-modal .select-wapper {
  border: 1px solid #d5d6d7;
  transition: border-color 200ms;
  padding: 0 8px;
}

.export-modal .el-input__inner {
  padding: 0;
}

.export-modal .el-input__inner,
.export-modal .bv-select-button .select-value {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.export-modal .el-button--primary {
  font-weight: 400;
}

.export-modal .el-input .el-input__wrapper:hover,
.export-modal .bv-select-button .select-wapper:hover {
  border: 1px solid #be9fff;
}

.export-modal .el-input .el-input__wrapper.is-focus {
  border: 1px solid #875eff !important;
  box-shadow: none !important;
}
</style>
