<template>
  <ol class="grid-container">
    <gridItem v-for="(item, index) in innerImageList" :key="item.uid" :item="item" :imageList="innerImageList"
      :index="index" @uploadRetry="handleUploadRetry">
      <template #upload v-if="item.upload">
        <slot :item="item" name="upload"> </slot>
      </template>
      <template #select-icon>
        <slot name="elect-icon"></slot>
      </template>
      <template #delete-icon>
        <slot name="delete-icon" :item="item" :index="showUpload ? index - 1 : index"></slot>
      </template>
    </gridItem>
  </ol>
</template>

<script lang="ts" setup>
import { ref, watch, type PropType } from "vue";
import gridItem from "./gridItem.vue";
import type { ImageType } from "./type";

const emits = defineEmits(["removeImage", "uploadRetry"]);
const props = defineProps({
  showUpload: Boolean,
  imageList: {
    type: Array as PropType<ImageType[]>,
    default: () => {
      return [];
    },
  },
});

const innerImageList = ref<ImageType[]>([]);

watch(
  () => props.imageList,
  (value) => {
    if (props.showUpload) {
      innerImageList.value = [
        {
          mid: "-1",
          upload: true,
        },
      ];

      innerImageList.value.splice(1, 0, ...value);
    } else {
      innerImageList.value = [];
      innerImageList.value.splice(0, 0, ...value);
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

const handleUploadRetry = (item: ImageType) => {
  emits("uploadRetry", item);
};
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  list-style: none;
}

@media only screen and (max-width: 1000px) {
  .grid-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    list-style: none;
  }
}
</style>
