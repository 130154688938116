<template>
  <div>
    <el-dialog
      modal-class="share-link-dialog"
      v-model="dialogVisible"
      destroy-on-close
      :close-on-click-modal="false"
      :show-close="false"
      width="415px"
      :footer="null"
      align-center
    >
      <div class="relative">
        <div class="font-medium text-base text-black">Share preview</div>
        <div
          class="absolute top-0 right-0 h-6 w-6 hover:bg-uploadBg flex items-center justify-center rounded-sm cursor-pointer"
          @click="handleClose"
        >
          <svg-icon
            name="icon_close"
            :style="{ width: '20px', height: '20px' }"
            color="#646A73"
          ></svg-icon>
        </div>
      </div>
      <p class="text-sm font-normal text-black pt-2 pb-6">
        People with this link can watch your video.
      </p>
      <el-input v-model="innerLink" placeholder="Please input link" />
      <template #footer>
        <div class="flex items-center justify-end pr-6">
          <primary-button padding="7px 36px" :showDefaultIcon="false">
            <p class="text-sm font-normal leading-22" @click="handleCopyLink">
              Copy link
            </p>
          </primary-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { PrimaryButton } from "@/components/common/index";
import useClipboard from "vue-clipboard3";
import { useSingleMessage } from "@/utils";
const emit = defineEmits(["close"]);
const props = defineProps({
  visible: Boolean,
  link: {
    type: String,
    default: "https://boolvideo.com/preview/daf0dec6-635b-44...",
  },
});
const message = useSingleMessage();
const dialogVisible = ref(true);
const innerLink = ref(props.link);
watch(
  () => props.visible,
  (value) => {
    dialogVisible.value = value;
  },
  {
    immediate: true,
  },
);

watch(
  () => props.link,
  (value) => {
    innerLink.value = value;
  },
  {
    immediate: true,
  },
);

// 删除
const handleClose = () => {
  dialogVisible.value = false;
  emit("close");
};

const handleCopyLink = async () => {
  try {
    const { toClipboard } = useClipboard();
    await toClipboard(innerLink.value);
    message.success("Link copied");
  } catch (error) {
    message.error("Copy failed");
  }
  handleClose();
};
</script>
<style lang="scss">
.share-link-dialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 24px;
  }

  .el-dialog__footer {
    padding: 0 0px 24px 0;
  }

  .el-input__inner {
    height: 40px;
  }
}

:deep(.el-overlay.asset-modal) {
  background-color: rgba(0, 0, 0, 0.54);
}
</style>
