<template>
  <div class="product-to-video">
    <div class="content-box">
      <case-input
        :icon="url_case"
        :showCase="showCase"
        :url="caseInfo.url"
        @update:url="(e) => (inputUrl = e)"
        :errorMsg="errorMsg"
        @update:error="(e) => (errorMsg = e)"
        @selected="(e) => (inputUrl = e)"
      />
      <p class="tip">Support</p>
      <div class="case-item-box">
        <div class="case-item" v-for="item in caseList">
          <img :src="item" />
        </div>
      </div>

      <primary-button size="small" padding="15px 55px" @click="handleApply">
        <p class="btn-text">Apply URL</p>
      </primary-button>

      <p class="upload-tip" @click="handleUpload">No URL, next step</p>
    </div>

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, reactive } from 'vue';
import {
  icon_shopify,
  icon_amazon,
  icon_etsy,
  url_case,
} from '../commonAssets/index.js';

import { PrimaryButton } from '@/components/common/index';
import CaseInput from './caseInput.vue';
import { useSingleMessage } from '@/utils';

import { importUrl } from '@/api/index';
import { useModalManager } from '@/components/common/custom-modal/instance';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

const props = defineProps({
  showCase: Boolean,
});
const caseList = [icon_shopify, icon_amazon, icon_etsy];

const emit = defineEmits(['nextStep', 'onLoading']);
const caseInfoList = [
  'https://www.rexingsports.com/collections/women/products/workout-long-sleeves-round-neck-dusty-pink',
  'https://www.rexingsports.com/collections/women/products/workout-long-sleeves-round-neck-dusty-pink',
  'https://www.rexingsports.com/collections/women/products/workout-long-sleeves-round-neck-dusty-pink',
  'https://www.rexingsports.com/collections/women/products/workout-long-sleeves-round-neck-dusty-pink',
  'https://www.rexingsports.com/collections/women/products/workout-long-sleeves-round-neck-dusty-pink',
];

const subscribeModalVisible = ref(false);
const modalManager = useModalManager();
const Message = useSingleMessage();
const handlerMessage = ref(null);
const inputUrl = ref('');
const errorMsg = ref('');
const caseInfo = reactive({
  url: 'https://www.rexingsports.com/collections/women/products/workout-long-sleeves-round-neck-dusty-pink',
});

const getRandomUrl = (caseList) => {
  const randomIndex = Math.floor(Math.random() * caseList.length);
  return caseList[randomIndex];
};

const close = () => {
  emit('onLoading', false);
  handlerMessage.value.close();
};

const setErrorMsg = (msg) => {
  errorMsg.value = msg;
};

const handleCrawMediaData = async (param) => {
  handlerMessage.value = Message.loading('Importing your product info', {
    showClose: false,
    duration: 0,
    onClose: close,
  });
  emit('onLoading', true);

  const { data = {}, code, msg, noCredit } = await importUrl(param);

  if (noCredit || code == 70001) {
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      key: 'upgradeTip',
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    close();
    return;
  }

  if (code == 30504) {
    close();
    setErrorMsg(
      'The product may have been removed. Please check and try another URL'
    );
    return;
  }

  if (code === 30503) {
    close();
    setErrorMsg('Please apply the product URL from Shopify, Amazon, or Etsy');
    return;
  }

  if (code === 30502) {
    close();
    setErrorMsg('Please apply the correct product URL');
    return;
  }

  // 权益
  if (code === 30001 || code === 40001) {
    close();
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  if (code != 0) {
    close();
    setErrorMsg('Something error');
    return;
  }

  const { materials = [], productDesc } = data;
  const imageList = materials.map((img) => {
    return {
      state: 0,
      type: 'image',
      mid: img.mid,
      upload: false,
      url: img.preview480Url,
    };
  });

  const productInfo = {
    productDesc,
    url: inputUrl.value,
  };

  close();
  emit('nextStep', { _imageList: imageList, _productInfo: productInfo });
};

const handleApply = () => {
  if (/^https?:\/\/([-\w]+\.)*\w+(\/[-\w%]+)*(\?)?/.test(inputUrl.value)) {
    handleCrawMediaData({ url: inputUrl.value });
  } else {
    setErrorMsg('Please apply the correct product URL');
  }
};

const handleUpload = () => {
  emit('nextStep', {});
};

onMounted(() => {
  caseInfo.url = getRandomUrl(caseInfoList);
});

onBeforeMount(() => {
  caseList.forEach((src) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      console.log('预加载');
    };
  });
});
</script>

<style lang="scss">
.product-to-video {
  .el-input {
    height: 54px;
  }

  .el-input__inner {
    color: #646a73;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .el-input__wrapper {
    height: 54px;
    padding-right: 18px;
    box-shadow: 0 0 0 1px #e5e7eb inset !important;

    &:hover {
      box-shadow: 0 0 0 1px #be9fff inset !important;
    }
  }

  .el-input__wrapper.is-focus {
    box-shadow: 0 0 0 1px #875eff inset !important;
  }

  .el-input.error-input .el-input__wrapper {
    box-shadow: 0 0 0 1px #ff5449;
    transition: all 0.2s;
  }
}
</style>

<style lang="scss" scoped>
.product-to-video {
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 500px;
  min-height: 500px;
}

.content-box {
  width: 710px;
  height: 438px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tip {
  text-align: center;
  color: #8f959e;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 27px;
}

.case-item {
  border-radius: 2px;
  border: 1px solid #e5e7eb;
  background: #fff;
  width: 96px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;

  &:last-child {
    margin-right: 0px;
  }
}

.case-item-box {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  margin-bottom: 130px;
}

.btn-text {
  width: 79px;
  line-height: 24px;
}

.upload-tip {
  color: #6741ff;
  text-align: center;
  font-family: Inter-variant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 46px;
  cursor: pointer;
}

.icon-clear-box {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
</style>
