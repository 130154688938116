<script setup>
import { 
  defaultFontFamily, 
  getFontFamilyByLabel,
  textPreview
} from "@/constants/text";
import { useNetwork } from "@/composables";
import Material from "./material.vue";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import { useTrackStore } from "@/store/modules/track"; 

const { collectData } = useTrackStore();
const { getXY, addTextNode, creator } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { assertNetworkError } = useNetwork();
const { dragData } = useDrag();

const textTypes = {
  title: {
    id: 2,
    text: "Title",
    fontWeight: "bold",
    fontSize: 64,
  },
  headline: {
    id: 3,
    text: "Headline",
    fontWeight: "bold",
    fontSize: 48,
  },
  subheadline: {
    id: 4,
    text: "Subheadline",
    fontWeight: "bold",
    fontSize: 40,
  },
  caption: {
    id: 5,
    text: "Caption",
    fontWeight: "bold",
    fontSize: 32,
    y: creator.value.screen.height * 0.8,
  },
};

const presetTypes = {  
  todaySACtivity: {
    id: 6,
    scale: 0.87,
    text: "Today's activity",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontWeight: "bold",
    fontSize: 54,
    dropShadow: true,
    dropShadowAngle: 0.75,
    dropShadowColor: "#FFFFFF",
    dropShadowDistance: 7,
    stroke: "#FFFFFF",
    strokeThickness: 0.18,
    url: textPreview.todaySActivity,
  },
  newContent: {
    id: 7,
    scale: 0.87,
    text: "New content",
    fontFamily: getFontFamilyByLabel("Inter"),
    fill: "#00FF40",  
    fontSize: 49,
    fontWeight: "bold",
    strokeThickness: 0.21,
    url: textPreview.newContent,
  },
  bestLiftBlack: {
    id: 8,
    scale: 1.18,
    text: "Best Life",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 25,
    stroke: "#FFFFFF",
    background: "#000000",
    url: textPreview.bestLifeBlack,
  },
  bestLifeWhite: {
    id: 9,
    scale: 1.18,
    text: "Best life",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 22,
    stroke: "#FFFFFF",
    background: "#FFFFFF",
    url: textPreview.bestLifeWhite,
  },
  loveIt: {
    id: 10,
    text: "I Love It",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 43,
    fontWeight: "bold",
    stroke: "#FF00F1",
    strokeThickness: 0.17,
    url: textPreview.loveIt,
  },
  shopNow: {
    id: 11,
    text: "Shop Now",
    fill: "#FFFA00",
    fontSize: 34,
    fontWeight: "bold",
    fontStyle: "italic",
    strokeThickness: 0.34,
    url: textPreview.shopNow,
  },
  off: {
    id: 12,
    scale: 1.18,
    text: "50% OFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 22,
    fontWeight: "bold",
    stroke: "#FFDA00",
    background: "#FFFA00",
    url: textPreview.off,
  },
  experience: {
    id: 13,
    text: "Experience",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 41,
    fontWeight: "bold",
    stroke: "#000000",
    strokeThickness: 0.25,
    background: "#9DFF0D",
    url: textPreview.experience0,
  },
  dailyWhite: {
    id: 14,
    scale: 0.87,
    text: "Daily",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 34,
    strokeThickness: 0.18,
    url: textPreview.dailyWhite,
  },
  dailyBlack: {
    id: 15,
    scale: 0.87,
    text: "Daily",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontWeight: "bold",
    fontSize: 34,
    stroke: "#FFFFFF",
    strokeThickness: 0.18,
    url: textPreview.dailyBlack,
  },
  coupon: {
    id: 16,
    scale: 0.87,
    text: "Coupon",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 53,
    fontWeight: "bold",
    stroke: "#FF1010",
    background: "#FF3232",
    url: textPreview.coupon,
  },
  travelTips: {
    id: 17,
    scale: 1.18,
    text: "Travel tips",
    fontFamily: getFontFamilyByLabel("Roboto"),
    fontSize: 37,
    fontWeight: "bold",
    fontStyle: "italic",
    stroke: "#FFFFFF",
    background: "#FFFFFF",
    url: textPreview.travelTips,
  },
  todayOutfit: {
    id: 18,
    text: "Today outfit",
    fill: "#FF00E8",
    fontFamily: getFontFamilyByLabel("Roboto"),
    fontSize: 43,
    fontWeight: "bold",
    stroke: "#FFFFFF",
    strokeThickness: 0.17,
    url: textPreview.todayOutfit,
  },
  brightSpot: {
    id: 19,
    scale: 1.18,
    text: "Bright spot",
    fill: "#00FF8E",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 46,
    fontWeight: "bold",
    dropShadow: true,
    dropShadowAngle: 0.66,
    dropShadowBlur: -95,
    stroke: "#FFDA00",
    url: textPreview.brightSpot,
  },
  flashSale: {
    id: 20,
    scale: 0.87,
    text: "Flash sale",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Roboto"),
    fontSize: 40,
    fontWeight: "bold",
    stroke: "#E20000",
    strokeThickness: 0.18,
    url: textPreview.flashSale,
  },
  vintage: {
    id: 21,
    scale: 1.18,
    text: "Vintage",
    fill: "#FF0000",
    fontFamily: getFontFamilyByLabel("Lobster"),
    fontSize: 66,
    dropShadow: true,
    dropShadowAngle: 0.96,
    dropShadowColor: "#FFDD00",
    dropShadowDistance: 6,
    stroke: "#FFDA00",
    url: textPreview.vintage,
  },
  amazing: {
    id: 22,
    scale: 1.18,
    text: "Amazing",
    fontFamily: getFontFamilyByLabel("Roboto"),
    fill: "#FFFFFF",  
    fontSize: 37,
    fontWeight: "bold",
    fontStyle: "italic",
    stroke: "#FFFFFF",
    background: "#000000",
    url: textPreview.amazing,
  },
  retroVibe: {
    id: 23,
    scale: 1.18,
    text: "Retro Vibe",
    fontFamily: getFontFamilyByLabel("Inter"),
    fill: "#FFFBF3",  
    fontSize: 46,
    fontWeight: "bold",
    dropShadow: true,
    dropShadowAngle: 0.66,
    dropShadowDistance: 0,
    stroke: "#FF6700",
    strokeThickness: 0.25,
    url: textPreview.retroVibe,
  },
}

async function addText(config) {
  assertNetworkError();
  await addTextNode({
    fontFamily: defaultFontFamily,
    ...config,
  });
  commit();
  updateDraft();
}

async function addDefaultText() {
  await addText({ text: "Text", sourceId: 1, fontSize: 24 });
}

function handleDrag(e, target, config, key) {
  let dragElement = null;
  if (Object.keys(textTypes).includes(key)) {
    const computedStyleMap = target.computedStyleMap();
    dragElement = document.createElement("div");  
    dragElement.innerText = config.text;
    Object.assign(dragElement.style, {
      fontSize: computedStyleMap.get("font-size").toString(),
      fontWeight: computedStyleMap.get("font-weight").toString(),
    });
  }
  else if (Object.keys(presetTypes).includes(key)) {
    dragElement = target.cloneNode(true);
    // Object.assign(dragElement.style, {
    //   borderRadius: "4px",
    //   backgroundColor: "rgba(100,100,100,.12)",
    // });
  }

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      ...getXY(),
      type: "text",
      fontFamily: defaultFontFamily,
      sourceId: config.id,
      fill: "#000000",
      ...config,
    },
  });
}

function handleMouseDown(e) {
  const target = e.target.closest("button[data-type]");

  if (target === null || e.button !== 0) return;

  const textArray = { ...textTypes, ...presetTypes };
  const { url, ...config } = textArray[target.dataset.type];

  const mouseUpListener = () => {
    addText({ ...config, sourceId: config.id });
    target.removeEventListener("mousemove", moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener("mouseup", mouseUpListener);
    handleDrag(e, target, config, target.dataset.type);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  target.addEventListener("mousemove", moveListener, { once: true });
  target.addEventListener("mouseup", mouseUpListener, { once: true });
}
</script>
<template>
  <Material title="Text">
    <el-scrollbar>
      <div class="material-content">
        <el-button size="large" @click="addDefaultText">
          <svg-icon name="editor_plus" color="#1C1B1E" :size="18" />
          <span>Add Text</span>
        </el-button>
        <div class="text-group" @mousedown="handleMouseDown">
          <button
            v-for="[key, config] of Object.entries(textTypes)"
            :class="key"
            :key="key"
            :data-type="key"
          >
            {{ config.text }}
          </button>
        </div>
        <div class="preset-group" @mousedown="handleMouseDown">
          <button
            v-for="[key, config] of Object.entries(presetTypes)"
            :class="key"
            :key="key"
            :data-type="key"
          >
            <img 
              :src="config.url" 
              draggable="false"
            />
          </button>
        </div>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style lang="scss" scoped>
:deep(.el-button.el-button--large) {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 27px;
  border-radius: 4px;
}
:deep(.el-button.el-button--large:hover) {
  background-color: #f5f6f7;
}
.text-group button {
  width: 100%;
  height: 42px;
  margin: 0 0 16px;
  border-radius: 4px;
  font-weight: 500;
  color: #000000;
  background-color: #f3f5f7;
  transition: background-color 200ms;

  &:last-child {
    margin-bottom: 30px;
  }
}
.text-group button:hover {
  background-color: #e8e9ec;
}
.text-group .title {
  font-size: 20px;
  line-height: 42px;
}
.text-group .headline {
  font-size: 18px;
  line-height: 32px;
}
.text-group .subheadline {
  font-size: 14px;
  line-height: 28px;
}
.text-group .caption {
  font-size: 12px;
  line-height: 26px;
}

.preset-group {
  display: grid;
  grid-template-columns: repeat(2, 107px);
  gap: 16px;
}

.preset-group button {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  color: #000000;
  background-color: #f3f5f7;
  transition: background-color 200ms;
}

.preset-group button:hover {
  background-color: #e8e9ec;
}
</style>
