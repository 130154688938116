<template>
  <el-row class="edit-video-container" id="edit-video-container" v-track:exposure="'boolvideo_editor_page_view'">
    <el-col :span="11" class="px-3 pt-3 hidden-container" >
      <el-scrollbar  class=" px-3  pt-3 scroll-container"  @scroll="handleAssetListScroll">
      <div>
        <!-- header -->
        <div class="flex justify-between mb-7">
          <div class="text-sm">
            <span class="mr-3"> Background Audio</span>
            <!-- <span> Copywriting ({{ counter.textCount }})</span> -->
          </div>
          <!-- 保存草稿成功 -->
          <div class="flex items-center" v-if="draftId && saveTime">
            <svg-icon name="icon_save" :size="20" style="color: #646a73"></svg-icon>
            <span class="mr-3 ml-2 text-defaultColor text-xs">
              {{ dayjs(saveTime).format("HH:mm:ss") }} Saved</span>
          </div>
          <!-- 保存失败 -->
          <div class="flex items-center" v-else-if="saveError">
            <svg-icon name="icon_save_failed" :style="{ width: '20px', height: '20px' }"></svg-icon>
            <span class="mr-3 ml-2 text-defaultColor text-xs">
              {{ dayjs(saveTime).format("HH:mm:ss") }} Save failed</span>
          </div>
        </div>

        <div class="skeleton flex pl-0 pr-10" v-if="!isLoaded">
            <div style="width: 130px; margin-right: 20px; margin-bottom: 46px">
              <el-skeleton style="width: 130px" animated>
                <template #template>
                  <el-skeleton-item variant="image" style="width: 130px; height: 130px; border-radius: 4px" />
                </template>
              </el-skeleton>
            </div>
            <div class="flex-1">
              <el-skeleton :rows="2" animated />
            </div>
          </div>

        <div class="music-wrap mlg:pr-72 lg:pr-36"  v-else>
          <div class="music-container w-full bg-musicItem rounded mb-12 flex justify-between items-center px-6">
          <div class="flex items-center">
            <div class="icon-music-box">
              <svg-icon name="icon_music" :size="18"></svg-icon>
            </div>
            <span class="disabled-music-name ml-3" v-if="audioItem?.disabled"> No audio</span>
             <span class="music-name ml-3" v-else>{{audioItem?.audioName || 'No audio'}}</span>
          </div>
          <div class="flex items-center">
            <el-tooltip class="box-item" popper-class="btn-container" effect="dark" content="Reset"
            placement="bottom">
            <div :class="{ 'icon-box': isCanRestAudio, 'icon-disabled-box': !isCanRestAudio}"  @click="handleResetAudio(isCanRestAudio)">
              <svg-icon name="icon_reset" :style="{'color': isCanRestAudio ? '' : 'rgb(144, 147, 153)'}" :size="18"></svg-icon>
            </div>
          </el-tooltip>


          <el-tooltip class="box-item" popper-class="btn-container" effect="dark" content="Delete"
            placement="bottom">
            <div  :class="{ 'icon-box': audioItem?.material, 'icon-disabled-box': !audioItem?.material}"  class="ml-5" @click="handleRemoveAudio(audioItem?.material)">
            <svg-icon name="icon_remove_music" :size="18" :style="{'color': audioItem?.material ? '#1C1B1E' : 'rgb(144, 147, 153)'}"></svg-icon>
           </div>
          </el-tooltip>
             <span class="upload-text ml-5" @click="handleOpenAudioModal" >Music</span>
          </div>
        </div>
        </div>

        <div class="text-sm mb-30">
            <span class="mr-3"> Images ({{ counter.imageCount }})</span>
            <span> Copywriting ({{ counter.textCount }})</span>
          </div>

          <!-- 骨架屏 -->
          <div class="skeleton flex pl-0 pr-10" v-for="item in skeletonListInfo" v-if="!isLoaded">
              <div style="width: 130px; margin-right: 20px; margin-bottom: 46px">
                <el-skeleton style="width: 130px" animated>
                  <template #template>
                    <el-skeleton-item variant="image" style="width: 130px; height: 130px; border-radius: 4px" />
                  </template>
                </el-skeleton>
              </div>
              <div class="flex-1">
                <el-skeleton :rows="2" animated />
              </div>
            </div>
          <!-- 编辑项 -->
          <edit-asset-item v-else  class="mlg:pr-72 lg:pr-36" v-for="(item, index) in editAssetInfo" :key="item.key" :item="item" :index="index"
            :isPlaying="isPlaying" :isScroll="isScroll" :isActive="currentEditItem?.key === item.key && !isPlaying"
             @onZoomChange="(zoom) => handleZoomChange(item, zoom)"
            @selectItem="(e) => handleItemClick(item, index)" @openAssetDialog="handleOpenAssetDialog"
            @openCropModal="handleOpenCropModal" @onDeleteLogo="handleDeleteLogo" @onSizeChange="handleSizeChange"
            @onTextColorChange="handleTextColorChange" @onImageReversColor="handleImageReversColor"
            @onResetText="handleResetText" @onTextContentChange="handleTextContentChange"
            :duration="duration" :isVideoPlaying="isPlaying"
             />
      </div>
    </el-scrollbar>
    </el-col>


    <el-col :span="13" class="bg-uploadBg loading-mask">
      <!-- 编辑操作界面 -->
      <div class="right-container">
        <div class="edit-player-container">
          <div class="video-box" :style="{ 'aspectRatio': verticalVersion ? '9/16' : '1/1' }">
            <edit-player ref="editPlayerRef" :src="templateUrl!" :loop="true" :autoplay="false"
              :assetType="currentEditItem?.type || 0" :editAssets="editAssetInfo" @onOffsetUpdate="handleOffsetUpdate"
              @onLoaded="handleAnimLoaded" @onPlaying="handleOnPlaying" />
          </div>
        </div>
      </div>


    </el-col>
    
    <asset-modal accept="image" :visible="isOpenAssetDialog" @close="handleCloseAssetDialog" @replace="handleReplace"
      :draftId="draftId" :isLogo="currentEditItem?.logo" />

    <crop-modal :visible="isOpenCropModal" :cropAssets="clipAbleAssets" :selectedKey="currentCropItem?.key"
      @change="handleCropChange" @close="handleCloseCropDialog" />

    <audio-modal :visible="audioModalVisible" :draftId="draftId" :duration="duration" @close="handleAudioModalClose"
      @onReplace="handleReplaceAudio" />

    <edit-onboarding :visible="!isShowEditOnboarding" />

  </el-row>
</template>
<script setup lang="ts">
import {
  ref,
  type Ref,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import dayjs from "dayjs";
import { Howl } from 'howler';

import { useEditVideo } from "@/store/modules/editVideo";
import { useTrackStore } from "@/store/modules/track";

import EditAssetItem from "./components/editItem.vue";
import EditOnboarding from "@/components/editOnBording/index.vue";
import CropModal, { type CropAssetData } from "./components/cropModal.vue";
import AudioModal from './components/audioModal.vue'
import EditPlayer, {
  type OffsetUpdateEventArgs,
} from "./components/editPlayer.vue";

import { debounce } from "@/utils";
import { getItem } from "@/utils/storage";

const opacityImageUrl =
  "https://boolv.tech/materials/other/61ecf8f065064bcfa3e3b481d5002473.webp";

type ImageCropInfo = {
  key: string;
  url: string;
};

type ChangeReverseColor = {
  key: string;
  isReverseColor: boolean;
  reverseColor: string;
};

type ChangeTextFontSize = {
  key: string;
  fontSize: string;
};

type ChangeFillColor = {
  key: string;
  fillColor: string;
};

type ResetTextData = {
  key: string;
  fillColor: string;
  fontSize: string;
  material: string;
};

type ChangeTextData = {
  key: string;
  material: string;
};

const isShowEditOnboarding = ref(0);
const route = useRoute();
const router = useRouter();
const { collectData } = useTrackStore();
const editVideoStore = useEditVideo();
const {
  rid,
  reqId,
  counter,
  duration,
  draftId,
  saveTime,
  saveError,
  templateUrl,
  templateId,
  ratio,
  editAssetInfo,
  verticalVersion,
} = storeToRefs(editVideoStore);

const isLoaded = ref(false);
const isPlaying = ref(false);
const isScroll = ref(false);
const isOpenCropModal = ref(false);
const isOpenAssetDialog = ref(false);
const skeletonListInfo = ref([{}, {}, {}, {}, {}]);

const currentEditItem: Ref<any> = ref(null);
const currentCropItem: Ref<Nullable<EditableAssetItem>> = ref(null);
const editPlayerRef: Ref<typeof EditPlayer | null> = ref(null);
const clipAbleAssets = ref<CropAssetData[]>([]);

const audioModalVisible = ref(false)
const audioItem = ref(null)
const isCanRestAudio = ref(false)
const isLoadingAudio = ref(false)

collectData("boolvideo_editor_page_view", {
  editor_type: "template_editor",
});

// 可裁剪的资源集
watch(
  () => editAssetInfo.value,
  () => {
    audioItem.value = editAssetInfo.value.find(item => {
      return item.type == 9
    })

    clipAbleAssets.value = editAssetInfo.value
      .filter((item) => item.type === 1)
      .map((item) => ({
        key: item.key,
        url: item.material,
        width: item.width,
        height: item.height,
        croppedData: item.croppedData,
        size: item.size,
      }));
  },
  {
    immediate: true,
    deep: true,
  },
);

watch(
  draftId,
  (value) => {
    if (draftId.value) {
      router.replace({
        query: {
          draftId: draftId.value,
        },
      });
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => duration.value,
  () => {
    if (duration.value) {
      // duration有值之后就保存草稿
      editVideoStore.updateEditAssetInfo(editAssetInfo.value);
      currentEditItem.value = editAssetInfo.value[0];
    }
  },
  {
    immediate: true,
  },
);

watch([templateId, ratio], (value) => {
  collectData("boolvideo_export_click", {
    video_type: "templates",
    ratio: ratio.value?.replace(":", "_"),
    template_id: templateId.value || 0,
    editor_type: "template_editor",
  });
});

onMounted(() => {
  isShowEditOnboarding.value = getItem("isShowEditOnboarding");

  const rid = route.query.rid as string;
  const draftId2 = route.query.draftId as string;
  // 获取editID 和 后端编辑素材
  if (rid) {
    editVideoStore.getEditId(rid);
  } else if (draftId2) {
    editVideoStore.getEditRenderInfo(draftId2);
  }
  collectData("boolvideo_export_click", {
    template_id: templateId.value,
    rid: rid,
    reqId: reqId.value,
    draftId: draftId2,
  });
  document.addEventListener("resize", handleWindowResize, { passive: true });
});

const handleWindowResize = debounce(() => {
  const target = document.getElementById("edit-player-box");
  if (!target) {
    return;
  }
  const width = target.getBoundingClientRect();
}, 16);

const handleAssetListScroll = () => {
  isScroll.value = true;
};

const handleItemClick = (item: EditableAssetItem, index: number) => {

  // 音频不需要聚焦
  if (item.type === 9) {
    return
  }
  // 先通过key过滤下，避免频繁触发
  if (item.key !== currentEditItem.value?.key) {
    currentEditItem.value = item;

    // 文字不显示编辑框
    if (item.type === 6 || item.type === 3) {
      editPlayerRef.value?.hideLayerResizerBox();
      editPlayerRef.value?.goToAndStop(item.positioningFrame, true);
      return;
    }

    editPlayerRef.value?.makeLayerEditable(item);
  }
};

const handleZoomChange = (item: EditableAssetItem, zoomValue: number) => {
  // 使图层进度可编辑模式
  editPlayerRef.value?.makeLayerEditable(item);
  // 根据zoom值进行大小位置调整
  editPlayerRef.value?.resizeLayerByZoom(zoomValue);
};

const handleOpenAssetDialog = () => {
  isOpenAssetDialog.value = true;
};

const handleOpenCropModal = (item: EditableAssetItem) => {
  isOpenCropModal.value = true;
  currentCropItem.value = item;
};

const handleCloseCropDialog = () => {
  isOpenCropModal.value = false;
};

const handleCloseAssetDialog = () => {
  isOpenAssetDialog.value = false;
};

const handleReplace = (item: ImageCropInfo) => {
  isOpenAssetDialog.value = false;
  editAssetInfo.value.forEach((asset) => {
    if (currentEditItem.value.key == asset.key) {
      asset.cropUrl = item.url;
      asset.material = item.url;
      editVideoStore.updateEditAssetInfo(editAssetInfo.value);
      editPlayerRef.value?.goToAndStop(asset.positioningFrame, true);
    }
  });

  clipAbleAssets.value.forEach((asset) => {
    if (currentEditItem.value.key == asset.key) {
      if (asset.url != item.url && asset.croppedData) {
        // 如果图片改变了，重置裁剪参数
        asset.croppedData.axis = undefined;
      }
    }
  });
};

// 确定裁剪之后修改数据
const handleCropChange = (croppedAssets: CropAssetData[]) => {
  editAssetInfo.value.forEach((item) => {
    croppedAssets.forEach((e) => {
      if (item.key == e.key && e.croppedData) {
        item.cropUrl = e.croppedData.url;
        // 记录下来用于回显
        item.croppedData = e.croppedData;
      }
    });
  });
  isOpenCropModal.value = false;
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  // 使播放器刷新
  if (currentCropItem.value) {
    editPlayerRef.value?.makeLayerEditable(currentCropItem.value);
  }
};

const handleOffsetUpdate = ({ layerId, offsetData }: OffsetUpdateEventArgs) => {
  editAssetInfo.value.forEach((item) => {
    if (item.layerId === layerId) {
      item.offsetData = offsetData;
    }
  });
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
};

const handleAnimLoaded = () => {
  isLoaded.value = true;
};

const handleOnPlaying = (isPlay: boolean) => {
  isPlaying.value = isPlay;
  if (isPlaying.value) {
    currentEditItem.value = null;
  }
};

const handleDeleteLogo = (key: string) => {
  // 删除设置为一张透明图
  editAssetInfo.value.forEach((asset) => {
    if (key == asset.key) {
      asset.cropUrl = opacityImageUrl;
      editVideoStore.updateEditAssetInfo(editAssetInfo.value);
      editPlayerRef.value?.goToAndStop(asset.positioningFrame, true);
    }
  });
};

// 字体大小
const handleSizeChange = (changeData: ChangeTextFontSize) => {
  const { key, fontSize } = changeData;
  let index = editAssetInfo.value.findIndex((asset) => asset.key == key);
  if (index < 0) {
    return;
  }
  editAssetInfo.value[index].fontSize = fontSize;
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  editPlayerRef.value?.updateDocumentData(editAssetInfo.value[index]);
};

// 字体颜色
const handleTextColorChange = (changeData: ChangeFillColor) => {
  const { key, fillColor } = changeData;
  let index = editAssetInfo.value.findIndex((asset) => asset.key == key);
  if (index < 0) {
    return;
  }

  editAssetInfo.value[index].fillColor = fillColor;
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  editPlayerRef.value?.updateDocumentData(editAssetInfo.value[index]);
};

// 图片反色、字体颜色、字体大小修改之后刷新当前帧
const handleImageReversColor = (changeData: ChangeReverseColor) => {
  const { key, isReverseColor, reverseColor } = changeData;
  let index = editAssetInfo.value.findIndex((asset) => asset.key == key);
  if (index < 0) {
    return;
  }

  editAssetInfo.value[index].isReverseColor = isReverseColor;
  editAssetInfo.value[index].reverseColor = reverseColor;
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  editPlayerRef.value?.goToAndStop(
    editAssetInfo.value[index].positioningFrame,
    true,
  );
};

const handleResetText = (resetData: ResetTextData) => {
  const { key, fillColor, fontSize, material } = resetData;
  let index = editAssetInfo.value.findIndex((asset) => asset.key == key);
  if (index < 0) {
    return;
  }

  editAssetInfo.value[index].fillColor = fillColor;
  editAssetInfo.value[index].fontSize = fontSize;
  editAssetInfo.value[index].material = material;
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  editPlayerRef.value?.updateDocumentData(editAssetInfo.value[index]);
};

const handleTextContentChange = (changeData: ChangeTextData) => {
  const { key, material } = changeData;
  let index = editAssetInfo.value.findIndex((asset) => asset.key == key);
  if (index < 0) {
    return;
  }

  editAssetInfo.value[index].material = material;
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  editPlayerRef.value?.updateDocumentData(editAssetInfo.value[index]);
};

const handleOpenAudioModal = () => {
  editPlayerRef.value?.pause()
  audioModalVisible.value = true
}

const handleResetAudio = (value) => {
  if (!value) {
    return
  }

  isCanRestAudio.value = false
  editPlayerRef.value?.pause()
  let originMaterial = ''
  editAssetInfo.value.forEach(audio => {
    if (audio.type == 9) {
      audio.key = audio.key
      audio.coverPic = audio.originCoverPic
      audio.duration = audio.originDuration
      audio.material = audio.originMaterial
      audio.audioName = audio.originAudioName
      audio.disabled = false
      originMaterial = audio.originMaterial
    }
  })

  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  editPlayerRef.value?.replay();
  audioModalVisible.value = false
}

const handleRemoveAudio = (url: string) => {
  if (!url) {
    return
  }
  editPlayerRef.value?.pause()
  editAssetInfo.value.forEach(audio => {
    if (audio.type == 9) {
      audio.coverPic = ''
      audio.duration = ''
      audio.material = ''
      audio.audioName = 'No audio'
      audio.disabled = true
    }
  })
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  isCanRestAudio.value = true;
  editPlayerRef.value?.replay();
  audioModalVisible.value = false
}

const handleAudioModalClose = () => {
  audioModalVisible.value = false
}

const handleReplaceAudio = (item: any) => {
  editAssetInfo.value.forEach(audio => {
    if (audio.type == 9) {
      audio.coverPic = item.coverPic
      audio.duration = item.duration
      audio.material = item.url
      audio.audioName = item.name
      audio.disabled = false
    }
  });
  editVideoStore.updateEditAssetInfo(editAssetInfo.value);
  audioModalVisible.value = false;
  isCanRestAudio.value = true;
  isLoadingAudio.value = true
  new Howl({
    src: [item.url],
  }).once('load', () => {
    isLoadingAudio.value = false;
    editPlayerRef.value?.replay();
  });
}

onBeforeUnmount(() => {
  editVideoStore.clearDraftId();
});

onUnmounted(() => {
  editPlayerRef.value?.destroy();
});
</script>
<style lang="scss">
.hidden-container {
  height: calc(100vh - 60px);

  .loading-mask {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
    z-index: 2;
  }

}

.edit-video-container {
  min-width: 1000px;
  overflow: hidden;
  height: 100%;

  .el-skeleton__item {
    svg {
      display: none;
    }
  }

  .el-loading-spinner .path {
    stroke: #646a73 !important;
  }
}

.music-container {
  padding-top: 27px;
  padding-bottom: 28px;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  font-weight: 400;
  font-family: Inter-variant;
  min-width: 300px;

  .music-name {
    color: #060606;
  }

  .disabled-music-name {
    color: #8F959E;
  }

  .icon-box {
    padding: 4px;
    display: flex;
    border-radius: 4px;
    justify-items: center;
    align-content: center;
    cursor: pointer;

    &:hover {
      background: rgba(31, 35, 41, 0.08);
    }
  }

  .icon-disabled-box {
    padding: 4px;
    display: flex;
    border-radius: 4px;
    justify-items: center;
    align-content: center;
  }

  .upload-text {
    color: #6741FF;
    cursor: pointer;
  }
}

.icon-music-box {
  width: 18px;
  height: 18px;
}

.scroll-container {
  .el-scrollbar__view {
    padding-bottom: 60px;
  }
}

.right-container {
  padding: 60px;
  width: 100%;
  height: 100%;
  height: calc(100vh - 60px);
  overflow: auto;
}

.edit-player-container {
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-box {
  margin-top: -60px;
  height: 100%;
  max-height: 654px;
  min-height: 350px;
}
</style>
