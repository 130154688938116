import { _get, _post, _delete } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

interface GetProjectMaterialListParams {
  page: number;
  size: number;
  excludeAi?: string[] | string;
  filterTypes?: string[] | string;
  draftId?: string | number;
}

interface GetCloudMaterialListParams {
  page: number;
  size: number;
  filterTypes?: string[];
  source?: string[];
}

interface GetVideoSpritesheetParams {
  frameMaxLongSide?: number;
  frameMaxShortSide?: number;
  intervalSeconds?: number;
  rowFrameCount?: number;
  url: string
}

interface DeleteProjectMaterialParams {
  draftId: string;
}

export async function getProjectMaterialList(
  params: GetProjectMaterialListParams,
) {
  return await _post(`${VITE_API_BASE}/material/project/page`, params);
}

export async function getCloudMaterialList(params: GetCloudMaterialListParams) {
  return await _post(`${VITE_API_BASE}/material/cloud/page`, params);
}

export async function getVideoSpritesheet(
  params: GetVideoSpritesheetParams,
) {
  return await _post(`${VITE_API_BASE}/material/video_sprite_image`, params);
}

export async function deleteProjectMaterial(
  mid: string,
  params: DeleteProjectMaterialParams,
) {
  return await _delete(`${VITE_API_BASE}/material/project/${mid}`, params);
}
