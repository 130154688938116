<script setup>
import { fileUploadPre, uploadFile } from "@/api/upload";

const aspectRatioOptions = [
  {
    label: "Freedom",
    value: [],
  },
  {
    label: "1:1",
    value: [1, 1],
  },
  {
    label: "3:2",
    value: [3, 2],
  },
  {
    label: "4:3",
    value: [4, 3],
  },
  {
    label: "9:16",
    value: [9, 16],
  },
  {
    label: "16:9",
    value: [16, 9],
  },
];

const emit = defineEmits(["change", "update:modelValue", "update:src"]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  src: {
    type: String,
    default: "",
  },
});
const route = useRoute();
const visible = ref(props.modelValue);
const cropperRef = ref(null);
const loading = ref(false);
const aspectRatio = ref([]);

watch(
  () => props.modelValue,
  (newValue) => {
    visible.value = newValue;
  },
);
watch(visible, (newValue) => {
  emit("update:modelValue", newValue);
});

async function crop() {
  loading.value = true;

  const data = await cropperRef.value.getCropBlob();
  const file = new File([data], "untitled.png");

  const { draftId } = route.query;
  const params = {
    draftId,
    suffix: ".png",
    type: "image",
    cropMaterial: props.src,
  };
  const response = await fileUploadPre(params);

  if (response.success) {
    const { preSignUrl, cropUrl } = response.data;
    const { code } = await uploadFile(preSignUrl, file);

    if (code === 0) {
      emit("change", cropUrl);
      emit("update:src", cropUrl);
      visible.value = false;
    }
  }
  loading.value = false;
}
function rotateLeft() {
  cropperRef.value.rotateLeft();
}

function reset() {
  aspectRatio.value = [];
  cropperRef.value.rotateClear();
}
</script>
<template>
  <bv-modal title="Crop" v-model="visible" modalClass="cropper-modal">
    <template #content>
      <div class="cropper-container">
        <cropper
          ref="cropperRef"
          :img="visible ? src : null"
          :fixed="aspectRatio.length > 0"
          :fixed-number="aspectRatio"
        />
      </div>
      <div class="tools">
        <bv-select
          width="133px"
          v-model="aspectRatio"
          :options="aspectRatioOptions"
          :disabled="loading"
        >
          <template #prefix>
            <svg-icon name="editor_crop" :disabled="loading" :size="16" />
          </template>
        </bv-select>
        <icon-button
          name="editor_crop_rotate"
          :disabled="loading"
          :size="24"
          @click="rotateLeft"
        />
        <icon-button
          name="editor_crop_reset"
          :disabled="loading"
          :size="24"
          @click="reset"
        />
        <span class="divider"></span>
        <button class="apply-button" :disabled="loading" @click="crop">
          <svg-icon v-if="loading" class="" name="icon_loading" :size="18" />
          Apply
        </button>
      </div>
    </template>
  </bv-modal>
</template>
<style>
.cropper-modal .modal-content {
  position: relative;
  overflow: hidden;
}

.cropper-modal .cropper-container {
  width: 100%;
  height: calc(100% - 132px);
}

.cropper-modal .tools {
  height: 114px;
  padding: 30px 32px;
  position: absolute;
  left: 50%;
  bottom: 28px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #e5e7eb;
  border-radius: 15px;
}

.cropper-modal .tools .bv-select-button {
  height: 38px;
  margin-right: 26px;
}

.cropper-modal .tools .icon-button {
  margin-right: 26px;
}

.cropper-modal .tools .select-wapper {
  padding: 0 10px;
}

.cropper-modal .tools .select-wapper .prefix {
  margin: 0 12px 0 2px;
}

.cropper-modal .tools .select-wapper.border {
  border: 0.5px solid #bbbfc4;
}

.cropper-modal .tools .select-wapper .select-value {
  color: #060606;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.cropper-modal .divider {
  width: 1px;
  height: 47px;
  background-color: #e5e7eb;
  margin-right: 30px;
}
.cropper-modal .apply-button {
  width: 120px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.cropper-modal .apply-button:disabled {
  background: #bbbfc4 !important;
  cursor: not-allowed;
}
.cropper-modal .apply-button:hover {
  background: linear-gradient(289deg, #6f46f4 5.38%, #957aec 99.95%);
}
.cropper-modal .apply-button svg {
  animation: rotate 1s linear infinite;
  margin-right: 6px;
}
</style>
