<template>
  <!-- Card container -->
  <div class="card-box">
    <div class="card-container" v-for="card in displayCardList" :key="card.name">
      <PricingCardItem
        :card="card"
        :subscriptionState="subscriptionState"
        :isLoading="isLoading"
        :userOperationMap="userOperationMap"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import PricingCardItem from "./pricing-card-item.vue";
import type { CardItemInfo } from "../../type";
import { useSubscriptionInfo } from "@/store/modules/user";

const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const props = defineProps({
  cardList: {
    type: Array<CardItemInfo>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  userOperationMap: {
    type: Object,
    required: true,
  },
});


const displayCardList = computed(() => {
  const { currentPlanName} = subscriptionState.value;
  if (currentPlanName === "Starter") {
    return props.cardList;
  }
  else {
    return props.cardList.filter(item => 
      item.name !== "Starter"
    );
  }
});
</script>

<style lang="scss" scoped>
.card-box {
  display: grid;
  max-width: 1672px;
  justify-items: center;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  margin-top: 68px;
  margin-bottom: 104px;
  padding: 0 1.5rem;
}

.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  min-width: 318px;
  padding: 0 20px 36px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dee0e3;
  border-radius: 8px;
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.08);
}

@media screen and (min-width: 820px) {
  .card-box {
    grid-template-columns: repeat(2, minmax(318px, 400px));
    padding: 0 2.25rem;
  }
}

@media screen and (min-width: 1340px) {
  .card-box {
    grid-template-columns: repeat(auto-fit, minmax(318px, 400px));
    padding: 0 2.5rem;
  }
}
</style>
