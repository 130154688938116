<template>
  <section class="use-case-part">
    <h2>Engaging use case</h2>
    <div>
      <ul
        class="type-list"
        v-if="!((route.name as string) in routeMap)"
        @click="handleClickNav"
      >
        <li
          v-for="item of previewList"
          :key="item.key"
          :data-key="item.key"
          :class="{ active: currentKey === item.key }"
        >
          <h3>{{ item.name }}</h3>
        </li>
      </ul>
      <ul :key="currentItem.key" class="case-list">
        <li v-for="(item, i) of currentItem.assets" :style="currentKey === '2' ? 'height: 343px' : ''">
          <player :posterUrl="item.poster" :resourceUrl="item.src" v-model:isMute="isMute" :init-play="i === 0" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">
import previewList from './data';
import player from './player.vue';
import { MutexSpace } from '@/utils/type';
import { useRoute } from 'vue-router';
import { routeMap } from './data';

const route = useRoute();
const mutexSpace = new MutexSpace();
const currentKey = ref(routeMap[route.name as string] ?? previewList[0].key);
const currentItem = computed(() => previewList.find(item => item.key === currentKey.value)!);
const isMute = ref(true);
const handleClickNav = (e: MouseEvent) => {
  const target = (e.target as HTMLElement).closest<HTMLElement>('li[data-key]');

  if (target === null) return;
  currentKey.value = target.dataset.key!;
}

provide('mutexSpace', mutexSpace);
</script>

<style scoped lang="scss">
.use-case-part {
  padding: 120px calc((100% - var(--view-width)) / 2);

  & > h2 {
    margin-bottom: 90px;
    font-size: 60px;
    font-weight: 700;
    text-align: center;
  }
}

.type-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;

  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 96px;
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    // transition:
    //   color 0.2s,
    //   background-color 0.2s,
    //   border-color 0.2s;

    cursor: pointer;

    &.active {
      color: #6741ff;
      background-color: #f8f5ff;
      border-color: #875eff;
    }
  }
}

.case-list {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 108px;

  & > li {
    border: 1px solid #E5E7EB;
    background-color: #E5E7EB;
    border-radius: 12px;
    overflow: hidden;
    height: 525px;
    flex: 1;

    & > div {
      height: 100%;
    }
  }
}
</style>
