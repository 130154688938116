<template>
  <div :data-id="elementId" class="animation-container border-borderColor" :class="{ 'border-style': showBorder }"
    :style="getCurrentStyle" @mouseenter="hoverStarted" @mouseleave="hoverEnded"></div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, type PropType } from "vue";
// @ts-ignore
import boolvRender from "@boolv/renderer";
import { EncryptedDataHandler } from "./dataHandler";
// @ts-ignore
import { cloneDeep, isEqual } from "lodash-es";
import type {
  AnimationDirection,
  AnimationItem,
  AnimationSegment,
  PlayerProps,
} from "./types";

const props = defineProps({
  round: {
    type: Number
  },
  showBorder: {
    type: Boolean as PropType<PlayerProps["showBorder"]>,
    default: false,
  },
  src: {
    type: String as PropType<PlayerProps["src"]>,
    default: "",
  },
  animationData: {
    type: Object as PropType<PlayerProps["animationData"]>,
    default: () => ({}),
  },
  assetsPath: {
    type: String as PropType<PlayerProps["assetsPath"]>,
    default: "",
  },
  loop: {
    type: [Boolean, Number] as PropType<PlayerProps["loop"]>,
    default: true,
  },
  autoplay: {
    type: Boolean as PropType<PlayerProps["autoPlay"]>,
    default: true,
  },
  speed: {
    type: Number as PropType<PlayerProps["speed"]>,
    default: 1,
  },
  delay: {
    type: Number as PropType<PlayerProps["delay"]>,
    default: 0,
  },
  direction: {
    type: String as PropType<PlayerProps["direction"]>,
    default: "forward",
  },
  pauseOnHover: {
    type: Boolean as PropType<PlayerProps["pauseOnHover"]>,
    default: false,
  },
  playOnHover: {
    type: Boolean as PropType<PlayerProps["playOnHover"]>,
    default: false,
  },
  backgroundColor: {
    type: String as PropType<PlayerProps["backgroundColor"]>,
    default: "transparent",
  },
  pauseAnimation: {
    type: Boolean as PropType<PlayerProps["pauseAnimation"]>,
    default: false,
  },
  renderer: {
    type: String as PropType<PlayerProps["renderer"]>,
    default: "svg",
  },
  rendererSettings: {
    type: Object as PropType<PlayerProps["rendererSettings"]>,
    default: () => ({}),
  },
  style: {
    type: Object as PropType<PlayerProps["style"]>,
    default: () => ({}),
  },
  editAssets: {
    type: Object,
    default: () => [],
  },
});

const emits = defineEmits({
  onReady: null,
  onActive: null,
  onIdle: null,
  onComplete: null,
  onLoopComplete: null,
  onEnterFrame: null,
  onSegmentStart: null,
  onAnimationLoaded: null,
});

const elementId = ref<string>("");

let animation: AnimationItem | null = null;
let animationAPI: any | null = null;
let direction: AnimationDirection = 1;
const audioPath = ref(null)

// hack fix supplement for ssr
const checkIfContainerExists = (elementID: String) => {
  if (document.querySelector(`[data-id="${elementID}"]`) !== null) {
    return true;
  } else {
    return false;
  }
};

const loadAnim = async (element: Element) => {
  let autoPlay = props.autoplay;

  if (props.playOnHover) {
    autoPlay = false;
  }

  // creating a copy of the animation data to prevent the original data from being modified
  // also needed to render multiple animations on the same page
  let animationData = null;
  if (isEqual(props.animationData, {}) === false) {
    animationData = cloneDeep(props.animationData);
  }

  let loop = props.loop;

  // drop the loop by one
  if (typeof loop === "number") {
    if (loop > 0) {
      loop = loop - 1;
    }
  }

  if (props.delay > 0) {
    autoPlay = false;
  }

  const animationConfig: any = {
    container: element,
    renderer: props.renderer,
    loop: loop,
    autoplay: autoPlay,
    path: props.src,
    animationData: animationData,
    assetsPath: props.assetsPath,
    editAssets: props.editAssets,
    renderInfo: {
      url: props.src,
      assets: props.editAssets,
    },
    audioPath: audioPath.value
  };

  if (isEqual(props.rendererSettings, {}) === false) {
    animationConfig.rendererSettings = props.rendererSettings;
  }

  // actually load the animation
  animation = boolvRender.loadAnimation(animationConfig);
  setTimeout(() => {
    autoPlay = props.autoplay;

    if (props.playOnHover) {
      animation?.pause();
    } else {
      if (autoPlay) {
        animation?.play();
      } else {
        animation?.pause();
      }
    }

    /**
     * Emit an `onAnimationLoaded` event when the animation is loaded
     * This should help with times where you want to run functions on the ref of the element
     */
    emits("onAnimationLoaded");
  }, props.delay);

  animation.setSpeed(props.speed);

  if (props.direction === "reverse") {
    animation.setDirection(-1);
  }
  if (props.direction === "normal") {
    animation.setDirection(1);
  }

  if (props.pauseAnimation) {
    animation.pause();
  } else {
    if (props.playOnHover) {
      animation.pause();
    }
  }

  // set the emit events
  animation.addEventListener("DOMLoaded", () => {
    emits("onReady", animation);
  });

  // @ts-ignore
  animation.addEventListener("_active", () => {
    emits("onActive");
  });

  // @ts-ignore
  animation.addEventListener("_idle", () => {
    emits("onIdle");
  });

  animation.addEventListener("loopComplete", () => {
    if (props.direction === "alternate") {
      animation?.stop();
      direction = direction === -1 ? 1 : -1; //invert direction
      animation?.setDirection(direction);
      animation?.play();
    }
    emits("onLoopComplete");
  });

  animation.addEventListener("complete", () => {
    emits("onComplete");
  });

  animation.addEventListener("enterFrame", () => {
    emits("onEnterFrame", animation!!.currentFrame);
  });

  animation.addEventListener("segmentStart", () => {
    emits("onSegmentStart");
  });
};

// generate the css variables for width, height and background color
const getCurrentStyle: any = computed(() => {
  const style = props.style;

  // set to px values if a number is passed
  if (typeof style.width === "number") {
    style.width = `${style.width}px`;
  }

  if (typeof style.height === "number") {
    style.height = `${style.height}px`;
  }

  return {
    margin: "0 auto",
    outline: "none",
    overflow: "hidden",
    ...style,
  };
});

// function to check if the container is being hovered
const hoverStarted = () => {
  if (animation && props.pauseOnHover) {
    animation.pause();
  }

  if (animation && props.playOnHover) {
    animation.play();
  }
};

// function to check if the container is no longer being hovered
const hoverEnded = () => {
  if (animation && props.pauseOnHover) {
    animation.play();
  }
  if (animation && props.playOnHover) {
    animation.pause();
  }
};

// watch for changes in props.pauseAnimation
watch(
  () => props.pauseAnimation,
  () => {
    // error if pauseAnimation is true and pauseOnHover is also true or playOnHover is also true
    if ((props.pauseOnHover || props.playOnHover) && props.pauseAnimation) {
      console.error(
        "If you are using pauseAnimation prop for player, please remove the props pauseOnHover and playOnHover",
      );
      return;
    }

    // control the animation play state
    if (animation) {
      if (props.pauseAnimation) {
        animation.pause();
      } else {
        animation.play();
      }
    }
  },
);


watch(() => props.editAssets, (list) => {
  const audio = list.find((item: any) => item.type == 9)
  audioPath.value = audio?.material
}, {
  immediate: true
})

// function to generate random strings for IDs
const makeId = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const setupAnim = (elementID: String) => {
  if (props.pauseOnHover && props.playOnHover) {
    throw new Error("您不能同时为player设置pauseOnHover和playOnHover.");
  }

  if (props.src === "" && !props.animationData) {
    throw new Error("你必须提供src或animationData.");
  }

  // Unfortunately, this is a hackfix for ssr. We need to wait for the element to be rendered before we can load the animation.
  // One day I will figure out how to do this properly.
  const interval = setInterval(() => {
    if (checkIfContainerExists(elementID)) {
      clearInterval(interval);
      const element = document.querySelector(`[data-id="${elementID}" ]`);
      if (element) {
        loadAnim(element); // load the animation
      }
    }
  }, 0);
};

onMounted(async () => {
  // @ts-ignore
  boolvRender.setDataHandler(new EncryptedDataHandler());
  elementId.value = makeId(20); // generate a random id for the container
  setupAnim(elementId.value);
});

watch(
  () => props.src,
  (value) => {
    if (value) {
      setupAnim(elementId.value);
    }
  },
);

// method to get the configJson
const getConfig = () => {
  // @ts-ignore
  return animation?.animationData;
};

// method to play the animation
const play = () => {
  if (animation) {
    animation.play();
  }
};

// method to pause the animation
const pause = () => {
  if (animation) {
    animation.pause();
  }
};

// method to stop the animation. It will reset the animation to the first frame
const stop = () => {
  if (animation) {
    animation.stop();
  }
};

const replay = () => {
  if (animation) {
    animation.replay();
  }
};

const mute = () => {
  if (animation) {
    animation.mute();
  }
};

const unmute = () => {
  if (animation) {
    animation.unmute();
  }
};

const destroy = () => {
  if (animation) {
    animation.destroy();
  }
};

const setSpeed = (speed: number = 1) => {
  // speed: 1 is normal speed.

  if (speed <= 0) {
    throw new Error("Speed must be greater than 0");
  }

  if (animation) {
    animation.setSpeed(speed);
  }
};

const setDirection = (direction: "forward" | "reverse") => {
  if (animation) {
    if (direction === "forward") {
      animation.setDirection(1);
    } else if (direction === "reverse") {
      animation.setDirection(-1);
    }
  }
};
const markLayerActive = (layerId: string) => {
  if (animation) {
    animation.markLayerActive(layerId);
  }
};

const goToAndStop = (frame: number, isFrame: boolean = true) => {
  //value: numeric value.
  //isFrame: defines if first argument is a time based value or a frame based (default true).

  if (animation) {
    animation.goToAndStop(frame, isFrame);
  }
};

const goToAndPlay = (frame: number, isFrame: boolean = true) => {
  //value: numeric value
  //isFrame: defines if first argument is a time based value or a frame based (default true).

  if (animation) {
    animation.goToAndPlay(frame, isFrame);
  }
};

const playSegments = (
  segments: AnimationSegment[],
  forceFlag: boolean = false,
) => {
  //segments: array. Can contain 2 numeric values that will be used as first and last frame of the animation. Or can contain a sequence of arrays each with 2 numeric values.
  //forceFlag: boolean. If set to false, it will wait until the current segment is complete. If true, it will update values immediately.

  if (animation) {
    animation.playSegments(segments, forceFlag);
  }
};

const setSubFrame = (useSubFrame: boolean = true) => {
  // useSubFrames: If false, it will respect the original AE fps. If true, it will update on every requestAnimationFrame with intermediate values. Default is true.
  if (animation) {
    animation.setSubframe(useSubFrame);
  }
};

const getDuration = (inFrames: boolean = true) => {
  if (animation) {
    return animation.getDuration(inFrames);
  }
};

const getCurrentFrame = () => {
  if (animation) {
    return animation.currentFrame;
  }
};

defineExpose({
  mute,
  unmute,
  play,
  pause,
  stop,
  replay,
  destroy,
  setSpeed,
  setDirection,
  markLayerActive,
  goToAndStop,
  goToAndPlay,
  playSegments,
  setSubFrame,
  getDuration,
  getConfig,
  getCurrentFrame,
});
</script>

<style lang="scss" scoped>
.animation-container {
  overflow: hidden;
  background-color: #f6f7f8;
}

.border-style {
  border-width: 0.5px;
}
</style>
