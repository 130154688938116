<template>
  <common-upload
    ref="uploadRef"
    class="space-upload-box"
    @click="uploadRef?.clearFileArr"
    :beforeUpload="onUpload"
    :handleSuccess="onSuccess"
    :handleError="onFail"
    :handleProgress="onProgress"
    :handleFileReceived="onFileReceived"
    :handlePreprocessingError="onPreprocessingError"
    :accept="accept"
  >
    <template #trigger>
      <div class="flex justify-center flex-col items-center">
        <svgIcon name="icon_upload" :size="32" style="color: #646a73" />
        <span class="upload-tip" :style="{'font-size': '14px'}">Upload media</span>
      </div>
    </template>
  </common-upload>
</template>

<script setup lang="ts">
import commonUpload from '@/components/common/bv-upload/commonUpload.vue';
import type { PropType } from 'vue';
import type { AcceptType } from '@/utils/type';
import type {
  UploadSuccessEvent,
  UploadProgressEvent,
  UploadEvent,
  UploadFailEvent,
  FileReceivedEvent,
} from '@/components/common/bv-upload/type';

defineProps({
  onUpload: {
    type: Function as PropType<(e: UploadEvent) => boolean>,
    default: () => {},
  },
  onFail: {
    type: Function as PropType<(e: UploadFailEvent) => void>,
    default: () => {},
  },
  onSuccess: {
    type: Function as PropType<(e: UploadSuccessEvent) => void>,
    default: () => {},
  },
  onProgress: {
    type: Function as PropType<(e: UploadProgressEvent) => void>,
    default: () => {},
  },
  onFileReceived: {
    type: Function as PropType<(e: FileReceivedEvent) => void>,
    default: () => {},
  },
  onPreprocessingError: {
    type: Function as PropType<(e: FileReceivedEvent) => void>,
    default: () => {},
  },
  accept: {
    type: Object as PropType<string | AcceptType | AcceptType[]>,
    default: '',
  },
});

const uploadRef = ref(null as unknown as InstanceType<typeof commonUpload>);

defineExpose({
  uploadRef,
});
</script>

<style lang="scss" scoped>
.space-upload-box {
  height: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
}
.space-upload-box:hover {
  background-color: #f4f5f7;
}

.space-upload-box :deep(.el-upload) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: var(--card-border-radius);
}

.space-upload-box .upload-tip {
  font-size: 14px;
  margin-top: 16px;
  color: #060606;
}
</style>
