<template>
  <el-dialog
    modal-class="editOnBording-modal"
    align-center
    v-model="dialogVisible"
    :close-on-click-modal="false"
    destroy-on-close
    :show-close="false"
    width="840px"
    @close="handleClose"
  >
    <!-- head -->
    <div class="flex justify-between items-center w-full header">
      <p class="tip">
        {{
          step == 1
            ? "Wrap text and change font size"
            : step == 2
            ? "Zoom and move pictures"
            : "Change logo color"
        }}
      </p>
      <div class="flex h-6">
        <svg-icon
          @click="handlePreStep"
          name="icon_arrow_left"
          width="24px"
          class="icon-left hover:bg-uploadBg rounded-sm cursor-pointer"
        ></svg-icon>
        <p class="step-num">{{ step }}/3</p>
        <svg-icon
          @click="handleNextStep"
          name="icon_arrow_right"
          width="24px"
          class="hover:bg-uploadBg rounded-sm cursor-pointer"
        ></svg-icon>
      </div>
    </div>
    <!-- content -->
    <!-- <component :is="currentStepCom"></component> -->
    <div class="step-box">
      <div class="step-one">
        <div class="left-part">
          <video
            :src="currentSrc"
            :poster="currentPoster"
            class="video"
            ref="videoRef"
            autoplay
            loop
          />
        </div>
      </div>
    </div>

    <!-- button -->
    <p class="next-button" @click="handleNextButton">
      {{ step == 3 ? "I got it" : "Next" }}
    </p>
  </el-dialog>
</template>
<script setup>
import { ref, watch, computed } from "vue";
import { StepOne, StepTwo, StepThree } from "./steps/index.js";
import { getItem, setItem } from "@/utils/storage";
import text from "./images/text.mp4";
import textPoster from "./images/text.jpg";
import image from "./images/image.mp4";
import imagePoster from "./images/image.jpg";
import logo from "./images/logo.mp4";
import logoPoster from "./images/logo.jpg";

const imageList = [textPoster, imagePoster, logoPoster];

const emit = defineEmits(["close"]);
const props = defineProps({
  visible: Boolean,
  type: Number,
});

const dialogVisible = ref(true);
const step = ref(1);
const currentSrc = ref(text);
const currentPoster = ref(textPoster);
const videoRef = ref(null);

const loadImage = (src) => {
  imageList.forEach((src) => {
    let img = new Image();
    img.src = src;
    img.onload = function () {
      //加载时执行resolve函数
    };
    img.onerror = function () {};
  });
};

watch(
  () => step.value,
  (value) => {
    if (value === 1) {
      currentSrc.value = text;
      currentPoster.value = textPoster;
      videoRef.value.currentTime = 2;
    }

    if (value === 2) {
      currentSrc.value = image;
      currentPoster.value = imagePoster;
    }
    if (value === 3) {
      currentSrc.value = logo;
      currentPoster.value = logoPoster;
    }
  },
);

watch(
  () => props.visible,
  (value) => {
    dialogVisible.value = value;
    loadImage();
  },
);

// 关闭
const handleClose = () => {
  dialogVisible.value = false;
  emit("close");
};

const handlePreStep = () => {
  if (step.value == 1) {
    return;
  }
  step.value = step.value -= 1;
};

const handleNextStep = () => {
  if (step.value == 3) {
    return;
  }
  step.value = step.value += 1;
};

const handleNextButton = () => {
  if (step.value == 3) {
    handleClose();
    setItem("isShowEditOnboarding", 1);
  }
  step.value = step.value += 1;
};

const currentStepCom = computed(() => {
  switch (step.value) {
    case 1:
      return StepOne;
    case 2:
      return StepTwo;
    case 3:
      return StepThree;
    default:
      break;
  }
});
</script>
<style lang="scss">
.editOnBording-modal {
  .el-dialog {
    border-radius: 4px;
  }

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 24px 24px 18px 24px;
    height: 564px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .el-dialog__footer {
    display: flex;
    padding: 20px 0;
    border-top: 1px solid #1f232926;
  }

  .tip {
    color: #060606;
    font-weight: 500;
    line-height: 24px;
  }

  .header {
    margin-bottom: 22px;
  }

  .step-num {
    line-height: 24px;
    margin: 0 24px;
    color: #000000;
  }

  .next-button {
    color: #060606;
    cursor: pointer;
    display: flex;
    width: 200px;
    padding: 10px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 36px;
    border: 1px solid var(--text-t-4-disable, #bbbfc4);
    background: #fff;

    &:hover {
      cursor: pointer;
      border-radius: 36px;
      border: 1px solid #bbbfc4;
      background: #f5f6f7;
    }
  }
}
</style>
<style lang="scss" scoped>
.step-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 414px;
  border-radius: 4px;
  margin-bottom: 18px;
}

.step {
  line-height: 24px;
  margin: 0 24px;
}

.step-one {
  width: 100%;
  height: 100%;
  display: flex;

  .left-part {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #e5e7eb;

    .video {
      width: 792px;
      height: 414px;
      border-radius: 10px;
    }
  }
}
</style>
