<template>
  <div class="pexels-container">
    <div class="search-container">
      <el-input
        v-model="keyword"
        placeholder="Search for free materials"
        @keyup.enter="search"
        @update:model-value="updateKeyword"
      >
        <template #prefix>
          <el-dropdown
            trigger="click"
            popper-class="script-type-popper"
            :teleported="true"
            :popper-options="{
              modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
            }"
            @command="command"
          >
            <div class="type-select">
              <span>{{ currentType }}</span>
              <svg-icon
                name="icon_left"
                class="icon_left"
                :size="16"
              />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in typeOptions"
                  :key="item"
                  :command="item"
                >
                  <span>{{ item }}</span>
                  <svg-icon
                    name="icon_select"
                    :size="16"
                    v-if="currentType === item"
                    style="color: #875eff"
                  />
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
        <template #suffix>
          <svg-icon name="editor_library_search" :size="18" />
        </template>
      </el-input>
      <el-popover
        ref="popover"
        placement="bottom-end"
        trigger="click"
        :show-arrow="false"
        :width="230"
        :hide-after="0"
        :teleported="false"
        :disabled="keyword.length === 0"
      >
        <template #reference>
          <icon-button
            border
            name="editor_library_filter"
            :size="18"
            :disabled="keyword.length === 0"
            :active="!!ratio || !!color"
            :color="!!ratio || !!color ? '#FFFFFF' : '#646A73'"
          />
        </template>
        <div class="filter-title">Ratio</div>
        <div class="ratio-list">
          <div
            class="ratio-option"
            v-for="(option, i) in ratioOptions"
            :key="i"
            :class="{ active: option === ratio }"
            @click="clickRatio(option)"
          >
            <div :class="option"></div>
          </div>
        </div>
        <div class="filter-title" v-if="showColor">Color</div>
        <div class="color-list" v-if="showColor">
          <div v-for="(col, i) in colorOptions" class="color-col" :key="i">
            <div
              v-for="(option, j) in col"
              class="color-option"
              :key="j"
              :style="{ backgroundColor: option }"
              @click="clickColor(option)"
            >
              <svg-icon
                v-show="option === color"
                name="editor_library_checked"
                :size="22"
              />
            </div>
          </div>
        </div>
        <div class="buttons">
          <ordinary-plain-button
            :disabled="!ratio && !color"
            size="extra-small"
            @click="reset"
            >Reset</ordinary-plain-button
          >
          <primary-button size="extra-small" @click="popover?.hide()"
            >Done</primary-button
          >
        </div>
      </el-popover>
    </div>
    <div class="tag-list">
      <span
        v-for="(option, i) in tagOptions"
        :key="i"
        class="tag"
        :class="{ active: option === keyword }"
        @click="clickTag(option)"
        >{{ option }}</span
      >
    </div>
    <el-scrollbar 
      class="file-scrollbar"
      ref="scrollbar"
    >
      <el-skeleton animated :loading="loading && page === 1">
        <template #template>
          <el-skeleton-item v-for="(_, i) in Array(40)" variant="rect" :key="i" />
        </template>
        <div v-if="files.length > 0" class="list-wrapper">
          <div
            ref="list"
            class="file-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-immediate="false"
            :infinite-scroll-distance="50"
          >
            <div
              class="file-item"
              v-for="(file, i) in files"
              :key="i"
              @mouseenter="hoverFile = file"
              @mouseleave="hoverFile = null"
              @click="clickFile(file)"
            >
              <div class="image-wrapper" drag-target :data-index="i">
                <img
                  :src="file.coverPic"
                  loading="lazy"
                  draggable="false"
                />
              </div>
              <span
                v-show="selectFile === file"
                class="select-mask"
              >
                <svg-icon
                  name="script_select_file"
                  :size="24"
                />
              </span>
              <span
                v-if="file.duration"
                v-show="hoverFile !== file"
                class="duration"
              >
                {{ secondsToHms(file.duration) }}
              </span>
              <span
                v-show="hoverFile === file"
                class="preview"
                @mousedown.stop="preview(file)"
              >
                <svg-icon name="editor_library_preview" clickable :size="18" />
              </span>
            </div>
          </div>
          <Loading v-show="loading && page > 1" />
        </div>
        <Empty v-else />
        <previewer ref="previewer" :showArrow="false" :record="file" :zIndex="3000" />
      </el-skeleton>
    </el-scrollbar>
  </div>
</template>

<script setup>
import Empty from "./empty.vue";
import Loading from "./loading.vue";
import Previewer from "../material-previewer/index.vue";
import { secondsToHms } from "../../utils";
import { getImageList, getVideoList } from "@/api/library"; 

const emits = defineEmits([
  "select",
]);
const props = defineProps({
  defaultValue: {
    type: String,
    default: "",
  },
});

const popover = ref(null);
const scrollbar = ref(null);
const previewer = ref(null);
const keyword = ref("");
const currentType = ref("Videos"); 
const hoverFile = ref(null);
const selectFile = ref(null);
const ratio = ref("");
const color = ref("");
const loading = ref(false);
const page = ref(1);
const total = ref(0);
const list = ref(null);
const files = ref([]);
const file = ref({});
const showColor = computed(() => currentType.value === "Photos");

const typeOptions = [
  "Videos",
  "Photos",
];
const tagOptions = [
  "Architecture",
  "Interiors",
  "Textures",
  "Patterns",
  "Nature",
  "Cozy",
];
const ratioOptions = ["portrait", "landscape", "square"];
const colorOptions = [
  ["#FFFFFF", "#CCCCCC", "#999999", "#666666", "#323333", "#000001"],
  ["#F9B8BA", "#F57A7B", "#F03D3D", "#D91012", "#9C0A0D", "#5F0708"],
  ["#F9DDB8", "#F5BD7C", "#F09F3D", "#DA8013", "#9D5B0F", "#5E3708"],
  ["#FAF6B8", "#F4ED7B", "#F0E73D", "#D9CE15", "#9B950D", "#5F590A"],
  ["#CFF9B9", "#A6F47A", "#7BF03D", "#57DA10", "#3F9C0C", "#265E06"],
  ["#B9F9E7", "#7BF5D6", "#3EEFC3", "#18D8A7", "#089C78", "#085E49"],
  ["#B9E5F9", "#7AD0F4", "#3FBAEE", "#0D9DD9", "#0B719C", "#07145F"],
  ["#B8C1F9", "#AA7BF4", "#853EF0", "#6112DA", "#450C9C", "#2A075E"],
  ["#F6B9FA", "#EE7BF3", "#E73EEF", "#CE12D8", "#950C9B", "#5A085E"],
];
const funcMap = {
  Photos: getImageList,
  Videos: getVideoList,
};

onMounted(() => {
  window.addEventListener("resize", () => {
    insertFile(files.value);
  });
  keyword.value = props.defaultValue;
  getList(1);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", () => {
    insertFile(files.value);
  });
});

watch(
  page,
  (newPage) => {
    if (newPage === 1) {
      scrollbar.value?.setScrollTop(0);
    }
    getList(newPage);
  },
);

watch(
  files,
  (newFiles) => {
    insertFile(newFiles);
  },
  { flush: "post" },
);

watch(
  currentType,
  () => {
    search();
  }
);

const insertFile = (newFiles) => {
  const colHelper = (containerWidth, colWidth, gap) => {
      const availableWidth = containerWidth + gap;
      const colCount = Math.floor(availableWidth / (colWidth + gap));
      const newColGap = Math.floor((containerWidth - colCount * colWidth) / (colCount - 1));
      return {colCount , newColGap };
    };
    if (list.value) {
      const listWrapper = document.querySelector(".list-wrapper");
      const { width: wrapperWidth } = listWrapper.getBoundingClientRect();
      const paddingWidth = parseFloat(getComputedStyle(listWrapper).paddingLeft) + parseFloat(getComputedStyle(listWrapper).paddingRight);
      const containerWidth = wrapperWidth - paddingWidth;
      const width = 110;
      const gap = 12;

      const {colCount, newColGap} = colHelper(containerWidth, width, gap);
      const hs = Array(colCount).fill(0);

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        const element = list.value.children[i];
        const wrapper = element.querySelector(".image-wrapper");
        const height = file.height * (width / file.width);
        const index = hs.indexOf(Math.min(...hs));
        const left = (width + newColGap) * index;
        const top = hs[index];

        element.style.left = `${left}px`;
        element.style.top = `${top}px`;
        element.style.height = wrapper.style.height = `${height}px`;

        hs[index] += height + gap;
      }
      list.value.style.height = `${Math.max(...hs)}px`;
    }
};

const command = (value) => {
  currentType.value = value;
};

function updateKeyword(value) {
  keyword.value = value;

  if (value === "") {
    ratio.value = "";
    color.value = "";
  }
}

function clickFile(file) {
  if (selectFile.value === file) {
    selectFile.value = null;
    return;
  }

  selectFile.value = file;
  const typeMap = {
    Photos: "image",
    Videos: "video"
  };
  const type = currentType.value;
  const newFile = {
    ...file,
    type: typeMap[type]
  }
  emits("select", newFile);
}

function clickTag(tag) {
  keyword.value = tag;

  search();
}

function clickRatio(value) {
  ratio.value = value === ratio.value ? "" : value;
  search();
}

function clickColor(value) {
  color.value = value === color.value ? "" : value;

  search();
}

function reset() {
  ratio.value = "";
  color.value = "";

  search();
}

function loadMore() {
  if (0 < files.value.length && files.value.length < total.value) {
    page.value++;
  }
}

async function getList(page) {
  loading.value = true;
  const data = { page, size: 40 };

  if (keyword.value) {
    data.keyword = keyword.value;
  }
  if (ratio.value) {
    data.shape = ratio.value;
  }
  if (color.value && currentType.value === "Photos") {
    data.color = color.value;
  }

  const type = currentType.value;
  const response = await funcMap[type](data);

  if (response.success) {
    const { records } = response.data;

    total.value = response.data.total;
    files.value = files.value.concat(records);
  }
  loading.value = false;
}

async function search() {
  files.value = [];

  if (page.value === 1) {
    await getList(1);
  } else {
    page.value = 1;
  }
};

function preview(value) {
  const typeMap = {
    Photos: "image",
    Videos: "video"
  };
  const type = currentType.value;
  file.value = { ...value, type: typeMap[type] };
  previewer.value.open = true;
}
</script>
<style>
.script-type-popper {
  width: 92px;
}

.script-type-popper .el-popper__arrow{
  display: none;
}

.script-type-popper .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px;
  color: #060606;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; 
}
</style>
<style lang="scss" scoped>
.file-scrollbar {
  width: 100%;
  flex: 1 1 auto;
}

:deep(.file-scrollbar) .el-scrollbar__view{
  min-height: calc(100% + 1px);
}

.pexels-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
}

.type-select {
  width: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #1C1B1E;

  & > span {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  & > svg {
    transform: rotate(-90deg);
    color: #BBBFC4;
  }
}

.search-container {
  display: flex;
  align-items: center;
}

:deep(.search-container .el-input) {
  height: 36px;
  width: 411px;
}

:deep(.search-container .el-input__wrapper) {
  padding: 4px 12px 4px 4px;
  border-color: #D5D6D7;
}

:deep(.search-container .el-input__inner) {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

:deep(.icon-button.border) {
  margin-left: 12px;
  border-radius: 4px;
}

:deep(.icon-button.border .icon-wapper) {
  padding: 9px;
}
:deep(.icon-button.active) {
  border: 1px solid #fff;
  background-color: #875eff;
}

:deep(.el-popover.el-popper) {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.08);
  padding: 12px 12px 20px;
}
.filter-title {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.ratio-list {
  margin: 10px 0 20px;
  display: flex;
  justify-content: space-between;
}
.ratio-option {
  width: 64px;
  height: 38px;
  border-radius: 2px;
  border: 0.5px solid #e5e7eb;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
}
.ratio-option.active {
  border-color: #6741ff;
  background-color: #f8f5ff;
}
.ratio-option div {
  background-color: #fff;
  border-radius: 2px;
  border: 0.5px solid #646a73;
  transition: border-color 200ms;
}
.ratio-option.active div {
  border-color: #6741ff;
  background-color: #f8f5ff;
}
.ratio-option .landscape {
  width: 30px;
  height: 18px;
}
.ratio-option .portrait {
  width: 18px;
  height: 30px;
}
.ratio-option .square {
  width: 24px;
  height: 24px;
}
.color-list {
  display: flex;
  margin-top: 5px;
}
.color-col + .color-col {
  margin-left: 1px;
}
.color-option {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.color-option + .color-option {
  margin-top: 1px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
:deep(.buttons .el-button) {
  padding: 0;
  width: 94px;
  height: 36px;
}
.tag-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 60px 18px;
  gap: 6px;
}
.tag {
  padding: 4px 7px;
  border-radius: 2px;
  background-color: #ebedef;
  color: #646a73;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  transition: all 200ms;
}

.tag.active {
  background-color: #646a73;
  color: #f8f5ff;
}

.search-container > .icon-button.border {
  border-width: 1px;
}

:deep(.el-skeleton) {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  padding: 0 60px;
}

.image-wrapper,
:deep(.el-skeleton__item) {
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f3f5f7;
  border-radius: 4px;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}

:deep(.el-skeleton__item) {
  height: 70px;
}
.list-wrapper {
  padding: 0 60px;
}
.file-list {
  width: 100%;
  position: relative;
}

.file-item {
  width: 110px;
  position: absolute;
}

.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-item .duration {
  padding: 0 8px;
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  right: 4px;
  bottom: 4px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.file-item .preview {
  padding: 3px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  border-radius: 2px;
  background-color: rgba(38, 38, 38, 0.7);
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%);
}
.empty p {
  color: #646a73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: -25px;
}

.select-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.60);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>