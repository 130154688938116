<template>
  <div class="space-exported-wrap">
    <div class="space-exported-container perfect-scrollbar">
      <el-row class="h-full w-full">
        <el-col :span="16" class="h-full">
          <div class="video-container" id="video-container">
            <div class="video-box"
              :class="{ 'video-resize-height': isResizeHeight, 'video-resize-width': isResizeWidth }">
              <div class="video-wrap" @click.stop="handleControlPlay" @mousemove.stop="handleMouseMove"
                @mouseleave.stop="handleMouseLeave"
                :style="{ aspectRatio: ratio == '9:16' ? '9/16' : ratio == '16:9' ? '16/9' : '1/1', width: ratio == '16:9' ? '100%' : '', height: ratio == '16:9' ? '' : '100%' }">

                <video ref="videoRef"
                  :style="{ aspectRatio: ratio == '9:16' ? '9/16' : ratio == '16:9' ? '16/9' : '1/1' }" :src="videoUrl"
                  :poster="videoInfo.coverPic" disablePictureInPicture="true" loop></video>

                <svg-icon v-if="isStop" class="icon-play-large" name="icon_big_play" :size="80" />

                <div class="time" v-if="isStop">{{ videoInfo.duration }}</div>

                <div class="controls" v-if="(played && showControl && !isStop) || (isPause && !isStop)"
                  @click.stop="e => { }">
                  <bv-slider :model-value="currentFrame" :step="1" :min="0" :max="Number(route.query.duration)"
                    :show-tooltip="false" @input="handleSeekTo" />

                  <div class="control-btns">
                    <div class="flex">
                      <svg-icon class="icon-play" v-show="!played" name="player_play" :size="24"
                        @click.stop="handlePlay" />
                      <svg-icon class="icon-play" v-show="played" name="player_pause" :size="24"
                        @click.stop="handlePause" />

                      <span>{{ formatDuration(currentFrame) }}/{{ videoInfo.duration }}</span>
                    </div>

                    <svg-icon class="icon-volume" v-show="!muted" name="player_volume" :size="20"
                      @click.stop="handleSetVolume(0)" />
                    <svg-icon class="icon-volume" v-show="muted" name="player_mute" :size="20"
                      @click.stop="handleSetVolume(1)" />
                  </div>
                </div>

              </div>
              <div class="video-info-box">
                <div class="text-sm font-normal text-black pt-5 flex items-center" v-for="item in infoList">
                  <p class="title flex-1">
                    {{ item.title }}
                  </p>
                  <p class="description">
                    {{ item.des }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, nextTick } from "vue";
import { useRoute } from "vue-router";
import { formatDate, DataUnit, toFixed } from "@/utils";

type VideoInfo = {
  videoUrl: string;
  videoName: string;
  ratio: string;
  purpose: string;
  resolution: number;
  size: string;
  coverPic: string;
  exportTime: number;
  duration: number;
  wh: [number, number];
};

type Info = {
  title: string;
  des: string | number;
};

const route = useRoute();
const videoName = ref("");
const videoUrl = ref("");
const draftId = ref("");
const ratio = ref("9:16");
const played = ref(false)
const playing = ref(false)
const muted = ref(false)
const visible = ref(true)
const videoInfo = reactive({} as unknown as VideoInfo);
const isResizeHeight = ref(false);
const isResizeWidth = ref(false);
const currentFrame = ref(0);
const videoRef = ref(null);
const infoList = ref<Info[]>([]);
const showControl = ref(false)
const isStop = ref(true)
const isPause = ref()

function formatDuration(duration: number) {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${minutes | 0}:${(seconds | 0).toString().padStart(2, "0")}`;
}

const handleControlVideo = () => {
  videoRef.value.addEventListener('play', function () {
    played.value = true
    isPause.value = false
    visible.value = false
  });

  videoRef.value.addEventListener('pause', function () {
    played.value = false
    visible.value = true
    isPause.value = true
  });

  videoRef.value.addEventListener('timeupdate', function () {
    currentFrame.value = videoRef.value.currentTime;
  });
}
const handlePlay = () => {
  videoRef.value?.play()
  playing.value = true

};

const handlePause = () => {
  videoRef.value?.pause()
  played.value = false
  visible.value = true
  playing.value = false
}

const handleSetVolume = (value) => {

  if (!videoRef.value) {
    return
  }

  videoRef.value.muted = value ? false : true
  muted.value = value ? false : true
}

const handleSeekTo = (value) => {

  if (!videoRef.value) {
    return
  }

  videoRef.value.currentTime = value
}

const handleControlPlay = () => {
  if (!videoRef.value) {
    return
  }

  if (played.value == true) {
    videoRef.value?.pause()
    isStop.value = true
    videoRef.value.currentTime = 0
  } else {
    videoRef.value?.play()
    isStop.value = false
  }
}

function formatSpaceSize(size: number) {
  const dataUnit = new DataUnit(size);
  return toFixed(dataUnit.value) + dataUnit.unit;
}

const setResize = () => {
  const element = document.getElementById('video-container');
  var width = element?.offsetWidth;
  var height = element?.offsetHeight;

  if (ratio.value == '16:9') {
    isResizeWidth.value = true
    isResizeHeight.value = false
    return
  }

  if (height && width && height < width) {
    isResizeHeight.value = true
    isResizeWidth.value = false
  } else {
    isResizeWidth.value = true
    isResizeHeight.value = false
  }
}

const handleMouseMove = () => {
  showControl.value = true
}

const handleMouseLeave = () => {
  showControl.value = false
}

onMounted(async () => {
  videoUrl.value = route.query.downloadUrl as string;
  videoName.value = route.query.name as string;
  draftId.value = route.query.id as string;
  ratio.value = route.query.ratio as string;
  const duration = route.query.duration as string

  videoInfo.duration = formatDuration(duration);
  videoInfo.videoUrl = videoUrl.value;
  videoInfo.coverPic = route.query.coverPic as string;
  videoInfo.videoName = videoName.value;
  videoInfo.ratio = route.query.ratio as string;
  videoInfo.purpose = route.query.purpose as string;
  videoInfo.resolution = Number(route.query.resolution);
  videoInfo.size = route.query.size as string;
  videoInfo.exportTime = Number(route.query.exportTime);
  videoInfo.wh = ((route.query.wh as Array<string>) || [0, 0]).map(Number) as [number, number];

  infoList.value = [
    {
      title: "Name",
      des: videoInfo.videoName,
    },
    {
      title: "Purpose",
      des: videoInfo.purpose || '/',
    },
    {
      title: "Resolution",
      des: videoInfo.wh?.join(' * '),
    },
    {
      title: "Ratio",
      des: videoInfo.ratio,
    },
    {
      title: "Size",
      des: videoInfo.size && formatSpaceSize(Number(videoInfo.size)),
    },
    {
      title: "Exported Time",
      des: videoInfo.exportTime && formatDate(videoInfo.exportTime),
    },
  ];


  await nextTick()
  setResize()
  handleControlVideo()
  window.onresize = () => {
    setResize()
  }
});
</script>

<style lang="scss" scoped>
.space-exported-wrap {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 800px;
  min-height: 400px;
  overflow: auto;
}

.space-exported-container {
  padding: 30px 72px;
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-width: 1440px;

}

.video-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.video-resize-height {
  height: 100%;

  video {
    height: 100%;
  }
}

.video-resize-width {
  width: 100%;

  video {
    width: 100%;
  }
}

.video-box {
  max-width: 780px;
  max-height: 780px;
  background: #F3F5F7;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  position: relative;

  .video-wrap {
    position: relative;
  }

  video {
    height: 100%;
  }
}

.video-info-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 60px;
  align-items: center;
}

.video-info-box {
  position: absolute;
  right: -494px;
  top: 60px;
  width: 434px;
  height: 245px;

  .title {
    color: #646a73;
  }

  .description {
    width: 307px;
    color: #060606;
  }
}

.time {
  position: absolute;
  left: 24px;
  bottom: 24px;
  height: 30px;
  padding: 4px 14px;
  border-radius: 200px;
  z-index: 1;
  color: #fff;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-radius: 200px;
  background: rgba(0, 0, 0, 0.54);
}

.icon-play-large {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.controls {
  height: 66px;
  width: 100%;
  padding: 8.5px 16px 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
}

.controls .control-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.controls svg {
  cursor: pointer;
}

.controls .icon-play {
  margin-right: 8px;
  margin-left: -6px;
}

.controls .icon-volume {
  margin-right: -3px;
}

:deep(.controls .el-slider) {
  margin: 0 12px;
  flex: 1 1;
}

:deep(.controls .el-slider__runway) {
  background: rgba(255, 255, 255, 0.4);
  height: 4px;
  border-radius: 40px;
  overflow: hidden;
}

:deep(.controls .el-slider__bar) {
  background-color: #ffffff;
  height: 4px;
}

:deep(.controls .el-slider__button-wrapper) {
  display: none;
}

:deep(.el-loading-spinner .path) {
  stroke: #646a73 !important;
}
</style>
