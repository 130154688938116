<template>
  <div class="youtube-video">
    <div :id="'youtube-' + id"></div>
  </div>
</template>

<script setup>
import { watch, onMounted, onBeforeUnmount } from "vue";
import YouTubePlayer from "youtube-player";

const props = defineProps({
  id: { type: String, default: "" },
  src: { type: String, required: true },
  width: { type: Number, default: 0 },
  height: { type: Number, default: 0 },
});

const emit = defineEmits(["ended", "play", "pause"]);

onMounted(() => {
  initPlayer();
  loadPlayer();
  addStateChange();
});

onBeforeUnmount(() => {
  player && player.destroy();
});

const getVideoId = () => {
  try {
    return new URL(props.src).searchParams.get("v") || "";
  } catch (error) {
    return "";
  }
};

let player = null;
const initPlayer = () => {
  try {
    player = YouTubePlayer(`youtube-${props.id}`, {
      videoId: props.id,
      width: props.width,
      height: props.height,
      playerVars: {
        playsinline: 1,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const loadPlayer = () => {
  try {
    player.loadVideoById(getVideoId());
  } catch (error) {
    console.log(error);
  }
};

const play = () => player && player.playVideo();
const pause = () => player && player.pauseVideo();

// -1（未开始）0（已结束）1（正在播放）2（已暂停）3（正在缓冲）5（视频已插入）
const addStateChange = () => {
  player &&
    player.on("stateChange", (event) => {
      if (event.data === 0) emit("ended");
      if (event.data === 1) emit("play");
      if (event.data === 2) emit("pause");
      if (event.data === 5) {
        pause();
      }
    });
};

watch(
  () => props.src,
  () => loadPlayer(),
);

defineExpose({ play, pause });
</script>

<style lang="scss" scoped>
.youtube-video {
  width: 100%;
  height: 100%;
}
</style>
