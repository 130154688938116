<script setup>
import { frameToAxis } from "../../utils";
import { useCreatorStore } from "../../stores";

const scale = 2;
const rangeList = [6400, 3600, 1800, 900, 600, 300, 150, 90, 60, 30, 15, 10, 5, 3, 2];
const unitList = [640, 360, 180, 90, 60, 30, 15, 9, 6, 3, 3, 2, 1, 1, 1];

const props = defineProps({
  x: {
    type: Number,
    default: 10,
  },
});

const axis = ref(null);
const ro = ref(null);
const { timeline, widthToFrame, frameToWidth } = useCreatorStore();

onMounted(() => {
  ro.value = new ResizeObserver(resize);
  ro.value.observe(axis.value);

  document.fonts.load("24px Inter-variant").then(draw);
});
onBeforeUnmount(() => {
  if (ro.value) {
    ro.value.unobserve(axis.value);
    ro.value.disconnect();
  }
  ro.value = null;
});

watch([() => timeline.frameWidth, () => props.x], draw);

function resize() {
  const canvas = axis.value;

  canvas.width = canvas.clientWidth * scale;
  canvas.height = canvas.clientHeight * scale;

  draw();
}

function computeAxis(width) {
  const frame = widthToFrame(width);
  let range = 2;
  let unit = 1;

  for (let i = 0; i < rangeList.length; i++) {
    if (frame / rangeList[i] <= 15 && frame / rangeList[i] >= 6) {
      range = rangeList[i];
      unit = unitList[i];
      break;
    }
  }
  return { range, unit };
}

function draw() {
  const tick = () => {
    const canvas = axis.value;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.font = "24px Inter-variant";
    ctx.fillStyle = "#8F959E";
    ctx.textBaseline = "bottom";
    ctx.strokeStyle = "#D5D6D7";

    const startX = props.x * scale;
    const { unit, range } = computeAxis(canvas.width / scale);

    for (let x = startX; x < canvas.width; x += frameToWidth(unit) * scale) {
      let y = canvas.height / 3;
      const frame = widthToFrame((x - startX) / scale);
      if (frame % range === 0) {
        y = canvas.height;
        ctx.fillText(frameToAxis(frame, range), x + 10, y);
      }
      ctx.moveTo(x, 0);
      ctx.lineTo(x, y);
    }
    ctx.stroke();
  };
  requestAnimationFrame(tick);
}
</script>
<template>
  <canvas ref="axis" class="timeline-axis"></canvas>
</template>
<style scoped>
.timeline-axis {
  display: block;
  height: 18px;
  left: 0px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
</style>
