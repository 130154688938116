import creatorModule from '@boolv/creator';
import { useVideoCliper } from './instance';
import { useCreatorStore } from '../stores';

const { replaceNode } = useCreatorStore();
const videoCliper = useVideoCliper();
const commonConfigProp = {
  imageAndVideo: ['anchor', 'start', 'end', 'fit', 'opacity', 'rotate', 'scale', 'x', 'y', 'mask', 'keyframes'],
};

export function imageToVideo(node, conf) {
  const extractedProps = {};

  if (node.type !== 'image') {
    throw new Error('node is not an image node');
  }

  for (const prop of commonConfigProp.imageAndVideo) {
    if (prop in node.conf) {
      extractedProps[prop] = node.conf[prop];
    }
  }

  return new creatorModule.Video({...extractedProps, ...conf});
}

export function videoToImage(node, conf) {
  const extractedProps = {};

  if (node.type !== 'video') {
    throw new Error('node is not a video node');
  }

  for (const prop of commonConfigProp.imageAndVideo) {
    if (prop in node.conf) {
      extractedProps[prop] = node.conf[prop];
    }
  }

  return new creatorModule.Image({...extractedProps, ...conf});
}

export async function replaceSource(node, file) {
  let replacedNode = node;
  const nodeDuration = node.conf.end - node.conf.start;
  const replacedProps = {
    name: file.name,
    src: file.url,
    hdUrl: file.hdUrl,
    materialMeta: {
      width480: file.width480,
      width1080: file.width1080,
      url1080: file.hdUrl,
    },
  };

  if (file.type === 'image') {
    if (node.type === 'image') {
      Object.assign(node.conf, replacedProps);
    } else if (node.type === 'video') {
      replacedNode = videoToImage(node, replacedProps);
    }
  } else if (file.type === 'video') {
    replacedProps.coverPic = file.coverPic;
    replacedProps.transparent = file.aiType === 'videoBgRemove';

    if (nodeDuration < file.duration * 30) {
      const clipData = await new Promise(res => videoCliper.open(file.url, nodeDuration / 30, res));
      replacedProps.ss = clipData.start * 30 | 0;
    } else {
      replacedProps.ss = 0;
      replacedProps.end = node.conf.start + file.duration * 30;
    }
    if (node.type === 'image') {
      replacedNode = imageToVideo(node, replacedProps);
    } else if (node.type === 'video') {
      Object.assign(node.conf, replacedProps);
    }
  }

  if (replacedNode !== node) {
    replaceNode(node, replacedNode);
  }
}
