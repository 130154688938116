<script lang="ts">
const isNaN = Number.isNaN || window.isNaN;
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/;
const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/;
const normalizeDecimalNumber = (value: number, times = 100000000000) =>
  REGEXP_DECIMALS.test(String(value))
    ? Math.round(value * times) / times
    : value;
type EmitEvents = "change" | "input" | "blur" | false;

export default defineComponent({
  name: "InputNumber",

  props: {
    alignCenter: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    percent: {
      type: Boolean,
      default: false,
    },
    minute: {
      type: Boolean,
      default: false,
    },
    angle: {
      type: Boolean,
      default: false,
    },
    speed: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Boolean,
      default: false,
    },
    attrs: {
      type: Object,
      default: undefined,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    name: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    step: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: NaN,
    },
  },

  emits: ["update:modelValue", "change", "input", "blur"],

  data() {
    return {
      isFocus: false,
      dragStart: false,
      dragging: false,
      value: NaN,
      beforePosition: { value: NaN, pageX: 0, pageY: 0 },
    };
  },

  beforeMount() {
    this.resetPosition();
  },

  mounted() {
    document.addEventListener("mousemove", this.mouseMove);
    window.addEventListener("mouseup", this.mouseUp);
  },

  beforeUnmount() {
    document.removeEventListener("mousemove", this.mouseMove);
    window.removeEventListener("mouseup", this.mouseUp);
  },

  computed: {
    displayValue(): string {
      if (this.percent) {
        return Math.round(this.value * 100).toString();
      } else if (this.angle) {
        return `${Math.round(this.value * (180 / Math.PI))}`;
      } else if (this.duration) {
        return (this.value / 30).toFixed(1);
      } else {
        return this.value.toString();
      }
    },

    increasable(): boolean {
      return isNaN(this.value) || this.value < this.max;
    },

    decreasable(): boolean {
      return isNaN(this.value) || this.value > this.min;
    },

    style() {
      let length = 0;

      for (const s of this.displayValue) {
        length += s === "." ? 0.5 : 1;
      }
      return {
        width: `${length}ch`,
      };
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          // Avoid triggering change event when created
          !(isNaN(newValue) && typeof oldValue === "undefined") &&
          // Avoid infinite loop
          newValue !== this.value
        ) {
          this.setValue(newValue, false, false);
        }
      },
    },
  },

  methods: {
    isNaN,

    mouseMove(e: MouseEvent) {
      if (this.dragStart) {
        this.drag(e);
      }
    },

    mouseUp() {
      document.documentElement.style.removeProperty("cursor");
      document.body.style.removeProperty("pointer-events");
      this.resetPosition();
      this.dragStart = false;

      if (this.dragging) {
        this.dragging = false;
        this.$emit("change", this.value);
      }
    },

    elementDown(e: MouseEvent) {
      this.dragStart = true;
      this.savePosition(e);
    },

    drag(e: MouseEvent) {
      if (!this.dragging) {
        document.body.style.pointerEvents = "none";
        document.documentElement.style.cursor = "ew-resize";
      }
      const { step, beforePosition } = this;
      this.dragging = true;

      const deltaX = beforePosition.pageX - e.pageX;
      const deltaY = beforePosition.pageY - e.pageY;

      this.setValue(
        beforePosition.value - (deltaX + deltaY) * step,
        false,
        "input",
      );
    },

    savePosition(e: MouseEvent) {
      this.beforePosition.pageX = e.pageX;
      this.beforePosition.pageY = e.pageY;
      this.beforePosition.value = this.value;
    },

    resetPosition() {
      this.beforePosition.pageX = 0;
      this.beforePosition.pageY = 0;
      this.beforePosition.value = NaN;
    },

    input(event: any) {
      this.setValue(event.target.value, true, false);
    },

    change(event: any) {
      this.setValue(event.target.value, true);
    },

    decrease() {
      if (this.decreasable) {
        const { value, step } = this;
        this.setValue(normalizeDecimalNumber(value - step));
      }
    },

    increase() {
      if (this.increasable) {
        const { value, step } = this;
        this.setValue(normalizeDecimalNumber(value + step));
      }
    },

    setValue(value: number, format?: boolean, event: EmitEvents = "change") {
      const oldValue = this.value;
      this.value = value;
      let newValue = typeof value !== "number" ? parseFloat(value) : value;

      if (value.toString().trim() === "") {
        newValue = 0;
      } else if (isNaN(newValue)) {
        newValue = oldValue;
      } else {
        if (format) {
          if (this.percent) {
            newValue = newValue / 100;
          }
          if (this.angle) {
            newValue = newValue / (180 / Math.PI);
          }
          if (this.duration) {
            newValue = newValue * 30;
          }
        }
        if (this.rounded) {
          newValue = Math.round(newValue);
        }
        if (this.min <= this.max) {
          newValue = Math.min(this.max, Math.max(this.min, newValue));
        }
      }
      this.value = newValue;

      if (event) {
        this.$emit(event, newValue);
        this.$emit("update:modelValue", newValue);
      }
    },

    focus() {
      this.isFocus = true;
      (this.$refs.input as HTMLInputElement).select();
    },

    blur() {
      this.isFocus = false;
      this.$emit("blur");
    },
  },
  expose: [
    'increase',
    'decrease',
    'increasable',
    'decreasable',
  ],
});
</script>
<template>
  <div
    class="el-input"
    tabindex="0"
    @focus="focus"
    @blur="blur"
  >
    <div
      class="el-input__wrapper"
      :class="{
        'el-input--center': alignCenter,
        'el-input--right': alignRight,
        'is-focus': isFocus,
      }"
    >
      <span v-if="$slots.prefix" class="el-input__prefix" @mousedown="elementDown">
        <span class="el-input__prefix-inner">
          <slot name="prefix"></slot>
        </span>
      </span>
      <div class="input-wapper">
        <input
          ref="input"
          class="el-input__inner"
          autocomplete="off"
          v-bind="attrs"
          :style="style"
          :name="name"
          :value="displayValue"
          :readonly="readonly"
          :disabled="disabled || (!decreasable && !increasable)"
          :placeholder="placeholder"
          @focus="focus"
          @blur="blur"
          @input="input"
          @change="change"
          @keydown.stop.up="increase"
          @keydown.stop.down="decrease"
        />
        <span class="el-input__suffix">
          <span class="el-input__suffix-inner">
            <span v-if="percent">%</span>
            <span v-else-if="angle">°</span>
            <span v-else-if="speed">x</span>
            <span v-else-if="duration">s</span>
            <slot v-else-if="$slots.suffix" name="suffix"></slot>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.el-input {
  &__wrapper {
    justify-content: flex-start;
  }
  &__prefix {
    min-width: 14px;
    cursor: ew-resize;
  }
  &__inner {
    flex: 0 0;
    font-variant: tabular-nums;

    &:focus {
      outline: none;
      box-shadow: none;
    }
    &::selection {
      background-color: #dbcfff;
    }
  }

  &__suffix {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #060606;

    &-inner > :first-child {
      margin-left: 0;
    }
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: space-between;
  }
}
</style>
