<template>
  <div class="h-full w-full flex items-center justify-center">
    <GenerateLoading
      :showSteps="copyGenerateSteps"
      :current="currentStep"
      :stepsLength="generateSteps.length"
      :stepNum="2"
      :perStepPercent="[80, 90, 100]"
    />

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, watch, onUnmounted } from 'vue';
import { useLoading } from '@/components/common/generateLoading/useLoading.js';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useSingleMessage } from '@/utils';
import { sessionGetItem } from '@/utils/storage';
import { routeMap } from '@/components/common/generateLoading/data.js';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

import { useRoute, useRouter } from 'vue-router';
import { renderVideoFromIdea } from '@/api/index';
import { validImport } from '@/pages/createVideos/utils/import';
import { useTrackStore } from '@/store/modules/track';

const { collectData, track, clearEventData } = useTrackStore();
const Message = useSingleMessage();
const modalManager = useModalManager();
const router = useRouter();
const route = useRoute();

const { currentStep, clearTimer, generateSteps, copyGenerateSteps, renderVO } =
  useLoading();
const leftPage = ref(false);
const reqId = ref('');
const formState = ref(null);
const subscribeModalVisible = ref(false);
const handleGenerated = async (formState) => {
  // 生成接口
  const { data } = await renderVideoFromIdea(formState);

  if (!data) {
    Message.error('Please try again');
    router.replace({
      path: '/idea-to-video',
    });
    return;
  }

  const trackHelper = (reqId) => {
    collectData('boolvideo_text_input', {
      video_type: 'idea_to_video',
      idea_prompt: formState.prompt,
      duration: formState.duration / 60,
      ratio: formState.size.replace(':', '_'),
      language: formState.language,
      reqId,
    });
    track('boolvideo_text_input');
    clearEventData('boolvideo_text_input');
  };

  trackHelper(data);

  router.replace({
    path: route.path,
    query: {
      reqId: data,
    },
  });

  reqId.value = data;
};

watch(
  () => renderVO.value,
  (renderVO) => {
    if (renderVO) {
      clearTimer();
      if (leftPage.value) {
        return;
      }

      router.replace({
        path: '/scene-editor',
        query: {
          draftId: renderVO.draftId,
          videoType: 'idea_to_video',
        },
      });
    }
  }
);

onMounted(() => {
  reqId.value = route.query.reqId;
  if (reqId.value) {
    return;
  }
  formState.value = sessionGetItem('generateParams');
  handleGenerated(formState.value);
});

onBeforeUnmount(() => {
  leftPage.value = true;
});

onUnmounted(() => {
  clearTimer();
});
</script>
