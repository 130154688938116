<template>
  <div class="payment-container">
    <div class="ltd-container">
      <el-skeleton :loading="loading" style="width: 25%">
        <template #template>
          <div class="skeleton">
            <el-skeleton-item
              variant="circle"
              style="width: 24px; height: 24px; margin-bottom: 20px"
            />
            <el-skeleton-item
              variant="text"
              style="width: 30%; margin-bottom: 20px"
            />
            <el-skeleton-item
              variant="text"
              style="width: 50%; height: 36px; margin-bottom: 50px"
            />
            <el-skeleton-item
              variant="text"
              style="width: 240px; height: 24px; margin-bottom: 90px"
            />
            <el-skeleton-item
              variant="rect"
              style="width: 30%; height: 0; padding: 0; padding-bottom: 30%"
            />
          </div>
        </template>
        <template #default> </template>
      </el-skeleton>
      <info
        v-if="!loading"
        v-model:couponCode="couponCode"
        :packageCode="packageCode"
        :info="priceInfo"
      />
      <checkout
        v-if="!loading"
        :info="checkoutInfo"
        :packageCode="packageCode"
        v-model:couponCode="couponCode"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { initLandingPagePrice } from "@/api/premium";
import { useMessage } from "@/utils";
import Info from "./info.vue";
import checkout from "./checkout.vue";

const message = useMessage();
const route = useRoute();
const packageCode = ref(0);
const priceInfo = ref({});
const checkoutInfo = ref({});
const couponCode = ref("");
const loading = ref(true);

onMounted(async () => {
  loading.value = true;
  packageCode.value = parseInt(route.query?.packageCode as string, 10);
  await initLandingPagePrice({ packageCode: packageCode.value }).then(
    (res: any) => {
      if (res.code === 0) {
        const { boolvideoNewSubPaymentVO, intentData } = res.data;
        priceInfo.value = boolvideoNewSubPaymentVO;
        checkoutInfo.value = intentData;
      } else {
        message.error(res.msg);
      }
    },
  );
  loading.value = false;
});
</script>

<style scoped lang="scss">
.skeleton {
  display: flex;
  flex-direction: column;
}
.payment-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ltd-container {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .ltd-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
