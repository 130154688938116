<template>
  <el-dialog
    class="script-voice-dialog"
    :model-value="modelValue" 
    :show-close="false"
    :align-center="true"
    :before-close="handleClose"
  >
    <div class="dialog-container">
      <div class="header-container">
        <div class="tabs-container">
          <div 
            v-for="tab in tabs"
            class="tab-wrapper"
            :class="{ active: tab.value === currentTab}"
            @click="currentTab = tab.value"
          >
            <span>{{ tab.label }}</span>
          </div>
        </div>
        <icon-button
          name="icon_close"
          color="#646A73"
          :size="20"
          @click="$emit('update:modelValue', false)"
        />
      </div>
      <el-scrollbar>
        <KeepAlive>
          <component 
            v-model:speed="speed"
            v-model:volume="volume"
            :is="currentComponent" 
            :node="node"
            :currentAudio="currentAudio"
            :currentVoice="currentVoice"
            :currentStyle="currentStyle"
            @selectVoice="item => currentVoice = item"
            @selectStyle="item => currentStyle = item"
            @play="play"
            @pause="pause"
          />
        </KeepAlive>
      </el-scrollbar>
      <div class="footer-container">
        <svg-icon
          :name="applyAll? 'script_check' : 'script_circle'"
          :size="18"
          @click="applyAll = !applyAll"
        />
        <span>Apply all the voiceovers</span>
        <PrimaryButton
          class="button-container"
          padding="10px 36px"
          @click="confirm" 
        >
          <div class="button-text">
            Apply
          </div>
        </PrimaryButton>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import VoicePicker from "./voicepicker.vue";
import VoiceRecent from "./voicerecent.vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  scene: {
    type: Object,
  },
  node: {
    type: Object,
  },
});
const emits = defineEmits([
  "update:modelValue",
  "confirm"
]);
const tabs = [
  {
    label: "Voice Library",
    value: "voicePicker",
    component: VoicePicker,
  },
  {
    label: "Recent use",
    value: "voiceRecent",
    component: VoiceRecent,
  }
];
const currentTab = ref("voicePicker");
const currentComponent = computed(() => 
  tabs.find(item => item.value === currentTab.value).component
);
const applyAll = ref(false);
const currentStyle = ref(null);
const currentVoice = ref(null);
const currentAudio = reactive({
  url: null,
  audio: null,
  playing: false,
  loading: true,
  currentTime: 0,
});
const volume = ref(props.node.conf.volume || 1);
const speed = ref(props.node.conf.speed || 1);

function play(url) {
  const currentUrl = url;
  if (currentAudio.url !== currentUrl) {
    destroyAudio();

    const audio = new Audio(currentUrl);
    audio.ontimeupdate = () => (currentAudio.currentTime = audio.currentTime);
    audio.oncanplay = () => { 
      currentAudio.loading = false;
    };
    audio.onended = () => {
      currentAudio.playing = false;
      audio.currentTime = 0;
    };

    currentAudio.url = currentUrl;
    currentAudio.audio = audio;
  }

  currentAudio.playing = true;
  currentAudio.audio.play();
}

function pause() {
  if (!currentAudio.audio) return;
  currentAudio.playing = false;
  currentAudio.audio.pause();
}

function destroyAudio() {
  if (currentAudio.audio) {
    currentAudio.url = null;
    currentAudio.playing = false;
    currentAudio.audio.ontimeupdate = null;
    currentAudio.audio.onended = null;
    currentAudio.audio.pause();
    currentAudio.audio = null;
    currentAudio.currentTime = 0;
    currentAudio.loading = true;
  }
};

const handleClose = () => {
  emits("update:modelValue", false);
};

const confirm = () => {
  const {
    locale,
    shortName,
  } = currentVoice.value;
  const {
    styleName,
  } = currentStyle.value;
  const params = {
    speed: speed.value,
    volume: volume.value,
    language: locale,
    voiceName: shortName,
    voiceStyle: styleName,
  };
  emits("confirm", params, applyAll.value);
  handleClose();
};

onBeforeUnmount(destroyAudio);
</script>


<style>
.script-voice-dialog.el-dialog {
  width: 65%;
  height: 80%;
  min-width: 840px;
  min-height: 500px;
  border-radius: 4px;
}

.voice-scrollbar {
  height: calc(100% - 114px);
}

.script-voice-dialog .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 100%;
}
.script-voice-dialog .el-dialog__header{
  padding: 0;
  margin: 0;  
}
</style>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-container {
  position: relative;
  width: 100%;
  flex: 0 0 70px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #E5E7EB;

  & > span {
    color: #060606;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}

.tabs-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  border: 0.5px solid #E5E7EB;
  background: #F3F5F7;
  padding: 1px;
  display: flex;
}

.tab-wrapper {
  width: 112px;
  height: 28px;
  text-align: center;
  background-color: transparent;
  border: 0.5px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;

  & > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    color: #000;
    transition: color 0.2s;
  }
}

.tab-wrapper.active {
  background-color: #FFF;
  border: 0.5px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0.25px 0.25px 0px #E5E7EB, 0px 1px 0.75px 0px #E5E7EB;

  & > span {
    color: #6741FF;
  }
}

.other-container {
  width: 100%;
  flex: 0 0 110px;
  padding: 30px 30px 53px;
  display: flex;
  gap: 24px;
}

.col {
  flex: 1 1;
  display: flex;
  align-items: center;
  color: #060606;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  & > span {
    margin-left: 10px;
    width: 80px;
  }

  & > .el-slider {
    margin-left: 14px;
    flex: 1 1;
  }
}

.left{
  width: 580px;
  margin-right: 20px;
}

.language-container {
  height: calc(100% - 110px);
  padding: 0 30px;
  display: flex;
  color: #060606;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; 
}

.left-header {
  display: flex;
  margin-bottom: 40px;

  & div > p {
    margin-bottom: 12px;
  }
}
.language {
  width: 380px;
}

.gender {
  margin-left: 20px;
  width: 180px;
}

.left-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 180px);
  gap: 20px;
  margin-bottom: 150px;
}

.voice-item {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #F3F5F7;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.08s;
}

.voice-item.active {
  color: #6741FF;
  background-color: #F8F5FF;
  border-color: #6741FF;
}

.voice-item-left {
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 6px;
  }

  & > span {
    width: 96px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
  }
}

.right {
  width: 180px;
}

.right > p {
  padding-left: 20px;
  margin-bottom: 12px;
}

.style-item {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 7px 6px 7px 9px;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: transparent;
  transition: all 0.08s;
  cursor: pointer;

  &:hover {
    background-color: #F3F5F7;
  }

  &.active {
    border-color: #6741FF;
    background-color: #F8F5FF;
    color: #6741FF;
    
  }
}

.style-item + .style-item {
  margin-top: 4px;
}

.style-item-left {
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 5px;
  }

  & > span {
    width: 96px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
  }

}

.footer-container {
  position: relative;
  width: 100%;
  flex: 0 0 86px; 
  padding: 0 30px;  
  display: flex;
  align-items: center;

  & > svg {
    cursor: pointer;
  }

  & > span {
    margin-left: 8px;
    color: #060606;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.button-container {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button-text {
  width: 108px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

:deep(.el-input__wrapper),
:deep(.el-textarea__inner),
:deep(.el-select .el-input .el-input__wrapper) {
  border-color: #d5d6d7 !important;
}

:deep(.el-input .el-input__wrapper:hover),
:deep(.script-voice-dialog .bv-select-button .select-wapper:hover),
:deep(.el-textarea__inner:hover) {
  border-color: #be9fff;
}

:deep(.el-input .el-input__wrapper.is-focus),
:deep(.script-voice-dialog .bv-select-button .select-wapper.border.is-focus),
:deep(.el-textarea__inner:focus) {
  border-color: #875eff !important;
  outline-offset: 0;
  box-shadow: none !important;
}

:deep(.script-voice-dialog .bv-select-button .select-wapper) {
  border: 1px solid #D5D6D7;
}

:deep(.bv-select-button .select-value),
:deep(.bv-select-button .placeholder) {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px !important;
}

:deep(.bv-select-button .select-value) {
  color: #060606;
}

:deep(.bv-select-button) {
  height: unset;
}

:deep(.select-wapper) {
  padding: 8px 12px;
  height: unset;
}
</style>