<script setup>
import Material from "./material.vue";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";

const shapeOptions = [
  {
    name: "Rectangle",
    shape: "rect",
    fill: '#CACDD0',
    icon: "editor_rectangle",
    width: 300,
    height: 150,
    size: 46,
  },
  {
    name: "Circle",
    shape: "circle",
    fill: '#CACDD0',
    icon: "editor_circle",
    width: 250,
    height: 250,
    size: 50,
  },
  {
    name: "Triangle",
    shape: "triangle",
    fill: '#CACDD0',
    icon: "editor_triangle",
    width: 250,
    height: 216,
    size: 58,
  },
];

const { getXY, addGraphicNode } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();

async function addGraphic(config) {
  await addGraphicNode(config);
  commit();
  updateDraft();
}

function handleDrag(e, dragTarget, file) {
  const dragElement = dragTarget.cloneNode(true);

  Object.assign(dragData, {
    x: e.clientX,
    y: e.clientY,
    target: dragElement,
    meta: {
      ...getXY(),
      type: "graphic",
      ...file,
    },
  });
}

function handleMouseDown(e) {
  const target = e.target.closest("[candrag]");

  if (target === null || e.button !== 0) return;
  const dragTarget = target.querySelector("[drag-target]");

  if (dragTarget === null) return;
  const file = shapeOptions[dragTarget.dataset.index];

  const mouseUpListener = () => {
    addGraphic(file);
    target.removeEventListener("mousemove", moveListener);
  };

  const moveListener = (e) => {
    target.removeEventListener("mouseup", mouseUpListener);
    handleDrag(e, dragTarget, file);
  };

  // 当鼠标按下时，仅有移动和抬起两种操作
  target.addEventListener("mousemove", moveListener, { once: true });
  target.addEventListener("mouseup", mouseUpListener, { once: true });
}
</script>
<template>
  <Material title="Graphic">
    <el-scrollbar>
      <div class="material-content">
        <div class="shape-list" @mousedown="handleMouseDown">
          <div
            v-for="(option, i) in shapeOptions"
            candrag
            class="shape-item"
            :key="i"
          >
            <div class="image-wrapper" drag-target :data-index="i">
              <svg-icon :name="option.icon" :color="option.fill" :size="option.size" />
            </div>
            <div class="title">{{ option.name }}</div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style scoped>
.shape-list {
  display: grid;
  flex-wrap: wrap;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));
  padding: 16px 0;
}
.shape-item {
  width: 68px;
}
.image-wrapper {
  width: 68px;
  height: 68px;
  padding: 7px;
  border: 1px solid #e8e9ec;
  border-radius: 4px;
  overflow: hidden;
  transition: border-color 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.image-wrapper:hover {
  border-color: #875eff;
}

.title {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  margin-top: 8px;
}
</style>
