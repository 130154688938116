<template>
  <header
    :class="['navigation-view']"
    :style="{ minWidth: viewStore.minViewWidth }"
  >
    <div :class="['navigation-header', isActive ? 'active' : '', theme]">
      <div class="header-container">
        <img :src="logo" width="140" alt="boolv" @click="toHome" />
        <div class="expend-icon" @click="handleClick">
          <i></i>
        </div>
      </div>
    </div>
    <div class="navigation-content">
      <HeaderContent :minWidth="viewStore.minViewWidth" />
    </div>
  </header>
</template>

<script setup>
import {
  ref,
  watch,
  provide,
  computed,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
} from "vue";

import { useViewStore } from "@/store/view.ts";
import { useRoute, useRouter } from "vue-router";
import { useWindowScroll } from "@vueuse/core";
import HeaderContent from "./HeaderContentInPE.vue";
import logo from "./image/logo.svg";

const { y: scrollY } = useWindowScroll();
const isActive = ref(false);
const route = useRoute();
const router = useRouter();
const instance = getCurrentInstance();
const viewStore = useViewStore();
const theme = computed(() =>
  viewStore.header.theme === "normal"
    ? scrollY.value === 0
      ? "light"
      : "dark"
    : viewStore.header.theme,
);
provide("isNavigationActive", isActive);

watch(
  () => isActive.value,
  (value) => {
    if (value) {
      document.body.setAttribute(instance.subTree.scopeId, "");
    } else {
      document.body.removeAttribute(instance.subTree.scopeId);
    }
  },
);

function toHome() {
  route.path !== "/workspace" && router.push("/workspace");
  isActive.value = false;
}

function handleClick() {
  isActive.value = !isActive.value;
}

const handleScroll = () => {
  if (route.hash) {
    const targetElement = document.querySelector(route.hash);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "auto" });
    }
  }
};

watch(
  () => route.hash,
  () => {
    handleScroll();
  },
);

onBeforeUnmount(() => {
  document.body.removeAttribute(instance.subTree.scopeId);
});
</script>

<style scoped>
body {
  overflow-y: hidden;
}

.navigation-view {
  position: fixed;
  inset-inline: 0;
  z-index: 1002;
  opacity: 1;
  transition: opacity 0.2s;
}

.navigation-header {
  background-color: #000;
  transition: background-color 0.2s;
}

.navigation-header.light {
  background-color: transparent;
}

.navigation-header.active {
  transition: none;
}

.navigation-header::before {
  content: "";
  position: absolute;
  inset: 0;
  right: 100%;
  background-color: #000;
  transition: right 0.2s;
  pointer-events: none;
}

.navigation-header.active::before {
  right: 0;
}

.header-container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 64px;
  background: #000;
  border-bottom: 0.5px solid rgba(222, 224, 227, 0.16);
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-container > img {
  display: inline-block;
  cursor: pointer;
}

.navigation-content {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 100%;
  background-color: #000;
  z-index: 9998;
  transition: right 0.2s;
}

.navigation-header.active + .navigation-content {
  right: 0;
  opacity: 1;
}

.expend-icon {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.expend-icon > i,
.expend-icon::before,
.expend-icon::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2.5px;
  margin-block: auto;
  border-radius: 2px;
  background-color: #fff;
}

.expend-icon > i {
  top: 0;
  bottom: 0;
}

.expend-icon::before {
  top: 3px;
  transform-origin: 12.5px 12.5px;
  transition: transform 0.2s;
}

.expend-icon::after {
  bottom: 3px;
  transform-origin: 4px -4px;
  transition: transform 0.2s;
}

.navigation-header.active .expend-icon > i {
  height: 0;
}

.navigation-header.active .expend-icon::before {
  transform: rotate(45deg) translateY(9px);
}

.navigation-header.active .expend-icon::after {
  transform: rotate(-45deg);
}
</style>
