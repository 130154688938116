<template>
  <form-item>

    <template #label>
      <p class="flex items-center">Brand kit
        <el-tooltip placement="bottom" effect="dark" popper-class="effect-tooltip">
          <template #content>
            Your logo, slogan and brand story will be <br/> considered or applied when generating videos.
          </template>
          <svg-icon name="icon_describe" :size="18" style="margin-left: 6px" class="cursor-pointer"></svg-icon>
        </el-tooltip>
      </p>
    </template>

    <el-select v-model="bkId" :popper-append-to-body="false" popper-class="feature-edit-select" placeholder="Do not use"
      size="large" :suffix-icon="SelectSuffix" :clear-icon="ClearIcon" clearable :popper-options="{
            modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
          }"  @change="handleChangeBrandKit">

      <template #empty>
        <div class="empty-data">
          No Brand kit, to <span class="link" @click="handleToBrandKit">create</span>
        </div>
      </template>
      <el-option v-for="item in brandKitList" :key="item.value" :label="item.label" :value="item.value">
        <div class="flex justify-between" :class="{ 'active': bkId == item.value }" >
          <p class="label">{{ item.label }}</p>
          <p v-if="bkId == item.value"><svg-icon name="icon_option_selected" :size="18"></svg-icon></p>
        </div>
      </el-option>
    </el-select>
  </form-item>
</template>

<script setup>
import { ref , watch} from 'vue'
import FormItem from './form-item.vue'
import { useRouter } from "vue-router";
import SelectSuffix from "@/components/common/selectSuffix.vue";
import ClearIcon from "@/components/common/clearIcon.vue";

const props = defineProps({
  value: String,
  brandKitList: Array
})

const router = useRouter();
const emit = defineEmits(['update:value'])

const bkId = ref('')

const handleChangeBrandKit = (item) => {
  emit('update:value', item)
}

const handleToBrandKit = () => {
  router.push('/brand')
}

watch(() => props.value, () => {
  bkId.value = props.value
}, {
  immediate: true
})

</script>

<style lang="scss">
@import './common.scss';

.empty-data {
  padding: 45px;
  text-align: center;
  color: #8F959E;
  text-align: center;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  .link {
    color: #6741FF;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
