import input_1 from './input_1.mp4';
import output_1 from './output_1.mp4';

export default [
  {
    input: input_1,
    output: output_1,
    thumbnail: input_1,
  },
];
