// tag 1-product 2-content-creator 3-market 4-art 5-design 6-Phontographer
export const caseList = [
  {
    id: 1,
    tag: 1,
    ratio: 9/16,
    buttonText: 'idea to video',
    path: '/idea-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/product/1_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/product/1.mp4'
  }, {
    id: 2,
    tag: 1,
    ratio: 9/16,
    buttonText: 'product to video',
    path: '/product-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/product/2_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/product/2.mp4'
  }, {
    id: 3,
    tag: 1,
    ratio: 9/16,
    buttonText: 'script to video',
    path: '/script-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/product/3_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/product/3.mp4'
  }, {
    id: 4,
    tag: 1,
    ratio: 9/16,
    buttonText: 'product to video',
    path: '/product-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/product/4_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/product/4.mp4'
  }, {
    id: 5,
    tag: 2,
    ratio: 9/16,
    buttonText: 'script to video',
    path: '/script-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Content_Creator/1_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Content_Creator/1.mp4'
  }, {
    id: 6,
    tag: 2,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Content_Creator/2_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Content_Creator/2.mp4'
  }, {
    id: 7,
    tag: 2,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Content_Creator/3_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Content_Creator/3.mp4'
  }, {
    id: 8,
    tag: 2,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Content_Creator/4_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Content_Creator/4.mp4'
  },
  {
    id: 11,
    tag: 4,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Art/1_thumbnail1.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Art/1.mp4'
  }, {
    id: 12,
    tag: 4,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Art/2_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Art/2.mp4'
  }, {
    id: 13,
    tag: 4,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Art/3_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Art/3.mp4'
  }, {
    id: 14,
    tag: 4,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Art/4_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Art/4.mp4'
  }, {
    id: 15,
    tag: 5,
    ratio: 9/16,
    buttonText: 'product to video',
    path: '/product-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Designer/1_thumbnail1.jpg',
    videoUrl:'https://cdn.boolv.video/pgc/home_video/Designer/1.mp4'
  }, {
    id: 16,
    tag: 5,
    ratio: 9/16,
    buttonText: 'product to video',
    path: '/product-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Designer/2_thumbnail1.jpg',
    videoUrl:'https://cdn.boolv.video/pgc/home_video/Designer/2.mp4'
  }, {
    id: 17,
    tag: 5,
    ratio: 9/16,
    buttonText: 'product to video',
    path: '/product-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Designer/3_thumbnail1.jpg',
    videoUrl:'https://cdn.boolv.video/pgc/home_video/Designer/3.mp4'
  }, {
    id: 18,
    tag: 5,
    ratio: 9/16,
    buttonText: 'product to video',
    path: '/product-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Designer/4_thumbnail.jpg',
    videoUrl:'https://cdn.boolv.video/pgc/home_video/Designer/4.mp4'
  },{
    id: 19,
    tag: 6,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Phontographer/1_thumbnail1.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Phontographer/1.mp4'
  }, {

    id: 20,
    tag: 6,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Phontographer/2_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Phontographer/2.mp4'
  }, {

    id: 21,
    tag: 6,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Phontographer/3_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Phontographer/3.mp4'
  }, {
    id: 22,
    tag: 6,
    ratio: 9/16,
    buttonText: 'visuals to video',
    path: '/visuals-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Phontographer/4_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Phontographer/4.mp4'
  }, {
    id: 9,
    tag: 3,
    ratio: 16/9,
    buttonText: 'idea to video',
    path: '/idea-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Marketer/1_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Marketer/1.mp4'
  }, {
    id: 10,
    tag: 3,
    ratio: 16/9,
    buttonText: 'product to video',
    path: '/product-to-video',
    coverPic: 'https://cdn.boolv.video/pgc/home_video/Marketer/2_thumbnail.jpg',
    videoUrl: 'https://cdn.boolv.video/pgc/home_video/Marketer/2.mp4'
  },
]














