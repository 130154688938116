<template>
  <div class="content-view" :style="{ minWidth: props.minWidth }">
    <div class="content-container">
      <ul class="panel-list">
        <li
          v-for="nav in navList"
          :key="nav.id"
          class="cursor-pointer"
          @click="handleClickLink({ target: '_self', hash: nav.hash })"
        >
          <span class="menu-title">{{ nav.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const isNavigationActive = inject("isNavigationActive");

const navList = ref([
  {
    id: 1,
    path: "",
    title: "Overview",
    hash: "#overview",
    idName: "overview",
  },
  {
    id: 2,
    path: "",
    title: "Plans & features",
    hash: "#plans",
    idName: "plans",
  },
  {
    id: 3,
    path: "",
    title: "Roadmap",
    hash: "#roadmap",
    idName: "roadmap",
  },
  {
    id: 4,
    path: "",
    title: "Testimonial",
    hash: "#testimonial",
    idName: "testimonial",
  },
  {
    id: 6,
    path: "",
    title: "Reviews",
    hash: "#reviews",
    idName: "reviews",
  },
  {
    id: 5,
    path: "",
    title: "Questions",
    hash: "#questions",
    idName: "questions",
  },
]);

const props = defineProps({
  minWidth: {
    type: String,
    default: "",
  },
});

const handleClickLink = ({ target, hash }) => {
  if (target === "_self" && hash) {
    router.push({ path: "/goldendeal", hash: hash });
    isNavigationActive.value = false;
  }
};
</script>

<style scoped>
.content-view {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  right: 0;
  color: #000;
  background-color: #000;
  text-align: left;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-container::-webkit-scrollbar {
  position: absolute;
  width: 0;
}

.content-container::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 2px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-name {
  margin-left: 8px;
  font-size: 16px;
  color: #fff;
}

.user-plan {
  padding: 2px 6px;
  margin-left: 8px;
  font-size: 12px;
  color: #ffe68e;
  background-color: #060606;
  border-radius: 2px;
}

.action-list {
  font-family: Inter-variant, Arial;
  font-size: 16px;
}

.action-list > li {
  padding-block: 20px;
  border-bottom: 1px solid #ededed;
}

.panel-list {
  padding: 0 40px;
  width: 100%;
  min-width: 100vw;
}

.panel-list > li {
  padding-block: 30px;
  border-bottom: 1px solid #333;
}

.menu-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}

.menu-item:deep > .x-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.menu-item:deep > .x-summary::after,
.user-info::after {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background: url("@/assets/icons/workspace/icon_arrow.svg") no-repeat center/50%;
  transition: transform 0.2s;
  cursor: pointer;
}

.user-info::after {
  margin-inline: auto 0;
  transform: rotate(-90deg);
}

.menu-item.open:deep > .x-summary::after {
  transform: rotate(180deg);
}

.menu-item:deep > .x-container {
  height: 0;
  transition: height 0.2s;
}

.item-list > li {
  padding-top: 24px;
  font-size: 16px;
  color: #d5d6d7;
}

.item-list > li > span:hover {
  color: #9774ff;
  cursor: pointer;
}

.bottom-part {
  position: relative;
  padding-block: 16px 45px;
}

.button-group {
  text-align: center;
  font-size: 16px;
  color: #fff;
}

.sign-button {
  width: 230px;
  height: 56px;
  margin-top: 24px;
  line-height: 56px;
  border-radius: 28px;
  border: 1px solid #535558;
}
</style>
