import type {
  SelectItemType,
  ModelValue as SelectModelValue,
} from "@/components/common/group-select/type";

export const useFilterStore = (() => {
  const queryTypes = reactive<SelectModelValue>({});
  const allQueryTypes = reactive<SelectItemType[]>([]);
  const queryTypeCount = computed(() =>
    Object.values(queryTypes).reduce((count, item) => count + item.size, 0),
  );

  const reset = () => {
    allQueryTypes.splice(0);

    for (const key of Object.keys(queryTypes)) {
      delete queryTypes[key];
    }
  };

  const setDefaultModel = (list: SelectItemType[]) => {
    for (const item of list) {
      if ("items" in item) {
        queryTypes[item.value] = new Set<string>();
        setDefaultModel(item.items!);
      }
    }
  };

  function setAllQueryTypes(list: SelectItemType[]) {
    for (const key of Object.keys(queryTypes)) {
      delete queryTypes[key];
    }

    allQueryTypes.splice(0);
    allQueryTypes.push(...list);
    queryTypes._default = new Set<string>();
    setDefaultModel(list);
  }

  return () => ({
    queryTypes,
    allQueryTypes,
    queryTypeCount,
    reset,
    setAllQueryTypes,
  });
})();
