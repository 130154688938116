<template>
  <form-item label="Ratio">
    <el-select v-model="size" :popper-append-to-body="false" popper-class="feature-edit-select" placeholder="Select"
      size="large" :suffix-icon="SelectSuffix" :popper-options="{
      modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
    }" @change="handleChangeRatio">
      <el-option v-for="item in sizeList" :key="item.value" :label="item.label" :value="item.value">
        <div class="flex justify-between" :class="{ 'active': size == item.value }">
          <p class="label">{{ item.label }}</p>
          <p v-if="size == item.value"><svg-icon name="icon_option_selected" :size="18"></svg-icon></p>
        </div>
      </el-option>
    </el-select>
  </form-item>
</template>

<script setup>
import { ref, watch } from 'vue'
import FormItem from './form-item.vue'
import SelectSuffix from "@/components/common/selectSuffix.vue";

const props = defineProps({
  value: String,
  type: String,
})

const emit = defineEmits(['update:value', 'update:duration'])

const size = ref('9:16')
const sizeList = ref([
  {
    value: '1:1',
    label: `1:1 ( Square )`,
  },
  {
    value: '9:16',
    label: '9:16 ( Portrait )'
  }, {
    value: '16:9',
    label: '16:9 ( Landscape )',
  }
])

watch(() => props.value, (value) => {
  size.value = value
})

const handleChangeRatio = (item) => {
  emit('update:value', item)
}

</script>

<style lang="scss">
@import './common.scss';
</style>
