<template>
    <component :is="showComponent"></component>
</template>

<script setup>
import { computed, onMounted} from 'vue'
import BoolVideoPage from './boolvideoIndex.vue'
import SimilarVideoIndex from './similarVideoIndex.vue'

import { useSimilarVideo } from '@/pages/similarVideo/store/index.js';

const similarVideoStore = useSimilarVideo();

const globalDomain = inject('globalDomain');
const showComponent = computed(() => {
  return  globalDomain == 1 ? BoolVideoPage : SimilarVideoIndex
})

onMounted(() => {
  similarVideoStore.removeSimilarVideoCache()
})
</script>

<style lang="scss">

</style>