<template>
  <!-- width="840px" -->
  <el-dialog
    id="audio-modal"
    modal-class="audio-modal"
    v-model="dialogVisible"
    destroy-on-close
    :close-on-click-modal="false"
    :show-close="false"
    width="60%"
    center
  >
    <div class="pt-6 relative">
      <div class="flex items-center title-box">
        <div class="cursor-pointer mb-6 font-medium text-base title">
          Music Lib
        </div>
      </div>
      <div
        class="icon-close absolute top-6 h-6 w-6 hover:bg-uploadBg flex items-center justify-center rounded-sm cursor-pointer"
        @click="handleClose"
      >
        <svg-icon
          name="icon_close"
          :style="{ width: '20px', height: '20px' }"
          color="#646A73"
        ></svg-icon>
      </div>

      <!-- materials -->
      <div style="height: 60vh" class="flex flex-col">
        <div class="audio-filter-container">
          <div class="filter-box">
            <bv-tag
              :text="item"
              v-for="item in genres"
              :key="item"
              :active="selectedType == item"
              class="cursor-pointer tag-item"
              @click="handleTypeTagClick(item)"
              bgColor="#646A73"
            />
          </div>
        </div>

        <div
          class="audio-wrapper"
          id="audio-wrapper"
          v-if="audioList.length"
          v-loading="loading"
        >
          <div
            class="h-full w-full audio-container"
            v-infinite-scroll="handleRequest"
            :infinite-scroll-immediate="false"
            :infinite-scroll-distance="1"
          >
            <audio-item
              v-for="item in audioList"
              :item="item"
              @onPlaying="handlePlaying"
              @onReplace="handleReplace"
            />
          </div>
        </div>

        <!-- empty -->
        <div
          class="empty flex mt-10 flex-col items-center"
          v-if="!audioList.length && !loading"
        >
          <svg-icon
            name="icon_empty"
            style="color: #bbbfc4; height: 200px"
          ></svg-icon>
          <div class="flex flex-col items-center -mt-4">
            <p class="leading-8 text-defaultColor text-sm font-normal -mt-8">
              No asset
            </p>
          </div>
        </div>

        <div
          class="empty flex mt-10 flex-col items-center"
          v-if="!audioList.length && loading"
          v-loading="loading"
        >
          <svg-icon
            name="icon_empty"
            style="color: #bbbfc4; height: 200px"
          ></svg-icon>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { BvTag } from '@/components/common/index';
import AudioItem from './audioItem.vue';
import { getAudioList } from '@/api/index';

const emit = defineEmits(['close', 'update:visible', 'onReplace']);
const props = defineProps({
  visible: Boolean,
  draftId: String,
  isLogo: Boolean,
  mode: String,
  duration: Number,
});

const genres = [
  'All',
  'Pop',
  'Blues',
  'Classical',
  'Rock',
  'Hip Hop',
  'Jazz',
  'Folk',
  'World',
  'Funk',
  'Latin',
  'Lounge',
  'Ambient',
  'Lofi & Chill Beats',
  'Country',
  'Soul & RnB',
  'Acoustic',
  'Reggae',
  'Retro',
  'Corporate',
  'Electronic',
  'Cinematic',
  'Fantasy',
];

const router = useRouter();
const loading = ref(false);
const dialogVisible = ref(false);
const audioList = ref([]);
const selectedType = ref('All');

const pagination = reactive({
  page: 1,
  size: 40,
  total: 100,
});

// 关闭
const handleClose = () => {
  audioList.value = [];
  dialogVisible.value = false;
  emit('update:visible', false);
  emit('close');
};

const resetParams = () => {
  pagination.page = 1;
  pagination.size = 40;
};

watch(
  () => selectedType.value,
  (value) => {
    resetParams();
    getLibAudio();
  }
);

// 获取素材列表
const getLibAudio = async () => {
  const params = {
    genre: selectedType.value == 'All' ? '' : selectedType.value,
    page: pagination.page,
    size: pagination.size,
  };

  loading.value = true;
  const { data } = await getAudioList(params);
  if (pagination.page == 1) {
    audioList.value = [];
  }
  pagination.page = data.current + 1;
  audioList.value = [...audioList.value, ...data.records];
  loading.value = false;
};

const handleRequest = () => {
  getLibAudio();
};

const handlePlaying = (id, isPlaying) => {
  if (isPlaying) {
    // 其它关闭
    audioList.value.forEach((audio) => {
      if (audio.id != id) {
        audio.isPlaying = false;
      } else {
        audio.isPlaying = true;
      }
    });
    return;
  }

  audioList.value.forEach((audio) => {
    if (audio.id == id) {
      audio.isPlaying = false;
    }
  });
};

const handleTypeTagClick = (item) => {
  selectedType.value = item;
};

const handleReplace = (item) => {
  emit('onReplace', item);
  handleClose();
};

watch(
  () => props.visible,
  (value) => {
    getLibAudio();
    dialogVisible.value = value;
  }
);
</script>

<style scoped lang="scss">
#audio-modal {
  .title-box {
    padding-left: 30px;
    border-bottom: 1px solid #e5e7eb;
    margin-bottom: 24px;
  }

  .title {
    color: #000;
  }

  .icon-close {
    right: 30px;
  }
}

.audio-filter-container {
  width: 100%;

  .filter-box {
    width: 100%;
    float: left;
    padding: 0px 62px 36px 62px;
  }

  .filter-type {
    color: #646a73;
    font-family: Inter-variant;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-right: 10px;
  }

  .tag-item {
    margin-right: 10px;
    margin-bottom: 4px;
    font-size: 12px;
  }
}

.logo-image {
  width: 200px;
  height: 200px;
}

.audio-wrapper {
  overflow: auto;
}

.audio-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  // grid-template-columns: 1fr 1fr 1fr;
  /* 创建具有三列的网格布局 */
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  overflow: auto;
  padding: 0px 62px;
}

.empty {
  min-height: 200px;
  background: #fff;
  .text-content {
    border-bottom: 1.5px solid #8f959e;

    &:hover {
      border-bottom: 1.5px solid #875eff;
    }
  }
}
</style>

<style lang="scss">
.audio-modal {
  .el-dialog {
    min-width:  840px !important;
  }
  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0px;
  }

  .el-dialog__footer {
    padding: 20px 0 20px 0;
    border-top: 1px solid #1f232926;
  }
}

:deep(.el-overlay.audio-modal) {
  background-color: rgba(0, 0, 0, 0.54);
}
</style>
