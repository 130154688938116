import { _post } from "@/utils/https";

const { VITE_API_BASE } = import.meta.env;

interface Data {
  page: number;
  size: number;
  keyword: string;
  color: string;
}

export async function getImageList(data: Data) {
  return await _post(`${VITE_API_BASE}/library/images_pex`, data);
}

export async function getVideoList(data: Data) {
  return await _post(`${VITE_API_BASE}/library/videos_pex`, data);
}
