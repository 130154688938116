<template>
  <div
    class="h-full w-full flex justify-center items-center relative"
    :class="{ 'template-to-Video-container': step == 1 }"
  >
    <Upload
      @nextStep="handleNextStep"
      v-if="step == 1"
      @onLoading="handleLoading"
    />
    <FeatureEdit
      v-if="step == 2"
      :imageList="imageList"
      :productInfo="productInfo"
      @onLoading="handleLoading"
      @onGenerated="handleGenerated"
      uploadType="image"
    />
    <div class="loading-mask" v-if="loading"></div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, watch } from 'vue';
import Upload from './ImportFile.vue';
import FeatureEdit from './components/featureEdit/index.vue';
import { templateVideoRender } from '@/api/index';
import { useRouter, useRoute } from 'vue-router';
import { sessionSetItem, sessionGetItem} from '@/utils/storage';
import { useSingleMessage } from '@/utils';
import { useTrackStore } from '@/store/modules/track';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { validImport } from '@/pages/createVideos/utils/import';

const modalManager = useModalManager();
const { collectData, track } = useTrackStore();
const Message = useSingleMessage();
const router = useRouter();
const route = useRoute();

const step = ref(1);
const imageList = ref([]);
const productInfo = ref({});
const loading = ref(false);
const generateLoading = ref(false);
const leftPage = ref(false);

const handleNextStep = ({ _imageList = [], _productInfo = {} }) => {
  const trackHelper = () => {
    track('boolvideo_template_upload');
  };
  trackHelper();
  step.value = 2;

  imageList.value = _imageList;
  productInfo.value = _productInfo;

  router.replace({
    path: route.path,
    query: {
      ...route.query,
      type: 'feature',
    },
  });
};

const handleLoading = (value) => {
  loading.value = value;
};

const handleGenerated = async (formState, cacheForm) => {
  const trackHelper = () => {
    collectData('boolvideo_template_input', {
      brand_kit_usage: formState.bkId !== '',
    });
    track('boolvideo_template_input');
  };
  trackHelper();
  if (route.query.templateId) {
    formState.templateId = route.query.templateId;
    cacheForm.templateId = route.query.templateId;
  }

  sessionSetItem('generateParams', formState);
  sessionSetItem('cacheForm', cacheForm);
  router.push({
    path: '/template-to-video/generating',
  });
};

watch(
  () => route.query.type,
  (type) => {
    if (type == 'feature') {
      step.value = 2;
    }
    const cacheForm = sessionGetItem('cacheForm');
    if (!cacheForm) {
      return;
    }
    imageList.value = cacheForm.cacheImageList;

    productInfo.value = {
      productDesc: cacheForm.productDesc,
      bkId: cacheForm.bkId,
      url: cacheForm.url,
      templateId: cacheForm.templateId || route.query.templateId,
    };

    // if (route.query.templateId) {
    //   productInfo.value.templateId = route.query.templateId;
    // }
  },
  {
    immediate: true,
  }
);

onBeforeUnmount(() => {
  leftPage.value = true;
});
</script>

<style lang="scss" scoped>
.template-to-Video-container {
  min-height: 600px;
}

.loading-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.35);
}

.loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>
