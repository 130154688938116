<template>
  <div class="playertools-container">
    <div class="player-tools-center">
      <svg-icon
        clickable
        v-show="playing"
        name="editor_pause"
        :size="24"
        @click="pause"
      />
      <svg-icon
        clickable
        v-show="!playing"
        name="editor_play"
        color="#1C1B1E"
        :disabled="!canPlay"
        :size="24"
        @click="playVideo"
      />
      <span class="time" :class="{ disabled: !canPlay }">
        <span class="current-time">{{ frameToHms(currentFrame) }}</span>
        <span class="duration">{{ ` / ${frameToHms(totalFrame)}` }}</span>
      </span>
      <div class="right">
        <icon-button 
          name="script_reset"
          tip="Reset"
          color="#646A73"
          :size="18"
          :tipDelay="0"
          @click="replayVideo"
        />
      </div>
    </div>
    <span class="resolution">
      Preview : 480P
    </span>
  </div>
</template>

<script setup>
import { frameToHms } from "../../utils";
import { useScriptStore } from "../../stores/script";

const { 
  creator,
  currentFrame, 
  totalFrame,
  canPlay,
  playing,
  replay,
  play,
  pause, 
} = useScriptStore();

function playVideo() {
  if (creator.value.ended) {
    replay();
  } else {
    play();
  }
}

async function replayVideo() {
  await replay();
  pause();
}
</script>

<style lang="scss" scoped>
.playertools-container {
  margin-top: 26px;
  position: relative;
  width: 100%;
  height: 24px;
  text-align: end;
}

.player-tools-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.player-tools-center svg {
  margin-right: 14px;
}

.player-tools-center .time {
  font-size: 14px;
  height: 22px;
}

.player-tools-center .time.disabled .current-time,
.player-tools-center .time.disabled .duration {
  color: #bbbfc4;
}

.player-tools-center .time .current-time {
  color: #000000;
  line-height: 22px;
  font-variant: tabular-nums;
}

.player-tools-center .time .duration {
  color: #646a73;
  line-height: 22px;
}

.right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  display: flex;
  align-items: center;
  padding-left: 14px;
}

.resolution {
  color: #646A73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
</style>