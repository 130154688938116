<template>
  <div>
    <p class="custom-colors">
      <span>Custom colors</span>
      <icon-button
        v-if="dropperCompatible"
        name="icon_colorpen"
        :size="16"
        @click="pick"
      />
    </p>
    <div class="colors isPick">
      <template v-for="(color, index) in colors" :key="color + index">
        <div class="relative">
          <color-item
            class="color-item"
            :size="18"
            :value="color"
            :border="color == '#FFFFFF' ? true : false"
            :border-radius="1"
            :selected="selectedIndex === index"
            @click.stop.prevent="onSelectColor(color, index)"
            :style="{ marginRight: index == colors.length - 1 ? '0px' : '6px' }"
          />
          <svg-icon
            name="icon_right"
            v-if="selectedIndex === index && color"
            :style="{
              width: '12px',
              height: '12px',
              left: '3px',
              top: '3px',
              color: color == '#FFFFFF' ? '#DEE0E3' : '#fff',
            }"
            class="absolute"
          ></svg-icon>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ColorItem from "../color-item";
import { useMessage } from "@/utils";
export default defineComponent({
  name: "Colors",
  components: {
    ColorItem,
  },
  props: {
    colors: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number,
      default: -1,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const message = useMessage();
    const dropperCompatible = ref(window.EyeDropper);

    const onSelectColor = (color: string, index: number) => {
      emit("change", color, index);
    };

    const pick = () => {
      if ( !dropperCompatible.value ) {
        message.error("Browser do not support this feature");
        return;
      }
      
      const eyeDropper = new EyeDropper();

      eyeDropper
        .open()
        .then((res) => {
          const { sRGBHex } = res;
          emit("change", sRGBHex, -1);
        })
        .catch(e => {
          console.log(e, "error");
        });
    };

    return {
      dropperCompatible,
      onSelectColor,
      pick,
    };
  },
});
</script>

<style scoped lang="scss">
.custom-colors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646A73;
  font-size: 12px;
}

.colors {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
}
</style>
