<template>
  <Empty v-if="voiceList.length === 0"/>
  <div class="recent-container" v-else>
    <bv-tip
      v-for="item in voiceList"
      :content="`${extractVoiceName(item.voiceName)} - ${capitalizeFirstLetter(item.styleName)}`"
      placement="top"
    >
      <div 
        class="voice-item"
        :class="{active: isActive(item)}"
        @click.capture="clickVoice(item)"
      > 
        <div class="voice-item-left">
          <svg-icon
            :name="item.gender === 'Male' ? 'script_male': 'script_female'"
            :color="isActive(item) ? '#6741FF' : '#646A73'"
            :size="18"
          />
          <span>{{ `${extractVoiceName(item.voiceName)} - ${capitalizeFirstLetter(item.styleName)}` }}</span>
        </div>
        <svg-icon
          v-if="currentAudio.playing && currentAudio.loading && item.url === currentAudio.url"
          name="icon_loading"
          class="animate-spin duration-300 p-0.5 button-loading"
          :size="24"
          :color="isActive(item) ? '#6741FF' : '#646A73'"
        />
        <svg-icon
          v-else-if="currentAudio.playing && item.url === currentAudio.url"
          name="script_pause"
          :size="24"
          :color="isActive(item) ? '#6741FF' : '#646A73'"
          @click="$emit('pause')"
        />
        <svg-icon
          v-else
          name="script_play"
          :color="isActive(item) ? '#6741FF' : '#646A73'"
          :size="24"
          @click="$emit('play', item.url)"
        />
      </div>
    </bv-tip>
  </div>
</template>

<script setup>
import Empty from "./empty.vue";
import { getRecentVoiceList } from "@/api/language";
import { capitalizeFirstLetter, extractVoiceName } from "../../utils";

const props = defineProps({
  node: Object,
  currentAudio: Object,
  currentVoice: Object,
  currentStyle: Object,
});
const emits = defineEmits([
  "play",
  "pause",
  "selectVoice", 
  "selectStyle"
]);

const voiceList = ref([]);

const isActive = (item) => {
  const { currentVoice, currentStyle } = props;
  return currentVoice.shortName === item.voiceName &&
   currentStyle.styleName === item.styleName
};

const clickVoice = (item) => {
  if (item == props.currentVoice) return ;
  const style = {
    audioFileEndpointWithSas: item.url,
    styleName: item.styleName,
  };
  const voice = {
    locale: item.languageName,
    gender: item.gender,
    localName: extractVoiceName(item.voiceName),
    shortName: item.voiceName,
    styleList: [
      style,
    ],
  };
  emits("selectVoice", voice);
  emits("selectStyle", style);
};

const getVoiceList = async () => {
  const { data } = await getRecentVoiceList();
  voiceList.value = data;
};

onDeactivated(() => {
  emits("pause");
});

onBeforeMount(getVoiceList);
</script>

<style lang="scss" scoped>
.recent-container {
  padding: 20px 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
  margin-bottom: 150px;
  color: #060606;
}

.voice-item {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #F3F5F7;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.08s;
}

.voice-item.active {
  color: #6741FF;
  background-color: #F8F5FF;
  border-color: #6741FF;
}

.voice-item-left {
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 6px;
  }

  & > span {
    width: 96px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    word-break:keep-all;
  }
}
</style>