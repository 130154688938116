<template>
  <button class="star-button" @click="handleToPath">
    <svg-icon name="icon_generate_star" />
    <slot></slot>
    <router-link v-if="link !== '' && !link.includes('https')" :to="link" />
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  link: {
    type: String,
    default: '',
  }
});

const handleToPath = () => {
  if(props.link.includes('https')) {
    window.open(props.link)
  }
}
</script>

<style scoped lang="scss">
.star-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 23px;
  width: fit-content;
  min-width: 278px;
  border-radius: 999px;
  color: #FFFFFF;
  background-color: #181818;
  font-size: 16px;

  & > svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  & > a {
    position: absolute;
    inset: 0;
    z-index: 1;
  }
}
</style>