import step1 from './step_1.webp';
import step2 from './step_2.webp';
import step3 from './step_3.webp';
import step4 from './step_4.webp';

export default {
  step1,
  step2,
  step3,
  step4,
};
