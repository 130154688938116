<script setup>
import EasingCanvas from "./easingcanvas.vue";

const easingOptions = [
  "linear",
  "easeInQuad",
  "easeOutQuad",
  "easeInOutQuad",
  "easeInCubic",
  "easeOutCubic",
  "easeInOutCubic",
  "easeInQuart",
  "easeOutQuart",
  "easeInOutQuart",
  "easeInQuint",
  "easeOutQuint",
  "easeInOutQuint",
  "easeInSine",
  "easeOutSine",
  "easeInOutSine",
  "easeInExpo",
  "easeOutExpo",
  "easeInOutExpo",
  "easeInCirc",
  "easeOutCirc",
  "easeInOutCirc",
  "easeInElastic",
  "easeOutElastic",
  "easeInOutElastic",
  "easeInBack",
  "easeOutBack",
  "easeInOutBack",
  "easeInBounce",
  "easeOutBounce",
  "easeInOutBounce",
];

const emit = defineEmits(["change", "apply", "update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  easing: {
    type: String,
    default: 'linear',
  },
});
const visible = ref(props.modelValue);
const currentEasing = ref(props.easing);

watch(
  () => props.modelValue,
  (newValue) => {
    visible.value = newValue;
  },
);
watch(
  () => props.easing,
  (newValue) => {
    currentEasing.value = newValue;
  },
);
watch(visible, (newValue) => {
  emit("update:modelValue", newValue);
});

function handleApply() {
  emit('apply', currentEasing.value);
  visible.value = false;
}
</script>
<template>
  <bv-modal title="Easing" v-model="visible" modalClass="easing-modal">
    <template #content>
      <el-scrollbar>
        <div class="easing-list">
          <div v-for="(name, i) in easingOptions" class="easing-item" :key="i">
            <easing-canvas :name="name" :active="currentEasing === name" @click="currentEasing = name" />
            <div class="title">{{ name }}</div>
          </div>
        </div>
      </el-scrollbar>
    </template>
    <template #footer>
      <primary-button @click="handleApply">Apply</primary-button>
    </template>
  </bv-modal>
</template>
<style>
.modal-container.easing-modal {
  width: 900px;
}
.easing-modal .modal-content {
  padding: 0 5px;
  height: 50vh;
}
.easing-modal .easing-list {
  display: grid;
  flex-wrap: wrap;
  gap: 11px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  padding: 24px 16px;
}

.easing-item {
  width: 120px;
  padding: 0 10px;
}
.easing-item .title {
  margin-top: 4px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}
</style>
