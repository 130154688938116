<template>
  <div class="user-form-container">
    <el-form v-if="!isPass" class="user-form" ref="formRef" :model="formData">
      <el-form-item class="user-form-header">
        <h3 class="user-form-title">Welcome to Boolvideo</h3>
        <p class="user-form-tip">Please enter your RocketHub code below to activate your account.</p>
      </el-form-item>
      <el-form-item
        name="activeEmail"
        :class="['input-item', isCorrectEmailFormat ? '' : 'no-pass']"
      >
        <el-input
          v-model="formData.activeEmail"
          placeholder="User Email"
          :prefix-icon="EmailIcon"
          @focus="isCorrectEmailFormat = true"
          @blur="handleBlur"
        />
      </el-form-item>
      <el-form-item
        name="activationCode"
        :class="['input-item', isCorrectCodeFormat ? '' : 'no-pass']"
      >
        <el-input
          v-model="formData.activationCode"
          placeholder="RocketHub Redemption Code"
          :prefix-icon="CodeIcon"
          @focus="isCorrectCodeFormat = true"
          @blur="handleBlur"
        />
      </el-form-item>
      <el-form-item>
        <p class="error-msg">{{ errorMsg }}</p>
      </el-form-item>
      <el-form-item>
        <PrimaryButton
          class="primary-button submit-button flex-1"
          @click="handleSubmit"
          :disabled="disabled"
          :loading="isLoading"
        >
          Continue with email
        </PrimaryButton>
      </el-form-item>
    </el-form>
    <div v-else class="success-panel">
      <div class="success-panel-header">
        <img :src="emojiCongratulation" />
        <p class="header-title">Congratulations!</p>
        <p class="header-subtitle">
          Your {{ packageName }} Lifetime plan has been activated successfully.
        </p>
      </div>

      <ul class="tip-list">
        <li v-for="item in tipListMap[packageName]">
          {{ item }}
        </li>
      </ul>
      
      <PrimaryButton
        class="primary-button try-button"
        @click="handleTry"
        :disabled="disabled"
        :loading="isLoading"
      >
        Try now
      </PrimaryButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { tipListMap } from './data';
import { submitRedemptionForm } from '@/api/exchange';
import prefixEmail from "./assets/icon_email.svg?raw";
import prefixPromoCode from "./assets/icon_promo_code.svg?raw";
import emojiCongratulation from "./assets/emoji_congratulation.jpg";
import type { FormInstance } from 'element-plus';

const router = useRouter();
const EmailIcon = () => h('span', {innerHTML: prefixEmail});
const CodeIcon = () => h('span', {innerHTML: prefixPromoCode});
const errorMsg = ref('');
const isLoading = ref(false);
const isPass = ref(false);
const disabled = ref(true);
const packageName = ref('Starter' as keyof typeof tipListMap);
const isCorrectEmailFormat = ref(true);
const isCorrectCodeFormat = ref(true);
const formRef = ref(null as null | FormInstance);
const formData = reactive({
  activationCode: '',
  activeEmail: '',
});

async function handleSubmit() {
  if (disabled.value || isLoading.value) return;

  submitRedemptionForm(formData).then(({ code, msg, data }) => {
    if (code === 0) {
      isPass.value = true;

      switch (data.packageCode) {
        case 300205:
          packageName.value = 'Standard';
        break;
        case 300305:
          packageName.value = 'Pro';
        break;
        case 300405:
          packageName.value = 'Starter';
        break;
        default:
          errorMsg.value = 'System error';
          isPass.value = false;
      }
    } else {
      errorMsg.value = msg;
    }
  }).finally(() => {
    isLoading.value = false;
  });

  isLoading.value = true;
}

function handleBlur() {
  let newErrorMsg = '';

  if (formData.activeEmail !== '' && !/.+@.+\..+/.test(formData.activeEmail)) {
    newErrorMsg = 'Please enter a valid email address';
    isCorrectEmailFormat.value = false;
  }
  
  if (formData.activationCode !== '' && !/^(RHBVS|RHBVD|RHBVP)[A-Z0-9]{15}$/.test(formData.activationCode)) {
    if (newErrorMsg === '') {
      newErrorMsg = 'Please enter a valid promo code';
    }

    isCorrectCodeFormat.value = false;
  }

  if (newErrorMsg !== '') {
    errorMsg.value = newErrorMsg;
    disabled.value = true;
  } else {
    errorMsg.value = '';
    disabled.value = formData.activeEmail === '' || formData.activationCode === '';
  }
}

function handleTry() {
  router.replace('/');
}
</script>

<style scoped lang="scss">
.user-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: fit-content;
}

.user-form {
  width: 100%;
  max-width: 375px;
  margin-bottom: 24px;

  :deep(.el-input__wrapper) {
    height: 54px;
    border-radius: 54px;
    padding-inline: 10px;
    box-shadow: none;
    outline-offset: -1px;
    outline: 1px solid var(--outline-color, #D0D0D0);
    transition: outline-color .2s;

    &:hover {
      outline: 1px solid var(--outline-color, #A987FF);
    }

    &.is-focus {
      box-shadow: 0 0 0 2px rgba(135, 94, 255, 0.2);
    }

    & > .el-input__inner {
      padding-left: 0;
      outline: none;
    }
  }

  :deep(.el-input__icon) {
    color: var(--outline-color, --color);
    margin-inline: 12px;
    font-size: 18px;
  }

  & > .el-form-item.no-pass {
    --outline-color: #FF0000;
  }
}

.user-form-title {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 500;
}

.user-form-tip {
  color: #9CA3AF;
  font-size: 16px;
}

.user-form-title {
  width: 100%;
}

.user-form-header {
  margin-bottom: 48px;

  :deep(> .el-form-item__content) {
    text-align: center;
  }
}

.input-item {
  margin-top: 28px;
}

.error-msg {
  width: 100%;
  min-height: 32px;
  padding-block: 5px;
  color: #f54a45;
}

.success-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;

}

.tip-list {
  margin-block: 64px;

  & > li {
    width: 100%;
    line-height: 41px;
    text-align: left;
  }
}

.success-panel-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Inter-variant, Arial;

  img {
    width: 54px;
    height: 54px;
  }

  .header-title {
    font-family: Inter-variant, Arial;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin: 14px 0 24px 0;
    text-align: center;
  }

  .header-subtitle {
    line-height: 24px;
  }
}

.primary-button {
  & > :deep(.el-button) {
    width: 100%;
    color: #FFFFFF;
    background-image: none !important;
  }
  
  &:not(.bv-primary-button-disabled) > :deep(.el-button) {
    background-color: #060606 !important;
  }
}

.try-button {
  width: 100%;
  max-width: 375px;
}
</style>