<script setup lang="ts">
import { useDebounceFn } from "@vueuse/core";
import { reactive, ref, watchEffect, type Ref } from "vue";
import prefix_email from "@/assets/images/login/prefix_email.svg";
import { validateEmail } from "../../utils";
import ForgotSecond from "./forgot-second.vue";
import { useRoute, type LocationQueryValue } from "vue-router";
import { resetPwd } from "./config";
import { useUserStore } from "@/store/modules/user";
import { PrimaryButton } from "@/components/common";
import { useMessage } from "@/utils";
const route = useRoute();
const message = useMessage();
const currentModa: Ref<LocationQueryValue | LocationQueryValue[]> =
  ref("forgot");
watchEffect(() => {
  currentModa.value = route.query.type;
});
const isEmail = ref(false);
const formState = reactive({
  email: "",
});
const errorState = reactive({
  email: "",
  errorMsg: "",
});
const step = ref(1);
const loading = ref(false);
const user = useUserStore();
const debouncedFn = useDebounceFn(async (value) => {
  //api
  await user
    .help(value)
    .then(({ code, msg }) => {
      if (code === 0) {
        step.value = 2;
      } else {
        onFinishFailed();
      }
    })
    .catch(() => {
      onFinishFailed();
    })
    .finally(() => {
      loading.value = false;
    });
}, 500);

const onFinish = (values: { email: string }) => {
  loading.value = true;
  debouncedFn(values);
};

const onFinishFailed = () => {
  message.error("send email failed");
  loading.value = false;
};

const handleEmailChange = () => {
  isEmail.value = validateEmail(formState.email);
};
</script>

<template>
  <div>
    <h1 class="text-4xl font-medium mb-8 text-center">
      {{ resetPwd[`${currentModa}`].title }}
    </h1>
    <el-form
      v-if="step === 1"
      :model="formState"
      class="login-form"
      @submit.native.prevent
      name="basic"
      autocomplete="off"
    >
      <el-form-item>
        <!-- TODO 重置和忘记密码展示不同的信息 -->
        <div class="text-left mb-8 forgot-text text-sm text-center">
          {{ resetPwd[`${currentModa}`].describe }}
        </div>
      </el-form-item>
      <el-form-item name="email" class="mb-8 email-input">
        <el-input
          v-model="formState.email"
          @input="handleEmailChange"
          @keyup.enter="onFinish(formState)"
          class="form-input"
          placeholder="Email"
        >
          <template #prefix>
            <img :src="prefix_email" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item class="confirm-button">
        <PrimaryButton
          @click="onFinish(formState)"
          width="100%"
          :disabled="!isEmail"
          :loading="loading"
          >{{ resetPwd[`${currentModa}`].button }}</PrimaryButton
        >
      </el-form-item>
    </el-form>
    <ForgotSecond v-if="step === 2" />
  </div>
</template>

<style lang="scss" scoped>
.forgot-text {
  width: 570px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  :deep(.el-input__prefix-inner > :last-child) {
    margin-right: 12px;
    margin-left: 10px;
  }
}

.email-input {
  width: 100%;
  min-width: auto;
  max-width: 375px;

  :deep(.el-form-item__content) {
    display: flex;
    justify-content: center;
  }

  :deep(.el-input__wrapper) {
    height: 54px;
    border-radius: 41px;
    padding: 1px 11px;
  }

  :deep(.el-input) {
    height: 54px;
    border-color: transparent;
    border-radius: 41px;
    padding: 0;
  }

  :deep(.el-input__inner) {
    text-align: left;
    padding: 0;
    font-size: 14px;
    color: #000;
  }
}

.confirm-button {
  width: 100%;
  min-width: auto;
  max-width: 375px;
  display: flex;
  justify-content: center;
  margin: 0;

  :deep(.bv-middle-button) {
    width: 100%;
  }

  :deep(.el-button) {
    width: 100%;
    height: 54px;
    border-radius: 36px;
    // background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  }
}
</style>
