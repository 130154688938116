<template>
  <div class="pricing-card-list-container" id="pricing">
    <div class="pricing-card-list" :class="gridStyle" :style="`--base-size: ${baseSize}px`">
      <div v-for="card in cardList" :key="card.id" class="card-item">
        <p class="tag" v-if="card.tag">{{ card.tag }}</p>
        <p class="title">{{ card.name }}</p>
        <p class="pricing-box">
          <span class="sale">{{ card.sale }}</span>
          <span class="pricing">{{ card.pricing }}</span>
        </p>
        <p class="origin-pricing">
          {{ card.originPricing }}
        </p>

        <RouterLink :to="card.path" target="_blank" class="route-link">
          <p
            class="button"
            @click="handleClick(card.trackName)"
            v-track:click="'boolvideo_deal_subscribe_click'"
          >
            {{ card.buttonText }}
          </p>
        </RouterLink>

        <ul class="tips-container">
          <li v-for="(item, index) in card.tips" :key="item">
            <p class="icon-right">✅</p>
            <p
              :class="{
                'font-bold': card.fontWeight && index <= 5 && index >= 2,
              }"
            >
              {{ item }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useTrackStore } from "@/store/modules/track";

const props = defineProps({
  timeDone: Boolean,
});

const isTimeDown = ref(false);
const { collectData } = useTrackStore();
const gridStyle = ref("");
const cardWidth = ref(0);
watch(
  () => props.timeDone,
  (value) => {
    isTimeDown.value = value;
  },
  {
    immediate: true,
  },
);

const oldCardLIst = ref([
  {
    id: 1,
    name: "Lifetime Deal Standard",
    trackName: "standard_ltd",
    pricing: "$597 ",
    sale: "-70%",
    originPricing: "$1990",
    buttonText: "Go to Plans",
    tag: "Deal Ended",
    path: "/premium",
    tips: [
      "Unlimited video generation",
      "Unlimited Al image tools",
      "60 video exports / month",
      "30 min video tools credit / month",
      "100+ standard templates",
      "10G cloud storage",
      "Al image recognition",
      "GPT-4 powered script-writing",
    ],
  },
  {
    id: 2,
    name: "Lifetime Deal Pro",
    trackName: "pro_ltd",
    pricing: "$1317",
    sale: "-70%",
    originPricing: "$4390",
    buttonText: "Go to Plans",
    tag: "Deal Ended",
    fontWeight: 500,
    path: "/premium",
    tips: [
      "Unlimited video generation",
      "Unlimited Al image tools",
      "Unlimited video exports / month",
      "90 min video tools credit / month",
      "500+ premium templates",
      "100G cloud storage",
      "Al image recognition",
      "GPT-4 powered script-writing",
    ],
  },
  {
    id: 3,
    name: "Annual Standard",
    trackName: "standard_annual",
    pricing: "$278 ",
    sale: "-20%",
    originPricing: "$348",
    buttonText: "Go to Plans",
    tag: "",
    path: "/premium",
    tips: [
      "Unlimited video generation",
      "Unlimited Al image tools",
      "60 video exports / month",
      "30 min video tools credit / month",
      "100+ standard templates",
      "10G cloud storage",
      "Al image recognition",
      "GPT-4 powered script-writing",
    ],
  },
  {
    id: 4,
    name: "Annual Pro",
    trackName: "pro_annual",
    pricing: "$662",
    sale: "-20%",
    fontWeight: 500,
    originPricing: "$828",
    buttonText: "Go to Plans",
    path: "/premium",
    tag: "",
    tips: [
      "Unlimited video generation",
      "Unlimited Al image tools",
      "Unlimited video exports / month",
      "90 min video tools credit / month",
      "500+ premium templates",
      "100G cloud storage",
      "Al image recognition",
      "GPT-4 powered script-writing",
    ],
  },
]);

const cardList = computed(() => {
  return oldCardLIst.value;
});

const handleClick = (trackName) => {
  collectData("boolvideo_deal_subscribe_click", {
    plan_name: trackName,
  });
};

const baseSize = ref("");
const computedCardSize = () => {
  const windowWidth = window.innerWidth;

  if (windowWidth <= 750) {
    gridStyle.value = "mini-grid";
  } else if (windowWidth > 750 && windowWidth <= 900) {
    gridStyle.value = "custom-phone-grid";
  } else if (windowWidth > 978 && windowWidth <= 1390) {
    gridStyle.value = "ipad-grid";
  } else {
    gridStyle.value = "ipad-grid";
  }
};

onMounted(async () => {
  await nextTick();
  const observer = new ResizeObserver((entries) => {
    for (let entry of entries) {
      const target = entry.target;
      const { width } = target.getBoundingClientRect();
      cardWidth.value = width;
      baseSize.value = Math.min(100, ((width / 318) * 100) | 0);
    }
  });

  const element = document.getElementsByClassName("card-item")[0];
  observer.observe(element);

  computedCardSize();

  window.addEventListener("resize", () => {
    computedCardSize();
  });

  collectData("boolvideo_deal_subscribe_click", {
    click: "click_subscribe",
  });
});
</script>

<style lang="scss" scoped>
.pricing-card-list-container {
  margin-top: 86px;
  width: 100%;
  padding: 90px 50px;
  background-color: #e5eaff;
  display: flex;
  justify-content: center;

  .mini-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .card-item {
      aspect-ratio: 318 / 556;
      max-width: 318px;
      margin-bottom: 24px;
      margin-right: 0px;
    }

    .card-item:last-child {
      margin-bottom: 0px;
    }
  }

  .custom-phone-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .card-item {
      aspect-ratio: 318 / 556;
      max-width: 318px;
    }

    .card-item:nth-child(odd) {
      margin-right: 14px;
    }

    .card-item:first-child {
      margin-bottom: 14px;
    }

    .card-item:nth-child(2) {
      margin-bottom: 14px;
    }
  }

  .ipad-grid {
    display: grid;
    grid-row-gap: 14px;
    grid-column-gap: 14px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .card-item {
      aspect-ratio: 318 / 556;
      max-width: 318px;
    }
  }

  .desktop-grid {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .card-item {
      max-width: 318px;
    }
  }
}

.pricing-card-list {
  width: 100%;
  max-width: 1344px;

  .card-item {
    width: 100%;
    aspect-ratio: 318 / 556;
    border-radius: calc(var(--base-size) * 0.136);
    border: 0.85px solid #dee0e3;
    background: #fff;
    padding: calc(var(--base-size) * 0.39) 0px 0 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .tag {
      position: absolute;
      left: 6px;
      top: 5px;
      display: inline-flex;
      height: calc(var(--base-size) * 0.28);
      padding: calc(var(--base-size) * 0.02) calc(var(--base-size) * 0.08);
      justify-content: center;
      align-items: center;
      border-radius: calc(var(--base-size) * 0.07);
      background: #bbbfc4;
      color: #fff;
      text-align: center;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.12);
      font-style: normal;
      font-weight: 500;
      line-height: calc(var(--base-size) * 0.2);
    }

    .title {
      color: #060606;
      text-align: center;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.18);
      font-style: normal;
      font-weight: 700;
      line-height: calc(var(--base-size) * 0.238);
      margin-bottom: calc(var(--base-size) * 0.22);
    }

    .pricing-box {
      text-align: center;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.36);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .sale {
      color: #ff5449;
      margin-right: calc(var(--base-size) * 0.1);
    }

    .pricing {
      color: #060606;
    }

    .origin-pricing {
      margin-top: calc(var(--base-size) * 0.0813);
      margin-bottom: calc(var(--base-size) * 0.2783);
      color: #060606;
      text-align: center;
      font-family: Inter-variant;
      font-size: calc(var(--base-size) * 0.18);
      font-style: normal;
      font-weight: 500;
      line-height: calc(var(--base-size) * 0.204);
      text-decoration: line-through;
    }

    .button-box {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .route-link {
      margin-bottom: calc(var(--base-size) * 0.28);
      border-radius: 100px;
    }

    .button {
      display: flex;
      width: calc(var(--base-size) * 2.17);
      height: calc(var(--base-size) * 0.48);
      padding: calc(var(--base-size) * 0.04251) calc(var(--base-size) * 0.136);
      justify-content: center;
      align-items: center;
      border-radius: calc(var(--base-size) * 0.306);
      font-size: calc(var(--base-size) * 0.18);
      background: #6f46f4;
      color: #fff;
      cursor: pointer;

      &:hover {
        background: linear-gradient(289deg, #6f46f4 5.38%, #957aec 99.95%);
      }
    }

    .disabled-button {
      display: flex;
      width: calc(var(--base-size) * 2.17);
      height: calc(var(--base-size) * 0.48);
      padding: calc(var(--base-size) * 0.04251) calc(var(--base-size) * 0.136);
      justify-content: center;
      align-items: center;
      border-radius: calc(var(--base-size) * 0.306);
      font-size: calc(var(--base-size) * 0.18);
      background: #bbbfc4;
      color: #fff;
    }

    .tips-container {
      min-width: calc(var(--base-size) * 2.4);

      .icon-right {
        margin-right: 4px;
      }

      .font-bold {
        font-weight: 600;
      }

      li {
        display: flex;
        align-items: center;
        color: #060606;
        font-family: Inter-variant;
        font-size: calc(var(--base-size) * 0.14);
        font-style: normal;
        font-weight: 400;
        min-height: calc(var(--base-size) * 0.308);
      }

      .disc {
        width: 4px;
        height: 4px;
        background-color: #060606;
        border-radius: calc(var(--base-size) * 0.1);
        margin-right: calc(var(--base-size) * 0.05);
      }
    }
  }
}
</style>
