<template>
  <transition>
    <div class="video-clip-overlay" v-show="isOpen" @click.self="handleClose">
      <div class="video-clip-modal">
        <header class="modal-header">
          <span class="modal-title">Trim</span>
          <SvgIcon
            class="modal-close-btn"
            name="icon_close"
            @click="handleClose"
          />
        </header>
        <div class="modal-content">
          <video
          class="video-previewer skeleton"
          preload="auto"
          ref="videoRef"
          :src="videoSrc"
          ></video>
          <div class="play-box" :disabled="isDisable ? '' : null">
            <svg-icon
              clickable
              :name="isPlay ? 'editor_pause' : 'editor_play'"
              :size="24"
              @click="handlePlay"
            />
            <span>
              <span class="current-time">{{ formatDuration(currentTime) }}</span>
              <span class="duration">{{ ` / ${formatDuration(totalTime)}` }}</span>
            </span>
          </div>
          <div
            :ref="pointerMove.ref"
            class="select-box skeleton"
          >
            <ul class="frame-list">
              <li v-for="frame, i in frames" :key="i">
                <v-render :vnode="frame.vnode" />
              </li>
            </ul>
            <div class="select-mask" v-if="!isDisable">
              <div class="select-range" :style="selectRangeStyle">
                <div class="range-rect">
                  <div
                    class="play-line"
                    :style="{left: `${(currentTime - clipStart) / duration * 100}%`}"
                  ></div>
                </div>
                <span class="clip-duration">{{ duration.toFixed(1) }}s</span>
              </div>
            </div>
          </div>
          <div class="button-group">
            <PrimaryButton
              class="apply-button"
              :disabled="isDisable"
              @click="handleApply"
            >
              Apply
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { getVideoSpritesheet } from '@/api';
import { usePointerMove } from '@/utils/hook';
import { useSingleMessage } from '@/utils/dom';
import PrimaryButton from '@/components/common/bv-button/components/primary-button.vue';
import { createImageFromSprite } from '@/utils';

const emit = defineEmits(['apply', 'close']);
const props = defineProps({
  videoSrc: {
    type: String,
    required: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
  start: {
    type: Number,
    default: 0,
  },
  duration: {
    type: Number,
    required: true,
  },
});

const ticker = ref<number | null>(null);
const loadingProgress = ref(true);
const totalTime = ref(0);
const currentTime = ref(0);
const clipStart = ref(0);
const isPlay = ref(false);
const isDisable = ref(true);
const isOpen = ref(props.open);
const videoRef = ref<HTMLVideoElement | null>(null);
const frames = ref<ReturnType<typeof createImageFromSprite>[]>([]);
const Message = useSingleMessage();
const selectRangeStyle = computed(() => {
  const ratio = clipStart.value / totalTime.value;
  const width = props.duration / totalTime.value;

  return isNaN(ratio) ? undefined : {
    width: `${width * 100}%`,
    left: `${ratio * 100}%`,
  };
});

const pointerMove = (() => {
  let minX = 0;

  return usePointerMove({
    suppressY: true,
    handler(e) {
      const target = (e.target as HTMLElement).closest<HTMLElement>('.select-range');

      if (e.state === 'start') {
        if (isDisable.value || target === null) {
          e.abort();
          return;
        } else {
          const offsetX = target.offsetLeft;
          minX = e.x - offsetX;
        }
      }

      clipStart.value = Math.max(0, Math.min(totalTime.value - props.duration, (e.x - minX) / e.self.clientWidth * totalTime.value));
    },
  });
})();

function handleClose() {
  isOpen.value = false;
  emit('close');
}

function handlePlay() {
  isPlay.value = !isPlay.value;
}

const handleLoadedData = async () => {
  if (!videoRef.value) return;
  const capturedFrames = [];
  const spriteParams = {
    url: props.videoSrc,
    rowFrameCount: 10,
    // 数字的处理是为了避免最后一张图为黑块
    intervalSeconds: Math.trunc(videoRef.value.duration * 10 / 10.5) / 10,
  };

  const sprite = new Image();
  const spriteInfo = (await getVideoSpritesheet(spriteParams)).data as {
    url: string;
    frameWidth: number;
    frameHeight: number;
  };

  sprite.crossOrigin = 'anonymous';
  sprite.src = spriteInfo.url;

  for (let i = 0; i < 10; i++) {
    capturedFrames.push(createImageFromSprite(sprite, new DOMRect(spriteInfo.frameWidth * i, 0, spriteInfo.frameWidth, spriteInfo.frameHeight)));
  }

  frames.value = capturedFrames;
  isDisable.value = false;
  totalTime.value = videoRef.value.duration;
  Message.close();
  loadingProgress.value = false;
};

function formatDuration(duration: number) {
  return Array.from({length: 3}).map((_, i) => (duration / 60 ** i % 60 | 0).toString().padStart(2, '0')).reverse().join(':');
}

function clearFrames() {
  for (const frame of frames.value) {
    frame.revoke();
  }

  frames.value = [];
}

function handleApply() {
  emit('apply', {
    start: clipStart.value,
    end: clipStart.value + props.duration,
    duration: props.duration,
    src: props.videoSrc,
  });

  isOpen.value = false;
}

watch(clipStart, value => {
  isPlay.value = false;
  currentTime.value = value;

  if (videoRef.value) {
    videoRef.value.currentTime = value;
  }
});

watch(isPlay, value => {
  if (videoRef.value) {
    if (value) {
      if (currentTime.value === clipStart.value + props.duration) {
        videoRef.value.currentTime = clipStart.value;
      }

      videoRef.value.play();
      refreshVideoTime();
    } else {
      videoRef.value.pause();
    }
  }
});

function refreshVideoTime() {
  if (!videoRef.value) return;
  currentTime.value = videoRef.value.currentTime;

  if (currentTime.value >= clipStart.value + props.duration) {
    currentTime.value = clipStart.value + props.duration;
    isPlay.value = false;
  }

  if (isPlay.value) {
    ticker.value = requestAnimationFrame(refreshVideoTime);
  }
}

defineExpose({ isOpen });

onMounted(() => {
  Message.loading("Loading, it will take a while", {
    duration: 0,
    onClose() {
      isDisable.value && handleClose();
    }
  });
  if (videoRef.value) {
    videoRef.value.addEventListener("loadedmetadata", handleLoadedData);
  }
});

onBeforeUnmount(() => {
  isPlay.value = false;
  clearFrames();
  if (ticker.value) {
    cancelAnimationFrame(ticker.value);
  }
  if (videoRef.value) {
    videoRef.value.removeEventListener("loadedmetadata", handleLoadedData);
  }
  videoRef.value = null;
  ticker.value = null;
});
</script>
<style scoped lang="scss">
.skeleton {
  background-size: 400% 100%;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .01) 37%, rgba(0, 0, 0, .05) 63%);
  animation: el-skeleton-loading 1.4s ease infinite;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 300ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.video-clip-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
}
.video-clip-modal {
  margin: auto;
  width: 65%;
  height: 80%;
  border-radius: 4px;
  background-color: #F3F5F7;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 26px;
  border-bottom: 1px solid #E5E7EB;
  background-color: #FFFFFF;
  border-radius: 4px 4px 0 0;
}

.modal-title {
  font-size: 18px;
  font-weight: 500;
}

.modal-content {
  height: calc(100% - 72px);
  padding: 0 24px;
}

.progress-skeleton {
  --el-skeleton-to-color: rgb(230, 231, 235);
}

.modal-close-btn {
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
}

.video-previewer {
  margin: 0 auto;
  height: calc(100% - 268px);
  width: auto;
  margin-top: 10px;
  background-color: #F3F5F7;
}

.play-box {
  display: flex;
  align-items: center;
  margin: 26px 0;
  justify-content: center;
  gap: 15px;
  font-variant-numeric: tabular-nums;
  color: #1C1B1E;
  font-size: 14px;

  &[disabled] {
    color: #646A73;

    & > svg {
      width: 24px;
      height: 24px;
      cursor: not-allowed;
    }
  }
}

.select-box {
  position: relative;
  width: 100%;
  height: 70px;
  background-color: #F3F5F7;
}

.frame-list {
  display: flex;
  height: 100%;
  pointer-events: none;

  & > li {
    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.select-range {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100%;
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #FFFFFF;
    mix-blend-mode: overlay;
  }
}

.duration {
  color: #646A73;
}

.select-mask {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, .5);
}

.play-line {
  position: absolute;
  left: 0;
  width: 2px;
  height: 100%;
  outline: 1px solid #FFFFFF;
  background-color: #333333;
  box-sizing: content-box;
}

.range-rect {
  position: absolute;
  inset: 0;
  overflow: hidden;
  
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border: 2px solid #6741FF;
  }
}

.clip-duration {
  position: absolute;
  bottom: 6px;
  right: 8px;
  padding-inline: 7px;
  font-size: 12px;
  border-radius: 2px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, .7);
}

.button-group {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 30px 0;
}

.apply-button {
  & > :deep(.el-button) {
    width: 120px;
    height: 54px;
  }
}
</style>
