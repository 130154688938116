export const styleEmojiList = [
  {
    label: "general",
    icon: "style_empathetic"
  },
  {
    label: "advertisement-upbeat",
    icon: "style_advertisement_upbeat"
  },
  {
    label: "depressed",
    icon: "style_depressed"
  },
  {
    label: "gentle",
    icon: "style_gentle",
  },
  {
    label: "sad",
    icon: "style_sad",
  },
  {
    label: "Sad",
    icon: "style_sad",
  },
  {
    label: "affectionate",
    icon: "style_affectionate",
  },
  {
    label: "disgruntled",
    icon: "style_disgruntled",
  },
  {
    label: "serious",
    icon: "style_serious",
  },
  {
    label: "hopeful",
    icon: "style_hopeful",
  },
  {
    label: "angry",
    icon: "style_angry",
  },
  {
    label: "documentary-narration",
    icon: "style_documentary_narration",
  },
  {
    label: "lyrical",
    icon: "style_lyrical",
  },
  {
    label: "shouting",
    icon: "style_shouting",
  },
  {
    label: "assistant",
    icon: "style_assistant",
  },
  {
    label: "embarrassed",
    icon: "style_embarrassed",
  },
  {
    label: "narration",
    icon: "style_narration",
  },
  {
    label: "narration-relaxed",
    icon: "style_narration"
  },
  {
    label: "sorry",
    icon: "style_sorry",
  },
  {
    label: "calm",
    icon: "style_calm",
  },
  {
    label: "empathetic",
    icon: "style_empathetic",
  },
  {
    label: "narration-professional",
    icon: "style_narration",
  },
  {
    label: "sports-commentary",
    icon: "style_sports_commentary",
  },
  {
    label: "chat",
    icon: "style_chat",
  },
  {
    label: "envy",
    icon: "style_envious",
  },
  {
    label: "envious",
    icon: "style_envious",
  },
  {
    label: "newscast",
    icon: "style_newscast"
  },
  {
    label: "sports-commentary-excited",
    icon: "style_sports_commentary",
  },
  {
    label: "chat-casual",
    icon: "style_chat",
  },
  {
    label: "excited",
    icon: "style_excited",
  },
  {
    label: "newscast-casual",
    icon: "style_newscast",
  },
  {
    label: "newscast-formal",
    icon: "style_newscast",
  },
  {
    label: "fearful",
    icon: "style_fearful",
  },
  {
    label: "Cheerful",
    icon: "style_cheerful",
  },
  {
    label: "cheerful",
    icon: "style_cheerful",
  },
  {
    label: "terrified",
    icon: "style_terrified",
  },
  {
    label: "unfriendly",
    icon: "style_unfriendly",
  },
  {
    label: "customerservice",
    icon: "style_customerservice",
  },
  {
    label: "friendly",
    icon: "style_friendly",
  },
  {
    label: "poetry-reading",
    icon: "style_poetry_reading",
  },
  {
    label: "whispering",
    icon: "style_whispering",
  },
];