export const resetPwd = {
  forgot: {
    title: "Forgot your password?",
    describe:
      "Don't panic — we've got you covered. Give us the email address you use to log in to Boolv and we'll send you instructions for resetting your password.",
    button: "Help me",
  },
  reset: {
    title: "Reset Password",
    describe:
      "Give us the email address you use to log in to Boolv and we'll send you instructions for resetting your password.",
    button: "Send",
  },
};
