<script setup>
import { useCreatorStore } from "../../stores";
import MediaMaterial from "./meidamaterial.vue";
import LibraryMaterial from "./librarymaterial.vue";
import TextMaterial from "./textmaterial.vue";
import GraphicMaterial from "./graphicmaterial.vue";
import EffectMaterial from "./effectmaterial.vue";
import StickerMaterial from "./stickermaterial.vue";
import FilterMaterial from "./filtermaterial.vue";
import AudioMaterial from "./audiomaterial.vue";
import BrandKitMaterial from "./brandkitmaterial.vue";
import VoiceoverMaterial from './voiceovermaterial.vue';
import TransitionMaterial from './transitionmaterial.vue';

const materials = {
  media: MediaMaterial,
  library: LibraryMaterial,
  audio: AudioMaterial,
  text: TextMaterial,
  graphic: GraphicMaterial,
  effect: EffectMaterial,
  sticker: StickerMaterial,
  filter: FilterMaterial,
  brandKit: BrandKitMaterial,
  voiceover: VoiceoverMaterial,
  transition: TransitionMaterial,
};

const { creator, empty, materialTab, showMaterial } = useCreatorStore();
const route = useRoute();

watch(creator, (newValue, oldValue) => {
  if (newValue && !oldValue) {
    showMaterial.value = empty.value;

    if (route.query.mat) {
      showMaterial.value = true;
      materialTab.value = route.query.mat;
    }
  }
}, { flush: "post" });
</script>
<template>
  <div class="material-main" v-show="showMaterial">
    <keep-alive>
      <component :is="materials[materialTab]" />
    </keep-alive>
  </div>
</template>
<style scoped>
.material-main {
  height: 100%;
  width: 263px;
  background-color: #ffffff;
  border-right: 1px solid #e8e9ec;
}
:deep(.material-content) {
  padding: 0 16px 150px;
}
</style>
