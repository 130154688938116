<template>
  <div
    class="relative w-full h-full video-background-container"
    :style="{ paddingBottom: mode == 'editor' ? '0px' : '39px' }"
  >
    <div class="w-full h-full flex flex-col justify-center items-center">
      <!-- 视频区域 -->
      <div
        class="video-box"
        :style="{ height: mode == 'editor' ? '80%' : '76%' }"
      >
        <videoItem :src="originUrl" ref="videoItemRef" />
      </div>

      <!-- button -->
      <div
        class="btn-box"
        :style="{ marginTop: mode == 'editor' ? '24px' : '38px' }"
      >
        <primary-button
          v-if="mode == 'editor'"
          :disabled="loading"
          :showDefaultIcon="false"
          padding="20px 65px"
          @click="handleEditorProcess"
        >
          <template #preIcon>
            <svg-icon
              name="icon_magic"
              :style="{ width: '20.7px', height: '20.7px', color: '#fff' }"
            ></svg-icon>
          </template>
          <p
            class="text-base font-normal flex items-center justify-center leading-22"
            :style="{ marginLeft: '10px' }"
          >
            Remove BG
          </p>
        </primary-button>

        <primary-button
          v-else
          :disabled="loading"
          :showDefaultIcon="false"
          padding="20px 62px"
          @click="handleProcess"
        >
          <template #preIcon>
            <svg-icon
              name="icon_magic"
              :style="{ width: '20.7px', height: '20.7px', color: '#fff' }"
            ></svg-icon>
          </template>
          <p
            class="text-base font-normal flex items-center leading-22"
            :style="{ width: '100px', marginLeft: '10px' }"
          >
            Remove BG
          </p>
        </primary-button>
      </div>
    </div>
    <loading-mask v-if="loading" />
  </div>
</template>

<script setup>
import { watch, onUnmounted, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import LoadingMask from "../../components/loading.vue";
import videoItem from "./videoItem.vue";
import cases from '../../assets/cases/video-background-remover';
import { PrimaryButton } from "@/components/common/index";

import { useSingleMessage } from "@/utils";
import {
  getVideoBgRemove,
  getVideoBgRemoveState,
  fileUploadPre,
  uploadFile,
  fileUploadState
} from "@/api";

const props = defineProps({
  src: String,
  mid: {
    type: String,
    default: "22222",
  },
  mode: String,
});

const emit = defineEmits(["onAsyncProcess"]);

const Message = useSingleMessage();
const router = useRouter();
const route = useRoute();
const originUrl = ref(props.src);
const resultUrl = ref("");
const removeComplete = ref(false);
const loading = ref(false);
const state = ref(null);
const timer = ref(null);
const taskId = ref("");
const videoItemRef = ref(null);
const usedCase = cases.find(item => item.input === route.query.url) ?? null;

watch(
  () => props.src,
  (value) => {
    if (value) {
      originUrl.value = value;
    }
  },
);

watch(
  () => state.value,
  (value) => {
    if (value == 0) {
      // success
      loading.value = false;
      removeComplete.value = true;
      Message.close();

      clearInterval(timer.value);
      timer.value = null;
    }

    if (value == 1) {
      timer.value = setInterval(() => {
        queryProcessState();
      }, 2000);
    }

    if (value == 2) {
      // failed
      clearInterval(timer.value);
      timer.value = null;
      loading.value = false;
      Message.close();
      Message.error("failed, try again");
    }
  },
);

// 正常过程中视频处理
const handleProcess = async () => {
  let code = -1;
  const videoSize = videoItemRef.value.judgeVideoWithOrHeight();

  if (videoSize.width < 360 || videoSize.height < 360) {
    Message.error("Minimum 360p resolution", { duration: 2000 });
    return;
  }

  loading.value = true;
  if (usedCase === null) {
    code = (await getVideoBgRemove({
      url: route.query?.url,
    })).code;
  } else {
    await uploadMaterials(usedCase.output);
    code = 0;
  }

  loading.value = false;
  if (code === 0) {
    // 已经处理过, 取到 space 异步处理完整视频
    handleToSpace();
  }
};

// 正常处理过程中：查询预览视频处理状态
const queryProcessState = async () => {
  const { data } = await getVideoBgRemoveState({
    taskId: taskId.value,
  });
  state.value = data?.state;
  if (data?.url) {
    resultUrl.value = data?.url;
  }
};

const handleToSpace = () => {
  const href = router.resolve({
    path: "/space",
    query: {
      tab: "media",
    },
  });
  // 点击事件
  window.open(href.href, "_blank");
};

// 编辑器中视频抠图处理
const handleEditorProcess = async () => {
  const videoSize = videoItemRef.value.judgeVideoWithOrHeight();

  if (videoSize.width < 360 || videoSize.height < 360) {
    Message.error("Minimum 360p resolution", { duration: 2000 });
    return;
  }

  loading.value = true;
  const data = await getVideoBgRemove({
    url: props.mode == "editor" ? props.src : route.query?.url,
    draftId: route.query?.draftId || 0,
  });
  // 处理成功，发送事件异步处理
  if (data.code === 0) {
    emit("onAsyncProcess", data.data.taskId);
  }

  loading.value = false;
};

const uploadMaterials = async (url) => {
  const blob = await fetch(url).then(res => res.blob());
  const file = new File([blob], 'untitled');
  const uploadParams = {
    suffix: '.mp4',
    size: file.size,
    type: 'video',
    draftId: 0,
    toolName: 'VIDEO_BG_REMOVE',
  };

  const preResult = await fileUploadPre(uploadParams);
  if (preResult.code !== 0) {
    throw new Error("error, save failed");
  }
  const { preSignUrl, mid } = preResult.data;
  const uploadResult = await uploadFile(preSignUrl, file);
  if (uploadResult.code !== 0) {
    throw new Error("error, save failed");
  }

  await waitPreProcessing(mid);
};

const waitPreProcessing = async (mid) => {
  const {data: {init}} = await fileUploadState(mid);

  if (!init) {
    await new Promise(res => {
      setTimeout(async () => res(await waitPreProcessing(mid)), 2000);
    });
  }
};

onMounted(() => {
  if (route.query?.url) {
    originUrl.value = route.query?.url;
  }
});

onUnmounted(async () => {
  clearInterval(timer.value);
  timer.value = null;
});
</script>

<style lang="scss" scoped>
.video-background-container {
  height: 100%;
}

.title {
  color: #000;
  font-family: Inter-variant;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 42px 0 28px 0;
}

.video-box {
  aspect-ratio: 16/9;
}

.image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  -webkit-user-drag: none;
}
</style>
