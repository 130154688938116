import generalPreview from "./assets/general/index";
import productToVideoPreview from './assets/productToVideo/index';
import ideaToVideoPreview from './assets/ideaToVideo/index';
import scriptToVideoPreview from './assets/scriptToVideo/index';
import affiliateProgram from './assets/affiliateProgram/index';
import blogToVideoPreview from './assets/blogToVideo/index';
import visualsToVideoPreview from './assets/visualsToVideo/index';
import templatesToVideoPreview from './assets/templatesToVideo/index';
import webEditorPreview from './assets/webEditor/index';
import aiToolPreview from './assets/aiTool/index';
import { RouterLink, useRoute } from "vue-router";
import type { VNode } from "vue";

const infoMap: Record<string, {
  title: string;
  steps: {
    title: string;
    description: VNode | string;
    link?: {
      text: string;
      path: string;
    };
    preview?: {
      src: string;
      alt: string;
    } | {
      src: string;
      poster: string;
    };
  }[];
}> = {
  home: {
    title: 'General workflow',
    steps: [
      {
        title: 'Step 1',
        description: 'Input your product URL, blog URL, images, videos, or text.',
        preview: {
          src: generalPreview.step1,
          alt: 'Step 1 preview',
        },
      },
      {
        title: 'Step 2',
        description: 'Customize your AI co-pilot.',
        preview: {
          src: generalPreview.step2,
          alt: 'Step 2 preview',
        },
      },
      {
        title: 'Step 3',
        description: 'Generate and adjust whatever you want with the lightweight editor.',
        preview: {
          src: generalPreview.step3,
          alt: 'Step 3 preview',
        },
      },
      {
        title: 'Step 4',
        description: 'Make more advanced edits or just export.',
        preview: {
          src: generalPreview.step4,
          alt: 'Step 4 preview',
        },
        link: {
          text: 'Start creating',
          path: '/workspace',
        },
      },
    ],
  },
  ['product-to-video']: {
    title: 'How to transform product URL\ninto videos in 4 steps',
    steps: [
      {
        title: 'Step 1: Input your Product URL',
        description: 'Select \'Product URL to Video\' and input the link to your Shopify, Amazon, WooCommerce, or Etsy Product Page.',
        preview: {
          src: productToVideoPreview.step1,
          alt: 'Step 1 of the Product URL to Video feature: import your product URL',
        }
      },
      {
        title: 'Step 2: Choose pics and personalize video properties',
        description: 'Once the images are recognized, choose video properties to suit your needs. Includes video type, length, scale, purpose, library, language, etc.',
        preview: {
          src: productToVideoPreview.step2,
          alt: 'Step 2 of the Product URL to Video feature: select images and personalize video properties',
        }
      },
      {
        title: 'Step 3: Preview and Customize your video',
        description: 'Make individual edits in our lightweight editor. You can adjust scenes with AI or our vast stock library to find the perfect visuals. We also offer an advanced editor if you need it!',
        preview: {
          src: productToVideoPreview.step3,
          alt: 'Step 3 of the Product URL to Video feature: preview and edit your video',
        }
      },
      {
        title: 'Step 4: Export your video',
        description: 'Satisfied with the preview? Export and share it to enhance conversion rates and elevate your sales figures.',
        preview: {
          src: productToVideoPreview.step4,
          alt: 'Step 4 of the Product URL to Video feature: export your video',
        },
        link: {
          text: 'Try product to videos',
          path: '/product-to-video',
        },
      }
    ],
  },
  ['idea-to-video']: {
    title: 'How to transform idea\ninto videos in 4 steps',
    steps: [
      {
        title: 'Step 1: Input your idea',
        description: 'Select \'Idea to Video\' and input your idea.',
        preview: {
          src: ideaToVideoPreview.step1,
          alt: 'Step 1 of the Idea to Video feature: importing your idea',
        }
      },
      {
        title: 'Step 2: Personalize video properties',
        description: 'Choose video properties to suit your needs. Includes video length, scale, language, etc.',
        preview: {
          src: ideaToVideoPreview.step2,
          alt: 'Step 2 of the Idea to Video feature: personalize video properties',
        }
      },
      {
        title: 'Step 3: Preview and Customize your video',
        description: 'Adjust VoiceOver styles and edit directly in our simple prompt box as if talking to a human editor. Command Boolvideo AI to delete scenes, swap stock, modify scenes, and more. We also offer an advanced editor if you need it!',
        preview: {
          src: ideaToVideoPreview.step3,
          alt: 'Step 3 of the Idea to Video feature: preview and edit your video',
        }
      },
      {
        title: 'Step 4: Export your video',
        description: 'Satisfied with the preview? Export and share it to enhance conversion rates and elevate your sales figures.',
        preview: {
          src: ideaToVideoPreview.step4,
          alt: 'Step 4 of the Idea to Video feature: export your video',
        },
        link: {
          text: 'Try idea to video',
          path: '/idea-to-video',
        },
      },
    ],
  },
  ['script-to-video']: {
    title: 'How to transform script\ninto videos in 4 steps',
    steps: [
      {
        title: 'Step 1: Input your script',
        description: 'Select \'Script to Video\' and input your script.',
        preview: {
          src: scriptToVideoPreview.step1,
          alt: 'Step 1 of the Script to Video feature: input your script',
        }
      },
      {
        title: 'Step 2: Personalize video properties',
        description: 'Choose video properties to suit your needs.',
        preview: {
          src: scriptToVideoPreview.step2,
          alt: 'Step 2 of the Script to Video feature: personalize video properties',
        }
      },
      {
        title: 'Step 3: Preview and Customize your video',
        description: 'Adjust VoiceOver styles and edit directly in our simple prompt box as if talking to a human editor. Command Boolvideo AI to delete scenes, swap stock, modify scenes, and more. We also offer an advanced editor if you need it!',
        preview: {
          src: scriptToVideoPreview.step3,
          alt: 'Step 3 of the Script to Video feature: preview and edit your video',
        }
      },
      {
        title: 'Step 4: Export your video',
        description: 'Satisfied with the preview? Export and share it to enhance conversion rates and elevate your sales figures.',
        preview: {
          src: scriptToVideoPreview.step4,
          alt: 'Step 4 of the Script to Video feature: export your video',
        },
        link: {
          text: 'Try script to video',
          path: '/script-to-video',
        },
      }
    ]
  },
  ['blog-to-video']: {
    title: 'How to transform Blog\ninto videos in 4 steps',
    steps: [
      {
        title: 'Step 1: Input your Blog URL',
        description: 'Select \'Blog to Video\' and input your Blog URL.',
        preview: {
          src: blogToVideoPreview.step1,
          alt: 'Step 1 of the BLog to Video feature: import your Blog URL',
        }
      },
      {
        title: 'Step 2: Personalize video properties',
        description: 'Choose video properties to suit your needs. Includes video length, scale, language, etc. ',
        preview: {
          src: blogToVideoPreview.step2,
          alt: 'Step 2 of the BLog to Video feature: personalize video properties',
        }
      },
      {
        title: 'Step 3: Preview and Customize your video',
        description: 'Adjust VoiceOver styles and edit directly in our simple prompt box as if talking to a human editor. Command Boolvideo AI to delete scenes, swap stock, modify scenes, and more. We also offer an advanced editor if you need it!',
        preview: {
          src: blogToVideoPreview.step3,
          alt: 'Step 3 of the BLog to Video feature: preview and edit your video',
        }
      },
      {
        title: 'Step 4: Export your video',
        description: 'Satisfied with the preview? Export and share it to enhance conversion rates and elevate your sales figures.',
        preview: {
          src: blogToVideoPreview.step4,
          alt: 'Step 4 of the BLog to Video feature: export your video',
        },
        link: {
          text: 'Try blog to video',
          path: '/blog-to-video',
        },
      }
    ],
  },
  ['visuals-to-video']: {
    title: 'How to transform visuals\ninto videos in 4 steps',
    steps: [
      {
        title: 'Step 1: Input your visuals',
        description: 'Select \'Visuals to Video\' and input your images and videos.',
        preview: {
          src: visualsToVideoPreview.step1,
          alt: 'Step 1 of the Visuals to Video feature: import your images and videos',
        }
      },
      {
        title: 'Step 2: Personalize video properties',
        description: 'Choose video properties to suit your needs. Includes video type, length, scale, purpose, library, language, etc.',
        preview: {
          src: visualsToVideoPreview.step2,
          alt: 'Step 2 of the Visuals to Video feature: select images and personalize video properties',
        }
      },
      {
        title: 'Step 3: Preview and Customize your video',
        description: 'Make individual edits in our lightweight editor. You can adjust scenes with AI or our vast stock library to find the perfect visuals. We also offer an advanced editor if you need it!',
        preview: {
          src: visualsToVideoPreview.step3,
          alt: 'Step 3 of the Visuals to Video feature: preview and edit your video',
        }
      },
      {
        title: 'Step 4: Export your video',
        description: 'Satisfied with the preview? Export and share it to enhance conversion rates and elevate your business.',
        preview: {
          src: visualsToVideoPreview.step4,
          alt: 'Step 4 of the Visuals to Video feature: export your video',
        },
        link: {
          text: 'Try visuals to video',
          path: '/visuals-to-video',
        },
      }
    ]
  },
  ['template-to-video']: {
    title: 'How to transform templates\ninto videos in 4 steps',
    steps: [
      {
        title: 'Step 1: Choose a template',
        description: 'Select \'Templates to Video\' and input your URL or visuals.',
        preview: {
          src: templatesToVideoPreview.step1,
          alt: 'Step 1 of the Templates to Video feature: import your product URL or images',
        }
      },
      {
        title: 'Step 2: Input your product description',
        description: 'Describe your product style, product selling point, or brand story.',
        preview: {
          src: templatesToVideoPreview.step2,
          alt: 'Step 2 of the Templates to Video feature: input your product description',
        }
      },
      {
        title: 'Step 3: Preview and Customize your video',
        description: 'Make individual edits in our lightweight editor.',
        preview: {
          src: templatesToVideoPreview.step3,
          alt: 'Step 3 of the Templates to Video feature: preview and edit your video',
        }
      },
      {
        title: 'Step 4: Export your video',
        description: 'Satisfied with the preview? Export and share it to enhance conversion rates and elevate your sales.',
        preview: {
          src: templatesToVideoPreview.step4,
          alt: 'Step 4 of the Templates to Video feature: export your video',
        },
        link: {
          text: 'Try templates to video',
          path: '/template-video-list',
        },
      }
    ]
  },
  ['web-editor']: {
    title: 'How to start effortlessly?',
    steps: [
      {
        title: 'Step 1: Go to advanced editor',
        description: 'Select \'Start from Scratch\'.',
        preview: {
          src: webEditorPreview.step1,
          alt: 'Step 1 of the Start from scratch feature: go to advanced editor',
        }
      },
      {
        title: 'Step 2: Input your visuals',
        description: 'Input your images and videos in the "Media" section of the left toolbar.',
        preview: {
          src: webEditorPreview.step2,
          alt: 'Step 2 of the Start from scratch feature: Input your images, videos and music',
        }
      },
      {
        title: 'Step 3: Edit your clips in the timeline',
        description: 'Drag visuals into the timeline and arrange your clips in a certain order. You can also add music, text, effects, filters and Voiceover!',
        preview: {
          src: webEditorPreview.step3,
          alt: 'Step 3 of the Start from scratch feature: edit your clips in the timeline',
        }
      },
      {
        title: 'Setp 4: Export your video',
        description: 'Satisfied with the preview? Export and share it to enhance conversion rates and elevate your business.',
        preview: {
          src: webEditorPreview.step4,
          alt: 'Step 4 of the Start from scratch feature: export your video',
        },
        link: {
          text: 'Start creating',
          path: '/editor',
        },
      }
    ],
  },
  ['ai-tools']: {
    title: 'AI-powered tools\nfor your video editing',
    steps: [
      {
        title: 'Image Enhancer',
        description: 'Upscale your images by 2x in one click to meet your resolution requirements',
        preview: {
          src: aiToolPreview.imageEnhancer,
          alt: 'Image Enhancer Preview',
        },
        link: {
          text: 'Enhance image now',
          path: '/aiTools/upload?aiToolType=imageEnhancer',
        }
      },
      {
        title: 'Image Background Remover',
        description: 'Remove unwanted background for your image with remarkable precision.',
        preview: {
          src: aiToolPreview.imageBgRemove,
          alt: 'Image Background Remover Preview',
        },
        link: {
          text: 'Remove background now',
          path: '/aiTools/upload?aiToolType=backgroundRemover',
        }
      },
      {
        title: 'Object Eraser',
        description: 'Erase unwanted objects, people, defects or watermarks to create flawless images',
        preview: aiToolPreview.objectEraser,
        link: {
          text: 'Erase object now',
          path: '/aiTools/upload?aiToolType=objectErase',
        }
      },
      {
        title: 'Video Background Remover',
        description: 'Effortlessly isolate people from the background of any video, without the need of green screen',
        preview: aiToolPreview.videoBgRemove,
        link: {
          text: 'Remove background now',
          path: '/aiTools/upload?aiToolType=videoBackgroundRemover',
        }
      },
      {
        title: 'Video Enhancer',
        description: 'Upscale your video by 2x and improve video color balance, sharpness, and clarity',
        preview: aiToolPreview.videoEnhancer,
        link: {
          text: 'Enhance video now',
          path: '/aiTools',
        }
      }
    ],
  },
  ['ecommerce-seller']: {
    title: 'Your Workflow with Boolvideo',
    steps: [
      {
        title: 'Gather Your Source Materials',
        description: `
          - Collect product URLs, text descriptions, marketing scripts, footage etc.
        `,
      },
      {
        title: 'Choose Boolvideo\'s Creation Feature',
        description: (
          <>
            - To show off products, use the <RouterLink to='/product-to-video'>Product URL to Video generator</RouterLink>, choose the main aims of your video by choosing from 'Product Showcase', 'ads' or 'posts'.
            - For content like blogs or scripts, use the <RouterLink to='/idea-to-video'>Idea to Video</RouterLink> feature.
            - For any visual assets like images and video, use <RouterLink to='/visuals-to-video'>Visuals to Video</RouterLink> feature.
            - For social ads and teasers, browse the ecommerce-specific <RouterLink to='/template-video-li'>Video Templates</RouterLink>.
          </>
        ),
      },
      {
        title: 'Customize and Edit with web timeline editor',
        description: (
          <>
            - Use Boolvideo's light <RouterLink to='/editor'>web editor</RouterLink> to intelligently fine-tune content, branding, styling.
          </>
        ),
      },
      {
        title: 'Publish & Optimize',
        description: `
          - Download your completed video for publishing across channels.
          - Review performance analytics to identify top performers.
          - Create new variations or updates with just a few clicks.
          With Boolvideo, you'll spend less time and money creating premium videos for your ecommerce business. Boost sales, engage customers, and stay ahead of the competition through the power of effortless video creation!
        `,
      }
    ],
  },
  ['content-creator']: {
    title: 'Your Workflow with Boolvideo',
    steps: [
      {
        title: 'Gather Your Source Materials',
        description: `
          - Collect your written content, such as blog posts, scripts, or stories.
          - Prepare any additional assets like logos, graphics, or footage you want to incorporate.
        `,
      },
      {
        title: 'Choose Boolvideo\'s Creation Feature',
        description: (
          <>
            - For any written content like ideas, blogs or scripts, use <RouterLink to=''>Idea to Video</RouterLink>, <RouterLink to=''>Script to Video</RouterLink>, <RouterLink to=''>Blog to Video</RouterLink> tools.
            - For visual assets like images and videos, use <RouterLink to=''>Visuals to Video</RouterLink> feature.
            - For social ads and teasers, browse the various video templates and use <RouterLink to=''>Template to Video</RouterLink>.
          </>
        ),
      },
      {
        title: 'Customize and Edit with web timeline editor',
        description: `
          - Use Boolvideo's light web editor to intelligently fine-tune content, branding, styling.
        `,
      },
      {
        title: 'Publish & Optimize',
        description: `
          - Download your completed video for publishing across channels.
          - Review performance analytics to identify top performers.
          - Create new variations or updates with just a few clicks.
          With Boolvideo's AI creation capabilities, you can shorten video turnaround time from days to minutes while producing quality content that truly resonates with your audience. Get ready to accelerate your content creation!
        `,
      },
    ]
  },
  ['social-media-marketer']: {
    title: 'Social Media Video Workflow\nwith Boolvideo',
    steps: [
      {
        title: 'Concept and Plan',
        description: `
          - Outline your video creative ideas, themes, messaging.
          - Choose which social platforms to produce video for.
        `,
      },
      {
        title: 'Choose Boolvideo\'s Creation Feature',
        description: (
          <>
            - To show off products, use the <RouterLink to='/product-to-video'>Product URL to Video generator</RouterLink> and choose 'post' as your video aim.
            <br />
            - For any written content like blogs or scripts, use <RouterLink to='/idea-to-video'>Idea to Video</RouterLink>, <RouterLink to='/script-to-video'>Script to Video</RouterLink> or <RouterLink to='/blog-to-video'>Blog to Video</RouterLink> tools.
            <br />
            - For social ads and teasers, browse the various video templates and use <RouterLink to='/template-video-list'>Template to Video</RouterLink>.
          </>
        ),
      },
      {
        title: 'Optimize for Social',
        description: `
          - Experiment with different video formats, aspect ratios, lengths.
          - Incorporate on-brand visual elements like logos, colors, fonts.
          - Add music tracks, captions, voiceovers and more.
        `,
      },
      {
        title: 'Publish and Analyze',
        description: `
          - Download videos optimized for each social channel.
          - Publish across Facebook, Instagram, Twitter, YouTube etc.
          - Review video performance analytics to identify winners.
          With Boolvideo's social-focused tools, you can finally produce the video content you need to stay ahead on social - faster and more cost-effectively than ever before.
        `,
      },
    ],
  },
  ['photographer']: {
    title: 'Your Workflow with Boolvideo',
    steps: [
      {
        title: 'Gather Your Source Materials',
        description: `
          - Collect your photographic collections or individual images you want to showcase.
          - Prepare any additional assets like logos, graphics, or footage you want to incorporate.
        `,
      },
      {
        title: 'Choose Boolvideo\'s Creation Feature',
        description: (
          <>
            - For transforming your visual assets into videos, use the <RouterLink to='/visuals-to-video'>Visuals to Video</RouterLink> tool.
            - For any written content like blogs or scripts, use <RouterLink to='/idea-to-video'>Idea to Video</RouterLink>, <RouterLink to='/script-to-video'>Script to Video</RouterLink> or <RouterLink to='/blog-to-video'>Blog to Video</RouterLink> tools.
            - For creating specific video types, browse the relevant <RouterLink to='/template-video-list'>video templates</RouterLink>.
          </>
        ),
      },
      {
        title: 'Customize and Edit with the Web Timeline Editor',
        description: `
          - Use Boolvideo's intuitive web-based editor to fine-tune your videos.
          - Incorporate your brand elements, visual effects, and motion graphics for a consistent visual identity.
        `,
      },
      {
        title: 'Publish and Optimize',
        description: `
          - Download your completed videos in optimized formats for various platforms.
          - Publish your videos across your portfolio websites, social media channels, and online galleries with ease.
          - Review performance analytics to identify top-performing videos and create new variations or updates efficiently.
          With Boolvideo, photographers can elevate their craft by transforming their artistry into captivating videos. Showcase your unique visual perspective, tell compelling narratives, and leave a lasting impression with the power of effortless video creation.
        `,
      },
    ],
  },
  ['artist']: {
    title: 'Your Creative Journey with Boolvideo',
    steps: [
      {
        title: 'Gather Your Source Materials',
        description: `
          - Prepare your paintings, sculptures, photographs, or other visual artworks you wish to showcase.
          - Collect any additional assets, such as logos, graphics, or footage, you want to incorporate.
        `,
      },
      {
        title: 'Choose Boolvideo\'s Creation Feature',
        description: (
          <>
            - Use <RouterLink to='/visuals-to-video'>Visuals to Video</RouterLink> to transform your static artwork into dynamic video presentations.
            - For any written content like blogs or scripts, use <RouterLink to='/idea-to-video'>Idea to Video</RouterLink>, <RouterLink to='/script-to-video'>Script to Video</RouterLink> or <RouterLink to='/blog-to-video'>Blog to Video</RouterLink> tools
            - Browse the relevant <RouterLink to='/template-video-list'>video templates</RouterLink> based on your specific needs, such as portfolio showcases, behind-the-scenes stories, or conceptual expressions.
          </>
        ),
      },
      {
        title: 'Customize and Edit Online',
        description: (
          <>
            - Utilize Boolvideo's intuitive <RouterLink to='/editor'>web-based editor</RouterLink> to fine-tune your videos, seamlessly blending them with your artistic style.
            - Incorporate your branding elements, visual effects, and motion graphics for a cohesive artistic identity.
          </>
        ),
      },
      {
        title: 'Publish and Optimize',
        description: `
          - Download your completed videos in optimized formats for various platforms and devices.
          - Easily publish your videos on your website, social media channels, online galleries, and more.
          - Analyze performance data to continuously refine and create captivating artistic visual experiences.
          With Boolvideo, artists can elevate their craft by producing video content with unprecedented efficiency and professional quality, reaching broader audiences than ever before. Unleash your creativity and shine brightly in the digital age!
        `,
      },
    ]
  },
  ['affiliate-program']: {
    title: 'How to Get Started',
    steps: [
      {
        title: 'Step 1: Join the Affiliate Program',
        description: `Sign up for Boolvideo's affiliate program, receive your personalized affiliate link, and begin earning commissions with ease.`,
        preview: {
          src: affiliateProgram.step1,
          alt: `Sign up for Boolvideo's affiliate program, receive your personalized affiliate link, and begin earning commissions with ease.`,
        }
      },
      {
        title: 'Step 2: Promote Boolvideo',
        description: `Create content to help spread the word about Boolvideo. Incorporate your affiliate links into your content and share across your favorite platforms.`,
        preview: {
          src: affiliateProgram.step2,
          alt: 'Create content to help spread the word about Boolvideo. Incorporate your affiliate links into your content and share across your favorite platforms.',
        }
      },
      {
        title: 'Step 3: Earn and Get Paid',
        description: `Earn 40% in commissions for every sale you bring in. All commissions will be paid monthly via Wise or PayPal. Get started today!`,
        preview: {
          src: affiliateProgram.step3,
          alt: `Earn 40% in commissions for every sale you bring in. All commissions will be paid monthly via Wise or PayPal. Get started today!`,
        }
      }
    ]
  },
};

export default infoMap;

export const useData = () => {
  const route = useRoute();
  const pageName = (route.name as string).match(/(?:^case-|^feature-)?(.+)/)![1];

  return infoMap[pageName];
};
