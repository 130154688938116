<template>
  <div class="slogan-wrapper" v-for="(item, index) in sloganList">
    <slogan
      v-model="sloganList[index]"
      :listLength="sloganList.length"
      :index="index"
      :placeholder="placeholder"
      @remove="remove"
      @add="add"
    />
  </div>
</template>

<script setup lang="ts">
import type { PropType, Ref } from "vue";
import { ref, watch, defineProps, defineEmits } from "vue";
import Slogan from "./slogan.vue";

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
  placeholder: String,
  modelValue: {
    type: Array as PropType<string[]>,
    required: true,
  },
});

const sloganList: Ref<string[]> = ref([]);

const add = (id: number) => {
  sloganList.value.splice(id, 0, "");
};

const remove = (id: number) => {
  sloganList.value = sloganList.value.filter((slogan, index) => index !== id);
};

watch(
  () => props.modelValue,
  (value) => (sloganList.value = value),
  {
    immediate: true,
  },
);

watch(sloganList, (value) => emits("update:modelValue", value), {
  deep: true,
});

watch(
  sloganList,
  (value) => {
    if (value.length === 0) {
      add(0);
    }
  },
  {
    immediate: true,
  },
);
</script>

<style scoped lang="scss">
.slogan-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 18px;
}
</style>
