<template>
  <!-- pricing FAQ -->
  <div class="faq-container">
    <!-- faq标题 -->
    <p class="faq-title">Frequently asked questions</p>
    <el-collapse v-model="activeFaqNames">
      <el-collapse-item :name="index + 1" v-for="(item, index) in faqList">
        <template #title>
          <div class="faq-item-title">
            <p>{{ item.title }}</p>
          </div>
        </template>
        <div class="faq-item-content">
          <p>{{ item.content }}</p>
        </div>
      </el-collapse-item>
      <el-collapse-item>
        <template #title>
          <div class="faq-item-title">
            <p>How do I request a refund?</p>
          </div>
        </template>
        <div class="faq-item-content">
          <p v-show="subscriptionState.supportRefundBoolean">
            Your subscription is covered by a 7-day money-back guarantee. If you
            are not satisfied with your purchase, you can click
            <a @click="handleRefund">here</a> to request a refund.
          </p>
          <p v-show="!subscriptionState.supportRefundBoolean">
            Usually, we don't process refunds, since we offer free trial for all
            users. However, if you are not 100% happy, contact us! As a startup,
            we will try our best to deliver delightful experiences for our
            customers :)
          </p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  <refund-modal v-model="showRefundModal" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { faqList } from "../../constants";
import { useSubscriptionInfo } from "@/store/modules/user";
import RefundModal from "../modal/refundmodal.vue";

const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const activeFaqNames = ref(["1"]);
const showRefundModal = ref(false);

const handleRefund = () => {
  showRefundModal.value = true;
};
</script>

<style lang="scss" scoped>
.faq-container {
  max-width: 782px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  margin-bottom: 449px;
  padding: 0 1.5rem;
  user-select: none;

  :deep(.el-collapse) {
    margin-top: 64px;
    border-top: none;
  }

  :deep(.el-collapse-item) {
    padding-bottom: 36px;
    padding-top: 36px;
    border-bottom: 1px solid #ebeef5;
  }

  :deep(.el-collapse-item__header) {
    height: fit-content;
    border: none;
  }

  :deep(.el-collapse-item__content) {
    padding: 0;
    padding-top: 24px;
  }

  :deep(.el-collapse-item__wrap) {
    border: none;
  }
}

.faq-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 51px;
  text-align: center;
}

.faq-item-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.faq-item-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #8f959e;

  & a {
    cursor: pointer;
    color: #875eff;

    &:hover {
      color: #a987ff;
    }
  }
}

@media screen and (min-width: 820px) {
  .faq-container {
    padding: 0 2.25rem;
  }
}
</style>
