<template>
  <div class="case-video-item" @mousemove="handleShowButton" @mouseleave="handleHiddenButton"  >
    <div class="video-item-box" :id="elementId" @click.stop="handleControlPlay" @mousemove="handleMouseMove" @mouseleave="handleMouseLeave"
       :style="{ aspectRatio: item.ratio }">

      <div class="asset-box" :style="{ 'aspect-ratio': item.ratio }">
        <img :src="item.coverPic" v-if="isStop" />
        <svg-icon v-if="isStop" class="icon-play-large" name="icon_big_play" :size="80" />
        <video ref="videoRef" class="video-item" :src="item.videoUrl" :poster="item.coverPic" autoplay
          preload="metadata" loop @loadedmetadata="handleVideoLoaded" @error="handleVideoError" v-else></video>
          
        <div class="controls" v-if="!isStop && showControl" @click.stop="e => { return }"  @mousemove="handleMouseMove" >
          <bv-slider :model-value="currentFrame" :step="1" :min="0" :max="Number(videoRef?.duration)"
            :show-tooltip="false" @input="handleSeekTo" />

          <div class="control-btns">
            <div class="flex">
              <svg-icon class="icon-play" v-show="!played" name="player_play" :size="24" @click.stop="handlePlay" />
              <svg-icon class="icon-play" v-show="played" name="player_pause" :size="24" @click.stop="handlePause" />
              <span>{{ formatDuration(currentFrame) }}/{{ formatDuration(videoRef?.duration) }}</span>
            </div>

            <svg-icon class="icon-volume" v-show="!muted" name="player_volume" :size="20"
              @click.stop="handleSetVolume(0)" />
            <svg-icon class="icon-volume" v-show="muted" name="player_mute" :size="20"
              @click.stop="handleSetVolume(1)" />
          </div>
        </div>

        <div class="loading" v-if="played && loading">
          <svg-icon name="icon_loading" color="#BBBFC4" :size="24" />
        </div>
      </div>
    </div>

    <div class="button-box" v-if="showButton" @mousemove.stop="handleShowButton" >
      <div class="button" @click.stop="handleToPath"> Try {{item.buttonText }} </div>
    </div>

  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { useRouter, useRoute } from "vue-router";

const props = defineProps({
  item: Object,
  isClick: Boolean
})

const emit = defineEmits(['isPlaying'])
const router = useRouter()
const innerItem = ref(null)
const isStop = ref(true)
const played = ref(false)
const videoRef = ref(null)
const loading = ref(true)
const visible = ref(false)
const currentFrame = ref(0)
const showControl = ref(false)
const showButton = ref(false)
const muted = ref(false)
const elementId = computed(() => {
  return `video-item-${props.item.id}`
})

const handleShowButton = () => {
  showButton.value = true
}

const handleHiddenButton = () => {
  showButton.value = false
}


function formatDuration (duration) {
  const minutes = duration / 60;
  const seconds = duration % 60;

  return `${minutes | 0}:${(seconds | 0).toString().padStart(2, "0")}`;
}

const handleSetVolume = (value) => {
  videoRef.value.muted = value ? false : true
  muted.value = value ? false : true
}

const handleSeekTo = (value) => {
  videoRef.value.currentTime = value
}

watch(() => props.item, (item) => {
  innerItem.value = item
  if (!item.isPlaying) {
    videoRef.value?.pause()
    played.value = false
    isStop.value = true
    loading.value = true
  }
}, {
  immediate: true,
  deep: true
})


watch(() => videoRef.value, (videoRef) => {
  if (videoRef) {
    handleControlVideo()
  }
}, {
  immediate: true
})


const handleControlVideo = () => {
  videoRef.value.addEventListener('timeupdate', function () {
    currentFrame.value = videoRef.value?.currentTime;
  });
}


const handleControlPlay = () => {
  if(!props.isClick) {
    return
  }
  
  if (played.value == true) {
    videoRef.value?.pause()
    isStop.value = true
    played.value = false
    loading.value = true
    innerItem.value.isPlaying = false
  } else {
    videoRef.value?.play()
    isStop.value = false
    played.value = true
    innerItem.value.isPlaying = true
  }

  emit('isPlaying', innerItem.value)
}

const handlePlay = () => {
  videoRef.value?.play()
  played.value = true
  isStop.value = false
  loading.value = false
};

const handlePause = () => {
  videoRef.value?.pause()
  visible.value = true
  played.value = false
  loading.value = true
}

const handleVideoLoaded = (e) => {
  loading.value = false
}

const handleVideoError = () => {
  loading.value = true
}

const handleMouseMove = () => {
  showControl.value = true
}

const handleMouseLeave = () => {
  showControl.value = false
}

const preloadVideo = (src, callback) => {
  var video = document.createElement('video');
  video.preload = 'auto';
  video.src = src;
  video.addEventListener('loadeddata', function () {
    // callback(video);
  });
}

const handleToPath = (e) => {
  router.push(props.item.path)
}

onMounted(async () => {
  const options = {
    root: null, // 使用视窗作为根元素
    rootMargin: '0px', // 根元素的边距
    threshold: 0.5 // 目标元素可见比例达到 50% 时触发回调
  };

  const element = document.getElementById(elementId.value)

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // 预加载
        preloadVideo(props.item.videoUrl)
      } else {
        handlePause()
        isStop.value = true
      }
    });
  }, options);

  if (!element) {
    return
  }
  observer.observe(element);
})

</script>

<style lang="scss" scoped>
.case-video-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-item-box {
  // height: 570px;
  vertical-align: middle;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
}

.asset-box {
  height: 461px;
  position: relative;
}

.icon-play-large {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.case-video-item img {
  width: 100%;
  border-radius: 9.57px;
  border: 0.719px solid #E5E7EB;
  object-fit: contain;
  user-drag: none; /* 禁止拖拽 */
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.case-video-item .video-item {
  width: 100%;
  border-radius: 9.57px;
  border: 0.719px solid #E5E7EB;
}
.case-video-item .button-box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.case-video-item .button {
  display: flex;
  width: 200px;
  padding: 10px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  border: 1px solid #BBBFC4;
  background: #FFF;
  margin-top: 23.97px;
  cursor: pointer;

  &:hover {
    background: #F5F6F7;
  }
}

.loading {
  z-index: 1;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading svg {
  animation: rotate 1s linear infinite;
}

.controls {
  height: 66px;
  width: 100%;
  padding: 8.5px 16px 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-variant: tabular-nums;
  border-bottom-left-radius: 9.57px;
  border-bottom-right-radius: 9.57px;
}

.controls .control-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.controls svg {
  cursor: pointer;
}

.controls .icon-play {
  margin-right: 8px;
  margin-left: -6px;
}

.controls .icon-volume {
  margin-right: -3px;
}

:deep(.controls .el-slider) {
  margin: 0 12px;
  flex: 1 1;
}

:deep(.controls .el-slider__runway) {
  background: rgba(255, 255, 255, 0.4);
  height: 4px;
  border-radius: 40px;
  overflow: hidden;
}

:deep(.controls .el-slider__bar) {
  background-color: #ffffff;
  height: 4px;
}

:deep(.controls .el-slider__button-wrapper) {
  display: none;
}

:deep(.el-loading-spinner .path) {
  stroke: #646a73 !important;
}
</style>