<template>
  <div class="info-container">
    <p class="text-base font-medium">
      {{ productSummary.productSummaryName || "Boolvideo Pro Lifetime" }}
    </p>
    <p class="mt-5">
      <span class="text-5xl font-bold mr-6">{{
        productSummary.productSummaryTotalAmount || "$50"
      }}</span>
    </p>
    <p class="mt-9 flex justify-between">
      <span>{{
        orderDetailsItems.lineItemProductName || "Boolvideo Pro Lifetime"
      }}</span>
      <span>{{ orderDetailsItems.lineItemTotalAmount || "$50" }}</span>
    </p>
    <!-- 账单信息 -->
    <p class="mt-9 mb-6 flex justify-between">
      <span>Subtotal</span>
      <span>{{
        orderDetailsFooter.orderDetailsFooterSubtotalAmount || "$69"
      }}</span>
    </p>
    <hr />
    <!-- 优惠券信息 -->
    <coupon
      :data="coupon"
      :discount="orderDetailsFooter.discountLine?.amount"
      :update="updatePriceData"
    />
    <hr />
    <p class="mt-6 font-medium flex justify-between">
      <span>Total due today</span
      ><span>{{ orderDetailsFooter.orderDetailsTotalAmount || "$10.00" }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import Coupon from "../coupon/coupon.vue";
import { updateLtdPrice, updateSubPrice } from "@/api/premium";
import { useMessage } from "@/utils";

const emits = defineEmits(["update:couponCode"]);
const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
  couponCode: {
    type: String,
    required: true,
  },
  packageCode: {
    type: Number,
    required: true,
  },
});

const message = useMessage();
const ltdList = [300204, 300304];
const subList = [300202, 300302];

const productSummary = ref({
  productSummaryDescription: "Server error",
  productSummaryName: "",
  productSummaryTotalAmount: "",
});
const orderDetailsItems = ref({
  lineItemAmountDetail: "",
  lineItemImage: "",
  lineItemProductName: "",
  lineItemTotalAmount: "",
});
const orderDetailsFooter = ref({
  discountLine: {
    amount: "",
    couponCode: "",
    discountDescription: "",
  },
  orderDetailsFooterSubtotalAmount: "",
  orderDetailsTotalAmount: "",
  orderDetailsTrialAmount: "",
});
const coupon = ref({
  couponCheckFailMsg: "",
  couponCheckSuccessBoolean: true,
  couponValue: "",
});

const updatePriceData = async (couponCode: string) => {
  const payload = {
    packageCode: props.packageCode,
    couponCode: couponCode || null,
  };
  const updateFn = () => {
    if (ltdList.includes(props.packageCode)) {
      return updateLtdPrice(payload);
    } else if (subList.includes(props.packageCode)) {
      return updateSubPrice(payload);
    } else {
      return null;
    }
  };
  const res = await updateFn();
  if (res) {
    if (res.code === 0) {
      if (res.data.couponCheckSuccessBoolean) {
        updatePaymentOrderInfo(res.data);
      }
      updateCouponData(res.data);
    } else {
      message.error(res.msg);
    }
    return res;
  } else {
    message.error("PackageCode Error");
    return;
  }
};

const updatePaymentOrderInfo = (_value: any) => {
  const {
    orderDetails: _orderDetails,
    productSummary: _productSummary,
    subscriptionCycle: _subscriptionCycle,
  } = _value;
  productSummary.value = _productSummary;
  const {
    orderDetailsFooter: _orderDetailsFooter,
    orderDetailsItems: _orderDetailsItems,
  } = _orderDetails;
  orderDetailsItems.value = _orderDetailsItems;
  orderDetailsFooter.value = _orderDetailsFooter;
};

const updateCouponData = (_value: any) => {
  const { couponCheckFailMsg, couponCheckSuccessBoolean, orderDetails } =
    _value;
  coupon.value.couponCheckFailMsg = couponCheckFailMsg;
  coupon.value.couponCheckSuccessBoolean = couponCheckSuccessBoolean;
  const discountLine = orderDetails?.orderDetailsFooter?.discountLine;
  coupon.value.couponValue = discountLine ? discountLine.couponCode : "";
  emits("update:couponCode", coupon.value.couponValue);
};

onMounted(() => {
  updatePaymentOrderInfo(props.info);
  updateCouponData(props.info);
});
</script>

<style scoped lang="scss">
.info-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 24px 16px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (min-width: 992px) {
  .info-container {
    width: 50%;
    margin: 0;
    padding: 30px 91px;
  }
}
</style>
