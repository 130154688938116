<template>
  <div class="banner-container">
    <div class="banner">
      <ul class="card-list" @wheel="handleWheel" :ref="pointerMove.ref"
        :style="{ left: `${offset}px` }">
        <li v-for="item in innerList" :key="item.id">
          <videoItem :item="item" @isPlaying="handleIsPlaying" :isClick="isClick" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import videoItem from './videoItem.vue'
import { usePointerMove } from "@/utils/hook";

const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
});

const offset = ref(0)
const startPoint = {}
const innerList = ref(props.list);
let isClick = ref(true)
const isLeft = ref(true)

function handleProgress (e) {

  if (e.state === "start") {
    isClick.value = true
    startPoint.x = e.x;
    startPoint.y = e.y;
  } else if (e.state === "move") {

    if (e.deltaY > 0) {
      return
    }

    if (e.deltaX > 0) {
      isLeft.value = false
    } else {
      isLeft.value = true
    }

    const maxOffset = e.self.clientWidth - e.self.parentElement.clientWidth
    offset.value = Math.min(0, Math.max(-maxOffset, offset.value + e.deltaX))

  } else if (e.state === 'end') {

    if (-180 < offset.value && offset.value < 0 && !isLeft.value) {
      offset.value = 0
    }
  }

  if (
    Math.sqrt((e.x - startPoint.x) ** 2 + (e.y - startPoint.y) ** 2) < 2
  ) return

  isClick.value = false
}

const pointerMove = usePointerMove({
  handler: handleProgress,
  suppressY: false,
  capturePointer: false
});


function handleWheel (e) {
  e.stopPropagation()
  if (Math.abs(e.deltaX) != 0) {
    e.preventDefault()
  }
  handleProgress({
    state: 'move',
    deltaX: -e.deltaX,
    deltaY: e.deltaY,
    self: pointerMove.ref.value
  })

  handleProgress({
    state: 'end',
    deltaX: e.deltaX,
    self: pointerMove.ref.value
  })
}

const handleIsPlaying = (item) => {
  innerList.value?.forEach(e => {
    if (e.id == item.id) {
      e.isPlaying = item.isPlaying
    } else {
      if (item.isPlaying) {
        e.isPlaying = false
      }
    }
  })
}
</script>

<style scoped>
.banner-container {
  position: relative;
  margin-top: 48px;
  height: 600px;
}

.banner {
  position: absolute;
  left: 0;
  right: 0;
  overflow-x: clip;
  touch-action: pan-x;

}

.card-list {
  position: absolute;
  left: 0;
  user-select: none;
  white-space: nowrap;
  padding-left: 156px;
  overscroll-behavior: none
}

.card-list>li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 9.57px;
  margin-right: 34px;
}


@media screen and (max-width: 1000px) {
  .card-list {
    padding-left: 70px;
  }
}
</style>