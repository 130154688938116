<template>
  <el-scrollbar class="perfect-scrollbar">
    <div class="form-wrapper">
      <div class="script-render-form-container">
        <div class="prompt-container">
          <p class="title">Blog URL</p>
          <case-input
            :showCase="true"
            :showError="false"
            :url="caseInfo.url"
            @update:url="(e) => (blogURL = e)"
            @selected="(e) => (blogURL = e)"
            placeholder="Enter your blog URL"
          />
        </div>
        <div class="duration-container">
          <p class="title">
            <span>Maximum duration </span>
            <span>{{ displayDuration }}</span>
          </p>
          <div class="input-container">
            <bv-slider
              v-model="duration"
              height="1"
              :min="30"
              :max="300"
              :step="30"
              :showStops="true"
              :showTooltip="false"
              @change="(v) => (duration = v)"
            />
          </div>
        </div>
        <div class="ratio-container">
          <p class="title">Ratio</p>
          <div class="select-container">
            <bv-select
              :showBoxShadow="true"
              :showBorder="false"
              v-model="ratio"
              :options="ratioPreset"
              :teleported="false"
              :popperStyle="{
                width: '50vw',
                maxWidth: '712px',
                minWidth: '500px',
              }"
              @change="(v) => (ratio = v)"
            />
          </div>
        </div>
        <div class="language-container">
          <p class="title">Language</p>
          <div class="select-container">
            <bv-select
              :showBoxShadow="true"
              :showBorder="false"
              v-model="language"
              popper-class="idea-language-select"
              searchPlaceholder="Search"
              :teleported="false"
              :showSearch="true"
              :options="languageOptions"
              :popperStyle="{
                width: '50vw',
                maxWidth: '712px',
                minWidth: '500px',
              }"
              @change="(v) => (language = v)"
            />
          </div>
        </div>
      </div>

      <PrimaryButton
        class="button-container"
        padding="16px 48px"
        @click="submit"
      >
        <div class="button-text">
          <svg-icon
            v-if="loading"
            class="loading-icon"
            name="icon_loading"
            :size="14"
          />
          <svg-icon class="star" name="icon_generate_star" :size="18" />
          Generate
        </div>
      </PrimaryButton>

      <SubscribeDialog
        :visible="subscribeModalVisible"
        @close="subscribeModalVisible = false"
        :showIntroduction="false"
      />
    </div>
  </el-scrollbar>
</template>

<script setup>
import { onBeforeUnmount, reactive, onMounted } from 'vue';
import GenerateLoading from '@/components/common/generateLoading/index.vue';
import { useModalManager } from '@/components/common/custom-modal/instance';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';
import { renderVideoFromBlog } from '@/api/script';
import { getLanguageList } from '@/api/language';
import { useMessage } from '@/utils';
import { useTrackStore } from '@/store/modules/track';
import { validImport } from '@/pages/createVideos/utils/import';
import CaseInput from '../../commonComponents/caseInput.vue';
import {  sessionSetItem} from '@/utils/storage';
import { checkResource } from '@/api/script';

const caseList = [
  'https://hannaz.substack.com/p/why-do-you-dancethen-why-do-you-live',
  'https://hannaz.substack.com/p/why-do-you-dancethen-why-do-you-live',
  'https://hannaz.substack.com/p/why-do-you-dancethen-why-do-you-live',
  'https://hannaz.substack.com/p/why-do-you-dancethen-why-do-you-live',
  'https://hannaz.substack.com/p/why-do-you-dancethen-why-do-you-live',
];

const modalManager = useModalManager();
const { collectData, track } = useTrackStore();
const router = useRouter();
const message = useMessage();
const blogURL = ref('');
const ratio = ref('9:16');
const language = ref('');
const loading = ref(false);
const duration = ref(0);
const languageOptions = ref([]);
const leftPage = ref(false);
const subscribeModalVisible = ref(false);

const getRandomUrl = (caseList) => {
  const randomIndex = Math.floor(Math.random() * caseList.length);
  return caseList[randomIndex];
};

const caseInfo = reactive({
  url: 'https://hannaz.substack.com/p/why-do-you-dancethen-why-do-you-live',
});

const displayDuration = computed(() => {
  if (duration.value <= 30) {
    return duration.value + 's';
  } else {
    return duration.value / 60 + 'min';
  }
});

const ratioPreset = [
  {
    label: '1:1 ( Square )',
    value: '1:1',
  },
  {
    label: '9:16 ( Portrait )',
    value: '9:16',
  },
  {
    label: '16:9 ( Landscape )',
    value: '16:9',
  },
];

const submit = async () => {
  if (!blogURL.value) {
    message.error('Blog URL not be empty');
    return;
  }

  const check = /^https?:\/\/([-\w]+\.)*\w+(\/[-\w%]+)*(\?)?/.test(
    blogURL.value
  );

  if (!check) {
    message.error('Please enter the correct blog URL');
    return;
  }

  const params = {
    language: language.value,
    size: ratio.value,
    blogUrl: blogURL.value,
    duration: duration.value,
  };

  sessionSetItem('generateParams', params);

  const { noCredit, msg, code } = await checkResource({
    resource: [90001, 70001],
  });

  if (code == 101011) {
    modalManager.applyTemplate('paymentFail', {});
    return;
  }

  if (noCredit) {
    modalManager.applyTemplate('upgradeTips', {
      msg,
      code,
      onConfirm: () => {
        subscribeModalVisible.value = true;
      },
    });
    return;
  }

  router.push({
    path: '/blog-to-video/generating',
  });
};

const setup = async () => {
  const languageHelper = (list) => {
    let temp = [];
    list.forEach((item) => {
      for (const i of item.localInfoList) {
        const newItem = {
          label: i.localName,
          value: i.locale,
        };
        temp.push(newItem);
      }
    });
    return temp;
  };

  const { success, data } = await getLanguageList({ filterSpecial: true });
  if (!success) return;

  languageOptions.value = languageHelper(data);
  const native = 'en-US';
  language.value = native;
};

onMounted(() => {
  caseInfo.url = getRandomUrl(caseList);
});
onBeforeMount(setup);
onBeforeUnmount(() => {
  leftPage.value = true;
});
</script>

<style lang="scss" scoped>
:deep(.el-scrollbar) {
  width: 100%;
}

:deep(.el-scrollbar__view) {
  display: flex;
  justify-content: center;
}

.form-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
}

.script-render-form-container {
  // padding-top: 5rem;
  width: 50%;
  min-width: 500px;
  max-width: 712px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  color: #060606;
  padding-bottom: 400px;

  & > div {
    width: 100%;
  }
}

.input-container {
  width: 100%;
  padding-left: 9px;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.select-container {
  height: 46px;
}

.button-container {
  position: fixed;
  bottom: 58px;
  width: fit-content !important;
}

.button-text {
  position: relative;
  width: 94px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  & > .star {
    margin-right: 6px;
  }

  & > .loading-icon {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    animation: rotate 1s linear infinite;
  }
}

.loading-container {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

:deep(.el-slider) {
  --el-slider-button-size: 18px;
  --el-slider-button-wrapper-size: 18px;
}

.prompt-container :deep(.el-input__inner) {
  height: 54px;
  padding: 14px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #060606;
}

:deep(.bv-select-button .select-wapper) {
  padding: 12px 21px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #e5e7eb inset !important;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

:deep(.bv-select-button .select-wapper.show-box-shadow.is-focus) {
  padding: 12px 21px;
  box-shadow: 0 0 0 1px #875eff inset !important;
}

:deep(.bv-select-button .select-value) {
  color: #060606;
  font-size: 14px;
  line-height: 20px;
}

.prompt-container :deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #e5e7eb inset !important;
  border-radius: 4px;

  &:hover {
    box-shadow: 0 0 0 1px #be9fff inset !important;
  }
}

.prompt-container :deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 1px #875eff inset !important;
}

.prompt-container
  :deep(.bv-select-button .select-wapper.show-box-shadow.is-focus) {
  padding: 12px 21px;
  box-shadow: 0 0 0 1px #875eff inset !important;
}

.prompt-container :deep(.el-input .el-input__wrapper:hover),
.prompt-container :deep(.el-textarea__inner:hover) {
  border-color: #be9fff;
}

:deep(.el-input__prefix) {
  margin-right: 6px;
  color: #646a73;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.el-input__prefix-inner > :last-child) {
  margin: 0;
}

:deep(.idea-language-select .bv-select-dropdown) {
  height: 400px;
}

:deep(.el-slider) {
  --el-slider-height: 1px;
  --el-slider-button-size: 18px;
  --el-slider-button-wrapper-size: 18px;
  --slider-main-color: #8f959e !important;
  --slider-runway-color: #e5e7eb !important;
  --slider-track-color: #8f959e !important;

  .el-slider__stop,
  .el-slider__runway::after,
  .el-slider__runway::before {
    width: 1px;
    height: 2px;
    background: #8f959e;
  }
}

.select-search-input:deep(.el-input__inner) {
}
</style>

<style lang="scss">
.bv-select-dropdown.idea-language-select ul {
  max-height: 250px;
}
</style>
