<template>
  <ul class="logo-container">
    <li>
      <logo-upload
        :listLength="listLength"
        @start="startUpload"
        @success="uploadSuccess"
        @progress="uploadProgress"
        @openCloud="() => (showCloud = true)"
      />
    </li>
    <li v-for="(item, index) in logoList">
      <logo
        v-if="item.url"
        :info="item"
        :index="index"
        :listLength="logoListLength"
        @preview="setPreview"
        @default="setDefault"
        @remove="remove"
      />
    </li>
    <li v-for="item in uploadList">
      <logo-upload-progress :info="item" />
    </li>
  </ul>
  <logo-preview v-model="showPreview" :src="currentPreview" />
  <cloud-asset-dialog
    :visible="showCloud"
    :multiple="true"
    @confirm="handleCloudConfirm"
    @close="() => (showCloud = false)"
  />
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import { MutexSpace } from "@/utils";
import type { PropType } from "vue";
import type { LogoInfo } from "../../type";
import Logo from "./logo.vue";
import LogoUpload from "./logoupload.vue";
import LogoUploadProgress from "./logouploadprogress.vue";
import LogoPreview from "./logopreview.vue";
import cloudAssetDialog from "@/components/asset-modal/cloudAssetDialog/index.vue";

provide("mutexSpace", new MutexSpace());

const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Array as PropType<LogoInfo[]>,
    required: true,
  },
});

interface UploadInfo {
  id: string;
  url?: string;
  state?: 0 | 1 | 2;
  progress?: number;
}

interface ImageType {
  "1080url": string;
  mid: string;
}

const logoList: Ref<LogoInfo[]> = ref(props.modelValue);
const logoListLength = computed(() => logoList.value.length);
const listLength = computed(
  () => logoList.value.length + uploadList.value.length,
);
const uploadList: Ref<UploadInfo[]> = ref([]);
const currentPreview = ref("");
const showPreview = ref(false);
const showCloud = ref(false);

watch(
  logoList,
  (value) => {
    emits("update:modelValue", value);
  },
  {
    deep: true,
  },
);

const setDefault = (id: number) => {
  logoList.value = logoList.value.map((item, index) => {
    item.defaultUse = false;
    if (index === id) {
      item.defaultUse = true;
    }
    return item;
  });
  const item = logoList.value[id];
  remove(id);
  logoList.value.unshift(item);
};

const remove = (id: number) => {
  logoList.value.splice(id, 1);
};

const startUpload = (id: string, url: string) => {
  uploadList.value.push({
    id,
    url,
    progress: 0,
    state: 2,
  });
};

const uploadProgress = (id: string, progress: number) => {
  const index = uploadList.value.findIndex((item) => (item.id = id));
  if (index < 0) {
    return;
  } else {
    uploadList.value[index].progress = progress;
  }
};

const uploadSuccess = (id: string, url: string) => {
  const index = uploadList.value.findIndex((item) => (item.id = id));
  if (index < 0) {
    return;
  } else {
    uploadList.value.splice(index, 1);
    // 导入的第一张图是默认为default use
    let d = logoListLength.value === 0;
    logoList.value.push({
      defaultUse: d,
      url,
    });
  }
};

const handleCloudConfirm = (list: ImageType[]) => {
  list.forEach((item) => {
    let d = logoListLength.value === 0;
    logoList.value.push({
      defaultUse: d,
      url: item["1080url"],
    });
  });
};

const setPreview = (url: string) => {
  currentPreview.value = url;
  showPreview.value = true;
};
</script>

<style scoped lang="scss">
.logo-container {
  width: 100%;
  display: grid;
  // grid-template-columns: repeat(autofill, 3, 1fr);\
  gap: 32px;
  --card-list-row-gap: 38px;
  --card-list-col-gap: 36px;
  --card-border-radius: 4px;
  --card-poster-height: 30%;
  --card-border-color: #dee0e3;

  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--card-poster-height), 1fr)
  );
}
</style>
