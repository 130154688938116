<template>
  <div class="privitePolicyOuter">
    <div class="privitePolicyWrapper">
      <div class="privitePolicyContainer">
        <h1 class="headerTitle">
         {{currentProject.title}}
        </h1>
        <section class="paragraph">
          Welcome to
          <a class="linkName" href="/" target="_blanck">
            {{ currentProject.name }}
          </a>
          , as applicable (the <span class="fontBold">"Website"</span>). The
          Website is owned and operated by
          <a class="linkName" href="https://boolv.tech/#/" target="_blanck">
            Boolv.tech
          </a>
          Ltd. (<span class="fontBold">"Company"</span>,
          <span class="fontBold">"us"</span>,
          <span class="fontBold">"our"</span>,
          <span class="fontBold">"we"</span>). The Website uses various types of
          cookies which help us administer and operate it, as described herein.
        </section>
        <section class="paragraph">
          This Cookie Policy explains what cookies are and how we use them. You
          should read this policy so you can understand what type of cookies we
          use, the information we collect using cookies and how that information
          is used. For further information on how we use, store and keep your
          personal data secure, Please refer to our
          <router-link to="/privacy-policy" class="tool"
            >Privacy Policy</router-link
          >.
        </section>
        <section class="paragraph">
          This Policy also tells you what your rights are in relation to the
          personal data you give to us. If you have any questions about this
          Cookie policy please contact us at;
        </section>

        <h2 class="title">WHAT ARE COOKIES</h2>
        <section class="paragraph">
          Cookies are small text files sent by a web server to your browser when
          you access the Website. Different types of cookies may be used for
          different purposes, such as to -
          <p class="identation">
            1. Collect statistical information about your use of the Website;
          </p>
          <p class="identation">
            2. Save you the need to re-enter your login information on the
            Website;
          </p>
          <p class="identation">
            3. Remember your actions and preferences on the Website over a
            period of time, so you don't have to keep re-entering them whenever
            you come back to the Website;
          </p>
          <p class="identation">
            4. Collect behavioral information about your surfing on other
            websites which then help advertisers deliver ads that are more
            relevant to you.
          </p>
        </section>
        <section class="paragraph">
          Cookies are classified into two major categories: session cookies and
          persistent cookies. Session cookies are temporarily stored on your
          device while you are visiting a given Website. They are deleted once
          you leave the Website. Persistent cookies, on the other hand, remain
          on your device for a more extended period of time specified in the
          cookie file, after you leave the Website. This type of cookie remains
          available for retrieval by the web server when you return to visit the
          website.
        </section>
        <section class="paragraph">
          Cookies are further classified into "first party cookies" and "third
          party cookies". First party cookies are those sent to your browser by
          the server of the Website you are visiting. Third party cookies are
          those sent to your browser by servers other than the Website you are
          visiting (e.g., the server of an advertising network to which the
          Website you are visiting is subscribed).
        </section>

        <h2 class="title">TYPES OF COOKIES WE ARE USING</h2>
        <section class="paragraph">
          When Users use our platform, we place the following cookies for them.
        </section>
        <table class="table">
          <tr>
            <td>NAME (ID)</td>
            <td>TYPE</td>
            <td>DOMAIN</td>
            <td>DESCRIPTION</td>
            <td>DURATION</td>
          </tr>
          <tr>
            <td>_fbp</td>
            <td>Advertisement Cookies</td>
            <td>.boolv.tech</td>
            <td>
              This cookie is set by Facebook to display advertisements when
              either on Facebook or on a digital platform powered by Facebook
              advertising, after visiting the website.
            </td>
            <td>3 months</td>
          </tr>
          <tr>
            <td>lo-uid</td>
            <td>Other Cookies</td>
            <td>.boolv.tech</td>
            <td>-</td>
            <td>2 years</td>
          </tr>
          <tr>
            <td>lo-visits</td>
            <td>Other Cookies</td>
            <td>.boolv.tech</td>
            <td>-</td>
            <td>2 years</td>
          </tr>
          <tr>
            <td>__cf_bm</td>
            <td>Functional Cookies</td>
            <td>.myfonts.net</td>
            <td>
              This cookie, set by Cloudflare, is used to support Cloudflare Bot
              Management.
            </td>
            <td>30 minutes</td>
          </tr>
        </table>
        <p class="smallTitle">
          Here are some more details about the cookies we use.
        </p>
        <section class="paragraph">
          See more details about the
          <a
            href="https://policies.google.com/technologies/cookies#types-of-cookies"
            class="linkName"
            target="_blank"
            >types of cookies used by Google</a
          >
          and its partners and how
          <a
            href="https://policies.google.com/technologies/cookies"
            class="linkName"
            target="_blank"
            >they use them</a
          >
          . You can also read more about and how Google
          <a
            href="https://policies.google.com/technologies/ads?hl=en"
            class="linkName"
            target="_blank"
            >uses</a
          >
          advertising cookies. For information about opting out of Google
          Analytics cookies, click
          <a
            href="https://tools.google.com/dlpage/gaoptout/"
            class="linkName"
            target="_blank"
            >here</a
          >
          . For information about opting out of cookies Google uses for
          advertising, click
          <a
            href="https://adssettings.google.com/authenticated?hl=en"
            class="linkName"
            target="_blank"
            >here</a
          >.
        </section>

        <h2 class="title">HOW DO WE USE COOKIES?</h2>
        <section class="paragraph">
          We may use cookies to enhance your browsing experience by:
          <p class="identation">
            1. Improving the quality of our service, better understanding of how
            people interact with us, and for survey control and fraud prevention
            purposes.
          </p>
          <p class="identation">
            2. Giving you a browsing experience that is unique to you and
            serving you content which we believe improves your experience on our
            Website.
          </p>
          <p class="identation">
            3. Analysing how you use our Website helps us to troubleshoot any
            problems and to monitor our own performance.
          </p>
        </section>

        <h2 class="title">HOW CAN I CONTROL OR DELETE COOKIES?</h2>
        <section class="paragraph">
          There are many ways to manage your cookies:
          <p class="identation">1. You can refuse your consent (Opt-Out);</p>
          <p class="identation">
            2. You can disable Company or third-party cookies by use of your
            browser settings;
          </p>
          <p class="identation">
            3. You can use our cookie management tool to disable Company or
            third-party cookies.
          </p>
        </section>

        <h2 class="title">Opt-Out</h2>
        <section class="paragraph">
          You can opt out of targeted advertising by
          <a
            href="https://www.facebook.com/settings/?tab=ads"
            class="linkName"
            target="_blank"
            >facebook</a
          >
          and
          <a
            href="https://www.google.com/settings/ads/anonymous"
            class="linkName"
            target="_blank"
            >google</a
          >
          . Additionally, you can opt out of some of these services by visiting
          the
          <a
            href="https://optout.aboutads.info/"
            class="linkName"
            target="_blank"
            >Digital Advertising Alliance's</a
          >
          opt-out portal.
        </section>

        <p class="smallTitle">Control via your browser settings</p>
        <section class="paragraph">
          Most internet browsers are initially set up to automatically accept
          cookies. If you do not want our Website to store cookies on your
          device, you can change your browser settings so that you receive a
          warning before certain cookies are stored. You can also adjust your
          settings so that your browser refuses most of our cookies or only
          certain cookies from third parties. You can also withdraw your consent
          to cookies by deleting the cookies that have already been stored.
        </section>
        <section class="paragraph">
          Most internet browsers are initially set up to automatically accept
          cookies. If you do not want our Website to store cookies on your
          device, you can change your browser settings so that you receive a
          warning before certain cookies are stored. You can also adjust your
          settings so that your browser refuses most of our cookies or only
          certain cookies from third parties. You can also withdraw your consent
          to cookies by deleting the cookies that have already been stored.
        </section>
        <section class="paragraph">
          If you use different devices to view and access the Website (e.g.,
          your computer, smartphone, or tablet) you will need to ensure that
          each browser on each device is adjusted to suit your cookie
          preferences.
        </section>
        <section class="paragraph">
          The procedures for changing your settings and cookies differ from
          browser to browser. If necessary, use the help function on your
          browser or click on one of the links below to go directly to the user
          manual for your browser.
        </section>
        <ul>
          <li class="listItem">
            <a
              href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
              class="linkName"
              target="_blank"
              >INTERNET EXPLORER</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
              class="linkName"
              target="_blank"
              >MOZILLA FIREFOX</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
              class="linkName"
              target="_blank"
              >GOOGLE CHROME</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
              class="linkName"
              target="_blank"
              >SAFARI</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://help.opera.com/en/latest/web-preferences/#cookies"
              class="linkName"
              target="_blank"
              >OPERA</a
            >
          </li>
        </ul>
        <section class="paragraph">
          In addition, most browsers offer a so-called "Do-not-track function",
          with which you can state that you do not wish to be "tracked" by the
          Website. If this function is activated, the respective browser informs
          advertising networks, websites and applications that you do not want
          to be tracked for behavioural advertising or similar. To enable the
          "do not track" option in your browser follow the respective link
          below:
        </section>
        <ul>
          <li class="listItem">
            <a
              href="https://support.mozilla.org/en-US/kb/how-do-i-turn-do-not-track-feature"
              class="linkName"
              target="_blank"
              >FIREFOX</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://support.microsoft.com/en-us/help/17288/windows-internet-explorer-11-use-do-not-track"
              class="linkName"
              target="_blank"
              >INTERNET EXPLORER</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
              class="linkName"
              target="_blank"
              >MICROSOFT EDGE</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://support.google.com/chrome/answer/2790761?hl=en-GB&co=GENIE.Platform%3DDesktop"
              class="linkName"
              target="_blank"
              >GOOGLE CHROME</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://support.apple.com/en-gb/guide/safari/prevent-websites-from-tracking-you-sfri40732/mac#ibrw88300566"
              class="linkName"
              target="_blank"
              >SAFARI</a
            >
          </li>
          <li class="listItem">
            <a
              href="https://help.opera.com/en/latest/security-and-privacy/#tracking"
              class="linkName"
              target="_blank"
              >OPERA</a
            >
          </li>
        </ul>
        <p class="smallTitle">Other cookie management tools</p>
        <section class="paragraph">
          In addition, you may opt out of some third-party cookies through the
          <a
            href="https://optout.networkadvertising.org/?c=1#!%2F"
            class="linkName"
            target="_blank"
            >Network Advertising Initiative's Opt-Out Tool</a
          >
          . For more information about how targeted advertising works, you can
          visit the Network Advertising Initiative's ("NAI")
          <a
            href="https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
            class="linkName"
            target="_blank"
            >educational page</a
          >.
        </section>

        <h2 class="title">CHANGES TO THE COOKIE POLICY</h2>
        <section class="paragraph">
          We may update this policy from time to time. If we make significant
          changes, we will let you know through email/ notifications, but please
          check this policy regularly to ensure you are aware of the most
          updated version.
        </section>

        <h2 class="title">CONTACT US</h2>
        <section class="paragraph">
          You may contact us with any questions or comments, at:
          <a
            href="mailto:support@boolvector.come"
            target="_blank"
            rel="noreferrer"
          >
            support@boolvector.com </a
          >.
        </section>
        <section class="paragraph">Last Updated: April 3, 2024</section>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useViewStore } from '@/store/view';

const viewStore = useViewStore();

const globalDomain = inject('globalDomain');
const projectConfig = {
  BoolVideo: {
    name: 'Boolv.video',
    url: 'https://boolv.tech/#/',
    title: 'Boolvideo AI Video Generator - Cookies Policy',
  },
  SimilarVideo: {
    name: 'similarvideo.ai',
    url: 'https://similarvideo.ai/#/',
    title: 'Similar Video Generator - Cookies Policy',
  },
};

const currentProject = computed(() => {
  if (globalDomain == 2) {
    return projectConfig.SimilarVideo;
  }

  if (globalDomain == 1) {
    return projectConfig.BoolVideo;
  }
});

onMounted(() => {
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth',
  });
  viewStore.header.theme = 'light';
});
</script>
<style scoped lang="scss">
.privitePolicyOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privitePolicyWrapper {
  padding: 140px 0;
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  text-align: left;
}

.privitePolicyContainer {
  max-width: 958px;
  padding-inline: 5%;
}

.headerTitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  color: #000000;
  padding-bottom: 36px;
}

.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
  margin-bottom: 24px;
}

.title {
  font-weight: 500;
  font-size: 26px;
  color: #000000;
  margin-bottom: 24px;
  line-height: 1.2;
}

.smallTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 12px;
}

.table {
  tr,
  td,
  th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 10px;
  }

  margin: 14px 0;
}

.anchorPoint {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #875eff;
  padding-bottom: 36px;
}

.linkName {
  color: #875eff;
}

.fontBold {
  font-weight: 500;
}

.identation {
  margin: 14px 0 14px 1.5rem;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
}

li.listItem {
  list-style-type: none;
}

.listItem {
  margin: 14px 0;
  font-size: 16px;
  line-height: 20px;
  color: #646a73;
}

.listItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

.subListItem {
  text-indent: 1.5rem;
  // padding-top: 10px;
}

.subListItem::before {
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #4f8eff;
  vertical-align: middle;
  margin-right: 14px;
}

.inShort {
  font-style: italic;
  padding-bottom: 12px;
}

.inShort:before {
  content: 'In Short: ';
  font-weight: 500;
  color: #000000;
}

a {
  color: #875eff;

  &:hover {
    color: #a987ff;
  }
}
</style>
