<template>
  <div
    class="saturation isPick"
    :style="saturationStyle"
    @mousedown.prevent.stop="onSelect"
  >
    <div class="saturation-white isPick"></div>
    <div class="saturation-black isPick"></div>
    <div class="slider isPick" :style="sliderStyle" v-if="color" />
  </div>
</template>

<script lang="js">
import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "Saturation",
  props: {
    color: {
      type: String,
      default: "",
    },
    size: {
      type: Number || String,
      default: 188,
    },
    hue: {
      type: Number,
      default: 0,
    },
    saturation: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  emits: ["input", "change"],
  setup(props, { emit }) {
    const sliderSize = 10;
    const sliderRadius = sliderSize / 2;
    const saturationStyle = computed(() => ({
      width: `${props.size}px`,
      height: `188px`,
      background: `hsl(${props.hue}, 100%, 50%)`,
    }));

    const sliderStyle = computed(() => {
      return {
        top: `${((100 - props.value) / 100) * 188 - sliderRadius}px`,
        left: `${(props.saturation * props.size) / 100 - sliderRadius}px`,
        width: `${sliderSize}px`,
        height: `${sliderSize}px`,
      };
    });

    const onSelect = (e) => {
      const target = e.target;
      const { left, top } = target.getBoundingClientRect();

      const getSaturation = (e) => {
        const { clientX, clientY } = e;
        let x = clientX - left;
        let y = clientY - top;
        if (x < 0) x = 0;
        if (y < 0) y = 0;
        if (x > props.size) x = props.size;
        if (y > props.size) y = props.size;
        const saturation = (x / props.size) * 100;
        const value = 100 - (y / props.size) * 100;

        return [saturation, value];
      }
      const onSelectMoving = (e) => {
        emit("input", getSaturation(e));
      };

      const onSelectEnd = (e) => {
        document.removeEventListener("mousemove", onSelectMoving);
        document.removeEventListener("mouseup", onSelectEnd);
        emit("change", getSaturation(e));
      };
      // 单点击选择
      onSelectMoving(e);
      // 选择移动
      document.addEventListener("mousemove", onSelectMoving);
      // 选择结束
      document.addEventListener("mouseup", onSelectEnd);
    };
    return {
      saturationStyle,
      sliderStyle,
      onSelect,
    };
  },
});
</script>

<style scoped lang="scss">
.saturation {
  position: relative;
}

.saturation-white,
.saturation-black {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.saturation-white {
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.saturation-black {
  background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  z-index: 1;
}
</style>
